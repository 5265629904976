import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import './style.css';
import Plus from '../../../assets/images/plus.png';
import totalclient from '../../../assets/images/totalclients.png';
import totalexpenses from '../../../assets/images/totalexpenses.png';
import CloseCard from '../../../assets/images/closeCard.png';
import totalsales from '../../../assets/images/totalsales.png';
import CreditsIcon from '../../../assets/images/Credits.png';
import SmileIcon from '../../../assets/images/smile.png';
import AngryIcon from '../../../assets/images/angry.png';
import UnHappyIcon from '../../../assets/images/unhappy.png';
import HappyIcon from '../../../assets/images/happy.png';
import NeutralIcon from '../../../assets/images/neutral-face.png';
import AionsCoin from '../../../assets/images/AionsCoin.png';
import ConfigIcon from '../../../assets/images/configs.png';
import { useNavigate } from 'react-router-dom';
import { FaUpload, FaDownload, FaAngleRight, FaPlus, FaTrash } from 'react-icons/fa';
import { AionsChart, BarGraph, Graph, Button, Card, Doc, DoughnutChart, Input, JobRequestPDF, LoadingBarGauge, LoadingScreen, MobileTabBar, MobileTabBarItem, OverviewIndicatorCard, PieChart, PieChartFinance, SmallGraph, View } from '../../../components';
import { componentsToColor } from 'pdf-lib';
var mobile = require('is-mobile');

const Company = observer(({ company }) => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [SelectedCompany, setSelectedCompany] = useState([]);
    const [TotalSales, setTotalSales] = useState(0);
    const [Period, setPeriod] = useState("weekly");
    const [AddActuals, setAddActuals] = useState(false);
    const [MetricsX, setMetricsX] = useState([]);
    const [NumberOfCredits, setNumberOfCredits] = useState([]);
    const [ExpandActualDataTable, setExpandActualDataTable] = useState(false);
    const [ActualsDetails, setActualsDetails] = useState({});
    const [TimeFrame, setTimeFrame] = useState(1);
    const [AddCredits, setAddCredits] = useState(false);
    let [MyConfig, setMyConfig] = useState({});
    const [CompanyList, setCompaniesList] = useState([]);
    const [Config, setConfig] = useState(false);
    const [metricValues, setMetricValues] = useState({});
    const [Metrics, setMetrics] = useState([]);
    const [CustomMetricsName, setCustomMetricsName] = useState('');
    const [Company, setCompany] = useState({});
    const [TotalExpensesActuals, setTotalExpensesActuals] = useState(0);
    const [TotalClients, setTotalClients] = useState(0);
    const [Offset, setOffset] = useState(0);
    const [Credits, setCredits] = useState({});
    const [CurrentDate, setCurrentDate] = useState(new Date())
    const [ButtonValue, setButtonValue] = useState("weeklygraph");
    const [MetricList, setMetricList] = useState([]);
    const [Metric, setMetric] = useState('TotalSales');
    const [Sales, setSales] = useState("");
    const [Forecast, setForecast] = useState(false);
    const [Dev, setDev] = useState("");
    const [NewTarget, setNewTarget] = useState({});
    const [Design, setDesign] = useState("");
    const colors = ['rgba(143,202,114,1)', 'rgba(49,192,209,1', 'rgba(146,140,195,1)', 'rgb(227, 54, 126)'];
    const [FinalData, setFinalData] = useState({
        ActualsDetails: {},
        Date: CurrentDate
    });
    const [ActualDetailsObject, setActualDetailsObject] = useState({
        Category: '',
        Amount: 0,
    });
    const [CreditMonth, setCreditMonth] = useState(0);
    const [CreditYear, setCreditYear] = useState(new Date().getFullYear());
    const [CreditAmount, setCreditAmount] = useState(0);
    const yearArr = [
        new Date().getFullYear() - 1,
        new Date().getFullYear(),
        new Date().getFullYear() + 1,
    ];
    const defaultMetrics = [
        'TotalSales',
        'NumberOfMeetings',
        'CostOfSales',
        'NewCustomers',
        'AverageRevenuePerCustomer',
        'OperatingExpenses',
        'RecurringRevenue',
        'TotalClients',
    ];
    const monthsArr = Array.from({
        length: 12
    }, (_, index) => index);
    const monthsArray = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    useEffect(() => {
        getCompany();
    }, [company]);

    function getCompany() {
        let id = company;
        let newTar = {};
        store.setLoading(true);
        store.getCompanyByID(id).then((ret) => {
            setNumberOfCredits([]);
            if (typeof ret === "string" || !ret) {
                setSelectedCompany([]);
                store.setLoading(false);
                return;
            }
            setMetricsX(ret.Config.MetricList);
            setMyConfig(ret.Config);
            //setNumberOfCredits(ret.Name, ret.Credits)
            setCredits(ret.Credits);
            if (ret.Credits.length > 0) {
                getCreditsOnLoad(ret)
            }
            let currDate = new Date();
            let currMonthStartDate = new Date(currDate.getFullYear(), currDate.getMonth(), 1);
            let currMonthEndDate = new Date(currDate.getFullYear(), currDate.getMonth() + 1, 0);
            const filteredActuals = ret.Actual.filter((actual) => {
                const actualDate = new Date(actual.Date);
                return actualDate >= currMonthStartDate && actualDate <= currMonthEndDate;
            });

            const filteredTargets = ret.Target.filter((target) => {
                const targetDate = new Date(target.Date);
                return targetDate >= currMonthStartDate && targetDate <= currMonthEndDate;
            });

            let totalSalesSum = 0;
            let totalClientsSum = 0;
            let totalExpensesActualsSum = 0;

            filteredActuals.forEach((actual) => {
                totalSalesSum += (actual.TotalSales || 0);
                totalClientsSum += (actual.NewCustomers || 0);
                totalExpensesActualsSum += (actual.OperatingExpenses || 0);
            });

            if (filteredTargets.length >= 1) {
                filteredTargets.sort((a, b) => new Date(b.Date) - new Date(a.Date));
                const latestTarget = ret.Target[0];
                let totalSalesPercentage = 0;
                if (latestTarget && latestTarget.TotalSales) {
                    totalSalesPercentage = ((totalSalesSum || 0) / (latestTarget.TotalSales || 0)) * 100;
                    totalSalesPercentage = Math.min(totalSalesPercentage, 100);
                }
                let totalClientsPercentage = 0;
                if (latestTarget && latestTarget.NewCustomers) {
                    totalClientsPercentage = ((totalClientsSum || 0) / (latestTarget.NewCustomers || 0)) * 100;
                    totalClientsPercentage = Math.min(totalClientsPercentage, 100);
                }
                let totalExpensesPercentage = 0;
                if (latestTarget && latestTarget.OperatingExpenses) {
                    totalExpensesPercentage = ((totalExpensesActualsSum || 0) / (latestTarget.OperatingExpenses || 0)) * 100;
                    totalExpensesPercentage = Math.min(totalExpensesPercentage, 100);
                }
                setTotalSales(totalSalesPercentage);
                setTotalClients(totalClientsPercentage);
                setTotalExpensesActuals(totalExpensesPercentage);
            } else {
                setTotalSales(0);
                setTotalClients(0);
                setTotalExpensesActuals(0);
            }
            setMetricList(ret.Config.MetricList || []);
            setSelectedCompany(ret);
            let metricValues = {};

            let metricList = ret.Config.MetricList || [];
            metricList.forEach((metricName) => {
                if (ret.Config[metricName] && Array.isArray(ret.Config[metricName])) {
                    metricValues[metricName] = [...ret.Config[metricName]];
                } else {
                    metricValues[metricName] = [];
                }
            });
            setMetricValues(metricValues);
            let tmpMetrics = ret.Config.MetricList || [];
            for (let i = 0; i < tmpMetrics.length; i++) {
                newTar[tmpMetrics[i]] = 0
            }
            newTar.Date = new Date();
            setNewTarget(newTar);
            store.setLoading(false);
        });
    };
    function closeAddForecastData() {
        handleClear();
        setForecast(false);
        forceUpdate();
    }
    function timeFrameClicked(e) {
        const selectedValue = e.target.value;
        if (selectedValue === "Yearly") {
            setTimeFrame(1);
            setPeriod("monthly");
        } else if (selectedValue === "Monthly") {
            setTimeFrame(2);
            setPeriod("weekly");
        }
    };
    function closeAddActualData() {
        handleClear();
        setAddActuals(false);
        forceUpdate();
    }
    function getMatricsTotals(data, category) {
        let updateKey = category.toString() + 'Details';
        let tmpFinalData = { ...FinalData };

        if (data && data[updateKey]) {
            let keyValues = Object.values(data[updateKey]);
            let total = 0;

            for (let i = 0; i < keyValues.length; i++) {
                total += keyValues[i].Amount;
            }
            tmpFinalData[category] = total;
            tmpFinalData.ActualsDetails = data;

        }
        setFinalData(tmpFinalData);
        forceUpdate();
    }
    function getCreditsOnLoad(ret) {
        let creds = ret.Credits;
        let semiFinalCreds = [];

        let credData = creds.filter(item => item.Departments && item.Departments.Sales !== undefined);
        for (let i = 0; i < credData.length; i++) {
            let Credits = {
                Sales: credData[i].Departments && credData[i].Departments.Sales !== undefined ? credData[i].Departments.Sales : 0,
                Dev: credData[i].Departments && credData[i].Departments.Dev !== undefined ? credData[i].Departments.Dev : 0,
                Design: credData[i].Departments && credData[i].Departments.Design !== undefined ? credData[i].Departments.Design : 0,
            };

            let cred = {
                Departments: Credits,
                Month: credData[i].Month,
                Year: credData[i].Year,
            };
            semiFinalCreds.push(cred);
        }

        let numCredits = semiFinalCreds.sort((a, b) => {
            if (b.Year !== a.Year) {
                return b.Year - a.Year;
            } else {
                return b.Month - a.Month;
            }
        });
        setNumberOfCredits(numCredits);
    }
    function handleSubmit() {
        const savedActuals = SelectedCompany.Actual || [];
        savedActuals.push(FinalData);
        if (FinalData.Date === '') {
            window.alert('Please enter date');
        }
        let data = {
            Actual: savedActuals,
        };
        let name = SelectedCompany.Name;
        store.setLoading(true);
        store.updateCompaniesByName(data, name).then((ret) => {
            if (ret) {
                window.alert("Data saved.");
                store.setLoading(false);
                handleClear();
            } else {
                window.alert("Failed to save data,please contact dev team.!");
                store.setLoading(false);
            }
        });
        handleClear();
    }
    function handleClear() {
        setActualDetailsObject({
            Category: '',
            Amount: 0,
        });
        setActualsDetails({});
        setFinalData({ Date: '' });
        window.scrollTo(0, 0);
    };
    function handleDateChange(e) {
        const selectedDate = e.target.value;
        const selectedDay = new Date(selectedDate).getDay();

        if (selectedDay !== 1) {
            alert("Please select a Monday date.");
            return;
        }

        let dateData = FinalData;
        if (e.target.id === 'Date') {
            dateData.Date = selectedDate;
        }
        setFinalData(dateData);
        forceUpdate()
    };
    function handleInputChange(category, configValue, amount, index) {
        const matricToUpdate = SelectedCompany.Config.MetricList?.filter(item => item === category);

        if (matricToUpdate[0]?.toString()) {
            const actDetails = { ...ActualsDetails };
            const categoryDetails = actDetails[category + 'Details'];

            if (categoryDetails) {
                let updated = false;
                for (let i = 0; i < categoryDetails.length; i++) {
                    if (categoryDetails[i].Category === configValue) {
                        categoryDetails[i].Amount = parseFloat(amount);
                        updated = true;
                        break;
                    }
                }

                if (!updated) {
                    categoryDetails.push({
                        Category: configValue,
                        Amount: parseFloat(amount)
                    });
                }
            } else {
                actDetails[category + 'Details'] = [{
                    Category: configValue,
                    Amount: parseFloat(amount)
                }];
            }

            setActualsDetails(actDetails);
            setExpandActualDataTable(true);
            getMatricsTotals(actDetails, category);
            forceUpdate();

            let updateKey = category.toString() + 'Details';
            let tmpFinalData = { ...FinalData };

            if (actDetails && actDetails[updateKey]) {
                let keyValues = Object.values(actDetails[updateKey]);
                let total = 0;

                for (let i = 0; i < keyValues.length; i++) {
                    total += keyValues[i].Amount;
                }
                tmpFinalData[category] = total;
                tmpFinalData.ActualsDetails = actDetails;

            }
            setFinalData(tmpFinalData);
        }
    };
    function closeAddCredits() {
        setAddCredits(false)
    }
    function openAddCredits() {
        setAddCredits(true);
    }
    function newCreditChanged(e) {
        if (e.currentTarget.id === "Sales") {
            setSales(Number(e.currentTarget.value));
        }
        if (e.currentTarget.id === "Dev") {
            setDev(Number(e.currentTarget.value));
        }

        if (e.currentTarget.id === "Design") {
            setDesign(Number(e.currentTarget.value));
        }

        if (e.currentTarget.id === "creditMonth") {
            setCreditMonth(Number(e.target.value));
        }

        if (e.currentTarget.id === "creditYear") {
            setCreditYear(Number(e.target.value));
        }

        if (e.currentTarget.id === "creditAmount") {
            setCreditAmount(Number(e.target.value));
        }
        forceUpdate();
    }
    function updateCredits() {
        let id = company;
        let data = {
            Credits: NumberOfCredits
        };
        store.setLoading(true);
        store.updateCompanyById(id, data).then((ret) => {
            if (ret) {
                store.updateCompanyById(id, data).then((retObj) => {
                    if (retObj) {
                        window.alert("Credits have been updated!");
                        store.setLoading(false);
                    }
                });
            } else {
                window.alert("Error cant update company!");
                store.setLoading(false);
            }
        })
    }
    function metricSwitchToggled(metric) {
        let tmpMetrics = [...Metrics];
        if (tmpMetrics.includes(metric)) {
            let ind = tmpMetrics.indexOf(metric);
            tmpMetrics.splice(ind, 1);
        } else {
            tmpMetrics.push(metric);
        }
        setMetrics(tmpMetrics);
        forceUpdate();
    }
    function addCustomMetric() {
        let tmpMetrics = Metrics;
        tmpMetrics.push(CustomMetricsName);
        setMetrics(tmpMetrics);
        setCustomMetricsName('');
    }
    function deleteCustomMetric(metricToDelete) {
        const updatedMetrics = Metrics.filter((metric) => metric !== metricToDelete);
        setMetrics(updatedMetrics);
    }
    function customNameChange(e) {
        setCustomMetricsName(e.target.value);
    }
    function configClicked(metric) {
        let id = SelectedCompany._id;
        setConfig(true)
        store.setLoading(true);
        store.getCompanyByID(id).then((ret) => {
            if (typeof ret === 'string' || !ret) {
                setCompany({})
                setMetrics([]);
                store.setLoading(false);
                return;
            }
            setCompany(ret)
            if (ret.Config && ret.Config.MetricList) {
                setMetrics(ret.Config.MetricList);
            } else {
                setMetrics([]);
            }

            store.setLoading(false);
        });

        store.setLoading(false);
        forceUpdate();
    }
    function saveMetricValue(metric) {
        const inputValue = metricValues[`input_${metric}`]?.trim();
        if (inputValue) {
            setMetricValues({
                ...metricValues,
                [metric]: [...(metricValues[metric] || []), inputValue],
                [`input_${metric}`]: '',
            });
        }
        forceUpdate();
    }
    function closeConfigurations() {
        setConfig(false);
        setCustomMetricsName('');
        setMetricValues({});
    }
    function getNewTargets(e) {
        let newTar = NewTarget;
        let currentMetric = Object.keys(newTar).filter(item => item === e.currentTarget.id)
        if (currentMetric) {
            newTar[currentMetric] = Number(e.target.value)
        }
        if (e.currentTarget.id === 'Date') {
            newTar.Date = e.target.value
        }
        setNewTarget(newTar);
        forceUpdate();
    }
    function addNewCredits() {
        if (Sales === '') {
            window.alert('Please input sales credits.!')
            return;
        } else if (Sales < 0) {
            window.alert('Sales can not have a negative value.!')
            return;
        }
        if (Dev === '') {
            window.alert('Please input Dev credits.!')
            return;
        } else if (Dev < 0) {
            window.alert('Dev can not have a negative value.!')
            return;
        }
        if (Design === '') {
            window.alert('Please input Design credits.!')
            return;
        } else if (Design < 0) {
            window.alert('Design can not have a negative value.!')
            return;
        }
        let creds = NumberOfCredits;
        let Credits = {
            Sales: Sales,
            Dev: Dev,
            Design: Design,
        }
        let cred = {
            Departments: Credits,
            Month: CreditMonth,
            Year: CreditYear,
        };
        creds.push(cred);
        let numCredits = creds.sort((a, b) => {
            if (b.Year !== a.Year) {
                return b.Year - a.Year;
            } else {
                return b.Month - a.Month;
            }
        });
        setNumberOfCredits(numCredits);
        let id = company;
        let data = {
            Credits: numCredits
        };
        store.setLoading(true);
        store.updateCompanyById(id, data).then((ret) => {
            if (ret) {
                store.updateCompanyById(id, data).then((retObj) => {
                    if (retObj) {
                        window.alert("Credits have been updated!");
                        setAddCredits(false);
                        store.setLoading(false);
                    }
                });
            } else {
                window.alert("Error cant update company!");
                setAddCredits(false);
                store.setLoading(false);
            }
        });
        forceUpdate();
    }
    function AddNewTargetsClicked() {
        let id = SelectedCompany._id;
        let tar = SelectedCompany.Target;
        tar.push(NewTarget)
        let data = {
            Target: tar,
        };
        store.setLoading(true);
        store.updateCompanyById(id, data).then((ret) => {
            if (ret) {
                window.alert("Targets added successfully");
                handleClear();
                store.setLoading(false);
                setForecast(false);
            }
        })
            .catch((error) => {
                console.error("Error updating company:", error);
                store.setLoading(false);
            });

    }
    function addMetrics() {
        let id = SelectedCompany._id;

        const missingMetrics = Metrics.filter((metric) => {
            const values = metricValues[metric];
            return !values || values.length === 0;
        });

        if (missingMetrics.length > 0) {
            const missingMetricNames = missingMetrics.join(', ');
            window.alert(`Please enter values for the following Metrics: ${missingMetricNames}`);
            return;
        }

        let config = {
            MetricList: Metrics,
            ...Metrics.reduce((acc, metric) => {
                acc[metric] = [];
                return acc;
            }, {}),
        };

        for (const metric in metricValues) {
            if (metricValues.hasOwnProperty(metric) && !metric.startsWith('input_')) {
                config[metric] = metricValues[metric];
            }
        }

        let data = {
            Config: config,
        };
        store.setLoading(true);
        store.updateCompanyById(id, data).then((ret) => {
            if (ret) {
                window.alert('Config added successfully');
                store.setLoading(false);
                setConfig(false)
            }
        }).catch((error) => {
            window.alert('Error updating company');
            console.error('Error updating company:', error);
            store.setLoading(false);
        });
    }
    function splitMetricName(metricName) {
        return metricName
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/\b\w/g, (match) => match.toUpperCase());
    }
    function removeMetricValue(metric, valueToDelete) {
        if (metricValues[metric]) {
            const updatedValues = metricValues[metric].filter((value) => value !== valueToDelete);
            setMetricValues({
                ...metricValues,
                [metric]: updatedValues,
            });
        }

        if (MyConfig[metric]) {
            const updatedMyConfig = MyConfig[metric].filter((value) => value !== valueToDelete);
            MyConfig = {
                ...MyConfig,
                [metric]: updatedMyConfig,
            };
        }
    }
    function deleteCredits(e) {
        let id = company;
        let creds = NumberOfCredits;
        creds.splice(Number(e.currentTarget.id), 1);
        setNumberOfCredits(creds);
        let data = {
            Credits: creds
        }
        store.setLoading(true);
        store.updateCompanyById(id, data).then((ret) => {
            if (ret) {
                store.updateCompanyById(id, data).then((retObj) => {
                    if (retObj) {
                        window.alert("Credits removed successfully");
                        setAddCredits(false);
                        store.setLoading(false);
                    }
                });
            } else {
                window.alert("Error cant credits!");
                setAddCredits(false);
                store.setLoading(false);
            }
        });
        forceUpdate();
    }
    if (mobile()) {
        return (
            <div>

            </div>
        );
    } else {
        return (
            <div style={{ width: '100%', height: '100%', overflow: 'scroll' }}>
                {SelectedCompany.Name && SelectedCompany.Name.startsWith('Aions') ? (
                    <div className='container' style={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
                        <Card className='companyDashboardTasks'>
                            <div className='tasks'>
                                <p style={{ color: 'white' }}><b>{SelectedCompany.Name}</b></p>
                            </div>
                            <div className='myCards'>
                                <Card className='tasksCardsCompleted'>
                                    <div>
                                        <img className='inProgressImg' src={totalsales} />
                                        <p>Total Sales</p>
                                    </div>
                                    <card className='cardCard'></card>
                                    <div style={{ width: '48%', display: 'flex', flexDirection: 'column', height: '100%', marginBottom: '-15%' }}>
                                        <div className='iconsRange'>
                                            <img src={AngryIcon} />
                                            <img src={UnHappyIcon} />
                                            <img src={NeutralIcon} />
                                            <img src={SmileIcon} />
                                            <img src={HappyIcon} />
                                        </div>
                                        <input className='inprng' type='range' min={0} max={100} value={TotalSales} />
                                        <h2 style={{ marginLeft: '35%', marginTop: '-1%' }}>{TotalSales.toFixed(2)}%</h2>
                                    </div>
                                </Card>
                                <Card className='tasksCardsTodo'>
                                    <div>
                                        <img className='inProgressImg' src={totalexpenses} />
                                        <p>Total Expenses</p>
                                    </div>
                                    <card className='cardCard'></card>
                                    <div style={{ width: '48%', display: 'flex', flexDirection: 'column', height: '100%', marginBottom: '-15%' }}>
                                        <div className='iconsRange'>
                                            <img src={AngryIcon} />
                                            <img src={UnHappyIcon} />
                                            <img src={NeutralIcon} />
                                            <img src={SmileIcon} />
                                            <img src={HappyIcon} />
                                        </div>
                                        <input className='inprng' type='range' min={0} max={100} value={TotalExpensesActuals} />
                                        <h2 style={{ marginLeft: '35%', marginTop: '-1%' }}>{TotalExpensesActuals.toFixed(2) || 'no value'}%</h2>
                                    </div>
                                </Card>
                                <Card className='tasksCardsForeCast' style={{ cursor: 'pointer' }} onClick={configClicked}>
                                    <div >
                                        <img className='todopngstyle' src={ConfigIcon} />
                                        <p>Config</p>
                                    </div>
                                </Card>
                            </div>
                        </Card>
                        <div className='bottomContentDiv' style={{ width: '100%' }}>
                            <Card className='metricsCardCard' style={{ width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                    <div style={{ width: '90%', overflow: 'scroll', display: 'flex' }}>
                                        {MetricList.map((metricName, index) => (
                                            <Card
                                                key={metricName}
                                                title={metricName}
                                                className='mappedMetricsStyle'
                                                onClick={() => setMetric(metricName)}
                                                style={{ backgroundColor: colors[index % colors.length] }}
                                            >
                                                <p>{metricName}</p>
                                            </Card>
                                        ))}
                                    </div>
                                    <div style={{ width: '10%' }}>
                                        <select className='periodDropDown' onChange={timeFrameClicked}>
                                            <option>Monthly</option>
                                            <option>Yearly</option>
                                        </select>
                                    </div>

                                </div>
                                <div className='graphMetricDiv'>
                                    <Graph className='MetricsOverviewGraph' actual={SelectedCompany.Actual} target={SelectedCompany.Target} timeFrame={TimeFrame} period={Period} type={Metric} buttonValue={ButtonValue} offSet={Offset} />
                                </div>
                            </Card>
                        </div>
                        {AddActuals ? (
                            <Card className='addAionsActuals'>
                                <div className='AddDataCardHeaders'>
                                    <h1>Actuals Values</h1>
                                    <img className='closeAddActualsCard' onClick={closeAddActualData} src={CloseCard} />
                                </div>
                                <div className="DateDiv">
                                    <p className="pElement">Selected Date</p>
                                    <Input className='InputDateElement' type="date" id="Date" value={FinalData.Date.toString()} onChange={handleDateChange} />
                                </div>
                                <div className='AddDataCardBody'>
                                    {MetricsX.map((type, index) => {
                                        const configArray = MyConfig[type];
                                        if (configArray) {
                                            return (
                                                <div key={index} className="metric-container">
                                                    <div className="metric-header">
                                                        <p className="metric-title">
                                                            <b>{type} :</b>
                                                        </p>
                                                    </div>
                                                    <div className="config-values">
                                                        {configArray.map((configValue, configIndex) => (
                                                            <div key={configIndex} className="config-item">
                                                                <span style={{ width: '40%' }}>{configValue}</span>
                                                                <Input
                                                                    style={{ width: '30%' }}
                                                                    type="number"
                                                                    className="config-input"
                                                                    id={configValue}
                                                                    onChange={(e) =>
                                                                        handleInputChange(type, configValue, e.target.value, index)
                                                                    }
                                                                />

                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </div>
                                <hr style={{ width: '100%' }} />
                                {MetricsX.map((mtrc) => {
                                    return <p>{mtrc}: {FinalData[mtrc] || 0}</p>
                                })}
                                <Button onClick={handleSubmit}>Add Data</Button>
                            </Card>
                        ) : null}
                        {Config ? (
                            <Card className='addAionsActuals'>
                                <div style={{ width: '100%', height: '10%', display: 'flex', flexDirection: 'column' }}>
                                    <h1>Configuration</h1>
                                    <img className='closeConfigIcon' onClick={closeConfigurations} src={CloseCard} />
                                </div>
                                <div style={{ width: '100%', height: '75%', display: 'flex', flexDirection: 'column', overflowY: 'scroll' }}>
                                    {defaultMetrics.map((metric, index) => {
                                        if (
                                            metric === 'TotalSales' ||
                                            metric === 'NumberOfMeetings' ||
                                            metric === 'CostOfSales' ||
                                            metric === 'NewCustomers' ||
                                            metric === 'AverageRevenuePerCustomer' ||
                                            metric === 'OperatingExpenses' ||
                                            metric === 'RecurringRevenue' ||
                                            metric === 'TotalClients'
                                        ) {
                                            return (
                                                <div key={index} style={{ width: '100%' }}>
                                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ width: '90%' }}>
                                                            <h3>{metric}</h3>
                                                        </div>
                                                        <div className="addMetricsIcon">
                                                            <div className="switch-container">
                                                                <div
                                                                    className={`switch ${Metrics.includes(metric) ? 'on' : 'off'}`}
                                                                    onClick={() => metricSwitchToggled(metric)}
                                                                >
                                                                    <div
                                                                        className={`toggle ${Metrics.includes(metric) ? 'on' : 'off'}`}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr style={{ width: '100%' }} />
                                                    <div>
                                                        {metricValues[metric] &&
                                                            metricValues[metric].map((value, index) => (
                                                                <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                    <p style={{ marginRight: '5px', width: '40%' }}>{value}</p>

                                                                    <button className="removeMetricValueButton" onClick={() => removeMetricValue(metric, value)}>Remove</button>
                                                                </div>
                                                            ))}
                                                    </div>
                                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                        <input className='addCategoryInputs' placeholder={`Enter ${metric} Categories`} value={metricValues[`input_${metric}`] || ''} onChange={(e) => setMetricValues({ ...metricValues, [`input_${metric}`]: e.target.value })} />
                                                        <button className="addCategoryButtons" onClick={() => saveMetricValue(metric)}>Add Category</button>
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                    <div>
                                        {Metrics.map((customMetric, index) => {
                                            if (!defaultMetrics.includes(customMetric)) {
                                                return (
                                                    <div key={index}>
                                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                            <h3 style={{ width: '90%' }}>{customMetric}</h3>
                                                            <button className="removeCustomMetricsArr" onClick={() => deleteCustomMetric(customMetric)}>Remove {customMetric} Metric</button>
                                                        </div>
                                                        <hr style={{ width: '100%' }} />
                                                        <div>
                                                            {metricValues[customMetric] &&
                                                                metricValues[customMetric].map((value, index) => (
                                                                    <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                        <p style={{ marginRight: '5px', width: '40%' }}>{value}</p>
                                                                        <button className="removeMetricValueButton" onClick={() => removeMetricValue(customMetric, value)}>Remove</button>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                            <input className='addCategoryInputs' placeholder={`Enter ${customMetric} Categories`} value={metricValues[`input_${customMetric}`] || ''} onChange={(e) => setMetricValues({ ...metricValues, [`input_${customMetric}`]: e.target.value })} />
                                                            <button className="addCategoryButtons" onClick={() => saveMetricValue(customMetric)}>Add Category</button>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
                                </div>
                                <div style={{ width: '100%', height: '5%', display: 'flex', flexDirection: 'row' }}>
                                    <input className='addCategoryInputs' placeholder="Custom Metrics" onChange={customNameChange} value={CustomMetricsName} />
                                    <button className="addCategoryButtons" onClick={addCustomMetric}>Add Custom Metric</button>
                                </div>
                                <div style={{ width: '100%', height: '5%', display: 'flex', flexDirection: 'column', marginTop: '2%' }}>
                                    <button className="addCategoryButtons" onClick={addMetrics}>Save Configurations</button>
                                </div>
                            </Card>
                        ) : null}
                        {Forecast ? (
                            <Card className='addForecast'>
                                <div className='AddDataCardHeaders'>
                                    <h1>Forecast</h1>
                                    <img className='closeAddActualsCard' onClick={closeAddForecastData} src={CloseCard} />
                                </div>
                                <div className="DateDiv">
                                    <p className="pElement">Selected Date</p>
                                    <Input className='InputDateElement' type="date" id="Date" value={NewTarget.Date} onChange={getNewTargets} />
                                </div>
                                <div className='AddDataCardBody'>
                                    {MetricsX.map((metrics, index) => (
                                        <div className="inputDiv" key={index}>
                                            <p className="targetName">{metrics}</p>
                                            <Input type="number" id={metrics} onChange={getNewTargets} value={Object.values(NewTarget)[index].toString()} />
                                        </div>
                                    ))}
                                </div>
                                <hr style={{ width: '100%' }} />
                                <Button onClick={AddNewTargetsClicked}>Add Data</Button>
                            </Card>
                        ) : null}
                    </div>
                ) :
                    <div className='container' style={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
                        <Card className='companyDashboardTasks'>
                            <div className='tasks'>
                                <p style={{ color: 'white' }}><b>{SelectedCompany.Name}</b></p>
                            </div>
                            <div className='myCards'>
                                <Card className='tasksCardsCompleted'>
                                    <div>
                                        <img className='inProgressImg' src={totalsales} />
                                        <p>Total Sales</p>
                                    </div>
                                    <card className='cardCard'></card>
                                    <div style={{ width: '48%', display: 'flex', flexDirection: 'column', height: '100%', marginBottom: '-15%' }}>
                                        <div className='iconsRange'>
                                            <img src={AngryIcon} />
                                            <img src={UnHappyIcon} />
                                            <img src={NeutralIcon} />
                                            <img src={SmileIcon} />
                                            <img src={HappyIcon} />
                                        </div>
                                        <input className='inprng' type='range' min={0} max={100} value={TotalSales} />
                                        <h2 style={{ marginLeft: '35%', marginTop: '-1%' }}>{TotalSales.toFixed(2)}%</h2>
                                    </div>
                                </Card>
                                <Card className='tasksCardsTodo'>
                                    <div>
                                        <img className='inProgressImg' src={totalexpenses} />
                                        <p>Total Expenses</p>
                                    </div>
                                    <card className='cardCard'></card>
                                    <div style={{ width: '48%', display: 'flex', flexDirection: 'column', height: '100%', marginBottom: '-15%' }}>
                                        <div className='iconsRange'>
                                            <img src={AngryIcon} />
                                            <img src={UnHappyIcon} />
                                            <img src={NeutralIcon} />
                                            <img src={SmileIcon} />
                                            <img src={HappyIcon} />
                                        </div>
                                        <input className='inprng' type='range' min={0} max={100} value={TotalExpensesActuals} />
                                        <h2 style={{ marginLeft: '35%', marginTop: '-1%' }}>{TotalExpensesActuals.toFixed(2) || 'no value'}%</h2>
                                    </div>
                                </Card>
                                <Card className='NewCustomersCard'>
                                    <div>
                                        <img className='inProgressImg' src={totalclient} />
                                        <p>New Clients</p>
                                    </div>
                                    <card className='cardCard'></card>
                                    <div style={{ width: '48%', display: 'flex', flexDirection: 'column', height: '100%', marginBottom: '-15%' }}>
                                        <div className='iconsRange'>
                                            <img src={AngryIcon} />
                                            <img src={UnHappyIcon} />
                                            <img src={NeutralIcon} />
                                            <img src={SmileIcon} />
                                            <img src={HappyIcon} />
                                        </div>
                                        <input className='inprng' type='range' min={0} max={100} value={TotalClients} />
                                        <h2 style={{ marginLeft: '35%', marginTop: '-1%' }}>{TotalClients.toFixed(2) || 'no value'}%</h2>
                                    </div>
                                </Card>
                                <Card className='AddCreditsCard' style={{ cursor: 'pointer' }} onClick={openAddCredits}>
                                    <div >
                                        <img className='todopngstyle' src={CreditsIcon} />
                                        <p>Credits</p>
                                    </div>
                                </Card>
                                <Card className='tasksCardsForeCast' style={{ cursor: 'pointer' }} onClick={configClicked}>
                                    <div >
                                        <img className='todopngstyle' src={ConfigIcon} />
                                        <p>Config</p>
                                    </div>
                                </Card>
                            </div>
                        </Card>
                        <div className='bottomContentDiv'>
                            <Card className='metricsCardCard' style={{ width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                    <div style={{ width: '90%', overflow: 'scroll', display: 'flex' }}>
                                        {MetricList.map((metricName, index) => (
                                            <Card
                                                key={metricName}
                                                title={metricName}
                                                className='mappedMetricsStyle'
                                                onClick={() => setMetric(metricName)}
                                                style={{ backgroundColor: colors[index % colors.length] }}
                                            >
                                                <p>{splitMetricName(metricName)}</p>
                                            </Card>
                                        ))}
                                    </div>
                                    <div style={{ width: '10%' }}>
                                        <select className='periodDropDown' onChange={timeFrameClicked}>
                                            <option>Monthly</option>
                                            <option>Yearly</option>
                                        </select>
                                    </div>

                                </div>
                                <div className='graphMetricDiv'>
                                    <Graph className='MetricsOverviewGraph' actual={SelectedCompany.Actual} target={SelectedCompany.Target} timeFrame={TimeFrame} period={Period} type={Metric} buttonValue={ButtonValue} offSet={Offset} />
                                </div>
                            </Card>
                        </div>
                        {AddCredits ? (
                            <Card className='addCreditsPage' style={{ height: '85%' }}>
                                <div className='AddDataCardHeaders'>
                                    <h1>Credits</h1>
                                    <img className='closeAddActualsCard' onClick={closeAddCredits} src={CloseCard} />
                                </div>
                                <div style={{ width: '100%', display: 'flex', marginTop: '5%' }}>
                                    <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                            <p style={{ width: '70%' }}>Sales Credits</p><input className='CreditsInputBox' value={String(Sales)} id="Sales" onChange={newCreditChanged} type='number' />
                                            <p style={{ marginLeft: '3%' }}>X</p>
                                            <img style={{ marginLeft: '3%' }} className='CompanyCreditsCoin' src={AionsCoin} />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                            <p style={{ width: '70%' }}>Design Credits</p><input className='CreditsInputBox' value={String(Design)} id="Design" onChange={newCreditChanged} type='number' />
                                            <p style={{ marginLeft: '3%' }}>X</p>
                                            <img style={{ marginLeft: '3%' }} className='CompanyCreditsCoin' src={AionsCoin} />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                            <p style={{ width: '70%' }}>Dev Credits</p><input className='CreditsInputBox' value={String(Dev)} id="Dev" onChange={newCreditChanged} type='number' />
                                            <p style={{ marginLeft: '3%' }}>X</p>
                                            <img style={{ marginLeft: '3%' }} className='CompanyCreditsCoin' src={AionsCoin} />
                                        </div>
                                    </div>
                                    <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                            <p style={{ width: '70%', marginLeft: '10%' }}>Month</p>
                                            <select className="CreditsInputBox" value={CreditMonth} id="creditMonth" onChange={newCreditChanged}>
                                                {monthsArr.map((month) => {
                                                    return (
                                                        <option value={month} >{monthsArray[month]}</option>
                                                    );
                                                })
                                                }
                                            </select>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                            <p style={{ width: '70%', marginLeft: '10%' }}>Year</p>
                                            <select className="CreditsInputBox" value={CreditYear} id="creditYear" onChange={newCreditChanged}>
                                                {yearArr.map((year) => {
                                                    return <option value={year}>
                                                        {year}</option>;
                                                })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <Button className='addCreditsButton' onClick={addNewCredits}>Add</Button>
                                <hr style={{ width: '100%' }} />
                                <h2>Summary</h2>
                                <div style={{ width: '100%', overflow: 'scroll', height: '40%' }}>
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ width: '25%', marginLeft: '5%' }}>
                                            <p><b>Month</b></p>
                                        </div>
                                        <div style={{ width: '15%' }}>
                                            <p><b>Design</b></p>
                                        </div>
                                        <div style={{ width: '15%' }}>
                                            <p><b>Dev</b></p>
                                        </div>
                                        <div style={{ width: '15%' }}>
                                            <p><b>Sales</b></p>
                                        </div>
                                    </div>
                                    {NumberOfCredits.map((creditObj, index) => {
                                        return (
                                            <>
                                                <div key={index} style={{ width: '100%', display: 'flex', flexDirection: 'row', overflowY: 'scroll' }}>
                                                    <div style={{ width: '25%', marginLeft: '5%' }}>
                                                        <p>{monthsArray[creditObj.Month]} - {creditObj.Year}</p>
                                                    </div>
                                                    <div style={{ width: '15%', marginLeft: '1%' }}>
                                                        <p>{creditObj.Departments.Design || null}</p>
                                                    </div>
                                                    <div style={{ width: '15%', marginLeft: '1%' }}>
                                                        <p>{creditObj.Departments.Dev || null}</p>
                                                    </div>
                                                    <div style={{ width: '15%', marginLeft: '1%' }}>
                                                        <p>{creditObj.Departments.Sales || null}</p>
                                                    </div>
                                                    <Button id={index} onClick={deleteCredits} style={{ width: "20%" }}>
                                                        Delete row
                                                    </Button>
                                                </div>
                                                <hr style={{ width: '90%' }} />
                                            </>
                                        );
                                    })}
                                </div>

                            </Card>
                        ) : null}
                        {Config ? (
                            <Card className='addAionsActuals'>
                                <div style={{ width: '100%', height: '10%', display: 'flex', flexDirection: 'column' }}>
                                    <h1>Configuration</h1>
                                    <img className='closeConfigIcon' onClick={closeConfigurations} src={CloseCard} />
                                </div>
                                <div style={{ width: '100%', height: '75%', display: 'flex', flexDirection: 'column', overflowY: 'scroll' }}>
                                    {defaultMetrics.map((metric, index) => {
                                        if (
                                            metric === 'TotalSales' ||
                                            metric === 'NumberOfMeetings' ||
                                            metric === 'CostOfSales' ||
                                            metric === 'NewCustomers' ||
                                            metric === 'AverageRevenuePerCustomer' ||
                                            metric === 'OperatingExpenses' ||
                                            metric === 'RecurringRevenue' ||
                                            metric === 'TotalClients'
                                        ) {
                                            return (
                                                <div key={index} style={{ width: '100%' }}>
                                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ width: '90%' }}>
                                                            <h3>{splitMetricName(metric)}</h3>
                                                        </div>
                                                        <div className="addMetricsIcon">
                                                            <div className="switch-container">
                                                                <div
                                                                    className={`switch ${Metrics.includes(metric) ? 'on' : 'off'}`}
                                                                    onClick={() => metricSwitchToggled(metric)}
                                                                >
                                                                    <div
                                                                        className={`toggle ${Metrics.includes(metric) ? 'on' : 'off'}`}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr style={{ width: '100%' }} />
                                                    <div>
                                                        {metricValues[metric] &&
                                                            metricValues[metric].map((value, index) => (
                                                                <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                    <p style={{ marginRight: '5px', width: '40%' }}>{value}</p>
                                                                    <button className="removeMetricValueButton" onClick={() => removeMetricValue(metric, value)}>Remove</button>
                                                                </div>
                                                            ))}
                                                    </div>
                                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                        <input className='addCategoryInputs' placeholder={`Enter ${metric} Categories`} value={metricValues[`input_${metric}`] || ''} onChange={(e) => setMetricValues({ ...metricValues, [`input_${metric}`]: e.target.value })} />
                                                        <button className="addCategoryButtons" onClick={() => saveMetricValue(metric)}>Add Category</button>
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                    <div>
                                        {Metrics.map((customMetric, index) => {
                                            if (!defaultMetrics.includes(customMetric)) {
                                                return (
                                                    <div key={index}>
                                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                            <h3 style={{ width: '90%' }}>{customMetric}</h3>
                                                            <button className="removeCustomMetricsArr" onClick={() => deleteCustomMetric(customMetric)}>Remove {customMetric} Metric</button>
                                                        </div>
                                                        <hr style={{ width: '100%' }} />
                                                        <div>
                                                            {metricValues[customMetric] &&
                                                                metricValues[customMetric].map((value, index) => (
                                                                    <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                        <p style={{ marginRight: '5px', width: '40%' }}>{value}</p>
                                                                        <button className="removeMetricValueButton" onClick={() => removeMetricValue(customMetric, value)}>Remove</button>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                            <input className='addCategoryInputs' placeholder={`Enter ${customMetric} Categories`} value={metricValues[`input_${customMetric}`] || ''} onChange={(e) => setMetricValues({ ...metricValues, [`input_${customMetric}`]: e.target.value })} />
                                                            <button className="addCategoryButtons" onClick={() => saveMetricValue(customMetric)}>Add Category</button>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
                                </div>
                                <div style={{ width: '100%', height: '5%', display: 'flex', flexDirection: 'row' }}>
                                    <input className='addCategoryInputs' placeholder="Custom Metrics" onChange={customNameChange} value={CustomMetricsName} />
                                    <button className="addCategoryButtons" onClick={addCustomMetric}>Add Custom Metric</button>
                                </div>
                                <div style={{ width: '100%', height: '5%', display: 'flex', flexDirection: 'column', marginTop: '2%' }}>
                                    <button className="addCategoryButtons" onClick={addMetrics}>Save Configurations</button>
                                </div>
                            </Card>
                        ) : null}
                    </div>
                }
            </div>
        );
    }
});

export default Company;
