import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import './style.css';
import { useStore } from '../../../stores/Store';
import { useNavigate } from 'react-router-dom';
import dashLogo from '../../../assets/images/dashLogo.png';
import Todo from '../../../assets/images/todo.png';
import InProgress from '../../../assets/images/In_Progress@2x.png';
import Completed from '../../../assets/images/checked.png';
import Plus from '../../../assets/images/plus.png';
import { Input, View, Button, Card, DoughnutChart, } from '../../../components';
import { PolarArea } from 'react-chartjs-2';
import { FaHome, FaRocketchat, FaEnvelopeOpenText, FaPaperPlane, FaRegCommentDots, FaRegFileAlt } from "react-icons/fa";
var mobile = require('is-mobile');

const Welcome = observer(({ currPage }) => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Jobs, setJobs] = useState([]);
    const [Company, setCompany] = useState({});
    const [CurrentPage, setCurrentPage] = useState('')

    useEffect(() => {
        loadCompanies();
    }, []);
    function loadCompanies() {
        store.setLoading(true);
        store.getCompaniesByPerms(store.user.Email).then((ret) => {
            if (typeof ret === "string" || !ret) {
                setCompany({});
                store.setLoading(false);
                return;
            }
            if (ret.length === 0) {
                setCompany({});
                store.setLoading(false);
                return;
            }
            let comp = ret.filter(item => item.Name === store.user.Company);
            if (comp.length === 0) {
                setCompany({});
                store.setLoading(false);
                return;
            }
            setCompany(comp[0]);
            store.setLoading(false)
        })
    }
    function navClicked(e) {
        currPage(e.currentTarget.id);
    }
    if (mobile()) {
        return (
            <div>

            </div>
        );

    } else {
        return (
            <div style={{ width: '100%', height: '88%', overflow: 'scroll' }}>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', padding: '15px' }}>
                        <h1>Welcome {Company.Name || ''}</h1>
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '15px', height: '20%' }}>
                        {/* <Button className='AlkemiaBriefingBtn' id='Overview' onClick={navClicked}><h1>Sales</h1></Button> */}
                        <Button className='AlkemiaBriefingBtn' id='AlkemiaBriefing' onClick={navClicked}><h1>Alkemia Briefing</h1></Button>
                    </div>
                </div>
            </div>
        )
    }
})

export default Welcome;