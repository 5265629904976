import React from "react";
import { Line } from "react-chartjs-2";

function AionsESDFunnel({ data, dealClicked, graphDeals, id }) {
    let Deals = data;
    let New = [];
    let Proposal = [];
    let InitMeeting = [];
    let LikelyToClose = [];
    let Closed = [];

    let NewArr = graphDeals.TalkingApproached || [];
    let WorkingOnArr = graphDeals.WorkingOnProposalSent || [];
    let MeetingArr = graphDeals.Meeting || [];
    let LikelyToCloseArr = graphDeals.Likelytoclosethismonth || [];
    let closedArr = graphDeals.ClosedWon || [];

    Deals.New.forEach((item) => {
        switch (true) {
            case (item.properties.dealname.startsWith('Aions') || item.properties.dealname.startsWith('ESD')):
                if (item.properties.dealstage === 'talking' || item.properties.dealstage === 'new') {
                    item.properties.dealstage = 'Talking/Approached';
                }
                New.push(item);
                break;
        }
    })
    Deals.Proposal.forEach((item) => {
        switch (true) {
            case (item.properties.dealname.startsWith('Aions') || item.properties.dealname.startsWith('ESD')):
                if (item.properties.dealstage === 'initialProposalSent' || item.properties.dealstage === 'workingOnProposal') {
                    item.properties.dealstage = 'Working On/Proposal Sent';
                }
                Proposal.push(item);
                break;
        }
    })
    Deals.InitMeeting.forEach((item) => {
        switch (true) {
            case (item.properties.dealname.startsWith('Aions') || item.properties.dealname.startsWith('ESD')):
                if (item.properties.dealstage === 'initialMeeting') {
                    item.properties.dealstage = 'Meeting';
                }
                InitMeeting.push(item);
                break;
        }
    })
    Deals.CloseThisMonth.forEach((item) => {
        switch (true) {
            case (item.properties.dealname.startsWith('Aions') || item.properties.dealname.startsWith('ESD')):
                if (item.properties.dealstage === 'likelyToCloseThisMonth') {
                    item.properties.dealstage = 'Likely to close this month';
                }
                LikelyToClose.push(item);
                break;
        }
    })

    Deals.Closed.forEach((item) => {
        switch (true) {
            case (item.properties.dealname.startsWith('Aions') || item.properties.dealname.startsWith('ESD')):
                if (item.properties.dealstage === 'closedWon') {
                    item.properties.dealstage = 'Closed Won';
                }
                Closed.push(item);
                break;
        }
    })

    const formatDate = (inputDate) => {
        const date = new Date(inputDate);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        return `${monthNames[date.getMonth()]} -${date.getFullYear()}`;
    };

    return (
        <table id={id}>
            <thead>
                <tr>
                    <th style={{ backgroundColor: 'black', color: 'white', width: `${100 / 6}%`, height: '50px', border: '1px solid black' }}>Company</th>
                    <th style={{ backgroundColor: 'black', color: 'white', width: `${100 / 6}%`, height: '50px', border: '1px solid black' }}>Status</th>
                    <th style={{ backgroundColor: 'black', color: 'white', width: `${100 / 6}%`, height: '50px', border: '1px solid black' }}>Week/Month Started Engagement</th>
                    <th style={{ backgroundColor: 'black', color: 'white', width: `${100 / 6}%`, height: '50px', border: '1px solid black' }}>When likely to close</th>
                    <th style={{ backgroundColor: 'black', color: 'white', width: `${100 / 6}%`, height: '50px', border: '1px solid black' }}>Progress of engagement</th>
                    <th style={{ backgroundColor: 'black', color: 'white', width: `${100 / 6}%`, height: '50px', border: '1px solid black' }}>Estimated Value</th>
                </tr>
            </thead>
            <tbody>
                {New.map((item, index) => {
                    const includesString = NewArr.some(str => item.properties.dealname.includes(str));
                    const borderStyle = includesString ? '2px solid black' : '1px solid black';
                    return (
                        <tr key={index}>
                            <td style={{ backgroundColor: 'rgb(227, 54, 126)', color: 'black', width: `${100 / 6}%`, border: borderStyle, cursor: 'pointer' }} id={`${item.properties.dealstage.replaceAll(' ', '').replaceAll('/', '')}|${item.properties.dealname.replace(/(?:Aions(?: -)? ESD(?: -)?)|ESD/g, '')}`} onClick={dealClicked}>{item.properties.dealname || ''}</td>
                            <td style={{ backgroundColor: 'rgb(227, 54, 126)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >{item.properties.dealstage || ''}</td>
                            <td style={{ backgroundColor: 'rgb(227, 54, 126)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >{formatDate(item.createdAt)}</td>
                            <td style={{ backgroundColor: 'rgb(227, 54, 126)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >{formatDate(item.properties.closedate)}</td>
                            <td style={{ backgroundColor: 'rgb(227, 54, 126)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >{item.progressOfEngagement || ''}</td>
                            <td style={{ backgroundColor: 'rgb(227, 54, 126)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >R {Number(item.properties.amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || ''}</td>
                        </tr>
                    )
                })}
                {Proposal.map((item, index) => {

                    const includesString = WorkingOnArr.some(str => item.properties.dealname.includes(str));
                    const borderStyle = includesString ? '2px solid black' : '1px solid black'; return (
                        <tr key={index}>
                            <td style={{ backgroundColor: 'rgb(146, 140, 195)', color: 'black', width: `${100 / 6}%`, border: borderStyle, cursor: 'pointer' }} id={`${item.properties.dealstage.replaceAll(' ', '').replaceAll('/', '')}|${item.properties.dealname.replace(/(?:Aions(?: -)? ESD(?: -)?)|ESD/g, '')}`} onClick={dealClicked}>{item.properties.dealname || ''}</td>
                            <td style={{ backgroundColor: 'rgb(146, 140, 195)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >{item.properties.dealstage || ''}</td>
                            <td style={{ backgroundColor: 'rgb(146, 140, 195)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >{formatDate(item.createdAt)}</td>
                            <td style={{ backgroundColor: 'rgb(146, 140, 195)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >{formatDate(item.properties.closedate)}</td>
                            <td style={{ backgroundColor: 'rgb(146, 140, 195)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >{item.progressOfEngagement || ''}</td>
                            <td style={{ backgroundColor: 'rgb(146, 140, 195)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >R {Number(item.properties.amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || ''}</td>
                        </tr>
                    )
                })}
                {InitMeeting.map((item, index) => {
                    const includesString = MeetingArr.some(str => item.properties.dealname.includes(str));
                    const borderStyle = includesString ? '2px solid black' : '1px solid black';
                    return (
                        <tr key={index}>
                            <td style={{ backgroundColor: 'rgb(49, 192, 209)', color: 'black', width: `${100 / 6}%`, border: borderStyle, cursor: 'pointer' }} id={`${item.properties.dealstage.replaceAll(' ', '').replaceAll('/', '')}|${item.properties.dealname.replace(/(?:Aions(?: -)? ESD(?: -)?)|ESD/g, '')}`} onClick={dealClicked}>{item.properties.dealname || ''}</td>
                            <td style={{ backgroundColor: 'rgb(49, 192, 209)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >{item.properties.dealstage || ''}</td>
                            <td style={{ backgroundColor: 'rgb(49, 192, 209)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >{formatDate(item.createdAt)}</td>
                            <td style={{ backgroundColor: 'rgb(49, 192, 209)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >{formatDate(item.properties.closedate)}</td>
                            <td style={{ backgroundColor: 'rgb(49, 192, 209)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >{item.progressOfEngagement || ''}</td>
                            <td style={{ backgroundColor: 'rgb(49, 192, 209)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >R {Number(item.properties.amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || ''}</td>
                        </tr>
                    )
                })}
                {LikelyToClose.map((item, index) => {
                    const includesString = LikelyToCloseArr.some(str => item.properties.dealname.includes(str));
                    const borderStyle = includesString ? '2px solid black' : '1px solid black';
                    return (
                        <tr key={index}>
                            <td style={{ backgroundColor: 'rgb(255, 223, 0)', color: 'black', width: `${100 / 6}%`, border: borderStyle, cursor: 'pointer' }} id={`${item.properties.dealstage.replaceAll(' ', '').replaceAll('/', '')}|${item.properties.dealname.replace(/(?:Aions(?: -)? ESD(?: -)?)|ESD/g, '')}`} onClick={dealClicked}>{item.properties.dealname || ''}</td>
                            <td style={{ backgroundColor: 'rgb(255, 223, 0)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >{item.properties.dealstage || ''}</td>
                            <td style={{ backgroundColor: 'rgb(255, 223, 0)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >{formatDate(item.createdAt)}</td>
                            <td style={{ backgroundColor: 'rgb(255, 223, 0)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >{formatDate(item.properties.closedate)}</td>
                            <td style={{ backgroundColor: 'rgb(255, 223, 0)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >{item.progressOfEngagement || ''}</td>
                            <td style={{ backgroundColor: 'rgb(255, 223, 0)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >R {Number(item.properties.amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || ''}</td>
                        </tr>
                    )
                })}
                {Closed.map((item, index) => {
                    const includesString = closedArr.some(str => item.properties.dealname.includes(str));
                    const borderStyle = includesString ? '2px solid black' : '1px solid black';
                    return (
                        <tr key={index}>
                            <td style={{ backgroundColor: 'rgba(143,202,114,1)', color: 'black', width: `${100 / 6}%`, border: borderStyle, cursor: 'pointer' }} id={`${item.properties.dealstage.replaceAll(' ', '').replaceAll('/', '')}|${item.properties.dealname.replace(/(?:Aions(?: -)? ESD(?: -)?)|ESD/g, '')}`} onClick={dealClicked}>{item.properties.dealname || ''}</td>
                            <td style={{ backgroundColor: 'rgba(143,202,114,1)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >{item.properties.dealstage || ''}</td>
                            <td style={{ backgroundColor: 'rgba(143,202,114,1)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >{formatDate(item.createdAt)}</td>
                            <td style={{ backgroundColor: 'rgba(143,202,114,1)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >{formatDate(item.properties.closedate)}</td>
                            <td style={{ backgroundColor: 'rgba(143,202,114,1)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >{item.progressOfEngagement || ''}</td>
                            <td style={{ backgroundColor: 'rgba(143,202,114,1)', color: 'black', width: `${100 / 6}%`, border: borderStyle }} >R {Number(item.properties.amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || ''}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    );
}

export default AionsESDFunnel;
