import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import AllCourses from './AllCourses';
import CourseDetails from './CourseDetails';
import CreateCourse from './CreateCourse';
import CompCourse from './CompCourse';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { FaShareSquare } from 'react-icons/fa'
import { Card, Input, View, Button, MobileTabBarItem, MobileTabBar } from '../../components';
import isMobile from 'is-mobile';
import EditCourse from './EditCourse';


const Courses = observer(() => {
    //#region UseSates
    const store = useStore();
    const [, updateState] = useState();
    const [CurrentPage, setCurrentPage] = useState('AllCourses');
    const [SelectedCourseId, setSelectedCourseID] = useState('');
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [cID, setcID] = useState('')

    function navClicked(e, cid) {
        setcID(cid)
        setCurrentPage(e);
        forceUpdate()
    }

    function selectCourseID(id) {
        setSelectedCourseID(id);
    }

    //#endregion
    if (isMobile()) {
        return (
            <div>
                <div>
                    {(CurrentPage === 'CompCourses') ? <CompCourse CurrentPage={CurrentPage} navClicked={navClicked} CourseId={SelectedCourseId} /> : null}
                    {(CurrentPage === 'AllCourses') ? <AllCourses CurrentPage={CurrentPage} navClicked={navClicked} /> : null}
                    {(CurrentPage === 'EditCourse') ? <EditCourse CurrentPage={CurrentPage} navClicked={navClicked} CourseId={cID} /> : null}
                    {(CurrentPage === 'CourseDetails') ? <CourseDetails CurrentPage={CurrentPage} navClicked={navClicked} CourseId={cID} /> : null}
                    {(CurrentPage === 'CreateCourse') ? <CreateCourse CurrentPage={CurrentPage} navClicked={navClicked} /> : null}
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <div>
                    {(CurrentPage === 'CompCourses') ? <CompCourse CurrentPage={CurrentPage} navClicked={navClicked} CourseId={SelectedCourseId} /> : null}
                    {(CurrentPage === 'AllCourses') ? <AllCourses CurrentPage={CurrentPage} navClicked={navClicked} /> : null}
                    {(CurrentPage === 'EditCourse') ? <EditCourse CurrentPage={CurrentPage} navClicked={navClicked} CourseId={cID} /> : null}
                    {(CurrentPage === 'CourseDetails') ? <CourseDetails CurrentPage={CurrentPage} navClicked={navClicked} CourseId={cID} /> : null}
                    {(CurrentPage === 'CreateCourse') ? <CreateCourse CurrentPage={CurrentPage} navClicked={navClicked} /> : null}
                </div>
            </div>
        );
    }
});

export default Courses;