import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from "../../../stores/Store";
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Input, Button } from '../../../components';
var isMobile = require('is-mobile');

const EditCourse = observer(({ CurrentPage, navClicked, CourseId }) => {
    const store = useStore();
    const params = useParams();
    const [SelectedCourse, setSelectedCourse] = useState({});
    const [NewCourseName, setNewCourseName] = useState('');
    const [NewRequired, setNewRequired] = useState(false);
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState("");
    const [NewContent, setNewContent] = useState({
        Type: '',
        Content: ''
    });
    const [Quiz, setQuiz] = useState([]);
    const [QuizPage, setQuizPage] = useState(false);
    const [Questions, setQuestions] = useState({
        Question: '',
        OptionA: '',
        OptionB: '',
        OptionC: '',
        OptionD: '',
    });
    const [selectedOption, setSelectedOption] = useState("");

    useEffect(() => {
        loadCourse();
    }, []);

    function loadCourse() {
        let id = CourseId;
        store.setLoading(true);
        store.getCoursesByID(id).then((ret) => {
            setSelectedCourse(ret);
            setNewCourseName(ret.Name);
            setNewRequired(ret.Required);
            setNewContent(ret.Content[0]);
            if (ret.Quiz) {
                setQuiz(ret.Quiz);
                setQuizPage(true);
            } else {
                setQuiz([]);
                setQuizPage(false);
            }
            store.setLoading(false);
        });
    }
    function clearData() {
        setNewCourseName('');
        setNewContent({
            Type: '',
            Content: '',
        });
        setNewRequired(false);
    }
    function handleSelectQuestion(e) {
        const selectedIndex = e.target.value;
        setSelectedQuestionIndex(selectedIndex);

        if (selectedIndex === "new") {
            setQuestions({
                Question: '',
                OptionA: '',
                OptionB: '',
                OptionC: '',
                OptionD: '',
            });
            setSelectedOption("");
        } else if (selectedIndex !== "") {
            const selectedQuestion = Quiz[selectedIndex];
            setQuestions({
                Question: selectedQuestion.Question,
                OptionA: selectedQuestion.OptionA,
                OptionB: selectedQuestion.OptionB,
                OptionC: selectedQuestion.OptionC,
                OptionD: selectedQuestion.OptionD,
            });
            setSelectedOption(selectedQuestion.CorrectAnswer);
        } else {
            setQuestions({
                Question: '',
                OptionA: '',
                OptionB: '',
                OptionC: '',
                OptionD: '',
            });
            setSelectedOption("");
        }
    }
    function newCourseNameChanged(e) {
        setNewCourseName(e.target.value);
    }
    function handleTypeChange(e) {
        const newType = e.target.value;
        setNewContent(prevContent => ({
            ...prevContent,
            Type: newType
        }));
    }
    function handleCheckboxChange(checked) {
        setNewRequired(checked);
    }
    function handleChange(event) {
        const checked = event.target.checked;
        handleCheckboxChange(checked);
    }
    function quizValues(e) {
        const { id, value } = e.target;

        setQuestions(prevQuestions => ({
            ...prevQuestions,
            [id]: value
        }));
    }
    function handleSelectChange(e) {
        setSelectedOption(e.target.value);
    }
    function submitQuiz() {
        if (!Questions.Question.trim()) {
            window.alert('Please enter a question');
            return;
        }
        if (!selectedOption) {
            window.alert('Please select the correct answer');
            return;
        }

        let updatedQuiz = [...Quiz];

        if (selectedQuestionIndex === "new") {
            const newQuestion = {
                Question: Questions.Question,
                OptionA: Questions.OptionA,
                OptionB: Questions.OptionB,
                OptionC: Questions.OptionC,
                OptionD: Questions.OptionD,
                CorrectAnswer: Questions[selectedOption],
            };
            updatedQuiz.push(newQuestion);
        } else if (selectedQuestionIndex !== "") {
            const editedQuestion = {
                Question: Questions.Question,
                OptionA: Questions.OptionA,
                OptionB: Questions.OptionB,
                OptionC: Questions.OptionC,
                OptionD: Questions.OptionD,
                CorrectAnswer: Questions[selectedOption],
            };
            updatedQuiz[selectedQuestionIndex] = editedQuestion;
        }

        setQuiz(updatedQuiz);
        setQuestions({
            Question: '',
            OptionA: '',
            OptionB: '',
            OptionC: '',
            OptionD: '',
        });
        setSelectedOption("");
        setSelectedQuestionIndex("");
    }
    function handleContentChange(e) {
        const newContent = e.target.value;
        setNewContent(prevContent => ({
            ...prevContent,
            Content: newContent
        }));
    }
    function updateCourseClicked() {
        let id = CourseId;
        if (NewCourseName === '') {
            window.alert('Please enter your Course Name');
            return;
        }
        if (NewContent.Type === '') {
            window.alert('Please select your Content Type');
            return;
        }
        let data = {
            Name: NewCourseName,
            Content: [
                {
                    Type: NewContent.Type,
                    Content: NewContent.Content
                }
            ],
            Required: NewRequired,
            RequiredBy: ''
        };

        if (NewContent.Type === "Quiz") {
            data.Quiz = Quiz;
        }

        store.setLoading(true);
        store.updateCourseById(id, data)
            .then((ret) => {
                if (!ret) {
                    window.alert('Course Updated successfully');
                    store.setLoading(false);
                    clearData();
                } else {
                    store.setLoading(false);
                }
            })
            .catch((error) => {
                console.error('API error:', error);
                store.setLoading(false);
            });
    }
    function handleCancel() {
        navClicked('AllCourses');
    }
    function renderQuestions() {
        return Quiz.map((question, index) => (
            <div key={index}>
                <h4>Question {index + 1}</h4>
                <p>{question.Question}</p>
                <input type="radio" name={`option_${index}`} value="A" />
                {question.OptionA}
                <input type="radio" name={`option_${index}`} value="B" />
                {question.OptionB}
                <input type="radio" name={`option_${index}`} value="C" />
                {question.OptionC}
                <input type="radio" name={`option_${index}`} value="D" />
                {question.OptionD}
            </div>
        ));
    }

    if (!isMobile()) {
        return (
            <div className='createCourse'>
                <Input className='courseInputName' onChange={newCourseNameChanged} value={NewCourseName} placeholder={SelectedCourse.Name} />
                <Button className='newCourseCancelBtn' id={CurrentPage} onClick={handleCancel}>Cancel</Button>
                <div className='requiredField'>
                    <p>Required</p><input type='checkbox' id='required' onChange={handleChange} checked={NewRequired} />
                </div>
                <select className='courseInputSelect' id='type' onChange={handleTypeChange} value={String(NewContent.Type)}>
                    <option>{NewContent.Type}</option>
                    <option value='Paragraph'>Paragraph</option>
                    <option value='Video'>Video</option>
                    <option value='Quiz'>Quiz</option>
                </select>
                <textarea className='courseInputSelectContent' placeholder={NewContent.Content} id='content' onChange={handleContentChange} value={(NewContent.Content).toString()} />
                <Button className='newCourseBtnAdd' onClick={updateCourseClicked}>Update Section</Button>
                {NewContent.Type === "Quiz" && (
                    <div className='Quiz'>
                        <div className='createQuizContentDiv'>
                            {QuizPage && (
                                <Card className='addQuizContent'>
                                    <h3>Edit Quiz Question</h3>
                                    <select className='questionDropdown' value={selectedQuestionIndex} onChange={handleSelectQuestion}>
                                        <option value="">Select an option to update</option>
                                        {Quiz.map((question, index) => (
                                            <option key={index} value={index}>Question {index + 1}</option>
                                        ))}
                                        <option value="new">Create a new question</option>
                                    </select>
                                    <Input id='Question' value={Questions.Question} onChange={quizValues} className="quizInputs" placeholder="Questions" />
                                    <Input id='OptionA' value={Questions.OptionA} onChange={quizValues} className="quizInputs" placeholder='Option A' />
                                    <Input id='OptionB' value={Questions.OptionB} onChange={quizValues} className="quizInputs" placeholder='Option B' />
                                    <Input id='OptionC' value={Questions.OptionC} onChange={quizValues} className="quizInputs" placeholder='Option C' />
                                    <Input id='OptionD' value={Questions.OptionD} onChange={quizValues} className="quizInputs" placeholder='Option D' />
                                    <select className="creditSelect" value={selectedOption} onChange={handleSelectChange}>
                                        <option>Select correct answer</option>
                                        <option value='OptionA'>Option A</option>
                                        <option value='OptionB'>Option B</option>
                                        <option value='OptionC'>Option C</option>
                                        <option value='OptionD'>Option D</option>
                                    </select>
                                    <Button onClick={submitQuiz}>Submit Question</Button>
                                </Card>
                            )}
                        </div>
                        <div className='createQuizContentDiv'>
                            <Card className='viewQuizContent'>
                                {renderQuestions()}
                            </Card>
                        </div>
                    </div>
                )}
            </div>
        );
    } else {
        return (
            <div className='createCourse'>
                <Input className='courseInputName' onChange={newCourseNameChanged} value={NewCourseName} placeholder={SelectedCourse.Name} />
                <Button className='newCourseCancelBtn' id={CurrentPage} onClick={handleCancel}>Cancel</Button>
                <div className='requiredField'>
                    <p>Required</p><input type='checkbox' id='required' onChange={handleChange} checked={NewRequired} />
                </div>
                <select className='courseInputSelect' id='type' onChange={handleTypeChange} value={String(NewContent.Type)}>
                    <option>{NewContent.Type}</option>
                    <option value='Paragraph'>Paragraph</option>
                    <option value='Video'>Video</option>
                    <option value='Quiz'>Quiz</option>
                </select>
                <textarea className='courseInputSelectContent' placeholder={NewContent.Content} id='content' onChange={handleContentChange} value={(NewContent.Content).toString()} />
                <Button className='newCourseBtnAdd' onClick={updateCourseClicked}>Update Section</Button>
                {NewContent.Type === "Quiz" && (
                    <div className='Quiz'>
                        <div className='createQuizContentDiv'>
                            {QuizPage && (
                                <Card className='addQuizContent'>
                                    <h3>Edit Quiz Question</h3>
                                    <select className='questionDropdown' value={selectedQuestionIndex} onChange={handleSelectQuestion}>
                                        <option value="">Select an option to update</option>
                                        {Quiz.map((question, index) => (
                                            <option key={index} value={index}>Question {index + 1}</option>
                                        ))}
                                        <option value="new">Create a new question</option>
                                    </select>
                                    <Input id='Question' value={Questions.Question} onChange={quizValues} className="quizInputs" placeholder="Questions" />
                                    <Input id='OptionA' value={Questions.OptionA} onChange={quizValues} className="quizInputs" placeholder='Option A' />
                                    <Input id='OptionB' value={Questions.OptionB} onChange={quizValues} className="quizInputs" placeholder='Option B' />
                                    <Input id='OptionC' value={Questions.OptionC} onChange={quizValues} className="quizInputs" placeholder='Option C' />
                                    <Input id='OptionD' value={Questions.OptionD} onChange={quizValues} className="quizInputs" placeholder='Option D' />
                                    <select className="creditSelect" value={selectedOption} onChange={handleSelectChange}>
                                        <option>Select correct answer</option>
                                        <option value='OptionA'>Option A</option>
                                        <option value='OptionB'>Option B</option>
                                        <option value='OptionC'>Option C</option>
                                        <option value='OptionD'>Option D</option>
                                    </select>
                                    <Button onClick={submitQuiz}>Submit Question</Button>
                                </Card>
                            )}
                        </div>
                        <div className='createQuizContentDiv'>
                            <Card className='viewQuizContent'>
                                {renderQuestions()}
                            </Card>
                        </div>
                    </div>
                )}
            </div>
        );
    }
});

export default EditCourse;