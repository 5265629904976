import React from 'react';
import { Bar } from 'react-chartjs-2';

const AEXSignUps = ({ Aex = [], id}) => {
    const obj = Aex.map(item => ({
        Label: item.Label,
        NumOfSignups: item.NumOfSignups,
    }));
    obj.sort((a, b) => new Date(a.Label) - new Date(b.Label));

    const labels = obj.map(item => item.Label);
    const data = obj.map(item => item.NumOfSignups);

    const options = {
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'AEX Monthly Signups',
            },
            datalabels: {
                display: true,
                color: 'black',
                font: {
                    weight: 'bold',
                },
                formatter: (value, context) => {
                    return value;
                },
            },
        },
        scales: {
            x: {
                display: true,
                position: 'bottom',
                labels,
            },
        },
        responsive: true,
    };

    const chartData = {
        labels,
        datasets: [
            {
                label: 'AEX Monthly Signups',
                data,
                backgroundColor: 'rgba(75,192,192,0.6)',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
                categoryPercentage: 1,
            },
        ],
    };

    return <Bar options={options} data={chartData} id={id}/>;
};

export default AEXSignUps;
