import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineController, LineElement } from 'chart.js';
import 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { LabelList } from 'recharts';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineController, LineElement);


const AionsIncome = ({ Aions = [], id }) => {
    const sortedLabels = [...new Set(Aions.map((item) => item.Label))]
        .sort((a, b) => {
            const dateA = new Date(a);
            const dateB = new Date(b);
            return dateA - dateB;
        });
    const alkemia = sortedLabels.map((label) => {
        const aions = Aions.find((item) => item.Label === label && item.Type === 'Actual');
        return aions ? Number(aions.Income.Alkemia).toFixed(2) : 0;
    });

    const aex = sortedLabels.map((label) => {
        const aions = Aions.find((item) => item.Label === label && item.Type === 'Actual');
        return aions ? Number(aions.Income.Aex).toFixed(2) : 0;
    });
    const interest = sortedLabels.map((label) => {
        const aions = Aions.find((item) => item.Label === label && item.Type === 'Actual');
        return aions ? Number(aions.Income.Interest).toFixed(2) : 0;
    });


    const alkemiaForecast = sortedLabels.map((label) => {
        const aions = Aions.find((item) => item.Label === label && item.Type === 'Forecast');
        return aions ? Number(aions.Income.AlkemiaForecast).toFixed(2) : 0;
    });
    const aexForecast = sortedLabels.map((label) => {
        const aions = Aions.find((item) => item.Label === label && item.Type === 'Forecast');
        return aions ? Number(aions.Income.AexForecast).toFixed(2) : 0;
    });
    const interestForecast = sortedLabels.map((label) => {
        const aions = Aions.find((item) => item.Label === label && item.Type === 'Forecast');
        return aions ? Number(aions.Income.InterestForecast).toFixed(2) : 0;
    });
 

    const filteredLabels = sortedLabels.filter((label) => {
        const hasAlkemiaForecast = Aions.some((item) => item.Label === label && item.Type === 'Forecast' && item.Income.AlkemiaForecast !== undefined);
        const hasAexForecast = Aions.some((item) => item.Label === label && item.Type === 'Forecast' && item.Income.AexForecast !== undefined);
        const hasInterestForecast = Aions.some((item) => item.Label === label && item.Type === 'Forecast' && item.Income.InterestForecast !== undefined);

        const hasAlkemiaActual = Aions.some((item) => item.Label === label && item.Type === 'Actual' && item.Income.Alkemia !== undefined);
        const hasAexActual = Aions.some((item) => item.Label === label && item.Type === 'Actual' && item.Income.Aex !== undefined);
        const hasInterestActual = Aions.some((item) => item.Label === label && item.Type === 'Actual' && item.Income.Interest !== undefined);

        return (hasAlkemiaForecast && hasAexForecast && hasInterestForecast) && !(hasAlkemiaActual || hasAexActual || hasInterestActual);
    });
    
    const options = {
        responsive: true,
        width: 800,
        height: 300,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Aions Income',
            },
            datalabels: {
                display: true,
                color: 'black',
                font: {
                    weight: 'bold',
                },
                formatter: (value) => value > 0 ? `R ${Number(value).toLocaleString()}` : '',
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            x2: {
                display: true,
                position: 'bottom',
                labels: sortedLabels.map((label) => filteredLabels.includes(label) ? 'Forecast' : 'Actual'),
                offset: true,
                grid: {
                    display: false,
                },
                ticks: {
                    display: true,
                },
            },
            y: {

            },
        },
    };

    const data = {
        labels: sortedLabels,
        datasets: [
            {
                label: 'Alkemia ',
                data: alkemia,
                backgroundColor: '#E4729F',
                borderWidth: 1,
                stack: 'stack1',
                categoryPercentage: 1,
            },
            {
                label: 'AEX',
                data: aex,
                backgroundColor: '#ADD1FA',
                borderWidth: 1,
                stack: 'stack1',
                categoryPercentage: 1,
            },
            {
                label: 'Interest',
                data: interest,
                backgroundColor: '#E65758',
                borderWidth: 1,
                stack: 'stack1',
                categoryPercentage: 1,
            },
            {
                label: 'Alkemia forecast',
                data: alkemiaForecast,
                backgroundColor: 'rgba(228, 114, 159, 0.6)',
                borderWidth: 1,
                stack: 'stack2',
                categoryPercentage: 1,
            },
            {
                label: 'AEX forecast',
                data: aexForecast,
                backgroundColor: 'rgba(173, 209, 250, 0.6)',
                borderWidth: 1,
                stack: 'stack2',
                categoryPercentage: 1,
            },
            {
                label: 'Interest forecast',
                data: interestForecast,
                backgroundColor: 'rgba(230, 87, 88, 0.6)',
                borderWidth: 1,
                stack: 'stack2',
                categoryPercentage: 1,
            },
        ],
    };

    return <Bar options={options} data={data} id={id} />;
};

export default AionsIncome;