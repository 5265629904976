import React from "react";
import "chartjs-plugin-datalabels";

function AdminSpiral({ Company, Start, End }) {
    let startDate = Start;
    let endDate = End;
    let comp = Company;
    let totalSalesTarget = 0;
    let totalSalesActuals = 0;
    let operatingExpensesTarget = 0;
    let operatingExpensesActuals = 0;
    let recurringRevenueTarget = 0;
    let recurringRevenueActuals = 0;
    let averageRevenuePerCustomerTarget = 0;
    let averageRevenuePerCustomerActuals = 0;
    let tempSpiral = [];

    const filteredActuals = comp.Actual.filter((actual) => {
        let actualDate = actual.Date;
        return actualDate >= startDate && actualDate <= endDate;
    });
    const filteredTargets = comp.Target.filter((target) => {
        let targetDate = target.Date;
        return targetDate >= startDate && targetDate <= endDate;
    });
    filteredActuals.forEach((actual) => {
        totalSalesActuals += (actual.TotalSales || 0);
        operatingExpensesActuals += (actual.OperatingExpenses || 0);
        recurringRevenueActuals += (actual.RecurringRevenue || 0);
        averageRevenuePerCustomerActuals += (actual.AverageRevenuePerCustomer || 0);
    });
    filteredTargets.forEach((target) => {
        totalSalesTarget += (target.TotalSales || 0);
        operatingExpensesTarget += (target.OperatingExpenses || 0);
        recurringRevenueTarget += (target.RecurringRevenue || 0);
        averageRevenuePerCustomerTarget += (target.AverageRevenuePerCustomer || 0);
    })
    tempSpiral.push({
        Name: comp.Name,
        Values: [
            { target: totalSalesTarget, actual: totalSalesActuals, color: 'rgba(227,54,126,1)', id: 'Total Sales' },
            { target: operatingExpensesTarget, actual: operatingExpensesActuals, color: 'rgba(146,140,195,1)', id: 'Operating Expenses' },
            { target: recurringRevenueTarget, actual: recurringRevenueActuals, color: 'rgba(49,192,209,1)', id: 'Recurring Revenue' },
            { target: averageRevenuePerCustomerTarget, actual: averageRevenuePerCustomerActuals, color: 'rgba(255, 223, 0, 1)', id: 'Average Revenue' }
        ]
    })
    return (
        <div>
            {tempSpiral.map((company, index) => {
                return (
                    <card key={index} className="companySvgCAnvas">
                        <h2>{company.Name}</h2>
                        <div style={{ display: "flex" }}>
                            <svg style={{ marginLeft: "0.1%" }} height="600" width="400">
                                {company.Values.map((data, dataIndex) => {
                                    const percentage = (data.actual / data.target) * 100;
                                    const radius = 160 - dataIndex * 40;
                                    const circumference = 2 * Math.PI * radius;
                                    const strokeDasharray = percentage >= 100 ? 'none' : circumference;
                                    const strokeDashoffset = percentage >= 100 ? 0 : circumference - (percentage / 100) * circumference;
                                    const startAngle = -90;
                                    const endAngle = startAngle + (360 * (percentage / 100));

                                    return (
                                        <circle
                                            key={dataIndex}
                                            cx="200"
                                            cy="300"
                                            r={radius}
                                            stroke={data.color}
                                            strokeWidth="5"
                                            fill="none"
                                            strokeDasharray={strokeDasharray}
                                            strokeDashoffset={strokeDashoffset}
                                            transform={`rotate(${startAngle} 200 300)`}
                                        />
                                    );
                                })}
                            </svg>
                            <div className="summary">
                                {company.Values.map((data, dataIndex) => {
                                    return (
                                        <div key={dataIndex} className="summary-item">
                                            <div className="color-dot" style={{ backgroundColor: data.color }}></div>
                                            <div style={{ height: "50%" }} className="summary-info">
                                                <p style={{ display: 'inline-block', marginRight: '10px', color: 'white' }}>{data.id}</p>
                                                <h2 style={{ marginBottom: '5px', color: 'white' }}>R{data.actual || 0}</h2>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </card>
                )
            })}
        </div>
    );
}

export default AdminSpiral;
