import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { useNavigate } from 'react-router-dom';
import './style.css';
import { Input, View, Button } from '../../components';
import AionsIcon from '../../assets/images/Aions.svg';
import { set } from 'mobx';
var mobile = require('is-mobile');

const General = observer(() => {
    //#region UseState
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Title, setTitle] = useState('Add a new General user')
    const [CurrentCreatorEmail, setCurrentCreatorEmail] = useState('')
    const [CreatorData, setCreatorData] = useState({
        Email: '',
        Password:''
    });
    //#endregion

    //#region functions
    useEffect(() => {
        checkCreatorInDB();
    }, []);
    function checkCreatorInDB(){
        let currentCreatorEmail = CurrentCreatorEmail;
        let title = Title;
        store.setLoading(true);
        store.getUserCreator().then((ret) => {
            if (ret){
                currentCreatorEmail = ret.creatorData.Email;
                title = 'Update General Info'

                setCurrentCreatorEmail(currentCreatorEmail)
                setCreatorData(ret);
                forceUpdate();
            }else{
                title = 'Add a new General Info'
            }
            setTitle(title);
        });
        store.setLoading(false);
    };
    function updatCreator() {
        if (CreatorData.Email === '') {
            window.alert('Please enter your email.');
            return
        }
        if (CreatorData.Password === '') {
            window.alert('Please enter your password.');
            return
        }
        let data = {
            Email:CreatorData.Email,
            Password: CreatorData.Password
        }
        store.setLoading(true);
        store.patchUserCreator(CurrentCreatorEmail,data).then((ret) => {
            if (ret){
                window.alert('User General Successfully Updated !');
            }else{
                window.alert('User General Unsuccessfully Updated !');
            }
            
        store.setLoading(false);
        });
        handleClear();
    };
    function handleClickSubmit (){
        if (CreatorData.Email === '') {
            window.alert('Please enter your email.');
            return
        }
        if (CreatorData.Password === '') {
            window.alert('Please enter your password.');
            return
        }
        let data = {
            Email:CreatorData.Email,
            Password: CreatorData.Password
        }
        store.setLoading(true);
        store.createUserCreator(data).then((ret) => {
            if (ret){
                window.alert('User creator Successfully added to database.!');
            }else{
                window.alert('User creator Unsuccessfully added to database.!');
            }
        });
        handleClear();
        checkCreatorInDB();
        store.setLoading(false);
        
    };
    function handleClear(){
        setCreatorData({
            Email: '',
            Password:''
        });
    };
    function getUserData(e){
        let userData = CreatorData;
     
        if(e.currentTarget.id === 'email'){
            userData.Email = (e.currentTarget.value);
        }
        if(e.currentTarget.id === 'password'){
            userData.Password = (e.currentTarget.value);
        }
        setCreatorData(userData);
        forceUpdate();
    };
    //#endregion
    return (
        <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
            <img className="Images" src={AionsIcon} />
            <h1>{Title}</h1>
            <Input placeholder='Email' id ='email' value={CreatorData.Email} type='text' onChange={getUserData} style={{ width: '500px' }} />
            <Input placeholder='Password' id='password' value={CreatorData.Password} type='password' style={{ width: '500px', marginBottom: '5px' }} onChange={getUserData} onKeyDown={(e) => { if (e && e.key && e.key === 'Enter') { e.preventDefault(); handleClickSubmit() } }} />
            {(Error === '') ? null : <p className='formError'>{Error}</p>}
            {(Title === 'Add a new General user')?
                <Button className='buttonStyle' onClick={handleClickSubmit}>Submit</Button>
                :
                <Button className='buttonStyle' onClick={updatCreator}>Update</Button>
            }
        </View>
    )
});

export default General;