import React from "react";
import { Line } from "react-chartjs-2";

function CompaniesGrowth({ comp }) {
    let Growthdata = comp.GrowthData;
    let values = Growthdata;
    const monthIndices = {
        January: 0,
        February: 1,
        March: 2,
        April: 3,
        May: 4,
        June: 5,
        July: 6,
        August: 7,
        September: 8,
        October: 9,
        November: 10,
        December: 11
    };
    let monthlyRevenue = values.Revenue;
    let growthRate = values.GrowthRate;
    let monthlyExpenses = values.Expenses;
    let Label = values.Label;

    const labels = [];
    const currentMonthIndex = new Date(Label).getMonth();
    for (let i = currentMonthIndex; i < currentMonthIndex + 60; i++) {
        const month = i % 12;
        const year = Math.floor(i / 12) + new Date(Label).getFullYear();

        labels.push(`${new Date(year, month, 1).toLocaleString('default', { month: 'long', year: 'numeric' })}`);
    }
    const revenueData = Array.from({ length: 60 }, (_, index) => {
        monthlyRevenue *= 1 + growthRate / 100;
        return monthlyRevenue.toFixed(2);
    });

    const expensesData = Array(60).fill(monthlyExpenses ? monthlyExpenses.toFixed(2) : 0);

    let Arr = [];
    labels.forEach((label, index) => {
        const monthIndex = monthIndices[label.split(" ")[0]];
        const Obj = {
            Amount: revenueData[index],
            Label: label
        };
        if (monthIndex === currentMonthIndex) {
            Arr.push(Obj);
        }
    });
    const myLabels = Arr
        .map((item) => item.Label)
        .filter((value, index, self) => {
            return self.indexOf(value) === index;
        })
        .sort((a, b) => {
            const dateA = new Date(a);
            const dateB = new Date(b);
            return dateA - dateB;
        });
    const amount = myLabels.map((label) => {
        const labelsData = Arr.find((item) => item.Label === label)
        return labelsData ? labelsData.Amount : 0;
    });
    const data = {
        labels: myLabels,
        datasets: [
            {
                label: "Monthly Revenue",
                data: amount,
                fill: false,
                borderColor: "rgb(115, 230, 0)",
                tension: 0.1
            },
            {
                label: "Monthly Expenses",
                data: expensesData,
                fill: false,
                borderColor: "rgb(255, 51, 0)",
                tension: 0.1
            },
        ]
    };

    const options = {
        plugins: {
            datalabels: {
                display: false
            }
        }
    };

    return (
        <Line data={data} options={options} />
    );
}

export default CompaniesGrowth;
