import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,useParams
} from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from './stores/Store';
import './App.css';
import { LoadingScreen} from './components';
import PrivateRouter from './privateRouter';
import Login from './pages/login';
import ViewCourse from './pages/Courses/ViewCourse';
import Dashboard from './pages/dashboard';
import EditCompanies from './pages/companies/EditCompanies';
import SignIn from './pages/signin';
import CompanyDashboard from './pages/companyDashboard';
import DepartmentDashboard from './pages/DepartmentDashboard';
import AdminDashboard from './pages/AdminDashboard';
import Investee from './pages/Investee';
import Act from './pages/Act'
import Handler from './pages/HandlerDashboard';
import Signup from './pages/Signup';
const App = observer(() => {
  const store = useStore();
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      store.setScreenWidth(screenWidth);
      store.setScreenHeight(screenHeight);
    };
    window.addEventListener('resize', handleResize);
  }, []);


  return (
    <Router>
      <div>
        <LoadingScreen />
        <Routes>
          <Route path="/dashboard" element={<PrivateRouter><Dashboard /></PrivateRouter>} />
          <Route path="/login" element={<Login />} />
          <Route path ='/signup'element={<Signup/>}/>
          <Route path="/act" element={<PrivateRouter><Act /></PrivateRouter>} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/handlerDashboard" element={<PrivateRouter><Handler /></PrivateRouter>} />
          {/* <Route path="/handlerDashboard" element={<Handler />} /> */}
          <Route path="/departmentDashboard" element={<PrivateRouter><DepartmentDashboard /></PrivateRouter>} />
          <Route path="/adminDashboard" element={<PrivateRouter><AdminDashboard /></PrivateRouter>} />
          <Route path="/companyDashboard" element={<PrivateRouter><CompanyDashboard /></PrivateRouter>} />
          <Route path="/editCompanies/:id" element={<PrivateRouter><EditCompanies /></PrivateRouter>} />
          <Route path="/investee" element={<PrivateRouter><Investee /></PrivateRouter>} />
          <Route path="/viewCourse/:id" element={<PrivateRouter><ViewCourse /></PrivateRouter>} />
          <Route path='/' element={<Navigate to='/signin' />} />
          <Route path='*' element={<Navigate to='/signin' />} />
        </Routes>
      </div>
    </Router>
  );
})

export default App;
