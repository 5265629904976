import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const RunwayGauge = ({ width, height, value }) => {
  const svgRef = useRef();
  const needleRadius = 4; // radius of the circular bottom part of the needle
  const needleLength = 40; // length of the needle from bottom to top
  
  // This function takes in a runway number in months
  // and returns a value between 0 and 1 for the gauge percent 
  const calculateGaugeValue = (months) => {
    if (months <= 5) {
        return 0.0;
    } else if (months >= 10) {
        return 1.0;
    } else {
        // Linearly interpolate between 0.0 and 1.0
        return (months - 1) / 10;
    }
  }

  const gaugeValue = calculateGaugeValue(value);
  // console.log(`Gauge value for ${value} months is: ${gaugeValue}`);

  useEffect(() => {
    const svg = d3.select(svgRef.current)
      // .attr('width', width)
      // .attr('height', height);
    svg.selectAll("*").remove();
    const radius = Math.min(width, height) / 2;
    const cornerRadius = 35;


    // Create a linear gradient
    const defs = svg.append("defs");
    const linearGradient = defs.append("linearGradient")
      .attr("id", "gradient")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "100%")
      .attr("y2", "0%");

    linearGradient.append("stop")
      .attr("offset", "0%")
      .attr("stop-color", "#7A8F9D");

    linearGradient.append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "#FEC051");

    // Draw the arc with rounded corners
    const arc = d3.arc()
      .innerRadius(radius - 20 - cornerRadius)
      .outerRadius(radius - cornerRadius)
      .cornerRadius(cornerRadius)
      .startAngle(-Math.PI / 2)
      .endAngle(Math.PI / 2);

    svg.append("path")
      .attr("d", arc)
      .attr("fill", "url(#gradient)")
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    // Draw the needle
    // Draw the circular bottom of the needle
    svg.append("circle")
    .attr("cx", width / 2)
    .attr("cy", height / 2)
    .attr("r", needleRadius + 1)
    .attr("fill", "#21303B");

    // Define the path for the triangular top of the needle
    const needlePath = `M${width / 2 - needleRadius},${height / 2}
      L${width / 2 + needleRadius},${height / 2}
      L${width / 2},${height / 2 - needleLength}
    Z`;

    // Draw the needle with round bottom and sharp top
    const needle = svg.append("path")
    .attr("d", needlePath)
    .attr("fill", "#21303B")
    .attr("stroke", "#21303B")
    .attr("stroke-width", 2);

    const angleScale = d3.scaleLinear()
      .domain([0, 1])
      .range([-Math.PI / 2, Math.PI / 2]);

    needle.attr('transform', `rotate(${angleScale(gaugeValue) * 180 / Math.PI}, ${width / 2}, ${height / 2})`);
  }, [width, height, value]);

  return (
    <svg ref={svgRef}></svg>
  );
};

export default RunwayGauge;
