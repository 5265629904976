import React, { useEffect, useState, updateState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { useNavigate } from 'react-router-dom';
import Alkemia from './Alkemia/Alkemia';
import Report from './Report/Report';
import './style.scss';
import { Input, View, Button, Card, DepartmentSidebar } from '../../components';
var mobile = require('is-mobile');

const Act = observer(() => {
    const store = useStore();
    const navigate = useNavigate();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [PageSelected, setPageSelected] = useState('Alkemia');
    const [IsHovered, setIsHovered] = useState(false);

    useEffect(() => {
    }, []);

    const handleHoverStart = () => {
        setIsHovered(true);
    };

    const handleHoverEnd = () => {
        setIsHovered(false);
    };
    function selectPage(e) {
        setPageSelected(e.currentTarget.id);
        forceUpdate();
    }
    function logout() {
        store.logout()
    }
    if (mobile()) {
        return (
            <View >
            </View>
        );
    } else {
        return (
            <div className='departmentHomePage' style={{ width: `${store.getScreenWidth()}px`, height: `${store.getScreenHeight()}px` }}>
                <div className='departmentSidebar' >
                </div>
                <DepartmentSidebar onMouseEnter={handleHoverStart} onMouseLeave={handleHoverEnd} Active={IsHovered} pageClicked={selectPage} pageSelected={PageSelected} logout={logout}/>
                <div className='departmentBody'>
                    {(PageSelected === 'Alkemia') ? <Alkemia/> : null}
                    {(PageSelected === 'Report') ? <Report/> : null}
                </div>
            </div>
        );
    }
});

export default Act;
