import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../stores/Store";
import "./style.css";
import { useNavigate } from "react-router-dom";
import {
    Card,
    Input,
    View,
    Button,
    MobileTabBar,
    MobileTabBarItem
} from "../../components";
import UpdateTargets from './EditCompanies/UpdateTargets';
import UpdateActuals from "./EditCompanies/UpdateActuals";
import Credits from "./EditCompanies/Credits";
import AddTargets from "./EditCompanies/AddTargets";
import EditCompanies from "./EditCompanies";
import { FaChevronRight, FaPlus, FaDatabase, FaAngleRight } from "react-icons/fa";
const isMobile = require("is-mobile");

const Companies = observer(() => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const navigate = useNavigate();
    const [ShowExistingCompanies, setShowExistingCompanies] = useState(true);
    const [Edit, setEdit] = useState('');
    const [EditTab, setEditTab] = useState("newTarget");
    const [EditCompany, setEditCompany] = useState(false);
    const [Companies, setCompanies] = useState([]);
    const [Name, setName] = useState("");
    const [InitialDate, setDate] = useState(new Date());
    const [EditActual, setEditActual] = useState(false);
    const [Company, setCompany] = useState({});
    const [IndexChange, setIndexChange] = useState(0);
    const [Count, setCount] = useState(0);
    const [NumberOfCredits, setNumberOfCredits] = useState([]);
    const [CreditMonth, setCreditMonth] = useState(0);
    const [CreditYear, setCreditYear] = useState(new Date().getFullYear());
    const [CreditAmount, setCreditAmount] = useState(0);
    const [CSVInputFile, setCSVInputFile] = useState('')
    const [AddNewTargets, setAddNewTargets] = useState('updateTargets');
    const [AddNewActuals, setAddNewActuals] = useState('updateActuals');
    const [CompID, setCompID] = useState('')

    const [CSVInputID, setCSVInputID] = useState('targetCsv')
    const [CurrentTab, setCurrentTab] = useState("existingCompanies");
    const [Metrics, setMetrics] = useState({
        MetricList: [
            "TotalSales",
            "NumberOfMeetings",
            "NewCustomers",
            "AverageRevenuePerCustomer",
            "OperatingExpenses",
            "TotalClients",
            "CostOfSales",
            "RecurringRevenue"
        ]
    })
    const [Target, setTarget] = useState({
        TotalSales: 0,
        NumberOfMeetings: 0,
        NewCustomers: 0,
        AverageRevenuePerCustomer: 0,
        OperatingExpenses: 0,
        TotalClients: 0,
        CostOfSales: 0,
        RecurringRevenue: 0,
        Date: new Date()
    });
    const [Actual, setActual] = useState({
        TotalSales: 0,
        NumberOfMeetings: 0,
        NewCustomers: 0,
        AverageRevenuePerCustomer: 0,
        OperatingExpenses: 0,
        RecurringRevenue: 0,
        TotalClients: 0,
        Date: new Date()
    });
    const [NewActual, setNewActual] = useState({
        TotalSales: 0,
        NumberOfMeetings: 0.0,
        NewCustomers: 0,
        AverageRevenuePerCustomer: 0,
        OperatingExpenses: 0,
        RecurringRevenue: 0,
        TotalClients: 0,
        CostOfSales: 0,
        Date: new Date()
    });
    const [NewTarget, setNewTarget] = useState({
        TotalSales: 0,
        NumberOfMeetings: 0,
        NewCustomers: 0,
        AverageRevenuePerCustomer: 0,
        OperatingExpenses: 0,
        RecurringRevenue: 0,
        TotalClients: 0,
        CostOfSales: 0,
        Date: new Date()
    });
    const monthsArr = Array.from({
        length: 12
    }, (_, index) => index);
    const monthsArray = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const yearArr = [
        new Date().getFullYear() - 1,
        new Date().getFullYear(),
        new Date().getFullYear() + 1,
    ];

    useEffect(() => {
        loadCompanies();
    }, []);
    function dateSelector(e) {
        if (e.target.value) {
            setIndexChange(e.target.value);
            setNewActual(Company.Actual[Number(e.target.value)]);
            forceUpdate();
        }

    }
    function tarDateSelector(e) {
        if (e.target.value) {
            setIndexChange(e.target.value);
            setNewTarget(Company.Target[Number(e.target.value)]);
            forceUpdate();
        }
    }
    function tabClicked(e) {
        store.setLoading(true);
        setEditTab(e.currentTarget.id);
        if (e.currentTarget.id === 'newTarget') {
            setCSVInputID('targetCsv');
            setNewActual({
                TotalSales: 0,
                NumberOfMeetings: 0,
                NewCustomers: 0,
                AverageRevenuePerCustomer: 0,
                OperatingExpenses: 0,
                RecurringRevenue: 0,
                TotalClients: 0,
                CostOfSales: 0,
                Date: 'YYYY-MM-DD HH:mm'
            })
            forceUpdate()
            store.setLoading(false);
        }
        if (e.currentTarget.id === 'editActual') {
            setCSVInputID('actualCsv');
            setNewTarget({
                TotalSales: 0,
                NumberOfMeetings: 0,
                NewCustomers: 0,
                AverageRevenuePerCustomer: 0,
                OperatingExpenses: 0,
                RecurringRevenue: 0,
                CostOfSales: 0,
                TotalClients: 0,
                Date: 'YYYY-MM-DD HH:mm'
            })
            forceUpdate()
            store.setLoading(false);
        } else {
            setNewActual({
                TotalSales: 0,
                NumberOfMeetings: 0,
                NewCustomers: 0,
                AverageRevenuePerCustomer: 0,
                OperatingExpenses: 0,
                RecurringRevenue: 0,
                CostOfSales: 0,
                TotalClients: 0,
                Date: 'YYYY-MM-DD HH:mm'
            });
            setNewTarget({
                TotalSales: 0,
                NumberOfMeetings: 0,
                NewCustomers: 0,
                AverageRevenuePerCustomer: 0,
                OperatingExpenses: 0,
                RecurringRevenue: 0,
                TotalClients: 0,
                CostOfSales: 0,
                Date: 'YYYY-MM-DD HH:mm'
            });
            forceUpdate()
            store.setLoading(false);
        }

    }
    function loadCompanies() {
        if (!store.user.Permissions.companies.view) {
            return;
        }
        store.setLoading(true);
        store.getCompanies().then((ret) => {
            if (typeof ret === "string" || !ret) {
                setCompanies([]);
                store.setLoading(false);
                return;
            }
            setCompanies(ret);
            store.setLoading(false);
        });
    }
    function nameChanged(e) {
        setName(e.target.value);
    }
    function initialDate(e) {
        if (e.currentTarget.id === "inDate") {
            setDate(e.target.value);
        }
    }
    function createCompanyClicked() {
        if (Name === "") {
            window.alert("Please enter company name!");
            return;
        }
        if (initialDate === "") {
            window.alert("Please input initial Date.!");
            return;
        }

        let newTarget = {
            TotalSales: (Target.TotalSales),
            NumberOfMeetings: (Target.NumberOfMeetings),
            CostOfSales: (Target.CostOfSales),
            NewCustomers: (Target.NewCustomers),
            AverageRevenuePerCustomer: (Target.AverageRevenuePerCustomer),
            OperatingExpenses: (Target.OperatingExpenses),
            RecurringRevenue: (Target.RecurringRevenue),
            TotalClients: (Target.TotalClients),
            Date: new Date()
        };
        let data = {
            Name: Name,
            Target: newTarget,
            Date: InitialDate,
            Config: Metrics
        };
        store.setLoading(true);
        store.createCompanies(data).then((ret) => {
            if (ret === true) {
                window.alert("Company created successfully");
                loadCompanies();
                setTarget({
                    TotalSales: 0,
                    NumberOfMeetings: 0,
                    NewCustomers: 0,
                    AverageRevenuePerCustomer: 0,
                    OperatingExpenses: 0,
                    CostOfSales: 0,
                    RecurringRevenue: 0,
                    TotalClients: 0

                });
                setName("");
                // TODO: Clear data from inputs
            } else {
                window.alert("Unable to create company");
                store.setLoading(false);
            }
        });
    }
    
    function updateEdit(e) {
        let compID = CompID
        compID = Companies[e.target.id]._id
        setCompID(compID);
        forceUpdate();
        navigate(`/editCompanies/${compID}`);
      
    }
    function updateAcutalsClicked() {
        let savedActuals = Company.Actual || [];
        let data = {};
        let name = Company.Name;
        if (CSVInputFile) {
            handleFileUpload()
            return
        }
        else {
            if (EditTab === "editActual") {
                for (let i = 0; i < savedActuals.length; i++) {
                    if (i === savedActuals.length - 1) {
                        savedActuals[IndexChange] = NewActual;

                        break;
                    }
                }
                data = {
                    Actual: NewActual
                };
            }
        }
        store.setLoading(true);
        store.updateCompaniesByName(data, name).then((ret) => {
            window.alert("Company updated Successfully.");
            setNewActual({
                TotalSales: 0,
                NumberOfMeetings: 0,
                NewCustomers: 0,
                AverageRevenuePerCustomer: 0,
                OperatingExpenses: 0,
                RecurringRevenue: 0,
                CostOfSales: 0,
                TotalClients: 0,
                Date: ""
            });
            store.setLoading(false);
        });
        handleClear();
        setEditCompany(false);
    }
    function updateTargetsClicked() {
        let savedTargets = Company.Target || [];
        let data = {};
        let name = Company.Name;
        if (CSVInputFile) {
            handleFileUpload()
            return
        } else {
            if (EditTab === "newTarget") {
                // for (let i = 0; i < savedTargets.length; i++) {
                //     if (i === savedTargets.length - 1) {
                //         savedTargets[IndexChange] = NewTarget;
                //         break;
                //     }
                // }
                savedTargets.push(NewTarget);
                data = {
                    Target: savedTargets
                };
            }
        } store.setLoading(true);
        store.updateCompaniesByName(data, name).then((ret) => {
            window.alert("Company updated successfully.");
            setNewTarget({
                TotalSales: 0,
                NumberOfMeetings: 0,
                NewCustomers: 0,
                AverageRevenuePerCustomer: 0,
                OperatingExpenses: 0,
                RecurringRevenue: 0,
                TotalClients: 0,
                CostOfSales: 0,
                Date: ""
            });
            store.setLoading(false);
        });
        handleClear();
        setEditCompany(false);
    }
    function TargetChanged(e) {
        let tar = Target;

        if (e.currentTarget.id === "targetTotalClients") {
            tar.TotalClients = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "targetCostOfsales") {
            tar.CostOfSales = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "targetTotalSales") {
            tar.TotalSales = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "targetNumberOfMeetings") {
            tar.NumberOfMeetings = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "targetNewCustomer") {
            tar.NewCustomers = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "targetAverageRevenuePerCustomer") {
            tar.AverageRevenuePerCustomer = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "targetOperatingExpenses") {
            tar.OperatingExpenses = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "targetMonthlyRevenue") {
            tar.RecurringRevenue = Number(Number(e.target.value));
        }
        setTarget(tar);
        forceUpdate();
    }
    function NewTargetChanged(e) {
        let tar = NewTarget;
        if (e.currentTarget.id === "targetTotalClients") {
            tar.TotalClients = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "targetTotalSales") {
            tar.TotalSales = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "targetCostOfSales") {
            tar.CostOfSales = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "targetnewDate") {
            tar.Date = e.target.value;
        }
        if (e.currentTarget.id === "targetNewCustomer") {
            tar.NewCustomers = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "targetAverageRevenuePerCustomer") {
            tar.AverageRevenuePerCustomer = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "targetOperatingExpenses") {
            tar.OperatingExpenses = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "targetMonthlyRevenue") {
            tar.RecurringRevenue = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "targetNumberOfMeetings") {
            tar.NumberOfMeetings = Number(Number(e.target.value));
        }
        setNewTarget(tar);
        forceUpdate();
    }
    function NewActualChange(e) {
        let act = NewActual;

        if (e.currentTarget.id === "actualTotalSales") {
            act.TotalSales = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "actualCostOfSales") {
            act.CostOfSales = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "actualTotalClients") {
            act.TotalClients = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "actualNumberOfMeetings") {
            act.NumberOfMeetings = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "actualNewCustomer") {
            act.NewCustomers = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "actualAverageRevenuePerCustomer") {
            act.AverageRevenuePerCustomer = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "actualOperatingExpenses") {
            act.OperatingExpenses = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "actualMonthlyRevenue") {
            act.RecurringRevenue = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "actualMonthlyRevenue") {
            act.RecurringRevenue = Number(Number(e.target.value));
        }
        setNewActual(act);
        forceUpdate();
    }
    function closeEdit() {
        setCount(0);
        setEdit(false);
        setEditCompany(false);
        setEditActual(false);
        setEditTab("newTarget");
        setNewActual({
            TotalSales: 0,
            TotalClients: 0,
            NumberOfMeetings: 0,
            NewCustomers: 0,
            AverageRevenuePerCustomer: 0,
            OperatingExpenses: 0,
            CostOfSales: 0,
            RecurringRevenue: 0,
            Date: ""
        });
        setNewTarget({
            TotalSales: 0,
            NumberOfMeetings: 0,
            NewCustomers: 0,
            AverageRevenuePerCustomer: 0,
            OperatingExpenses: 0,
            CostOfSales: 0,
            TotalClients: 0,
            RecurringRevenue: 0,
            Date: ""
        });
        setNumberOfCredits([]);
    }
    function handleClear() {
        setTarget({
            TotalSales: 0,
            NumberOfMeetings: 0,
            NewCustomers: 0,
            AverageRevenuePerCustomer: 0,
            OperatingExpenses: 0,
            RecurringRevenue: 0,
            CostOfSales: 0,
            TotalClients: 0,
            Date: ""
        });
        setNewTarget({
            TotalSales: 0,
            NumberOfMeetings: 0,
            NewCustomers: 0,
            CostOfSales: 0,
            AverageRevenuePerCustomer: 0,
            OperatingExpenses: 0,
            RecurringRevenue: 0,
            TotalClients: 0,
            Date: "YYYY-MM-DD"
        });
        setNewActual({
            TotalSales: 0,
            NumberOfMeetings: 0,
            NewCustomers: 0,
            AverageRevenuePerCustomer: 0,
            OperatingExpenses: 0,
            CostOfSales: 0,
            TotalClients: 0,
            RecurringRevenue: 0,
            Date: "YYYY-MM-DD"
        });
        setActual({
            TotalSales: 0,
            NumberOfMeetings: 0,
            NewCustomers: 0,
            AverageRevenuePerCustomer: 0,
            CostOfSales: 0,
            OperatingExpenses: 0,
            RecurringRevenue: 0,
            TotalClients: 0,
            Date: "YYYY-MM-DD"
        });
        setCount(0);
        setEditCompany(false);
        setEditActual(false);
        setEdit(false);
        forceUpdate();
    }
    function toggleShowExistingCompanies() {
        setShowExistingCompanies(!ShowExistingCompanies);
    }
    function updateCredits() {
        let data = {
            Credits: NumberOfCredits
        };
        let id = Company._id;
        store.setLoading(true);
        store.updateCompanyById(data, id).then((ret) => {
            window.alert("Credits updates successfully");
            closeEdit();
            loadCompanies();
        });
    }
    function newCreditChanged(e) {
        if (e.currentTarget.id === "creditMonth") {
            setCreditMonth(Number(e.target.value));
        }

        if (e.currentTarget.id === "creditYear") {
            setCreditYear(Number(e.target.value));
        }

        if (e.currentTarget.id === "creditAmount") {
            setCreditAmount(Number(e.target.value));
        }
    }
    function addNewCredits() {
        let creds = NumberOfCredits;
        let cred = {
            Month: CreditMonth,
            Year: CreditYear,
            Amount: CreditAmount
        };
        creds.push(cred);
        let numCredits = creds.sort((a, b) => {
            if (b.Year !== a.Year) {
                return b.Year - a.Year;
            } else {
                return b.Month - a.Month;
            }
        });
        setNumberOfCredits(numCredits);
        forceUpdate();
    }
    function deleteCredits(e) {
        let creds = NumberOfCredits;
        creds.splice(Number(e.currentTarget.id), 1);
        setNumberOfCredits(creds);
        forceUpdate();
    }
    function mobileTabClicked(e) {
        setCurrentTab(e.currentTarget.id);

    }
    function getCsvInputFile(e) {
        if (e.target.files === []) {
            return
        } else {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = function (event) {
                const csvData = event.target.result;
                const rows = csvData.split('\n');

                for (let i = 0; i < rows.length - 1; i++) {
                    if (i === 0) {
                        continue;
                    }
                    const row = rows[i];
                    const fields = row.split(',');
                    if (CSVInputID === 'targetCsv') {
                        setNewTarget({
                            TotalSales: fields[0],
                            NumberOfMeetings: fields[1],
                            NewCustomers: fields[2],
                            AverageRevenuePerCustomer: fields[3],
                            OperatingExpenses: fields[4],
                            RecurringRevenue: fields[5],
                            TotalClients: fields[6],
                            CostOfSales: fields[7],
                            Date: fields[8]
                        });
                    } else if (CSVInputID === 'actualCsv') {
                        setNewActual({
                            TotalSales: fields[0],
                            NumberOfMeetings: fields[1],
                            NewCustomers: fields[2],
                            AverageRevenuePerCustomer: fields[3],
                            OperatingExpenses: fields[4],
                            RecurringRevenue: fields[5],
                            TotalClients: fields[6],
                            CostOfSales: fields[7],
                            Date: fields[8]
                        });
                    }
                }
            };
            setCSVInputFile(e.target.files[0])
            forceUpdate();
            reader.readAsText(file);

        }
    }
    function handleFileUpload() {
        if (CSVInputFile) {
            store.setLoading(true);
            store.csvToArray(CSVInputFile, Company, CSVInputID).then((ret) => {
                if (ret) {
                    window.alert('File Successfully Uploaded...!')
                    store.setLoading(false);
                } else {
                    window.alert('File could not be uploaded...!')
                    store.setLoading(false);
                }
            });
        } else {
            window.alert('No file selected..!')
            store.setLoading(false);
        }
    }
    function toggleEditTarget(e) {
        setAddNewTargets(e.currentTarget.id);
        setNewTarget(
            {
                TotalSales: 0,
                NumberOfMeetings: 0,
                NewCustomers: 0,
                AverageRevenuePerCustomer: 0,
                OperatingExpenses: 0,
                RecurringRevenue: 0,
                TotalClients: 0,
                CostOfSales: 0,
                Date: ""
            }
        )
        forceUpdate(AddNewTargets);
    }


    if (isMobile()) {
        return (
            <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
                {
                    CurrentTab === "existingCompanies" ? (
                        <div style={
                            {
                                width: "100%",
                                height: "93%"
                            }
                        }>
                            <p className="userCardHeader">Existing Companies</p>
                            {
                                !ShowExistingCompanies ? null : (
                                    <> {
                                        Companies.map((Company, index) => {
                                            return (
                                                <div key={index}
                                                    className="existingUserDivMobile" onClick={updateEdit} id={index}>
                                                    <p>{
                                                        Company.Name || ""
                                                    }</p>
                                                    {
                                                        !store.user.Permissions.companies.edit ? null : (
                                                            <FaAngleRight className='editUserIcon' onClick={updateEdit}
                                                                id={index}>
                                                                Edit
                                                            </FaAngleRight>
                                                        )
                                                    } </div>
                                            );
                                        })
                                    }
                                        {" "} </>
                                )
                            } </div>
                    ) : null
                }
                {
                    Edit ? (
                        <Card className="updateTargetsMobile">
                            <div>
                                <div className="closeEditCard">
                                    <p id="x"
                                        onClick={closeEdit}>
                                        X
                                    </p>
                                </div>
                                <div style={
                                    {
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }
                                }>
                                    <p style={
                                        {
                                            fontSize: "20px",
                                            fontWeight: "bold"
                                        }
                                    }>
                                        {
                                            Company.Name
                                        } </p>
                                </div>
                                <div className="editDivMobile">
                                    <Card className="selectEditOptionMobile" id="newTarget"
                                        onClick={tabClicked}>
                                        <p id="newTarget">Target</p>
                                    </Card>
                                    <Card className="selectEditOptionMobile" id="editActual"
                                        onClick={tabClicked}>
                                        <p id="editActual">Actuals</p>
                                    </Card>
                                    <Card className="selectEditOptionMobile" id="credits"
                                        onClick={tabClicked}>
                                        <p id="credits">Credits</p>
                                    </Card>
                                </div>
                            </div>
                            {
                                EditTab === "newTarget" ? (
                                    <div className="editCardCenterMobile">
                                        <h1 className="targetHeader">Update Target Values </h1>
                                        <div className="chooseTarOptionMobile">
                                            <Card className="selectTrgetEditOptionMobile" id="AddNewTarget"
                                                onClick={toggleEditTarget}>
                                                <p id="newTarget">Add New</p><FaPlus></FaPlus>
                                            </Card>
                                            <Card className="selectTrgetEditOptionMobile" id="updateTargets"
                                                onClick={toggleEditTarget}>
                                                <p id="newTarget">Update Existing</p><FaDatabase></FaDatabase>
                                            </Card>
                                        </div>
                                        {
                                            AddNewTargets === 'updateTargets' ? (
                                                <>
                                                    <label className="uploadCvsLebelMobile">Upload CSV :</label>
                                                    <input type="file" id="targetCsv" className="csvFileInputMobile" accept=".csv"
                                                        onChange={getCsvInputFile} />

                                                    <div className="targets">
                                                        <p className="setPStyleMobile">Update Date :</p>
                                                    </div>
                                                    <select className="selectTargetDateMobile"
                                                        onChange={tarDateSelector}
                                                        id="optionClicked"
                                                        value={IndexChange}>
                                                        {
                                                            Company.Target.map((tar, index) => {
                                                                return (
                                                                    <option key={index}
                                                                        value={index}>
                                                                        {
                                                                            tar.Date
                                                                        } </option>
                                                                );
                                                            })
                                                        }</select></>
                                            ) : <>
                                                <p className="targetName">Select Date :</p>
                                                <Input type="Date" name="Date" id="updateDate" className='dateInputMobileNewTar'
                                                    value={
                                                        NewTarget.Date.toString()
                                                    }
                                                    onChange={NewTargetChanged} />
                                            </>}
                                        <div className="inputDiv">
                                            <p className="targetName">TotalSales :</p>
                                            <Input type="number" name="TotalSales" id="targetTotalSales"
                                                value={
                                                    NewTarget.TotalSales.toString()
                                                }
                                                onChange={NewTargetChanged} />
                                        </div>
                                        <div className="inputDiv">
                                            <p className="targetName">Number of Meetings :</p>
                                            <Input type="number" name="NumberOfMeetings" id="targetNumberOfMeetings"
                                                value={
                                                    NewTarget.NumberOfMeetings.toString()
                                                }
                                                onChange={NewTargetChanged} />
                                        </div>
                                        <div className="inputDiv">
                                            <p className="targetName">New Customer :</p>
                                            <Input type="number" name="NewCustomers" id="targetNewCustomer"
                                                value={
                                                    NewTarget.NewCustomers.toString()
                                                }
                                                onChange={NewTargetChanged} />
                                        </div>
                                        <div className="inputDiv">
                                            <p className="targetName">Average Revenue Per Customer :</p>
                                            <Input type="number" name="AverageRevenuePerCustomer" id="targetAverageRevenuePerCustomer"
                                                value={
                                                    NewTarget.AverageRevenuePerCustomer.toString()
                                                }
                                                onChange={NewTargetChanged} />
                                        </div>
                                        <div className="inputDiv">
                                            <p className="targetName">Operating Expenses :</p>
                                            <Input type="number" name="OperatingExpenses" id="targetOperatingExpenses"
                                                value={
                                                    NewTarget.OperatingExpenses.toString()
                                                }
                                                onChange={NewTargetChanged} />
                                        </div>
                                        <div className="inputDiv">
                                            <p className="targetName">Monthly Recurring Revenue :</p>
                                            <Input type="number" name="RecurringRevenue" id="targetMonthlyRevenue"
                                                value={
                                                    NewTarget.RecurringRevenue.toString()
                                                }
                                                onChange={NewTargetChanged} />
                                        </div>

                                        <div className="inputDiv">
                                            <p className="targetName">Total Clients:</p>
                                            <Input type="number" name="totalClients" id="targetTotalClients"
                                                value={
                                                    NewTarget.TotalClients.toString()
                                                }
                                                onChange={NewTargetChanged} />
                                        </div>

                                        <div className="inputDiv">
                                            <p className="targetName">Cost Of Sales :</p>
                                            <Input type="number" name="costOfSales" id="targetCostOfSales"
                                                value={
                                                    NewTarget.CostOfSales.toString()
                                                }
                                                onChange={NewTargetChanged} />
                                        </div>

                                        <div className="elementStylingForButtons">
                                            <Button className="buttonMobile"
                                                onClick={handleClear}>Cancel</Button>
                                            <Button className="buttonMobile"
                                                onClick={updateTargetsClicked}>Submit</Button>
                                        </div>
                                    </div>
                                ) : null
                            }

                            {
                                EditTab === "editActual" ? (
                                    <div className="editCardCenterMobile">
                                        <h1 className="targetHeader">Update Actual Values</h1>
                                        <label className="uploadCvsLebelMobile">Upload CSV :</label>
                                        <input type="file" id="actualCsv" className="csvFileInputMobile" accept=".csv"
                                            onChange={getCsvInputFile} />
                                        <div>
                                            <div className="targets">
                                                <p className="setPStyleMobile">Update Date :</p>
                                            </div>
                                            <select className="selectTargetDateMobile"
                                                onChange={dateSelector}
                                                id="optionClicked"
                                                value={IndexChange}>
                                                {
                                                    Company.Actual.map((act, index) => {
                                                        return (
                                                            <option key={index}
                                                                value={index}>
                                                                {
                                                                    act.Date
                                                                } </option>
                                                        );
                                                    })
                                                } </select>
                                            <div className="inputDiv">
                                                <p className="targetName">TotalSales :</p>
                                                <Input type="number" name="TotalSales" id="actualTotalSales"
                                                    value={
                                                        NewActual.TotalSales.toString()
                                                    }
                                                    onChange={NewActualChange} />
                                            </div>
                                            <div className="inputDiv">
                                                <p className="targetName">Number of Meetings :</p>
                                                <Input type="number" name="NumberOfMeetings" id="actualNumberOfMeetings"
                                                    value={
                                                        NewActual.NumberOfMeetings.toString()
                                                    }
                                                    onChange={NewActualChange} />
                                            </div>
                                            <div className="inputDiv">
                                                <p className="targetName">New Customer :</p>
                                                <Input type="number" name="NewCustomers" id="actualNewCustomer"
                                                    value={
                                                        NewActual.NewCustomers.toString()
                                                    }
                                                    onChange={NewActualChange} />
                                            </div>
                                            <div className="inputDiv">
                                                <p className="targetName">Average Revenue Per Customer :</p>
                                                <Input type="number" name="AverageRevenuePerCustomer" id="actualAverageRevenuePerCustomer"
                                                    value={
                                                        NewActual.AverageRevenuePerCustomer.toString()
                                                    }
                                                    onChange={NewActualChange} />
                                            </div>
                                            <div className="inputDiv">
                                                <p className="targetName">Operating Expenses :</p>
                                                <Input type="number" name="OperatingExpenses" id="actualOperatingExpenses"
                                                    value={
                                                        NewActual.OperatingExpenses.toString()
                                                    }
                                                    onChange={NewActualChange} />
                                            </div>
                                            <div className="inputDiv">
                                                <p className="targetName">Monthly Recurring Revenue :</p>
                                                <Input type="number" name="RecurringRevenue" id="actualMonthlyRevenue"
                                                    value={
                                                        NewActual.RecurringRevenue.toString()
                                                    }
                                                    onChange={NewActualChange} />
                                            </div>

                                            <div className="inputDiv">
                                                <p className="targetName">Total Clients:</p>
                                                <Input type="number" name="totalClients" id="actualTotalClients"
                                                    value={
                                                        NewActual.TotalClients.toString()
                                                    }
                                                    onChange={NewActualChange} />
                                            </div>

                                            <div className="inputDiv">
                                                <p className="targetName">Cost Of Sales :</p>
                                                <Input type="number" name="costOfSales" id="targetCostOfSales"
                                                    value={
                                                        NewTarget.CostOfSales.toString()
                                                    }
                                                    onChange={NewActualChange} />
                                            </div>
                                        </div>
                                        <div className="elementStylingForButtons">
                                            <Button className="buttonMobile"
                                                onClick={handleClear}>Cancel</Button>
                                            <Button className="buttonMobile"
                                                onClick={updateAcutalsClicked}>Submit</Button>
                                        </div>
                                    </div>
                                ) : null
                            }

                            {
                                EditTab === "credits" ? (
                                    <div className="editCardCenterMobile">
                                        <h1 className="targetHeader">Update Monthly Credits</h1>
                                        <p>
                                            Companies get a set amount of credits per month. All hours
                                            used within a month will be subtracted from the amount of
                                            credits.
                                        </p>
                                        <table className="creditsTable">
                                            <thead className="creditsTableHeader">
                                                <td className="creditsTableCol">Month</td>
                                                <td className="creditsTableCol">Year</td>
                                                <td className="creditsTableCol">Number of Credits</td>
                                            </thead>
                                            <tr className="creditsTableHeader">
                                                <td className="creditsTableCol">
                                                    <select className="creditSelect"
                                                        value={CreditMonth}
                                                        id="creditMonth"
                                                        onChange={newCreditChanged}>
                                                        {
                                                            monthsArr.map((month) => {
                                                                return (
                                                                    <option value={month}>
                                                                        {
                                                                            monthsArray[month]
                                                                        }</option>
                                                                );
                                                            })
                                                        } </select>
                                                </td>
                                                <td className="creditsTableCol">
                                                    <select className="creditSelect"
                                                        value={CreditYear}
                                                        id="creditYear"
                                                        onChange={newCreditChanged}>
                                                        {
                                                            yearArr.map((year) => {
                                                                return <option value={year}>
                                                                    {year}</option>;
                                                            })
                                                        } </select>
                                                </td>
                                                <td className="creditsTableCol">
                                                    <Input type="number"
                                                        value={
                                                            String(CreditAmount)
                                                        }
                                                        id="creditAmount"
                                                        onChange={newCreditChanged} />
                                                </td>
                                                <td className="creditsTableCol">
                                                    <Button onClick={addNewCredits}
                                                        style={
                                                            { width: "90%" }
                                                        }>
                                                        Add Credits
                                                    </Button>
                                                </td>
                                            </tr>
                                            {
                                                NumberOfCredits.map((creditObj, index) => {
                                                    return (
                                                        <tr className="creditsTableHeader">
                                                            <td className="creditsTableCol">
                                                                {
                                                                    monthsArray[creditObj.Month]
                                                                } </td>
                                                            <td className="creditsTableCol">
                                                                {
                                                                    creditObj.Year
                                                                }</td>
                                                            <td className="creditsTableCol">
                                                                {
                                                                    creditObj.Amount
                                                                }</td>
                                                            <td className="creditsTableCol">
                                                                <Button id={index}
                                                                    onClick={deleteCredits}
                                                                    style={
                                                                        { width: "90%" }
                                                                    }>
                                                                    Delete Credits
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            } </table>
                                        <Button onClick={updateCredits}>Update Credits</Button>
                                    </div>
                                ) : null
                            } </Card>
                    ) : null
                }

                {
                    CurrentTab === "newCompany" ? (
                        <div style={
                            {
                                width: "100%",
                                height: "93%"
                            }
                        }>
                            {
                                !store.user.Permissions.companies.create ? null : (
                                    <div className='createNewCompanyCardMobile'>
                                        <div className="userCardHeaderDiv">
                                            <p className="userCardHeader">New Company</p>
                                            <Input placeholder="Name" className='mobileInputCompName' type="text"
                                                value={Name}
                                                onChange={nameChanged} />
                                        </div>
                                        <div className="targets">
                                            <p className="setPStyle">Initial Date :</p>
                                            <Input placeholder="InitialDate" type="date"
                                                value={InitialDate}
                                                onChange={initialDate}
                                                id="inDate" />
                                        </div>
                                        <div className="targets">
                                            <p className="setPStyle">TotalSales :</p>
                                            <Input type="number"
                                                value={
                                                    String(Target.TotalSales)
                                                }
                                                id="targetTotalSales"
                                                onChange={TargetChanged} />
                                        </div>

                                        <div className="targets">
                                            <p className="setPStyle">Number of Meetings :</p>
                                            <Input type="number"
                                                value={
                                                    String(Target.NumberOfMeetings)
                                                }
                                                id="targetNumberOfMeetings"
                                                onChange={TargetChanged} />
                                        </div>
                                        <div className="targets">
                                            <p className="setPStyle">New Customers :</p>
                                            <Input type="number"
                                                value={
                                                    String(Target.NewCustomers)
                                                }
                                                id="targetNewCustomer"
                                                onChange={TargetChanged} />
                                        </div>
                                        <div className="targets">
                                            <p className="setPStyle">Average Revenue per customer :</p>
                                            <Input type="number"
                                                value={
                                                    String(Target.AverageRevenuePerCustomer)
                                                }
                                                id="targetAverageRevenuePerCustomer"
                                                onChange={TargetChanged} />
                                        </div>
                                        <div className="targets">
                                            <p className="setPStyle">Operating Expenses :</p>
                                            <Input type="number"
                                                value={
                                                    String(Target.OperatingExpenses)
                                                }
                                                id="targetOperatingExpenses"
                                                onChange={TargetChanged} />
                                        </div>
                                        <div className="targets">
                                            <p className="setPStyle">Monthly Recurring Revenue :</p>
                                            <Input type="number"
                                                value={
                                                    String(Target.RecurringRevenue)
                                                }
                                                id="targetMonthlyRevenue"
                                                onChange={TargetChanged} />
                                        </div>
                                        <div className="targets">
                                            <p className="setPStyle">Total Clients :</p>
                                            <Input type="number"
                                                value={
                                                    String(Target.TotalClients)
                                                }
                                                id="totalClients"
                                                onChange={TargetChanged} />
                                        </div>
                                        <div className="targets">
                                            <p className="setPStyle">Cost Of Sales:</p>
                                            <Input type="number"
                                                value={
                                                    Target.CostOfSales.toString()
                                                }
                                                id="targetCostOfSales"
                                                onChange={TargetChanged} />
                                        </div>

                                        <Button className="btnCreateUser"
                                            onClick={createCompanyClicked}>
                                            Create Company
                                        </Button>
                                    </div>
                                )
                            } </div>
                    ) : null
                }

                <MobileTabBar className='mobileTabBarDiv'>
                    <MobileTabBarItem description="Existing" icon="FaBuilding"
                        numItems={2}
                        id="existingCompanies"
                        onClick={mobileTabClicked}
                        isActive={
                            CurrentTab === "existingCompanies"
                        } />
                    <MobileTabBarItem description="New" icon="FaPlus"
                        numItems={2}
                        id="newCompany"
                        onClick={mobileTabClicked}
                        isActive={
                            CurrentTab === "newCompany"
                        } />
                </MobileTabBar>
            </div>
        );
    } else {
        return (
            <div style={
                {
                    height: "100%",
                    overflow: "scroll"
                }
            }>
                {
                    !store.user.Permissions.companies.view ? null /*Existing Companies */ : (
                        <Card className="newUserCard">
                            <div style={
                                {
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%"
                                }
                            }>
                                <p className="userCardHeader">Existing Companies</p>
                                <FaChevronRight className={
                                    `showSectionChevron ${ShowExistingCompanies ? "showSectionChevronShow" : ""
                                    }`
                                }
                                    onClick={toggleShowExistingCompanies} />
                            </div>
                            {!ShowExistingCompanies ? null : (
                                <> {
                                    Companies.map((Company, index) => {
                                        return (
                                            <div key={index} className="existingUserDiv">
                                                <p>{Company.Name || ""}</p>
                                                {!store.user.Permissions.companies.edit ? null : (
                                                    <Button onClick={updateEdit} id={index}>Edit </Button>
                                                )
                                                }</div>
                                        );
                                    })
                                }
                                </>
                            )
                            } </Card>
                    )
                }
                {
                    !store.user.Permissions.companies.create ? null : (
                        <Card className="newUserCard">
                            <div className="userCardHeaderDiv">
                                <p className="userCardHeader">New Company</p>
                            </div>
                            <Input placeholder="Name" type="text"
                                value={Name}
                                onChange={nameChanged} />
                            <div className="targets">
                                <p className="setPStyle">Initial Date :</p>
                            </div>

                            <Input placeholder="InitialDate" type="date"
                                value={InitialDate}
                                onChange={initialDate}
                                id="inDate" />

                            <div className="targets">
                                <p className="setPStyle">TotalSales :</p>
                            </div>
                            <Input type="number"
                                value={
                                    String(Target.TotalSales)
                                }
                                id="targetTotalSales"
                                onChange={TargetChanged} />
                            <div className="targets">
                                <p className="setPStyle">Number of Meetings :</p>
                            </div>
                            <Input type="number"
                                value={
                                    String(Target.NumberOfMeetings)
                                }
                                id="targetNumberOfMeetings"
                                onChange={TargetChanged} />
                            <div className="targets">
                                <p className="setPStyle">New Customers :</p>
                            </div>
                            <Input type="number"
                                value={
                                    String(Target.NewCustomers)
                                }
                                id="targetNewCustomer"
                                onChange={TargetChanged} />
                            <div className="targets">
                                <p className="setPStyle">Average Revenue per customer :</p>
                            </div>
                            <Input type="number"
                                value={
                                    String(Target.AverageRevenuePerCustomer)
                                }
                                id="targetAverageRevenuePerCustomer"
                                onChange={TargetChanged} />
                            <div className="targets">
                                <p className="setPStyle">Operating Expenses :</p>
                            </div>
                            <Input type="number"
                                value={
                                    String(Target.OperatingExpenses)
                                }
                                id="targetOperatingExpenses"
                                onChange={TargetChanged} />

                            <div className="targets">
                                <p className="setPStyle">Monthly Recurring Revenue :</p>
                            </div>
                            <Input type="number"
                                value={
                                    String(Target.RecurringRevenue)
                                }
                                id="targetMonthlyRevenue"
                                onChange={TargetChanged} />

                            <div className="targets">
                                <p className="setPStyle">Total Clients :</p>
                            </div>
                            <Input type="number"
                                value={
                                    String(Target.TotalClients)
                                }
                                id="targetTotalClients"
                                onChange={TargetChanged} />

                            <div className="targets">
                                <p className="setPStyle">Cost Of Sales :</p>
                            </div>
                            <Input type="number"
                                value={
                                    Target.CostOfSales.toString()
                                }
                                id="targetCostOfsales"
                                onChange={TargetChanged} />

                            <Button className="btnCreateUser"
                                onClick={createCompanyClicked}>
                                Create Company
                            </Button>
                        </Card>
                    )
                }
                <div>
                    {(Edit === 'EditCompanies') ? <EditCompanies  compID={CompID}/> : null}
                    
                </div>
                
            </div>
        );
    }
});
export default Companies;