import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import './style.css'

const LoadingScreen = observer(() =>{
    const store = useStore();
    let { isLoading } = store;
    if (!isLoading){
        return null
    }else {
        return (
            <div className='LoadingScreen'>
                <div className='Loading'>
                    <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
            </div>
        )
    }

    
})

LoadingScreen.propTypes = {
    
}

export default LoadingScreen;