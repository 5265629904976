import React from 'react';
import "./style.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BarGraph({ barData }) {
  let barLabels = [];
  let expTarData =[];
  let incActData = [];
  let gridColor = '';
  let topLabels ={
    Actuals:'',
    Targets:''
  }
  let padding={
    paddingL:'',
    paddingR:'',
    paddingT:'',
    paddingB:'',
  }
  if (barData.type === 'sales') {
    padding.paddingL = 100;
    padding.paddingR =100;
    padding.paddingB = 15;
    padding.paddingT = 5;
    barLabels=[''];
    gridColor='rgb(40, 44, 52)';
    expTarData.push(barData.NumOfTarMeets);
    incActData.push (barData.NumOfActMeets);
    topLabels.Actuals= 'Actuals';
    topLabels.Targets= 'Targets';
  }
  if (barData.type === 'finance') {
    padding.paddingL = -5;
    padding.paddingR =1;
    padding.paddingB = 1;
    padding.paddingT = 1;
    gridColor='white';
    barLabels=barData.labels;
    expTarData=barData.Expenses;
    incActData= barData.Income;
    topLabels.Actuals= 'Income';
    topLabels.Targets= 'Expenses';
  }

  if (barLabels.length> 6){
    barLabels = barLabels.slice(-6);
    expTarData = expTarData.slice(-6);
    incActData = incActData.slice(-6);
  }
  const labels = barLabels;
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: topLabels.Targets+ ' Vs '+topLabels.Actuals,
      },
    },
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: padding.paddingL,
        right: padding.paddingR,
        top: padding.paddingT,
        bottom: padding.paddingB,
      },
    },
    scales: {
      x: {
        ticks: {
          autoSkip: false,
          maxRotation: 0, // Set maxRotation to 0 for horizontal labels
          minRotation: 0, // Set minRotation to 0 for horizontal labels
        },
        grid: {
          color: gridColor, // Set grid color to white
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: gridColor, // Set grid color to white
        },
      },
    },
  };
  

  const data = {
    labels,
    datasets: [
      {
        label: topLabels.Targets,
        data: expTarData,
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label:topLabels.Actuals,
        data: incActData,
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  return (
    <div>
      <Bar className='descriptionDiv' options={options} data={data} />
    </div>
  );
}

export default BarGraph;