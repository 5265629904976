import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { useNavigate } from 'react-router-dom';
import {
    Card,
    Input,
    View,
    Button,
    MobileTabBarItem,
    MobileTabBar
} from '../../../../components';
import isMobile from 'is-mobile';

const Credits = observer(({ SelectedCompanyId }) => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const navigate = useNavigate();
    const [Companies, setCompanies] = useState([]);
    const [NumberOfCredits, setNumberOfCredits] = useState([]);
    const [MappedCredits, setMappedCredits] = useState([]);
    const [CreditMonth, setCreditMonth] = useState(0);
    const [CreditYear, setCreditYear] = useState(new Date().getFullYear());
    const [CreditAmount, setCreditAmount] = useState(0);
    const [Sales, setSales] = useState("");
    const [Dev, setDev] = useState("");
    const [Design, setDesign] = useState("");
    const monthsArr = Array.from({
        length: 12
    }, (_, index) => index);
    const monthsArray = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const yearArr = [
        new Date().getFullYear() - 1,
        new Date().getFullYear(),
        new Date().getFullYear() + 1,
    ];

    useEffect(() => {
        selectedCompany();
    }, []);

    function selectedCompany() {
        store.setLoading(true);
        store.getCompanyByID(SelectedCompanyId).then((ret) => {
            if (typeof ret === "string" || !ret) {
                setCompanies([]);
                store.setLoading(false);
                return;
            }
            setCompanies(ret);
            if (ret.Credits.length > 0) {
                getCreditsOnLoad(ret)
            }
            store.setLoading(false);
        });
        forceUpdate();
    }
    function updateCredits() {
        let id = Companies._id;
        let data = {
            Credits: NumberOfCredits
        };
        store.setLoading(true);
        store.updateCompanyById(id, data).then((ret) => {
            if (ret) {
                store.updateCompanyById(id, data).then((retObj) => {
                    if (retObj) {
                        window.alert("Credits have been updated!");
                        store.setLoading(false);
                    }
                });
            } else {
                window.alert("Error cant update company!");
                store.setLoading(false);
            }
        })
    }
    function newCreditChanged(e) {
        if (e.currentTarget.id === "Sales") {
            setSales(Number(e.currentTarget.value));
        }
        if (e.currentTarget.id === "Dev") {
            setDev(Number(e.currentTarget.value));
        }

        if (e.currentTarget.id === "Design") {
            setDesign(Number(e.currentTarget.value));
        }

        if (e.currentTarget.id === "creditMonth") {
            setCreditMonth(Number(e.target.value));
        }

        if (e.currentTarget.id === "creditYear") {
            setCreditYear(Number(e.target.value));
        }

        if (e.currentTarget.id === "creditAmount") {
            setCreditAmount(Number(e.target.value));
        }
        forceUpdate();
    }
    function addNewCredits() {
        if (Sales === '') {
            window.alert('Please input sales credits.!')
            return;
        } else if (Sales < 0) {
            window.alert('Sales can not have a negative value.!')
            return;
        }
        if (Dev === '') {
            window.alert('Please input Dev credits.!')
            return;
        } else if (Dev < 0) {
            window.alert('Dev can not have a negative value.!')
            return;
        }
        if (Design === '') {
            window.alert('Please input Design credits.!')
            return;
        } else if (Design < 0) {
            window.alert('Design can not have a negative value.!')
            return;
        }
        let creds = NumberOfCredits;
        let Credits = {
            Sales: Sales,
            Dev: Dev,
            Design: Design,
        }
        let cred = {
            Departments: Credits,
            Month: CreditMonth,
            Year: CreditYear,
        };
        creds.push(cred);
        let numCredits = creds.sort((a, b) => {
            if (b.Year !== a.Year) {
                return b.Year - a.Year;
            } else {
                return b.Month - a.Month;
            }
        });
        setNumberOfCredits(numCredits);
        forceUpdate();
    }
    function getCreditsOnLoad(ret) {
        let creds = ret.Credits;
        let semiFinalCreds = [];

        let credData = creds.filter(item => item.Departments && item.Departments.Sales !== undefined);

        for (let i = 0; i < credData.length; i++) {
            let Credits = {
                Sales: credData[i].Departments && credData[i].Departments.Sales !== undefined ? credData[i].Departments.Sales : 0,
                Dev: credData[i].Departments && credData[i].Departments.Dev !== undefined ? credData[i].Departments.Dev : 0,
                Design: credData[i].Departments && credData[i].Departments.Design !== undefined ? credData[i].Departments.Design : 0,
            };

            let cred = {
                Departments: Credits,
                Month: credData[i].Month,
                Year: credData[i].Year,
            };
            semiFinalCreds.push(cred);
        }

        let numCredits = semiFinalCreds.sort((a, b) => {
            if (b.Year !== a.Year) {
                return b.Year - a.Year;
            } else {
                return b.Month - a.Month;
            }
        });

        setNumberOfCredits(numCredits);
    }

    function deleteCredits(e) {
        let creds = NumberOfCredits;
        creds.splice(Number(e.currentTarget.id), 1);
        setNumberOfCredits(creds);
        forceUpdate();
    }

    if (isMobile()) {
        return (
            <div>
                <div className='updateTargetsPage'>
                    <p>
                        Companies get a set amount of credits per month. All hours used within a month will be subtracted from the amount of credits.
                    </p>
                    <div className='departmentInputs'>
                        <Input type="number" placeholder="Sales Credits" value={String(Sales)} id="Sales" onChange={newCreditChanged} />
                        <Input type="number" placeholder="Dev Credits" value={String(Dev)} id="Dev" onChange={newCreditChanged} />
                        <Input type="number" placeholder="Design Credits" value={String(Design)} id="Design" onChange={newCreditChanged} />
                    </div>
                    <table className="creditsTable">
                        <thead className="creditsTableHeader">
                            <td className="creditsTableCol">Month</td>
                            <td className="creditsTableCol">Year</td>
                        </thead>
                        <tr className="creditsTableHeader">
                            <td className="creditsTableCol">
                                <select className="creditSelect" value={CreditMonth} id="creditMonth" onChange={newCreditChanged}>
                                    {monthsArr.map((month) => {
                                        return (
                                            <option value={month} >{monthsArray[month]}</option>
                                        );
                                    })
                                    }
                                </select>
                            </td>
                            <td className="creditsTableCol">
                                <select className="creditSelect" value={CreditYear} id="creditYear" onChange={newCreditChanged}>
                                    {yearArr.map((year) => {
                                        return <option value={year}>
                                            {year}</option>;
                                    })
                                    }

                                </select>
                            </td>
                            <td className="creditsTableCol">
                                <Button onClick={addNewCredits} style={{ width: "100%" }}>Add Credits</Button>
                            </td>
                        </tr>
                        {NumberOfCredits.map((creditObj, index) => {
                            return (
                                <tr className="creditsTableContentHeader">
                                    <td className="creditsTableContent">
                                        <p className='ptagCreditMonth'> {monthsArray[creditObj.Month]}{'\t'}</p>
                                        <p style={{ width: '20%' }}> {creditObj.Year}</p>
                                    </td>
                                    <td className='creditsTableContent1'>
                                        <div className='divTagsCreditsData'>
                                            <p style={{ width: '80%' }}>Design </p><div >: {creditObj.Departments.Design}</div >

                                        </div>
                                        <div className='divTagsCreditsData'>
                                            <p style={{ width: '80%' }}>Sales </p><div >: {creditObj.Departments.Sales}</div >
                                        </div>
                                        <div className='divTagsCreditsData'>
                                            <p style={{ width: '80%' }}>Dev </p><div >: {creditObj.Departments.Dev}</div >
                                        </div>
                                    </td>
                                    <Button id={index} onClick={deleteCredits} style={{ width: "90%" }}>
                                        Delete row
                                    </Button>
                                </tr>
                            );
                        })
                        } </table>
                    <Button onClick={updateCredits}>Update Credits</Button>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <div className='updateTargetsPage'>
                    <p>
                        Companies get a set amount of credits per month. All hours used within a month will be subtracted from the amount of credits.
                    </p>
                    <div className='departmentInputs'>
                        <Input type="number" placeholder="Sales Credits" value={String(Sales)} id="Sales" onChange={newCreditChanged} />
                        <Input type="number" placeholder="Dev Credits" value={String(Dev)} id="Dev" onChange={newCreditChanged} />
                        <Input type="number" placeholder="Design Credits" value={String(Design)} id="Design" onChange={newCreditChanged} />
                    </div>
                    <table className="creditsTable">
                        <thead className="creditsTableHeader">
                            <td className="creditsTableCol">Month</td>
                            <td className="creditsTableCol">Year</td>
                        </thead>
                        <tr className="creditsTableHeader">
                            <td className="creditsTableCol">
                                <select className="creditSelect" value={CreditMonth} id="creditMonth" onChange={newCreditChanged}>
                                    {monthsArr.map((month) => {
                                        return (
                                            <option value={month} >{monthsArray[month]}</option>
                                        );
                                    })
                                    }</select>
                            </td>
                            <td className="creditsTableCol">
                                <select className="creditSelect" value={CreditYear} id="creditYear" onChange={newCreditChanged}>
                                    {yearArr.map((year) => {
                                        return <option value={year}>
                                            {year}</option>;
                                    })
                                    }

                                </select>
                            </td>
                            <td className="creditsTableCol">
                                <Button onClick={addNewCredits} style={{ width: "100%" }}>Add Credits</Button>
                            </td>
                        </tr>
                        {NumberOfCredits.map((creditObj, index) => {
                            return (
                                <tr className="creditsTableContentHeader">
                                    <td className="creditsTableContent">
                                        <p className='ptagCreditMonth'> {monthsArray[creditObj.Month]}{'\t'}</p>
                                        <p style={{ width: '20%' }}> {creditObj.Year}</p>
                                    </td>
                                    <td className='creditsTableContent1'>
                                        <div className='divTagsCreditsData'>
                                            <p style={{ width: '80%' }}>Design </p><div >: {creditObj.Departments.Design}</div >

                                        </div>
                                        <div className='divTagsCreditsData'>
                                            <p style={{ width: '80%' }}>Sales </p><div >: {creditObj.Departments.Sales}</div >
                                        </div>
                                        <div className='divTagsCreditsData'>
                                            <p style={{ width: '80%' }}>Dev </p><div >: {creditObj.Departments.Dev}</div >
                                        </div>
                                    </td>
                                    <Button id={index} onClick={deleteCredits} style={{ width: "90%" }}>
                                        Delete row
                                    </Button>
                                </tr>
                            );
                        })
                        } </table>
                    <Button onClick={updateCredits}>Update Credits</Button>
                </div>
            </div>
        )
    }
})

export default Credits;