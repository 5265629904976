import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { Card, Input, View, Button, MobileTabBarItem, MobileTabBar } from '../../components';
import { FaChevronRight, FaAngleRight } from 'react-icons/fa';
var mobile = require('is-mobile');


const Users = observer(() => {
    // const userAgent = req.headers['user-agent'];
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), [])
    const navigate = useNavigate();
    const [Users, setUsers] = useState([]);
    const [Companies, setCompanies] = useState([]);
    const [FirstName, setFirstName] = useState('');
    const [ShowAddData, setShowAddData] = useState(false);
    const [LastName, setLastName] = useState('');
    const [Email, setEmail] = useState('');
    const [IndexChange, setIndexChange] = useState(0);
    const [Password, setPassword] = useState('');
    const [CreatorEmail, setCreatorEmail] = useState('');
    const [CreatorPassword, setCreatorPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');
    const [UserRole, setUserRole] = useState('role');
    const [SelectedUserComp, setSelectedUserComp] = useState('');
    const [ShowExistingUsers, setShowExistingUsers] = useState(true);
    const [CurrentTab, setCurrentTab] = useState('existingUsers');
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    const [NewPermissions, setNewPermissions] = useState({
        admin: false,
        users: {
            view: false,
            edit: false,
            create: false
        },
        companies: {
            view: false,
            edit: false,
            create: false
        },
        RolePermission: {
            Company: false,
            Finance: false,
            HR: false,
            ViewFinance: false
        }
    });
    const [RolePermissions, setRolePermissions] = useState({
        Company: false,
        Finance: false,
        HR: false,
        ViewFinance: false
    });
    const [NewRolePermissions, setNewRolePermissions] = useState({
        Company: false,
        Finance: false,
        HR: false
    });
    const [Permissions, setPermissions] = useState({
        admin: false,
        users: {
            view: false,
            edit: false,
            create: false
        },
        companies: {
            view: false,
            edit: false,
            create: false
        },
        RolePermission: {
            Company: false,
            Finance: false,
            HR: false,
            ViewFinance: false
        }

    });
    function handleCompanySelection() {

        const Perms = Permissions.companies
        Perms = {
            edit: true,
            view: true
        };

        setPermissions(Perms);
        forceUpdate();
    }
    useEffect(() => {
        loadCompanies();
    }, []);

    const loadCompanies = async () => {
        await store.setLoading(true);
        await store.getCompanies().then((ret) => {
            setCompanies(ret);
            loadUsers();
        })
    }

    // function selectedTab(e) {
    //     setCurrentPage(e.currentTarget.id);
    // }
    const loadUsers = async () => {
        if (!store.user.Permissions.users.view) {
            return
        }
        await store.setLoading(true);
        await store.getUsers().then((ret) => {
            setUsers(ret);
            store.setLoading(false);
        })
    }
    function clearData() {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPassword('')
        setConfirmPassword('')
        setUserRole('role')
        setPermissions({
            admin: false,
            users: {
                view: false,
                edit: false,
                create: false
            },
            companies: {
                view: false,
                edit: false,
                create: false
            },
            userType: {
                adminUser: false
            },
            RolePermission: {}
        })
        setNewPermissions({
            admin: false,
            users: {
                view: false,
                edit: false,
                create: false
            },
            companies: {
                view: false,
                edit: false,
                create: false
            },
            userType: {
                adminUser: false
            },
            RolePermission: {}
        })
        setCreatorEmail('')
        setCreatorPassword('')
        setRolePermissions({
            Company: false,
            Finance: false,
            HR: false
        })
        setNewRolePermissions({
            Company: false,
            Finance: false,
            HR: false
        })
    }
    function permissionSelected(e) {
        let perms = Permissions;
        if (e.currentTarget.id === 'adminUser') {
            perms.admin = !perms.admin;
        }
        if (e.currentTarget.id === 'userView') {
            perms.users.view = !perms.users.view;
        }
        if (e.currentTarget.id === 'userEdit') {
            perms.users.edit = !perms.users.edit;
        }
        if (e.currentTarget.id === 'userCreate') {
            perms.users.create = !perms.users.create;
        }
        if (e.currentTarget.id === 'companyView') {
            perms.companies.view = !perms.companies.view;
        }
        if (e.currentTarget.id === 'companyEdit') {
            perms.companies.edit = !perms.companies.edit;
        }
        if (e.currentTarget.id === 'companyCreate') {
            perms.companies.create = !perms.companies.create;
        }
        setPermissions(perms);
        forceUpdate()
    }
    function firstNameChanged(e) {
        setFirstName(e.target.value);
    }
    function creatorsEmailChange(e) {
        setCreatorEmail(e.target.value);
    }
    function creatorsPasswordChange(e) {
        setCreatorPassword(e.target.value);
    }
    function lastNameChanged(e) {
        setLastName(e.target.value);
    }
    function emailChanged(e) {
        setEmail(e.target.value);
    }
    const validateEmail = (Email) => {
        if (!emailRegex.test(Email)) {
            return false
        } else {
            return true
        }
    }
    const validatePassword = (pwd) => {
        if (!passwordRegex.test(pwd)) {
            return false
        } else {
            return true
        }
    }
    function passwordChanged(e) {
        setPassword(e.target.value);
    }
    function ConfirmPasswordChanged(e) {
        setConfirmPassword(e.target.value);
    }
    function userRoleChanged(e) {
        setUserRole(e.target.value);
    }
    function createClicked() {
        store.setLoading(true);
        if (FirstName === '') {
            window.alert('Please enter your First name.');
            store.setLoading(false);
            return
        }
        if (LastName === '') {
            window.alert('Please enter your Last name.');
            store.setLoading(false);
            return
        }
        if (!validateEmail(Email) || Email === '') {
            window.alert('Please enter a Valid Email address.');
            store.setLoading(false);
            return
        }
        if (!validatePassword(Password) || Password === '') {
            window.alert('Password must contain at least 8 characters, including uppercase, lowercase, number, and special character.');
            store.setLoading(false);
            return
        }
        if (ConfirmPassword === '' || ConfirmPassword !== Password) {
            window.alert('Passwords do not match!');
            store.setLoading(false);
            return
        }
        if (Permissions === '') {
            window.alert('Please set users permissions.');
            store.setLoading(false);
            return
        }
        if (UserRole === 'role') {
            window.alert('Please select user role.');
            store.setLoading(false);
            return
        }

        let data = {
            FirstName: FirstName,
            LastName: LastName,
            Email: Email,
            Password: Password,
            Permissions: Permissions,
            Blocked: false,
            Role: UserRole,
            Company: SelectedUserComp,
            CreatorPassword: CreatorPassword,
            CreatorEmail: CreatorEmail
        }
        store.createUsers(data).then((ret) => {
            if (ret) {
                window.alert('User created successfully');
                clearData();
                loadUsers();
            }
            store.setLoading(false);
        })

    }
    function UpdatePermissions(e) {

        let data = {
            Permissions: NewPermissions,
            Role: UserRole
        }
        let email = Users[IndexChange].Email
        store.setLoading(true);
        store.patchUsers(email, data).then((ret) => {
            if (ret === true || ret !== {}) {
                window.alert('User updated successfully');
                clearData();
                loadUsers();

            } else {
                window.alert('User not updated successfully')
            }
            store.setLoading(false);
        })
    }
    function showDataButton(e) {
        setShowAddData(true)
        setIndexChange(e.currentTarget.id)
        setNewPermissions(Users[e.currentTarget.id].Permissions)
        setUserRole(Users[e.currentTarget.id].Role)
        forceUpdate();
    }
    function updatePermits(e) {
        let perms = NewPermissions;
        if (e.currentTarget.id === 'adminUser') {
            perms.admin = !perms.admin;
        }
        if (e.currentTarget.id === 'userView') {
            perms.users.view = !perms.users.view;
        }
        if (e.currentTarget.id === 'userEdit') {
            perms.users.edit = !perms.users.edit;
        }
        if (e.currentTarget.id === 'userCreate') {
            perms.users.create = !perms.users.create;
        }
        if (e.currentTarget.id === 'companyView') {
            perms.companies.view = !perms.companies.view;
        }
        if (e.currentTarget.id === 'companyEdit') {
            perms.companies.edit = !perms.companies.edit;
        }
        if (e.currentTarget.id === 'companyCreate') {
            perms.companies.create = !perms.companies.create;
        }

        setNewPermissions(perms);
        forceUpdate()
    }
    function closeEditCard() {
        setShowAddData(false);
        clearData();
    }
    function companyPermissionSelected(e) {
        let args = e.target.id.split('_');
        args[0] = args[0].replace(' ', '');
        args[0] = args[0].replace('-', '');
        let perms = Permissions;
        if (Object.keys(perms).includes(args[0])) {
            perms[args[0]][args[1]] = !perms[args[0]][args[1]];
        } else {
            perms[args[0]] = {
                view: false,
                edit: false,
                create: false
            }
            perms[args[0]][args[1]] = !perms[args[0]][args[1]];
        }

        setPermissions(perms);
        forceUpdate();
    }
    function NewCompPermissions(e) {
        let args = e.target.id.split('_');
        args[0] = args[0].replace(' ', '');
        args[0] = args[0].replace('-', '');
        let perms = NewPermissions;
        if (Object.keys(perms).includes(args[0])) {
            perms[args[0]][args[1]] = !perms[args[0]][args[1]];
        } else {
            perms[args[0]] = {
                view: false,
                edit: false,
                create: false
            }
            perms[args[0]][args[1]] = !perms[args[0]][args[1]];
        }

        setNewPermissions(perms);
        forceUpdate();
    }
    function compSelected(e) {
        let compName = e.target.value;
        setSelectedUserComp(compName);
        if (compName === '') {
            let perms = {
                admin: false,
                users: {
                    view: false,
                    edit: false,
                    create: false
                },
                companies: {
                    view: false,
                    edit: false,
                    create: false
                },
                RolePermissions: {}
            };
            setPermissions(perms);
            forceUpdate();
            return
        }
        let perms = {
            admin: false,
            users: {
                view: false,
                edit: false,
                create: false
            },
            companies: {
                view: false,
                edit: false,
                create: false
            },
            RolePermissions: {}

        };
        compName = compName.split('_');
        compName[0] = compName[0].replace(' ', '');
        compName[0] = compName[0].replace('-', '');
        perms[compName[0]] = {
            create: true,
            view: true,
            edit: true
        }
        setPermissions(perms);
        forceUpdate();

    }
    function toggleExistingUsers() {
        setShowExistingUsers(!ShowExistingUsers);
    }
    function mobileTabClicked(e) {
        setCurrentTab(e.currentTarget.id)
    }
    function RolePermissionsChanged(e) {
        let permissions = RolePermissions;
        let perms = Permissions;
        if (e.currentTarget.id === 'company') {
            permissions.Company = !permissions.Company;
        };
        if (e.currentTarget.id === 'finance') {
            permissions.Finance = !permissions.Finance;
        };
        if (e.currentTarget.id === 'hr') {
            permissions.HR = !permissions.HR;
        };
        if (e.currentTarget.id === 'viewFinance') {
            permissions.ViewFinance = !permissions.ViewFinance;
        }
        setRolePermissions(permissions);
        forceUpdate();

        if (UserRole === 'Company') {
            perms.RolePermission = permissions;
        };
        setPermissions(perms);
        forceUpdate();
    }
    function NewRolePermissionsChanged(e) {
        let permissions = NewRolePermissions;
        let newPerms = NewPermissions;

        if (e.currentTarget.id === 'compnyNewPerm') {
            permissions.Company = !permissions.Company;
        }
        if (e.currentTarget.id === 'financeNewPerm') {
            permissions.Finance = !permissions.Finance;
        }
        if (e.currentTarget.id === 'hrNewPerm') {
            permissions.HR = !permissions.HR;
        }
        if (e.currentTarget.id === 'viewFinancePerm') {
            permissions.ViewFinance = !permissions.ViewFinance;
        }
        setNewRolePermissions(permissions);
        forceUpdate();

        if (UserRole === 'Company') {
            newPerms.RolePermission = permissions;
        };
        setNewPermissions(newPerms);
        forceUpdate();
    }
    if (mobile()) {
        return (
            <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
                {CurrentTab === 'existingUsers' ? (
                    <div style={{ width: '100%', height: '93%' }}>
                        {(!store.user.Permissions.users.view) ? null :
                            <div className='newUserCard' id='existingUsers'>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                    <p className='userCardHeader'>Existing Users</p>
                                </div>
                                {(!ShowExistingUsers) ? null : <>
                                    {
                                        Users.map((user, index) => {
                                            return (
                                                <div key={index} onClick={showDataButton} id={index} className='existingUserDivMobile'>
                                                    <p>{user.Email}</p>
                                                    {(!store.user.Permissions.users.edit) ? null : <FaAngleRight className='editUserIcon' onClick={showDataButton} id={index}>Edit</FaAngleRight>}
                                                </div>
                                            )
                                        })
                                    }</>}
                            </div>}
                    </div>
                ) : null}
                {CurrentTab === 'newUser' ? (
                    <div style={{ width: '100%', height: '93%' }}>
                        {(!store.user.Permissions.users.create) ? null :
                            <div className='createNewUserCardMobile' id='createUser'>
                                <p className='userCardHeader'>New User</p>
                                <div className='newUserInputsMobile'>
                                    <Input style={{ left: '10%' }} placeholder='First Name' type='text'
                                        value={FirstName}
                                        onChange={firstNameChanged} />
                                    <Input placeholder='Last Name' type='text'
                                        value={LastName}
                                        onChange={lastNameChanged} />
                                    <Input placeholder='Email' type='text'
                                        value={Email}
                                        onChange={emailChanged} />
                                    <Input placeholder='Password' type='password'
                                        value={Password}
                                        onChange={creatorsPasswordChange} />
                                    <Input placeholder='Confirm Password' type='password'
                                        value={ConfirmPassword}
                                        onChange={creatorsEmailChange} />
                                </div>
                                <p style={{ fontWeight: 'bold', fontSize: '18px' }}>Permissions</p>
                                <div className='newUserCheckboxRow'>
                                    <p className='checkboxRowHeader'>User Type:</p>
                                    <div className='newUserCheckboxDiv'>
                                        <p>Admin</p>
                                        <input type='checkbox' id='adminUser'
                                            checked={
                                                Permissions.admin
                                            }
                                            onClick={permissionSelected} />
                                    </div>
                                </div>

                                <div className='newUserCheckboxRow'>
                                    <p className='checkboxRowHeader'>Users:</p>
                                    <div className='newUserCheckboxDiv'>
                                        <p>View</p>
                                        <input type='checkbox' id='userView'
                                            checked={
                                                Permissions.users.view
                                            }
                                            onClick={permissionSelected} />
                                    </div>
                                    <div className='newUserCheckboxDiv'>
                                        <p>Edit</p>
                                        <input type='checkbox' id='userEdit'
                                            checked={
                                                Permissions.users.edit
                                            }
                                            onClick={permissionSelected} />
                                    </div>
                                    <div className='newUserCheckboxDiv'>
                                        <p>create</p>
                                        <input type='checkbox' id='userCreate'
                                            checked={
                                                Permissions.users.create
                                            }
                                            onClick={permissionSelected} />
                                    </div>
                                </div>

                                <div className='newUserCheckboxRow'>
                                    <p className='checkboxRowHeader'>Companies:</p>
                                    <div className='newUserCheckboxDiv'>
                                        <p>View</p>
                                        <input type='checkbox' id='companyView'
                                            checked={
                                                Permissions.companies.view
                                            }
                                            onClick={permissionSelected} />
                                    </div>
                                    <div className='newUserCheckboxDiv'>
                                        <p>Edit</p>
                                        <input type='checkbox' id='companyEdit'
                                            checked={
                                                Permissions.companies.edit
                                            }
                                            onClick={permissionSelected} />
                                    </div>
                                    <div className='newUserCheckboxDiv'>
                                        <p>create</p>
                                        <input type='checkbox' id='companyCreate'
                                            checked={
                                                Permissions.companies.create
                                            }
                                            onClick={permissionSelected} />
                                    </div>
                                </div>
                                <select className='dropdownSelectMobile'
                                    value={UserRole}
                                    onChange={userRoleChanged}>
                                    <option value='role'>Select Role</option>
                                    <option value='Admin'>Admin</option>
                                    <option value='Company'>Company</option>
                                    <option value='Sales'>Sales</option>
                                    <option value='Design'>Design</option>
                                    <option value='Dev'>Dev</option>
                                </select>
                                {(UserRole === 'Company') ?
                                    <div>
                                        <select className='dropdownSelectMobile' value={SelectedUserComp} onChange={compSelected} style={{ marginTop: '10px' }}>
                                            <option value=''>Select a company</option>
                                            {Companies.map((company, index) => {
                                                return (
                                                    <option key={index} value={company.Name}>{company.Name}</option>
                                                )
                                            })}
                                        </select>
                                    </div> : null}
                                {(UserRole === 'Company') ? null :
                                    <div>
                                        {
                                            Companies.map((company, index) => {
                                                let compPerm = Permissions[company.Name.replace(' ', '')] || {}
                                                return (
                                                    <div key={index}
                                                        className='newUserCheckboxRow'>
                                                        <p className='checkboxRowHeader'>
                                                            {
                                                                `${company.Name
                                                                }:`
                                                            }</p>
                                                        <div className='newUserCheckboxDiv'>
                                                            <p>View</p>
                                                            <input type='checkbox'
                                                                id={
                                                                    `${company.Name
                                                                    }_view`
                                                                }
                                                                checked={
                                                                    compPerm.view || false
                                                                }
                                                                onClick={companyPermissionSelected} />
                                                        </div>
                                                        <div className='newUserCheckboxDiv'>
                                                            <p>Edit</p>
                                                            <input type='checkbox'
                                                                id={
                                                                    `${company.Name
                                                                    }_edit`
                                                                }
                                                                checked={
                                                                    compPerm.edit || false
                                                                }
                                                                onClick={companyPermissionSelected} />

                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        <div>
                                            <p className='checkboxRowHeader'>Role Permissions:</p>
                                            <div className='newUserCheckboxDiv'>
                                                <p>Company</p>
                                                <input type='checkbox' id='company'
                                                    checked={
                                                        RolePermissions.Company
                                                    }
                                                    onClick={RolePermissionsChanged} />
                                            </div>
                                            <div className='newUserCheckboxDiv'>
                                                <p>Finance</p>
                                                <input type='checkbox' id='finance'
                                                    checked={
                                                        RolePermissions.Finance
                                                    }
                                                    onClick={RolePermissionsChanged} />
                                            </div>
                                            <div className='newUserCheckboxDiv'>
                                                <p>HR</p>
                                                <input type='checkbox' id='hr'
                                                    checked={
                                                        RolePermissions.HR
                                                    }
                                                    onClick={RolePermissionsChanged} />
                                            </div>
                                        </div>
                                    </div>}
                                <Button className='createUserBtnMobile' onClick={createClicked}>Create User</Button>
                            </div>
                        }
                    </div>
                ) : null
                }
                {
                    (ShowAddData) ? <Card className='userAddDataCardMobile'>
                        <div className='closeEditCard'>
                            <p id="x" onClick={closeEditCard}>X</p>
                        </div>
                        <div>
                            <h2>{`Edit ${Users[Number(IndexChange)].Email}`}</h2>
                            <div className=''>
                                <p style={{ fontWeight: 'bold', fontSize: '18px' }}>Permissions</p>
                                <div className='newUserCheckboxRow'>
                                    <p className='checkboxRowHeader'>User Type:</p>
                                    <div className='newUserCheckboxDiv'>
                                        <p>Admin</p>
                                        <input type='checkbox' id='adminUser' checked={NewPermissions.admin} onChange={updatePermits} />
                                    </div>
                                </div>

                                <div className='newUserCheckboxRow'>
                                    <p className='checkboxRowHeader'>Users:</p>
                                    <div className='newUserCheckboxDiv'>
                                        <p>View</p>
                                        <input type='checkbox' id='userView' checked={NewPermissions.users.view} onChange={updatePermits} />
                                    </div>
                                    <div className='newUserCheckboxDiv'>
                                        <p>Edit</p> <input type='checkbox' id='userEdit' checked={NewPermissions.users.edit} onChange={updatePermits} />
                                    </div>
                                    <div className='newUserCheckboxDiv'>
                                        <p>create</p>
                                        <input type='checkbox' id='userCreate' checked={NewPermissions.users.create} onChange={updatePermits} />
                                    </div>
                                </div>

                                <div className='newUserCheckboxRow'>
                                    <p className='checkboxRowHeader'>Companies:</p>
                                    <div className='newUserCheckboxDiv'>
                                        <p>View</p>
                                        <input type='checkbox' id='companyView' checked={NewPermissions.companies.view} onChange={updatePermits} />
                                    </div>
                                    <div className='newUserCheckboxDiv'>
                                        <p>Edit</p>
                                        <input type='checkbox' id='companyEdit' checked={NewPermissions.companies.edit} onChange={updatePermits} />
                                    </div>
                                    <div className='newUserCheckboxDiv'>
                                        <p>create</p>
                                        <input type='checkbox' id='companyCreate' checked={NewPermissions.companies.create} onChange={updatePermits} />
                                    </div>
                                </div>

                                <select className='editRoleDropDown'
                                    value={UserRole}
                                    onChange={userRoleChanged}>
                                    <option value='role'>Select Role</option>
                                    <option value='Admin'>Admin</option>
                                    <option value='Company'>Company</option>
                                    <option value='Sales'>Sales</option>
                                    <option value='Dev'>Dev</option>
                                    <option value='Design'>Design</option>
                                </select>
                                {Companies.map((company, index) => {
                                    let compPerm = NewPermissions[company.Name.replace(' ', '')] || {}
                                    return (
                                        <div key={index}
                                            className='newUserCheckboxRow'>
                                            <p className='checkboxRowHeader'> {`${company.Name}:`}</p>
                                            <div className='newUserCheckboxDiv'>
                                                <p>View</p>
                                                <input type='checkbox' id={`${company.Name}_view`} checked={compPerm.view || false} onClick={NewCompPermissions} />
                                            </div>
                                            <div className='newUserCheckboxDiv'>
                                                <p>Edit</p>
                                                <input type='checkbox' id={`${company.Name}_edit`} checked={compPerm.edit || false} onClick={NewCompPermissions} />
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className='updateButton'>
                                    <Button className='buttonUpdatePerms' onClick={UpdatePermissions}> Update Permissions</Button>
                                </div>
                            </div>
                        </div>
                    </Card> : null
                }
                <MobileTabBar className='mobileTabBarDiv'>
                    <MobileTabBarItem description="Existing" icon="FaBuilding"
                        numItems={2}
                        id="existingUsers"
                        onClick={mobileTabClicked}
                        isActive={
                            CurrentTab === "existingCompanies"
                        } />
                    <MobileTabBarItem description="New" icon="FaPlus"
                        numItems={2}
                        id="newUser"
                        onClick={mobileTabClicked}
                        isActive={
                            CurrentTab === "newCompany"
                        } />
                </MobileTabBar>

            </div >
        )
    } else {
        return (
            <div style={{ height: '100%', overflow: 'scroll' }}>
                {(!store.user.Permissions.users.view) ? null : /* Existing Users */
                    <Card className='newUserCard'>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                            <p className='userCardHeader'>Existing Users</p>
                            <FaChevronRight className={`showSectionChevron ${(ShowExistingUsers) ? 'showSectionChevronShow' : ''}`} onClick={toggleExistingUsers} />
                        </div>
                        {(!ShowExistingUsers) ? null : <>
                            {
                                Users.map((user, index) => {
                                    return (
                                        <div key={index} className='existingUserDiv'>
                                            <p>{user.Email}</p>
                                            {(!store.user.Permissions.users.edit) ? null : <Button onClick={showDataButton} id={index}>Edit</Button>}
                                        </div>
                                    )
                                })
                            }</>}
                    </Card>}
                {(!store.user.Permissions.users.create) ? null :  /* New User */
                    <Card className='cardCreateUser'>
                        <div className='userCardHeaderDiv'>
                            <p className='userCardHeader'>New User</p>
                            <div className='newUserInputs'>
                                <Input placeholder='First Name' type='text'
                                    value={FirstName}
                                    onChange={firstNameChanged} />
                                <Input placeholder='Last Name' type='text'
                                    value={LastName}
                                    onChange={lastNameChanged} />
                                <Input placeholder='Email' type='text'
                                    value={Email}
                                    onChange={emailChanged} />
                                <Input placeholder='Password' type='password'
                                    value={Password}
                                    onChange={passwordChanged} />
                                <Input placeholder='Confirm Password' type='password'
                                    value={ConfirmPassword}
                                    onChange={ConfirmPasswordChanged} />
                            </div>
                        </div>
                        <div className='newUserForm'>
                            <div className='newUserPermissions'>
                                <p style={
                                    {
                                        fontWeight: 'bold',
                                        fontSize: '18px'
                                    }
                                }>Permissions</p>
                                <div className='newUserCheckboxRow'>
                                    <p className='checkboxRowHeader'>User Type:</p>
                                    <div className='newUserCheckboxDiv'>
                                        <p>Admin</p>
                                        <input type='checkbox' id='adminUser'
                                            checked={
                                                Permissions.admin
                                            }
                                            onClick={permissionSelected} />
                                    </div>
                                </div>

                                <div className='newUserCheckboxRow'>
                                    <p className='checkboxRowHeader'>Users:</p>
                                    <div className='newUserCheckboxDiv'>
                                        <p>View</p>
                                        <input type='checkbox' id='userView'
                                            checked={
                                                Permissions.users.view
                                            }
                                            onClick={permissionSelected} />
                                    </div>
                                    <div className='newUserCheckboxDiv'>
                                        <p>Edit</p>
                                        <input type='checkbox' id='userEdit'
                                            checked={
                                                Permissions.users.edit
                                            }
                                            onClick={permissionSelected} />
                                    </div>
                                    <div className='newUserCheckboxDiv'>
                                        <p>create</p>
                                        <input type='checkbox' id='userCreate'
                                            checked={
                                                Permissions.users.create
                                            }
                                            onClick={permissionSelected} />
                                    </div>
                                </div>
                                <div className='newUserCheckboxRow'>
                                    <p className='checkboxRowHeader'>Companies:</p>
                                    <div className='newUserCheckboxDiv'>
                                        <p>View</p>
                                        <input type='checkbox' id='companyView'
                                            checked={
                                                Permissions.companies.view
                                            }
                                            onClick={permissionSelected} />
                                    </div>
                                    <div className='newUserCheckboxDiv'>
                                        <p>Edit</p>
                                        <input type='checkbox' id='companyEdit'
                                            checked={
                                                Permissions.companies.edit
                                            }
                                            onClick={permissionSelected} />
                                    </div>
                                    <div className='newUserCheckboxDiv'>
                                        <p>create</p>
                                        <input type='checkbox' id='companyCreate'
                                            checked={
                                                Permissions.companies.create
                                            }
                                            onClick={permissionSelected} />
                                    </div>
                                </div>
                                <select className='dropdownSelect'
                                    value={UserRole}
                                    onChange={userRoleChanged}>
                                    <option value='role'>Select Role</option>
                                    <option value='Admin'>Admin</option>
                                    <option value='Company'>Company</option>
                                    <option value='Sales'>Sales</option>
                                    <option value='Design'>Design</option>
                                    <option value='Dev'>Dev</option>
                                </select>
                                {(UserRole === 'Company') ?
                                    <div>
                                        <select className='dropdownSelect' value={SelectedUserComp} onChange={compSelected} style={{ marginTop: '10px' }}>
                                            <option value=''>Select a company</option>
                                            {Companies.map((company, index) => {
                                                return (
                                                    <option key={index} value={company.Name}>{company.Name}</option>
                                                )
                                            })}
                                        </select>
                                        <div>
                                            <p className='checkboxRowHeader'>Role Permissions:</p>
                                            <div className='newUserCheckboxDiv'>
                                                <p>Company :</p>
                                                <input style={{ marginLeft: '8%' }} type='checkbox' id='company' checked={RolePermissions.Company} onClick={RolePermissionsChanged} />
                                            </div>
                                            <div className='newUserCheckboxDiv'>
                                                <p>Finance :</p>
                                                <input style={{ marginLeft: '10%' }} type='checkbox' id='finance' checked={RolePermissions.Finance} onClick={RolePermissionsChanged} />
                                            </div>
                                            <div className='newUserCheckboxDiv'>
                                                <p>HR :</p>
                                                <input style={{ marginLeft: '15%' }} type="checkbox" id="hr" checked={RolePermissions.HR} onClick={RolePermissionsChanged} />
                                            </div>
                                            <div className='newUserCheckboxDiv'>
                                                <p>View Finance :</p>
                                                <input style={{ marginLeft: '15%' }} type="checkbox" id="viewFinance" checked={RolePermissions.ViewFinance} onClick={RolePermissionsChanged} />
                                            </div>
                                        </div>
                                    </div> : null}
                                {(UserRole === 'Company') ? null : <>
                                    {
                                        Companies.map((company, index) => {
                                            let compPerm = Permissions[company.Name.replace(' ', '')] || {}
                                            return (
                                                <div key={index}
                                                    className='newUserCheckboxRow'>
                                                    <p className='checkboxRowHeader'>
                                                        {
                                                            `${company.Name
                                                            }:`
                                                        }</p>
                                                    <div className='newUserCheckboxDiv'>
                                                        <p>View</p>
                                                        <input type='checkbox'
                                                            id={
                                                                `${company.Name
                                                                }_view`
                                                            }
                                                            checked={
                                                                compPerm.view || false
                                                            }
                                                            onClick={companyPermissionSelected} />
                                                    </div>
                                                    <div className='newUserCheckboxDiv'>
                                                        <p>Edit</p>
                                                        <input type='checkbox'
                                                            id={
                                                                `${company.Name
                                                                }_edit`
                                                            }
                                                            checked={
                                                                compPerm.edit || false
                                                            }
                                                            onClick={companyPermissionSelected} />
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }</>}
                                <Button className='newUserButton' onClick={createClicked}>Create User</Button>
                            </div>
                        </div>
                    </Card>
                }
                {
                    (ShowAddData) ? <Card className='addDataCard'>
                        <div className='closeEditCard'>
                            <p id="x" onClick={closeEditCard}>X</p>
                        </div>
                        <div>
                            <h1 className='targetHeader'>Edit Permissions</h1>
                            <div className=''>
                                <p style={{ fontWeight: 'bold', fontSize: '18px' }}>Permissions</p>
                                <div className='newUserCheckboxRow'>
                                    <p className='checkboxRowHeader'>User Type:</p>
                                    <div className='newUserCheckboxDiv'>
                                        <p>Admin</p>
                                        <input type='checkbox' id='adminUser' checked={NewPermissions.admin} onChange={updatePermits} />
                                    </div>
                                </div>

                                <div className='newUserCheckboxRow'>
                                    <p className='checkboxRowHeader'>Users:</p>
                                    <div className='newUserCheckboxDiv'>
                                        <p>View</p>
                                        <input type='checkbox' id='userView' checked={NewPermissions.users.view} onChange={updatePermits} />
                                    </div>
                                    <div className='newUserCheckboxDiv'>
                                        <p>Edit</p> <input type='checkbox' id='userEdit' checked={NewPermissions.users.edit} onChange={updatePermits} />
                                    </div>
                                    <div className='newUserCheckboxDiv'>
                                        <p>create</p>
                                        <input type='checkbox' id='userCreate' checked={NewPermissions.users.create} onChange={updatePermits} />
                                    </div>
                                </div>

                                <div className='newUserCheckboxRow'>
                                    <p className='checkboxRowHeader'>Companies:</p>
                                    <div className='newUserCheckboxDiv'>
                                        <p>View</p>
                                        <input type='checkbox' id='companyView' checked={NewPermissions.companies.view} onChange={updatePermits} />
                                    </div>
                                    <div className='newUserCheckboxDiv'>
                                        <p>Edit</p>
                                        <input type='checkbox' id='companyEdit' checked={NewPermissions.companies.edit} onChange={updatePermits} />
                                    </div>
                                    <div className='newUserCheckboxDiv'>
                                        <p>create</p>
                                        <input type='checkbox' id='companyCreate' checked={NewPermissions.companies.create} onChange={updatePermits} />
                                    </div>
                                </div>

                                <select className='dropdownSelect'
                                    value={UserRole}
                                    onChange={userRoleChanged}>
                                    <option value='role'>Select Role</option>
                                    <option value='Admin'>Admin</option>
                                    <option value='Company'>Company</option>
                                    <option value='Sales'>Sales</option>
                                    <option value='Dev'>Dev</option>
                                    <option value='Design'>Design</option>
                                </select>

                                {(UserRole === 'Company') ?
                                    <div>
                                        {Companies.map((company, index) => {
                                            let compPerm = NewPermissions[company.Name.replace(' ', '')] || {}
                                            return (
                                                <div key={index} className='newUserCheckboxRow'>
                                                    <p className='checkboxRowHeader'> {`${company.Name}:`}</p>
                                                    <div className='newUserCheckboxDiv'>
                                                        <p>View</p>
                                                        <input type='checkbox' id={`${company.Name}_view`} checked={compPerm.view || false} onClick={NewCompPermissions} />
                                                    </div>
                                                    <div className='newUserCheckboxDiv'>
                                                        <p>Edit</p>
                                                        <input type='checkbox' id={`${company.Name}_edit`} checked={compPerm.edit || false} onClick={NewCompPermissions} />
                                                    </div>
                                                </div>
                                            )
                                        })}

                                        <div>
                                            <p className='checkboxRowHeader'>Role Permissions</p>
                                            <div className='newUserCheckboxDiv'>
                                                <p>Company :</p>
                                                <input style={{ marginLeft: '10%' }} type='checkbox' id='compnyNewPerm' checked={NewRolePermissions.Company} onClick={NewRolePermissionsChanged} />
                                            </div>
                                            <div className='newUserCheckboxDiv'>
                                                <p>Finance :</p>
                                                <input style={{ marginLeft: '15%' }} type='checkbox' id='financeNewPerm' checked={NewRolePermissions.Finance} onClick={NewRolePermissionsChanged} />
                                            </div>
                                            <div className='newUserCheckboxDiv'>
                                                <p>HR :</p>
                                                <input style={{ marginLeft: '27%' }} type="checkbox" id="hrNewPerm" checked={NewRolePermissions.HR} onClick={NewRolePermissionsChanged} />
                                                <div className='newUserCheckboxDiv'>
                                                    <p>View Finance :</p>
                                                    <input style={{ marginLeft: '27%' }} type="checkbox" id="viewFinancePerm" checked={NewRolePermissions.ViewFinance} onClick={NewRolePermissionsChanged} />
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null}
                                <div className='updateButton'>
                                    <Button className='btnCreate-hover' onClick={UpdatePermissions}> Update Permissions</Button>
                                </div>
                            </div>
                        </div>
                    </Card> : null
                }
            </div>
        )
    }
})
export default Users