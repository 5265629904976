import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import './style.css'
import { useNavigate } from 'react-router-dom';
import { Input, View, Button, Card, DoughnutChart, AdminSpiral } from '../../../components';
var mobile = require('is-mobile');

const Dashboard = observer(() => {
    const store = useStore();
    const [, updateState] = useState();
    const [CompaniesList, setCompaniesList] = useState([]);
    const [Actuals, setActual] = useState([]);
    const [AdminOverview, setAdminOverview] = useState([]);
    const [FromDate, setFromDate] = useState('');
    const [ToDate, setToDate] = useState('');
    const [RangeStartDate, setRangeStartDate] = useState('');
    const [RangeEndDate, setRangeEndDate] = useState('');
    const [FilteredActuals, setFilteredActuals] = useState([]);
    const [SpiralValues, setSpiralValues] = useState([]);
    const [TotalActualsByMetric, setTotalActualsByMetric] = useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        let currDate = new Date();
        let x = new Date(currDate.getFullYear(), currDate.getMonth(), 1);
        let y = new Date(currDate.getFullYear(), currDate.getMonth() + 1, 0);
        loadCompanies();
    }, []);
    function loadCompanies(stDate = -1, edDate = -1) {
        store.setLoading(true);
        store.getCompaniesByPerms(store.user.Email).then((ret) => {
            if (typeof ret === "string" || !ret) {
                setCompaniesList({});
                store.setLoading(false);
                return;
            }
            setCompaniesList(ret);
            let currDate = new Date();
            var currMonthStartDate = new Date(currDate.getFullYear(), currDate.getMonth(), 1);
            var currMonthEndDate = new Date(currDate.getFullYear(), currDate.getMonth() + 1, 0);
            setFromDate(currMonthStartDate);
            setToDate(currMonthEndDate);
            if (stDate !== -1) {
                currMonthStartDate = new Date(stDate);
            }
            if (edDate !== -1) {
                currMonthStartDate = new Date(edDate)
            }
            if (FromDate !== -1) {
                currMonthStartDate = new Date(FromDate);
            }
            if (ToDate !== -1) {
                currMonthEndDate = new Date(ToDate);
            }

            let totalSalesTarget = 0;
            let totalSalesActuals = 0;
            let operatingExpensesTarget = 0;
            let operatingExpensesActuals = 0;
            let recurringRevenueTarget = 0;
            let recurringRevenueActuals = 0;
            let averageRevenuePerCustomerTarget = 0;
            let averageRevenuePerCustomerActuals = 0;
            let tempSpiral = []
            ret.forEach((company) => {
                const filteredActuals = company.Actual.filter((actual) => {
                    let actualDate = new Date(actual.Date);
                    return actualDate >= currMonthStartDate && actualDate <= currMonthEndDate;
                });
                const filteredTargets = company.Target.filter((target) => {
                    let targetDate = new Date(target.Date);
                    return targetDate >= currMonthStartDate && targetDate <= currMonthEndDate;
                });
                filteredActuals.forEach((actual) => {
                    totalSalesActuals += (actual.TotalSales || 0);
                    operatingExpensesActuals += (actual.OperatingExpenses || 0);
                    recurringRevenueActuals += (actual.RecurringRevenue || 0);
                    averageRevenuePerCustomerActuals += (actual.AverageRevenuePerCustomer || 0);
                });
                filteredTargets.forEach((target) => {
                    totalSalesTarget += (target.TotalSales || 0);
                    operatingExpensesTarget += (target.OperatingExpenses || 0);
                    recurringRevenueTarget += (target.RecurringRevenue || 0);
                    averageRevenuePerCustomerTarget += (target.AverageRevenuePerCustomer || 0);
                })
                tempSpiral.push({
                    Company: company.Name,
                    Values: [
                        { target: totalSalesTarget, actual: totalSalesActuals, color: 'rgba(227,54,126,1)', id: 'Total Sales' },
                        { target: operatingExpensesTarget, actual: operatingExpensesActuals, color: 'rgba(146,140,195,1)', id: 'Operating Expenses' },
                        { target: recurringRevenueTarget, actual: recurringRevenueActuals, color: 'rgba(49,192,209,1)', id: 'Recurring Revenue' },
                        { target: averageRevenuePerCustomerTarget, actual: averageRevenuePerCustomerActuals, color: 'rgba(255, 223, 0, 1)', id: 'Average Revenue' }
                    ]
                })
                forceUpdate();
            });
            setSpiralValues(tempSpiral)

            if (store.user.Role === "Admin") {
                getCompanyOverview(ret);
            } else {
                store.setLoading(false);
            }
        });
        forceUpdate();
    };
    function getCompanyOverview(companies) {
        store.calculateAdminCompanyOverview(companies).then((ret) => {
            setAdminOverview(ret);
            forceUpdate();
            store.setLoading(false);
        })
    };
    function getDates(e) {
        setRangeStartDate(e.target.value);
        forceUpdate();
    };
    function getEndDate(e) {
        setRangeEndDate(e.target.value);
        forceUpdate();
    }
    function searchDates() {
        setFromDate(RangeStartDate);
        setToDate(RangeEndDate);
    }
    function formatDate(inputDate) {
        if (!inputDate) {
            return null;
        }
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(inputDate).toLocaleDateString(undefined, options);
        return formattedDate;
    }
    if (mobile()) {
        return (
            <div>

            </div>
        );

    } else {
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }} >
                <div style={{ display: 'flex', width: '100%', height: '25%', flexDirection: 'column', marginLeft: '2%' }}>
                    <h1>Select Date : </h1>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '20%' }}>
                            <h3>From :</h3><Input type='date' id="startDate" onChange={getDates} className='RangeInputs' value={RangeStartDate} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '20%', marginLeft: '-3%' }}>
                            <h3 style={{ marginLeft: '5%' }}>To :</h3><Input type='date' id='endDate' onChange={getEndDate} className='RangeInputs' value={RangeEndDate} />
                        </div>
                        <Button className='searchButton' onClick={searchDates}>Search</Button>
                    </div>
                    <p>Showing data from : <b style={{ color: 'rgb(227, 54, 126)' }}>{formatDate(RangeStartDate)}</b> to : <b style={{ color: 'rgb(227, 54, 126)' }}>{formatDate(RangeEndDate)}</b>.</p>
                </div>
                <div className='spiralDiv'>
                    {CompaniesList.map((company, index) => {
                        return (
                            <div style={{ width: '50%' }} key={index}>
                                <AdminSpiral Company={company} Start={FromDate} End={ToDate} />
                            </div>
                        )
                    })}
                </div>

            </div>
        )
    }
})
export default Dashboard;