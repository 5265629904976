import React from "react";
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineController, LineElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineController, LineElement, ChartDataLabels);

const PieChart = ({ Aions = [], id }) => {
  const generateRandomColor = () => {
    const randomColor = () => Math.floor(Math.random() * 256);
    return `rgba(${randomColor()}, ${randomColor()}, ${randomColor()}, 0.7)`;
  };
  const aionsData = Aions;
  let currMonthData = aionsData.CurrentMonth || [];
  let PrevMonthData = aionsData.PreviousMonth || [];
  let OtherData = aionsData.MonthBeforePrev || [];

  let currIncomeData = [];
  let prevIncomeData = [];
  let OtherIncomeData = [];
  let tempCurrMonthData = [];
  let tmpPrevIncomeData = [];
  let tmpOtherData = [];

  currMonthData.forEach((item) => {
    let Income = item.Income || [];
    tempCurrMonthData.push(...Income);
  });
  let currMonthDataLabel = currMonthData[0]?.Label || '';

  PrevMonthData.forEach((item) => {
    let Income = item.Income || [];
    tmpPrevIncomeData.push(...Income);
  });
  let PrevMonthDataLabel = PrevMonthData[0]?.Label || '';

  OtherData.forEach((item) => {
    let Income = item.Income || [];
    tmpOtherData.push(...Income.flat())
  });
  let OtherDataLabel = OtherData[0]?.Label || '';

  const categoryMap = new Map();
  for (let i = 0; i < tempCurrMonthData.length; i++) {
    const currentItem = tempCurrMonthData[i];
    const existingItem = categoryMap.get(currentItem.Client);

    if (existingItem) {
      existingItem.Amount += parseFloat(currentItem.Amount);
    } else {
      categoryMap.set(currentItem.Client, { ...currentItem, Amount: parseFloat(currentItem.Amount) });
    }
  }
  currIncomeData.push(...categoryMap.values());

  const anotherMap = new Map();
  for (let i = 0; i < tmpPrevIncomeData.length; i++) {
    const currentItem = tmpPrevIncomeData[i];
    const existingItem = anotherMap.get(currentItem.Client);

    if (existingItem) {
      existingItem.Amount += parseFloat(currentItem.Amount);
    } else {
      anotherMap.set(currentItem.Client, { ...currentItem, Amount: parseFloat(currentItem.Amount) });
    }
  }
  prevIncomeData.push(...anotherMap.values());

  const otherMap = new Map();
  for (let i = 0; i < tmpOtherData.length; i++) {
    const currentItem = tmpOtherData[i];
    const existingItem = otherMap.get(currentItem.Client);

    if (existingItem) {
      existingItem.Amount += parseFloat(currentItem.Amount);
    } else {
      otherMap.set(currentItem.Client, { ...currentItem, Amount: parseFloat(currentItem.Amount) });
    }
  }
  OtherIncomeData.push(...otherMap.values());

  const getRandomColors = (length) => Array.from({ length }, () => generateRandomColor());
  // const options = {
  //   plugins: {
  //     legend: {
  //       position: 'right',
  //     },
  //     title: {
  //       display: true,
  //       text: `${currMonthDataLabel} Income`,
  //       size: 24,
  //     },
  //     datalabels: {
  //       display: 'auto',
  //       color: 'black',
  //       font: {
  //         weight: 'bold',
  //         size: 15,
  //       },
  //     },
  //   },
  // };
  const prevOptions = {
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: `${PrevMonthDataLabel} Clients`,
        font: {
          weight: 'bold',
          size: 25,
        },
      },
      datalabels: {
        display: 'auto',
        color: 'black',
        font: {
          weight: 'bold',
        },
        formatter: (value) => value > 0 ? `R ${Number(value).toLocaleString()}` : '',
      },
    },
  };
  // const OtherOptions = {
  //   plugins: {
  //     legend: {
  //       position: 'right',
  //     },
  //     title: {
  //       display: true,
  //       text: `${OtherDataLabel} Income`,
  //     },
  //     datalabels: {
  //       display: 'auto',
  //       color: 'black',
  //       font: {
  //         weight: 'bold',
  //       },
  //     },
  //   },
  // };
  // const prevData = {
  //   labels: OtherIncomeData.map(item => `${item.Client}: R${item.Amount}`),
  //   datasets: [
  //     {
  //       data: OtherIncomeData.map(item => item.Amount),
  //       backgroundColor: getRandomColors(OtherIncomeData.length),
  //     },
  //   ],
  // };
  // const data = {
  //   labels: currIncomeData.map(item => `${item.Client}: ${item.Amount}`),
  //   datasets: [
  //     {
  //       data: currIncomeData.map(item => item.Amount),
  //       backgroundColor: getRandomColors(currIncomeData.length),
  //     },
  //   ],
  // };
  const filteredPrevIncomeData = prevIncomeData.filter(item => item.Amount !== 0);
  const charData = {
    labels: filteredPrevIncomeData.map(item => `${item.Client}:R ${item.Amount}`),
    datasets: [
      {
        data: filteredPrevIncomeData.map(item => item.Amount),
        backgroundColor: getRandomColors(filteredPrevIncomeData.length),
      },
    ],
  };


  return (
    <div style={{ width: "800px", height: "800px" }}>
      {/* <Pie data={prevData} options={OtherOptions} />
      <Pie data={charData} options={prevOptions} />
      <Pie data={data} options={options} /> */}
      <Pie data={charData} options={prevOptions} id={id} />
    </div>

  );
};

export default PieChart;
