import { componentsToColor } from 'pdf-lib';
import React from 'react';
import { Bar } from 'react-chartjs-2';

const Burnrate = ({ Actual, currentAct, currentFore, previousAct, previousFore }) => {
    let currMonthData = Actual.CurrentMonth;
    let prevMonthData = Actual.PreviousMonth;
    let labels = [];
    if (prevMonthData.Type === 'Monthly' && currMonthData.Type === 'Monthly') {
        labels = ['Previous Month', 'Current Month', 'Deficit'];
    } else if (currMonthData.Type === 'Yearly' && prevMonthData.Type === 'Yearly') {
        labels = ['Previous Year', 'Current Year', 'Deficit'];
    } else if (currMonthData.Type === 'Entire' && prevMonthData.Type === 'Entire') {
        labels = ['', 'Current', 'Deficit'];
    }
    let deficit = currentFore - currentAct;
    if (deficit < 0) {
        deficit = 0
    }
    const datasets = [
        {
            axis: 'y',
            label: 'Forecast',
            data: [previousAct, currentAct, 0],
            backgroundColor: labels.map(label => {
                if (label === 'Previous Month' || label === 'Previous Year' || label === '') {
                    return '#EFB86F';
                } else {
                    return '#D5922D';
                }
            }),
            borderColor: labels.map(label => {
                if (label === 'Previous Month' || label === 'Previous Year' || label === '') {
                    return '#EFB86F';
                } else {
                    return '#D5922D';
                }
            }),
            borderWidth: 1
        },
        {
            axis: 'y',
            label: 'Actual',
            data: [previousFore, currentFore, null],
            backgroundColor: '#213038',
            borderColor: '#213038',
            borderWidth: 1
        },
        {
            axis: 'y',
            label: 'Dificit',
            data: [null, null, deficit],
            backgroundColor: '#7A8F9D',
            borderColor: '#7A8F9D',
            borderWidth: 1
        }

    ];

    // const filteredDatasets = [
    //     [datasets[0], datasets[1]],
    //     [datasets[0], datasets[1]],
    //     [datasets[2]]
    // ];

    const data = {
        labels: labels,
        datasets: datasets
    };

    const options = {
        indexAxis: 'y',
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            },
            tooltip: {
                enabled: true,
                backgroundColor: '#213038',
                borderColor: '#213038',
                borderWidth: 1,
                borderRadius: 10,
                padding: 10,
                displayColors: false,
                color: '#fff',
                html: true,
                callbacks: {
                    label: function (context) {
                        const value = context.raw;
                        return [
                            'Catchup Total',
                            `R ${value}`
                        ];
                    }
                }
            }
        },
        scales: {
            x: {
                stacked: false,
                grid: {
                    color: (context) => {
                        if (context.tick && context.tick.value === 0) {
                            return 'black';
                        }
                        return '#ddd';
                    },
                    borderWidth: (context) => {
                        return context.tick && context.tick.value === 0 ? 2 : 1;
                    },
                    borderDash: (context) => {
                        return context.tick && context.tick.value === 0 ? [5] : [];
                    },
                }
            },
            y: {
                stacked: false,
                grid: {
                    display: false
                }
            }
        },
        elements: {
            bar: {
                borderRadius: 35,
            }
        }
    };

    return (
        <Bar data={data} options={options} />
    )
};

export default Burnrate;