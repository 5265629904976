import React from 'react';
import { Bar } from 'react-chartjs-2';

const AEXFunnelBar = ({ dealsObj, graphDeals, id, labels, value}) => {
    let Labels = labels || {};
    let deals = dealsObj;
    let NewArr = [];
    let WorkingOnArr = [];
    let MeetingArr = [];
    let LikelyToCloseArr = 0;
    let closedArr = 0;

    if (graphDeals.TalkingApproached && Array.isArray(graphDeals.TalkingApproached)) {
        graphDeals.TalkingApproached.forEach(item => {
            NewArr.push(item);
        });
    } else {
        NewArr.push('');
    }
    if ((graphDeals.WorkingOnProposalSent && Array.isArray(graphDeals.WorkingOnProposalSent))) {
        graphDeals.WorkingOnProposalSent.forEach(item => {
            WorkingOnArr.push(item);
        });
    } else {
        WorkingOnArr.push('');
    }
    if ((graphDeals.Meeting && Array.isArray(graphDeals.Meeting))) {
        graphDeals.Meeting.forEach(item => {
            MeetingArr.push(item);
        });
    } else {
        MeetingArr.push('');
    }
    if (deals) {
        let totalAmount = 0;
        let totalClosedAmount = 0;

        if (deals.CloseThisMonth && Array.isArray(deals.CloseThisMonth)) {
            deals.CloseThisMonth.forEach((item) => {
                if (item.properties.dealname.includes('AEX -')) {
                    const amount = item.properties.amount ? Number(item.properties.amount) : 0;
                    totalAmount += amount;
                }
            });
        }

        if (deals.Closed && Array.isArray(deals.Closed)) {
            deals.Closed.forEach((item) => {
                if (item.properties.dealname.includes('AEX -')) {
                    const amount = item.properties.amount ? Number(item.properties.amount) : 0;
                    totalClosedAmount += amount;
                }
            });
        }
        LikelyToCloseArr = totalAmount;
        closedArr = totalClosedAmount;
    } else {
        closedArr = 0;
        LikelyToCloseArr = 0;
    }

    const data = {
        labels: ['Talking/Approached', 'Proposal Sent', 'Meeting', 'Likely to close this month', 'Closed'],
        datasets: [
            {
                label: '',
                backgroundColor: [
                    'rgb(227, 54, 126 , 0.6)',
                    'rgb(146, 140, 195 , 0.6)',
                    'rgb(49, 192, 209, 0.6)',
                    'rgb(255, 223, 0, 0.6)',
                    'rgba(143,202,114,1, 0.6)'
                ],
                borderColor: [
                    'rgb(227, 54, 126 , 1)',
                    'rgb(146, 140, 195, 2)',
                    'rgb(49, 192, 209)',
                    'rgb(255, 223, 0)',
                    'rgba(143,202,114,1)'
                ],
                borderWidth: 1,
                borderSkipped: false,
                barThickness: 69,

                data: [
                    [0, 100],
                    [10, 90],
                    [20, 80],
                    [30, 70],
                    [40, 60],
                ]
            }
        ]
    };

    const options = {
        indexAxis: 'y',
        scales: {
            x: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    display: false,
                }
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: false,
                    drawBorder: false,
                }
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: 'Sales Funnel',
                color: "black",
            },
            datalabels: {
                display: true,
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                    size: 15
                },
                formatter: (value, context) => {
                    const index = context.dataIndex;
                    let label = '';

                    if (index === 0) {
                        label = NewArr.join(', ');
                        label = label.replace(/(.{74})/g, "$1\n");
                    } else if (index === 1) {
                        label = WorkingOnArr.join(', ');
                        label = label.replace(/(.{64})/g, "$1\n");
                    } else if (index === 2) {
                        label = MeetingArr.join(', ');
                        label = label.replace(/(.{54})/g, "$1\n");
                    } else if (index === 3) {
                        if (LikelyToCloseArr === 0) {
                            label = '';
                        } else {
                            label = `R ${Number(LikelyToCloseArr).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
                        }
                    } else if (index === 4) {
                        if (closedArr === 0) {
                            label = '';
                        } else {
                            label = `R ${Number(closedArr).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
                        }
                    }
                    // label = label.replace(/(.{74})/g, "$1\n");

                    return label;
                },
            },
        },
    };

    return (
        <div >
            <Bar data={data} options={options} id={id}/>
        </div>
    )
};

export default AEXFunnelBar;
