import React from 'react';
import './style.css';
function AionsPL({ aions = [], offset = 0 }) {
    // #region Dates
    let actualArray = [];
    let opexSet = new Set();
    let alkemiaSet = new Set();
    for (let m = 0; m < 6; m++) {
        let date = new Date();
        let month = date.getMonth();
        let year = date.getFullYear();
        month = month - m;
        if (month < 0) {
            month = month + 12;
            year = year - 1;
        }
        date.setMonth(month);
        date.setFullYear(year);
        let startDate = new Date(date);
        startDate.setDate(1);
        let endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() + 1);
        endDate.setDate(0);
        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);
        // #endregion
        let currentData = aions.filter((a) => (new Date(a.Date)) >= startDate && (new Date(a.Date)) <= endDate);
        let finalData = {
            Income: {
                Total: 0,
                AEX: 0,
                Interest: 0,
                Alkemia: 0,
                AlkemiaDetail: []
            },
            COS: {
                Total: 0,
                Alkemia: 0,
                AEX: 0
            },
            Telkom: {
                Drawdown: 0
            },
            OPEX: {
                Total: 0,
                AEX: 0,
                AEXDetail: [],
                Alkemia: 0,
                AlkemiaDetail: [],
                Aions: 0,
                AionsDetail: []
            },
            Date: `${startDate.getMonth() + 1}/${startDate.getFullYear()}`
        }

        for (let i = 0; i < currentData.length; i++) {
            let cData = currentData[i];
            //Income
            let cIncome = cData.Income
            let tempTotal = 0;
            tempTotal += cIncome.AEX;
            finalData.Income.AEX += cIncome.AEX;
            tempTotal += cIncome.Interest;
            finalData.Income.Interest += cIncome.Interest;
            for (let j = 0; j < cIncome.Alkemia.length; j++) {
                let cAlkemiaIncome = cIncome.Alkemia[j];
                alkemiaSet.add(cAlkemiaIncome.Client);
                tempTotal += cAlkemiaIncome.Amount;
                finalData.Income.Alkemia += cAlkemiaIncome.Amount
                let alkIndex = finalData.Income.AlkemiaDetail.findIndex(obj => obj.Client === cAlkemiaIncome.Client);
                if (alkIndex !== -1) {
                    finalData.Income.AlkemiaDetail[alkIndex].Amount += cAlkemiaIncome.Amount
                } else {
                    finalData.Income.AlkemiaDetail.push(cAlkemiaIncome);
                }
            }
            finalData.Income.Total += tempTotal
            //COS
            let cCOS = cData.COS;
            finalData.COS.Total += cCOS.AEX;
            finalData.COS.AEX += cCOS.AEX;
            finalData.COS.Total += cCOS.Alkemia;
            finalData.COS.Alkemia += cCOS.Alkemia;
            //Telkom
            finalData.Telkom.Drawdown += cData.Telkom.Amount
            //OPEX
            let cOPEX = cData.OPEX;
            let tOpexTotal = 0;
            for (let k = 0; k < cOPEX.Aions.length; k++) {
                let tOpex = cOPEX.Aions[k];
                opexSet.add(tOpex.Category);
                tOpexTotal += tOpex.Amount;
                let opexIndex = finalData.OPEX.AionsDetail.findIndex(obj => obj.Category === tOpex.Category);
                if (opexIndex !== -1) {
                    finalData.OPEX.AionsDetail[opexIndex].Amount += tOpex.Amount
                } else {
                    finalData.OPEX.AionsDetail.push(tOpex)
                }
            }
            finalData.OPEX.Total += tOpexTotal;
            finalData.OPEX.Aions += tOpexTotal;
        }
        actualArray.unshift(finalData);
    }
    let opexArray = Array.from(opexSet);
    let alkemiaArray = Array.from(alkemiaSet);
    

    return (
        <div className='aionspl'>
            <table style={{ width: '100%' }}>
                <tr>
                    <th className='itemRow'>Item</th>
                    {actualArray.map((actualDate, index) => {
                        return (
                            <th className='dataRow'>{actualDate.Date}</th>
                        )
                    })}
                </tr>
                <tr style={{ color: 'rgba(143,202,114,1)' }}>
                    <td style={{ fontWeight: 'bold' }}>Alkemia Income</td>
                </tr>
                {alkemiaArray.map((alkCat, index) => {
                    return (
                        <tr>
                            <td>{alkCat}</td>
                            {actualArray.map((opexData, index) => {
                                let op = opexData.Income.AlkemiaDetail.findIndex(obj => obj.Client === alkCat);
                                let opVal = 0;
                                if (op !== -1) {
                                    opVal = opexData.Income.AlkemiaDetail[op].Amount;
                                }
                                return (
                                    <td className='dataRow' style={{ fontWeight: 'bold' }}>R{opVal.toFixed(2)}</td>
                                )
                            })}
                        </tr>
                    )
                })}
                <tr style={{ border: '3px solid white', color: 'rgba(143,202,114,1)' }}>
                    <td style={{ fontWeight: 'bold' }}>Total Sales</td>
                    {actualArray.map((tsData, index) => {
                        return (
                            <td className='dataRow' style={{ fontWeight: 'bold' }}>R{tsData.Income.Alkemia.toFixed(2)}</td>
                        )
                    })}
                </tr>
                <tr>
                    <td style={{ fontWeight: 'bold', color: 'rgba(143,202,114,1)' }}>Other Income</td>
                </tr>
                <tr>
                    <td>AEX</td>
                    {actualArray.map((aexData, index) => {
                        return (
                            <td className='dataRow'>R{aexData.Income.AEX.toFixed(2)}</td>
                        )
                    })}
                </tr>
                <tr>
                    <td>Interest</td>
                    {actualArray.map((isData, index) => {
                        return (
                            <td className='dataRow'>R{isData.Income.Interest.toFixed(2)}</td>
                        )
                    })}
                </tr>
                <tr style={{ border: '3px solid white', color: 'rgba(143,202,114,1)' }}>
                    <td style={{ fontWeight: 'bold' }}>Total</td>
                    {actualArray.map((iaexData, index) => {
                        return (
                            <td className='dataRow' style={{ fontWeight: 'bold' }}>R{(iaexData.Income.AEX + iaexData.Income.Interest).toFixed(2)}</td>
                        )
                    })}
                </tr>
                <tr style={{ color: 'rgba(146,140,195,1)' }}>
                    <td style={{ fontWeight: 'bold' }}>Cost Of Sales</td>
                </tr>
                <tr>
                    <td>Alkemia</td>
                    {actualArray.map((alkCos, index) => {
                        return (
                            <td className='dataRow'>R{alkCos.COS.Alkemia.toFixed(2)}</td>
                        )
                    })}
                </tr>
                <tr>
                    <td>AEX</td>
                    {actualArray.map((aexCos, index) => {
                        return (
                            <td className='dataRow'>R{aexCos.COS.AEX.toFixed(2)}</td>
                        )
                    })}
                </tr>
                <tr style={{ border: '3px solid white', color: 'rgba(146,140,195,1)' }}>
                    <td style={{ fontWeight: 'bold' }}>Total</td>
                    {actualArray.map((ttCos, index) => {
                        return (
                            <td className='dataRow' style={{ fontWeight: 'bold' }}>R{(ttCos.COS.Total).toFixed(2)}</td>
                        )
                    })}
                </tr>
                <tr style={{ border: '3px solid white' }}>
                    <td style={{ fontWeight: 'bold' }}>Gross Profit</td>
                    {actualArray.map((gpData, index) => {
                        let value = gpData.Income.Total - gpData.COS.Total;
                        let textColor = value < 0 ? 'rgba(227, 54, 126, 1)' : 'rgb(143, 202, 114)';
                        return (
                            <td className='dataRow' style={{ fontWeight: 'bold', color: textColor }}>R{(gpData.Income.Total - gpData.COS.Total).toFixed(2)}</td>
                        )
                    })}
                </tr>
                <tr>
                    <td style={{ fontWeight: 'bold', color: 'rgba(227,54,126,1)' }}>Opex</td>
                </tr>
                {opexArray.map((opexCat, index) => {
                    return (
                        <tr>
                            <td>{opexCat}</td>
                            {actualArray.map((opexData, index) => {
                                let op = opexData.OPEX.AEXDetail.findIndex(obj => obj.Category === opexCat);
                                let opVal = 0;
                                if (op !== -1) {
                                    opVal = opexData.OPEX.AEXDetail[op].Amount;
                                }
                                return (
                                    <td className='dataRow' style={{ fontWeight: 'bold' }}>R{opVal.toFixed(2)}</td>
                                )
                            })}
                        </tr>
                    )
                })}
                <tr style={{ border: '3px solid white', color: 'rgba(227,54,126,1)' }}>
                    <td style={{ fontWeight: 'bold' }}>Total</td>
                    {actualArray.map((opexTtlData, index) => {
                        return (
                            <td className='dataRow' style={{ fontWeight: 'bold' }}>R{opexTtlData.OPEX.Aions.toFixed(2)}</td>
                        )
                    })}
                </tr>
                <tr style={{ border: '3px solid white' }}>
                    <td style={{ fontWeight: 'bold' }}>Profit/Loss</td>
                    {actualArray.map((plData, index) => {
                        let profitOrLossValue = plData.Income.Total - plData.COS.Total - plData.OPEX.Aions;
                        let textColor = profitOrLossValue < 0 ? 'rgba(227, 54, 126, 1)' : 'rgb(143, 202, 114)';
                        return (
                            <td className='dataRow' style={{ fontWeight: 'bold', color: textColor }}>R{(plData.Income.Total - plData.COS.Total - plData.OPEX.Aions).toFixed(2)}</td>
                        );
                    })}
                </tr>
                <tr style={{ color: 'rgba(49,192,209,1)' }}>
                    <td>Telkom Drawdown</td>
                    {actualArray.map((telkData, index) => {
                        return (
                            <td className='dataRow'>R{telkData.Telkom.Drawdown.toFixed(2)}</td>
                        )
                    })}
                </tr>
            </table>
        </div>
    )
}

export default AionsPL;