import React from 'react';
import { Bar } from 'react-chartjs-2';

const AEXGenderSignups = ({ Aex = [], Arr = [], id }) => {
    let male = 0;
    let female = 0;
    let unknown = 0;
    Arr.forEach((item) => {
        let gender = item.Gender;
        if (gender === 'male') {
            male += 1;
        } else if (gender === 'female') {
            female += 1;
        } else {
            unknown += 1;
        }
    })
    const obj = Aex.map(item => ({
        Label: item.Label,
        Amount: item.Amount,
    }));
    obj.sort((a, b) => new Date(a.Label) - new Date(b.Label));

    const labels = obj.map(item => item.Label);
    const data = obj.map(item => item.Amount);
    const options = {
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'AEX Male and Female Signups',
            },
            datalabels: {
                display: true,
                color: 'black',
                font: {
                    weight: 'bold',
                    size: 15,
                },
                formatter: (value) => value > 0 ? `${Number(value).toLocaleString()}` : '',
            },
        },
        scales: {
            x: {
                display: true,
                position: 'bottom',
            },
        },
        responsive: true,
    };

    const chartData = {
        labels: ['Male', 'Female', 'Unknown'],
        datasets: [
            {
                label: 'AEX Male and Female Signups',
                data: [male, female, unknown],
                backgroundColor: 'rgb(227, 54, 126)',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
                categoryPercentage: 1,
            },
        ],
    };
    return <Bar options={options} data={chartData} id={id} />;
};

export default AEXGenderSignups;