import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const VerticalGraph = ({ width, height, inputData, type }) => {
  const svgRef = useRef();

  const transformBurnrateData = (data) => {
    return data.map(item => ({
      month: item.date,
      value: item.Burnrate,
      type: item.Type
    }));
  }

  const transformCashflowData = (data) => {
    return data.map(item => ({
      month: item.date,
      value: item.Cashflow,
      type: item.Type
    }));
  }

  let data;
  if (type === 'Burnrate') {
    data = transformBurnrateData(inputData);
  } else {
    data = transformCashflowData(inputData);
  }

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const margin = { top: 20, right: 20, bottom: 30, left: 40 };
    svg.selectAll('*').remove();

    const x = d3.scaleBand()
      .domain(data.map(d => d.month))
      .range([margin.left, width - margin.right])
      .padding(0.05);

    const yMax = d3.max(data, d => d.value);
    const yDomain = [0, yMax * 1.05];
    const y = d3.scaleLinear()
      .domain(yDomain).nice(4)
      .range([height - margin.bottom, margin.top]);

    // Append x-axis without the line
    const xAxis = svg.append('g')
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(x).tickFormat(d => {
        const date = new Date(d);
        return d3.timeFormat('%b')(date)[0]; // Format to show only the first letter of the month
      }));

    // Remove the default x-axis line
    xAxis.select('.domain').remove();
    const tooltip = d3.select('body').append('div')
      .style('position', 'absolute')
      .style('background', '#ffffff')
      .style('box-shadow', '0 0 10px rgba(0, 0, 0, 1)')
      .style('padding', '5px')
      .style('display', 'none')
      .style('pointer-events', 'none')
      .style('color', '#213038')
      .style('font-weight', '300')
      .style('font-size', '15px')
      .style('text-align', 'center')
      .style('align-items', 'center')
      .style('justify-content', 'center')
      .style('border-radius', '85px')
      .style('min-width', '100px')
      .style('height', '65px')
      .style('z-index', '5')
      .style('flex-direction', 'column');

      function formatDateToMonthYear(dateString) {
        const date = new Date(dateString);
        
        const options = { year: 'numeric', month: 'long' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        
        return formattedDate;
    }
    const showTooltip = (event, value, obj, element) => {
      let itemDate = obj.month
      let category = obj.type || [];
      tooltip
        .style('left', (event.pageX + 10) + 'px')
        .style('top', (event.pageY + 10) + 'px')
        .style('display', 'flex')
        .html(`
        <p style="line-height: 0px; background-color: green; font-size: 15px;" >${category}</p>
        <p style="line-height: 0px; font-size: 20px; font-weight: 700; margin: 6px;">R ${value.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
        <p style="line-height: 0px; font-size: 15px;">${formatDateToMonthYear(itemDate)}</p>
      `);

      // Blur other bars, when tooltip open
      d3.selectAll('.bar-actual, .bar-forecast, .bar-deficit, .bar-difference').transition().duration(600).style('opacity', 0.3);
      d3.select(element).transition().duration(600).style('opacity', 1);
    };

    const hideTooltip = () => {
      tooltip.style('display', 'none');
      d3.selectAll('.bar-actual, .bar-forecast, .bar-deficit, .bar-difference').style('opacity', 1);
    };
    // Style x-axis text
    xAxis.selectAll('text')
      .style('fill', '#213038')
      .style('font-weight', '300')
      .style('font-size', '18px');

    // Append a group for the y-axis with grid lines
    const yAxisGrid = svg.append("g")
      .attr("class", "grid")
      .attr("transform", `translate(${margin.left}, 0)`)
      .call(d3.axisLeft(y)
        .ticks(4) // Ensure exactly 4 ticks
        .tickSizeInner(-(width - margin.left - margin.right)) // Length of the grid lines
        .tickSizeOuter(0) // Hide the outer ticks
        .tickFormat(d3.format(".2s")) // Show the y-axis labels with 2 significant digits
      );

    // Style y-axis text and add margin-left
    yAxisGrid.selectAll('text')
      .style('fill', '#213038')
      .style('font-weight', '300')
      .style('font-size', '18px')
      .attr('dx', '2px'); // Add margin-left to y-axis text

    svg.selectAll(".grid line")
      .style("stroke", "black")
      .style("stroke-opacity", 0.3);

    // Remove the y-axis line
    yAxisGrid.select('.domain').remove();

    svg.selectAll("text")
      .style("font-weight", "300")
      .style("font-size", "18px");

    const filteredData = data.filter(d => d.value > 0);
    svg.selectAll('.bar')
      .data(filteredData)
      .join('path')
      .attr('class', 'bar')
      .attr('d', d => {
        if (d.value <= 0) return null;
        const xPos = x(d.month) + x.bandwidth() / 3;
        const yPos = y(d.value);
        const barWidth = x.bandwidth() / 3;
        const barHeight = y(0) - y(d.value);
        const radius = 10;
        return `
        M${xPos},${yPos + radius}
        Q${xPos},${yPos} ${xPos + radius},${yPos}
        H${xPos + barWidth - radius}
        Q${xPos + barWidth},${yPos} ${xPos + barWidth},${yPos + radius}
        V${yPos + barHeight}
        H${xPos}
        Z
      `;
      })
      .on('mouseover', function (event, d) { showTooltip(event, d.value, d, this); })
      .on('mouseout', hideTooltip)
      .attr('fill', d => d.type === 'Actual' ? '#D5922D' : '#213038');
  }, [width, height, inputData, type]);
  return (
    <svg ref={svgRef} width={width} height={height} style={{ marginLeft: '5px' }}></svg>
  );
};
export default VerticalGraph;