import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import '../style.css';
import isMobile from 'is-mobile';
import UpdateTargets from './UpdateTargets';
import Config from './Config';
import Credits from './Credits';
import HomePage from '../../homePage';
import UpdateActuals from './UpdateActuals';
import AddTargets from './AddTargets';
import { Card } from "../../../components"
import { FaHome } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

const EditCompanies = observer(() => {
    //#region UseSates
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const store = useStore();
    const navigate = useNavigate();
    let params = useParams()
    const [CurrentPage, setCurrentPage] = useState('AllCourses');
    const [SelectedCompanyId, setSelectedCompanyID] = useState({})

    // const useParams = useParams


    useEffect(() => {
        setSelectedCompanyID(params.id)
    }, []);

    function navClicked(e) {
        setCurrentPage(e.target.id);
    }
    function homeClicked() {
        navigate('/dashboard');
    }

    //#endregion
    if (isMobile()) {
        return (
            <div >
                <div style={{display: "flex"}}>
                    <Card id="Details" className={`${CurrentPage === "Details" ? "tabCardsSelectedForCompanies" : "tabCardsForCompanies"}`} onClick={navClicked} >Details</Card>
                    <Card id="HomePage" className={`${CurrentPage === "HomePage" ? "tabCardsSelectedForCompanies" : "tabCardsForCompanies"}`} onClick={homeClicked} >Home</Card>
                    <Card id="AddTargets" className={`${CurrentPage === "AddTargets" ? "tabCardsSelectedForCompanies" : "tabCardsForCompanies"}`} onClick={navClicked} >New Targets</Card>
                    <Card id="UpdateTargets" className={`${CurrentPage === "UpdateTargets" ? "tabCardsSelectedForCompanies" : "tabCardsForCompanies"}`} onClick={navClicked} >Targets</Card>
                    <Card id="UpdateActuals" className={`${CurrentPage === "UpdateActuals" ? "tabCardsSelectedForCompanies" : "tabCardsForCompanies"}`} onClick={navClicked} >Actuals</Card>
                    <Card id="Credits" className={`${CurrentPage === "Credits" ? "tabCardsSelectedForCompanies" : "tabCardsForCompanies"}`} onClick={navClicked} >Credits</Card>
                    <Card id="Config" className={`${CurrentPage === "Config" ? "tabCardsSelectedForCompanies" : "tabCardsForCompanies"}`} onClick={navClicked} >Config</Card>

                </div>
                <div>
                    {(CurrentPage === 'AddTargets') ? <AddTargets SelectedCompanyId={SelectedCompanyId} /> : null}
                    {(CurrentPage === 'UpdateActuals') ? <UpdateActuals SelectedCompanyId={SelectedCompanyId} /> : null}
                    {(CurrentPage === 'Credits') ? <Credits SelectedCompanyId={SelectedCompanyId} /> : null}
                    {(CurrentPage === 'Config') ? <Config SelectedCompanyId={SelectedCompanyId} /> : null}
                    {(CurrentPage === 'UpdateTargets') ? <UpdateTargets SelectedCompanyId={SelectedCompanyId} /> : null}
                    {(CurrentPage === 'HomePage') ? <HomePage /> : null}
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <div className="Tabs">
                    <Card id="HomePage" className={`${CurrentPage === "HomePage" ? "tabCardsSelectedForCompanies" : "tabCardsForCompanies"}`} onClick={homeClicked} >Home</Card>
                    <Card id="Details" className={`${CurrentPage === "Details" ? "tabCardsSelectedForCompanies" : "tabCardsForCompanies"}`} onClick={navClicked} >Details</Card>
                    <Card id="AddTargets" className={`${CurrentPage === "AddTargets" ? "tabCardsSelectedForCompanies" : "tabCardsForCompanies"}`} onClick={navClicked} >New Targets</Card>
                    <Card id="UpdateTargets" className={`${CurrentPage === "UpdateTargets" ? "tabCardsSelectedForCompanies" : "tabCardsForCompanies"}`} onClick={navClicked} >Update Targets</Card>
                    <Card id="UpdateActuals" className={`${CurrentPage === "UpdateActuals" ? "tabCardsSelectedForCompanies" : "tabCardsForCompanies"}`} onClick={navClicked} >Update Actuals</Card>
                    <Card id="Credits" className={`${CurrentPage === "Credits" ? "tabCardsSelectedForCompanies" : "tabCardsForCompanies"}`} onClick={navClicked} >Credits</Card>
                    <Card id="Config" className={`${CurrentPage === "Config" ? "tabCardsSelectedForCompanies" : "tabCardsForCompanies"}`} onClick={navClicked} >Config</Card>

                </div>
                <div>
                    {(CurrentPage === 'AddTargets') ? <AddTargets SelectedCompanyId={SelectedCompanyId} /> : null}
                    {(CurrentPage === 'UpdateActuals') ? <UpdateActuals SelectedCompanyId={SelectedCompanyId} /> : null}
                    {(CurrentPage === 'Credits') ? <Credits SelectedCompanyId={SelectedCompanyId} /> : null}
                    {(CurrentPage === 'Config') ? <Config SelectedCompanyId={SelectedCompanyId} /> : null}
                    {(CurrentPage === 'UpdateTargets') ? <UpdateTargets SelectedCompanyId={SelectedCompanyId} /> : null}
                    {(CurrentPage === 'HomePage') ? <HomePage /> : null}
                </div>
            </div>
        );
    }
});

export default EditCompanies;