import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from "../../../stores/Store";
import { Card, Button } from '../../../components';
import isMobile from 'is-mobile';
import { useNavigate, useParams } from "react-router-dom";
import AionsIcon from '../../../assets/images/Aions.svg';
import loserPng from '../../../assets/images/failed.png';
import redoPng from '../../../assets/images/redo.png';
import dancingPng from '../../../assets/images/congrats.png';

const ViewCourse = observer(() => {
    const store = useStore();
    const params = useParams();
    const [, updateState] = useState();
    const [Course, setCourse] = useState({});
    const [CourseId, setCourseId] = useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Content, setContent] = useState([]);
    const navigate = useNavigate();
    const [Quiz, setQuiz] = useState([]);
    const [QuizContent, setQuizContent] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [Results, setResults] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [QuizPercentage, setQuizPercentage] = useState(0);
    const [showWarning, setShowWarning] = useState(false);
    const [Informations, setInformations] = useState(true);
    const [canRetakeQuiz, setCanRetakeQuiz] = useState(false);
    const [quizCompleted, setQuizCompleted] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [videoLink, setVideoLink] = useState('');

    useEffect(() => {
        loadCourse();
        if (Content.some(item => item.Type === 'Quiz')) {
            setQuizContent(true);
        }
        if (QuizPercentage >= 50 && QuizPercentage < 80) {
            setCanRetakeQuiz(true);
        }
    }, [QuizPercentage]);

    function loadCourse() {
        setCourseId(params.id);
        let id = params.id;
        store.setLoading(true);
        store.getCoursesByID(id).then((ret) => {
            setContent(ret.Content);
            setQuiz(ret.Quiz);
            setCourse(ret);
            store.setLoading(false);
        });
        forceUpdate();
    }
    function courseDone() {
        const userEmail = store.user.Email;
        const courseId = Course._id;
        store.setLoading(true);

        store.getCoursesByID(courseId).then((courseData) => {
            let completedByArray = courseData.CompletedBy || [];
            const existingEntryIndex = completedByArray.findIndex((entry) => entry.Email === userEmail);
            let updatedData;

            if (existingEntryIndex !== -1) {
                if (Course.Type === "Quiz") {
                    completedByArray[existingEntryIndex].Score = QuizPercentage;
                } else {
                    delete completedByArray[existingEntryIndex].Score;
                    completedByArray[existingEntryIndex].Status = "Completed";
                }
                updatedData = {
                    CompletedBy: completedByArray,
                };
            } else {
                let newEntry = {
                    Email: userEmail,
                };

                if (Course.Type === "Quiz") {
                    newEntry.Score = QuizPercentage;
                } else {
                    newEntry.Status = "Completed";
                }
                completedByArray.push(newEntry);
                updatedData = {
                    CompletedBy: completedByArray,
                };
            }
            store.updateCourseById(courseId, updatedData).then((retObj) => {
                window.alert('Congratulations you have Finished');
                setCourse(retObj);
                store.setLoading(false);
                navigate('/dashboard')
            });
        });
    }
    function handleRetakeQuiz() {
        setQuizContent(true);
        setResults(false);
        setCurrentQuestionIndex(0);
        setQuizPercentage(0);
        setCanRetakeQuiz(false);
        setSelectedOption(null);
        setQuiz(
            Quiz.map((question) => ({
                ...question,
                userAnswer: null,
            }))
        );
        setQuizCompleted(false);
    }
    function homeClicked() {
        navigate('/dashboard');
    }
    function handleOptionChange(e) {
        setSelectedOption(e.target.value);
    }
    function handleSubmit() {
        if (selectedOption !== null) {
            const newAnswers = [...Quiz];
            newAnswers[currentQuestionIndex].userAnswer = selectedOption;
            setQuiz(newAnswers);

            if (currentQuestionIndex < Quiz.length - 1) {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
            } else {
                const numCorrectAnswers = newAnswers.reduce((acc, question) => {
                    if (question.userAnswer === question.CorrectAnswer) {
                        return acc + 1;
                    }
                    return acc;
                }, 0);
                const percentageCorrect = (numCorrectAnswers / Quiz.length) * 100;
                setQuizPercentage(percentageCorrect);

                setQuizCompleted(true);
                setResults(true);
            }

            setSelectedOption(null);
            setShowWarning(false);
        } else {
            setShowWarning(true);
        }
    }
    function handleBack() {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
            setSelectedOption(null);
        }
    }
    function handleStartQuiz() {
        setQuizContent(true);
        setInformations(false)
        setQuizCompleted(false);
        setCurrentQuestionIndex(0);
        setSelectedOption(null);
    }
    function removeYouTubeLinkPrefix(content) {
        const youtubeLinkPrefix = "https://youtu.be/";
        if (content.startsWith(youtubeLinkPrefix)) {
            return content.replace(youtubeLinkPrefix, "");
        }
        return content;
    }
    if (isMobile()) {
        return (
            <div>
                {Course && (
                    <Card className='ViewCourseCard'>
                        <img className="Images" src={AionsIcon} />

                        {Content && Content.map((item, index) => (
                            <div key={index}>
                                {Informations ? (
                                    <div>
                                        <h4>Name: {Course.Name}</h4>
                                        <h4>Required: {Course.Required ? 'Yes' : 'No'}</h4>
                                        <h4>Type: {item.Type}</h4>
                                        <h4>Content: {item.Content}</h4>
                                    </div>
                                ) : null}
                                {item.Type === 'Video' && !showVideo && (
                                    <Button onClick={() => { setVideoLink(removeYouTubeLinkPrefix(item.Content)); setShowVideo(true); }}>
                                        View Video
                                    </Button>
                                )}
                                {item.Type === 'Video' && showVideo && (
                                    <div>
                                        <iframe
                                            title="YouTube Video"
                                            width="560"
                                            height="315"
                                            src={`https://www.youtube.com/embed/${videoLink}`}
                                            frameBorder="0"
                                            allowFullScreen
                                        />
                                        <Button onClick={() => setShowVideo(false)}>Back</Button>
                                    </div>
                                )}
                            </div>
                        ))}

                        {QuizContent && !quizCompleted && (
                            <div>
                                {currentQuestionIndex < Quiz.length ? (
                                    <div>
                                        <h2>Quiz</h2>
                                        <h3>Question {currentQuestionIndex + 1}: {Quiz[currentQuestionIndex].Question}</h3>
                                        {Object.entries(Quiz[currentQuestionIndex]).map(([optionKey, optionValue], index) => {
                                            if (optionKey.startsWith("Option")) {
                                                return (
                                                    <div key={index}>
                                                        <input
                                                            type="radio"
                                                            id={optionKey}
                                                            name="options"
                                                            value={optionValue}
                                                            checked={optionValue === selectedOption}
                                                            onChange={handleOptionChange}
                                                        />
                                                        <label htmlFor={optionKey}>{optionValue}</label>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                        {showWarning && (
                                            <p style={{ color: 'red' }}>Please select an answer before proceeding to the next question.</p>
                                        )}
                                        {currentQuestionIndex > 0 && (
                                            <Button className='btnHome' onClick={handleBack}>Back</Button>
                                        )}
                                        <Button className='btnHome' onClick={handleSubmit} disabled={selectedOption === null}>
                                            {currentQuestionIndex === Quiz.length - 1 ? "Submit" : "Next"}
                                        </Button>
                                    </div>
                                ) : null}
                            </div>
                        )}
                        {Results && (
                            <div>
                                <div className="result-animation">
                                    {QuizPercentage < 50 ? (
                                        <div>
                                            <img className="animated-emoji dancing" src={loserPng} alt="Loser" />
                                            <p>Contact your mentor for assistance.</p>
                                        </div>
                                    ) : QuizPercentage >= 50 && QuizPercentage < 80 ? (
                                        <div>
                                            <img className="animated-emoji redo" src={redoPng} alt="Retake" />
                                            <p>You can retake the quiz to improve your score.</p>
                                            <Button className='btnHome' onClick={handleRetakeQuiz}>Retake Quiz</Button>
                                        </div>
                                    ) : (
                                        <div>
                                            <img className="animated-emoji dancing" src={dancingPng} alt="Dancing" />
                                            <p>Congratulations! You have completed the Course!</p>
                                        </div>
                                    )}
                                    <p>Your Percentage: {QuizPercentage.toFixed(2)}%</p>
                                </div>
                            </div>
                        )}
                        <div className='courseButtons'>
                            <Button className='btnHome' onClick={homeClicked}>Home</Button>
                            {!QuizContent && Content.some(item => item.Type === 'Quiz') && (
                                <Button className='btnHome' onClick={handleStartQuiz}>Take Quiz</Button>
                            )}
                            <Button className='btnHome' onClick={courseDone}>Done</Button>
                        </div>
                    </Card>
                )}
            </div>
        )
    } else {
        return (
            <div>
                {Course && (
                    <Card className='ViewCourseCard'>
                        <img className="Images" src={AionsIcon} />

                        {Content && Content.map((item, index) => (
                            <div key={index}>
                                {Informations ? (
                                    <div>
                                        <h4>Name: {Course.Name}</h4>
                                        <h4>Required: {Course.Required ? 'Yes' : 'No'}</h4>
                                        <h4>Type: {item.Type}</h4>
                                        <h4>Content: {item.Content}</h4>
                                    </div>
                                ) : null}
                                {item.Type === 'Video' && !showVideo && (
                                    <Button onClick={() => { setVideoLink(removeYouTubeLinkPrefix(item.Content)); setShowVideo(true); }}>
                                        View Video
                                    </Button>
                                )}
                                {item.Type === 'Video' && showVideo && (
                                    <div>
                                        <iframe
                                            title="YouTube Video"
                                            width="560"
                                            height="315"
                                            src={`https://www.youtube.com/embed/${videoLink}`}
                                            frameBorder="0"
                                            allowFullScreen
                                        />
                                        <Button onClick={() => setShowVideo(false)}>Back</Button>
                                    </div>
                                )}
                            </div>
                        ))}

                        {QuizContent && !quizCompleted && (
                            <div>
                                {currentQuestionIndex < Quiz.length ? (
                                    <div>
                                        <h2>Quiz</h2>
                                        <h3>Question {currentQuestionIndex + 1}: {Quiz[currentQuestionIndex].Question}</h3>
                                        {Object.entries(Quiz[currentQuestionIndex]).map(([optionKey, optionValue], index) => {
                                            if (optionKey.startsWith("Option")) {
                                                return (
                                                    <div key={index}>
                                                        <input
                                                            type="radio"
                                                            id={optionKey}
                                                            name="options"
                                                            value={optionValue}
                                                            checked={optionValue === selectedOption}
                                                            onChange={handleOptionChange}
                                                        />
                                                        <label htmlFor={optionKey}>{optionValue}</label>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                        {showWarning && (
                                            <p style={{ color: 'red' }}>Please select an answer before proceeding to the next question.</p>
                                        )}
                                        {currentQuestionIndex > 0 && (
                                            <Button className='btnHome' onClick={handleBack}>Back</Button>
                                        )}
                                        <Button className='btnHome' onClick={handleSubmit} disabled={selectedOption === null}>
                                            {currentQuestionIndex === Quiz.length - 1 ? "Submit" : "Next"}
                                        </Button>
                                    </div>
                                ) : null}
                            </div>
                        )}
                        {Results && (
                            <div>
                                <div className="result-animation">
                                    {QuizPercentage < 50 ? (
                                        <div>
                                            <img className="animated-emoji dancing" src={loserPng} alt="Loser" />
                                            <p>Contact your mentor for assistance.</p>
                                        </div>
                                    ) : QuizPercentage >= 50 && QuizPercentage < 80 ? (
                                        <div>
                                            <img className="animated-emoji redo" src={redoPng} alt="Retake" />
                                            <p>You can retake the quiz to improve your score.</p>
                                            <Button className='btnHome' onClick={handleRetakeQuiz}>Retake Quiz</Button>
                                        </div>
                                    ) : (
                                        <div>
                                            <img className="animated-emoji dancing" src={dancingPng} alt="Dancing" />
                                            <p>Congratulations! You have completed the Course!</p>
                                        </div>
                                    )}
                                    <p>Your Percentage: {QuizPercentage.toFixed(2)}%</p>
                                </div>
                            </div>
                        )}
                        <div className='courseButtons'>
                            <Button className='btnHome' onClick={homeClicked}>Home</Button>
                            {!QuizContent && Content.some(item => item.Type === 'Quiz') && (
                                <Button className='btnHome' onClick={handleStartQuiz}>Take Quiz</Button>
                            )}
                            <Button className='btnHome' onClick={courseDone}>Done</Button>
                        </div>
                    </Card>
                )}
            </div>
        )
    }
});

export default ViewCourse; 