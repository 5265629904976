import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const DepartmentReport = ({ }) => {
    const ref = useRef();
    const data = [
        { Company: "Tubby's Kitchen", DesignTasks: 19, DevTasks: 38 },
        { Company: 'Scooter', DesignTasks: 10, DevTasks: 25 },
        { Company: 'Delivery Ka Speed', DesignTasks: 12, DevTasks: 20 },
        { Company: 'Welo', DesignTasks: 7, DevTasks: 18 },
        { Company: 'SpaceSalad Studios', DesignTasks: 5, DevTasks: 28 },
        { Company: 'Taco', DesignTasks: 19, DevTasks: 38 },
        { Company: 'Level', DesignTasks: 10, DevTasks: 25 },
        { Company: 'Credipple', DesignTasks: 12, DevTasks: 20 },
        { Company: 'COCO', DesignTasks: 22, DevTasks: 18 },
    ];
    const width = 1400;
    const height = 360;
    useEffect(() => {
        const svg = d3.select(ref.current);
        const margin = { top: 20, right: 20, bottom: 20, left: 200 };

        svg.selectAll('*').remove();
        svg.attr('width', width).attr('height', height);

        const designTasksMax = d3.max(data, d => d.DesignTasks);
        const devTasksMax = d3.max(data, d => d.DevTasks);
        const finalMax = Math.max(designTasksMax, devTasksMax);
        const maxValue = finalMax * 1.05;

        const x = d3.scaleLinear()
            .domain([-maxValue, maxValue])
            .range([margin.left, width - margin.right]);

        const y = d3.scaleBand()
            .domain(data.map(d => d.Company))
            .range([margin.top, height - margin.bottom])
            .padding(0.1);

        const yAxis = svg.append('g')
            .attr('transform', `translate(${margin.left - 180},0)`)
            .call(d3.axisLeft(y).tickSize(0))
            .selectAll('text')
            .attr('font-weight', '300')
            .attr('font-size', '20px')
            .style('text-anchor', 'start');

        svg.append('g')
            .attr('transform', `translate(0,${height - margin.bottom})`)
            .call(d3.axisBottom(x)
                .ticks(7)
                .tickFormat(function (d) {
                    return d3.format(".0s")(Math.abs(d));
                })
                .tickSize(-height + margin.top + margin.bottom)
            )
            .selectAll('text')
            .attr('font-weight', '300')
            .attr('font-size', '20px')
            .attr('text-color', '20px')
            .selectAll('line')
            .remove();

        svg.selectAll('.tick line')
            .attr('stroke', '#E5E5E5')
            .attr('stroke-width', 1);

        svg.selectAll('.tick line')
            .filter((d, i) => d === 0)
            .attr('stroke', 'black')
            .attr('stroke-width', 2);

        svg.selectAll('.dev-bar')
            .data(data)
            .enter()
            .append('path')
            .attr('class', 'dev-bar')
            .attr('d', d => roundedRect(x(0), y(d.Company) + 10, x(d.DevTasks) - x(0), y.bandwidth() - 20, 5, 'Yes'))
            .attr('fill', '#D5922D')
            .on('mouseover', function (event, d) { showTooltip(event, d, this); })
            .on('mouseout', hideTooltip);

        svg.selectAll('.design-bar')
            .data(data)
            .enter()
            .append('path')
            .attr('class', 'design-bar')
            .attr('d', d => roundedRect(x(-d.DesignTasks), y(d.Company) + 10, x(0) - x(-d.DesignTasks), y.bandwidth() - 20, 5, 'No'))
            .attr('fill', '#213038')
            .on('mouseover', function (event, d) { showTooltip(event, d, this); })
            .on('mouseout', hideTooltip);

        const tooltip = d3.select('body').append('div')
            .attr('class', 'tooltip')
            .style('position', 'absolute')
            .style('background', '#ffffff')
            .style('box-shadow', '0 0 10px rgba(0, 0, 0, 0.1)')
            .style('padding', '5px')
            .style('display', 'none')
            .style('pointer-events', 'none')
            .style('color', '#213038')
            .style('font-weight', '300')
            .style('font-size', '15px')
            .style('text-align', 'center')
            .style('border-radius', '85px')
            .style('min-width', '100px')
            .style('height', '75px')
            .style('z-index', '5');

        function showTooltip(event, d, element) {
            tooltip
                .style('left', (event.pageX + 10) + 'px')
                .style('top', (event.pageY + 10) + 'px')
                .style('display', 'block')
                .html(`<p style="line-height: 0px; font-size: 20px;">${d.Company}</p>
                    <p style="line-height: 0px; font-size: 20px; font-weight: 700; margin: 15px;self-align: left">Dev Tasks: ${d.DevTasks}</p>
                    <p style="line-height: 0px; font-size: 20px; font-weight: 700; margin: 22px;">Design Tasks: ${d.DesignTasks}</p>
                    `);
            d3.selectAll('.design-bar,.dev-bar')
                .transition()
                .duration(600)
                .style('opacity', function (d1) {
                    return d.Company === d1.Company ? 1 : 0.3;
                });
            d3.selectAll('.tick text')
                .transition()
                .duration(600)
                .style('opacity', function (d1) {
                    return d.Company === d1 ? 1 : 0.3;
                });
        }

        function hideTooltip() {
            tooltip.style('display', 'none');
            d3.selectAll('.design-bar,.dev-bar').transition().duration(600).style('opacity', 1);
            d3.selectAll('.tick text').transition().duration(600).style('opacity', 1);
        }

        svg.attr('style', 'border: none');
        svg.selectAll('g').selectAll('path').attr('stroke', 'none');
    }, [data]);

    const roundedRect = (x, y, width, height, radius, roundRight) => {
        if (roundRight === 'Yes') {
            return `M${x},${y}
                  h${width - radius}
                  a${radius},${radius} 0 0 1 ${radius},${radius}
                  v${height - 2 * radius}
                  a${radius},${radius} 0 0 1 -${radius},${radius}
                  h-${width - radius}
                  z`;
        } else {
            return `M${x + radius},${y}
                  h${width - radius}
                  v${height}
                  h-${width - radius}
                  a${radius},${radius} 0 0 1 -${radius},-${radius}
                  v-${height - 2 * radius}
                  a${radius},${radius} 0 0 1 ${radius},-${radius}
                  z`;
        }
    };

    return <svg ref={ref}></svg>;
};

export default DepartmentReport;