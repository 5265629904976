import React, { useEffect, useState, updateState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import CloseCard from '../../../assets/images/CloseTask.svg';
import Archive from '../../../assets/images/ArchiveIcon.svg';
import CloseArchive from '../../../assets/images/closeArchive.svg';
import unArchiveIcon from '../../../assets/images/UnArchiveIcon.svg';
import Filter from '../../../assets/images/Filter.svg';
import FilterDown from '../../../assets/images/FilterDown.svg';
import AddIcon from '../../../assets/images/addMeeting.svg'
import { Input, View, Button, Card, InvesteeSidebar, Textbox, Textarea, Calender, CustomSelect, StatusChange, UnArchiveHover, ArchiveTask, ResizableTextarea } from '../../../components';

var mobile = require('is-mobile');
const Alkemia = observer(() => {
    const store = useStore();
    const navigate = useNavigate();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [TabSelected, setTabSelected] = useState('requested');
    const [Tasks, setTasks] = useState([]);
    const [SelectedTask, setSelectedTask] = useState({});
    const [ViewTask, setViewTask] = useState(false);
    const [IsHovered, setIsHovered] = useState(new Array(Tasks.length).fill(false));
    const [ViewArchived, setViewArchived] = useState(false);
    const [AdditionsStatus, setAdditionalStatus] = useState('');
    const [StatusHover, setStatusHover] = useState(false);
    const [FilterComps, setFilterComps] = useState('');
    const [TitleFilter, setTitleFilter] = useState('A-Z');
    const [DateFilter, setDateFilter] = useState('');
    const [ViewArchivesHover, setViewArchivesHover] = useState(false);
    const [StatusChange, setStatusChange] = useState(false);
    const [MonthlyCredits, setMonthlyCredits] = useState(false);
    const [Credits, setCredits] = useState([]);
    const [CredsUsed, setCredsUsed] = useState(0);
    const [CredsDate, setCredsDate] = useState(null);
    const [TotCreds, setTotCreds] = useState(0);
    const [Browser, setBrowser] = useState('');
    const [Error, setError] = useState(false);
    const [AddMeeting, setAddMeeting] = useState(false);
    const [Companies, setCompanies] = useState([]);
    const [SelectedComp, setSelectedComp] = useState('Select Company');
    const [MeetingStatus, setMeetingStatus] = useState(false);
    const [Task, setTask] = useState({
        ActualDueDate: null,
        Title: "",
        Description: "",
        FeedBack: '',
        Department: "",
        Archived: false,
        Status: 'Completed',
        Client: '',
        ClientEmail: '',
        DesignSpec: {
            designElements: "",
            collaterals: "",
            category: "",
            listDeliverables: "",
            DocLink: '',
            Platform: '',
            Sizes: '',
            CopyIfRequired: '',
            Attachments: ''
        },
        ClientName: '',
        DevSpec: {
            Platform: "",
            Type: "",
            Outcomes: "",
            Figma_Available: "",
            Outcomes: ''
        },
        ReqDate: new Date(),
        DueDate: new Date()
    });
    const [AddMonth, setMonth] = useState(false);
    const [RemainingCreds, setRemainingCreds] = useState(0);
    const [BGColor, setBGColor] = useState('');
    useEffect(() => {
        getTasks();
        const getBrowserName = () => {
            const userAgent = navigator.userAgent;
            if (userAgent.includes("Firefox")) {
                return "Firefox";
            } else if (userAgent.includes("Chrome")) {
                return "Chrome";
            } else if (userAgent.includes("Safari")) {
                return "Safari";
            } else if (userAgent.includes("Edge")) {
                return "Edge";
            } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
                return "Opera";
            } else if (userAgent.includes("Trident")) {
                return "Internet Explorer";
            } else {
                return "Unknown";
            }
        };
        const browserName = getBrowserName();
        setBrowser(browserName);
    }, []);
    const getTasks = async () => {
        store.setLoading(true);
        let tasks = await store.getJob();
        if (typeof tasks === "string" || !tasks) {
            setTasks([]);
            store.setLoading(false);
            return;
        }
        let departmentTasks = tasks.filter((a) => a.Department === store.user.Role);
        let comps = await store.getCompanies();

        if (typeof comps === "string" || !comps) {
            setTasks([]);
            store.setLoading(false);
            return;
        } else {
            let compsCreds = [];
            setCompanies(comps)
            for (let t = 0; t < departmentTasks; t++) {
                let task = departmentTasks[t];
                let totDevHrs = 0;
                let act = task.ActualHours;
                let est = task.EstimatedHours;
                if (Object.keys(task).includes('MonthlyCredits')) {
                    let creds = task.MonthlyCredits;
                    totDevHrs += Object.values(creds).reduce((sum, value) => sum + value, 0);
                } else {
                    if (act === undefined || act === null || act === 0 || !act) {
                        if (est === undefined || est === null || est === 0 || !est) {
                            totDevHrs += 0;
                        } else {
                            totDevHrs += est;
                        }
                    } else {
                        totDevHrs += act;
                    }
                }
                task.Credits = totDevHrs;
            }
            for (let i = 0; i < comps.length; i++) {
                let company = comps[i];
                let date = new Date();
                let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                let month = monthNames[date.getMonth()];
                let year = date.getFullYear();
                let formattedDate = month + year;
                let currentMonth = date.getMonth();
                let currentYear = date.getFullYear();
                let creds = company.Credits;
                let currentMonthCredits = creds.find((credit) => credit.Month === currentMonth && credit.Year === currentYear);

                let designTask = departmentTasks.filter((item) =>
                    item.Department === 'Design' && !item.Status.startsWith('Declined') &&
                    item.Client === company.Name
                );
                let devTasks = departmentTasks.filter((item) =>
                    item.Department === 'Dev' && !item.Status.startsWith('Declined') &&
                    item.Client === company.Name
                );
                let salesTasks = departmentTasks.filter((item) =>
                    item.Department === 'Sales' && !item.Status.startsWith('Declined') &&
                    item.Client === company.Name
                );
                let totDevHrs = 0;
                let totDesignHrs = 0;
                let totSalesHrs = 0;
                devTasks.forEach((item) => {
                    let est = item.EstimatedHours;
                    let act = item.ActualHours;
                    item.TotalCredits = 0;
                    let credsUsed
                    let reqDate= new Date(item.ReqDate);
                    if (Object.keys(item).includes('MonthlyCredits')) {
                        let creds = item.MonthlyCredits;
                        if (creds.hasOwnProperty(formattedDate)) {
                            totDevHrs += creds[formattedDate];
                            credsUsed += creds[formattedDate];
                        }
                    } else if (reqDate.getMonth() === date.getMonth() && reqDate.getFullYear() === date.getFullYear()){
                        if (act === undefined || act === null || act === 0 || !act) {
                            if (est === undefined || est === null || est === 0 || !est) {
                                totDevHrs += 0;
                                credsUsed += 0;
                            } else {
                                totDevHrs += est;
                                credsUsed += est
                            }
                        } else {
                            totDevHrs += act;
                            credsUsed += act;
                        }
                    }
                    item.TotalCredits = credsUsed
                });
                designTask.forEach((item) => {
                    let est = item.EstimatedHours;
                    let act = item.ActualHours;
                    item.TotalCredits = 0;
                    let credsUsed;
                    let reqDate= new Date(item.ReqDate);
                    if (Object.keys(item).includes('MonthlyCredits')) {
                        let creds = item.MonthlyCredits;
                        if (creds.hasOwnProperty(formattedDate)) {
                            totDesignHrs += creds[formattedDate];
                            credsUsed += creds[formattedDate];
                        }
                    } else if (reqDate.getMonth() === date.getMonth() && reqDate.getFullYear() === date.getFullYear()) {
                        if (act === undefined || act === null || act === 0 || !act) {
                            if (est === undefined || est === null || est === 0 || !est) {
                                totDesignHrs += 0;
                                credsUsed += 0;
                            } else {
                                totDesignHrs += est;
                                credsUsed += est
                            }
                        } else {
                            totDesignHrs += act;
                            credsUsed += act;
                        }
                    }
                    item.TotalCredits = credsUsed
                });
                salesTasks.forEach((item) => {
                    let est = item.EstimatedHours;
                    let act = item.ActualHours;
                    item.TotalCredits = 0;
                    let credsUsed
                    let reqDate= new Date(item.ReqDate);
                    if (Object.keys(item).includes('MonthlyCredits')) {
                        let creds = item.MonthlyCredits;
                        if (creds.hasOwnProperty(formattedDate)) {
                            totSalesHrs += creds[formattedDate];
                            credsUsed += creds[formattedDate];
                        }
                    } else if (reqDate.getMonth() === date.getMonth() && reqDate.getFullYear() === date.getFullYear()){
                        if (act === undefined || act === null || act === 0 || !act) {
                            if (est === undefined || est === null || est === 0 || !est) {
                                totSalesHrs += 0;
                                credsUsed += 0;
                            } else {
                                totSalesHrs += est;
                                credsUsed += est
                            }
                        } else {
                            totSalesHrs += act;
                            credsUsed += act;
                        }
                    }
                    item.TotalCredits = credsUsed
                });
                let Credits = {
                    Sales: currentMonthCredits && currentMonthCredits.Departments && currentMonthCredits.Departments.Sales - totSalesHrs || 0,
                    Dev: currentMonthCredits && currentMonthCredits.Departments && currentMonthCredits.Departments.Dev - totDevHrs || 0,
                    Design: currentMonthCredits && currentMonthCredits.Departments && currentMonthCredits.Departments.Design - totDesignHrs || 0,
                }
                let tmpObj = {
                    CompanyName: company.Name,
                    RemainingCredits: Credits
                }
                compsCreds.push(tmpObj)
            }
            setCredits(compsCreds);
        }
        setTasks(departmentTasks);
        forceUpdate();
        store.setLoading(false);
    };
    function selectTab(e) {
        if (e.currentTarget.id === 'Completed') {
            setAdditionalStatus('Complete')
            setTabSelected(e.currentTarget.id);
        } else if (e.currentTarget.id === 'requested') {
            setAdditionalStatus(e.currentTarget.id)
            setTabSelected(e.currentTarget.id);
        } else if (e.currentTarget.id === 'pending') {
            setAdditionalStatus(e.currentTarget.id)
            setTabSelected(e.currentTarget.id);
        } else if (e.currentTarget.id === 'inProgress') {
            setAdditionalStatus(e.currentTarget.id)
            setTabSelected(e.currentTarget.id);
        } else {
            setAdditionalStatus(e.currentTarget.id)
            setTabSelected(e.currentTarget.id);
        }
        forceUpdate();
    };
    function viewTaskClicked(index) {
        setSelectedTask(Tasks[index]);
        let clientName = Tasks[index].Client;
        let filteredCompany = Companies.find(company => company.Name === clientName);
        let department = store.user.Role
        let companyCreds = Credits.find(companyCreds => companyCreds.CompanyName === filteredCompany.Name);
        if (department === 'Dev') {
            setRemainingCreds(companyCreds.RemainingCredits.Dev)
        } else if (department === 'Design') {
            setRemainingCreds(companyCreds.RemainingCredits.Design)
        } else {
            setRemainingCreds(companyCreds.RemainingCredits.Sales)
        }
        if (Tasks[index].hasOwnProperty('MonthlyCredits')) {
            let data = Tasks[index].MonthlyCredits
            const total = Object.values(data).reduce((acc, value) => acc + value, 0);
            setTotCreds(total);

            const formattedData = Object.entries(data).map(([month, value]) => ({
                Name: month,
                Value: value
            }));
        } else {
            setTotCreds(0)
        }
        setViewTask(true);
        forceUpdate();
    };
    function closeViewTask() {
        setViewTask(false);
        setStatusChange(false);
        setCredsUsed(0);
        setCredsDate();
        setError(false);
        forceUpdate();
    };
    const handleHoverStart = (index) => {
        setSelectedTask(Tasks[index]);
        setIsHovered(prevState => {
            const newState = [...prevState];
            newState[index] = true;
            return newState;
        });
        forceUpdate();
    };
    const handleHoverEnd = (index) => {
        setIsHovered(prevState => {
            const newState = [...prevState];
            newState[index] = false;
            return newState;
        });
        forceUpdate();
    };
    const viewArchives = () => {
        setViewArchived(true);
        forceUpdate();
    };
    const archiveTask = async () => {
        let confirmed = window.confirm('Are you sure you want to archive this task?');
        if (!confirmed) {
            return
        }
        let task = SelectedTask;
        store.setLoading(true);
        task.Archived = true;
        let archive = await store.updateTaskById(task);
        if (archive === 'error') {
            store.setLoading(false);
        }
        setViewTask(false);
        store.setLoading(false);
        forceUpdate();
    };
    const closeViewArchive = () => {
        setViewArchived(false);
        setViewTask(false);
        setIsHovered(false);
        forceUpdate();
    };
    const handleUnacrchiveTask = async (index) => {
        let confirmed = window.confirm('Are you sure you want to unarchive this task?');
        if (!confirmed) {
            return
        }
        let task = Tasks[index];
        store.setLoading(true);
        task.Archived = false;
        let archive = await store.updateTaskById(task);
        if (archive === 'error') {
            store.setLoading(false);
        }
        setIsHovered(false);
        setViewTask(false);
        store.setLoading(false);
        forceUpdate();
    };
    const onStatusChange = () => {
        setStatusHover(true);
        forceUpdate();
    };
    const offStatusChange = () => {
        setStatusHover(false);
        forceUpdate();
    };
    const handleStatusChange = async (e) => {
        let task = SelectedTask;
        task.Status = e.currentTarget.id;
        setStatusChange(true);
        setStatusHover(false);
        forceUpdate();
    };
    const handleAcceptTask = async () => {
        let task = SelectedTask;

        if (Object.keys(task).includes('MonthlyCredits')) {
            task.MonthlyCredits[CredsDate] = CredsUsed;
            delete task.MonthlyCredits['undefined']
        } else {
            task['MonthlyCredits'] = {};
            task.MonthlyCredits[CredsDate] = CredsUsed;
            delete task.MonthlyCredits['undefined'];
        };
        if (verifyTaskData(task) === false) {
            setError(true);
            return
        } else {
            setError(false);
        };

        store.setLoading(true);
        task.Status = 'pending';
        let updateTask = await store.departmentAccept(task);
        if (updateTask === 'error') {
            window.alert('There was an error updating this task.');
            store.setLoading(false);
            setViewTask(false);
            getTasks();
        }
        store.setLoading(false);
        setViewTask(false);
        getTasks();
        forceUpdate();
    };
    const verifyTaskData = (task) => {
        if (Object.keys(task).includes('MonthlyCredits')) {
            if (Object.keys(task.MonthlyCredits).length < 1 || task.ActualDueDate === null) {
                return false
            };
            if (Object.values(task.MonthlyCredits).length < 1) {
                return false
            };
            if (Object.values(task.MonthlyCredits)[Object.values(task.MonthlyCredits).length - 1] <= 0) {
                return false
            }
            if (Object.keys(task.MonthlyCredits).includes('undefined')) {
                return false
            };
            forceUpdate();
            return true
        } else {
            return false
        };

    };
    const handleDeclineTask = async () => {
        let task = SelectedTask;
        task.Status = 'Declined peacefully.';
        store.setLoading(true);
        let updateTask = await store.departmentReject(task);
        getTasks();
        if (updateTask === 'error') {
            window.alert('There was an error updating this task.');
            store.setLoading(false);
        }
        store.setLoading(false);
        setViewTask(false);
        forceUpdate();
    };
    const abandonTask = async () => {
        let task = SelectedTask;
        task.Status = 'Declined peacefully.';
        task.Archived = true;
        let update = await store.updateTaskById(task);
        if (update === 'error') {
            window.alert('There was an error updating this task.');
        }
        forceUpdate();
        setViewTask(false);
        getTasks();
        forceUpdate();
    };
    const handleSortTasksBy = (e) => {
        let tasks = Tasks;
        if (e.currentTarget.id === 'TaskName') {
            setFilterComps('');
            setDateFilter('');
            setTitleFilter('Z-A');
            if (TitleFilter === 'Z-A') {
                setTitleFilter('A-Z');
                tasks.sort((a, b) => a.Title.localeCompare(b.Title));
            } else {
                setTitleFilter('Z-A');
                tasks.sort((a, b) => b.Title.localeCompare(a.Title));
            }
        } else if (e.currentTarget.id === 'CompanyName') {
            setTitleFilter('');
            setDateFilter('');
            setFilterComps('A-Z');
            if (FilterComps === 'Z-A') {
                setFilterComps('A-Z');
                tasks = tasks.sort((a, b) => (a.Client < b.Client ? -1 : a.Client > b.Client ? 1 : 0));
            } else {
                setFilterComps('Z-A');
                tasks = tasks.sort((a, b) => (a.Client > b.Client ? -1 : a.Client < b.Client ? 1 : 0));
            }
        } else if (e.currentTarget.id === 'CreatedDate') {
            setFilterComps('');
            setTitleFilter('');
            setDateFilter('A-Z');
            if (DateFilter === 'Z-A') {
                setDateFilter('A-Z');
                tasks = tasks.sort((a, b) => (a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0));
            } else {
                setDateFilter('Z-A');
                tasks = tasks.sort((a, b) => (a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0));
            }
        }
        setTasks(tasks);
        forceUpdate();
    }
    const handleUpdateTask = async () => {
        let data = SelectedTask;
        let newCredsDate = '';
        if (CredsDate === undefined || CredsDate === null || CredsDate === '') {
            let newDate = new Date();
            const months = [
                'January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'
            ];

            let month = months[newDate.getMonth()];
            let year = newDate.getFullYear();

            newCredsDate = `${month}${year}`;
        } else {
            newCredsDate = CredsDate.replace(" ", "")
        }
        if (Object.keys(data).includes('MonthlyCredits')) {
            data.MonthlyCredits[newCredsDate] = CredsUsed;
        } else {
            data.MonthlyCredits = {};
            data.MonthlyCredits[newCredsDate] = CredsUsed;
        }

        store.setLoading(true);
        if (StatusChange === true) {
            if (data.Status === 'Complete' || data.Status === 'Completed') {
                let update = await store.departmentTaskComplete(data);
                if (typeof update === "string" || !update) {
                    window.alert('Error updating task, please try again.')
                    store.setLoading(false);
                    return;
                }
            } else {
                let update = await store.updateTaskById(data);
                if (typeof update === "string" || !update) {
                    window.alert('Error updating task, please try again.')
                    store.setLoading(false);
                    return;
                }
            }
        } else {
            let update = await store.updateTaskById(data);
            if (typeof update === "string" || !update) {
                window.alert('Could not update task, please try again later.')
                store.setLoading(false);
                return;
            }
        }
        store.setLoading(false);
        forceUpdate();
        setViewTask(false);
    }
    const handleAbandon = async () => {
        let task = SelectedTask;
        task.Status = 'Abandoned';
        let update = await store.updateTaskById(task);
        setSelectedTask(task);
        if (update === 'error') {
            window.alert('There was an error updating this task.');
        }
        forceUpdate();
    }
    const HandleArchivesHover = () => {
        setViewArchivesHover(true);
        forceUpdate();
    }
    const handleArchiveOffHover = () => {
        setViewArchivesHover(false);
        forceUpdate();
    }
    const handleEstimatedhoursChange = (e) => {
        let task = SelectedTask;
        task.EstimatedHours = e.target.value;
        setSelectedTask(task);
        forceUpdate();
    }
    const handleActualDueDateChange = (e) => {
        let task = SelectedTask;
        task.ActualDueDate = e.target.value;
        setSelectedTask(task);
        forceUpdate();
    }
    const handleActualhoursChange = (e) => {
        const value = parseFloat(e.target.value);
        setCredsUsed(value);
        forceUpdate();
    }
    const handleMonthChange = (e) => {
        const date = new Date(e.target.value);
        let formattedDate = '';
        if (Browser === 'Safari') {
            let monthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
            let year = date.getFullYear();
            formattedDate = monthName + year;

        } else {
            const month = date.toLocaleString('default', { month: 'long' });
            const year = date.getFullYear();
            formattedDate = `${month}${year}`;
        }

        setCredsDate(formattedDate);
        forceUpdate();
    }
    const closeMonthlyCreds = () => {
        setMonthlyCredits(false);
        forceUpdate();
    }
    const openMonthlyCreds = () => {
        setMonthlyCredits(true);
        forceUpdate();
    }
    const addMeetingClicked = () => {
        setAddMeeting(true);
        forceUpdate();
    }
    const closeAddMeeting = () => {
        setAddMeeting(false);
        forceUpdate();
    }
    const selectedCompChange = (e) => {
        setSelectedComp(e.currentTarget.id);
        let task = Task;
        let filteredCompany = Companies.find(company => company.Name === e.currentTarget.id);
        let department = store.user.Role
        let companyCreds = Credits.find(companyCreds => companyCreds.CompanyName === filteredCompany.Name);
        if (department === 'Dev') {
            setRemainingCreds(companyCreds.RemainingCredits.Dev)
        } else if (department === 'Design') {
            setRemainingCreds(companyCreds.RemainingCredits.Design)
        } else {
            setRemainingCreds(companyCreds.RemainingCredits.Sales)
        }
        task.Client = filteredCompany.Name;
        task.Client = e.currentTarget.id;
        task.Department = store.user.Role;
        task.ClientName = e.currentTarget.id;
        task.ClientEmail = e.currentTarget.id;
        forceUpdate();
    }
    const openStatus = () => {
        setMeetingStatus(true);
        forceUpdate();
    }
    const closeStatus = () => {
        setMeetingStatus(false);
        forceUpdate();
    }
    const newMeetingValueChange = (e) => {
        let task = Task;
        if (e.currentTarget.id === 'meetingName') {
            task.Name = e.target.value
        }
        if (e.currentTarget.id === 'Date') {
            task.DueDate = e.target.value
        }
        if (e.currentTarget.id === 'description') {
            task.Description = e.target.value
        }
        if (e.currentTarget.id === 'notes') {
            if (store.user.Role === 'Dev') {
                task.DevSpec.Outcomes = e.target.value
            } else if (store.user.Role === 'Design') {
                task.DesignSpec.CopyIfRequired = e.target.value
            }
        }
        if (e.currentTarget.id === 'Credits') {
            let creds = e.target.value;
            if (!isNaN(creds)) {
                creds = Number(creds);
                let date = task.DueDate;
                let dateObj = new Date(date);
                let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                let month = monthNames[dateObj.getMonth()];
                let year = dateObj.getFullYear();
                let formattedDate = `${month}${year}`;

                if (Object.keys(task).includes('MonthlyCredits')) {
                    task.MonthlyCredits[formattedDate] = creds;
                } else {
                    task.MonthlyCredits = {};
                    task.MonthlyCredits[formattedDate] = creds;
                }
            } else {
                console.error('Creds must be a number');
            }
        }
        if (e.currentTarget.id === 'Archive') {
            setBGColor(e.currentTarget.id);
            task.Archived = true;
            task.Status = 'Completed';
        }
        if (e.currentTarget.id === 'Completed') {
            setBGColor(e.currentTarget.id);
            task.Status = 'Completed';
        }
        forceUpdate();
    }
    const addNewMeeting = async () => {
        store.setLoading(true);
        let data = Task;
        data.Title = data.Name
        if (data.Title === '') {
            window.alert('Meeting Name required!')
        }
        if (data.Description === '') {
            window.alert('Meeting Description Required')
        }
        if (data.Client === '') {
            window.alert('Please Select a Company.')
        }
        if (store.user.Role === 'Dev' && data.DevSpec.Outcomes === '') {
            window.alert('')
        }
        let success = await store.createMeeting(data);
        if (success === true) {
            window.alert('Meeting added successfully')
            setAddMeeting(false);
            store.setLoading(false);
        } else {
            window.alert('Meeting could not be added');
            setAddMeeting(false);
            store.setLoading(false);
        }
        forceUpdate();
    }
    const addMonth = () => {
        setMonth(true);
        forceUpdate();
    }
    const closeMonth = () => {
        setMonth(false);
        forceUpdate();
    }
    const getDate = (date) => {

        setCredsDate(date);
        forceUpdate();
    };

    if (mobile()) {
        return (
            <View >
            </View>
        );
    } else {
        return (
            <div className='departmentAlkemiaBriefing'>
                {(ViewArchived) ?
                    <>
                        <div className='viewArchive-taskHeaders'>
                            <img src={CloseArchive} alt='' onClick={closeViewArchive} />
                            <p className='companyNameField' id='CompanyName' style={{ cursor: 'pointer' }} onClick={handleSortTasksBy}><img src={FilterComps === 'A-Z' ? FilterDown : FilterComps === 'Z-A' ? Filter : null} onClick={handleSortTasksBy} />Company</p>
                            <p className='titleField' style={{ cursor: 'pointer' }} id='TaskName' onClick={handleSortTasksBy}><img src={TitleFilter === 'A-Z' ? FilterDown : TitleFilter === 'Z-A' ? Filter : null} onClick={handleSortTasksBy} />Title</p>
                            <p className='taskStatusField'>Status</p>
                            <p className='taskDateField' style={{ cursor: 'pointer' }} id='CreatedDate' onClick={handleSortTasksBy}><img src={DateFilter === 'A-Z' ? FilterDown : DateFilter === 'Z-A' ? Filter : null} onClick={handleSortTasksBy} />Date</p>
                        </div>
                        <div className='mappedArchived-tasks'>
                            {Tasks.map((item, index) => {
                                if (!item.Archived) {
                                    return null;
                                }
                                return (
                                    <Card className='archived-tasks'>
                                        <p className='companyNameValue'>{item.Client}</p>
                                        <p className='titleValue'>{item.Title}</p>
                                        <p className='taskStatusValue'>{item.Status === 'Declined peacefully.' ? 'Declined' : item.Status}</p>
                                        <p className='taskDateValue'>{(new Date(item.createdAt)).toLocaleDateString()}</p>
                                        <UnArchiveHover onClick={() => handleUnacrchiveTask(index)} />
                                    </Card>
                                )
                            })}
                        </div>
                    </> :
                    <>
                        <div className='tabSections'>
                            <Card className={`filterTasksCard ${TabSelected === 'requested' ? 'selectedTaskCard' : ''}`} style={{ marginLeft: '0%' }} onClick={selectTab} id='requested'><p>Requested</p><b>{(Tasks.filter((t) => t.Status === 'requested' && !t.Archived)).length}</b></Card>
                            <Card className={`filterTasksCard ${TabSelected === 'pending' ? 'selectedTaskCard' : ''}`} onClick={selectTab} id='pending'><p>Pending</p><b>{(Tasks.filter((t) => t.Status === 'pending' && !t.Archived)).length}</b></Card>
                            <Card className={`filterTasksCard ${TabSelected === 'inProgress' ? 'selectedTaskCard' : ''}`} onClick={selectTab} id='inProgress'><p>In Progress</p><b>{(Tasks.filter((t) => t.Status === 'inProgress' && !t.Archived)).length}</b></Card>
                            <Card className={`filterTasksCard ${TabSelected === 'Completed' || TabSelected === 'Complete' ? 'selectedTaskCard' : ''}`} onClick={selectTab} id='Completed'><p>Completed</p><b>{(Tasks.filter((t) => (t.Status === 'Completed' || t.Status === 'Complete') && !t.Archived)).length}</b></Card>
                            <Card className={`filterTasksCard ${TabSelected === 'Declined peacefully.' ? 'selectedTaskCard' : ''}`} onClick={selectTab} id='Declined peacefully.'><p>Declined</p><b>{(Tasks.filter((t) => (t.Status === 'Declined peacefully.') && !t.Archived)).length}</b></Card>
                        </div>
                        <div className='title'>
                            <p className='companyNameField' id='CompanyName' style={{ cursor: 'pointer' }} onClick={handleSortTasksBy}><img src={FilterComps === 'A-Z' ? FilterDown : FilterComps === 'Z-A' ? Filter : null} onClick={handleSortTasksBy} />Company</p>
                            <p className='titleField' style={{ cursor: 'pointer' }} id='TaskName' onClick={handleSortTasksBy}><img src={TitleFilter === 'A-Z' ? FilterDown : TitleFilter === 'Z-A' ? Filter : null} onClick={handleSortTasksBy} />Title</p>
                            <p className='dateField' style={{ cursor: 'pointer' }} id='CreatedDate' onClick={handleSortTasksBy}><img src={DateFilter === 'A-Z' ? FilterDown : DateFilter === 'Z-A' ? Filter : null} onClick={handleSortTasksBy} />Date</p>
                        </div>
                        <div className='availableTasks'>
                            {Tasks.filter((item, index) => {
                                if (item.Status !== TabSelected && item.Status !== AdditionsStatus) {
                                    return false;
                                }
                                if (item.Archived) {
                                    return false;
                                }
                                return true;
                            }).length === 0 ? (
                                <div className='task' key=''>
                                    <p className='companyNameField'>No Tasks Available</p>
                                    <p className='titleField'></p>
                                    <p className='dateField'></p>
                                    {(TabSelected === 'Completed' && AdditionsStatus === 'Complete' || TabSelected === 'Declined peacefully.') ?
                                        <div className={`UnArchiveHover`} style={{ width: '60%', marginRight: '1%' }} onMouseEnter={HandleArchivesHover} onMouseLeave={handleArchiveOffHover}>
                                            <img src={unArchiveIcon} alt="Unarchive" />
                                            {ViewArchivesHover && (
                                                <>
                                                    <div className="archiveTask-triangle" />
                                                    <div className='archive-viewArchivesContainer' style={{ width: '250px' }}>
                                                        <Card className='archiveTask' id='ViewArchives' onClick={viewArchives} style={{ width: '100%' }}>
                                                            <p className='unArchiveValue' onClick={viewArchives}>View Archive</p>
                                                        </Card>
                                                    </div>
                                                </>
                                            )}
                                        </div> : null}

                                </div>
                            ) : (
                                Tasks.map((item, index) => {
                                    if (item.Status !== TabSelected && item.Status !== AdditionsStatus) {
                                        return null;
                                    }
                                    if (item.Archived) {
                                        return null;
                                    }
                                    return (
                                        <div className='task' onClick={() => viewTaskClicked(index)} key={index}>
                                            <p className='companyNameField'>{item.Client}</p>
                                            <p className='titleField'>{item.Title.length > 30 ? item.Title.substring(0, 30) + '...' : item.Title}</p>
                                            <p className='dateField'>{(new Date(item.createdAt)).toLocaleDateString()}</p>
                                            {(item.Status === 'Completed' || item.Status === 'Complete' || item.Status === 'Declined peacefully.') ? (
                                                <div className={`UnArchiveHover`} style={{ width: '60%', marginRight: '1%' }} onMouseEnter={() => handleHoverStart(index)} onMouseLeave={() => handleHoverEnd(index)}>
                                                    <img src={unArchiveIcon} alt="Unarchive" />
                                                    {IsHovered[index] && (
                                                        <>
                                                            <div className="archiveTask-triangle" />
                                                            <div className='archive-viewArchivesContainer'>
                                                                <Card className='archiveTask' id='Archive' onClick={archiveTask} style={{ marginLeft: '0' }}>
                                                                    <p className='unArchiveValue' id='Archive' onClick={archiveTask}>Archive</p>
                                                                </Card>
                                                                <Card className='archiveTask' id='ViewArchives' onClick={viewArchives} style={{ marginLeft: '1%' }}>
                                                                    <p className='unArchiveValue' id='ViewArchives' onClick={viewArchives}>View Archive</p>
                                                                </Card>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            ) : null}
                                        </div>
                                    );
                                })
                            )}

                        </div>
                        <div className='addMeetingContainer' onClick={addMeetingClicked}>
                            <img style={{ width: '100%' }} src={AddIcon} alt='' />
                        </div>
                        {(AddMeeting) ?
                            <Card className='addNewMeeting'>
                                <img className='closeCard' src={CloseCard} alt='' onClick={closeAddMeeting} />
                                <div className='credsSect'>
                                    <p>New Meeting</p>
                                    <div className='credsCont'>
                                        <p style={{ marginLeft: '10px' }}>Remaining Credits</p>
                                        <div className='credsValue'>{RemainingCreds}</div>
                                    </div>
                                </div>
                                <div className='nameAndDateSec'>
                                    <Textbox className='meetingNameInput' id='meetingName' onChange={newMeetingValueChange} style={{ width: '45%', height: '40px' }} placeholder='Meeting Name *'></Textbox>
                                    <Textbox className='meetingNameInput' type='date' id='Date' onChange={newMeetingValueChange} placeholder='Date Created *' style={{ height: '40px', width: '25%' }}></Textbox>
                                    <CustomSelect className='meetingNameInput' placeholder='Company *' value={SelectedComp} style={{ minHeight: '50px', minWidth: '24%', marginRight: 'unset' }}>
                                        {Companies.map((item, index) => {
                                            return (
                                                <div className='availComps' style={{ backgroundColor: SelectedComp === item.Name ? '#EFB86F' : '#EAEAEA' }} key={index} id={item.Name} onClick={selectedCompChange}>
                                                    {item.Name}
                                                </div>
                                            )
                                        })}
                                    </CustomSelect>
                                </div>
                                <hr className='hr' />
                                <div className='descriptionAndActionsSec'>
                                    <div className='descSection'>
                                        <ResizableTextarea id='description' onChange={newMeetingValueChange} style={{ marginTop: '2%', width: '95%', height: '30px' }} placeholder='Description *' />
                                        <ResizableTextarea id='notes' onChange={newMeetingValueChange} style={{ width: '95%', height: '30px' }} placeholder='Notes *' />
                                    </div>
                                    <div className='actionssection'>
                                        <div style={{ width: '100%', height: '20%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <div className='creditSection' style={{ marginBottom: '5px' }}>
                                                <p style={{ marginLeft: '10px' }}>Credits</p>
                                                <input id='Credits' onChange={newMeetingValueChange}></input>
                                            </div>
                                            <div className='statusSection'>
                                                <p style={{ marginLeft: '10px' }}>Status</p>
                                                <div className='archiveMessage' onMouseEnter={openStatus} onMouseLeave={closeStatus} >
                                                    <p>{BGColor}</p>
                                                    {(MeetingStatus) ?
                                                        <>
                                                            <div className='statusPointer' />
                                                            <div className='optionContainer'>
                                                                <div className='options' id='Completed' style={{ backgroundColor: BGColor === 'Completed' ? '#EFB86F' : '#EAEAEA' }} onClick={newMeetingValueChange}>Completed</div>
                                                                <div className='options' id='Archive' style={{ backgroundColor: BGColor === 'Archive' ? '#EFB86F' : '#EAEAEA' }} onClick={newMeetingValueChange}>Archive</div>
                                                            </div>
                                                        </> : null}
                                                </div>
                                            </div>
                                        </div>
                                        <button className='createBtn' onClick={addNewMeeting}>Create</button>
                                    </div>
                                </div>
                            </Card> : null}
                        {(ViewTask) ?
                            <Card className='viewSelectedTask'>
                                <img className='closeCard' src={CloseCard} alt='' onClick={closeViewTask} />
                                <div className='nameAndCredsSection'>
                                    <p className='compName'>{SelectedTask.Title.length > 40 ? SelectedTask.Title.substring(0, 40) + '...' : SelectedTask.Title}</p>
                                    <div className='remCredsContainer'>
                                        <p className='remCredsTitle'>Remaining {SelectedTask.Department} Credits</p><div className='credsContainer'><b style={{ fontWeight: '700', alignSelf: 'center', fontSize: '25px', color: '#213038', lineHeight: '30.26px' }}>{RemainingCreds || 0}</b></div>
                                    </div>
                                </div>
                                <div className='taskNameSection'>
                                    <Textbox className='taskInput' style={{ marginLeft: 'unset' }} placeholder='Company' value={SelectedTask.Client} readOnly>{SelectedTask.Client} </Textbox>
                                    <Textbox className='taskInput' placeholder='Date Created' value={new Date(SelectedTask.createdAt).toLocaleDateString('en-US')} readOnly></Textbox>
                                    <Textbox className='taskInput' placeholder='Requested By' value={SelectedTask.ClientEmail} readOnly></Textbox>
                                </div>
                                <div className='categorySection'>
                                    <Textbox className='taskInput' style={{ marginLeft: 'unset' }} placeholder='Requested Date' value={new Date(SelectedTask.DueDate).toLocaleDateString('en-US')} readOnly></Textbox>
                                    <Textbox className='taskInput' placeholder='Category' value={SelectedTask.Department === 'Dev' ? SelectedTask.DevSpec.Type : SelectedTask.Department === 'Design' ? SelectedTask.DesignSpec.category : ''} readOnly></Textbox>
                                    <Textbox className='taskInput' placeholder='Platform' value={SelectedTask.Department === 'Dev' ? SelectedTask.DevSpec.Platform : SelectedTask.Department === 'Design' ? SelectedTask.DesignSpec.Platform : ''} readOnly />
                                </div>
                                <hr className='hr' />
                                <div className='taskActionSection'>
                                    <div className='descBar'>
                                        <p className='titleLabel'>Description</p>
                                        <p className='titlleValue'>{SelectedTask.Description}</p>
                                        {(SelectedTask.Department === 'Design') ?
                                            <>
                                                <p className='titleLabel'>Collateral</p>
                                                <p className='titlleValue'>{SelectedTask.DesignSpec.Collateral}</p>
                                                <p className='titleLabel'>Size/Dimension</p>
                                                <p className='titlleValue'>{SelectedTask.DesignSpec.Sizes}</p>
                                                <p className='titleLabel'>Text/Copy</p>
                                                <p className='titlleValue'>{SelectedTask.DesignSpec.CopyIfRequired}</p>
                                                <p className='titleLabel'>Attachments</p>
                                                <a href={SelectedTask.DesignSpec.Attachments} target="_blank" rel="noopener noreferrer" className='titlleValue'>{SelectedTask.DesignSpec.Attachments}</a>
                                            </> : (SelectedTask.Department === 'Dev' ?
                                                <>
                                                    <p className='titleLabel'>What should this project achieve?</p>
                                                    <p className='titlleValue'>{SelectedTask.DevSpec.Outcomes}</p>
                                                    <p className='titleLabel'>Is there a design Available?</p>
                                                    <p className='titlleValue'>{SelectedTask.DevSpec.Figma_Available}</p>
                                                </>
                                                : null)}
                                    </div>
                                    {(SelectedTask.Status === 'requested') ?
                                        <div className='ActionBar'>
                                            <Card className='actionValueCards'>
                                                <p className='categoryCArds'>Credits</p>
                                                <div style={{ width: '18%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <input className={Browser === 'Safari' ? 'addNewAssignedTAskCreds' : 'addNewAssignedTAskCreds'} onChange={handleActualhoursChange} id='ActualDeliveryDate' ></input>
                                                    <div className='addNewAssignedTAskCreds' id='CreditsInfo' onMouseEnter={openMonthlyCreds} onMouseLeave={closeMonthlyCreds} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        i
                                                        {MonthlyCredits && (
                                                            <>
                                                                <div className='monthlyCredsPointer'></div>
                                                                <div className='monthlyCredtsContainer'>
                                                                    <div className='monthlyCredsHeader'>
                                                                        <p>Month</p>
                                                                        <p>Credits</p>
                                                                    </div>
                                                                    <hr className='hr' style={{ marginTop: '0%', marginBottom: '0%', width: '95%' }}></hr>
                                                                    <div className='allocateCredsSection'>
                                                                        {SelectedTask.MonthlyCredits && Object.keys(SelectedTask.MonthlyCredits).length > 0 ? (
                                                                            Object.entries(SelectedTask.MonthlyCredits)
                                                                                .sort((a, b) => {
                                                                                    const monthA = new Date(Date.parse(a[0] + ' 1, 2020')).getMonth();
                                                                                    const yearA = parseInt(a[0].match(/\d+/)[0]);
                                                                                    const monthB = new Date(Date.parse(b[0] + ' 1, 2020')).getMonth();
                                                                                    const yearB = parseInt(b[0].match(/\d+/)[0]);
                                                                                    if (yearA !== yearB) {
                                                                                        return yearA - yearB;
                                                                                    } else {
                                                                                        return monthA - monthB;
                                                                                    }
                                                                                })
                                                                                .filter(([date]) => {
                                                                                    const formattedDate = date.replace(/([a-zA-Z]+)(\d+)/, '$1 $2');
                                                                                    const parsedDate = new Date(formattedDate + ' 1, 2020');
                                                                                    return !isNaN(parsedDate);
                                                                                })
                                                                                .map(([date, credits]) => {
                                                                                    const formattedDate = date.replace(/([a-zA-Z]+)(\d+)/, '$1 $2');
                                                                                    return (
                                                                                        <div key={date} className='availableMonthlyCreds'>
                                                                                            <p>{formattedDate}</p>
                                                                                            <p>{credits}</p>
                                                                                        </div>
                                                                                    );
                                                                                })
                                                                        ) : (
                                                                            <p>No monthly credits available.</p>
                                                                        )}

                                                                    </div>
                                                                    <hr className='hr' style={{ marginTop: '0%', marginBottom: '0%', width: '95%' }}></hr>
                                                                    <div className='totalSection'>
                                                                        <p>Total</p>
                                                                        <p>{TotCreds}</p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </Card>
                                            <Card className='actionValueCards'><p className='categoryCArds'>Month</p>
                                                <div className='changeStatusHover' onMouseEnter={addMonth} onMouseLeave={closeMonth}>
                                                    <p>{CredsDate || ''}</p>
                                                    {(AddMonth) ?
                                                        <div className='addCredsMonthSelector'>
                                                            <Calender onClick={getDate} />
                                                        </div> : null}
                                                </div>
                                            </Card>
                                            <Card className='credsXCard' style={{ marginTop: '1%' }}>
                                                <p>Delivery Date</p>
                                                <input type='date' className='updateDeliveryDate' id='deliveryDate' onChange={handleActualDueDateChange} value={SelectedTask.ActualDueDate}></input>
                                            </Card>
                                            {Error ? <p style={{ color: 'red' }}>{`Please fill all required fields ('Credits', 'Month' and 'Delivery Date')`}</p>
                                                : null}                                            <div className='acceptRejectBtn'>
                                                <button className='acceptRequestedTask' style={{ backgroundColor: '#D5922D' }} onClick={handleAcceptTask}>Accept</button>
                                                <button className='acceptRequestedTask' style={{ backgroundColor: '#D9D9D9' }} onClick={handleDeclineTask}>Reject</button>
                                                <p style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleAbandon}>Abandon this task.</p>
                                            </div>
                                        </div>
                                        :
                                        <div className='ActionBar'>
                                            <Card className='actionValueCards'>
                                                <p className='categoryCArds'>Credits</p>
                                                <div style={{ width: '18%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <input className={Browser === 'Safari' ? 'addNewAssignedTAskCreds' : 'addNewAssignedTAskCreds'} onChange={handleActualhoursChange} id='ActualDeliveryDate' value={SelectedTask.ActualHours} ></input>
                                                    <div className='addNewAssignedTAskCreds' id='CreditsInfo' onMouseEnter={openMonthlyCreds} onMouseLeave={closeMonthlyCreds} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        i
                                                        {MonthlyCredits && (
                                                            <>
                                                                <div className='monthlyCredsPointer'></div>
                                                                <div className='monthlyCredtsContainer'>
                                                                    <div className='monthlyCredsHeader'>
                                                                        <p>Month</p>
                                                                        <p>Credits</p>
                                                                    </div>
                                                                    <hr className='hr' style={{ marginTop: '0%', marginBottom: '0%', width: '95%' }}></hr>
                                                                    <div className='allocateCredsSection'>
                                                                        {SelectedTask.MonthlyCredits && Object.keys(SelectedTask.MonthlyCredits).length > 0 ? (
                                                                            Object.entries(SelectedTask.MonthlyCredits)
                                                                                .sort((a, b) => {
                                                                                    const monthA = new Date(Date.parse(a[0] + ' 1, 2020')).getMonth();
                                                                                    const yearA = parseInt(a[0].match(/\d+/)[0]);
                                                                                    const monthB = new Date(Date.parse(b[0] + ' 1, 2020')).getMonth();
                                                                                    const yearB = parseInt(b[0].match(/\d+/)[0]);
                                                                                    if (yearA !== yearB) {
                                                                                        return yearA - yearB;
                                                                                    } else {
                                                                                        return monthA - monthB;
                                                                                    }
                                                                                })
                                                                                .map(([date, credits]) => {
                                                                                    const formattedDate = date.replace(/([a-zA-Z]+)(\d+)/, '$1 $2');
                                                                                    if (!formattedDate || date === 'Invalid DateNaN') {
                                                                                        credits = 0;
                                                                                        return null;
                                                                                    }
                                                                                    return (
                                                                                        <div key={date} className='availableMonthlyCreds'>
                                                                                            <p>{formattedDate}</p>
                                                                                            <p>{credits}</p>
                                                                                        </div>
                                                                                    );
                                                                                })
                                                                        ) : (
                                                                            <p>No monthly credits available.</p>
                                                                        )}
                                                                    </div>
                                                                    <hr className='hr' style={{ marginTop: '0%', marginBottom: '0%', width: '95%' }}></hr>
                                                                    <div className='totalSection'>
                                                                        <p>Total</p>
                                                                        <p>{TotCreds}</p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </Card>
                                            <Card className='actionValueCards'><p className='categoryCArds'>Month</p>
                                                <div className='changeStatusHover' onMouseEnter={addMonth} onMouseLeave={closeMonth}>
                                                    <p>{CredsDate || ''}</p>
                                                    {(AddMonth) ?
                                                        <div className='addCredsMonthSelector'>
                                                            <Calender onClick={getDate} />
                                                        </div> : null}
                                                </div>
                                            </Card>
                                            <Card className='actionValueCards'><p className='categoryCArds'>Delivery Date</p>
                                                <div className='changeStatusHover'>
                                                    <input className='updateDeliveryDate' type='date' onChange={handleActualDueDateChange} value={SelectedTask.ActualDueDate} />
                                                </div>
                                            </Card>
                                            <Card className='actionValueCards'><p className='categoryCArds'>Status</p>
                                                <div className='changeStatusHover' onMouseEnter={onStatusChange} onMouseLeave={offStatusChange} value={SelectedTask.Status}>
                                                    <p className='statusValue'>{
                                                        SelectedTask.Status === 'requested' ? 'Requested' :
                                                            SelectedTask.Status === 'inProgress' ? 'In Progress' :
                                                                SelectedTask.Status === 'Complete' || SelectedTask.Status === 'Completed' ? 'Completed' :
                                                                    SelectedTask.Status === 'pending' ? 'Pending' :
                                                                        SelectedTask.Status === 'Declined peacefully.' ? 'Declined' :
                                                                            SelectedTask.Status
                                                    }</p>
                                                    {StatusHover && (
                                                        <>
                                                            <div className="statusChangePointer" />
                                                            <div className='statusChanageContainer' value={SelectedTask.Status}>
                                                                <div className='statusChangeOptions' id='pending' onClick={handleStatusChange} style={{ backgroundColor: SelectedTask.Status === 'pending' ? '#D5922D' : '' }}><p className='statusValue'>Pending</p></div>
                                                                <div className='statusChangeOptions' id='inProgress' onClick={handleStatusChange} style={{ backgroundColor: SelectedTask.Status === 'inProgress' ? '#D5922D' : '' }}><p className='statusValue'>In Progress</p></div>
                                                                <div className='statusChangeOptions' id='Completed' onClick={handleStatusChange} style={{ backgroundColor: SelectedTask.Status === 'Completed' || SelectedTask.Status === 'Complete' ? '#D5922D' : '' }}><p className='statusValue'>Completed</p></div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </Card>
                                            <div className='updateTaskStatus'>
                                                {(SelectedTask.Status === 'Declined peacefully.') ?
                                                    <p className='Txt' onClick={abandonTask}>Abandon This Task.</p>
                                                    :
                                                    <button className='btnSubmitTask' onClick={handleUpdateTask}><p>Update</p></button>
                                                }

                                            </div>

                                        </div>}
                                </div>
                            </Card> : null}
                    </>}
            </div >
        );
    }
});

export default Alkemia;
