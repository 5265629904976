import React from 'react';
import './style.scss';

function Textbox({ className, id, placeholder, type, value, onKeyDown, onChange, style = {}, inputStyle={}}) {
    const customStyle = style
    return (
        <div className={`textBox ${className}`} style={customStyle} type={type} id={id} value={value} onKeyDown={onKeyDown} onChange={onChange}>
            <p className='placeHolder'>{placeholder}</p>
            <input type={type} id={id} value={value} onKeyDown={onKeyDown} onChange={onChange} style={inputStyle} />
        </div>
    )
}

export default Textbox
