import { observer } from "mobx-react-lite";
import { useStore } from "../../../stores/Store";
import { Card, Input, View, Button, LoadingBarGauge } from "../../../components";
import "./style.css";
import React, { useEffect, useState } from "react";
import { Colors } from "chart.js";
import { FaExclamation, FaMinus, FaCheck } from 'react-icons/fa';
var mobile = require("is-mobile");

const Dev = observer(() => {

    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [DesignTabs, setDesignTabs] = useState('requested');
    const [Jobs, setJobs] = useState([]);
    const [ShowData, setShowData] = useState(false);
    const [IndexChange, setIndexChange] = useState(0);
    


    useEffect(() => {
        getJobs();
    }, []);

    function getJobs() {
        store.setLoading(true);
        store.getJob().then((ret) => {
          if (typeof ret === "string" || !ret) {
            setJobs([]);
            store.setLoading(false);
            return;
          }
          let designJobs = ret.filter((a) => a.Department === 'Dev');
          setJobs(designJobs)
          forceUpdate();
          store.setLoading(false);
        });
    }

    function pageSelected(e) {
    setDesignTabs(e.target.id)
    }

    function showDataButton(e) {
        setShowData(true);
        setIndexChange(e.currentTarget.id);
    }


    if (mobile()){
        return(
            <div>
                <div className="designHomePage">
                    <Card id='requested' className={`${(DesignTabs === 'requested') ? 'tabCardsSelected' : 'tabCards'}`} onClick={pageSelected}>Requested</Card>
                    <Card id='inProgress' className={`${(DesignTabs === 'inProgress') ? 'tabCardsSelected' : 'tabCards'}`} onClick={pageSelected}>In Progress</Card>
                    <Card id='completed' className={`${(DesignTabs === 'completed') ? 'tabCardsSelected' : 'tabCards'}`} onClick={pageSelected}>Completed</Card>
                </div>

                {(DesignTabs === 'requested') ? 
                <>
                    <Card className="designHomePageCards">
                        <h1>Requested Jobs</h1>
                            {Jobs.map((job, index) => {
                                if (job.Status !== 'requested'){
                                    return
                                }
                                return (
                                <div key={index} className="existingjobDiv receivedJob">
                                    <p>{job.Title}</p>
                                    <Button onClick={showDataButton} id={index}>Edit</Button>
                                </div>
                                );
                            })}
                    </Card>
                </>
                : null}


                {(DesignTabs === 'inProgress') ? 
                <>
                    <Card className="designHomePageCards">
                        <h1>In Progress Jobs</h1>
                            {Jobs.map((job, index) => {
                                if (job.Status !== 'inProgress'){
                                    return
                                }
                                return (
                                <div key={index} className="existingjobDiv receivedJob">
                                    <p>{job.Title}</p>
                                    <Button onClick={showDataButton} id={index}>Edit</Button>
                                </div>
                                );
                            })}
                    </Card>
                </>
                : null}

                {(DesignTabs === 'completed') ? 
                <>
                    <Card className="designHomePageCards">
                        <h1>Completed Jobs</h1>
                            {Jobs.map((job, index) => {
                                if (job.Status !== 'complete'){
                                    return
                                }
                                return (
                                <div key={index} className="existingjobDiv receivedJob">
                                    <p>{job.Title}</p>
                                    <Button onClick={showDataButton} id={index}>Edit</Button>
                                </div>
                                );
                            })}
                    </Card>
                </>
                : null}
              
            </div>
        )
    }else{
        return(
            <div>
                <div className="designHomePage">
                    <Card id='requested' className={`${(DesignTabs === 'requested') ? 'tabCardsSelected' : 'tabCards'}`} onClick={pageSelected}>Requested</Card>
                    <Card id='inProgress' className={`${(DesignTabs === 'inProgress') ? 'tabCardsSelected' : 'tabCards'}`} onClick={pageSelected}>In Progress</Card>
                    <Card id='completed' className={`${(DesignTabs === 'completed') ? 'tabCardsSelected' : 'tabCards'}`} onClick={pageSelected}>Completed</Card>
                </div>

                {(DesignTabs === 'requested') ? 
                <>
                    <Card className="designHomePageCards">
                        <h1>Requested Jobs</h1>
                            {Jobs.map((job, index) => {
                                if (job.Status !== 'requested'){
                                    return
                                }
                                return (
                                <div key={index} className="existingjobDiv receivedJob">
                                    <p>{job.Title}</p>
                                    <Button onClick={showDataButton} id={index}>Edit</Button>
                                </div>
                                );
                            })}
                    </Card>
                </>
                : null}
                
                {(DesignTabs === 'inProgress') ? 
                <>
                    <Card className="designHomePageCards">
                        <h1>In Progress Jobs</h1>
                            {Jobs.map((job, index) => {
                                if (job.Status !== 'inProgress'){
                                    return
                                }
                                return (
                                <div key={index} className="existingjobDiv receivedJob">
                                    <p>{job.Title}</p>
                                    <Button onClick={showDataButton} id={index}>Edit</Button>
                                </div>
                                );
                            })}
                    </Card>
                </>
                : null}

                {(DesignTabs === 'completed') ? 
                <>
                    <Card className="designHomePageCards">
                        <h1>Completed Jobs</h1>
                            {Jobs.map((job, index) => {
                                if (job.Status !== 'complete'){
                                    return
                                }
                                return (
                                <div key={index} className="existingjobDiv receivedJob">
                                    <p>{job.Title}</p>
                                    <Button onClick={showDataButton} id={index}>Edit</Button>
                                </div>
                                );
                            })}
                    </Card>
                </>
                : null}
              
            </div>
        )
    }

})

export default Dev;