import React, { useEffect, useState, updateState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import { Input, View, Button, Card, InvesteeSidebar } from '../../components';
import AlkemiaBriefing from './AlkemiaBriefing/AlkemiaBriefing';
import Report from './Report/Report';
import Metrics from'./Metrics/Metrics';
import { NULL } from 'sass';
var mobile = require('is-mobile');

const Investee = observer(() => {
    const store = useStore();
    const navigate = useNavigate();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [isHovered, setIsHovered] = useState(false);
    const [PageSelected, setPageSelected] = useState('AlkemiaBriefing');
    const [CompanyName, setCompanyName] = useState('')

    useEffect(() => {
        handleCompany()
    }, []);
    const handleCompany = () => {
        let company = store.user.Company;
        company = company.replace(/\s/g, '');
        setCompanyName(company);
    }
    const handleHoverStart = () => {
        setIsHovered(true);
    };

    const handleHoverEnd = () => {
        setIsHovered(false);
    };

    function selectPage(e) {
        setPageSelected(e.currentTarget.id);
        forceUpdate();
    }
    function logout() {
        store.logout()
    }
    if (mobile()) {
        return (
            <View >
            </View>
        );
    } else {
        return (
            <div className='investeeHomePage' style={{ width: `${store.getScreenWidth()}px`, height: `${store.getScreenHeight()}px` }}>
                <div className='investeeSidebar' >
                    {/* Content of investeeSidebar div */}
                </div>
                <InvesteeSidebar onMouseEnter={handleHoverStart} onMouseLeave={handleHoverEnd} Active={isHovered} pageClicked={selectPage} pageSelected={PageSelected} logout={logout} compName={CompanyName} />
                <div className='investeeBody'>
                    {(PageSelected === 'AlkemiaBriefing') ? <AlkemiaBriefing /> : null}
                    {(PageSelected === 'Reports') ? <Report /> : null}
                    {(PageSelected === 'Metrics')? <Metrics /> : null}
                </div>
            </div>
        );
    }
});

export default Investee;
