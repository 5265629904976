import React, { useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import './style.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import AionsIcon from '../../../assets/images/Aions.png';
import LevelFinanceLogo from '../../../assets/images/LevelFinance.png';
import CocoBrandsLogo from '../../../assets/images/CocoBrands.png';
import TubbysKitchenLogo from '../../../assets/images/TubbysKitchen.png';
import ScootersLogo from '../../../assets/images/scooterLogo.png';
import AlkemiaLogo from '../../../assets/images/alkemia.png';
import AexLogo from '../../../assets/images/aex.png';
import CloseCard from '../../../assets/images/closeCard.png';
import {
    Input, BurnrateGauge, Button, Card, CompaniesIncomeExpenses, CompaniesIncome,
    CompaniesGrowth, CompaniesIncomePie, CompaniesExpenses, ReportsBarGraph,
    ExpensesGraph, PieChart, AlkemiaStackedGraph, TelkomDrawDownGraph,
    AionsIncome, OpexPie, CompaniesExpensePie, BurnRateGraph, AEXTransactionAmount,
    AEXSignUps, AEXTransactions, AEXGenderSignups, AEXAgeGroups, ReportExport, AEXConversions, SalesFunnelTable, AionsESDFunnel, AEXFunnel, AEXFunnelBar, AlkemiaFunnelBar, AionsESDFunnelBar
} from '../../../components';
import { PDFDownloadLink } from "@react-pdf/renderer";
import { FaComment, FaCommentDollar, FaCommentSlash } from 'react-icons/fa';
import { extendClass } from 'highcharts';
var mobile = require('is-mobile');
const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
]

const Reports = observer(() => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [FromMonth, setFromMonth] = useState(0);
    const [FromYear, setFromYear] = useState(new Date().getFullYear());
    const [ToMonth, setToMonth] = useState(0);
    const [StartDate, setStartDate] = useState();
    const [EndDate, setEndDate] = useState();
    const [ToYear, setToYear] = useState(new Date().getFullYear());
    const [Aions, setAions] = useState([]);
    const [Desc, setDesc] = useState(false)
    const [PieData, setPieData] = useState([]);
    const [BurnRateData, setBurnRateData] = useState({});
    const [ExpenseDescription, setExpenseDescription] = useState('');
    const [GrowthRateData, setGrowthRateData] = useState({});
    const [AEXUsers, setAEXUsers] = useState([]);
    const [AEXtransactions, setAEXTransactions] = useState([]);
    const [AEXTransactionamount, setAEXTransactionAmount] = useState([]);
    const [IncomeExpForecastData, setIncomeExpForecastData] = useState([]);
    const [Arr, setArr] = useState([]);
    const [Companies, setCompanies] = useState([]);
    const graphRef = useRef(null);
    const [ReportGenerated, setReportGenerated] = useState(false);
    const [AionsIncomeExpenseComment, setAionsIncomeExpenseComment] = useState('');
    const [AionsIncomeReportComment, setAionsIncomeReportComment] = useState('');
    const [AionsExpenseReportComment, setAionsExpenseReportComment] = useState('');
    const [AionsExpensesComment, setAionsExpensesComment] = useState('');
    const [TelkomDrawdownComment, setTelkomDrawdownComment] = useState('');
    const [AionsBurnRateComment, setAionsBurnRateComment] = useState('');
    const [AionsGrowthComment, setAionsGrowthComment] = useState('');
    const [AEXSignUpsComment, setAEXSignUpsComment] = useState('');
    const [AEXTransactionsComment, setAEXTransactionsComment] = useState('');
    const [AEXTransactionAmountComment, setAEXTransactionAmountComment] = useState('');
    const [AEXGenderComment, setAEXGenderComment] = useState('');
    const [AEXAgeComment, setAEXAgeComment] = useState('');
    const [AlkemiaClientsComment, setAlkemiaClientsComment] = useState('');
    const [AEXConversionsComments, setAEXConversionsComments] = useState('');
    const [AEXConversionObj, setAEXConversionObj] = useState({});
    const [AexDealGraph, setAEXDealGraph] = useState({});
    const [AionsDealGraph, setAionsDealGraph] = useState({});
    const [GraphDeals, setGraphDeals] = useState({});
    const [Labels, setLabels] = useState({
        Closed: [],
        LikelyTo: [],
        AlkemiaClosed: [],
        AlkemiaLikely: [],
        AexClosed: [],
        AexLikely: []
    });
    const [DealsObj, setDealsObj] = useState({
        New: [],
        InitMeeting: [],
        Proposal: [],
        SecondMeeting: [],
        CloseThisMonth: [],
        Closed: []
    });
    useEffect(() => {
        let currDate = new Date();
        let x = new Date(currDate.getFullYear(), currDate.getMonth(), 1);
        let y = new Date(currDate.getFullYear(), currDate.getMonth() + 1, 0);
        loadCompany(x, y);
    }, []);

    const loadCompany = async (startDate, endDate) => {
        let reqStartDate = formatDate(startDate);
        let reqEndDate = formatDate(endDate);
        const defaultForecastFrom = new Date(reqEndDate);
        defaultForecastFrom.setDate(defaultForecastFrom.getDate() + 1);
        const defaultForecastTo = new Date(defaultForecastFrom);
        defaultForecastTo.setMonth(defaultForecastTo.getMonth() + 3);
        defaultForecastTo.setDate(defaultForecastTo.getDate() - 1);
        let forecastStartDate = formatDate(defaultForecastFrom);
        let forecastEndDate = formatDate(defaultForecastTo);
        try {
            store.setLoading(true);
            const data = await store.getAions();
            let aexData = await store.getAEXdata();
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth();
            const startDate = new Date(currentYear, currentMonth, 1);
            const formattedStartDate = startDate.toISOString();
            const endDate = new Date(currentYear, currentMonth + 1, 0);
            const formattedEndDate = endDate.toISOString();
            if (aexData) {

                const getMonthYearLabel = (date) => {
                    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                    const objDate = new Date(date);
                    return `${monthNames[objDate.getMonth()]} ${objDate.getFullYear()}`;
                };

                store.setLoading(false);
                let users = aexData.user;
                let transactions = aexData.transactions;
                let aexUsers = [];
                let aexTrans = [];
                let transAmt = [];
                let monthlySignUps = {};
                let monthlyTrans = {};
                let monthlyAmounts = {};
                const currMonthTrans = transactions.filter((target) => {
                    let forecastDate = target.Date;
                    if (forecastDate !== undefined) {
                        return forecastDate >= formattedStartDate && forecastDate <= formattedEndDate;
                    }
                    return false;
                });
                const currMonthSignUps = users.filter((target) => {
                    let forecastDate = target.SignupDate;
                    if (forecastDate !== undefined) {
                        return forecastDate >= formattedStartDate && forecastDate <= formattedEndDate;
                    }
                    return false;
                });
                let ConversionsObj = {
                    TotalSignUps: currMonthSignUps.length,
                    TotalPurchase: currMonthTrans.length,
                    LabelDate: formattedStartDate
                }
                setAEXConversionObj(ConversionsObj);
                const filteredUsers = users.filter((item) => {
                    let actualDate = item.SignupDate;
                    if (actualDate !== undefined) {
                        return actualDate >= reqStartDate && actualDate <= reqEndDate;
                    }
                    return false;
                });
                let Aex = [];
                for (let i = 0; i < filteredUsers.length; i++) {
                    let user = filteredUsers[i];
                    let id = ' ';

                    if (user.IDNumber.includes('@')) {
                        continue;
                    } else {
                        id = user.IDNumber;
                    }
                    let DOB = '';
                    let Gender = '';
                    let Age = '';
                    const strLength = id.length;
                    for (let i = 0; i < strLength; i++) {

                        if (i < (strLength - 7)) {
                            DOB += id[i];
                            let tmpDob = DOB;
                            let dobYear = parseInt(tmpDob.substring(0, 2));
                            let dobMonth = parseInt(tmpDob.substring(2, 4)) - 1;
                            let dobDay = parseInt(tmpDob.substring(4, 6));
                            let century = dobYear < 20 ? 2000 : 1900;
                            let dob = new Date(century + dobYear, dobMonth, dobDay)
                            const birthYear = century + dobYear;
                            let currDate = new Date();
                            const currentYear = new Date().getFullYear();
                            let age = currentYear - birthYear;

                            if (currDate.getMonth() < dob.getMonth() || currDate.getMonth() === dob.getMonth() && currDate.getDate() < dob.getDate()) {
                                Age = age - 1;
                            } else {
                                Age = age;
                            }
                        } else if (i === 6) {
                            Gender = (id[i] > 4) ? 'male' : 'female';
                        }
                    }
                    let obj = {
                        Dob: DOB,
                        Gender: Gender,
                        Age: Age
                    }
                    Aex.push(obj);
                }
                setArr(Aex);
                users.forEach(user => {
                    if (user.SignupDate) {
                        const monthYearLabel = getMonthYearLabel(user.SignupDate);
                        if (!monthlySignUps[monthYearLabel]) {
                            monthlySignUps[monthYearLabel] = {
                                Label: monthYearLabel,
                                NumOfSignups: 0
                            };
                        }
                        monthlySignUps[monthYearLabel].NumOfSignups++;
                    }
                });
                aexUsers.push(...Object.values(monthlySignUps));
                setAEXUsers(aexUsers)

                transactions.forEach(user => {
                    if (user.Date) {
                        const monthYearLabel = getMonthYearLabel(user.Date);
                        if (!monthlyTrans[monthYearLabel]) {
                            monthlyTrans[monthYearLabel] = {
                                Label: monthYearLabel,
                                NumOfTransactions: 0
                            };
                        }
                        monthlyTrans[monthYearLabel].NumOfTransactions++;
                    }
                });
                aexTrans.push(...Object.values(monthlyTrans));
                setAEXTransactions(aexTrans);
                transactions.forEach(transaction => {
                    if (transaction.Date) {
                        const monthYearLabel = getMonthYearLabel(transaction.Date);
                        if (!monthlyAmounts[monthYearLabel]) {
                            monthlyAmounts[monthYearLabel] = {
                                Label: monthYearLabel,
                                Amount: 0,
                            };
                        }
                        monthlyAmounts[monthYearLabel].Amount += transaction.Amount;
                    }
                });
                transAmt.push(...Object.values(monthlyAmounts));
                setAEXTransactionAmount(transAmt);
            }
            if (data) {
                store.setLoading(false);
                let forecast = [];
                let actuals = [];
                let monthlyDataArray = [];
                const processedMonths = new Map();
                let currDate = new Date()
                var currMonthStartDate = new Date(currDate.getFullYear(), currDate.getMonth(), 1);
                var currMonthEndDate = new Date(currDate.getFullYear(), currDate.getMonth() + 1, 0);
                var prevMonthStartDate = new Date(currDate.getFullYear(), currDate.getMonth() - 1, 1);
                let currStartDate = formatDate(currMonthStartDate);
                let currEndDate = formatDate(currMonthEndDate);
                var prevMonthEndDate = new Date(currDate.getFullYear(), currDate.getMonth(), 0);
                var firstDayOfMonthBeforePrevMonth = new Date(currDate.getFullYear(), currDate.getMonth() - 2, 1);
                var lastDayOfMonthBeforePrevMonth = new Date(currDate.getFullYear(), currDate.getMonth() - 1, 0);
                let startDate = formatDate(prevMonthStartDate);
                let endDate = formatDate(prevMonthEndDate);

                const getMonthYearLabel = (date) => {
                    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                    const objDate = new Date(date);
                    return `${monthNames[objDate.getMonth()]} ${objDate.getFullYear()}`;
                };

                data.forEach((item) => {
                    if (item.Type === "Forecast") {
                        forecast.push(item);
                    }
                    if (item.Type === "Actuals") {
                        actuals.push(item);
                    }
                });

                const uniqueMonths = new Set();
                actuals.forEach((item) => {
                    if (item.Type === "Actuals") {
                        const month = new Date(item.Date).getMonth() + 1;
                        uniqueMonths.add(month);
                    }
                });
                const numberOfMonths = uniqueMonths.size;
                const earliestDate = actuals.reduce((minDate, item) => {
                    if (item.Type === "Actuals") {
                        const currentDate = new Date(item.Date);
                        return currentDate < minDate ? currentDate : minDate;
                    }
                    return minDate;
                }, new Date());
                const firstDayOfMonth = new Date(earliestDate.getFullYear(), earliestDate.getMonth(), 1);
                const lastDayOfMonth = new Date(earliestDate.getFullYear(), earliestDate.getMonth() + 1, 0);
                let firstDate = formatDate(firstDayOfMonth);
                let lastDate = formatDate(lastDayOfMonth);

                const IncomeExpDefaults = forecast.filter((target) => {
                    let forecastDate = target.Date;
                    return forecastDate >= reqStartDate && forecastDate <= forecastEndDate;
                });

                const firstActs = actuals.filter((target) => {
                    let forecastDate = target.Date;
                    return forecastDate >= firstDate && forecastDate <= lastDate;
                });
                const CurrMonthData = actuals.filter((target) => {
                    let forecastDate = target.Date;
                    return forecastDate >= currStartDate && forecastDate <= currEndDate;
                });

                const prevMonthData = actuals.filter((acts) => {
                    let actualDate = acts.Date;
                    return actualDate >= startDate && actualDate <= endDate;
                });
                const incomeData = prevMonthData.reduce((acc, obj) => {
                    const alkemiaArray = Array.isArray(obj.Income.Alkemia) ? obj.Income.Alkemia : [];
                    const amountValues = alkemiaArray.map(item => item.Amount);
                    amountValues.push(obj.Income.Interest, obj.Income.AEX);
                    return acc.concat(amountValues);
                }, []);
                const ExpensesData = prevMonthData.reduce((acc, obj) => {
                    const Opex = Array.isArray(obj.OPEX.Aions) ? obj.OPEX.Aions : [];
                    const amountValues = Opex.map(item => item.Amount);
                    return acc.concat(amountValues);
                }, []);
                const InitialValue = firstActs.reduce((acc, obj) => {
                    const alkemiaArray = Array.isArray(obj.Income.Alkemia) ? obj.Income.Alkemia : [];
                    const amountValues = alkemiaArray.map(item => item.Amount);
                    amountValues.push(obj.Income.Interest, obj.Income.AEX);
                    return acc.concat(amountValues);
                }, []);
                const InitialExpenses = firstActs.reduce((acc, obj) => {
                    const Opex = Array.isArray(obj.OPEX.Aions) ? obj.OPEX.Aions : [];
                    const amountValues = Opex.map(item => item.Amount);
                    return acc.concat(amountValues);
                }, []);
                const CurrMOnthIncomeData = CurrMonthData.reduce((acc, obj) => {
                    const alkemiaArray = Array.isArray(obj.Income.Alkemia) ? obj.Income.Alkemia : [];
                    const amountValues = alkemiaArray.map(item => item.Amount);
                    amountValues.push(obj.Income.Interest, obj.Income.AEX);
                    return acc.concat(amountValues);
                }, []);
                const CurrMonthExpensesData = CurrMonthData.reduce((acc, obj) => {
                    const Opex = Array.isArray(obj.OPEX.Aions) ? obj.OPEX.Aions : [];
                    const amountValues = Opex.map(item => item.Amount);
                    return acc.concat(amountValues);
                }, []);
                prevMonthData.forEach((item) => {
                    const itemDate = new Date(item.Date);
                    const label = getMonthYearLabel(itemDate);

                    if (!processedMonths.has(label)) {
                        let BurnrateData = {
                            Income: incomeData.reduce((total, amount) => total + amount, 0),
                            Expenses: ExpensesData.reduce((total, amount) => total + amount, 0),
                            CashOnHand: item.Income.CashBalance,
                            Label: label,
                            Type: 'BurnRate'
                        };
                        processedMonths.set(label, true);
                        setBurnRateData(BurnrateData);
                    }
                });
                const growthData = new Map();
                if (InitialValue) {
                    const itemDate = new Date();
                    const label = getMonthYearLabel(itemDate);
                    let Income = CurrMOnthIncomeData.reduce((total, amount) => total + amount, 0);
                    let initExp = InitialExpenses.reduce((total, amount) => total + amount, 0);
                    let currIncome = incomeData.reduce((total, amount) => total + amount, 0);
                    let prevIncome = InitialValue.reduce((total, amount) => total + amount, 0);
                    let currExp = ExpensesData.reduce((total, amount) => total + amount, 0);
                    const uniqueMonths = new Set();
                    actuals.forEach((actualItem) => {
                        if (actualItem.Type === "Actuals") {
                            const month = new Date(actualItem.Date).getMonth() + 1;
                            uniqueMonths.add(month);
                        }
                    });

                    const numberOfMonths = uniqueMonths.size;
                    function calculateCMGR(finalMonthValue, initialMonthValue) {
                        const cmgr = Math.pow(finalMonthValue / initialMonthValue, 1 / numberOfMonths) - 1;
                        return cmgr;
                    }

                    const finalMonthValue = Income;
                    const initialMonthValue = prevIncome;
                    const result = calculateCMGR(finalMonthValue, initialMonthValue);
                    let tot = result * 100;
                    if (!growthData.has(label)) {
                        let GrowthData = {
                            Revenue: prevIncome,
                            Expenses: initExp,
                            GrowthRate: tot,
                            Label: firstDate,
                        };
                        growthData.set(label, true);
                        setGrowthRateData(GrowthData);
                    }
                }

                IncomeExpDefaults.forEach((item) => {
                    const itemDate = new Date(item.Date);
                    const label = getMonthYearLabel(itemDate);
                    if (label) {
                        let ForecastData = {
                            Income: {
                                TotalIncomeForecast: item.Income.AEX + item.Income.Alkemia + item.Income.Interest,
                            },
                            IncomeAndExpense: {
                                IncomeForecast: item.Income.AEX + item.Income.Alkemia + item.Income.Interest,
                                ExpenseForecast: item.OPEX.AEX + item.OPEX.Alkemia + item.OPEX.Aions,
                            },
                            Label: label,
                            Type: 'Forecast'
                        };
                        processedMonths.set(label, true);
                        monthlyDataArray.push(ForecastData);
                    }
                });
                setIncomeExpForecastData(monthlyDataArray);
            }
            if (!data) {
                store.setLoading(false);
            }
            forceUpdate();
        } catch (error) {
            console.error("An error occurred:", error);
        }
        try {
            store.setLoading(true);
            const data = await store.getAions();
            if (data) {
                store.setLoading(false);
                let forecast = [];
                let actuals = [];
                let monthlyDataArray = [];
                const processedMonths = new Map();
                let currDate = new Date()
                var currMonthStartDate = new Date(currDate.getFullYear(), currDate.getMonth(), 1);
                var currMonthEndDate = new Date(currDate.getFullYear(), currDate.getMonth() + 1, 0);
                var prevMonthStartDate = new Date(currDate.getFullYear(), currDate.getMonth() - 1, 1);
                var prevMonthEndDate = new Date(currDate.getFullYear(), currDate.getMonth(), 0);
                var firstDayOfMonthBeforePrevMonth = new Date(currDate.getFullYear(), currDate.getMonth() - 2, 1);
                var lastDayOfMonthBeforePrevMonth = new Date(currDate.getFullYear(), currDate.getMonth() - 1, 0);
                let currStartDate = formatDate(currMonthStartDate);
                let currEndDate = formatDate(currMonthEndDate);
                let prevStartDate = formatDate(prevMonthStartDate);
                let prevEndDate = formatDate(prevMonthEndDate);
                let monthBeforePrevStart = formatDate(firstDayOfMonthBeforePrevMonth);
                let monthBeforePrevEnd = formatDate(lastDayOfMonthBeforePrevMonth);

                const getMonthYearLabel = (date) => {
                    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                    const objDate = new Date(date);
                    return `${monthNames[objDate.getMonth()]} ${objDate.getFullYear()}`;
                };

                data.forEach((item) => {
                    if (item.Type === "Forecast") {
                        forecast.push(item);
                    }
                    if (item.Type === "Actuals") {
                        actuals.push(item);
                    }
                });

                const currMonthActuals = actuals.filter((actual) => {
                    let actualDate = actual.Date;
                    return actualDate >= currStartDate && actualDate <= currEndDate;
                }).map((item) => {
                    let itemDate = item.Date;
                    const label = getMonthYearLabel(itemDate);
                    return {
                        Income: item.Income.Alkemia,
                        Opex: item.OPEX,
                        Label: label
                    };
                });

                const prevMonthActuals = actuals.filter((actual) => {
                    let actualDate = actual.Date;
                    return actualDate >= prevStartDate && actualDate <= prevEndDate;
                }).map((item) => {
                    let itemDate = item.Date;
                    const label = getMonthYearLabel(itemDate);
                    return {
                        Income: item.Income.Alkemia,
                        Opex: item.OPEX,
                        Label: label
                    };
                });
                const monthBeforePrevActs = actuals.filter((actual) => {
                    let actualDate = actual.Date;
                    return actualDate >= monthBeforePrevStart && actualDate <= monthBeforePrevEnd;
                }).map((item) => {
                    let itemDate = item.Date;
                    const label = getMonthYearLabel(itemDate);
                    return {
                        Income: item.Income.Alkemia,
                        Opex: item.OPEX,
                        Label: label
                    };
                });
                let IncomeExpActuals = {
                    CurrentMonth: currMonthActuals,
                    PreviousMonth: prevMonthActuals,
                    MonthBeforePrev: monthBeforePrevActs
                }
                setPieData(IncomeExpActuals);


                const filteredActuals = actuals.filter((actual) => {
                    let actualDate = actual.Date;
                    return actualDate >= reqStartDate && actualDate <= reqEndDate;
                });
                const filteredForecast = forecast.filter((target) => {
                    let forecastDate = target.Date;
                    return forecastDate >= reqStartDate && forecastDate <= reqEndDate;
                });
                const filteredDefaultForecast = forecast.filter((target) => {
                    let forecastDate = target.Date;
                    return forecastDate >= reqStartDate && forecastDate <= forecastEndDate;
                });

                const IncomeExpDefaults = forecast.filter((target) => {
                    let forecastDate = target.Date;
                    return forecastDate >= reqStartDate && forecastDate <= forecastEndDate;
                });
                let aionsData = {
                    Forecast: filteredForecast,
                    Actuals: filteredActuals,
                    Defaults: filteredDefaultForecast,
                    IncomeExp: IncomeExpDefaults
                };
                aionsData.Defaults.forEach((item) => {
                    const itemDate = new Date(item.Date);
                    const label = getMonthYearLabel(itemDate);
                    if (processedMonths) {
                        let ForecastData = {
                            Income: {
                                TotalIncomeForecast: item.Income.AEX + item.Income.Alkemia + item.Income.Interest,
                                AlkemiaForecast: item.Income.Alkemia,
                                AexForecast: item.Income.AEX,
                                InterestForecast: item.Income.Interest,
                            },
                            IncomeAndExpense: {
                                IncomeForecast: item.Income.AEX + item.Income.Alkemia + item.Income.Interest,
                                ExpenseForecast: item.COS.AEX + item.COS.Alkemia + item.OPEX.AEX + item.OPEX.Alkemia + item.OPEX.Aions,
                            },
                            Expenses: {
                                COSForecast: item.COS.AEX + item.COS.Alkemia,
                                ExpensesForecast: item.OPEX.AEX + item.OPEX.Alkemia + item.OPEX.Aions,
                            },
                            TelkomForecast: item.Telkom.Amount,
                            Label: label,
                            Type: 'Forecast'
                        };
                        processedMonths.set(label, true);
                        monthlyDataArray.push(ForecastData);
                    }
                });
                const incomeData = aionsData.Actuals.reduce((acc, obj) => {
                    const alkemiaArray = Array.isArray(obj.Income.Alkemia) ? obj.Income.Alkemia : [];
                    const amountValues = alkemiaArray.map(item => item.Amount);
                    return acc.concat(amountValues);
                }, []);
                const ExpensesData = aionsData.Actuals.reduce((acc, obj) => {
                    const Opex = Array.isArray(obj.OPEX.Aions) ? obj.OPEX.Aions : [];
                    const amountValues = Opex.map(item => item.Amount);
                    return acc.concat(amountValues);
                }, []);
                aionsData.Actuals.forEach((item) => {
                    const itemDate = new Date(item.Date);
                    const label = getMonthYearLabel(itemDate);
                    if (processedMonths) {
                        let Alkemia = item.Income.Alkemia;
                        const amounts = Alkemia.map((item) => item.Amount);
                        const totalAlkemiaAmount = amounts.reduce((acc, current) => acc + current, 0);
                        let AionsArr = item.OPEX.Aions;
                        const totAmounts = AionsArr.map((amount) => amount.Amount);
                        const totalAionsAmount = totAmounts.reduce((acc, current) => acc + current, 0);
                        let ActualData = {
                            Income: {
                                TotalIncome: totalAlkemiaAmount + item.Income.AEX + item.Income.Interest,
                                Alkemia: totalAlkemiaAmount,
                                Aex: item.Income.AEX,
                                Interest: item.Income.Interest,
                            },
                            IncomeAndExpense: {
                                Income: totalAlkemiaAmount + item.Income.AEX + item.Income.Interest,
                                Expense: totalAionsAmount + item.COS.AEX + item.COS.Alkemia,
                            },
                            Expenses: {
                                COS: item.COS.AEX + item.COS.Alkemia,
                                Expenses: totalAionsAmount,
                            },
                            Telkom: item.Telkom.Amount,
                            Label: label,
                            Type: 'Actual',
                        };
                        processedMonths.set(label, true);
                        monthlyDataArray.push(ActualData);
                    }
                });
                setAions(monthlyDataArray);
            }
            if (!data) {
                store.setLoading(false);
            }
            forceUpdate();
        } catch (error) {
            console.error("An error occurred:", error);
        }
        try {
            store.setLoading(true);
            let deals = await store.getDeals();
            if (deals) {
                store.setLoading(false);
                let Obj = DealsObj;
                let newTalkingArr = [];
                let initMeetingArr = [];
                let proposalArr = [];
                let secondMeetingArr = [];
                let likelyToCloseArr = [];
                let closedDealArr = [];

                deals.forEach((item) => {
                    switch (item.properties.dealstage) {
                        case 'new':
                            newTalkingArr.push(item)
                            break
                        case 'talking':
                            newTalkingArr.push(item)
                            break
                        case 'initialMeeting':
                            initMeetingArr.push(item)
                            break
                        case 'initialProposalSent':
                            proposalArr.push(item)
                            break
                        case 'workingOnProposal':
                            proposalArr.push(item)
                            break
                        case 'secondMeeting':
                            secondMeetingArr.push(item)
                            break
                        case 'likelyToCloseThisMonth':
                            likelyToCloseArr.push(item)
                            break
                        case 'closedWon':
                            closedDealArr.push(item)
                            break
                    }

                });
                Obj.New = newTalkingArr;
                Obj.InitMeeting = initMeetingArr;
                Obj.Proposal = proposalArr;
                Obj.SecondMeeting = secondMeetingArr;
                Obj.CloseThisMonth = likelyToCloseArr;
                Obj.Closed = closedDealArr;

                let labelsObj = Labels;

                let aionsLikelyTo = [];
                let aionsClosed = [];
                let aexLikelyTo = [];
                let aexClosed = [];
                let alkemiaLikelyTo = [];
                let alkemiaClosed = [];

                likelyToCloseArr.forEach((item) => {
                    const dynamicPattern = /Aions\sESD\s-|ESD|Aions\s-\sESD/;
                    const alkemiaPattern = /Alkemia\s-\s/;
                    const aexPattern = /AEX\s-\s/;

                    if (dynamicPattern.test(item.properties.dealname)) {
                        const modifiedDealname = item.properties.dealname.replace(dynamicPattern, "").replace(/^R/, "");

                        const formattedAmount = item.properties.amount ? `R${Number(item.properties.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : 'R0';
                        const updatedString = `${modifiedDealname} ${formattedAmount}`;
                        aionsLikelyTo.push(updatedString);
                    } else {
                        const formattedAmount = item.properties.amount ? `R${Number(item.properties.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : 'R0';
                        const originalString = `${item.properties.dealname} ${formattedAmount}`;
                        aionsLikelyTo.push(originalString);
                    }
                    if (alkemiaPattern.test(item.properties.dealname)) {
                        const modifiedDealname = item.properties.dealname.replace(alkemiaPattern, "");
                        const formattedAmount = item.properties.amount ? `R${Number(item.properties.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : 'R0';
                        const updatedString = `${modifiedDealname} ${formattedAmount}`;
                        alkemiaLikelyTo.push(updatedString);
                    } else {
                        const formattedAmount = item.properties.amount ? `R${Number(item.properties.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : 'R0';
                        const originalString = `${item.properties.dealname} ${formattedAmount}`;
                        alkemiaLikelyTo.push(originalString);
                    }
                    if (aexPattern.test(item.properties.dealname)) {
                        const modifiedDealname = item.properties.dealname.replace(aexPattern, "");
                        const formattedAmount = item.properties.amount ? `R${Number(item.properties.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : 'R0';
                        const updatedString = `${modifiedDealname} ${formattedAmount}`;
                        aexLikelyTo.push(updatedString);
                    } else {
                        const formattedAmount = item.properties.amount ? `R${Number(item.properties.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : 'R0';
                        const originalString = `${item.properties.dealname} ${formattedAmount}`;
                        aexLikelyTo.push(originalString);
                    }
                });
                closedDealArr.forEach((item) => {
                    const dynamicPattern = /Aions\sESD\s-|ESD|Aions\s-\sESD/;
                    const alkemiaPattern = /Alkemia\s-\s/;
                    const aexPattern = /AEX\s-\s/;

                    if (dynamicPattern.test(item.properties.dealname)) {
                        const modifiedDealname = item.properties.dealname.replace(dynamicPattern, "");
                        const formattedAmount = Number(item.properties.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        const updatedString = `${modifiedDealname} ${formattedAmount}`;
                        aionsClosed.push(updatedString);
                    } else {
                        const formattedAmount = Number(item.properties.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        const originalString = `${item.properties.dealname} ${formattedAmount}`;
                        aionsClosed.push(originalString);
                    }

                    if (alkemiaPattern.test(item.properties.dealname)) {
                        const modifiedDealname = item.properties.dealname.replace("Alkemia -", "");
                        const formattedAmount = item.properties.amount ? `R${Number(item.properties.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : 'R0';
                        const updatedString = `${modifiedDealname} ${formattedAmount}`;
                        alkemiaClosed.push(updatedString);
                    } else {
                        const formattedAmount = item.properties.amount ? `R${Number(item.properties.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : 'R0';
                        const originalString = `${item.properties.dealname} ${formattedAmount}`;
                        alkemiaClosed.push(originalString);
                    }
                    if (aexPattern.test(item.properties.dealname)) {
                        const modifiedDealname = item.properties.dealname.replace(aexPattern, "");
                        const formattedAmount = item.properties.amount ? `R${Number(item.properties.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : 'R0';
                        const updatedString = `${modifiedDealname} ${formattedAmount}`;
                        aexClosed.push(updatedString);
                    } else {
                        const formattedAmount = item.properties.amount ? `R${Number(item.properties.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : 'R0';
                        const originalString = `${item.properties.dealname} ${formattedAmount}`;
                        aexClosed.push(originalString);
                    }
                });

                labelsObj.Closed = aionsClosed.filter(name => !name.includes("AEX") && !name.includes("Alkemia"));
                labelsObj.LikelyTo = aionsLikelyTo.filter(name => !name.includes("AEX") && !name.includes("Alkemia"));
                labelsObj.AlkemiaClosed = alkemiaClosed.filter(name => !name.includes("AEX") && !name.includes("Aions") && !name.includes("ESD"));
                labelsObj.AlkemiaLikely = alkemiaLikelyTo.filter(name => !name.includes("AEX") && !name.includes("Aions") && !name.includes("ESD"));
                labelsObj.AexClosed = aexClosed.filter(name => !name.includes("Alkemia") && !name.includes("Aions") && !name.includes("ESD"));
                labelsObj.AexLikely = aexLikelyTo.filter(name => !name.includes("Alkemia") && !name.includes("Aions") && !name.includes("ESD"));
                setLabels(labelsObj);
                setDealsObj(Obj);
                forceUpdate();
            } else {
                window.alert('Unable to get deals from HubSpot')
                store.setLoading(false);
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };
    function searchDates() {
        let startDate = new Date(FromYear, FromMonth, 1);
        let endDate = new Date(ToYear, ToMonth + 1, 0);
        setStartDate(startDate);
        setEndDate(endDate);
        setReportGenerated(false);
        loadCompany(startDate, endDate);
        forceUpdate();
    };
    function dateRangeChanged(e) {
        if (e.currentTarget.id === "FromDate") {
            let [year, month] = e.target.value.split("-");
            setFromYear(Number(year));
            setFromMonth(Number(month) - 1);
        }
        if (e.currentTarget.id === "ToDate") {
            let [year, month] = e.target.value.split("-");
            setToYear(Number(year));
            setToMonth(Number(month) - 1);
        }
        forceUpdate();
    };
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const logoMapping = {
        aions: AionsIcon,
        levelfinance: LevelFinanceLogo,
        cocobrands: CocoBrandsLogo,
        tubbyskitchen: TubbysKitchenLogo,
        scooter: ScootersLogo,
        aex: AexLogo,
        alkemia: AlkemiaLogo
    };
    function generateReport() {
        setReportGenerated(true);

    }
    function addAionsIncomeExpenseComment() {
        let comment = window.prompt('Please add a comment for Aions income and expenses', '');
        setAionsIncomeExpenseComment(comment);
    }
    function clearAionsIncomeExpenseComment() {
        setAionsIncomeExpenseComment('')
    }
    function addAionsIncomeReportComment() {
        let comment = window.prompt('Please add a comment for Aions Income Report', '');
        setAionsIncomeReportComment(comment);
    }
    function clearAionsIncomeReportComment() {
        setAionsIncomeReportComment('')
    }
    function addAionsExpenseReportComment() {
        let comment = window.prompt('Please add a comment for Aions Expense Report', '');
        setAionsExpenseReportComment(comment);
    }
    function clearAionsExpenseReportComment() {
        setAionsExpenseReportComment('')
    }
    function addAionsExpensesComment() {
        let comment = window.prompt('Please add a comment for Aions Expenses', '');
        setAionsExpensesComment(comment);
    }
    function clearAionsExpensesComment() {
        setAionsExpensesComment('')
    }
    function addTelkomDrawdownComment() {
        let comment = window.prompt('Please add a comment for Telkom Drawdown', '');
        setTelkomDrawdownComment(comment);
    }
    function clearTelkomDrawdownComment() {
        setTelkomDrawdownComment('')
    }
    function addAionsBurnRateComment() {
        let comment = window.prompt('Please add a comment for Aions Burn Rate', '');
        setAionsBurnRateComment(comment);
    }
    function clearAionsBurnRateComment() {
        setAionsBurnRateComment('')
    }
    function addAionsGrowthComment() {
        let comment = window.prompt('Please add a comment for Aions Growth', '');
        setAionsGrowthComment(comment);
    }
    function clearAionsGrowthComment() {
        setAionsGrowthComment('')
    }
    function addAEXSignUpsComment() {
        let comment = window.prompt('Please add a comment for AEX Sign Ups', '');
        setAEXSignUpsComment(comment);
    }
    function clearAEXSignUpsComment() {
        setAEXSignUpsComment('')
    }
    function addAEXTransactionsComment() {
        let comment = window.prompt('Please add a comment for AEX Transactions', '');
        setAEXTransactionsComment(comment);
    }
    function clearAEXTransactionsComment() {
        setAEXTransactionsComment('')
    }
    function addAEXTransactionAmountComment() {
        let comment = window.prompt('Please add a comment for AEX Transaction Amount', '');
        setAEXTransactionAmountComment(comment);
    }
    function clearAEXTransactionAmountComment() {
        setAEXTransactionAmountComment('')
    }
    function addAEXGenderComment() {
        let comment = window.prompt('Please add a comment for AEX Gender', '');
        setAEXGenderComment(comment);
    }
    function clearAEXGenderComment() {
        setAEXGenderComment('')
    }
    function addAEXAgeComment() {
        let comment = window.prompt('Please add a comment for AEX Age', '');
        setAEXAgeComment(comment);
    }
    function clearAEXAgeComment() {
        setAEXAgeComment('')
    }
    function addAlkemiaClientsComment() {
        let comment = window.prompt('Please add a comment for Alkemia Clients', '');
        setAlkemiaClientsComment(comment);
    }
    function clearAlkemiaClientsComment() {
        setAlkemiaClientsComment('')
    }
    function addAEXConversionsComment() {
        let comment = window.prompt('Please add a comment for AEX Signups vs Purchases', '');
        setAEXConversionsComments(comment);
    }
    function clearAEXConversionsComment() {
        setAEXConversionsComments('')
    }
    function dealClicked(e) {
        let fullId = e.currentTarget.id;
        let idArr = fullId.split('|');
        let tempGraphDeals = GraphDeals;
        if (Object.keys(tempGraphDeals).includes(idArr[0]) && tempGraphDeals[idArr[0]].includes(idArr[1])) {
            let index = tempGraphDeals[idArr[0]].indexOf(idArr[1]);
            tempGraphDeals[idArr[0]].splice(index, 1);
            setGraphDeals(tempGraphDeals)
        } else {
            let tempArr = tempGraphDeals[idArr[0]] || [];
            tempArr.push(idArr[1]);
            tempGraphDeals[idArr[0]] = tempArr;
            setGraphDeals(tempGraphDeals)
        }
        forceUpdate();
    }
    function aionsDealClicked(e) {
        let fullId = e.currentTarget.id;
        let idArr = fullId.split('|');
        let tempGraphDeals = AionsDealGraph;
        if (Object.keys(tempGraphDeals).includes(idArr[0]) && tempGraphDeals[idArr[0]].includes(idArr[1])) {
            let index = tempGraphDeals[idArr[0]].indexOf(idArr[1]);
            tempGraphDeals[idArr[0]].splice(index, 1);
            setAionsDealGraph(tempGraphDeals)
        } else {
            let tempArr = tempGraphDeals[idArr[0]] || [];
            tempArr.push(idArr[1]);
            tempGraphDeals[idArr[0]] = tempArr;
            setAionsDealGraph(tempGraphDeals)
        }
        forceUpdate();
    }
    function aexDealClicked(e) {
        let fullId = e.currentTarget.id;
        let idArr = fullId.split('|');
        let tempGraphDeals = AexDealGraph;
        if (Object.keys(tempGraphDeals).includes(idArr[0]) && tempGraphDeals[idArr[0]].includes(idArr[1])) {
            let index = tempGraphDeals[idArr[0]].indexOf(idArr[1]);
            tempGraphDeals[idArr[0]].splice(index, 1);
            setAEXDealGraph(tempGraphDeals)
        } else {
            let tempArr = tempGraphDeals[idArr[0]] || [];
            tempArr.push(idArr[1]);
            tempGraphDeals[idArr[0]] = tempArr;
            setAEXDealGraph(tempGraphDeals)
        }
        forceUpdate();
    }
    if (mobile()) {
        return (
            <div>

            </div>
        );

    } else {
        return (
            <div className='container' style={{ width: '100%', height: '99%' }} >
                <div style={{ display: 'flex', width: '100%', height: '20%', flexDirection: 'column', marginLeft: '2%' }}>
                    <h1>Select Date : </h1>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '20%', }}>
                            <h3>From :</h3>
                            <Input
                                className="RangeInputs"
                                type="month"
                                value={`${FromYear}-${String(FromMonth + 1).padStart(2, '0')}`}
                                id="FromDate"
                                onChange={dateRangeChanged}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '20%', }}>
                            <h3 style={{ marginLeft: '5%' }}>To :</h3>
                            <Input
                                className="RangeInputs"
                                type="month"
                                value={`${ToYear}-${String(ToMonth + 1).padStart(2, '0')}`}
                                id="ToDate"
                                onChange={dateRangeChanged}
                            />
                        </div>
                        <Button className='searchButton' onClick={searchDates}>Search</Button>
                        {/* <Button className='searchButton' style={{ marginLeft: '5%', backgroundColor: 'rgba(143,202,114,1)' }} onClick={downloadGraphsAsPDF}>Download Report</Button> */}
                        {/* {(ReportGenerated)? 
                        <PDFDownloadLink document={<ReportExport fromDate={`${months[FromMonth]} ${FromYear}`} toDate={`${months[ToMonth]} ${ToYear}`} AionsIncomeExpenseComment={AionsIncomeExpenseComment} AionsIncomeReportComment={AionsIncomeReportComment} AionsExpenseReportComment={AionsExpenseReportComment} AionsExpensesComment={AionsExpensesComment} TelkomDrawdownComment={TelkomDrawdownComment} AionsBurnRateComment={AionsBurnRateComment} AionsGrowthComment={AionsGrowthComment} AEXSignUpsComment={AEXSignUpsComment} AEXTransactionsComment={AEXTransactionsComment} AEXTransactionAmountComment={AEXTransactionAmountComment} AEXGenderComment={AEXGenderComment} AEXAgeComment={AEXAgeComment} AlkemiaClientsComment={AlkemiaClientsComment} />} fileName="Aions_Report.pdf" >
                            {({loading}) => (loading ? <p>Loading Document...</p> : <p style={{height: '20px', color: 'black', cursor: 'pointer'}}>Download</p> )}
                        </PDFDownloadLink>
                        :<p onClick={generateReport}>Generate Report</p>} */}
                        {(ReportGenerated) ?
                            <PDFDownloadLink className='searchButton' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '10%' }} document={<ReportExport fromDate={`${months[FromMonth]} ${FromYear}`} toDate={`${months[ToMonth]} ${ToYear}`} AionsIncomeExpenseComment={AionsIncomeExpenseComment} AionsIncomeReportComment={AionsIncomeReportComment} AionsExpenseReportComment={AionsExpenseReportComment} AionsExpensesComment={AionsExpensesComment} TelkomDrawdownComment={TelkomDrawdownComment} AionsBurnRateComment={AionsBurnRateComment} AionsGrowthComment={AionsGrowthComment} AEXSignUpsComment={AEXSignUpsComment} AEXTransactionsComment={AEXTransactionsComment} AEXTransactionAmountComment={AEXTransactionAmountComment} AEXGenderComment={AEXGenderComment} AEXAgeComment={AEXAgeComment} AlkemiaClientsComment={AlkemiaClientsComment} AEXConversionsComment={AEXConversionsComments} AEXConversionsObj={AEXConversionObj} labels={Labels} />} fileName="Aions_Report.pdf" >
                                {({ loading }) => (loading ? <p style={{ height: '16px', color: 'white', cursor: 'pointer', margin: '0px', textDecoration: 'none' }}>Loading...</p> : <p style={{ height: '16px', color: 'white', cursor: 'pointer', margin: '0px', textDecoration: 'none' }}>Download</p>)}
                            </PDFDownloadLink>
                            : <Button className='searchButton' style={{ marginLeft: '5%', backgroundColor: 'rgba(143,202,114,1)' }} onClick={generateReport}>Generate Report</Button>}
                        {/* <Button className='searchButton' style={{ marginLeft: '10%' }} onClick={addDesc}>Add Opex Description</Button> */}
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', maxHeight: '80%', overflow: 'scroll', flexDirection: 'column' }} ref={graphRef} id="graphContainer">
                    <Card style={{ width: '95%', marginLeft: '1%', height: '30%', marginBottom: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
                        <h2>Aions Income and Expense Report</h2>
                        <p style={{ color: 'black' }}>{AionsIncomeExpenseComment}</p>
                        <ReportsBarGraph Aions={Aions} forecast={IncomeExpForecastData} id='aionsIncomeExpense' />
                        <FaComment style={{ position: 'absolute', top: '10px', right: '40px' }} onClick={addAionsIncomeExpenseComment} />
                        <FaCommentSlash style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={clearAionsIncomeExpenseComment} />
                    </Card>
                    <Card style={{ width: '95%', marginLeft: '1%', height: '30%', marginBottom: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
                        <h2>Aions Income Report</h2>
                        <p style={{ color: 'black' }}>{AionsIncomeReportComment}</p>
                        <AionsIncome Aions={Aions} id='aionsIncomeReport' />
                        <FaComment style={{ position: 'absolute', top: '10px', right: '40px' }} onClick={addAionsIncomeReportComment} />
                        <FaCommentSlash style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={clearAionsIncomeReportComment} />
                    </Card>
                    <Card style={{ width: '95%', marginLeft: '1%', height: '30%', marginBottom: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
                        <h2>Aions Expense Report</h2>
                        <p style={{ color: 'black' }}>{AionsExpenseReportComment}</p>
                        <AlkemiaStackedGraph Aions={Aions} id='aionsExpenseReport' />
                        <FaComment style={{ position: 'absolute', top: '10px', right: '40px' }} onClick={addAionsExpenseReportComment} />
                        <FaCommentSlash style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={clearAionsExpenseReportComment} />
                    </Card>
                    <Card style={{ width: '95%', marginLeft: '1%', height: '30%', marginBottom: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column', }}>
                        <h2>Aions Expenses Broken-down</h2>
                        <p style={{ color: 'black' }} >{AionsExpensesComment}</p>
                        <OpexPie Aions={PieData} id='aionsExpenses' />
                        <div style={{ width: '60%', overflow: 'scroll' }}>
                            <p>{ExpenseDescription || ''}</p>
                        </div>
                        <FaComment style={{ position: 'absolute', top: '10px', right: '40px' }} onClick={addAionsExpensesComment} />
                        <FaCommentSlash style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={clearAionsExpensesComment} />
                    </Card>
                    <Card style={{ width: '95%', marginLeft: '1%', height: '30%', marginBottom: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
                        <h2>Telkom Drawdown Report</h2>
                        <p style={{ color: 'black' }}>{TelkomDrawdownComment}</p>
                        <TelkomDrawDownGraph Aions={Aions} id='telkomDrawdown' />
                        <FaComment style={{ position: 'absolute', top: '10px', right: '40px' }} onClick={addTelkomDrawdownComment} />
                        <FaCommentSlash style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={clearTelkomDrawdownComment} />
                    </Card>
                    <Card style={{ width: '95%', marginLeft: '1%', height: '30%', marginBottom: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
                        <h2>Aions Burn Rate Report</h2>
                        <p style={{ color: 'black' }} >{AionsBurnRateComment}</p>
                        <BurnRateGraph Aions={BurnRateData} id='aionsBurnRate' />
                        <FaComment style={{ position: 'absolute', top: '10px', right: '40px' }} onClick={addAionsBurnRateComment} />
                        <FaCommentSlash style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={clearAionsBurnRateComment} />
                    </Card>
                    <Card style={{ width: '95%', marginLeft: '1%', height: '30%', marginBottom: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
                        <h2>Aions Growth</h2>
                        <p style={{ color: 'black' }}>{AionsGrowthComment}</p>
                        <BurnrateGauge Growthdata={GrowthRateData} id='aionsGrowth' />
                        <FaComment style={{ position: 'absolute', top: '10px', right: '40px' }} onClick={addAionsGrowthComment} />
                        <FaCommentSlash style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={clearAionsGrowthComment} />
                    </Card>
                    <Card style={{ width: '95%', marginLeft: '1%', height: '30%', marginBottom: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
                        <h2>AEX Sign Ups</h2>
                        <p style={{ color: 'black' }}>{AEXSignUpsComment}</p>
                        <AEXSignUps Aex={AEXUsers} id='aexSignUps' />
                        <FaComment style={{ position: 'absolute', top: '10px', right: '40px' }} onClick={addAEXSignUpsComment} />
                        <FaCommentSlash style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={clearAEXSignUpsComment} />
                    </Card>
                    <Card style={{ width: '95%', marginLeft: '1%', height: '30%', marginBottom: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
                        <h2>AEX Transactions</h2>
                        <p style={{ color: 'black' }}>{AEXTransactionsComment}</p>
                        <AEXTransactions Aex={AEXtransactions} id='aexTransactions' />
                        <FaComment style={{ position: 'absolute', top: '10px', right: '40px' }} onClick={addAEXTransactionsComment} />
                        <FaCommentSlash style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={clearAEXTransactionsComment} />
                    </Card>
                    <Card style={{ width: '95%', marginLeft: '1%', height: '30%', marginBottom: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
                        <h2>AEX Transactions Amount</h2>
                        <p style={{ color: 'black' }}>{AEXTransactionAmountComment}</p>
                        <AEXTransactionAmount Aex={AEXTransactionamount} id='aexTransactionAmount' />
                        <FaComment style={{ position: 'absolute', top: '10px', right: '40px' }} onClick={addAEXTransactionAmountComment} />
                        <FaCommentSlash style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={clearAEXTransactionAmountComment} />
                    </Card>
                    <Card style={{ width: '95%', marginLeft: '1%', height: '30%', marginBottom: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
                        <h2>AEX Gender Signups Report</h2>
                        <p style={{ color: 'black' }}>{AEXTransactionAmountComment}</p>
                        <AEXGenderSignups Aex={AEXTransactionamount} Arr={Arr} id='aexGender' />
                        <FaComment style={{ position: 'absolute', top: '10px', right: '40px' }} onClick={addAEXGenderComment} />
                        <FaCommentSlash style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={clearAEXGenderComment} />
                    </Card>
                    <Card style={{ width: '95%', marginLeft: '1%', height: '30%', marginBottom: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
                        <h2>AEX Age Group</h2>
                        <p style={{ color: 'black' }}>{AEXAgeComment}</p>
                        <AEXAgeGroups Aex={AEXTransactionamount} Arr={Arr} id='aexAge' />
                        <FaComment style={{ position: 'absolute', top: '10px', right: '40px' }} onClick={addAEXGenderComment} />
                        <FaCommentSlash style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={clearAEXGenderComment} />
                    </Card>

                    <Card style={{ width: '95%', marginLeft: '1%', height: '30%', marginBottom: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
                        <h2>AEX Signups vs Purchases</h2>
                        <p style={{ color: 'black' }}>{AEXTransactionAmountComment}</p>
                        <AEXConversions Obj={AEXConversionObj} id='aexConversions' />
                        <FaComment style={{ position: 'absolute', top: '10px', right: '40px' }} onClick={addAEXConversionsComment} />
                        <FaCommentSlash style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={clearAEXConversionsComment} />
                    </Card>
                    <Card style={{ width: '95%', marginLeft: '1%', height: '30%', marginBottom: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
                        <h2>Alkemia Clients Report</h2>
                        <p style={{ color: 'black' }}>{AlkemiaClientsComment}</p>
                        <PieChart Aions={PieData} id='alkemiaClients' />
                        <FaComment style={{ position: 'absolute', top: '10px', right: '40px' }} onClick={addAlkemiaClientsComment} />
                        <FaCommentSlash style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={clearAlkemiaClientsComment} />
                    </Card>
                    {/* 
                    ALKEMIA
                    ALKEMIA
                    ALKEMIA
                    ALKEMIA
                    */}
                    <Card style={{ width: '95%', marginLeft: '1%', height: '30%', marginBottom: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
                        <h2>Alkemia Sales Funnel</h2>
                        <p style={{ color: 'black' }}></p>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '50%' }}>
                                <SalesFunnelTable data={DealsObj} graphDeals={GraphDeals} dealClicked={dealClicked} id='alkemiaSalesFunnel' />
                            </div>
                            <div style={{ width: '50%', minHeight: '80%' }}>
                                <AlkemiaFunnelBar dealsObj={DealsObj} graphDeals={GraphDeals} labels={Labels} id='alkemiaSalesFunnelBar' />
                            </div>
                        </div>
                        <FaComment style={{ position: 'absolute', top: '10px', right: '40px' }} />
                        <FaCommentSlash style={{ position: 'absolute', top: '10px', right: '10px' }} />
                    </Card>

                    <Card style={{ width: '95%', marginLeft: '1%', height: '30%', marginBottom: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
                        <h2>Aions ESD Funnel</h2>
                        <p style={{ color: 'black' }}></p>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '50%' }}>
                                <AionsESDFunnel data={DealsObj} graphDeals={AionsDealGraph} dealClicked={aionsDealClicked} id='aionsSalesFunnel' />
                            </div>
                            <div style={{ width: '50%', minHeight: '80%' }}>
                                <AionsESDFunnelBar dealsObj={DealsObj} graphDeals={AionsDealGraph} labels={Labels} id='aionsSalesFunnelBar' value='' />
                            </div>
                        </div>
                        <FaComment style={{ position: 'absolute', top: '10px', right: '40px' }} />
                        <FaCommentSlash style={{ position: 'absolute', top: '10px', right: '10px' }} />
                    </Card>

                    <Card style={{ width: '95%', marginLeft: '1%', height: '30%', marginBottom: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
                        <h2>AEX Sales Funnel</h2>
                        <p style={{ color: 'black' }}></p>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '50%' }}>
                                <AEXFunnel data={DealsObj} graphDeals={AexDealGraph} dealClicked={aexDealClicked} id='aexSalesFunnel' />
                            </div>
                            <div style={{ width: '50%', minHeight: '80%' }}>
                                <AEXFunnelBar dealsObj={DealsObj} graphDeals={AexDealGraph} labels={Labels} id='aexSalesFunnelBar' />
                            </div>
                        </div>
                        <FaComment style={{ position: 'absolute', top: '10px', right: '40px' }} />
                        <FaCommentSlash style={{ position: 'absolute', top: '10px', right: '10px' }} />
                    </Card>

                    {/* {Companies.map((company, index) => {
                        const companyName = company.CompanyName.toLowerCase().replace(/\s/g, '');
                        const companyLogo = logoMapping[companyName] || null;
                        return (
                            <div key={index}>

                                {companyLogo && (
                                    <Card style={{ maxWidth: '80%', marginLeft: '5%', maxHeight: '30%', marginBottom: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
                                        <img src={companyLogo} alt={companyName} style={{ width: "60%", height: '50%' }} />
                                    </Card>
                                )}
                                <Card style={{ width: '95%', marginLeft: '1%', height: '30%', marginBottom: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
                                    <h2>{company.CompanyName} Income and Expense Report</h2>
                                    <CompaniesIncomeExpenses comp={company} />
                                </Card>
                                <Card style={{ width: '95%', marginLeft: '1%', height: '30%', marginBottom: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
                                    <h2>{company.CompanyName} Clients Report</h2>
                                    <CompaniesIncomePie comp={company} />
                                </Card>
                                <Card style={{ width: '95%', marginLeft: '1%', height: '30%', marginBottom: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
                                    <h2>{company.CompanyName} Income Report</h2>
                                    <CompaniesIncome comp={company} />
                                </Card>
                                <Card style={{ width: '95%', marginLeft: '1%', height: '30%', marginBottom: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
                                    <h2>{company.CompanyName} Expense Report</h2>
                                    <CompaniesExpenses comp={company} />
                                </Card>
                                <Card style={{ width: '95%', marginLeft: '1%', height: '30%', marginBottom: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
                                    <h2>{company.CompanyName} Growth Report</h2>
                                    <CompaniesGrowth comp={company} />
                                </Card>
                                <Card style={{ width: '95%', marginLeft: '1%', height: '30%', marginBottom: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
                                    <h2>{company.CompanyName} Expenses</h2>
                                    <CompaniesExpensePie comp={company} />
                                </Card>
                            </div>
                        );
                    })} */}

                </div>
            </div>
        )
    }
})

export default Reports;