import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const CashflowGraph = ({ id, Actual, currentAct, currentFore, previousAct, previousFore, width, height, date, prevDate, type }) => {
  const svgRef = useRef();
  useEffect(() => {
    const svg = d3.select(svgRef.current);
    let prevColor = false;
    let currColor = false;
    const margin = { top: 20, right: 30, bottom: 40, left: 100 };
    svg.selectAll('*').remove();
    svg.attr('width', width).attr('height', height);

    let currMonthData = Actual.CurrentMonth;
    let prevMonthData = Actual.PreviousMonth;
    let prevDeficit = previousAct - previousFore;
    let currDeficit = currentAct - currentFore;
    let currProfitLoss = '';
    let profitLoss = '';

    if (type === 'Expenses') {
      if (prevDeficit > 0) {
        prevColor = false
      }
      if (prevDeficit < 0) {
        prevColor = true
      }
      if (currDeficit > 0) {
        currColor = false
      }
      if (currDeficit < 0) {
        currColor = true
      }
    } else if (type === 'Income') {
      if (prevDeficit > 0) {
        prevColor = true
      }
      if (prevDeficit < 0) {
        prevColor = false
      }
      if (currDeficit > 0) {
        currColor = true
      }
      if (currDeficit < 0) {
        currColor = false
      }
    }

    if (prevDeficit < 0 || prevDeficit === NaN || prevDeficit == undefined || !prevDeficit) {
      profitLoss = 'ShortFall';
      prevDeficit = Math.abs(prevDeficit);
    } else if (prevDeficit > 0) {
      profitLoss = 'Surplus';
      prevDeficit = Math.abs(prevDeficit);
    }

    if (currDeficit < 0 || currDeficit === NaN || currDeficit == undefined || !currDeficit) {
      currProfitLoss = 'Shortfall';
      currDeficit = Math.abs(currDeficit);
    } else if (currDeficit > 0) {
      currProfitLoss = 'Surplus';
      currDeficit = Math.abs(currDeficit);
    }

    let data = [];
    if (prevMonthData.Type === 'Monthly' && currMonthData.Type === 'Monthly') {
      data = [
        {
          Category: 'Current Month',
          Actual: currentAct,
          Forecast: currentFore
        },
        {
          Category: `Current ${currProfitLoss}`,
          Deficit: currDeficit
        },
        {
          Category: 'Previous Month',
          Actual: previousAct,
          Forecast: previousFore
        },
        {
          Category: `Previous ${profitLoss}`,
          Difference: prevDeficit
        }]
    } else if (currMonthData.Type === 'Yearly' && prevMonthData.Type === 'Yearly') {
      data = [
        {
          Category: 'Current Year',
          Actual: currentAct,
          Forecast: currentFore
        },
        {
          Category: `Current ${currProfitLoss}`,
          Deficit: currDeficit
        },
        {
          Category: 'Previous Year',
          Actual: previousAct,
          Forecast: previousFore
        },
        {
          Category: `Previous ${profitLoss}`,
          Difference: prevDeficit
        }
      ]
    } else if (currMonthData.Type === 'Entire' && prevMonthData.Type === 'Entire') {
      data = [
        {
          Category: 'Current',
          Actual: currentAct,
          Forecast: currentFore
        },
        {
          Category: 'Deficit',
          Deficit: currDeficit
        },
        {
          Category: '',
          Actual: 0,
          Forecast: 0
        },
        {
          Category: '',
          Difference: 0
        },

      ];
    }
    const maxDataValue = d3.max(data, d => d3.max([d.Forecast, d.Actual, d.Deficit, d.Difference]));
    let final = typeof maxDataValue !== 'undefined' ? maxDataValue : 0;
    let finalValue = 0
    if (final > 0) {
      finalValue = final * 1.05
    }
    const x = d3.scaleLinear()
      .domain(finalValue > 0 ? [0, finalValue] : [0, 1])
      .range([margin.left, width - margin.right]);

    const tickValues = finalValue > 0 ? [0, finalValue / 3, (finalValue * 2) / 3, finalValue] : [];

    const xAxis = d3.axisBottom(x)
      .tickValues(tickValues)
      .tickFormat(d3.format(".2s"))
      .tickSizeOuter(0);
    function rightRoundedRect(x, y, width, height, radius) {
      return "M" + x + "," + y
        + "h" + (width - radius)
        + "a" + radius + "," + radius + " 0 0 1 " + radius + "," + radius
        + "v" + (height - 2 * radius)
        + "a" + radius + "," + radius + " 0 0 1 " + -radius + "," + radius
        + "h" + (radius - width)
        + "z";
    }
    // Append x-axis
    svg.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .call(xAxis)
      .selectAll('text')
      .style('font-size', '15px')
      .style('fill', '#213038')
      .style('font-weight', '300');

    if (finalValue > 0) {
      svg.selectAll('.vertical-line')
        .data(tickValues)
        .enter().append('line')
        .attr('class', 'vertical-line')
        .attr('x1', d => x(d))
        .attr('y1', margin.top)
        .attr('x2', d => x(d))
        .attr('y2', height - margin.bottom)
        .style('stroke', '#ccc')
        .style('stroke-width', '1px');
    }
    svg.selectAll('.x-axis path').remove();
    svg.selectAll('.x-axis-line').remove();
    // Y scale
    const y = d3.scaleBand()
      .domain(data.map(d => d.Category))
      .range([margin.top, height - margin.bottom])
      .padding(0.1);

    const tooltip = d3.select('body').append('div')
      .style('position', 'absolute')
      .style('background', '#ffffff')
      .style('box-shadow', '0 0 10px rgba(0, 0, 0, 1)')
      .style('padding', '5px')
      .style('display', 'none')
      .style('pointer-events', 'none')
      .style('color', '#213038')
      .style('font-weight', '300')
      .style('font-size', '15px')
      .style('text-align', 'center')
      .style('align-items', 'center')
      .style('justify-content', 'center')
      .style('border-radius', '85px')
      .style('min-width', '100px')
      .style('height', '65px')
      .style('z-index', '5')
      .style('flex-direction', 'column');

    const showTooltip = (event, value, obj, element) => {
      let itemDate = ''
      let category = obj.Category || [];
      if (category.includes('Previous')) {
        itemDate = prevDate
      } else if (category.includes('Current')) {
        itemDate = date
      }
      tooltip
        .style('left', (event.pageX + 10) + 'px')
        .style('top', (event.pageY + 10) + 'px')
        .style('display', 'flex')
        .html(`
          <p style="line-height: 0px; background-color: green; font-size: 15px;" >${category}</p>
          <p style="line-height: 0px; font-size: 20px; font-weight: 700; margin: 6px;">R ${value.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
          <p style="line-height: 0px; font-size: 15px;">${itemDate}</p>
        `);

      // Blur other bars, when tooltip open
      d3.selectAll('.bar-actual, .bar-forecast, .bar-deficit, .bar-difference').transition().duration(600).style('opacity', 0.3);
      d3.select(element).transition().duration(600).style('opacity', 1);
    };


    //  to hide the tooltip
    const hideTooltip = () => {
      tooltip.style('display', 'none');
      d3.selectAll('.bar-actual, .bar-forecast, .bar-deficit, .bar-difference').style('opacity', 1);
    };

    // Y axis line
    svg.append('line')
      .attr('x1', margin.left)
      .attr('y1', margin.top)
      .attr('x2', margin.left)
      .attr('y2', height - margin.bottom)
      .style('stroke', '#213038')
      .style('stroke-width', '2px');

    // Y axis data
    svg.append('g')
      .attr('transform', `translate(${margin.left},0)`)
      .selectAll('text')
      .data(data.map((d, i) => (i === 1 || i === 3) ? '' : d.Category))
      .enter()
      .append('text')
      .attr('x', -10)
      .attr('y', d => y(d) + y.bandwidth() / 1.8)
      .attr('dy', '0.35em')
      .style('text-anchor', 'end')
      .style('font-weight', '300')
      .style('font-size', '17px')
      .each(function (d) {
        const words = d ? d.split(' ') : [''];
        d3.select(this)
          .selectAll('tspan')
          .data(words)
          .enter()
          .append('tspan')
          .attr('x', -10)
          .attr('dy', (d, i) => i * 1.2 + "em")
          .text(d => d);
      });


    // Bars for Forecast and Actual in Previous and Current Month
    svg.selectAll('.bar-actual')
      .data(data.filter(d => d.Actual !== undefined && d.Actual > 0))
      .enter()
      .append('path')
      .attr('class', 'bar-actual')
      .attr('fill', '#D4912D')
      .attr('d', d => {
        const barHeight = 15;
        const yStart = y(d.Category) + (y.bandwidth() - barHeight) / 2 - 2;
        const xStart = x(0) + 1;
        const barWidth = xStart;
        return rightRoundedRect(xStart, yStart, x(d.Actual) - xStart, barHeight, 5);
      })
      .on('mouseover', function (event, d) { showTooltip(event, d.Actual, d, this); })
      .on('mouseout', hideTooltip)
      .transition()
      .duration(1000)
      .attrTween('d', function (d) {
        const barHeight = 15;
        const yStart = y(d.Category) + (y.bandwidth() - barHeight) / 2 - 2;
        const xEnd = x(d.Actual) + 1;
        const interpolate = d3.interpolateNumber(x(0) + 1, xEnd);
        return function (t) {
          const xCurrent = interpolate(t);
          return rightRoundedRect(x(0) + 1, yStart, xCurrent - x(0), barHeight, 5);
        };
      });

    svg.selectAll('.bar-forecast')
      .data(data.filter(d => d.Forecast !== undefined && d.Forecast > 0))
      .enter()
      .append('path')
      .attr('class', 'bar-forecast')
      .attr('fill', '#213038')
      .attr('d', d => {
        const barHeight = 15;
        const yStart = y(d.Category) + (y.bandwidth() - barHeight) / 2 + 4 - 3;
        const xStart = x(0) + 1;
        const barWidth = xStart;
        return rightRoundedRect(xStart, yStart, x(d.Forecast) - xStart, barHeight, 5);
      })
      .on('mouseover', function (event, d) { showTooltip(event, d.Forecast, d, this); })
      .on('mouseout', hideTooltip)
      .transition()
      .duration(1000)
      .attrTween('d', function (d) {
        const barHeight = 15;
        const yStart = y(d.Category) + (y.bandwidth() - barHeight) / 1 + 4 - 3;
        const xEnd = x(d.Forecast) + 1;
        const interpolate = d3.interpolateNumber(x(0) + 1, xEnd);
        return function (t) {
          const xCurrent = interpolate(t);
          return rightRoundedRect(x(0) + 1, yStart, xCurrent - x(0), barHeight, 5);
        };
      });

    // Bar for Deficit
    svg.selectAll('.bar-deficit')
      .data(data.filter(d => d.Deficit !== undefined && d.Deficit > 0))
      .enter()
      .append('path')
      .attr('class', 'bar-deficit')
      .attr('fill', d => currColor ? '#7A8F9D' : '#A62626')
      .attr('d', d => {
        const barHeight = 15;
        const offset = 12;
        const yStart = y(d.Category) + (y.bandwidth() - barHeight) / 2 - 2 + offset;
        const xStart = x(0) + 1;
        const barWidth = xStart;
        return rightRoundedRect(xStart, yStart, x(d.Deficit) - xStart, barHeight, 5);
      })
      .on('mouseover', function (event, d) { showTooltip(event, d.Deficit, d, this); })
      .on('mouseout', hideTooltip)
      .transition()
      .duration(900)
      .attrTween('d', function (d) {
        const barHeight = 15;
        const offset = 12;
        const yStart = y(d.Category) + (y.bandwidth() - barHeight) / 2 - 2 + offset;
        const xEnd = x(d.Deficit) + 1;
        const interpolate = d3.interpolateNumber(x(0) + 1, xEnd);
        return function (t) {
          const xCurrent = interpolate(t);
          return rightRoundedRect(x(0) + 1, yStart, xCurrent - x(0), barHeight, 5);
        };
      });

    // Bar for Difference
    svg.selectAll('.bar-difference')
      .data(data.filter(d => d.Difference !== undefined && d.Difference > 0))
      .enter()
      .append('path')
      .attr('class', 'bar-difference')
      .attr('fill', d => prevColor ? '#7A8F9D' : '#A62626')
      .attr('d', d => {
        const barHeight = 15;
        const offset = 12;
        const yStart = y(d.Category) + (y.bandwidth() - barHeight) / 2 - 2 + offset;
        const xStart = x(0) + 1;
        return rightRoundedRect(xStart, yStart, x(d.Difference) - xStart, barHeight, 5);
      })
      .on('mouseover', function (event, d) { showTooltip(event, d.Difference, d, this); })
      .on('mouseout', hideTooltip)
      .transition()
      .duration(900)
      .attrTween('d', function (d) {
        const barHeight = 15;
        const offset = 12;
        const yStart = y(d.Category) + (y.bandwidth() - barHeight) / 2 - 3 + offset;
        const xEnd = x(d.Difference) + 1;
        const interpolate = d3.interpolateNumber(x(0) + 1, xEnd);
        return function (t) {
          const xCurrent = interpolate(t);
          return rightRoundedRect(x(0) + 1, yStart, xCurrent - x(0), barHeight, 5);
        };
      });

  }, [id, Actual, currentAct, currentFore, previousAct, previousFore, width, height, date]);

  return (
    <svg ref={svgRef} id={id}></svg>
  );
};

export default CashflowGraph;
