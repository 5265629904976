import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import './style.css';
import AionsCoin from '../../../assets/images/AionsCoin.png';
import { useNavigate } from 'react-router-dom';
import dashLogo from '../../../assets/images/dashLogo.png';
import SocialMedia from '../../../assets/images/SocialMedia.svg';
import Brochure from '../../../assets/images/Brochure.svg';
import Flyer from '../../../assets/images/Flyer.svg';
import Presentation from '../../../assets/images/Presentation.svg';
import Poster from '../../../assets/images/Poster.svg';
import CloseCard from '../../../assets/images/circle.png';
import Todo from '../../../assets/images/todo.png';
import InProgress from '../../../assets/images/In_Progress@2x.png';
import Completed from '../../../assets/images/checked.png';
import Plus from '../../../assets/images/plus.png';
import { Input, View, Button, Card, DoughnutChart, } from '../../../components'
var mobile = require('is-mobile');

const AlkemiaBriefing = observer(() => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Jobs, setJobs] = useState([]);
    const [TaskLabel, setTaskLabel] = useState('requested');
    const [Company, setCompany] = useState({});
    const [SelectedJob, setSelectedJob] = useState({});
    const [ShowData, setShowData] = useState(false);
    const [Task, setTask] = useState(false);
    const [DeclinedTAsks, setDeclinedTasks] = useState(false);
    const [Credits, setCredits] = useState({});
    const [TaskStatus, setTaskStatus] = useState({
        Pending: 0,
        Completed: 0,
        Declined: 0,
        InProgress: 0,
        Requested: 0,
    });
    const [Pending, setPending] = useState([]);
    const [InProgress, setInProgress] = useState([]);
    const [Complete, setComplete] = useState([]);
    const [Requested, setRequested] = useState([]);
    const [Declined, setDeclined] = useState([]);

    const [Background, setBackground] = useState({});
    const [JobRequest, setJobRequest] = useState({
        ActualDueDate: null,
        Title: "",
        Description: "",
        FeedBack: '',
        Department: "",
        Archived: false,
        DesignSpec: {
            designElements: "",
            collaterals: "",
            category: "",
            listDeliverables: "",
            DocLink: '',
            Platform: '',
            Sizes: '',
            CopyIfRequired: '',
            Attachments: ''
        },
        DevSpec: {
            Platform: "",
            Type: "",
            Outcomes: "",
            Figma_Available: ""
        },
        ReqDate: new Date(),
        DueDate: ''
    });

    useEffect(() => {
        loadCompanies();
    }, []);
    function loadCompanies() {
        let usedCreds = {
            Sales: 0,
            Dev: 0,
            Design: 0,
        }
        store.setLoading(true);
        store.getJobByEmail(store.user.Company).then((ret) => {
            if (typeof ret === "string" || !ret) {
                setJobs({});
                store.setLoading(false);
            }
            let StatusLength = TaskStatus;
            let tasks = ret;
            let pending = [];
            let completed = [];
            let inprogress = [];
            let declined = [];
            let requested = [];
            for (let b = 0; b < tasks.length; b++) {
                let task = tasks[b];
                if (task.Status === "inProgress") {
                    setInProgress((prevInProgress) => [...prevInProgress, task]);
                }
                if (task.Status === 'Completed' || task.Status === 'Complete') {
                    setComplete((prevComplete) => [...prevComplete, task]);
                }
                if (task.Status === 'pending') {
                    setPending((prevPending) => [...prevPending, task]);
                }
                if (task.Status === 'Declined peacefully.') {
                    setDeclined((prevDeclined) => [...prevDeclined, task]);
                }
                if (task.Status === 'requested') {
                    setRequested((prevRequested) => [...prevRequested, task]);
                }
            }
            StatusLength.Completed = completed.length;
            StatusLength.Pending = pending.length;
            StatusLength.Requested = requested.length;
            StatusLength.Declined = declined.length;
            StatusLength.InProgress = inprogress.length;

            setTaskStatus(StatusLength);
            forceUpdate();
            let pendingTasks = [];
            for (let i = 0; i < ret.length; i++) {
                let task = ret[i];
                if (task.Status === "pending") {
                    pendingTasks.push(task);
                }
            }
            for (let k = 0; k < pendingTasks.length; k++) {
                let task = pendingTasks[k];
                let updatedAtDate = new Date(task.updatedAt);
                let timeDifference = new Date() - updatedAtDate;
                if (timeDifference >= 24 * 60 * 60 * 1000) {
                    store.setLoading(true)
                    let job = task;
                    job.Status = 'inProgress';
                    store.updateJobById(job).then((ret) => {
                        if (ret === 'error') {
                            window.alert('There was an error updating this job.');
                            store.setLoading(false);
                        } else {
                            setSelectedJob({});
                            store.setLoading(false)
                        }
                    });
                    forceUpdate();
                } else {
                    return
                }
            }

            let date = new Date();
            let designTask = ret.filter((item) => item.Department === 'Design' && new Date(item.ReqDate).getMonth() === date.getMonth());
            let devTasks = ret.filter((item) => item.Department === 'Dev' && new Date(item.ReqDate).getMonth() === date.getMonth());
            let salesTasks = ret.filter((item) => item.Department === 'Sales' && new Date(item.ReqDate).getMonth() === date.getMonth());

            let totDevHrs = devTasks.reduce((total, task) => total + (task.ActualHours || 0), 0);
            let totDesignHrs = designTask.reduce((total, task) => total + (task.ActualHours || 0), 0);
            let totSalesHrs = salesTasks.reduce((total, task) => total + (task.ActualHours || 0), 0);
            usedCreds.Design = totDesignHrs;
            usedCreds.Dev = totDevHrs;
            usedCreds.Sales = totSalesHrs;
            store.setLoading(false);
            setJobs(ret);
        });
        store.getCompaniesByPerms(store.user.Email).then((ret) => {
            if (typeof ret === "string" || !ret) {
                setCompany({});
                store.setLoading(false);
                return;
            }
            if (ret.length === 0) {
                setCompany({});
                store.setLoading(false);
                return;
            }
            let comp = ret.filter(item => item.Name === store.user.Company);
            setCompany(comp[0]);

            let date = new Date();
            let currentMonth = date.getMonth();
            let currentYear = date.getFullYear();
            let creds = comp[0].Credits;
            let currentMonthCredits = creds.find((credit) => credit.Month === currentMonth && credit.Year === currentYear);
            let Credits = {
                Sales: currentMonthCredits && currentMonthCredits.Departments && currentMonthCredits.Departments.Sales - usedCreds.Sales,
                Dev: currentMonthCredits && currentMonthCredits.Departments && currentMonthCredits.Departments.Dev - usedCreds.Dev,
                Design: currentMonthCredits && currentMonthCredits.Departments && currentMonthCredits.Departments.Design - usedCreds.Design,
            }

            setCredits(Credits)
        });
    }
    function changeStatus(e) {
        setTaskLabel(e.currentTarget.id);
        forceUpdate();
    }
    function closeAddTaskData() {
        setShowData(false);
    }
    function RequestJobClicked() {
        let job = JobRequest;
        if (job.DueDate === null || job.DueDate === undefined || job.DueDate === "") {
            window.alert("Please select a Due Date.");
            return;
        }
        if (job.Title === "") {
            window.alert("Please Enter Job Title!");
            return;
        }
        if (job.Department === "") {
            window.alert("Please Enter Job Department!");
            return;
        }
        if (job.Description === "") {
            window.alert("PLease Enter Job Description!");
            return;
        }
        if (job.Department === "Dev") {
            if (
                job.DevSpec.Platform === "" ||
                job.DevSpec.Type === "" ||
                job.DevSpec.Outcomes === "" ||
                job.DevSpec.Figma_Available === ""
            ) {
                window.alert("Please fill in all Dev-related fields.");
                return;
            }
        }
        if (job.Department === 'Design') {
            if (job.DesignSpec.Attachments === "" ||
                job.DesignSpec.Sizes === "" ||
                job.DesignSpec.CopyIfRequired === '' ||
                job.DesignSpec.collaterals === "" ||
                job.DesignSpec.category === '' ||
                job.DesignSpec.Platform === ""
            ) {
                window.alert("Please fill in all Design-related fields.");
            }
        }
        const selectedDepartmentCredits = Credits && Credits[job.Department];
        if (selectedDepartmentCredits === undefined) {
        } else if (selectedDepartmentCredits !== null && selectedDepartmentCredits <= 0) {
            window.alert(`Credits for ${job.Department} exhausted. Extra credits used for job requests will be deducted from next month's refill. Contact admin for more info.`);
        }
        store.setLoading(true);
        let data = {
            ClientEmail: store.user.Email,
            Title: job.Title,
            Department: job.Department,
            Description: job.Description,
            Client: store.user.Company,
            DueDate: new Date(job.DueDate),
            ReqDate: job.ReqDate,
            ActualDueDate: job.ActualDueDate,
            Status: "requested",
            DesignSpec: {
                designElements: job.DesignSpec?.designElements || "",
                collateral: job.DesignSpec?.collaterals || '',
                category: job.DesignSpec?.category || '',
                listDeliverables: job.DesignSpec?.listDeliverables || "",
                Doclink: job.DesignSpec?.DocLink || "",
                Sizes: job.DesignSpec?.Sizes || "",
                Attachments: job.DesignSpec?.Attachments || "",
                CopyIfRequired: job.DesignSpec?.CopyIfRequired || "",
                Platform: job.DesignSpec?.Platform || "",
            },
            DevSpec: {
                Figma_Available: job.DevSpec?.Figma_Available || "",
                Outcomes: job.DevSpec?.Outcomes || "",
                Platform: job.DevSpec?.Platform || "",
                Type: job.DevSpec?.Type || ""
            },
        };
        store.createJob(data).then((ret) => {
            if (ret === true) {
                window.alert("Job request created successfully");
                setTask(false);
                loadCompanies();
                setJobRequest({
                    Title: "",
                    Description: "",
                    Department: "",
                    FeedBack: '',
                    DesignSpec: {
                        designElements: "",
                        collateral: "",
                        category: "",
                        listDeliverables: "",
                        DocLink: "",
                    },
                    DevSpec: {
                        Figma_Available: "",
                        Outcomes: "",
                        Platform: "",
                        Type: ""
                    },
                    ActualDueDate: null,
                    ReqDate: new Date(),
                    DueDate: ''
                });
                forceUpdate();
            } else {
                window.alert("Unable to Request job");
                forceUpdate();
            }
            store.setLoading(false);
            forceUpdate();
        });
        forceUpdate();
    }
    function viewJobDetails(e) {
        let hrs = new Date(Jobs[Number(e.currentTarget.id)].updatedAt).getHours() + 24;
        setSelectedJob(Jobs[Number(e.currentTarget.id)]);
        setShowData(true);
    }
    function formatDate(dateString) {
        const options = { day: "numeric", month: "short", year: "numeric" };
        const dateObj = new Date(dateString);
        return dateObj.toLocaleDateString("en-US", options);
    }
    function jobRequestChange(e) {
        let job = JobRequest;
        job.ActualDueDate = null;
        job.FeedBack = "";
        if (e.currentTarget.id === "title") {
            job.Title = e.target.value;
        }
        if (e.currentTarget.id === "Description") {
            job.Description = e.target.value;
        }
        if (e.currentTarget.id === "Department") {
            job.Department = e.target.value;
        }
        if (e.currentTarget.id === "designElements") {
            job.DesignSpec.designElements = e.target.value;
        }
        if (e.currentTarget.id === "Collaterals") {
            job.DesignSpec.collaterals = e.target.value;
        }
        if (e.currentTarget.id === "category") {
            JobRequest.DesignSpec.category = e.currentTarget.value;
        }
        if (e.currentTarget.id === "listDeliverables") {
            job.DesignSpec.listDeliverables = e.target.value;
        }
        if (e.currentTarget.id === "docLinks") {
            job.DesignSpec.DocLink = e.target.value;
        }
        if (e.currentTarget.id === "Size") {
            job.DesignSpec.Sizes = e.target.value;
        }
        if (e.currentTarget.id === "CopyIfRequired") {
            job.DesignSpec.CopyIfRequired = e.target.value;
        }
        if (e.currentTarget.id === "Attachments") {
            job.DesignSpec.Attachments = e.target.value;
        }
        if (e.currentTarget.id === "Platform") {
            job.DesignSpec.Platform = e.target.value;
        }
        if (e.currentTarget.id === "Platform") {
            job.DevSpec.Platform = e.target.value;
        }
        if (e.currentTarget.id === "Type") {
            job.DevSpec.Type = e.target.value;
        }
        if (e.currentTarget.id === "Outcomes") {
            job.DevSpec.Outcomes = e.target.value;
        }
        if (e.currentTarget.id === "Figma_Available") {
            job.DevSpec.Figma_Available = e.target.value;
        }
        // if (e.currentTarget.id === "Figma_Available" || e.currentTarget.id === "Figma_NotAvailable") {
        //     job.DevSpec.Figma_Available = e.target.value;
        // }
        if (e.currentTarget.id === "dueDate") {
            job.DueDate = e.target.value;
        }
        setJobRequest(job);
        forceUpdate();
    }
    function addTaskClicked() {
        setTask(true);
    }
    function closeTaskClicked() {
        setTask(false);
        setJobRequest({
            Title: "",
            Description: "",
            Department: "",
            FeedBack: '',
            DesignSpec: {
                designElements: "",
                collateral: "",
                category: "",
                listDeliverables: "",
                DocLink: "",
            },
            DevSpec: {
                Figma_Available: "",
                Outcomes: "",
                Platform: "",
                Type: ""
            },
            ActualDueDate: null, ReqDate: new Date(),
            DueDate: new Date()
        });
    }
    function devCategory(e) {
        setBackground(e.currentTarget.id);
        let job = JobRequest;
        job.DevSpec.Type = e.currentTarget.id;
        setJobRequest(job);
    }
    function categoryClicked(e) {
        setBackground(e.currentTarget.id);
        let job = JobRequest;
        job.DesignSpec.collaterals = e.currentTarget.id;
        setJobRequest(job);
    }
    function approveCreds(e) {
        let index = 0;
        for (let i = 0; i < Jobs.length; i++) {
            if (e.currentTarget.id === Jobs[i]._id) {
                Jobs[i].Status = 'inProgress'
                Jobs[i].FeedBack = 'We are working on it.'
                index = i
            }
        }
        store.setLoading(true)
        store.updateJobById(Jobs[index]).then((ret) => {
            if (ret === 'error') {
                window.alert('There was an error updating this job.');
                store.setLoading(false);
            } else {
                setSelectedJob({});
                window.alert('Task has been approved');
                loadCompanies();
                setShowData(false);
                forceUpdate();
                store.setLoading(false)
            }
        });
        forceUpdate();
    }
    function editTaskClicked(e) {
        setSelectedJob(Jobs[Number(e.currentTarget.id)]);
        setDeclinedTasks(true);
        forceUpdate();
    }
    function closeEditTask() {
        setSelectedJob({});
        setDeclinedTasks(false);
        store.setLoading(true);
        store.getJobByEmail(store.user.Company).then((ret) => {
            if (typeof ret === "string" || !ret) {
                setJobs({});
                store.setLoading(false);
            }
            setJobs(ret);
            store.setLoading(false);
        })
        forceUpdate();
    }
    function handleSelectedJobChange(e) {
        let taskObj = SelectedJob;
        if (e.currentTarget.id === 'title') {
            taskObj.Title = e.target.value;
        }
        if (e.currentTarget.id === 'Department') {
            taskObj.Department = e.target.value;
        }
        if (e.currentTarget.id === 'dueDate') {
            taskObj.DueDate = e.currentTarget.value;
        }
        if (e.currentTarget.id === 'category') {
            taskObj.DesignSpec.category = e.target.value;
        }
        if (e.currentTarget.id === 'Platform') {
            taskObj.DesignSpec.Platform = e.target.value;
        }
        if (e.currentTarget.id === 'CopyIfRequired') {
            taskObj.DesignSpec.CopyIfRequired = e.target.value;
        }
        if (e.currentTarget.id === 'Attachments') {
            taskObj.DesignSpec.Attachments = e.target.value;
        }
        if (e.currentTarget.id === 'Size') {
            taskObj.DesignSpec.Sizes = e.target.value;
        }
        setSelectedJob(taskObj);
        forceUpdate();
    }
    function devDepartmentChange(e) {
        let taskObj = SelectedJob;
        if (e.currentTarget.id === 'Description') {
            taskObj.Description = e.target.value;
        }
        if (e.currentTarget.id === 'Figma_Available') {
            taskObj.DevSpec.Figma_Available = e.target.value;
        }
        if (e.currentTarget.id === 'Outcomes') {
            taskObj.DevSpec.Outcomes = e.target.value;
        }
        if (e.currentTarget.id === 'Platform') {
            taskObj.DevSpec.Platform = e.target.value;
        }
        if (e.currentTarget.id === "Figma_Available" || e.currentTarget.id === "Figma_NotAvailable") {
            taskObj.DevSpec.Figma_Available = e.target.value;
        }
        setSelectedJob(taskObj);
        forceUpdate();
    }
    function reSubJobReq() {
        store.setLoading(true);
        let task = SelectedJob;
        task.Status = 'requested'

        store.updateJobById(task).then((ret) => {
            if (ret === 'error') {
                window.alert('There was an error updating this job.');
                setDeclinedTasks(false);
                forceUpdate();
                store.setLoading(false);
            } else {
                window.alert('Job updated successfully.');
                setSelectedJob({});
                setDeclinedTasks(false);
                forceUpdate();
                store.setLoading(false);
            }
        });
        forceUpdate();
    }
    function devSpecTypeChange(e) {
        let task = SelectedJob;
        task.DevSpec.Type = e.currentTarget.id;
        setSelectedJob(task);
        forceUpdate();
    }
    function designSpecCatChange(e) {
        let task = SelectedJob;
        task.DesignSpec.collateral = e.currentTarget.id;
        setSelectedJob(task);
        forceUpdate();
    }
    let requestedBg = TaskLabel === 'requested' ? 'rgba(143, 202, 114, 0.5)' : 'rgb(143, 202, 114)';
    let inProgressBg = TaskLabel === 'inProgress' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)';
    let pendingBg = TaskLabel === 'pending' ? 'rgb(255, 223, 0 , 0.5)' : 'rgb(255, 223, 0)';
    let completedBg = TaskLabel === 'Completed' ? 'rgba(146,140,195, 0.5)' : 'rgba(146,140,195,1)';
    let declinedBg = TaskLabel === 'Declined peacefully.' ? 'rgba(227, 54, 126, 0.5)' : 'rgb(227, 54, 126)';

    if (mobile()) {
        return (
            <div>

            </div>
        );

    } else {
        return (
            <div className='container' style={{ width: '100%', height: '88%', overflowX: 'scroll' }} >
                <Card className='addNewTaskCredsContainer' style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '90%', height: '100%' }}>
                        <Button className='addNewTaskbtn' onClick={addTaskClicked}><p style={{ fontSize: '16px' }}>Add Task</p></Button>
                    </div>
                    <div style={{ width: '12%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <p ><b style={{ width: '80%' }}>Strategy     : </b> {Credits.Sales || 0}</p>
                        <p style={{ marginTop: '-1%' }}><b style={{ width: '80%' }}>Dev       : </b>{Credits.Dev || 0}</p>
                        <p style={{ marginTop: '-1%' }}><b style={{ width: '80%' }}>Design    : </b>{Credits.Design || 0}</p>
                    </div>
                </Card>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: "2%", marginLeft: '2%' }}>
                    <div style={{ width: '100%', height: '20%', display: 'flex', flexDirection: 'row' }}>
                        <Card className='RequestedTasksBtn' id='requested' style={{ cursor: 'pointer', backgroundColor: requestedBg }} onClick={changeStatus}><p style={{ fontSize: '16px' }}>Request</p><b style={{ marginLeft: '10%' }}>{Jobs.filter(task => task.Status === 'requested').length}</b></Card>
                        <Card className='InProgressTasksBtn' id='pending' style={{ cursor: 'pointer', backgroundColor: pendingBg }} onClick={changeStatus}><p style={{ fontSize: '16px' }}>Pending</p><b style={{ marginLeft: '10%' }}>{Jobs.filter(task => task.Status === 'pending').length}</b></Card>
                        <Card className='InProgressTasksBtn' id='inProgress' style={{ cursor: 'pointer', backgroundColor: inProgressBg }} onClick={changeStatus}><p style={{ fontSize: '16px' }}>In Progress</p><b style={{ marginLeft: '10%' }}>{Jobs.filter(task => task.Status === 'inProgress').length}</b></Card>
                        <Card className='CompletedTasksBtn' id='Completed' style={{ cursor: 'pointer', backgroundColor: completedBg }} onClick={changeStatus}><p style={{ fontSize: '16px' }}>Completed</p><b style={{ marginLeft: '10%' }}>{Jobs.filter(task => task.Status === 'Completed' || task.Status === 'Complete').length}</b></Card>
                        <Card className='DeclinedTasksBtn' id='Declined peacefully.' style={{ cursor: 'pointer', backgroundColor: declinedBg }} onClick={changeStatus}><p >Declined</p><b style={{ marginLeft: '10%' }}>{Jobs.filter(task => task.Status === 'Declined peacefully.').length}</b></Card>
                    </div>
                    <div style={{ width: '100%', height: '60%', overflow: 'scroll', marginTop: '2%' }}>
                        <Card className='DeclinedTasks'>
                            <div style={{ width: '30%' }}>
                                <p><b>Title</b></p>
                            </div>
                            <div style={{ width: '30%' }}>
                                <p>Description</p>
                            </div>
                            <div style={{ width: '30%', display: 'flex', flexDirection: 'row' }}>
                                <p>Credits</p>
                            </div>
                            <div style={{ width: '15%' }}>
                            </div>
                        </Card>

                        {Jobs.map((job, index) => {
                            if (job.Status === TaskLabel) {
                                let creds = 0;
                                if (TaskLabel === 'pending') {
                                    creds = job.EstimatedHours || 0;
                                } else {
                                    creds = job.ActualHours || 0;
                                }
                                let desc = job.Description.slice(0, 20) + '...';

                                return (
                                    <Card key={index} id={index} style={{ cursor: 'pointer' }} className='DeclinedTasks' onClick={job.Status === 'Declined peacefully.' ? editTaskClicked : viewJobDetails}>
                                        <div style={{ width: '30%' }}>
                                            <p><b>{job.Title}</b></p>
                                        </div>
                                        <div style={{ width: '30%' }}>
                                            <p>{desc}</p>
                                        </div>
                                        <div style={{ width: '30%', display: 'flex', flexDirection: 'row' }}>
                                            <img className='CreditsCoin' src={AionsCoin} />
                                            <p>X {creds}</p>
                                        </div>
                                        {job.Status === 'Declined peacefully.' ? (
                                            <Button className='editTaskBtn'>
                                                Edit Task
                                            </Button>
                                        ) : (
                                            <Button className='viewTaskBtn' onClick={viewJobDetails}>
                                                View Task
                                            </Button>
                                        )}
                                    </Card>
                                );
                            }
                        })}
                    </div>
                </div>

                {ShowData ? (
                    <>
                        {SelectedJob.Department === 'Design' ? (
                            <Card className='viewTaskInfoCard'>
                                <img className='closeViewTaskCard' onClick={closeAddTaskData} src={CloseCard} />
                                <div className='AddDataCardHeaders'>
                                    <h1>{SelectedJob.Title}</h1>
                                </div>
                                <div className='AddDataCardBody' style={{ marginTop: '2%', overflowY: 'scroll' }}>
                                    <p style={{ width: '50%' }}>Task created on {formatDate(SelectedJob.createdAt)}</p>
                                    <p style={{ color: 'rgba(227,54,126,1)' }}><b>Estimated Credits : {SelectedJob.EstimatedHours || 0}</b></p>

                                    <div style={{ width: '100%', height: '10%', display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ width: '50%', display: 'flex' }}>
                                            <p>Department</p>
                                            <Card className="displayDepartmentCard" style={{ marginLeft: '4%' }}>
                                                <p>{SelectedJob.Department}</p>
                                            </Card>
                                        </div>
                                        <div style={{ width: '50%', display: 'flex', }}>
                                            <p>Requested due date</p>
                                            <Card className="displayDepartmentCard" style={{ marginLeft: '4%' }}>
                                                <p>{formatDate(SelectedJob.DueDate)}</p>
                                            </Card>
                                        </div>
                                    </div>
                                    <hr style={{ width: '100%' }} />
                                    <div style={{ width: '100%', height: '10%', display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ width: '50%' }}>
                                            <p>Collateral required</p>
                                            <Card className="collateralCard">
                                                <p>{SelectedJob.DesignSpec.collateral || ''}</p>
                                            </Card>
                                        </div>
                                        <div style={{ width: '50%' }}>
                                            <p>PlatForm</p>
                                            <Card className="collateralCard">
                                                <p>{SelectedJob.DesignSpec.Platform}</p>
                                            </Card>
                                        </div>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: '3%' }}>
                                        <p>Size/Dimensions</p>
                                        <card className="dimensionsCards">
                                            <p>{SelectedJob.DesignSpec.Sizes}</p>
                                        </card>
                                        <p>Description</p>
                                        <card className="dimensionsCards">
                                            <p>{SelectedJob.Description}</p>
                                        </card>
                                        <p>Copy if required</p>
                                        <card className="dimensionsCards">
                                            <p>{SelectedJob.DesignSpec.CopyIfRequired}</p>
                                        </card>
                                    </div>
                                </div>

                                {SelectedJob.Department === 'Design' && SelectedJob.Status === 'pending' && (
                                    <div className='sendTaskRequestDiv'>
                                        <Button className='rejectTaskButton' id={SelectedJob._id}>Reject</Button>
                                        <Button className='rejectTaskButtonT' id={SelectedJob._id} onClick={approveCreds}>Accept</Button>
                                    </div>

                                )}

                            </Card>
                        ) : SelectedJob.Department === 'Dev' ? (
                            <Card className='viewTaskInfoCard'>
                                <div className='AddDataCardHeaders'>
                                    <h1>{SelectedJob.Title}</h1>
                                    <img className='closeViewTaskCard' onClick={closeAddTaskData} src={CloseCard} />
                                </div>
                                <div className='AddDataCardBody' style={{ marginTop: '2%', overflowY: 'scroll' }}>
                                    <p style={{ width: '50%' }}>Task created on {formatDate(SelectedJob.createdAt)}</p>
                                    <p style={{ color: 'rgba(227,54,126,1)' }}>Estimated Credits : {SelectedJob.EstimatedHours || 0}</p>
                                    <div style={{ width: '100%', height: '10%', display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ width: '50%', display: 'flex' }}>
                                            <p>Department</p>
                                            <Card className="displayDepartmentCard" style={{ marginLeft: '4%' }}>
                                                <p>{SelectedJob.Department}</p>
                                            </Card>
                                        </div>
                                        <div style={{ width: '50%', display: 'flex', }}>
                                            <p>Requested due date</p>
                                            <Card className="displayDepartmentCard" style={{ marginLeft: '4%' }}>
                                                <p>{formatDate(SelectedJob.DueDate)}</p>
                                            </Card>
                                        </div>
                                    </div>
                                    <hr style={{ width: '100%' }} />
                                    <div style={{ width: '100%', height: '10%', display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ width: '50%' }}>
                                            <p>Figma Available</p>
                                            <Card className="collateralCard">
                                                <p>{SelectedJob.DevSpec.Figma_Available || ''}</p>
                                            </Card>
                                        </div>
                                        <div style={{ width: '50%' }}>
                                            <p>PlatForm</p>
                                            <Card className="collateralCard">
                                                <p>{SelectedJob.DevSpec.Platform}</p>
                                            </Card>
                                        </div>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: '3%' }}>
                                        <p>Outcomes</p>
                                        <card className="dimensionsCards">
                                            <p>{SelectedJob.DevSpec.Outcomes}</p>
                                        </card>
                                        <p>Description</p>
                                        <card className="dimensionsCards">
                                            <p>{SelectedJob.Description}</p>
                                        </card>
                                        <p>Type</p>
                                        <card className="dimensionsCards">
                                            <p>{SelectedJob.DevSpec.Type}</p>
                                        </card>
                                    </div>
                                    {SelectedJob.Department === 'Dev' && SelectedJob.Status === 'pending' && (
                                        <div className='sendTaskRequestDiv'>
                                            <Button className='rejectTaskButton' id={SelectedJob._id}>Reject</Button>
                                            <Button className='rejectTaskButtonT' id={SelectedJob._id} onClick={approveCreds}>Accept</Button>
                                        </div>

                                    )}
                                </div>
                            </Card>
                        ) : null}
                    </>
                ) : null}
                {DeclinedTAsks ? (
                    <Card className='addNewTaskWideCard'>
                        <img className='newCloseCard' onClick={closeEditTask} src={CloseCard} />
                        <div style={{ width: '65%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <div className='AddDataCardHeaders'>
                                <h1>Declined Task</h1>
                            </div>
                            <div className='AddDataCardBody' style={{ marginTop: '5%' }}>
                                <input className="addNewTaskName" placeholder='Task Name' value={SelectedJob.Title} id="title" onChange={handleSelectedJobChange} />
                                <div className='selectDepartmentDiv'>
                                    <div className='selectDueDateDiv'>
                                        <p style={{ width: '50%' }}>Select department:</p>
                                        <select className="selectDepartmentBtn" id="Department" value={SelectedJob.Department} onChange={handleSelectedJobChange}>
                                            <option>Departments</option>
                                            <option value="Design">Design</option>
                                            <option value="Dev">Dev</option>
                                        </select>
                                    </div>
                                    <div className='selectDueDateDiv' style={{ marginLeft: '2%' }}>
                                        <p style={{ width: '50%' }}>Requested due date:</p>
                                        <Input className="selectDepartmentBtn" type='date' value={SelectedJob.DueDate} onChange={handleSelectedJobChange} id="dueDate" />
                                    </div>
                                </div>
                                <hr style={{ marginTop: '-5%' }} />

                                <div className='moreCollateralInfo'>
                                    {SelectedJob.Department === "Design" ? (
                                        <>
                                            <select className="newCategoryTaskInputs" id="category" value={SelectedJob.DesignSpec.category} onChange={handleSelectedJobChange}>
                                                <option>Category</option>
                                                <option value="StillGraphics">Still Graphics</option>
                                                <option value="WebDesign">Web Design</option>
                                                <option value="Video/Animation">Video/ Animation</option>
                                                <option value="Photo">Photo</option>
                                                <option value="Written">Written</option>
                                            </select>
                                            <span style={{ color: 'red' }}>*</span>
                                        </>
                                    ) : null}
                                    {SelectedJob.Department === "Design" ? (
                                        <>
                                            <select className="newCategoryTaskInputs" style={{ marginLeft: '2%' }} id="Platform" value={SelectedJob.DesignSpec.Platform} onChange={handleSelectedJobChange}>
                                                <option>Select platform for use</option>
                                                <option value="SocialMediaPost">Social Media Post</option>
                                                <option value="SocialMediaStory">Social Media Story</option>
                                                <option value="Website">Website</option>
                                                <option value="DigitalPDF">Digital PDF</option>
                                                <option value="PrintPDF">Print PDF</option>
                                                <option value="Youtube">Youtube</option>
                                                <option value="GoogleCampaigns">Google Campaigns</option>
                                                <option value="LiveEvents">LiveEvents</option>
                                                <option value="Other">Other</option>
                                            </select>
                                            <span style={{ color: 'red' }}>*</span>
                                        </>
                                    ) : null}
                                </div>
                                {SelectedJob.Department === "Design" ? (
                                    <div className='TaskInformationCard'>
                                        <p>Description<span style={{ color: 'red' }}>*</span></p>
                                        <textarea className='addNewTaskName' value={SelectedJob.Description} id='Description' onChange={devDepartmentChange} style={{ fontFamily: 'sans-serif', padding: '10px', resize: 'none', overflow: 'hidden', minHeight: '30px', color: 'black' }} placeholder='Enter a full description of the project, what is required and will it be used for.'
                                            onInput={(e) => {
                                                e.target.style.height = 'auto';
                                                e.target.style.height = (e.target.scrollHeight) + 'px';
                                            }}
                                        />
                                        <p>Size/Dimensions<span style={{ color: 'red' }}>*</span></p>
                                        <input className='addNewTaskName' value={SelectedJob.DesignSpec.Sizes} id='Size' onChange={handleSelectedJobChange} style={{ fontFamily: 'sans-serif', color: 'black' }} placeholder='Please specify the size dimensions for the required project i.e. A4 brochure, square social media post, etc.' />
                                        <p>Text/ Copy<span style={{ color: 'red' }}>*</span></p>
                                        <textarea className='addNewTaskName' value={SelectedJob.DesignSpec.CopyIfRequired} id='CopyIfRequired' onChange={handleSelectedJobChange} style={{ fontFamily: 'sans-serif', padding: '10px', color: 'black' }} placeholder='Please include any wording, phrasing or information that needs to go onto the designs.' />
                                        <p>Attachments</p>
                                        <input className='addNewTaskName' value={SelectedJob.DesignSpec.Attachments} id='Attachments' onChange={handleSelectedJobChange} style={{ fontFamily: 'sans-serif', color: 'black' }} placeholder='Google link drive' />
                                    </div>
                                ) : null}
                                {SelectedJob.Department === "Dev" ? (
                                    <>
                                        <p>Description<span style={{ color: 'red' }}>*</span></p>
                                        <input style={{ width: '98%' }} className='addNewTaskName' value={SelectedJob.Description} id='Description' onChange={devDepartmentChange} placeholder='Add Description' />
                                        <p>Select platForm<span style={{ color: 'red' }}>*</span></p>
                                        <select className='addNewTaskName' style={{ marginBottom: "10px", marginTop: "10px", width: "100%" }} value={SelectedJob.DevSpec.Platform} id="Platform" onChange={devDepartmentChange} >
                                            <option value="Select platform">Platforms</option>
                                            <option value="App">App</option>
                                            <option value="Website">Website</option>
                                            <option value="Other">Other</option>
                                        </select>
                                        <p>What should this project achieve?<span style={{ color: 'red' }}>*</span></p>
                                        <textarea className='addNewTaskName' style={{ resize: "none", width: '98%' }} id="Outcomes" onChange={devDepartmentChange} value={SelectedJob.DevSpec.Outcomes} placeholder="Start typing ..." />
                                        <p>Is there a design available<span style={{ color: 'red' }}>*</span></p>
                                        <div>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="figmaAvailability"
                                                    value="Yes"
                                                    checked={SelectedJob.DevSpec.Figma_Available === "Yes"}
                                                    onChange={devDepartmentChange}
                                                    id="Figma_Available"
                                                />
                                                Yes
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="figmaAvailability"
                                                    value="No"
                                                    checked={SelectedJob.DevSpec.Figma_Available === "No"}
                                                    onChange={devDepartmentChange}
                                                    id="Figma_NotAvailable"
                                                />
                                                No
                                            </label>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                            <div className='sendTaskRequestDiv'>
                                <Button onClick={reSubJobReq}>Re-Submit Task</Button>
                            </div>
                        </div>
                        <div style={{ width: '35%', height: '100%', marginTop: '2%' }}>
                            {SelectedJob.Department && (SelectedJob.Department === 'Design' || SelectedJob.Department === 'Dev') && (
                                <div style={{ width: '100%', marginTop: '3%', height: '95%', display: 'flex', flexDirection: 'column', marginLeft: '3%', overflow: 'scroll' }}>
                                    {SelectedJob.Department !== "Design" && SelectedJob.DevSpec.Platform === 'App' || SelectedJob.Department === "Dev" && SelectedJob.DevSpec.Platform === 'Website' ? (
                                        <>
                                            <h1>Dev Category Details</h1>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Bug Fixes' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Bug Fixes' onClick={devCategory}>
                                                <img />
                                                <h3>
                                                    Bug Fixes
                                                </h3>
                                                <p>There is an issue in the current platform that needs to be resolved</p>
                                            </div>

                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Feature Request' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Feature Request' onClick={devCategory} >
                                                <img />
                                                <h3>Feature Request</h3>
                                                <p>An additional feature needs to be added onto an existing platform</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Support Meeting' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Support Meeting' onClick={devCategory}>
                                                <img />
                                                <h3>Support Meeting</h3>
                                                <p>A user has been in contact regarding an issue or question that requires a developer to provide an answer for or a solution to</p>
                                            </div>
                                        </>
                                    ) : null}
                                    {SelectedJob.Department !== "Dev" && SelectedJob.DesignSpec.category === "StillGraphics" ? (
                                        <>
                                            <h1>Design Category Details</h1>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Social Media' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Social Media' onClick={categoryClicked} >
                                                <img src={SocialMedia} />
                                                <h3>
                                                    Social Media
                                                </h3>
                                                <p>Tailored still images/designs for specific platforms to enhance brand engagement. Possible platforms include Linkedin, Instagram, Facebook.</p>
                                            </div>

                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Flyer' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Flyer' onClick={categoryClicked}>
                                                <img src={Flyer} />
                                                <h3>Flyer</h3>
                                                <p>A promotional document designed for quick distribution. There is no standard sizing for a flyer. Please provide specific size requirements from the printers you will be using to print.</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Brochure' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Brochure' onClick={categoryClicked}>
                                                <img src={Brochure} />
                                                <h3>Brochure</h3>
                                                <p>A multi-page informational document often used for marketing and advertising, providing a concise overview of a company, product, or service. Generally printers will require brochures to be in page variables of 4 = 4,8,12,16, 20,etc. Pages may also need to be provided indivually and not in spreads. Please clarify with the printers you will be using regarding their printing guidlines for brochures.</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Poster' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Poster' onClick={categoryClicked}>
                                                <img src={Poster} />
                                                <h3>Poster</h3>
                                                <p>A poster is a visually impactful, single-sheet promotional material designed for public display. It conveys concise information using eye-catching graphics and text to attract attention and convey a message.</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Presentation/Document' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Presentation/Document' onClick={categoryClicked}>
                                                <img src={Presentation} />
                                                <h3>Presentation/Document</h3>
                                                <p>A presentation is a visual communication tool involving spoken content accompanied by slides or visual aids. It serves to convey information, ideas, or messages in a structured and engaging format. Questions to Consider: does this need to be printed or is it digital? Does it need to be editable i.e in Microsoft Powerpoint, or can it be a PDF? A document is a written or electronic piece of information, providing details, instructions, or data on a particular subject. It serves as a record or reference for communication and documentation purposes. Questions to Consider: does this need to be printed or is it digital? Does it need to be editable i.e in Microsoft Word, or can it be a PDF?</p>
                                            </div>
                                        </>
                                    ) : null}
                                    {SelectedJob.Department !== "Dev" && SelectedJob.DesignSpec.category === "WebDesign" ? (
                                        <>
                                            <h1>Design Category Details</h1>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'New website design' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='New website design' onClick={categoryClicked} >
                                                <img />
                                                <h3>
                                                    New website design
                                                </h3>
                                                <p>An online platform accessible through web browsers, comprising web pages with multimedia content and information. It serves various purposes, including information dissemination, communication, and e-commerce.</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'New App design' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='New App design' onClick={categoryClicked}>
                                                <img />
                                                <h3>New App design</h3>
                                                <p>Short for application, an app is a software program designed for mobile devices or computers to perform specific tasks, provide services, or offer entertainment. It typically offers a user-friendly interface for easy interaction.</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Web page updates' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Web page updates' onClick={categoryClicked} >
                                                <img />
                                                <h3>Web page updates</h3>
                                                <p>Updates involve refreshing the visual elements of a website, including layout, color schemes, and imagery.</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'App updates' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='App updates' onClick={categoryClicked} >
                                                <img />
                                                <h3>App updates</h3>
                                                <p>Updates involve refreshing the visual elements of a website, including layout, color schemes, and imagery.</p>
                                            </div>
                                        </>
                                    ) : null}
                                    {SelectedJob.Department !== "Dev" && SelectedJob.DesignSpec.category === "Video/Animation" ? (
                                        <>
                                            <h1>Design Category Details</h1>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Animation - infographics' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Animation - infographics' onClick={categoryClicked} >
                                                <img />
                                                <h3>Animation - infographics
                                                </h3>
                                                <p>Generally focused around text and numbers, animated infographics use dynamic visuals and motion graphics to convey complex information in a visually engaging and easily digestible format. They combine animation and data visualization to enhance audience understanding and retention.</p>
                                            </div>

                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Animation - characters' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Animation - characters' onClick={categoryClicked} >
                                                <img />
                                                <h3>Animation - characters</h3>
                                                <p>Animation characters and designs refer to visually crafted elements in animated content, encompassing characters' appearances, movements, and overall aesthetics. They play a crucial role in conveying the storyline, enhancing engagement, and creating a distinctive visual identity for the animation.</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Filming - Podcast' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Filming - Podcast' onClick={categoryClicked}>
                                                <img />
                                                <h3>Filming - Podcast</h3>
                                                <p>A podcast/vodcast is a digital audio or video program available for streaming or download, typically in a series format. It covers a wide range of topics, featuring discussions, interviews, or storytelling, and is accessible on various platforms for audience consumption.</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Filming - Content' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Filming - Content' onClick={categoryClicked} >
                                                <img />
                                                <h3>Filming - Content </h3>
                                                <p>A visual presentation captured on video, covering various topics such as educational content, entertainment, or promotional material. It leverages visual elements to engage and communicate messages effectively.</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Filming - interview' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Filming - interview' onClick={categoryClicked}  >
                                                <img />
                                                <h3>Filming - interview</h3>
                                                <p>Serves as a visual tool for sharing insights, expertise, or corporate messages, often enhancing communication and engagement.</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Filming - Product video' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Filming - Product video' onClick={categoryClicked}>
                                                <img />
                                                <h3>Filming - Product video</h3>
                                                <p>A product video is a short visual presentation showcasing the features, benefits, and usage of a product. It aims to engage and inform potential customers quickly, often using a combination of visuals, demonstrations, and compelling narration.</p>
                                            </div>
                                        </>
                                    ) : null}
                                    {SelectedJob.Department !== "Dev" && SelectedJob.DesignSpec.category === "Written" ? (
                                        <>
                                            <h1>Design Category Details</h1>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Newsletter/Emailer' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Newsletter/Emailer' onClick={categoryClicked} >
                                                <img />
                                                <h3>
                                                    Newsletter/Emailer
                                                </h3>
                                                <p>A concise means of communication with a targeted audience through email. Please indicate whether you already have a mailer platform, eg, mailchimp, etc. </p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Blog/article' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Blog/article' onClick={categoryClicked} >
                                                <img />
                                                <h3>
                                                    Blog/ article
                                                </h3>
                                                <p>A dynamic online digital journal on various topics. These can be posted onto social media - linekdin, or websites to healp with SEO.</p>
                                            </div>
                                        </>
                                    ) : null}
                                    {SelectedJob.Department !== "Dev" && SelectedJob.DesignSpec.category === "Photo" ? (
                                        <>
                                            <h1>Design Category Details</h1>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Product' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Product' onClick={categoryClicked} >
                                                <img />
                                                <h3>
                                                    Product
                                                </h3>
                                                <p>Visual representations of a product, showcasing its features, design, and details through high-quality images</p>
                                            </div>

                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Lifestyle' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Lifestyle' onClick={categoryClicked} >
                                                <img />
                                                <h3>Lifestyle</h3>
                                                <p>Lifestyle photos are candid or staged images that capture the essence of everyday life, often featuring people engaging in various activities.</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Corporate' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Corporate' onClick={categoryClicked} >
                                                <img />
                                                <h3>Corporate</h3>
                                                <p>Photos are professional images capturing the personnel within a company. The main form of corporate photos are employee headshots.</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Event' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Event' onClick={categoryClicked} >
                                                <img />
                                                <h3>Event</h3>
                                                <p>Event photos capture moments and activities during an event, providing a visual record of the occasion. These images showcase attendees, speakers, and key happenings, serving as both documentation and promotional material for the event.</p>
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            )}
                        </div>
                    </Card>
                ) : null}
                {Task && (JobRequest.DesignSpec.category !== '' || JobRequest.DevSpec.Platform !== '') ? (
                    <Card className='addNewTaskWideCard'>
                        <img className='newCloseCard' onClick={closeTaskClicked} src={CloseCard} />
                        <div id='firstDiv' style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <div className='AddDataCardHeaders'>
                                <h1>New Task</h1>
                            </div>
                            <div className='AddDataCardBody' style={{ marginTop: '5%' }}>
                                <input className="addNewTaskName" placeholder='Task Name' value={JobRequest.Title} id="title" onChange={jobRequestChange} />
                                <div className='selectDepartmentDiv'>
                                    <div className='selectDueDateDiv'>
                                        <p style={{ width: '50%' }}>Select department:</p>
                                        <select className="selectDepartmentBtn" id="Department" value={JobRequest.Department} onChange={jobRequestChange}>
                                            <option>Departments</option>
                                            <option value="Design">Design</option>
                                            <option value="Dev">Dev</option>
                                        </select>
                                    </div>
                                    <div className='selectDueDateDiv' style={{ marginLeft: '2%' }}>
                                        <p style={{ width: '50%' }}>Requested due date:</p>
                                        <Input className="selectDepartmentBtn" type='date' value={JobRequest.DueDate} onChange={jobRequestChange} id="dueDate" />
                                    </div>
                                </div>
                                <hr style={{ marginTop: '-5%' }} />
                                <div className='moreCollateralInfo'>
                                    {JobRequest.Department === "Design" ? (
                                        <>
                                            <select className="newCategoryTaskInputs" id="category" value={JobRequest.DesignSpec.category} onChange={jobRequestChange}>
                                                <option>Category</option>
                                                <option value="StillGraphics">Still Graphics</option>
                                                <option value="WebDesign">Web Design</option>
                                                <option value="Video/Animation">Video/ Animation</option>
                                                <option value="Photo">Photo</option>
                                                <option value="Written">Written</option>
                                            </select>
                                            <span style={{ color: 'red' }}>*</span>
                                        </>
                                    ) : null}
                                    {JobRequest.Department === "Design" ? (
                                        <>
                                            <select className="newCategoryTaskInputs" style={{ marginLeft: '2%' }} id="Platform" value={JobRequest.DesignSpec.Platform} onChange={jobRequestChange}>
                                                <option>Select platform for use</option>
                                                <option value="SocialMediaPost">Social Media Post</option>
                                                <option value="SocialMediaStory">Social Media Story</option>
                                                <option value="Website">Website</option>
                                                <option value="DigitalPDF">Digital PDF</option>
                                                <option value="PrintPDF">Print PDF</option>
                                                <option value="Youtube">Youtube</option>
                                                <option value="GoogleCampaigns">Google Campaigns</option>
                                                <option value="LiveEvents">LiveEvents</option>
                                                <option value="Other">Other</option>
                                            </select>
                                            <span style={{ color: 'red' }}>*</span>
                                        </>
                                    ) : null}
                                </div>


                                {JobRequest.Department === 'Design' && (JobRequest.DesignSpec.collaterals === '' || JobRequest.DesignSpec.collaterals === null) ? (
                                    <p style={{ marginTop: '4%', marginBottom: '1%' }}><b>Please Select a sub-category</b></p>
                                ) : null}
                                {JobRequest.Department === 'Design' && (JobRequest.DesignSpec.collaterals !== '') ? (
                                    <p style={{ marginTop: '4%', marginBottom: '1%' }}> Sub-category : <b>{JobRequest.DesignSpec.collaterals}</b></p>
                                ) : null}
                                {JobRequest.Department === "Design" ? (
                                    <div className='TaskInformationCard'>
                                        <p>Description<span style={{ color: 'red' }}>*</span></p>
                                        <textarea className='addNewTaskName' value={JobRequest.Description} id='Description' onChange={jobRequestChange} style={{ fontFamily: 'sans-serif', padding: '10px', resize: 'none', overflow: 'hidden', minHeight: '30px', color: 'black' }} placeholder='Enter a full description of the project, what is required and will it be used for.'
                                            onInput={(e) => {
                                                e.target.style.height = 'auto';
                                                e.target.style.height = (e.target.scrollHeight) + 'px';
                                            }}
                                        />
                                        {/* <textarea className='addNewTaskName' value={JobRequest.Description} id='Description' onChange={jobRequestChange} style={{ fontFamily: 'sans-serif', padding: '10px', color: '#ccc' }} placeholder='Enter a full description of the project, what is required and will it be used for.' /> */}
                                        <p>Size/Dimensions<span style={{ color: 'red' }}>*</span></p>
                                        <input className='addNewTaskName' value={JobRequest.DesignSpec.Sizes} id='Size' onChange={jobRequestChange} style={{ fontFamily: 'sans-serif', color: 'black' }} placeholder='Please specify the size dimensions for the required project i.e. A4 brochure, square social media post, etc.' />
                                        <p>Text/ Copy<span style={{ color: 'red' }}>*</span></p>
                                        <textarea className='addNewTaskName' value={JobRequest.DesignSpec.CopyIfRequired} id='CopyIfRequired' onChange={jobRequestChange} style={{ fontFamily: 'sans-serif', padding: '10px', color: 'black' }} placeholder='Please include any wording, phrasing or information that needs to go onto the designs.' />
                                        <p>Attachments</p>
                                        <input className='addNewTaskName' value={JobRequest.DesignSpec.Attachments} id='Attachments' onChange={jobRequestChange} style={{ fontFamily: 'sans-serif', color: 'black' }} placeholder='Google link drive' />
                                    </div>
                                ) : null}
                                {JobRequest.Department === "Dev" ? (
                                    <>
                                        <p>Description<span style={{ color: 'red' }}>*</span></p>
                                        <input style={{ width: '98%' }} className='addNewTaskName' value={JobRequest.Description} id='Description' onChange={jobRequestChange} placeholder='Add Description' />
                                        <p>Select platForm<span style={{ color: 'red' }}>*</span></p>
                                        <select className='addNewTaskName' style={{ marginBottom: "10px", marginTop: "10px", width: "100%" }} value={JobRequest.DevSpec.Platform} id="Platform" onChange={jobRequestChange} >
                                            <option value="Select platform">Platforms</option>
                                            <option value="App">App</option>
                                            <option value="Website">Website</option>
                                            <option value="Other">Other</option>
                                        </select>
                                        <p>What should this project achieve?<span style={{ color: 'red' }}>*</span></p>
                                        <textarea className='addNewTaskName' style={{ resize: "none", width: '98%' }} id="Outcomes" onChange={jobRequestChange} value={JobRequest.DevSpec.Outcomes} placeholder="Start typing ..." />
                                        <p>Is there a design available<span style={{ color: 'red' }}>*</span></p>
                                        <div>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="figmaAvailability"
                                                    value="Yes"
                                                    checked={JobRequest.DevSpec.Figma_Available === "Yes"}
                                                    onChange={jobRequestChange}
                                                    id="Figma_Available"
                                                />
                                                Yes
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="figmaAvailability"
                                                    value="No"
                                                    checked={JobRequest.DevSpec.Figma_Available === "No"}
                                                    onChange={jobRequestChange}
                                                    id="Figma_NotAvailable"
                                                />
                                                No
                                            </label>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                            <div className='sendTaskRequestDiv'>
                                <button className='sendTAskBtn' style={{ height: '40px' }} onClick={RequestJobClicked}>Send</button>
                            </div>
                        </div>
                        <div id='secondDiv' style={{ width: '40%', height: '100%', marginTop: '2%' }}>
                            {JobRequest.Department && (JobRequest.Department === 'Design' || JobRequest.Department === 'Dev') && (
                                <div style={{ width: '100%', marginTop: '3%', height: '95%', display: 'flex', flexDirection: 'column', marginLeft: '3%', overflow: 'scroll' }}>
                                    {JobRequest.Department !== "Design" && JobRequest.DevSpec.Platform === 'App' || JobRequest.Department === "Dev" && JobRequest.DevSpec.Platform === 'Website' ? (
                                        <>
                                            <h1>Dev Category Details</h1>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Bug Fixes' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Bug Fixes' onClick={devCategory}>
                                                <img />
                                                <h3>
                                                    Bug Fixes
                                                </h3>
                                                <p>There is an issue in the current platform that needs to be resolved</p>
                                            </div>

                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Feature Request' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Feature Request' onClick={devCategory} >
                                                <img />
                                                <h3>Feature Request</h3>
                                                <p>An additional feature needs to be added onto an existing platform</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Support Meeting' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Support Meeting' onClick={devCategory}>
                                                <img />
                                                <h3>Support Meeting</h3>
                                                <p>A user has been in contact regarding an issue or question that requires a developer to provide an answer for or a solution to</p>
                                            </div>
                                        </>
                                    ) : null}
                                    {JobRequest.Department !== "Dev" && JobRequest.DesignSpec.category === "StillGraphics" ? (
                                        <>
                                            <h1>Design Category Details</h1>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Social Media' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Social Media' onClick={categoryClicked} >
                                                <img src={SocialMedia} />
                                                <h3>
                                                    Social Media
                                                </h3>
                                                <p>Tailored still images/designs for specific platforms to enhance brand engagement. Possible platforms include Linkedin, Instagram, Facebook.</p>
                                            </div>

                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Flyer' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Flyer' onClick={categoryClicked}>
                                                <img src={Flyer} />
                                                <h3>Flyer</h3>
                                                <p>A promotional document designed for quick distribution. There is no standard sizing for a flyer. Please provide specific size requirements from the printers you will be using to print.</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Brochure' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Brochure' onClick={categoryClicked}>
                                                <img src={Brochure} />
                                                <h3>Brochure</h3>
                                                <p>A multi-page informational document often used for marketing and advertising, providing a concise overview of a company, product, or service.

                                                    Generally printers will require brochures to be in page variables of 4 = 4,8,12,16, 20,etc. Pages may also need to be provided indivually and not in spreads. Please clarify with the printers you will be using regarding their printing guidlines for brochures. </p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Poster' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Poster' onClick={categoryClicked}>
                                                <img src={Poster} />
                                                <h3>Poster</h3>
                                                <p>A poster is a visually impactful, single-sheet promotional material designed for public display. It conveys concise information using eye-catching graphics and text to attract attention and convey a message.</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Presentation/Document' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Presentation/Document' onClick={categoryClicked}>
                                                <img src={Presentation} />
                                                <h3>Presentation/Document</h3>
                                                <p>A presentation is a visual communication tool involving spoken content accompanied by slides or visual aids. It serves to convey information, ideas, or messages in a structured and engaging format. Questions to Consider: does this need to be printed or is it digital? Does it need to be editable i.e in Microsoft Powerpoint, or can it be a PDF?

                                                    A document is a written or electronic piece of information, providing details, instructions, or data on a particular subject. It serves as a record or reference for communication and documentation purposes. Questions to Consider: does this need to be printed or is it digital? Does it need to be editable i.e in Microsoft Word, or can it be a PDF?</p>
                                            </div>
                                        </>
                                    ) : null}
                                    {JobRequest.Department !== "Dev" && JobRequest.DesignSpec.category === "WebDesign" ? (
                                        <>
                                            <h1>Design Category Details</h1>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'New website design' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='New website design' onClick={categoryClicked} >
                                                <img />
                                                <h3>
                                                    New website design
                                                </h3>
                                                <p>An online platform accessible through web browsers, comprising web pages with multimedia content and information. It serves various purposes, including information dissemination, communication, and e-commerce.</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'New App design' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='New App design' onClick={categoryClicked}>
                                                <img />
                                                <h3>New App design</h3>
                                                <p>Short for application, an app is a software program designed for mobile devices or computers to perform specific tasks, provide services, or offer entertainment. It typically offers a user-friendly interface for easy interaction.</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Web page updates' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Web page updates' onClick={categoryClicked} >
                                                <img />
                                                <h3>Web page updates</h3>
                                                <p>Updates involve refreshing the visual elements of a website, including layout, color schemes, and imagery.</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'App updates' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='App updates' onClick={categoryClicked} >
                                                <img />
                                                <h3>App updates</h3>
                                                <p>Updates involve refreshing the visual elements of a website, including layout, color schemes, and imagery.</p>
                                            </div>
                                        </>
                                    ) : null}
                                    {JobRequest.Department !== "Dev" && JobRequest.DesignSpec.category === "Video/Animation" ? (
                                        <>
                                            <h1>Design Category Details</h1>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Animation - infographics' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Animation - infographics' onClick={categoryClicked} >
                                                <img />
                                                <h3>Animation - infographics
                                                </h3>
                                                <p>Generally focused around text and numbers, animated infographics use dynamic visuals and motion graphics to convey complex information in a visually engaging and easily digestible format. They combine animation and data visualization to enhance audience understanding and retention.</p>
                                            </div>

                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Animation - characters' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Animation - characters' onClick={categoryClicked} >
                                                <img />
                                                <h3>Animation - characters</h3>
                                                <p>Animation characters and designs refer to visually crafted elements in animated content, encompassing characters' appearances, movements, and overall aesthetics. They play a crucial role in conveying the storyline, enhancing engagement, and creating a distinctive visual identity for the animation.</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Filming - Podcast' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Filming - Podcast' onClick={categoryClicked}>
                                                <img />
                                                <h3>Filming - Podcast</h3>
                                                <p>A podcast/vodcast is a digital audio or video program available for streaming or download, typically in a series format. It covers a wide range of topics, featuring discussions, interviews, or storytelling, and is accessible on various platforms for audience consumption.</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Filming - Content' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Filming - Content' onClick={categoryClicked} >
                                                <img />
                                                <h3>Filming - Content </h3>
                                                <p>A visual presentation captured on video, covering various topics such as educational content, entertainment, or promotional material. It leverages visual elements to engage and communicate messages effectively.</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Filming - interview' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Filming - interview' onClick={categoryClicked}  >
                                                <img />
                                                <h3>Filming - interview</h3>
                                                <p>Serves as a visual tool for sharing insights, expertise, or corporate messages, often enhancing communication and engagement.</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Filming - Product video' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Filming - Product video' onClick={categoryClicked}>
                                                <img />
                                                <h3>Filming - Product video</h3>
                                                <p>A product video is a short visual presentation showcasing the features, benefits, and usage of a product. It aims to engage and inform potential customers quickly, often using a combination of visuals, demonstrations, and compelling narration.</p>
                                            </div>
                                        </>
                                    ) : null}
                                    {JobRequest.Department !== "Dev" && JobRequest.DesignSpec.category === "Animation" ? (
                                        <>
                                            <h1>Design Category Details</h1>
                                        </>
                                    ) : null}
                                    {JobRequest.Department !== "Dev" && JobRequest.DesignSpec.category === "Written" ? (
                                        <>
                                            <h1>Design Category Details</h1>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Newsletter/Emailer' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Newsletter/Emailer' onClick={categoryClicked} >
                                                <img />
                                                <h3>
                                                    Newsletter/Emailer
                                                </h3>
                                                <p>A concise means of communication with a targeted audience through email. Please indicate whether you already have a mailer platform, eg, mailchimp, etc. </p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Blog/article' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Blog/article' onClick={categoryClicked} >
                                                <img />
                                                <h3>
                                                    Blog/ article
                                                </h3>
                                                <p>A dynamic online digital journal on various topics. These can be posted onto social media - linekdin, or websites to healp with SEO.</p>
                                            </div>
                                        </>
                                    ) : null}
                                    {JobRequest.Department !== "Dev" && JobRequest.DesignSpec.category === "Photo" ? (
                                        <>
                                            <h1>Design Category Details</h1>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Product' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Product' onClick={categoryClicked} >
                                                <img />
                                                <h3>
                                                    Product
                                                </h3>
                                                <p>Visual representations of a product, showcasing its features, design, and details through high-quality images</p>
                                            </div>

                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Lifestyle' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Lifestyle' onClick={categoryClicked} >
                                                <img />
                                                <h3>Lifestyle</h3>
                                                <p>Lifestyle photos are candid or staged images that capture the essence of everyday life, often featuring people engaging in various activities.</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Corporate' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Corporate' onClick={categoryClicked} >
                                                <img />
                                                <h3>Corporate</h3>
                                                <p>Photos are professional images capturing the personnel within a company. The main form of corporate photos are employee headshots.</p>
                                            </div>
                                            <div style={{ width: '95%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: `${Background === 'Event' ? 'rgba(49, 192, 209, 0.5)' : 'rgb(49, 192, 209)'}`, marginBottom: '1%' }} id='Event' onClick={categoryClicked} >
                                                <img />
                                                <h3>Event</h3>
                                                <p>Event photos capture moments and activities during an event, providing a visual record of the occasion. These images showcase attendees, speakers, and key happenings, serving as both documentation and promotional material for the event.</p>
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            )}
                        </div>
                    </Card>
                ) : Task && (JobRequest.DesignSpec.category === '' && JobRequest.DevSpec.Platform === '') ? (
                    <Card className='addNewTaskWideCard' style={{ width: '50%', left: '30%' }}>
                        <img className='newCloseCard' onClick={closeTaskClicked} src={CloseCard} />
                        <div id='firstDiv' style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <div className='AddDataCardHeaders'>
                                <h1>New Task</h1>
                            </div>
                            <div className='AddDataCardBody' style={{ marginTop: '5%' }}>
                                <input className="addNewTaskName" placeholder='Task Name' value={JobRequest.Title} id="title" onChange={jobRequestChange} />
                                <div className='selectDepartmentDiv'>
                                    <div className='selectDueDateDiv'>
                                        <p style={{ width: '50%' }}>Select department:</p>
                                        <select className="selectDepartmentBtn" id="Department" value={JobRequest.Department} onChange={jobRequestChange}>
                                            <option>Departments</option>
                                            <option value="Design">Design</option>
                                            <option value="Dev">Dev</option>
                                        </select>
                                    </div>
                                    <div className='selectDueDateDiv' style={{ marginLeft: '2%' }}>
                                        <p style={{ width: '50%' }}>Requested due date:</p>
                                        <Input className="selectDepartmentBtn" type='date' value={JobRequest.DueDate} onChange={jobRequestChange} id="dueDate" />
                                    </div>
                                </div>
                                <hr style={{ marginTop: '-5%' }} />
                                <div className='moreCollateralInfo'>
                                    {JobRequest.Department === "Design" ? (
                                        <>
                                            <select className="newCategoryTaskInputs" id="category" value={JobRequest.DesignSpec.category} onChange={jobRequestChange}>
                                                <option>Category</option>
                                                <option value="StillGraphics">Still Graphics</option>
                                                <option value="WebDesign">Web Design</option>
                                                <option value="Video/Animation">Video/ Animation</option>
                                                <option value="Photo">Photo</option>
                                                <option value="Written">Written</option>
                                            </select>
                                            <span style={{ color: 'red' }}>*</span>
                                        </>
                                    ) : null}
                                    {JobRequest.Department === "Design" ? (
                                        <>
                                            <select className="newCategoryTaskInputs" style={{ marginLeft: '2%' }} id="Platform" value={JobRequest.DesignSpec.Platform} onChange={jobRequestChange}>
                                                <option>Select platform for use</option>
                                                <option value="SocialMediaPost">Social Media Post</option>
                                                <option value="SocialMediaStory">Social Media Story</option>
                                                <option value="Website">Website</option>
                                                <option value="DigitalPDF">Digital PDF</option>
                                                <option value="PrintPDF">Print PDF</option>
                                                <option value="Youtube">Youtube</option>
                                                <option value="GoogleCampaigns">Google Campaigns</option>
                                                <option value="LiveEvents">LiveEvents</option>
                                                <option value="Other">Other</option>
                                            </select>
                                            <span style={{ color: 'red' }}>*</span>
                                        </>
                                    ) : null}
                                </div>


                                {JobRequest.Department === 'Design' && (JobRequest.DesignSpec.collaterals === '' || JobRequest.DesignSpec.collaterals === null) ? (
                                    <p style={{ marginTop: '3%', marginBottom: '1%' }}><b>Please Select a sub-category</b></p>
                                ) : null}
                                {JobRequest.Department === 'Design' && (JobRequest.DesignSpec.collaterals !== '') ? (
                                    <p style={{ marginTop: '1%', marginBottom: '1%' }}> Sub-category : <b>{JobRequest.DesignSpec.collaterals}</b></p>
                                ) : null}
                                {JobRequest.Department === "Design" ? (
                                    <div className='TaskInformationCard'>
                                        <p>Description<span style={{ color: 'red' }}>*</span></p>
                                        <textarea className='addNewTaskName' value={JobRequest.Description} id='Description' onChange={jobRequestChange} style={{ fontFamily: 'sans-serif', padding: '10px', resize: 'none', overflow: 'hidden', minHeight: '30px', color: 'black' }} placeholder='Enter a full description of the project, what is required and will it be used for.'
                                            onInput={(e) => {
                                                e.target.style.height = 'auto';
                                                e.target.style.height = (e.target.scrollHeight) + 'px';
                                            }}
                                        />
                                        {/* <textarea className='addNewTaskName' value={JobRequest.Description} id='Description' onChange={jobRequestChange} style={{ fontFamily: 'sans-serif', padding: '10px', color: '#ccc' }} placeholder='Enter a full description of the project, what is required and will it be used for.' /> */}
                                        <p>Size/Dimensions<span style={{ color: 'red' }}>*</span></p>
                                        <input className='addNewTaskName' value={JobRequest.DesignSpec.Sizes} id='Size' onChange={jobRequestChange} style={{ fontFamily: 'sans-serif', color: 'black' }} placeholder='Please specify the size dimensions for the required project i.e. A4 brochure, square social media post, etc.' />
                                        <p>Text/ Copy<span style={{ color: 'red' }}>*</span></p>
                                        <textarea className='addNewTaskName' value={JobRequest.DesignSpec.CopyIfRequired} id='CopyIfRequired' onChange={jobRequestChange} style={{ fontFamily: 'sans-serif', padding: '10px', color: 'black' }} placeholder='Please include any wording, phrasing or information that needs to go onto the designs.' />
                                        <p>Attachments</p>
                                        <input className='addNewTaskName' value={JobRequest.DesignSpec.Attachments} id='Attachments' onChange={jobRequestChange} style={{ fontFamily: 'sans-serif', color: 'black' }} placeholder='Google link drive' />
                                    </div>
                                ) : null}
                                {JobRequest.Department === "Dev" ? (
                                    <>
                                        <p>Description<span style={{ color: 'red' }}>*</span></p>
                                        <input style={{ width: '98%' }} className='addNewTaskName' value={JobRequest.Description} id='Description' onChange={jobRequestChange} placeholder='Add Description' />
                                        <p>Select platForm<span style={{ color: 'red' }}>*</span></p>
                                        <select className='addNewTaskName' style={{ marginBottom: "10px", marginTop: "10px", width: "100%" }} value={JobRequest.DevSpec.Platform} id="Platform" onChange={jobRequestChange} >
                                            <option value="Select platform">Platforms</option>
                                            <option value="App">App</option>
                                            <option value="Website">Website</option>
                                            <option value="Other">Other</option>
                                        </select>
                                        <p>What should this project achieve?<span style={{ color: 'red' }}>*</span></p>
                                        <textarea className='addNewTaskName' style={{ resize: "none", width: '98%' }} id="Outcomes" onChange={jobRequestChange} value={JobRequest.DevSpec.Outcomes} placeholder="Start typing ..." />
                                        <p>Is there a design available<span style={{ color: 'red' }}>*</span></p>
                                        <div>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="figmaAvailability"
                                                    value="Yes"
                                                    checked={JobRequest.DevSpec.Figma_Available === "Yes"}
                                                    onChange={jobRequestChange}
                                                    id="Figma_Available"
                                                />
                                                Yes
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="figmaAvailability"
                                                    value="No"
                                                    checked={JobRequest.DevSpec.Figma_Available === "No"}
                                                    onChange={jobRequestChange}
                                                    id="Figma_NotAvailable"
                                                />
                                                No
                                            </label>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                            <div className='sendTaskRequestDiv'>
                                <button className='sendTAskBtn' onClick={RequestJobClicked}>Send</button>
                            </div>
                        </div>
                    </Card>
                ) : null}

            </div>
        )
    }
})

export default AlkemiaBriefing;