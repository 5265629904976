import React from 'react';
import { Bar } from 'react-chartjs-2';

const TelkomDrawDownGraph = ({ Aions = [], id }) => {
    let initialLabel = 'Apr 2023';
    const reformattedAions = Aions.map((item) => {
        const date = new Date(item.Label);
        const formattedDate = date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
        return { ...item, Label: formattedDate };
    });
    const labels = Aions
        .map((item) => item.Label)
        .filter((value, index, self) => {
            return self.indexOf(value) === index;
        })
        .sort((a, b) => {
            const dateA = new Date(a);
            const dateB = new Date(b);
            return dateA - dateB;
        })
        .map((label) => {
            const date = new Date(label);
            const formattedDate = date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
            return formattedDate;
        });

    let TelkomAmt = 58000000;
    const constantLine = Array(labels.length).fill(TelkomAmt);
    const telkomForecast = labels.map((label) => {
        const labelsData = reformattedAions.find((item) => item.Label === label && item.Type === 'Forecast')
        return labelsData ? Number(labelsData.TelkomForecast).toFixed(2) : 0;
    });
    const telkom = labels.map((label) => {
        const labelsData = reformattedAions.find((item) => item.Label === label && item.Type === 'Actual')
        return labelsData ? Number(labelsData.Telkom).toFixed(2) : 0;
    });
    const lineDatasetOptions = {
        datalabels: {
            align: 'top',
        },
    };
    const dataSets = [
        {
            label: 'Telkom',
            data: telkom,
            backgroundColor: '#E65758',
            borderWidth: 1,
        },
        {
            label: 'Telkom Forecast',
            data: telkomForecast,
            backgroundColor: '#E4729f89',
            borderWidth: 1,
            categoryPercentage: 0.9,
        },
        {
            label: 'Telkom DrawDown',
            data: constantLine,
            backgroundColor: '#004493',
            borderColor: '#4285F4',
            borderWidth: 1,
            tension: 0.1,
            type: 'line',
            ...lineDatasetOptions,
        },
    ];
    const labelsWithOnlyForecast = labels.filter((label) => {
        const telkomData = telkom[labels.indexOf(label)];
        const telkomForecastData = telkomForecast[labels.indexOf(label)];
        return telkomData === 0 && telkomForecastData > 0;
    });
    const calculateMonthDifference = (startLabel, currentLabel) => {
        const startDate = new Date(startLabel);
        const currentDate = new Date(currentLabel);
        const monthDifference = (currentDate.getFullYear() - startDate.getFullYear()) * 12 + currentDate.getMonth() - startDate.getMonth();
        return monthDifference;
    };

    const adjustedLabels = labels.map((label) => {
        const monthDifference = calculateMonthDifference(initialLabel, label);
        return `${label} - Month ${monthDifference + 1}`;
    });

    const options = {
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Telkom Draw Down',
            },
            datalabels: {
                display: true,
                color: 'black',
                font: {
                    weight: 'bold',
                },
                formatter: (value) => value > 0 ? `R ${Number(value).toLocaleString()}` : '',
            },
        },
        scales: {
            x: {
                display: true,
                stacked: true,
                position: 'bottom',
                labels: adjustedLabels,
            },
            x2: {
                display: true,
                position: 'bottom',
                labels: labels.map((label) => labelsWithOnlyForecast.includes(label) ? 'Forecast' : 'Actual'),
                offset: true,
                grid: {
                    display: false,
                },
                ticks: {
                    display: true,
                },
            },
            y: {
                display: true,
            },
        },
        responsive: true,
    };

    const chartData = {
        labels,
        datasets: dataSets,
    };
    return <Bar options={options} data={chartData} id={id} />;
};

export default TelkomDrawDownGraph;