import React from 'react';
import { Pie } from 'react-chartjs-2';

const AEXConversions = ({ id, Obj }) => {
    let signUps = Obj.TotalSignUps || 0;
    let transactions = Obj.TotalPurchase || 0;
    let date = new Date(Obj.LabelDate);
    let month = date.toLocaleString('en-US', { month: 'long' });
    let year = date.getFullYear();
    let formattedDate = `${month} ${year}`;

    let subPercentage = signUps > 0 ? (transactions / signUps) * 100 : 0;
    let percentage = Number(subPercentage).toFixed(2)
    const chartData = {
        labels: ['Signups', 'Purchases'],
        datasets: [
            {
                data: [signUps, transactions],
                backgroundColor: ['rgb(227, 54, 126)', 'rgb(75,192,192)'],
                borderColor: 'rgba(255, 255, 255, 1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                position: 'right',
            },
            title: {
                display: false,
                text: 'AEX Male and Female Signups',
            },
            datalabels: {
                display: true,
                color: 'black',
                font: {
                    weight: 'bold',
                    size: 15,
                },
                formatter: (value) => value > 0 ? `${Number(value).toLocaleString()}` : '',
            },
        },
        responsive: true,
    };

    return <div>
        <h3>{formattedDate} conversion rate: {percentage}%</h3>
        <Pie options={options} data={chartData} id={id} />
    </div>
};

export default AEXConversions;
