import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const ActRunwayChart = ({ width, height, data }) => {
  const ref = useRef();

  useEffect(() => {
    const margin = { top: 20, right: 20, bottom: 30, left: 190 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const svg = d3.select(ref.current)
      .attr('width', width)
      .attr('height', height);

    svg.selectAll('*').remove();

    const maxMonths = d3.max(data, d => d.Months);
    const maxDomain = maxMonths + (maxMonths * 0.25);

    const xScale = d3.scaleLinear()
      .domain([0, maxDomain])
      .range([0, innerWidth]);

    const yScale = d3.scaleBand()
      .domain(data.map(d => d.Company))
      .range([0, innerHeight])
      .padding(0.1);

    const g = svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    g.append('g')
      .call(d3.axisLeft(yScale).tickSize(0))
      .selectAll('text')
      .style('font-size', '18px')
      .style('font-weight', 300)
      .style('fill', '#213038')
      .style('text-anchor', 'end');

    g.selectAll('.grid-line')
      .data(xScale.ticks(4))
      .enter().append('line')
      .attr('class', 'grid-line')
      .attr('x1', d => xScale(d))
      .attr('y1', 0)
      .attr('x2', d => xScale(d))
      .attr('y2', innerHeight)
      .style('stroke', '#E5E5E5')
      .each(function (d, i) {
        if (i === 0) {
          d3.select(this).style('stroke', '#213038');
        }
      });

    g.selectAll('.domain')
      .style('stroke-width', '2px')
      .style('z-index', '5')
      .style('stroke', '#000');

    function rightRoundedRect(x, y, width, height, radius) {
      return `M${x},${y}
        h${width - radius}
        a${radius},${radius} 0 0 1 ${radius},${radius}
        v${height - 2 * radius}
        a${radius},${radius} 0 0 1 -${radius},${radius}
        h-${width - radius}
        z`;
    }

    const tooltip = d3.select('body').append('div')
      .style('position', 'absolute')
      .style('background', '#ffffff')
      .style('box-shadow', '0 0 10px rgba(0, 0, 0, 1)')
      .style('padding', '5px')
      .style('display', 'none')
      .style('pointer-events', 'none')
      .style('color', '#213038')
      .style('font-weight', '300')
      .style('font-size', '15px')
      .style('text-align', 'center')
      .style('align-items', 'center')
      .style('border-radius', '85px')
      .style('min-width', '100px')
      .style('height', '65px')
      .style('z-index', '5')
      .style('flex-direction', 'column');

    const showTooltip = (event, value, obj, element) => {
      tooltip
        .style('left', (event.pageX + 10) + 'px')
        .style('top', (event.pageY + 10) + 'px')
        .style('display', 'flex')
        .html(`
        <p style="line-height: 0px; font-size: 20px;">${obj.Company}</p>
        <p style="line-height: 0px; font-size: 20px; font-weight: 700; margin: 6px;">${value}</p>
      `);
    };

    const hideTooltip = () => {
      tooltip.style('display', 'none');
      d3.selectAll('.bar-actual, .bar-forecast, .bar-deficit, .bar-difference').style('opacity', 1);
    };
    g.selectAll('.bar')
      .data(data.filter(d => d.Months > 0))
      .enter()
      .append('path')
      .attr('class', 'bar')
      .attr('fill', '#D5922D')
      .on('mouseover', function (event, d) { showTooltip(event, d.Months, d, this); })
      .on('mouseout', hideTooltip)
      .attr('d', d => {
        const barHeight = yScale.bandwidth() * 0.28;
        const yStart = yScale(d.Company) + (yScale.bandwidth() - barHeight) / 2;
        const xStart = 1;
        return rightRoundedRect(xStart, yStart, xScale(d.Months) - xStart, barHeight, 5);
      });

    const xAxis = d3.axisBottom(xScale)
      .ticks(4)
      .tickFormat(d => `${d.toFixed(0)}`);

    g.append('g')
      .attr('transform', `translate(0,${innerHeight})`)
      .call(xAxis)
      .selectAll('text')
      .style('font-size', '15px')
      .style('font-weight', 300)
      .style('fill', '#213038');

    svg.attr('style', 'border: none');
    svg.selectAll('g').selectAll('path').attr('stroke', 'none');
  }, [width, height, data]);

  return <svg ref={ref}></svg>;
};

export default ActRunwayChart;
