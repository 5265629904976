import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { useNavigate } from 'react-router-dom';
import './style.css';
import dashLogo from '../../assets/images/dashLogo.png';
import Todo from '../../assets/images/todo.png';
import InProgress from '../../assets/images/In_Progress@2x.png';
import Completed from '../../assets/images/checked.png';
import Plus from '../../assets/images/plus.png';
import Logo from '../../assets/images/levelLogo.png';
import MessageIcon from '../../assets/images/message.svg';
import ListIcon from '../../assets/images/list.png'
import Task from './Task/Task';
import UserIcon from '../../assets/images/user.png';
import Dashboard from './Dashboard/Dashboard'
import Messages from './Message/Message';
import LogoutIcon from '../../assets/images/power.png';
import HomeIcon from '../../assets/images/icon.png';
import Settings from '../../assets/images/settings.png';
import Notification from '../../assets/images/notification.png';
import { Input, View, Button, Card, DoughnutChart } from '../../components';
import { FaHome, FaRocketchat, FaEnvelopeOpenText, FaPaperPlane, FaRegCommentDots, FaRegFileAlt } from "react-icons/fa";
var mobile = require('is-mobile');

const DepartmentDashboard = observer(() => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [CurrentPage, setCurrentPage] = useState('Task');
    const [User, setUser] = useState({});
    const [CompaniesList, setCompaniesList] = useState([]);
    const [RemovedCrds, setRemovedCrds] = useState(0);
    useEffect(() => {
        loadCompanies();
    }, []);
    function loadCompanies() {
        store.setLoading(true);
        store.getUserByEmail(store.user.Email).then((ret) => {
            if (typeof ret === "string" || !ret) {
                setUser({});
                store.setLoading(false);
                return;
            }
            setUser(ret);
            store.setLoading();
        })
        store.getCompaniesByPerms(store.user.Email).then((ret) => {
            if (typeof ret === "string" || !ret) {
                setCompaniesList({});
                store.setLoading(false);
                return;
            }
            if (ret.length === 0) {
                setCompaniesList({});
                store.setLoading(false);
                return;
            }
            let comp = ret.filter(item => item.Name === store.user.Company);
            if (comp.length === 0) {
                setCompaniesList({});
                store.setLoading(false);
                return;
            }
            store.setLoading(false);
        });
        forceUpdate()
    }

    function navClicked(e) {
        setCurrentPage(e.currentTarget.id);
    }
    function logout() {
        store.logout()
    }
    if (mobile()) {
        return (
            <div>

            </div>
        );

    } else {
        return (
            <div style={{ display: 'flex' }} className='dashBoardBackGround'>
                <div className='companyDashboardSideNav'>
                    <img className='dashPng' src={dashLogo} />
                    <div className='sideNavItems'>
                        {/* <div className={`assets ${(CurrentPage === 'Dashboard') ? 'sidebarItemSelected' : ''}`} id='Dashboard' onClick={navClicked}>
                            <img className='dashboardIcons' src={HomeIcon} />
                            <h3 style={{ marginLeft: '5%', fontFamily: 'Roboto' }} >Dashboard</h3>
                        </div> */}
                        <div className={`assets ${(CurrentPage === 'Task') ? 'sidebarItemSelected' : ''}`} id="Task" onClick={navClicked} >
                            <img className='dashboardIcons' src={ListIcon} />
                            <h3 style={{ marginLeft: '5%', fontFamily: 'Roboto' }} >Task</h3>
                        </div>
                        {/* <div className={`assets ${(CurrentPage === 'Messages') ? 'sidebarItemSelected' : ''}`} onClick={navClicked} id="Messages">
                            <img className='dashboardIcons' src={MessageIcon} />
                            <h3 style={{ marginLeft: '5%', fontFamily: 'Roboto' }} >Message</h3>
                        </div> */}
                        <div className={`assets ${(CurrentPage === 'Logout') ? 'sidebarItemSelected' : ''}`} onClick={logout} id="Logout">
                            <img className='dashboardIcons' src={LogoutIcon} />
                            <h3 style={{ marginLeft: '5%', fontFamily: 'Roboto' }} >Logout</h3>
                        </div>
                    </div>
                </div>
                <div className='companyDashboardBody'>
                    <div className='companyDashboardTopNav'>
                        <div className='headerDivUserInfo'>
                            <div className='Shape_cf_Class'>

                            </div>
                            <div>
                                <h3>{User.FirstName} {User.LastName}</h3>
                                <p>{User.Position || ''}</p>
                            </div>
                            <div>
                                <img style={{ marginLeft: '22%' }} src={UserIcon} />
                            </div>
                            {/* <Card className='CompanyIconsLogo'>
                                <img src={UserIcon} />
                            </Card>
                            <Card className='CompanyIconsLogoSettings'>
                                <img src={Settings} />
                            </Card>
                            <Card className='CompanyIconsLogoSettings'>
                                <img style={{ width: '85%' }} src={Notification} />
                            </Card> */}
                        </div>
                    </div>
                    {(CurrentPage === 'Dashboard') ? <Dashboard /> : null}
                    {(CurrentPage === 'Messages') ? <Messages /> : null}
                    {(CurrentPage === 'Task') ? <Task /> : null}
                </div>
            </div>
        )
    }
})

export default DepartmentDashboard;