import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import totalsales from '../../../assets/images/totalsales.png';
import Config from '../../../assets/images/configs.png';
import UserUpdateIcon from '../../../assets/images/updating.png';
import AddUserIcon from '../../../assets/images/add-user.png';
import './style.css'
import { useNavigate } from 'react-router-dom';
import { Input, View, Button, Card, DoughnutChart, } from '../../../components';
import CloseCard from '../../../assets/images/closeCard.png';
var mobile = require('is-mobile');

const Users = observer(() => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [DashUsers, setDashUsers] = useState([]);
    const [AddUsers, setAddUsers] = useState(false);
    const [Companies, setCompanies] = useState([]);
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [Email, setEmail] = useState('');
    const [Password, setPassword] = useState('');
    const [CreatorEmail, setCreatorEmail] = useState('');
    const [CreatorPassword, setCreatorPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');
    const [UserRole, setUserRole] = useState('role');
    const [SelectedUserComp, setSelectedUserComp] = useState('');
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    const [NewPermissions, setNewPermissions] = useState({
        admin: false,
        users: {
            view: false,
            edit: false,
            create: false
        },
        companies: {
            view: false,
            edit: false,
            create: false
        },
        RolePermission: {
            Company: false,
            Finance: false,
            HR: false,
            ViewFinance: false
        }
    });
    const [RolePermissions, setRolePermissions] = useState({
        Company: false,
        Finance: false,
        HR: false,
        ViewFinance: false
    });
    const [NewRolePermissions, setNewRolePermissions] = useState({
        Company: false,
        Finance: false,
        HR: false
    });
    const [Permissions, setPermissions] = useState({
        admin: false,
        users: {
            view: false,
            edit: false,
            create: false
        },
        companies: {
            view: false,
            edit: false,
            create: false
        },
        RolePermission: {
            Company: false,
            Finance: false,
            HR: false,
            ViewFinance: false
        }
    });
    useEffect(() => {
        loadUsers()
    }, []);

    function loadUsers() {
        store.setLoading(true);
        store.getUsers().then((ret) => {
            setDashUsers(ret);
            store.setLoading(false);
        })
        store.getCompanies().then((ret) => {
            setCompanies(ret);
        })
    }
    function loadCompanies() {
        store.setLoading(true);
        store.getCompanies().then((ret) => {
            setCompanies(ret);
            loadUsers();
        })
    }
    function firstNameChanged(e) {
        setFirstName(e.target.value);
    }
    function emailChanged(e) {
        setEmail(e.target.value);
    }
    function passwordChanged(e) {
        setPassword(e.target.value);
    }
    function ConfirmPasswordChanged(e) {
        setConfirmPassword(e.target.value);
    }
    function lastNameChanged(e) {
        setLastName(e.target.value);
    }
    function clearData() {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPassword('')
        setConfirmPassword('')
        setUserRole('role')
        setPermissions({
            admin: false,
            users: {
                view: false,
                edit: false,
                create: false
            },
            companies: {
                view: false,
                edit: false,
                create: false
            },
            userType: {
                adminUser: false
            },
            RolePermission: {}
        })
        setNewPermissions({
            admin: false,
            users: {
                view: false,
                edit: false,
                create: false
            },
            companies: {
                view: false,
                edit: false,
                create: false
            },
            userType: {
                adminUser: false
            },
            RolePermission: {}
        })
        setCreatorEmail('')
        setCreatorPassword('')
        setRolePermissions({
            Company: false,
            Finance: false,
            HR: false
        })
        setNewRolePermissions({
            Company: false,
            Finance: false,
            HR: false
        })
    }
    const validateEmail = (Email) => {
        if (!emailRegex.test(Email)) {
            return false
        } else {
            return true
        }
    }
    const validatePassword = (pwd) => {
        if (!passwordRegex.test(pwd)) {
            return false
        } else {
            return true
        }
    }
    function NewRolePermissionsChanged(e) {
        let permissions = NewRolePermissions;
        let newPerms = NewPermissions;

        if (e.currentTarget.id === 'compnyNewPerm') {
            permissions.Company = !permissions.Company;
        }
        if (e.currentTarget.id === 'financeNewPerm') {
            permissions.Finance = !permissions.Finance;
        }
        if (e.currentTarget.id === 'hrNewPerm') {
            permissions.HR = !permissions.HR;
        }
        if (e.currentTarget.id === 'viewFinancePerm') {
            permissions.ViewFinance = !permissions.ViewFinance;
        }
        setNewRolePermissions(permissions);
        forceUpdate();

        if (UserRole === 'Company') {
            newPerms.RolePermission = permissions;
        };
        setNewPermissions(newPerms);
        forceUpdate();
    }
    function NewCompPermissions(e) {
        let args = e.target.id.split('_');
        args[0] = args[0].replace(' ', '');
        args[0] = args[0].replace('-', '');
        let perms = NewPermissions;
        if (Object.keys(perms).includes(args[0])) {
            perms[args[0]][args[1]] = !perms[args[0]][args[1]];
        } else {
            perms[args[0]] = {
                view: false,
                edit: false,
                create: false
            }
            perms[args[0]][args[1]] = !perms[args[0]][args[1]];
        }

        setNewPermissions(perms);
        forceUpdate();
    }
    function addUsersClicked() {
        setAddUsers(true);
    }
    function closeAddUser() {
        setAddUsers(false);
    }
    function permissionSelected(e) {
        let perms = Permissions;
        if (e.currentTarget.id === 'adminUser') {
            perms.admin = !perms.admin;
        }
        if (e.currentTarget.id === 'userView') {
            perms.users.view = !perms.users.view;
        }
        if (e.currentTarget.id === 'userEdit') {
            perms.users.edit = !perms.users.edit;
        }
        if (e.currentTarget.id === 'userCreate') {
            perms.users.create = !perms.users.create;
        }
        if (e.currentTarget.id === 'companyView') {
            perms.companies.view = !perms.companies.view;
        }
        if (e.currentTarget.id === 'companyEdit') {
            perms.companies.edit = !perms.companies.edit;
        }
        if (e.currentTarget.id === 'companyCreate') {
            perms.companies.create = !perms.companies.create;
        }
        setPermissions(perms);
        forceUpdate()
    }
    function userRoleChanged(e) {
        setUserRole(e.target.value);
    }

    function createClicked() {
        store.setLoading(true);
        if (FirstName === '') {
            window.alert('Please enter your First name.');
            store.setLoading(false);
            return
        }
        if (LastName === '') {
            window.alert('Please enter your Last name.');
            store.setLoading(false);
            return
        }
        if (!validateEmail(Email) || Email === '') {
            window.alert('Please enter a Valid Email address.');
            store.setLoading(false);
            return
        }
        if (!validatePassword(Password) || Password === '') {
            window.alert('Password must contain at least 8 characters, including uppercase, lowercase, number, and special character.');
            store.setLoading(false);
            return
        }
        if (ConfirmPassword === '' || ConfirmPassword !== Password) {
            window.alert('Passwords do not match!');
            store.setLoading(false);
            return
        }
        if (Permissions === '') {
            window.alert('Please set users permissions.');
            store.setLoading(false);
            return
        }
        if (UserRole === 'role') {
            window.alert('Please select user role.');
            store.setLoading(false);
            return
        }

        let data = {
            FirstName: FirstName,
            LastName: LastName,
            Email: Email,
            Password: Password,
            Permissions: Permissions,
            Blocked: false,
            Role: UserRole,
            Company: SelectedUserComp,
            CreatorPassword: CreatorPassword,
            CreatorEmail: CreatorEmail
        }
        store.createUsers(data).then((ret) => {
            if (ret) {
                window.alert('User created successfully');
                setAddUsers(false);
                clearData();
                loadUsers();
            }
            store.setLoading(false);
        })

    }
    if (mobile()) {
        return (
            <div>

            </div>
        );

    } else {
        return (
            <div className='container' style={{ width: '100%', height: '88%', overflow: 'scroll' }} >
                <card className='allCompaniesCard'>
                    <h1>Users</h1>
                    <div className='myCards'>
                        <Card className='tasksCardsCompleted'>
                            <div>
                                <img className='inProgressImg' src={totalsales} />
                                <p >Total Users</p>
                            </div>
                            <card className='cardCard'></card>
                            <h1>{DashUsers.length || 0}</h1>
                        </Card>
                        <Card className='companiesAddActualsCard' style={{ cursor: 'pointer' }} onClick={addUsersClicked}>
                            <div>
                                <img className='inProgressImg' src={AddUserIcon} />
                                <p style={{ marginLeft: '25%' }}>Add User</p>
                            </div>
                        </Card>
                    </div>
                </card>
                <div className='bottomMappedCompanies'>
                    {DashUsers.map((user, index) => (
                        <card key={user} title={user} className='usersCardWithUpdateButton'>
                            <div style={{ width: '90%' }}>
                                <p style={{ marginLeft: '2%' }}>{user.FirstName} {user.LastName}</p>
                            </div>
                            <card className='companyConfigButtons'><img src={UserUpdateIcon} /><p style={{ marginLeft: '10%' }}>Update</p></card>
                        </card>
                    ))}
                </div>
                {AddUsers ? (
                    <Card className='addForecast'>
                        <div className='AddDataCardHeaders'>
                            <h1>Add User</h1>
                            <img className='closeAddActualsCard' onClick={closeAddUser} src={CloseCard} />
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', maxHeight: '80%', overflow: 'scroll' }}>
                            <div className='AddUserDiv'>
                                <h2>New User</h2>
                                <Input className='addNewUserInputs' placeholder='First Name' type='text'
                                    value={FirstName}
                                    onChange={firstNameChanged} />
                                <Input className='addNewUserInputs' placeholder='Last Name' type='text'
                                    value={LastName}
                                    onChange={lastNameChanged} />
                                <Input className='addNewUserInputs' placeholder='Email' type='text'
                                    value={Email}
                                    onChange={emailChanged} />
                                <Input className='addNewUserInputs' placeholder='Password' type='password'
                                    value={Password}
                                    onChange={passwordChanged} />
                                <Input className='addNewUserInputs' placeholder='Confirm Password' type='password'
                                    value={ConfirmPassword}
                                    onChange={ConfirmPasswordChanged} />
                            </div>
                            <div className='UserPermsDiv'>
                                <h2>Permissions</h2>
                                <div className='newUserCheckboxRow'>
                                    <p className='checkboxRowHeader'>User Type:</p>
                                    <div className='newUserCheckboxDiv'>
                                        <p>Admin</p>
                                        <input type='checkbox' id='adminUser'
                                            checked={
                                                Permissions.admin
                                            }
                                            onClick={permissionSelected} />
                                    </div>
                                </div>
                                <div className='newUserCheckboxRow'>
                                    <p className='checkboxRowHeader'>Users:</p>
                                    <div className='newUserCheckboxDiv'>
                                        <p>View</p>
                                        <input type='checkbox' id='userView'
                                            checked={
                                                Permissions.users.view
                                            }
                                            onClick={permissionSelected} />
                                    </div>
                                    <div className='newUserCheckboxDiv'>
                                        <p>Edit</p>
                                        <input type='checkbox' id='userEdit'
                                            checked={
                                                Permissions.users.edit
                                            }
                                            onClick={permissionSelected} />
                                    </div>
                                    <div className='newUserCheckboxDiv'>
                                        <p>create</p>
                                        <input type='checkbox' id='userCreate'
                                            checked={
                                                Permissions.users.create
                                            }
                                            onClick={permissionSelected} />
                                    </div>
                                </div>
                                <div className='newUserCheckboxRow'>
                                    <p className='checkboxRowHeader'>Companies:</p>
                                    <div className='newUserCheckboxDiv'>
                                        <p>View</p>
                                        <input type='checkbox' id='companyView'
                                            checked={
                                                Permissions.companies.view
                                            }
                                            onClick={permissionSelected} />
                                    </div>
                                    <div className='newUserCheckboxDiv'>
                                        <p>Edit</p>
                                        <input type='checkbox' id='companyEdit'
                                            checked={
                                                Permissions.companies.edit
                                            }
                                            onClick={permissionSelected} />
                                    </div>
                                    <div className='newUserCheckboxDiv'>
                                        <p>create</p>
                                        <input type='checkbox' id='companyCreate'
                                            checked={
                                                Permissions.companies.create
                                            }
                                            onClick={permissionSelected} />
                                    </div>
                                </div>
                                <select className='addNewUserInputs'
                                    value={UserRole}
                                    onChange={userRoleChanged}>
                                    <option value='role'>Select Role</option>
                                    <option value='Admin'>Admin</option>
                                    <option value='Company'>Company</option>
                                    <option value='Sales'>Sales</option>
                                    <option value='Design'>Design</option>
                                    <option value='Dev'>Dev</option>
                                </select>
                                {(UserRole === 'Company') ?
                                    <div>
                                        {Companies.map((company, index) => {
                                            let compPerm = NewPermissions[company.Name.replace(' ', '')] || {}
                                            return (
                                                <div key={index} className='newUserCheckboxRow'>
                                                    <p className='checkboxRowHeader'> {`${company.Name}:`}</p>
                                                    <div className='newUserCheckboxDiv'>
                                                        <p>View</p>
                                                        <input type='checkbox' id={`${company.Name}_view`} checked={compPerm.view || false} onClick={NewCompPermissions} />
                                                    </div>
                                                    <div className='newUserCheckboxDiv'>
                                                        <p>Edit</p>
                                                        <input type='checkbox' id={`${company.Name}_edit`} checked={compPerm.edit || false} onClick={NewCompPermissions} />
                                                    </div>
                                                </div>
                                            )
                                        })}

                                        <div>
                                            <p className='checkboxRowHeader'>Role Permissions</p>
                                            <div className='newUserCheckboxDiv'>
                                                <p>Company :</p>
                                                <input style={{ marginLeft: '10%' }} type='checkbox' id='compnyNewPerm' checked={NewRolePermissions.Company} onClick={NewRolePermissionsChanged} />
                                            </div>
                                            <div className='newUserCheckboxDiv'>
                                                <p>Finance :</p>
                                                <input style={{ marginLeft: '15%' }} type='checkbox' id='financeNewPerm' checked={NewRolePermissions.Finance} onClick={NewRolePermissionsChanged} />
                                            </div>
                                            <div className='newUserCheckboxDiv'>
                                                <p>HR :</p>
                                                <input style={{ marginLeft: '27%' }} type="checkbox" id="hrNewPerm" checked={NewRolePermissions.HR} onClick={NewRolePermissionsChanged} />
                                                <div className='newUserCheckboxDiv'>
                                                    <p>View Finance :</p>
                                                    <input style={{ marginLeft: '27%' }} type="checkbox" id="viewFinancePerm" checked={NewRolePermissions.ViewFinance} onClick={NewRolePermissionsChanged} />
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null}
                            </div>
                        </div>
                        <Button className='addNewUserButton' onClick={createClicked}>Create User</Button>
                    </Card>
                ) : null}
            </div>
        )
    }
})

export default Users;