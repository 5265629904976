import React from 'react';
import './style.scss';
import tubbyshuge from './../../assets/images/tubbyshuge.svg';
import tubbysmini from './../../assets/images/TubbysMini.svg';
function InvesteeSidebar({ Active, onMouseEnter, onMouseLeave, pageClicked, pageSelected, logout, compName }) {
    const Icon = `https://d830j64u6p5xs.cloudfront.net/Companies/${compName}/LogoLong.svg`
    const miniIcon = `https://d830j64u6p5xs.cloudfront.net/Companies/${compName}/LogoShort.svg`

    return (
        <div className={`InvesteeSidebar ${Active ? 'InvesteeSidebarActive' : ''}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={{ transition: 'width 1s ease' }}>
            <img src={Active ? Icon : miniIcon} alt='' style={{maxWidth: '70%'}}/>
            <div className='pagesSection'>
                <div className={pageSelected === 'AlkemiaBriefing' ? 'availablePagesActive' : 'availablePages'} id='AlkemiaBriefing' onClick={pageClicked}>
                    <div className='availablePageSelector'></div>
                    <p className={'selectedPageFont'}>{Active ? 'Alkemia' : 'A'}</p>
                </div>
                <div className={`availablePages ${(pageSelected === 'Reports' ? 'availablePagesActive' : '')}`} id='Reports' onClick={pageClicked}>
                    <div className='availablePageSelector'></div>
                    <p className='selectedPageFont'>{Active ? 'Reports' : 'R'}</p>
                </div>
                <div className={`availablePages ${(pageSelected === 'Metrics' ? 'availablePagesActive' : '')}`} id='Metrics' onClick={pageClicked}>
                    <div className='availablePageSelector'></div>
                    <p className='selectedPageFont'>{Active ? 'Metrics' : 'M'}</p>
                </div>
                <div className={`availablePages ${(pageSelected === 'Logout' ? 'availablePagesActive' : '')}`} id='Logout' onClick={logout}>
                    <div className='availablePageSelector'></div>
                    <p className='selectedPageFont'>{Active ? 'Logout' : 'L'}</p>
                </div>
            </div>
        </div>
    );
}

export default InvesteeSidebar;
