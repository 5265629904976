import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Input,
  Button,
} from '../../../../components';

import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import isMobile from 'is-mobile';
import { componentsToColor } from 'pdf-lib';

const UpdateActuals = observer(({ SelectedCompanyId }) => {
  const store = useStore();
  const [, updateState] = useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const navigate = useNavigate();
  const [Company, setCompany] = useState([]);
  const [MatrixTotals, setMatrixTotals] = useState([])
  const [MatrixList, setMatrixList] = useState([])
  const [UpdatedActuals, setUpdatedActuals] = useState([])
  const [NewActual, setNewActual] = useState([]);
  const [SeletedDate, setSelectedDate] = useState(new Date())
  const [ToggleTableID, setToggleTableID] = useState('')
  const [ToggleTable, setToggleTable] = useState(false)
  const [ActualDetailsObject, setActualDetailsObject] = useState({
    Category: '',
    Amount: 0,
  })
  const [ActualDetails, setActualDetails] = useState([]);
  useEffect(() => {
    loadCompany();
  }, []);
  function dateSelector(e) {
    let date = new Date();
    let actingDetails = {};
    let actDetailsArray = ActualDetails;
    let finalDetailsArray = [];
    let matrixTotals = [];
    let finalDetails = {};
    let actDetails = {}

    if (e.target.value) {
      date = new Date(e.target.value).toDateString();
      setSelectedDate(date);
    }

    for (let i = 0; i < Company.Actual.length; i++) {
      let act = {}
      actDetails = {}
      if (date === new Date(Company.Actual[i].Date).toDateString()) {
        act = Company.Actual[i];
        actDetails = Company.Actual[i].ActualDetails;
        setNewActual(act);
        for (let j = 0; j < Object.keys(act).length; j++) {
          if (Object.keys(act)[j] !== 'Date') {
            matrixTotals.push(Object.values(act)[j]);
          };
        }
        setMatrixTotals(matrixTotals);
      }
      if (act) {
        actDetailsArray = [];
        for (let key in act.ActualDetails) {
          actingDetails = {
            Type: key.replace("Details", ""),
            Details: act.ActualDetails[key]
          }
          actDetailsArray.push(actingDetails);
        }
        for (let k = 0; k < actDetailsArray.length; k++) {
          for (let j = 0; j < actDetailsArray[k].Details.length; j++) {
            finalDetails = {
              Type: actDetailsArray[k].Type,
              Details: actDetailsArray[k].Details[j]
            }
            finalDetailsArray.push(finalDetails);
          }
        }
      }
    }
    setActualDetails(finalDetailsArray);
    forceUpdate();
  }
  function loadCompany() {
    let actuals = [];
    let matrixList = [];
    let matricTotals = [];
    let actingDetails = {};
    let finalDetails = {};
    let finalDetailsArray = [];
    let actDetailsArray = ActualDetails;
    let comp = Company;
    if (!store.user.Permissions.companies.view) {
      return;
    }
    store.setLoading(true);
    store.getCompanyByID(SelectedCompanyId).then((ret) => {
      if (typeof ret === "string" || !ret) {
        setCompany([]);
        store.setLoading(false);
        return;
      }
      comp = (ret);
      actDetailsArray = [];
      if (comp.Actual[0]) {
        for (let key in comp.Actual[0].ActualsDetails) {
          actingDetails = {
            Type: key.replace("Details", ""),
            Details: comp.Actual[0].ActualsDetails[key]
          }
          actDetailsArray.push(actingDetails);
        }
        for (let i = 0; i < actDetailsArray.length; i++) {
          for (let j = 0; j < actDetailsArray[i].Details.length; j++) {
            finalDetails = {
              Type: actDetailsArray[i].Type,
              Details: actDetailsArray[i].Details[j]
            }
            finalDetailsArray.push(finalDetails);
          }
        }
      }
      setActualDetails(finalDetailsArray);
      if (comp.Actual) {
        actuals = comp.Actual;
        let tmpList = [];

        if (comp.Actual.length !== 0) {
          tmpList = comp.Config
          matrixList = [];
          matricTotals = [];

          let filteredAct = Object.keys(ret.Actual[0]).filter(item => item !== 'Date' && item !== 'ActualsDetails')
          for (let j = 0; j < filteredAct.length; j++) {
            for (let k = 0; k < Object.keys(ret.Actual[0]).length; k++) {
              if (filteredAct[j] === Object.keys(ret.Actual[0])[k]) {
                matricTotals.push(Object.values(ret.Actual[0])[k])
                matrixList.push(Object.keys(ret.Actual[0])[k])
              }
            }
          }
        };

        if (comp.Actual.length > 0) {
          setSelectedDate(comp.Actual[0].Date)
        }
        setNewActual(actuals);
        setMatrixList(matrixList);
        setMatrixTotals(matricTotals);
        setCompany(comp);
        forceUpdate();
        store.setLoading(false);
      }
      store.setLoading(false);
    });
  }
  function updateAcutalsClicked(e) {
    let data = {};
    let newAct = NewActual

    for (let i = 0; i < newAct.length; i++) {
      if (new Date(SeletedDate).toDateString() === new Date(newAct[i].Date).toDateString()) {
        if (e.currentTarget.id === "editActual") {
          data = {
            Actual: newAct
          };
        }
      }
    }
    store.setLoading(true);
    store.updateCompanyById(SelectedCompanyId, data).then((retObj) => {
      if (retObj) {
        window.alert("Company updated Successfully..!");
        setNewActual([]);
        store.setLoading(false);
      } else {
        window.alert("Company updated Unsuccessfully..!");
        store.setLoading(false);
      }
    });
    handleClear();
  }
  function handleClear() {
    setNewActual();
    forceUpdate();
  }
  function toggleTable(e) {
    let tmpID = ToggleTableID;
    let toggleTableID = MatrixList.filter(item => item === e.currentTarget.id);
    if (toggleTableID) {
      tmpID = toggleTableID;
      setToggleTable(!ToggleTable)

      if (ToggleTable === false) {
        tmpID = ''
      }
    };
    setToggleTableID(tmpID);
    forceUpdate();
  }
  function deleteActualDetails(e) {
    let matrixTotals = MatrixTotals;
    let actDetailsArray = ActualDetails;
    let newAct = [];
    let finalActDetailsArray = UpdatedActuals;
    let deteletedMatric = actDetailsArray[e.currentTarget.id].Type
    let MatricValueToDel = actDetailsArray[e.currentTarget.id].Details.Amount

    newAct = Company.Actual.filter(item => item.Date === SeletedDate);

    for (let k = 0; k < newAct.length; k++) {
      let objNewAct = newAct[k]
      for (let i = 0; i < actDetailsArray.length; i++) {
        if (!actDetailsArray[i]) {
        } else {
          if (actDetailsArray[i].Type === deteletedMatric) {
            matrixTotals[MatrixList.indexOf(deteletedMatric)] -= MatricValueToDel;
            let total = matrixTotals[MatrixList.indexOf(deteletedMatric)]
            objNewAct[deteletedMatric] = total;

            for (let j = 0; j < (objNewAct.ActualsDetails[deteletedMatric.concat('Details')].length); j++) {
              if (objNewAct.ActualsDetails[deteletedMatric.concat('Details')][j]) {
                if (objNewAct.ActualsDetails[deteletedMatric.concat('Details')][j].Amount === MatricValueToDel) {
                  delete objNewAct.ActualsDetails[deteletedMatric.concat('Details')][j]
                  for (const key in objNewAct.ActualsDetails) {
                    if (Array.isArray(objNewAct.ActualsDetails[key])) {
                      objNewAct.ActualsDetails[key] = objNewAct.ActualsDetails[key].filter(item => item !== undefined);
                    }
                  }
                }
              }
            }
            break
          }
        }
      }
      delete actDetailsArray[e.currentTarget.id];
      Company.Actual[k] = objNewAct
    }

    setNewActual(Company.Actual);
    setUpdatedActuals(finalActDetailsArray);
    setMatrixTotals(matrixTotals);
    forceUpdate();
  }
  function addActualDetails(e) {
    let actDetails = {};
    let finalDetails = {};
    let matrixTotals = MatrixTotals;
    let actDetailsArray = ActualDetails;
    let finalActDetailsArray = UpdatedActuals;

    if (ActualDetailsObject.Category === '') {
      window.alert('Please provide a Category');
      return;
    } else {
      actDetails = ActualDetailsObject;
      finalDetails = {
        Type: e.currentTarget.id,
        Details: actDetails,
      };
      let newAct = Company.Actual.filter(item => item.Date === SeletedDate);

      for (let k = 0; k < newAct.length; k++) {
        let objNewAct = newAct[k];
        for (let i = 0; i < actDetailsArray.length; i++) {
          if (!actDetailsArray[i]) {
            continue;
          } else {
            if (actDetailsArray[i].Type === e.currentTarget.id) {
              matrixTotals[MatrixList.indexOf(e.currentTarget.id)] += actDetails.Amount;
              let total = matrixTotals[MatrixList.indexOf(e.currentTarget.id)];
              objNewAct[e.currentTarget.id] = total;
              let detailsArray = objNewAct.ActualsDetails[e.currentTarget.id + 'Details'];
              if (!detailsArray) {
                detailsArray = [];
              }
              detailsArray.push(actDetails);
              objNewAct.ActualsDetails[e.currentTarget.id + 'Details'] = detailsArray;

            } else {
              actDetailsArray.push(finalDetails);
              matrixTotals[MatrixList.indexOf(e.currentTarget.id)] += actDetails.Amount;
              let total = matrixTotals[MatrixList.indexOf(e.currentTarget.id)];
              objNewAct[e.currentTarget.id] = total;
              let detailsArray = objNewAct.ActualsDetails[e.currentTarget.id + 'Details'];
              if (!detailsArray) {
                detailsArray = [];
              }
              detailsArray.push(actDetails);
              objNewAct.ActualsDetails[e.currentTarget.id + 'Details'] = detailsArray;
              break;
            }
          }
        }
        Company.Actual[k] = objNewAct;
      }

      actDetailsArray.push(finalDetails);
    }
    setNewActual(Company.Actual)
    setMatrixTotals(matrixTotals);
    setActualDetails(actDetailsArray);
    setUpdatedActuals(finalActDetailsArray);
    setActualDetailsObject({
      Category: '',
      Amount: 0,
    });
    forceUpdate();
  }
  function getActualDetails(e) {
    let actDetailsObj = ActualDetailsObject;
    let typeidCat = MatrixList.filter(item => item.concat('category') === e.currentTarget.id)
    let typeidAmt = MatrixList.filter(item => item.concat('amount') === e.currentTarget.id)

    if (e.target.id === typeidCat + 'category') {
      actDetailsObj.Category = e.target.value;
    }
    if (e.currentTarget.id === typeidAmt + 'amount') {
      actDetailsObj.Amount = Number(e.target.value);
    }

    setActualDetailsObject(actDetailsObj);
    forceUpdate();
  }

  if (isMobile()) {
    return (
      <div>
        <div className='updateTargetsPage'>
          <h1 className="targetHeader">Update Actual Values</h1>
          <div>
            {(Company.Actual) ?
              <>
                <div className='selectUpdateDateDiv'>
                  <p><b>Select Date : </b></p>
                  <select id='selectDate' className='selectElemen' onChange={dateSelector} >
                    {
                      Company.Actual.map((act, index) => {
                        if (act.Date) {
                          return (
                            <option key={index} value={act.Date}>
                              {
                                new Date(act.Date).toDateString()
                              }
                            </option>
                          )
                        }

                      })
                    }
                  </select>
                </div>
                <div>
                  {(MatrixList).map((type, index) => {
                    if (type) {
                      if (type === 'Date') {
                      } else {
                        return (
                          <div className='tableDiv'>
                            <p className='tablePtag'>
                              <b style={{ width: '70%' }}>{type}</b>
                              <b className='totalStyle'>Total : {MatrixTotals[index]}</b>
                              <div className='FaAngleDownDiv' onClick={toggleTable} id={type} value={index}>
                                {(ToggleTableID[0] !== type) ?
                                  <FaAngleDown></FaAngleDown> :
                                  <FaAngleUp></FaAngleUp>}
                              </div>
                            </p>
                            {(ToggleTableID[0] === type) ?
                              <table className="creditsTable">
                                <thead className="creditsTableHeader">
                                  <td className="creditsTableCol">Category</td>
                                  <td className="creditsTableCol">Amount</td>
                                </thead>
                                <tr className="creditsTableHeader">
                                  <td className="creditsTableCol">
                                    <Input type="text" className="creditSelect" id={MatrixList[index].concat("category")} onChange={getActualDetails} value={ActualDetailsObject.Category} > </Input>
                                  </td>
                                  <td className="creditsTableCol">
                                    <Input type="number" className="creditSelect" id={MatrixList[index].concat("amount")} onChange={getActualDetails} value={ActualDetailsObject.Amount}  > </Input>
                                  </td>

                                  <td className="creditsTableCol">
                                    <Button id={type} style={{ width: "90%" }} onClick={addActualDetails}>Add Data</Button>
                                  </td>
                                </tr>
                                {ActualDetails.map((actArr, index2) => {
                                  if (actArr.Type === MatrixList[index]) {
                                    if (actArr.Details) {
                                      return (
                                        <tr className="creditsTableHeader">
                                          <td className="creditsTableCol">
                                            {
                                              actArr.Details.Category
                                            } </td>
                                          <td className="creditsTableCol">
                                            {
                                              actArr.Details.Amount
                                            }</td>
                                          <td className="creditsTableCol">
                                            <Button id={index2} style={{ width: "90%" }} onClick={deleteActualDetails}> Delete Row</Button>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  }
                                })}

                              </table>
                              : null}
                          </div>
                        )
                      }

                    }

                  })
                  }
                </div>
              </> :
              <Card>No Available Actuals for {Company.Name}</Card>
            }

          </div>

          <div className="elementStylingForButtons">
            <Button onClick={handleClear}>Cancel</Button>
            <Button id='editActual' onClick={updateAcutalsClicked}>Update Actuals</Button>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <div className='updateTargetsPage'>
          <h1 className="targetHeader">Update Actual Values</h1>
          <div>
            {(Company.Actual) ?
              <>
                <div className='selectUpdateDateDiv'>
                  <p><b>Select Date : </b></p>
                  <select id='selectDate' className='selectElemen' onChange={dateSelector} >
                    {
                      Company.Actual.map((act, index) => {
                        if (act.Date) {
                          return (
                            <option key={index} value={act.Date}>
                              {
                                new Date(act.Date).toDateString()
                              }
                            </option>
                          )
                        }

                      })
                    }
                  </select>
                </div>
                <div>
                  {(MatrixList).map((type, index) => {
                    if (type) {
                      if (type === 'Date') {
                      } else {
                        return (
                          <div className='tableDiv'>
                            <p className='tablePtag'>
                              <b style={{ width: '70%' }}>{type}</b>
                              <b className='totalStyle'>Total : {MatrixTotals[index]}</b>
                              <div className='FaAngleDownDiv' onClick={toggleTable} id={type} value={index}>
                                {(ToggleTableID[0] !== type) ?
                                  <FaAngleDown></FaAngleDown> :
                                  <FaAngleUp></FaAngleUp>}
                              </div>
                            </p>
                            {(ToggleTableID[0] === type) ?
                              <table className="creditsTable">
                                <thead className="creditsTableHeader">
                                  <td className="creditsTableCol">Category</td>
                                  <td className="creditsTableCol">Amount</td>
                                </thead>
                                <tr className="creditsTableHeader">
                                  <td className="creditsTableCol">
                                    <Input type="text" className="creditSelect" id={MatrixList[index].concat("category")} onChange={getActualDetails} value={ActualDetailsObject.Category} > </Input>
                                  </td>
                                  <td className="creditsTableCol">
                                    <Input type="number" className="creditSelect" id={MatrixList[index].concat("amount")} onChange={getActualDetails} value={ActualDetailsObject.Amount}  > </Input>
                                  </td>

                                  <td className="creditsTableCol">
                                    <Button id={type} style={{ width: "90%" }} onClick={addActualDetails}>Add Data</Button>
                                  </td>
                                </tr>
                                {ActualDetails.map((actArr, index2) => {
                                  if (actArr.Type === MatrixList[index]) {
                                    if (actArr.Details) {
                                      return (
                                        <tr className="creditsTableHeader">
                                          <td className="creditsTableCol">
                                            {
                                              actArr.Details.Category
                                            } </td>
                                          <td className="creditsTableCol">
                                            {
                                              actArr.Details.Amount
                                            }</td>
                                          <td className="creditsTableCol">
                                            <Button id={index2} style={{ width: "90%" }} onClick={deleteActualDetails}> Delete Row</Button>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  }
                                })}

                              </table>
                              : null}
                          </div>
                        )
                      }

                    }

                  })
                  }
                </div>
              </> :
              <Card>No Available Actuals for {Company.Name}</Card>
            }

          </div>

          <div className="elementStylingForButtons">
            <Button onClick={handleClear}>Cancel</Button>
            <Button id='editActual' onClick={updateAcutalsClicked}>Update Actuals</Button>
          </div>
        </div>
      </div>

    )
  }
})
export default UpdateActuals;