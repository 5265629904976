import React from 'react';
import './style.css';

function View({children, className, id, style={}}){
    let customStyle = style;
    customStyle.height = `${window.innerHeight}px`;
    customStyle.width = `${window.innerWidth}px`;
    return (
        <div className={`view ${className}`} id={`view ${id}`} style={customStyle}>
            {children}
        </div>
    )
}

export default View