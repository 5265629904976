
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { useNavigate } from 'react-router-dom';
import './style.css';
import { Input, View, Button, Card } from '../../components';
import AionsIcon from '../../assets/images/Aions.svg';
var mobile = require('is-mobile');

const Login = observer((data) => {
    const store = useStore();
    const navigate = useNavigate();
    const [Email, setEmail] = useState('');
    const [Password, setPassword] = useState('');
    const [Error, setError] = useState('');
    const [ForgotPassword, setForgotPassword] = useState(false);
    const [LogInViaUseEff, setLogInViaUseEff] = useState(null);
    useEffect(() => {
        let passworddata = {
            Password: data.password
        };
        if (data.password === '' || data.email === '') {
            return (
                <div style={{ minHeigh: "100vh", width: '100vw', display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                    <h2>Something went wrong</h2>
                </div>
            );
        } else {
            setLogInViaUseEff(true);
            store.login(data.email, passworddata).then((ret) => {
                if (ret === true) {
                    store.getUserByEmail(data.email).then((foundUser) => {
                        if (foundUser.Role === "Company") {
                            navigate('/investee');
                        } else if (foundUser.Role === "Design" || foundUser.Role === "Dev") {
                            navigate('/act');
                        } else if (foundUser.Role === "Admin") {
                            navigate('/adminDashboard');
                        } else if (foundUser.Role === 'Handler') {
                            navigate('/handlerDashboard');
                        } else {
                            navigate('/signin');
                        };
                        store.setLoading(false);
                    });
                };
            });
        };
    }, []);

    function emailChanged(e) {
        setEmail(e.target.value);
    }
    function passwordChanged(e) {
        setPassword(e.target.value);
    }
    function loginClicked() {
        if (Email === '') {
            setError('Please enter your email.');
            return
        }
        if (Password === '') {
            setError('Please enter your password.');
            return
        }
        let data = {
            Password: Password
        }
        store.setLoading(true);
        store.login(Email, data).then((ret) => {
            if (ret === 'Blocked') {
                setError('User is blocked');
                store.setLoading(false);
                return
            }
            if (ret === 'not found') {
                setError('User is not found');
                store.setLoading(false);
                return
            }
            if (ret === 'Authentication Failed') {
                setError('Authentication Failed');
                store.setLoading(false);
                return
            }
            if (ret === true) {
                navigate('/dashboard')
            }
        })
    }
    function forgotPasswordClicked() {
        setForgotPassword(true);
    }
    if (LogInViaUseEff !== true) {
        if (mobile()) {
            return (

                <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                    <img className="Images" src={AionsIcon} />
                    <h1>Login</h1>
                    <Input placeholder='Email' value={Email} type='text' onChange={emailChanged} style={{ width: '250px' }} />
                    <Input placeholder='Password' type='password' value={Password} style={{ width: '250px', marginBottom: '3px' }} onChange={passwordChanged} onKeyDown={(e) => { if (e && e.key && e.key === 'Enter') { e.preventDefault(); loginClicked() } }} />
                    <p style={{ marginTop: '2px', cursor: "pointer" }} onClick={forgotPasswordClicked}>Forgot Password</p>
                    {(Error === '') ? null : <p className='formError'>{Error}</p>}
                    <Button className='buttonStyle' onClick={loginClicked}>Login</Button>

                </View>
            );

        } else {
            return (
                <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                    <img className="Images" src={AionsIcon} />
                    <h1>Login</h1>
                    <Input placeholder='Email' value={Email} type='text' onChange={emailChanged} style={{ width: '500px' }} />
                    <Input placeholder='Password' type='password' value={Password} style={{ width: '500px', marginBottom: '5px' }} onChange={passwordChanged} onKeyDown={(e) => { if (e && e.key && e.key === 'Enter') { e.preventDefault(); loginClicked() } }} />
                    <p style={{ marginTop: '2px', cursor: "pointer" }} onClick={forgotPasswordClicked}>Forgot Password</p>
                    {(Error === '') ? null : <p className='formError'>{Error}</p>}
                    <Button className='buttonStyle' onClick={loginClicked}>Login</Button>
                    {/* {ForgotPassword ? (
                            <Card className="forgotPasswordCard">
                            <div>
                              <h1>Forgot Password</h1>  
                            </div>
                            <div className='forgotPasswordBody'>
        
                            </div>
                        </Card>
                        ) : null} */}
                </View>
            )
        }
    } else {
        return null
    };
})

export default Login;