import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import './style.css'
import { useNavigate } from 'react-router-dom';
import { Card, Input, View, Button } from '../../components';
var mobile = require('is-mobile');

const OkrSessions = observer(() => {
    //#region UseState
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [selectedSession, setSelectedSession] = useState('');
    const [AllSessions, setAllSessions] = useState(true);
    const [OkrSession, setOkrSession] = useState({
        Name: "",
        Description: "",
        StartDate: new Date,
        EndDate: new Date,
    });
    const [CreateSession, setCreateSession] = useState(false);
    const [ViewSession, setViewSession] = useState(false);
    const [Sessions, setSessions] = useState([]);
    const [ViewSelectedSession, setViewSelectedSession] = useState({})
    //#endregion


    //#region functions
    useEffect(() => {
        loadSessions();
    }, []);

    function loadSessions() {
        store.setLoading(true);
        store.getOkrSessions().then((ret) => {
            if (ret) {
                setSessions(ret);
                store.setLoading(false);
            } else {
                store.setLoading(false);
            }
        })
        forceUpdate();
    }
    function cancelClicked() {
        setCreateSession(false);
        setAllSessions(true);
    }
    function formatDate(dateString) {
        const options = { day: "numeric", month: "short", year: "numeric" };
        const dateObj = new Date(dateString);
        return dateObj.toLocaleDateString("en-US", options);
    }
    function viewSessionClicked(sessionId) {
        let id = sessionId;
        store.setLoading(true);
        store.getOKrSessionByID(id).then((ret) => {
            if (!ret) {
                setViewSelectedSession(ret);
                setAllSessions(false);
                store.setLoading(false);
                forceUpdate()
            } else {
                setAllSessions(true);
                store.setLoading(false);
            }
        })
        forceUpdate();
    }
    function clearData() {
        setOkrSession({
            Name: "",
            Description: "",
            StartDate: new Date,
            EndDate: new Date,
        });
    }
    function submitSession() {
        if (selectedSession) {
            const data = {
                ...OkrSession,
                ParentID: selectedSession,
            };

            store.setLoading(true);
            store.createOkrSession(data).then((ret) => {
                if (ret) {
                    window.alert("OKR session created!");
                    store.setLoading(false);
                    clearData();
                } else {
                    store.setLoading(false);
                }
            });
        } else {
           return
        }
    }
    function createSessionChange(e) {
        let session = OkrSession

        if (e.currentTarget.id === "Name") {
            session.Name = e.currentTarget.value;
        }
        if (e.currentTarget.id === "Description") {
            session.Description = e.currentTarget.value;
        }
        if (e.currentTarget.id === "StartDate") {
            session.StartDate = e.currentTarget.value;
        }
        if (e.currentTarget.id === "EndDate") {
            session.EndDate = e.currentTarget.value;
        }
        setOkrSession(session);
        forceUpdate();
    }
    function createSessionClicked() {
        setCreateSession(true);
        setAllSessions(false);
    }

    //#endregion
    if (mobile()) {
        return (
            <div>
                {AllSessions ? (
                    <>
                        <Button className="newCourseButton" onClick={createSessionClicked}>New Session</Button>
                        <Card className='descriptionCard'>
                            <p className='descriptionCardLbalel'>Session Name</p>
                            <p className='descriptionCardLbalel'>Start Date</p>
                            <p className='descriptionCardLbalel'>End Date</p>
                        </Card>
                        <Card style={{ width: "97%" }} className='newCourseMapMainCard'>
                            {Sessions && Sessions.length > 0 ? (
                                Sessions.map((sessions, index) => (
                                    <Card className='newCourseMapInnerCard' key={sessions._id}>
                                        <div className='descriptionCardLbalel'>
                                            <p>{sessions.Name}</p>
                                        </div>
                                        <div className='descriptionCardLbalel'>
                                            <div>{formatDate(sessions.StartDate)}</div>
                                        </div>
                                        <div className='descriptionCardLbalel'>
                                            <div>{formatDate(sessions.EndDate)}</div>
                                        </div>
                                        <div className='courseEditButtonDiv'>
                                            <Button className='courseEditButton'>View</Button>
                                        </div>
                                    </Card>
                                ))
                            ) : (
                                <Card><h1>No available courses!</h1></Card>
                            )}
                        </Card>
                    </>
                ) : null}

                {CreateSession ? (
                    <Card className="createSession">
                        <h2>Create session</h2>
                        <div className='sessionCardStyling'>
                            <p><b>Name : </b></p>
                            <Input id="Name" placeholder="Give a name to this session" onChange={createSessionChange} />
                        </div>
                        <div className='sessionCardStyling'>
                            <p><b>Description : </b></p>
                            <textarea className='descriptionField' id="Description" placeholder="Give a description to this session" onChange={createSessionChange} />
                        </div>
                        <div className='sessionCardStyling'>
                            <p><b>Start date : </b></p>
                            <Input id="StartDate" type="date" onChange={createSessionChange} />
                        </div>
                        <div className='sessionCardStyling'>
                            <p><b>End date :</b></p>
                            <Input id="EndDate" type="date" onChange={createSessionChange} />
                        </div>
                        <div className='sessionCardStyling'>
                            <p><b>Parent session :</b></p>
                            <select className='parentSelect' onChange={event => setSelectedSession(event.target.value)}
                                value={selectedSession}>
                                <option value="">Select parent session</option>
                                {Sessions.map((session) => (
                                    <option key={session._id} value={session._id}>
                                        {session.Name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div style={{ display: "flex", width: "80%", marginLeft: "30%" }}>
                            <Button onClick={submitSession}>Create session</Button>
                            <Button style={{ marginLeft: "2%" }} onClick={cancelClicked}>Cancel</Button>
                        </div>
                    </Card>
                ) : null}
            </div>
        );
    } else {
        return (
            <div>
                {AllSessions ? (
                    <>
                        <Button className="newCourseButton" onClick={createSessionClicked}>New Session</Button>
                        <Card className='descriptionCard'>
                            <p className='descriptionCardLbalel'>Session Name</p>
                            <p className='descriptionCardLbalel'>Start Date</p>
                            <p className='descriptionCardLbalel'>End Date</p>
                        </Card>
                        <Card style={{ width: "97%" }} className='newCourseMapMainCard'>
                            {Sessions && Sessions.length > 0 ? (
                                Sessions.map((sessions, index) => (
                                    <Card className='newCourseMapInnerCard' key={sessions._id}>
                                        <div className='descriptionCardLbalel'>
                                            <p>{sessions.Name}</p>
                                        </div>
                                        <div className='descriptionCardLbalel'>
                                            <div>{formatDate(sessions.StartDate)}</div>
                                        </div>
                                        <div className='descriptionCardLbalel'>
                                            <div>{formatDate(sessions.EndDate)}</div>
                                        </div>
                                        <div className='courseEditButtonDiv'>
                                            <Button className='courseEditButton' onClick={() => viewSessionClicked(sessions._id)}>View</Button>
                                        </div>
                                    </Card>
                                ))
                            ) : (
                                <Card><h1>No available courses!</h1></Card>
                            )}
                        </Card>
                    </>
                ) : null}

                {CreateSession ? (
                    <Card className="createSession">
                        <h2>Create session</h2>
                        <div className='sessionCardStyling'>
                            <p><b>Name : </b></p>
                            <Input id="Name" placeholder="Give a name to this session" onChange={createSessionChange} />
                        </div>
                        <div className='sessionCardStyling'>
                            <p><b>Description : </b></p>
                            <textarea className='descriptionField' id="Description" placeholder="Give a description to this session" onChange={createSessionChange} />
                        </div>
                        <div className='sessionCardStyling'>
                            <p><b>Start date : </b></p>
                            <Input id="StartDate" type="date" onChange={createSessionChange} />
                        </div>
                        <div className='sessionCardStyling'>
                            <p><b>End date :</b></p>
                            <Input id="EndDate" type="date" onChange={createSessionChange} />
                        </div>
                        <div className='sessionCardStyling'>
                            <p><b>Parent session :</b></p>
                            <select className='parentSelect' onChange={event => setSelectedSession(event.target.value)}
                                value={selectedSession}>
                                <option value="">Select parent session</option>
                                {Sessions.map((session) => (
                                    <option key={session._id} value={session._id}>
                                        {session.Name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div style={{ display: "flex", width: "80%", marginLeft: "30%" }}>
                            <Button onClick={submitSession}>Create session</Button>
                            <Button style={{ marginLeft: "2%" }} onClick={cancelClicked}>Cancel</Button>
                        </div>
                    </Card>
                ) : null}

                {ViewSession ? (
                    <div>

                    </div>
                ) : null}
            </div>
        );
    }
});

export default OkrSessions;