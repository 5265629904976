import React from 'react';
import './style.css';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    registerables
  } from 'chart.js';
  import { Line } from "react-chartjs-2";
  ChartJS.register(...registerables);
function AEXGraph({aions=[], forecast=[], offset=0, type}){
    // #region Dates
    let actualArray = [];
    let opexSet = new Set();
    let alkemiaSet = new Set();
    let forecastArray = [];
    for (let m = 0; m < 6; m++){
        let date = new Date();
        let month = date.getMonth();
        let year = date.getFullYear();
        month = month - m;
        if (month < 0){
            month = month + 12;
            year = year - 1;
        }
        date.setMonth(month);
        date.setFullYear(year);
        let startDate = new Date(date);
        startDate.setDate(1);
        let endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() + 1);
        endDate.setDate(0);
        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);
        // #endregion
        let currentData = aions.filter((a) => (new Date(a.Date)) >= startDate && (new Date(a.Date)) <= endDate);
        let currentforecast = forecast.filter((a) => (new Date(a.Date)) >= startDate && (new Date(a.Date)) <= endDate);
        let finalData = {
            Income: {
                Total: 0,
                AEX: 0,
                Interest: 0,
                Alkemia: 0,
                AlkemiaDetail: []
            },
            COS: {
                Total: 0,
                Alkemia: 0,
                AEX: 0
            },
            Telkom: {
                Drawdown: 0
            },
            OPEX: {
                Total: 0,
                AEX: 0,
                AEXDetail: [],
                Alkemia: 0,
                AlkemiaDetail: [],
                Aions: 0,
                AionsDetail: []
            },
            Date: `${startDate.getMonth() + 1}/${startDate.getFullYear()}`
        }

        let finalTarget = {
          Income: {
              Total: 0,
              AEX: 0,
              Interest: 0,
              Alkemia: 0,
          },
          COS: {
              Total: 0,
              Alkemia: 0,
              AEX: 0
          },
          Telkom: {
              Drawdown: 0
          },
          OPEX: {
              Total: 0,
              AEX: 0,
              Alkemia: 0,
              Aions: 0
          }
      }

        for (let i = 0; i < currentData.length; i++){
            let cData = currentData[i];
            //Income
            let cIncome = cData.Income
            let tempTotal = 0;
            tempTotal += cIncome.AEX;
            finalData.Income.AEX += cIncome.AEX;
            tempTotal += cIncome.Interest;
            finalData.Income.Interest += cIncome.Interest;
            for (let j = 0; j < cIncome.Alkemia.length; j++){
                let cAlkemiaIncome = cIncome.Alkemia[j];
                alkemiaSet.add(cAlkemiaIncome.Client);
                tempTotal += cAlkemiaIncome.Amount;
                finalData.Income.Alkemia += cAlkemiaIncome.Amount
                let alkIndex = finalData.Income.AlkemiaDetail.findIndex(obj => obj.Client === cAlkemiaIncome.Client);
                if (alkIndex !== -1){
                    finalData.Income.AlkemiaDetail[alkIndex].Amount += cAlkemiaIncome.Amount
                }else{
                    finalData.Income.AlkemiaDetail.push(cAlkemiaIncome);
                }
            }
            finalData.Income.Total += tempTotal
            //COS
            let cCOS = cData.COS;
            finalData.COS.Total += cCOS.AEX;
            finalData.COS.AEX += cCOS.AEX;
            finalData.COS.Total += cCOS.Alkemia;
            finalData.COS.Alkemia += cCOS.Alkemia;
            //Telkom
            finalData.Telkom.Drawdown += cData.Telkom.Amount
            //OPEX
            let cOPEX = cData.OPEX
            let tOpexTotal = 0;
            for (let k = 0; k < cOPEX.Aions.length; k++){
                let tOpex = cOPEX.Aions[k];
                opexSet.add(tOpex.Category);
                tOpexTotal += tOpex.Amount;
                let opexIndex = finalData.OPEX.AionsDetail.findIndex(obj => obj.Category === tOpex.Category);
                if (opexIndex !== -1){
                    finalData.OPEX.AionsDetail[opexIndex].Amount += tOpex.Amount
                }else{
                    finalData.OPEX.AionsDetail.push(tOpex)
                }
            }
            finalData.OPEX.Total += tOpexTotal;
            finalData.OPEX.Aions += tOpexTotal;
        }
        actualArray.unshift(finalData);
        for (let j = 0; j < currentforecast.length; j++){
          let cforecast = currentforecast[j];
          // Income
          let cIncome = cforecast.Income;
          let tmpIncFor = 0;
          finalTarget.Income.AEX += cIncome.AEX;
          tmpIncFor += cIncome.AEX;
          finalTarget.Income.Alkemia += cIncome.Alkemia;
          tmpIncFor += cIncome.Alkemia;
          finalTarget.Income.Interest += cIncome.Interest;
          tmpIncFor += cIncome.Interest;
          finalTarget.Income.Total += tmpIncFor;
          // COS
          let cCos = cforecast.COS;
          let tmpCOSFor = 0;
          finalTarget.COS.AEX += cCos.AEX;
          tmpCOSFor += cCos.AEX;
          finalTarget.COS.Alkemia += cCos.Alkemia;
          tmpCOSFor += cCos.Alkemia;
          finalTarget.COS.Total += tmpCOSFor;
          // Telkom
          finalTarget.Telkom.Drawdown += cforecast.Telkom.Amount;
          // OPEX
          finalTarget.OPEX.Total += cforecast.OPEX.Aions;
          finalTarget.OPEX.Aions += cforecast.OPEX.Aions;
        }
        forecastArray.unshift(finalTarget);
    }

    let dataArray = [];
    let forecastDataArray = [];
    let labels = [];
    for (let i = 0; i < actualArray.length; i++){
        let currentActObj = actualArray[i];
        let currentForObj = forecastArray[i]
        if (type === 'Income'){
            dataArray.push(currentActObj.Income.AEX);
            forecastDataArray.push(currentForObj.Income.AEX);
        }
        if (type === 'COS'){
            dataArray.push(currentActObj.COS.AEX);
            forecastDataArray.push(currentForObj.COS.AEX);
        }
        labels.push(currentActObj.Date);
    }

    const data = {
        labels: labels,
        datasets: [
          {
            label: 'Actual',
            data: dataArray,
            fill: false,
            borderColor: 'rgba(143,202,114,1)',
            tension: 0.1,
            pointRadius: 6,
            color: "white",
          },
          {
            label: 'Target',
            data: forecastDataArray,
            fill: false,
            borderColor: 'rgb(255, 0, 166)',
            borderDash: [10, 10], 
            tension: 0.1,
            backgroundColor: "black",
            pointRadius: 3,
            color: "white",
          },
        ],
      };
      const options = {
        scales: {
          x: {
            ticks: {
              color: "white",
            },
            grid: {
              color: "transparent",
            },
          },
          y: {
            border: {dash: [10, 10]}, 
            ticks: {
              color: "white",
            },
            grid: {
              color: "grey",
              
            },
          },
        },
        backgroundColor: "white",
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          fill: true,
          title: {
            display: true,
            text: type,
            color: "lightgrey",
          },
          datalabels: {
            display: false,
        },
        },
      };
    

    return(
        <Line  data={data} options={options} />
    )
}

export default AEXGraph;