import React from 'react';
import './style.scss';
import Icon from '../../assets/images/AionsDashIcon.svg';
import miniIcon from '../../assets/images/AionsDashMini.svg';

function DepartmentSidebar({ Active, onMouseEnter, onMouseLeave, pageClicked, pageSelected, logout, compName}) {
    return (
        <div className={`InvesteeSidebar ${Active ? 'InvesteeSidebarActive' : ''}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={{ transition: 'width 1s ease' }}>
            <img src={Active ? Icon : miniIcon} alt=''/>
            <div className='pagesSection'>
                <div className={`availablePages ${(pageSelected === 'Alkemia' ? 'availablePagesActive' : '')}`} id='Alkemia' onClick={pageClicked}>
                    <div className='availablePageSelector'></div>
                    <p className='selectedPageFont'>{Active ? 'Alkemia' : 'A'}</p>
                </div>
                {/* <div className={`availablePages ${(pageSelected === 'Report' ? 'availablePagesActive' : '')}`} id='Report' onClick={pageClicked}>
                    <div className='availablePageSelector'></div>
                    <p className='selectedPageFont'>{Active ? 'Report' : 'R'}</p>
                </div> */}
                <div className={`availablePages ${(pageSelected === 'Logout' ? 'availablePagesActive' : '')}`} id='Logout' onClick={logout}>
                    <div className='availablePageSelector'></div>
                    <p className='selectedPageFont'>{Active ? 'Logout' : 'L'}</p>
                </div>
            </div>
        </div>
    )
}
export default DepartmentSidebar;