import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import './style.css'
import { useStore } from '../../../stores/Store';
import { useNavigate } from 'react-router-dom';
import Plus from '../../../assets/images/plus.png';
import totalclient from '../../../assets/images/totalclients.png';
import totalexpenses from '../../../assets/images/totalexpenses.png';
import totalsales from '../../../assets/images/totalsales.png';
import CloseCard from '../../../assets/images/closeCard.png';
import Logo from '../../../assets/images/levelLogo.png';
import Settings from '../../../assets/images/settings.png';
import SmileIcon from '../../../assets/images/smile.png';
import AngryIcon from '../../../assets/images/angry.png';
import UnHappyIcon from '../../../assets/images/unhappy.png';
import HappyIcon from '../../../assets/images/happy.png';
import CostOfSalesIcon from '../../../assets/images/save-money.png';
import NeutralIcon from '../../../assets/images/neutral-face.png';
import 'react-input-range/lib/css/index.css';
import { Input, View, Button, Card, Graph } from '../../../components';
import { rectangle } from 'pdf-lib';
// import { PolarArea } from 'react-chartjs-2';
var mobile = require('is-mobile');

const Overview = observer(() => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [CompaniesList, setCompaniesList] = useState([]);
    const colors = ['rgba(143,202,114,1)', 'rgba(49,192,209,1', 'rgba(146,140,195,1)', 'rgb(227, 54, 126)'];
    const [MetricsList, setMetricsList] = useState([]);
    const [Offset, setOffset] = useState(0);
    const [Company, setCompany] = useState({});
    const [Metric, setMetric] = useState('TotalSales');
    const [Actuals, setActuals] = useState([]);
    const [TimeFrame, setTimeFrame] = useState(1);
    const [TotalSales, setTotalSales] = useState(0);
    const [TotalClients, setTotalClients] = useState(0);
    const [metricColor, setMetricColor] = useState({});
    const [Config, setConfig] = useState({});
    const [TotalExpensesActuals, setTotalExpensesActuals] = useState(0);
    const [User, setUser] = useState({});
    const [Period, setPeriod] = useState("weekly");
    const [SelectedGraph, setSelectedGraph] = useState("none");
    const [ButtonValue, setButtonValue] = useState("weeklygraph");
    const [AddActuals, setAddActuals] = useState(false);
    const [Forecast, setForecast] = useState(false);
    const [CurrentDate, setCurrentDate] = useState(new Date())
    const [SelectedType, setSelectedType] = useState('');
    const [ActualsDetails, setActualsDetails] = useState({});
    const [ActualsDates, setActualsDates] = useState([]);
    const [MetricsX, setMetricsX] = useState([]);
    const [NewTarget, setNewTarget] = useState({});
    const [ExpandActualDataTable, setExpandActualDataTable] = useState(false);
    const [FinalData, setFinalData] = useState({
        ActualsDetails: {},
        Date: CurrentDate
    });
    const [ActualDetailsObject, setActualDetailsObject] = useState({
        Category: '',
        Amount: 0,
    });

    useEffect(() => {
        loadCompanies();
        let date = new Date().toLocaleDateString();
        setCurrentDate(date);
    }, []);
    function loadCompanies() {
        store.setLoading(true);
        let newTar = {};
        setUser(store.user)
        store.getCompaniesByPerms(store.user.Email).then((ret) => {
            let comp = ret.filter(item => item.Name === store.user.Company);
            if (typeof ret === "string" || !ret) {
                setCompaniesList({});
                store.setLoading(false);
                return;
            }
            if (ret.length === 0) {
                setCompaniesList({});
                store.setLoading(false);
                return;
            }

            if (comp.length === 0) {
                setCompaniesList({});
                store.setLoading(false);
                return;
            }
            newTar.Date = new Date()
            setNewTarget(newTar);
            let compActuals = comp[0].Actual;
            let dates = compActuals.map(item => item.Date);
            setActualsDates(dates);
            setCompany(comp[0]);
            setMetricsList(comp[0].Config.MetricList);
            forceUpdate();
            setConfig(comp[0].Config);
            setActuals(comp[0].Actual[comp[0].Actual.length - 1]);
            setMetricsX(comp[0].Config.MetricList);
            setConfig(comp[0].Config);
            let currDate = new Date();
            let currMonthStartDate = new Date(currDate.getFullYear(), currDate.getMonth(), 1);
            let currMonthEndDate = new Date(currDate.getFullYear(), currDate.getMonth() + 1, 0);
            const filteredActuals = comp[0].Actual.filter((actual) => {
                const actualDate = new Date(actual.Date);
                return actualDate >= currMonthStartDate && actualDate <= currMonthEndDate;
            });

            const filteredTargets = comp[0].Target.filter((target) => {
                const targetDate = new Date(target.Date);
                return targetDate >= currMonthStartDate && targetDate <= currMonthEndDate;
            });
            let totalSalesSum = 0;
            let totalClientsSum = 0;
            let totalExpensesActualsSum = 0;

            filteredActuals.forEach((actual) => {
                totalSalesSum += (actual.TotalSales || 0);
                totalClientsSum += (actual.CostOfSales || 0);
                totalExpensesActualsSum += (actual.OperatingExpenses || 0);
            });
            if (filteredTargets.length >= 1) {
                filteredTargets.sort((a, b) => new Date(b.Date) - new Date(a.Date));
                const latestTarget = filteredTargets[0];

                let totalSalesPercentage = 0;
                if (latestTarget && latestTarget.TotalSales) {
                    totalSalesPercentage = ((totalSalesSum) / (latestTarget.TotalSales)) * 100;
                    totalSalesPercentage = Math.min(totalSalesPercentage, 100);
                }
                let totalClientsPercentage = 0;
                if (latestTarget && latestTarget.CostOfSales) {
                    totalClientsPercentage = ((totalClientsSum || 0) / (latestTarget.CostOfSales || 0)) * 100;
                    totalClientsPercentage = Math.min(totalClientsPercentage, 100);
                }
                let totalExpensesPercentage = 0;
                if (latestTarget && latestTarget.OperatingExpenses) {
                    totalExpensesPercentage = ((totalExpensesActualsSum || 0) / (latestTarget.OperatingExpenses || 0)) * 100;
                    totalExpensesPercentage = Math.min(totalExpensesPercentage, 100);
                }
                setTotalSales(totalSalesPercentage);
                setTotalClients(totalClientsPercentage);
                setTotalExpensesActuals(totalExpensesPercentage);
            } else {
                setTotalSales(0);
                setTotalClients(0);
                setTotalExpensesActuals(0);
            }
            store.setLoading(false);
            forceUpdate()
        })
    }
    function getNewTargets(e) {
        const { id, value } = e.target;
        const newTar = { ...NewTarget };
        if (id === 'Date') {
            newTar.Date = value;
        } else {
            newTar[id] = Number(value);
        }
        setNewTarget(newTar);
        forceUpdate();
    }
    function AddNewTargetsClicked() {
        let id = Company._id;
        let tar = Company.Target;
        tar.push(NewTarget)
        let data = {
            Target: tar,
        };
        store.setLoading(true);
        store.updateCompanyById(id, data).then((ret) => {
            if (ret) {
                window.alert("Targets added successfully");
                handleClear();
                store.setLoading(false);
            }
        })
            .catch((error) => {
                window.alert("What are you doing")
                console.error("Error updating company:", error);
                store.setLoading(false);
            });

    }
    function timeFrameClicked(e) {
        const selectedValue = e.target.value;
        if (selectedValue === "Yearly") {
            setTimeFrame(1);
            setPeriod("monthly");
        } else if (selectedValue === "Monthly") {
            setTimeFrame(2);
            setPeriod("weekly");
        }
    }
    const viewBoxWidth = 100;
    const viewBoxHeight = 20;
    const lineWidth = viewBoxWidth * (69 / 100);

    function setSelectedMetric(metricName) {
        setMetric(metricName);
        forceUpdate();
    }
    function addActualsClicked() {
        setAddActuals(true);
    }
    function openForecastClicked() {
        setForecast(true);
    }
    function closeAddActualData() {
        setAddActuals(false);
    }
    function closeAddForecastData() {
        setForecast(false);
    }
    function closeActDetailTable() {
        setSelectedType('');
        setExpandActualDataTable(false);
    }
    function selectedMatric(e) {
        setSelectedType(e.currentTarget.id);
        setExpandActualDataTable(true)
        setActualDetailsObject({
            Category: '',
            Amount: 0
        })
        forceUpdate();
    }
    function deleteActualSpecs(e) {
        let actDetails = ActualsDetails;
        let matricToUpdate = '';

        for (let i = 0; i < Object.keys(actDetails).length; i++) {
            let tmpKey = Company.Config.MetricList.filter(item => item + 'Details' === Object.keys(actDetails)[i]);
            matricToUpdate = tmpKey[0].toString();
            let data = [];
            data = Object.values(actDetails)[i];
            if (data.length > 0) {
                for (let j = data.length - 1; j >= 0; j--) {
                    if (data[j].Category === data[e.currentTarget.id].Category && data[j].Amount === data[e.currentTarget.id].Amount) {
                        data.splice(j, 1);
                    }
                }
                Object.values(actDetails)[i] = data;
            }
        };
        setActualsDetails(actDetails);
        reCulculateMatricsTotal(actDetails, matricToUpdate);
        setExpandActualDataTable(true);
        forceUpdate();
    }
    function reCulculateMatricsTotal(data, key) {
        let updateKey = key
        let tmpFinalData = FinalData;
        let total = 0;

        if (updateKey) {
            let keyValues = Object.values(data);

            for (let i = 0; i < keyValues.length; i++) {
                for (let k = 0; k < keyValues[i].length; k++) {
                    total = Number(total + keyValues[i][k].Amount);
                }
            }
            for (let i = 0; i < Object.keys(data).length; i++) {
                tmpFinalData[updateKey] = total;
            }
            tmpFinalData.ActualsDetails = data;
        }
        setFinalData(tmpFinalData);
        forceUpdate();
    }
    function splitMetricName(metricName) {
        return metricName
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/\b\w/g, (match) => match.toUpperCase());
    }
    function addActualSpecs(e) {
        let actDetailsObj = ActualDetailsObject;
        let typeidCat = Company.Config.MetricList.filter(item => item.concat('category') === e.currentTarget.id)
        let typeidAmt = Company.Config.MetricList.filter(item => item.concat('amount') === e.currentTarget.id)

        if (e.target.id === typeidCat + 'category') {
            actDetailsObj.Category = e.target.value;
        }
        if (e.currentTarget.id === typeidAmt + 'amount') {
            actDetailsObj.Amount = Number(e.target.value);
        }
        setActualDetailsObject(actDetailsObj);
        forceUpdate();
        return
    }
    function handleClear() {
        setActualDetailsObject({
            Category: '',
            Amount: 0,
        });
    }
    function addActualSpecsClick(e) {
        let matricToUpdate = Company.Config.MetricList.filter(item => item === e.currentTarget.id);

        if (ActualDetailsObject.Category === '') {
            window.alert('Please input category .!')
            return
        }

    }
    function getMatricsTotals(data, category) {
        let updateKey = category.toString() + 'Details';
        let tmpFinalData = { ...FinalData };

        if (data && data[updateKey]) {
            let keyValues = Object.values(data[updateKey]);
            let total = 0;

            for (let i = 0; i < keyValues.length; i++) {
                total += keyValues[i].Amount;
            }
            tmpFinalData[category] = total;
            tmpFinalData.ActualsDetails = data;

        }
        setFinalData(tmpFinalData);
        forceUpdate();
    }
    function handleSubmit() {
        const savedActuals = Company.Actual || [];
        const dateToCheck = FinalData.Date;

        const dateExist = ActualsDates.some((actualDate) => {
            return actualDate === dateToCheck;
        });
        if (dateExist) {
            window.alert('Actuals for this date already exist. Update Actuals for this date in the Previous Actuals page.');
            return;
        }
        if (FinalData.Date === '') {
            window.alert('Please enter a date.');
        } else {
            const date = new Date(FinalData.Date);
            if (date.getDay() !== 1) {
                window.alert('Date should be a Monday.');
                return;
            }
        }
        savedActuals.push(FinalData);
        let data = {
            Actual: savedActuals,
        };
        let name = Company.Name;
        store.setLoading(true);
        store.updateCompaniesByName(data, name).then((ret) => {
            if (ret) {
                window.alert('Data saved.');
                setAddActuals(false);
                store.setLoading(false);
            } else {
                window.alert('Failed to save data, please contact the dev team.');
                store.setLoading(false);
            }
        });
        handleClear();
    }

    // function handleSubmit() {
    //     const savedActuals = Company.Actual || [];
    //     savedActuals.push(FinalData);
    //     if (FinalData.Date === '') {
    //         window.alert('Please enter date');
    //     }
    //     let data = {
    //         Actual: savedActuals,
    //     };
    //     let name = Company.Name;
    //     store.setLoading(true);
    //     store.updateCompaniesByName(data, name).then((ret) => {
    //         if (ret) {
    //             window.alert("Data saved.");
    //             store.setLoading(false);
    //         } else {
    //             window.alert("Failed to save data,please contact dev team.!");
    //             store.setLoading(false);
    //         }
    //     });
    //     handleClear();
    // }
    function handleInputChange(category, configValue, amount, index) {
        const matricToUpdate = Company.Config.MetricList.filter(item => item === category);

        if (matricToUpdate[0]?.toString()) {
            const actDetails = { ...ActualsDetails };
            const categoryDetails = actDetails[category + 'Details'];

            if (categoryDetails) {
                let updated = false;
                for (let i = 0; i < categoryDetails.length; i++) {
                    if (categoryDetails[i].Category === configValue) {
                        categoryDetails[i].Amount = parseFloat(amount);
                        updated = true;
                        break;
                    }
                }

                if (!updated) {
                    categoryDetails.push({
                        Category: configValue,
                        Amount: parseFloat(amount)
                    });
                }
            } else {
                actDetails[category + 'Details'] = [{
                    Category: configValue,
                    Amount: parseFloat(amount)
                }];
            }

            setActualsDetails(actDetails);
            setExpandActualDataTable(true);
            getMatricsTotals(actDetails, category);
            forceUpdate();

            let updateKey = category.toString() + 'Details';
            let tmpFinalData = { ...FinalData };

            if (actDetails && actDetails[updateKey]) {
                let keyValues = Object.values(actDetails[updateKey]);
                let total = 0;

                for (let i = 0; i < keyValues.length; i++) {
                    total += keyValues[i].Amount;
                }
                tmpFinalData[category] = total;
                tmpFinalData.ActualsDetails = actDetails;

            }
            setFinalData(tmpFinalData);
        }
    }
    function handleDateChange(e) {
        const selectedDate = e.target.value;
        let dateData = FinalData;
        if (e.target.id === 'Date') {
            dateData.Date = selectedDate;
        }
        setFinalData(dateData);
        forceUpdate()
    }
    //
    function syncData() {
        let id = Company._id
        store.setLoading(true);
        store.getCompaniesSheetData(id).then((ret) => {
            if (ret === true) {
                window.alert('Data has been updated');
                store.setLoading(false);
            } else {
                window.alert('Error syncing data, please check your google sheet and try again.');
                store.setLoading(false);
            }
        });
        //store.setLoading(false);
    }

    if (mobile()) {
        return (
            <div>

            </div>
        );

    } else {
        return (
            <div style={{ width: '100%', height: '88%', overflow: 'scroll' }}>
                <Card className='companyDashboardTasks'>
                    <div className='tasks'>
                        <p style={{ color: 'white' }}><b>{Company.Name}</b></p>
                    </div>
                    <div className='myCards'>
                        <Card className='tasksCardsCompleted'>
                            <div>
                                <img className='inProgressImg' src={totalsales} />
                                <p>Total Sales</p>
                            </div>
                            <card className='cardCard'></card>
                            <div style={{ width: '48%', display: 'flex', flexDirection: 'column', height: '100%', marginBottom: '-15%' }}>
                                <div className='iconsRange'>
                                    <img src={AngryIcon} />
                                    <img src={UnHappyIcon} />
                                    <img src={NeutralIcon} />
                                    <img src={SmileIcon} />
                                    <img src={HappyIcon} />
                                </div>
                                <input className='inprng' type='range' min={0} max={100} value={TotalSales} />
                                <h2 style={{ marginLeft: '35%', marginTop: '-1%' }}>{TotalSales.toFixed(2)}%</h2>
                            </div>
                        </Card>
                        <Card className='tasksCardsTodo'>
                            <div>
                                <img className='inProgressImg' src={totalexpenses} />
                                <p>Operating Expenses</p>
                            </div>
                            <card className='cardCard'></card>
                            <div style={{ width: '48%', display: 'flex', flexDirection: 'column', height: '100%', marginBottom: '-15%' }}>
                                <div className='iconsRange'>
                                    <img src={AngryIcon} />
                                    <img src={UnHappyIcon} />
                                    <img src={NeutralIcon} />
                                    <img src={SmileIcon} />
                                    <img src={HappyIcon} />
                                </div>
                                <input className='inprng' type='range' min={0} max={100} value={TotalExpensesActuals} />
                                <h2 style={{ marginLeft: '35%', marginTop: '-1%' }}>{TotalExpensesActuals.toFixed(2)}%</h2>
                            </div>
                        </Card>
                        <Card className='tasksCardsInProgress'>
                            <div>
                                <img className='inProgressImg' src={CostOfSalesIcon} />
                                <p>Cost of Sales</p>
                            </div>
                            <card className='cardCard'></card>
                            <div style={{ width: '48%', display: 'flex', flexDirection: 'column', height: '100%', marginBottom: '-15%' }}>
                                <div className='iconsRange'>
                                    <img src={AngryIcon} />
                                    <img src={UnHappyIcon} />
                                    <img src={NeutralIcon} />
                                    <img src={SmileIcon} />
                                    <img src={HappyIcon} />
                                </div>
                                <input className='inprng' type='range' min={0} max={100} value={TotalClients} />
                                <h2 style={{ marginLeft: '35%', marginTop: '-1%' }}>{TotalClients.toFixed(2)}%</h2>
                            </div>
                        </Card>
                        <Card className='syncCompData' style={{ cursor: 'pointer' }} onClick={syncData}>
                            <p onClick={syncData}>Sync Google Sheets Data.</p>
                        </Card>
                        {/* <Card className='tasksCardsNew' style={{ cursor: 'pointer' }} onClick={addActualsClicked}>
                            <div >
                                <img className='todopngstyle' src={Plus} onClick={addActualsClicked} />
                                <p onClick={addActualsClicked}>Actuals</p>
                            </div>
                        </Card>
                        <Card className='tasksCardsForeCast' style={{ cursor: 'pointer' }} onClick={openForecastClicked}>
                            <div >
                                <img className='todopngstyle' src={Plus} onClick={openForecastClicked} />
                                <p onClick={openForecastClicked}>Forecast</p>
                            </div>
                        </Card> */}
                    </div>
                </Card>
                <div className='bottomContentDiv'>
                    <Card className='metricsCardCard' style={{ width: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <div style={{ width: '90%', overflow: 'scroll', display: 'flex' }}>
                                {MetricsList.map((metricName, index) => (
                                    <Card
                                        key={metricName}
                                        title={metricName}
                                        className='mappedMetricsStyle'
                                        onClick={() => setMetric(metricName)}
                                        style={{ backgroundColor: colors[index % colors.length] }}
                                    >
                                        <p>{splitMetricName(metricName)}</p>
                                    </Card>
                                ))}
                            </div>
                            <div style={{ width: '10%' }}>
                                <select className='periodDropDown' onChange={timeFrameClicked}>
                                    <option>Monthly</option>
                                    <option>Yearly</option>
                                </select>
                            </div>

                        </div>
                        <div className='graphMetricDiv'>
                            <Graph className='MetricsOverviewGraph' actual={Company.Actual} target={Company.Target} timeFrame={TimeFrame} period={Period} type={Metric} buttonValue={ButtonValue} offSet={Offset} />
                        </div>
                    </Card>
                </div>
                {AddActuals ? (
                    <Card className='addActualDataCard'>
                        <div className='AddDataCardHeaders'>
                            <h1>Actuals Values</h1>
                            <img className='closeAddActualsCard' onClick={closeAddActualData} src={CloseCard} />
                        </div>
                        <div className="DateDiv">
                            <p className="pElement" style={{ width: '80%' }}>Selected Date</p>
                            <Input className='InputDateElement' type="date" id="Date" value={FinalData.Date.toString()} onChange={handleDateChange} />
                        </div>
                        <div className='AddDataCardBody'>
                            {MetricsX.map((type, index) => {
                                const configArray = Config[type];
                                if (configArray) {
                                    return (
                                        <div key={index} className="metric-container">
                                            <div className="metric-header">
                                                <p className="metric-title">
                                                    <b>{type} :</b>
                                                </p>
                                            </div>
                                            <div className="config-values">
                                                {configArray.map((configValue, configIndex) => (
                                                    <div key={configIndex} className="config-item">
                                                        <span style={{ width: '40%' }}>{configValue}</span>
                                                        <Input
                                                            style={{ width: '30%' }}
                                                            type="number"
                                                            className="config-input"
                                                            id={configValue}
                                                            onChange={(e) =>
                                                                handleInputChange(type, configValue, e.target.value, index)
                                                            }
                                                        />

                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                        <hr style={{ width: '100%' }} />
                        <div style={{ width: '100%', height: '30%', overflowY: 'scroll' }}>
                            {MetricsX.map((mtrc) => {
                                return <p>{mtrc}: {FinalData[mtrc] || 0}</p>
                            })}
                        </div>
                        <Button className='AddDataButton' onClick={handleSubmit}>Add Actuals</Button>
                    </Card>
                ) : null}
                {Forecast ? (
                    <Card className='addForecast'>
                        <div className='AddDataCardHeaders'>
                            <h1>Forecast</h1>
                            <img className='closeAddActualsCard' onClick={closeAddForecastData} src={CloseCard} />
                        </div>
                        <div className="DateDiv">
                            <p className="pElement" style={{ width: '80%' }}>Selected Date</p>
                            <Input className='InputDateElement' type="date" id="Date" value={NewTarget.Date} onChange={getNewTargets} />
                        </div>
                        <div className='AddDataCardBody'>
                            {MetricsX.map((metrics, index) => (
                                <div className="inputDiv" key={index}>
                                    <p className="targetName">{metrics}</p>
                                    <Input type="number" id={metrics} onChange={getNewTargets} value={NewTarget[metrics] || ''} />
                                </div>
                            ))}
                        </div>
                        <hr style={{ width: '100%' }} />
                        <Button onClick={AddNewTargetsClicked}>Add Forecast</Button>
                    </Card>
                ) : null}
            </div>
        )
    }
})

export default Overview;