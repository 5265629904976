import React from "react";
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineController, LineElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineController, LineElement, ChartDataLabels);

const CompaniesExpensePie = ({ comp = [] }) => {
    const generateRandomColor = () => {
        const randomColor = () => Math.floor(Math.random() * 256);
        return `rgba(${randomColor()}, ${randomColor()}, ${randomColor()}, 0.7)`;
    };
    const aionsData = comp;
    let currMonthData = aionsData.CurrentExpenses || [];
    let PrevMonthData = aionsData.PreviousExpenses || [];
    let currIncomeData = [];
    let prevIncomeData = [];
    const categoryMap = new Map();

    for (let i = 0; i < currMonthData.length; i++) {
        const currentItem = currMonthData[i];

        const existingItem = categoryMap.get(currentItem.Category);

        if (existingItem) {
            existingItem.Amount += parseFloat(currentItem.Amount);
        } else {
            categoryMap.set(currentItem.Category, { ...currentItem, Amount: parseFloat(currentItem.Amount) });
        }
    }
    currIncomeData.push(...categoryMap.values());

    const PrevCategoryMap = new Map();
    for (let i = 0; i < PrevMonthData.length; i++) {
        const currentItem = PrevMonthData[i];
        const existingItem = PrevCategoryMap.get(currentItem.Category);

        if (existingItem) {
            existingItem.Amount += parseFloat(currentItem.Amount);
        } else {
            PrevCategoryMap.set(currentItem.Category, { ...currentItem, Amount: parseFloat(currentItem.Amount) });
        }
    }
    prevIncomeData.push(...PrevCategoryMap.values());
    const getRandomColors = (length) => Array.from({ length }, () => generateRandomColor());

    let currDate = new Date();
    var currMonthStartDate = new Date(currDate.getFullYear(), currDate.getMonth(), 1);
    var prevMonthStartDate = new Date(currDate.getFullYear(), currDate.getMonth() - 1, 1);
    const getMonthYearLabel = (date) => {
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const objDate = new Date(date);
        return `${monthNames[objDate.getMonth()]} ${objDate.getFullYear()}`;
    };

    let CurrMonth = getMonthYearLabel(currMonthStartDate);
    let PrevMonth = getMonthYearLabel(prevMonthStartDate);
    const prevOptions = {
        plugins: {
            legend: {
                position: 'right',
            },
            title: {
                display: true,
                text: `${PrevMonth} Expenses`,
            },
            datalabels: {
                display: false,
                color: 'black',
                font: {
                    weight: 'bold',
                },
                formatter: (value) => value > 0 ? `R ${Number(value).toLocaleString()}` : '',
            },
        },
    };

    const charData = {
        labels: PrevMonthData.map(item => `${item.Category}: ${item.Amount}`),
        datasets: [
            {
                data: PrevMonthData.map(item => item.Amount),
                backgroundColor: getRandomColors(PrevMonthData.length),
            },
        ],
    };
    const data = {
        labels: currMonthData.map(item => `${item.Category}: ${item.Amount}`),
        datasets: [
            {
                data: currMonthData.map(item => item.Amount),
                backgroundColor: getRandomColors(currMonthData.length),
            },
        ],
    };
    const options = {
        plugins: {
            legend: {
                position: 'right',
            },
            title: {
                display: true,
                text: `${CurrMonth} Expenses`,
            },
            datalabels: {
                display: false,
                color: 'black',
                font: {
                    weight: 'bold',
                },
            },
        },
    };
    return (
        <div style={{ width: "400px", height: "400px", display: 'flex', flexDirection: 'row', marginLeft: '-30%' }}>
            <Pie data={charData} options={prevOptions} />
            <Pie data={data} options={options} />
        </div>
    );
};

export default CompaniesExpensePie;
