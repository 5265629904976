import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from "../../../stores/Store";
import { useNavigate } from 'react-router-dom';
import { FaShareSquare } from 'react-icons/fa'
import { Card, Input, View, Button, MobileTabBarItem, MobileTabBar } from '../../../components';
import isMobile from 'is-mobile';


const CourseDetails = observer(({ CurrentPage, navClicked, CourseId }) => {
    //#region UseSates
    const store = useStore();
    const [, updateState] = useState();
    const [Courses, setCourses] = useState([]);
    const [SelectedCourse, setSelectedCourse] = useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [RequiredBy, setRequiredBy] = useState([])
    const [CompletedBy, setCompletedBy] = useState([])


    useEffect(() => {
        getAllCourses();
        loadCourse()
    }, []);

    function getAllCourses() {
        store.setLoading(true);
        store.getCourse().then((ret) => {
            setCourses(ret);
            store.setLoading(false);
        });
        forceUpdate();
    }
    function loadCourse() {
        let id = CourseId
        store.setLoading(true)
        store.getCoursesByID(id).then((ret) => {
            setSelectedCourse(ret)
            setCompletedBy(ret.CompletedBy)
            setRequiredBy(ret.RequiredBy)
            store.setLoading(false)
            forceUpdate();
        })
    }
    function handleCancel() {
        navClicked('AllCourses')
    }

    if (isMobile()) {
        return (
            <div>
                <div>
                    <h2>{SelectedCourse.Name}</h2>
                </div>
                <Button className="newCourseCancelBtn" id={CurrentPage} onClick={handleCancel}>
                    Cancel
                </Button>
                <div className="courseProgressCards">
                    {SelectedCourse ? (
                        <>
                            <Card className="completedCard">
                                <h2 className="completedCardLength">Completed</h2>
                                <div>{CompletedBy?.length || 0}</div>
                            </Card>
                            <Card className="completedCard">
                            <h2 className="completedCardLength">Outstanding</h2>
                                <div>{RequiredBy?.length || 0}</div>
                            </Card>
                        </>
                    ) : (
                        <>
                            <Card>
                                <h1>No Data Available..!!</h1>
                            </Card>
                        </>
                    )}
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <div>
                    <h2>{SelectedCourse.Name}</h2>
                </div>
                <Button className="newCourseCancelBtn" id={CurrentPage} onClick={handleCancel}>
                    Cancel
                </Button>
                <div className="courseProgressCards">
                    {SelectedCourse ? (
                        <>
                            <Card className="completedCard">
                                <h2 className="completedCardLength">Completed</h2>
                                <div>{CompletedBy?.length || 0}</div>
                            </Card>
                            <Card className="completedCard">
                            <h2 className="completedCardLength">Outstanding</h2>
                                <div>{RequiredBy?.length || 0}</div>
                            </Card>
                        </>
                    ) : (
                        <>
                            <Card>
                                <h1>No Data Available..!!</h1>
                            </Card>
                        </>
                    )}
                </div>
            </div>
        )
    }
})

export default CourseDetails;