import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import './style.css';
import { Card, View, Doc } from '../../components';
import AionsIcon from '../../assets/images/Aions.svg';
import Users from '../users';
import Companies from '../companies';
import Company from '../company';
import HomePage from '../homePage';
import Courses from '../Courses';
import CompanyDashboard from '../companyDashboard';
import General from '../General';
import OkrSessions from '../okrSessions';
import CompCourse from '../Courses/CompCourse';
import ChangePassword from '../ChangePassword';
import { FaBars, FaUserCog } from 'react-icons/fa'
var mobile = require('is-mobile');

const Dashboard = observer(() => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), [])
    const [AdminOpen, setAdminOpen] = useState(true);
    const [CompanyOpen, setCompanyOpen] = useState(true);
    const [CurrentPage, setCurrentPage] = useState('HomePage');
    const [CompaniesList, setCompaniesList] = useState([])
    const [SelectedCompany, setSelectedCompany] = useState({});
    const [SidebarOpen, setSidebarOpen] = useState(false);
    const [Dashboard, setDashboard] = useState(true);
    const [UserSettings, setUserSettings] = useState(false);

    useEffect(() => {
        store.setLoading(true);
        store.getCompaniesByPerms(store.user.Email).then((ret) => {
            if (typeof ret === 'string' || !ret) {
                setCompaniesList([]);
                store.setLoading(false);
                return
            }
            if (store.user.Role === 'Company') {
                let comp = ret.filter(company => company.Name === store.user.Company)
                setCompaniesList(comp);
            } else {
                setCompaniesList(ret);
            }

            store.setLoading(false);
        })
    }, []);
    function companySelected(e) {
        let id = ''
        if (e.currentTarget.id === '') {
            return
        }
        else {
            id = e.currentTarget.id
        }
        setSelectedCompany(CompaniesList[Number(id)])
        setCurrentPage('Company');
        setSidebarOpen(false);
        forceUpdate()
    }
    function openAdmin() {
        setAdminOpen(!AdminOpen);
    }
    function openCompany() {
        setCompanyOpen(!CompanyOpen)
    }
    function navClicked(e) {
        setCurrentPage(e.currentTarget.id);
        setSidebarOpen(false);
    }
    function logout() {
        store.logout()
    }
    function toggleSidebar() {
        setSidebarOpen(!SidebarOpen)
        setDashboard(!Dashboard)
    }
    if (mobile()) {
        return (
            <div style={{ justifyContent: 'flex-start', overflow: 'hidden' }} className='toggleOpen'>
                <div className='dashboardTopBarMobile' style={{ height: '60px' }}>
                    <img className="topBarImageMobile" src={AionsIcon} />
                    <FaBars onClick={toggleSidebar} className='menuIcon' />
                </div>

                <div className={`dashboardSidebarMobile ${(SidebarOpen) ? '' : 'dashboardSidebarHiddenMobile'}`} style={{ height: `${window.innerHeight - 60}px` }}>
                    {(!store.user.Permissions.admin) ? null :
                        <div className='sidebarAdminMobile' >
                            <p onClick={openAdmin}>Admin</p>
                            {(AdminOpen) ?
                                <>
                                    <Card className={`sidebarItem ${(CurrentPage === 'Users') ? 'sidebarItemSelected' : ''}`} id='Users' onClick={navClicked}>
                                        <p id='Users'>Users</p>
                                    </Card>
                                    <Card className={`sidebarItem ${(CurrentPage === 'Companies') ? 'sidebarItemSelected' : ''}`} id='Companies' onClick={navClicked}>
                                        <p id='Companies'>Companies</p>
                                    </Card>
                                    {CompaniesList.map((company, index) => {
                                        if (store.user.Role === 'Admin') {
                                            return (
                                                <>
                                                    <Card className={`sidebarItem ${(CurrentPage === 'Courses') ? 'sidebarItemSelected' : ''}`} id='Courses' onClick={navClicked}>
                                                        <p id='Courses'>Courses</p>
                                                    </Card>
                                                </>
                                            )
                                        }
                                    })}
                                </> : null}
                        </div>
                    }

                    <div className='sideBarCompaniesMobile'>
                        <p onClick={openCompany}>Companies</p>
                        {(CompanyOpen) ?
                            <>
                                {CompaniesList.map((company, index) => {
                                    if (store.user.Role === 'Admin' || store.user.Company !== '') {
                                        return (
                                            <>
                                                <Card key={index} id={index} onClick={companySelected} className={`sidebarItem ${(CurrentPage === 'Company' && SelectedCompany.Name && SelectedCompany.Name === company.Name) ? 'sidebarItemSelected' : ''}`}>
                                                    <p>{company.Name}</p>
                                                </Card>
                                            </>
                                        )
                                    }
                                })}
                                {(store.user.Role === 'Company') ? <>
                                    <Card className={`sidebarItem ${(CurrentPage === 'CompCourses') ? 'sidebarItemSelected' : ''}`} id='CompCourses' onClick={navClicked}>
                                        <p id='CompCourses'>Courses</p>
                                    </Card>
                                    <Card className={`sidebarItem ${(CurrentPage === 'OkrSessions') ? 'sidebarItemSelected' : ''}`} id='OkrSessions' onClick={navClicked}>
                                        <p id='OkrSessions'>Courses</p>
                                    </Card>
                                </> : null}

                            </> : null}

                    </div>

                    <div className='logOutButton'>
                        <hr className='hrStyle' />
                        <Card className={`sidebarItem ${(CurrentPage === 'HomePage') ? 'sidebarItemSelected' : ''}`} id='HomePage' onClick={navClicked}>
                            <p id='HomePage'>Home</p>
                        </Card>
                        <Card className={`sidebarItem ${(CurrentPage === 'ChangePassword') ? 'sidebarItemSelected' : ''}`} id='ChangePassword' onClick={navClicked}>
                            <p id='ChangePassword'>Settings</p>
                        </Card>
                        <Card className='sidebarItem' onClick={logout}>
                            <p>Logout</p>
                        </Card>
                    </div>

                </div>
                <div className={`${(SidebarOpen) ? 'dashboardContentMobile' : 'dashboardContentFullMobile'}`} style={{ height: `${window.innerHeight - 60}px`, width: '100%' }}>
                    {(CurrentPage === 'HomePage') ? <HomePage /> : null}
                    {(CurrentPage === 'Users') ? <Users /> : null}
                    {(CurrentPage === 'Courses') ? <Courses /> : null}
                    {(CurrentPage === 'CompCourses') ? <CompCourse /> : null}
                    {(CurrentPage === 'General') ? <General /> : null}
                    {(CurrentPage === 'Companies') ? <Companies /> : null}
                    {(CurrentPage === 'documents') ? <Doc /> : null}
                    {(CurrentPage === 'ChangePassword') ? <ChangePassword /> : null}
                    {(CurrentPage === 'Company') ? <Company company={SelectedCompany} activePage='overview' /> : null}
                </div>

            </div>
        );
    } else {
        return (
            <View style={{ justifyContent: 'flex-start', height: `${window.innerHeight}px`, overflow: 'hidden' }}>
                <div className='dashboardTopBar'>
                    <img className="topBarImage" src={AionsIcon} />
                    <div>
                        <FaBars onClick={toggleSidebar} style={{ position: 'fixed', top: "20px", left: '15px', cursor: 'pointer' }} />
                        <FaUserCog className='cogUserSetting' id='ChangePassword' onClick={navClicked} />
                    </div>
                </div>

                <div className={`${(SidebarOpen) ? 'dashboard' : 'dashboardSidebarHidden'}`} style={{ height: `${window.innerHeight - 61}px` }}>
                    {(!store.user.Permissions.admin) ? null :
                        <div className='sidebarAdminMobile'>
                            <p onClick={openAdmin}>Admin</p>
                            {(AdminOpen) ?
                                <>
                                    <Card className={`sidebarItem ${(CurrentPage === 'Users') ? 'sidebarItemSelected' : ''}`} id='Users' onClick={navClicked}>
                                        <p id='Users'>Users</p>
                                    </Card>
                                    <Card className={`sidebarItem ${(CurrentPage === 'Companies') ? 'sidebarItemSelected' : ''}`} id='Companies' onClick={navClicked}>
                                        <p id='Companies'>Companies</p>
                                    </Card>
                                    <Card className={`sidebarItem ${(CurrentPage === 'Courses') ? 'sidebarItemSelected' : ''}`} id='Courses' onClick={navClicked}>
                                        <p id='Courses'>Courses</p>
                                    </Card>
                                    <Card className={`sidebarItem ${(CurrentPage === 'OkrSessions') ? 'sidebarItemSelected' : ''}`} id='OkrSessions' onClick={navClicked}>
                                        <p id='OkrSessions'>OKR Sessions</p>
                                    </Card>
                                    <Card className={`sidebarItem ${(CurrentPage === 'General') ? 'sidebarItemSelected' : ''}`} id='General' onClick={navClicked}>
                                        <p id='General'>General</p>
                                    </Card>
                                </> : null}
                        </div>
                    }

                    <div className='sideBarCompaniesMobile'>
                        <p onClick={openCompany}> All Companies</p>
                        {(CompanyOpen) ?
                            <>
                                {CompaniesList.map((company, index) => {
                                    if (store.user.Company !== '') {
                                        return (
                                            <>
                                                <Card key={index} id={index} onClick={companySelected} className={`sidebarItem ${(CurrentPage === 'Company' && SelectedCompany.Name && SelectedCompany.Name === company.Name) ? 'sidebarItemSelected' : ''}`}>
                                                    <p>{company.Name}</p>
                                                </Card>

                                            </>
                                        )
                                    }
                                })}

                            </> : null}
                        <hr className='hrStyle' />
                        <div className='logOutButton'>

                            <Card className={`sidebarItem ${(CurrentPage === 'HomePage') ? 'sidebarItemSelected' : ''}`} id='HomePage' onClick={navClicked}>
                                <p id='HomePage'>Home</p>
                            </Card>
                            {(store.user.Role === 'Company') ? <>
                                <Card className={`sidebarItem ${(CurrentPage === 'CompCourses') ? 'sidebarItemSelected' : ''}`} id='CompCourses' onClick={navClicked}>
                                    <p id='CompCourses'>Courses</p>
                                </Card>
                                <Card className={`sidebarItem ${(CurrentPage === 'OkrSessions') ? 'sidebarItemSelected' : ''}`} id='OkrSessions' onClick={navClicked}>
                                    <p id='OkrSessions'>Sessions</p>
                                </Card>
                                <Card className={`sidebarItem ${(CurrentPage === 'documents') ? 'sidebarItemSelected' : ''}`} id='documents' onClick={navClicked}>
                                    <p id='documents'>Documents</p>
                                </Card>
                            </> : null}
                            <Card className='sidebarItem' onClick={logout}>
                                <p>Logout</p>
                            </Card>
                        </div>
                    </div>

                </div>
                <div className="dashboardContentFull" style={{ height: `${window.innerHeight - 61}px` }}>
                    {(CurrentPage === 'HomePage') ? <HomePage /> : null}
                    {(CurrentPage === 'Users') ? <Users /> : null}
                    {(CurrentPage === 'Courses') ? <Courses /> : null}
                    {(CurrentPage === 'CompCourses') ? <CompCourse /> : null}
                    {(CurrentPage === 'General') ? <General /> : null}
                    {(CurrentPage === 'Companies') ? <Companies /> : null}
                    {(CurrentPage === 'OkrSessions') ? <OkrSessions /> : null}
                    {(CurrentPage === 'documents') ? <Doc /> : null}
                    {(CurrentPage === 'ChangePassword') ? <ChangePassword /> : null}
                    {(CurrentPage === 'Company') ? <Company company={SelectedCompany} activePage='overview' /> : null}
                </div>

            </View>
        );
    }
})

export default Dashboard;