import React from 'react';
import './style.css';

function Input({className, id, placeholder, type, value, onKeyDown, onChange, style = {}}){
    let customStyle = style;
    return (
       
        <input className={`input ${className}`} id={id} type={type} value={value} onKeyDown={onKeyDown} onChange={onChange} placeholder={placeholder} style={customStyle}/>   
        
    )
}

export default Input
