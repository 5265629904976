import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { useNavigate } from 'react-router-dom';
import './style.css';
import dashLogo from '../../assets/images/dashLogo.png';
import Todo from '../../assets/images/todo.png';
import InProgress from '../../assets/images/In_Progress@2x.png';
import Completed from '../../assets/images/checked.png';
import Plus from '../../assets/images/plus.png';
import MessageIcon from '../../assets/images/message.svg';
import TaskIcon from '../../assets/images/list.png';
import OverviewIcon from '../../assets/images/search.png';
import HomeIcon from '../../assets/images/icon.png';
import Task from './Task/task';
import Dashboard from './Dashboard/dashboard';
import AlkemiaBriefing from './AlkemiaBriefing/AlkemiaBriefing';
import Welcome from './Welcome/Welcome';
import WelcomeIcon from '../../assets/images/home.png'
import BriefingIcon from '../../assets/images/briefing.png'
import OKR from './OKR/okr';
import ActualsIcon from '../../assets/images/time-is-money.png';
import OKRIcon from '../../assets/images/okr.png'
import Overview from './OverView/Overview';
import ListIcon from '../../assets/images/list.png'
import LogoutIcon from '../../assets/images/power.png';
import Reports from './Reports/reports';
import CoursesIcon from '../../assets/images/online-learning.png';
import Courses from './Courses/courses';
import Logo from '../../assets/images/levelLogo.png';
import PreviousActuals from './PreviousActuals/previousactuals';
import UserIcon from '../../assets/images/user.png'
import Settings from '../../assets/images/settings.png';
import Notification from '../../assets/images/notification.png';
import { Input, View, Button, Card, DoughnutChart, } from '../../components';
import { PolarArea } from 'react-chartjs-2';
import { FaHome, FaRocketchat, FaEnvelopeOpenText, FaPaperPlane, FaRegCommentDots, FaRegFileAlt } from "react-icons/fa";
var mobile = require('is-mobile');

const CompanyDashboard = observer(() => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Jobs, setJobs] = useState([]);
    const [CompaniesList, setCompaniesList] = useState([]);
    const [Company, setCompany] = useState({});
    const [Actuals, setActual] = useState({});
    const [Targets, setTargets] = useState({});
    const [CurrentPage, setCurrentPage] = useState('Welcome');
    const [MappedActuals, setMappedActuals] = useState([]);
    const [Period, setPeriod] = useState("Weekly");
    const [ShowData, setShowData] = useState(false);
    const [ShowPreviousJobs, setShowPreviousJobs] = useState(true);
    const [SelectedJob, setSelectedJob] = useState({});
    const [ViewDetailsMobile, setViewDetailsMobile] = useState(false);
    const [ViewCommentsMobile, setViewCommentsMobile] = useState(true);
    const [MappedCredits, setMappedCredits] = useState({
        "Departments": {
            "Sales": 0,
            "Dev": 0,
            "Design": 0
        }
    });
    const [CompanyCredits, setCompanyCredits] = useState([])
    const [CreditsColor, setCreditsColor] = useState('')
    const [Comment, setComment] = useState('');
    const [Comments, setComments] = useState([]);
    const [CommentObj, setCommentObj] = useState({
        Comment: '',
        Date: new Date(),
        Email: ''
    });
    const [ActDueDate, setActDueDate] = useState(new Date().toDateString());
    const [CompletedJobs, setCompletedJobs] = useState([]);
    const [InProgressJobs, setInProgressJobs] = useState([]);
    const [ToDoJobs, setToDoJobs] = useState([]);
    const [User, setUser] = useState({});
    const [JobRequest, setJobRequest] = useState({
        ActualDueDate: null,
        Title: "",
        Description: "",
        FeedBack: '',
        Department: "",
        DesignSpec: {
            designElements: "",
            collateral: "",
            listDeliverables: "",
            DocLink: ''
        },
        DevSpec: {
            Platform: "",
            Type: "",
            Outcomes: "",
            Figma_Available: ""
        },
        ReqDate: new Date(),
        DueDate: new Date()
    });
    useEffect(() => {
        loadCompanies();
    }, []);
    function loadCompanies() {
        store.setLoading(true);
        store.getUserByEmail(store.user.Email).then((ret) => {
            if (typeof ret === "string" || !ret) {
                setUser({});
                store.setLoading(false);
                return;
            }
            setUser(ret);
            store.setLoading();
        })
        store.getCompaniesByPerms(store.user.Email).then((ret) => {
            if (typeof ret === "string" || !ret) {
                setCompaniesList({});
                store.setLoading(false);
                return;
            }
            if (ret.length === 0) {
                setCompaniesList({});
                store.setLoading(false);
                return;
            }
            let comp = ret.filter(item => item.Name === store.user.Company);
            if (comp.length === 0) {
                setCompaniesList({});
                store.setLoading(false);
                return;
            }
            if (comp[0].Credits && comp[0].Credits.length > 0) {
                setCompanyCredits(comp[0].Credits[0].Departments);
            }
            setCompany(comp);
            setActual(comp[0].Actual[comp[0].Actual.length - 1]);
            getJobs(comp[0]);
            setTargets(comp[0].Target[comp[0].Target.length - 1]);
            forceUpdate();
            setMappedActuals([
                { target: comp[0].Target[comp[0].Target.length - 1].TotalSales, actual: comp[0].Actual[comp[0].Actual.length - 1].TotalSales, color: 'rgba(227,54,126,1)', id: 'Total Sales' },
                { target: comp[0].Target[comp[0].Target.length - 1].OperatingExpenses, actual: comp[0].Actual[comp[0].Actual.length - 1].OperatingExpenses, color: 'rgba(146,140,195,1)', id: 'Operating Expenses' },
                { target: comp[0].Target[comp[0].Target.length - 1].RecurringRevenue, actual: comp[0].Actual[comp[0].Actual.length - 1].RecurringRevenue, color: 'rgba(49,192,209,1)', id: 'Recurring Revenue' },
                { target: comp[0].Target[comp[0].Target.length - 1].AverageRevenuePerCustomer, actual: comp[0].Actual[comp[0].Actual.length - 1].AverageRevenuePerCustomer, color: 'rgba(255, 223, 0, 1)', id: 'Average Revenue' }
            ]);
            store.setLoading(false);
        });
        forceUpdate()
    }
    function addPercentange(data, usedCredsObj) {
        let currentDate = new Date();
        let currentMonth = currentDate.getMonth();
        let currMonthData = data.find(entry => entry.Month === currentMonth);
        if (currMonthData) {
            currMonthData.Departments.Sales = ((currMonthData.Departments.Sales || 0) - usedCredsObj.Sales);
            currMonthData.Departments.Dev = ((currMonthData.Departments.Dev || 0) - usedCredsObj.Dev);
            currMonthData.Departments.Design = ((currMonthData.Departments.Design || 0) - usedCredsObj.Design);

        } else {
            currMonthData = {
                Departments: {
                    Sales: 0 - usedCredsObj.Sales,
                    Dev: 0 - usedCredsObj.Dev,
                    Design: 0 - usedCredsObj.Design,
                }
            }
        }
        setMappedCredits(currMonthData);
    }
    function getJobs(company) {
        store.setLoading(true);
        store.getJobByEmail(store.user.Company).then((ret) => {
            if (typeof ret === "string" || !ret) {
                setJobs({});
                store.setLoading(false);
                return;
            }
            const completedJobs = [];
            const inProgressJobs = [];
            const toDoJobs = [];

            ret.forEach(job => {
                if (job.Status === "complete") {
                    completedJobs.push(job);
                } else if (job.Status === "inProgress") {
                    inProgressJobs.push(job);
                } else if (job.Status === "requested") {
                    toDoJobs.push(job);
                }
            });

            setCompletedJobs(completedJobs);
            setInProgressJobs(inProgressJobs);
            setToDoJobs(toDoJobs);

            forceUpdate();
            getCredits(company, ret);
            store.setLoading(false);
        });
    }
    function navClicked(e) {
        setCurrentPage(e.currentTarget.id);
    }
    function getCredits(comp, jobArr) {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
        const jobsThisMonth = [];
        jobArr.forEach((item) => {
            const itemDate = new Date(item.ReqDate);
            const itemMonth = itemDate.getMonth();
            const itemYear = itemDate.getFullYear();

            if (itemMonth === currentMonth && itemYear === currentYear) {
                jobsThisMonth.push(item);
            }
        });

        let DepartmentSring = ''
        let usedCredsObj = {
            Sales: 0,
            Dev: 0,
            Design: 0
        }
        for (let i = 0; i < jobsThisMonth.length; i++) {
            let jb = jobsThisMonth[i];
            let usedCredits = 0;
            let estimatedHrs = jb.EstimatedHours || 0;
            let actualHrs = jb.ActualHours || 0;
            DepartmentSring = jb.Department;
            if (actualHrs === 0) {
                usedCredits = estimatedHrs;
            } else {
                usedCredits = actualHrs;
            }
            usedCredsObj[DepartmentSring] = usedCredsObj[DepartmentSring] + usedCredits;
        }
        let creditsThisMonthArr = []
        let allCreditsArr = comp.Credits || [];
        for (let i = 0; i < allCreditsArr.length; i++) {
            if (allCreditsArr[i].Month === currentMonth && allCreditsArr[i].Year === currentYear) {
                creditsThisMonthArr.push(allCreditsArr[i])
            }
        }

        let data = comp.Credits;
        addPercentange(data, usedCredsObj);
        forceUpdate();
    }
    function logout() {
        store.logout()
    }
    const currPage = (page) => {
        setCurrentPage(page);
    };



    if (mobile()) {
        return (
            <div>

            </div>
        );

    } else {
        return (
            <div style={{ display: 'flex' }} className='dashBoardBackGround'>
                <div className='companyDashboardSideNav'>
                    <img className='dashPng' src={dashLogo} />
                    <div className='sideNavItems'>
                        <div className={`assets ${(CurrentPage === 'Welcome') ? 'sidebarItemSelected' : ''}`} id="Welcome" onClick={navClicked} >
                            <img className='dashboardIcons' src={WelcomeIcon} />
                            <h3 style={{ marginLeft: '5%' }} >Welcome</h3>
                        </div>
                        {/* <div className={`assets ${(CurrentPage === 'Dashboard') ? 'sidebarItemSelected' : ''}`} id='Dashboard' onClick={navClicked}>
                            <img className='dashboardIcons' src={HomeIcon} />
                            <h3 style={{ marginLeft: '5%' }} >Dashboard</h3>
                        </div> */}

                        {/* <div className={`assets ${(CurrentPage === 'Overview') ? 'sidebarItemSelected' : ''}`} id="Overview" onClick={navClicked}>
                            <img className='dashboardIcons' src={OverviewIcon} />
                            <h3 style={{ marginLeft: '5%' }} >Overview</h3>
                        </div> */}

                        {/* <div className={`assets ${(CurrentPage === 'Task') ? 'sidebarItemSelected' : ''}`} id="Task" onClick={navClicked} >
                            <img className='dashboardIcons' src={TaskIcon} />
                            <h3 style={{ marginLeft: '5%' }} >Task</h3>
                        </div> */}
                        <div className={`assets ${(CurrentPage === 'AlkemiaBriefing') ? 'sidebarItemSelected' : ''}`} id="AlkemiaBriefing" onClick={navClicked} >
                            <img className='dashboardIcons' src={BriefingIcon} />
                            <h3 style={{ marginLeft: '5%' }} >Alkemia Briefing</h3>
                        </div>
                        {/* <div className={`assets ${(CurrentPage === 'PreviousActuals') ? 'sidebarItemSelected' : ''}`} id="PreviousActuals" onClick={navClicked} >
                            <img className='dashboardIcons' src={ActualsIcon} />
                            <h3 style={{ marginLeft: '5%' }} >Previous Actuals</h3>
                        </div> */}
                        {/* <div className={`assets ${(CurrentPage === 'Courses') ? 'sidebarItemSelected' : ''}`} id="Courses" onClick={navClicked} >
                        <img className='dashboardIcons' src={CoursesIcon} />
                            <h3 style={{ marginLeft: '5%' }} >Courses</h3>
                        </div>
                        <div className={`assets ${(CurrentPage === 'OKR') ? 'sidebarItemSelected' : ''}`} id="OKR" onClick={navClicked}  >
                        <img className='dashboardIcons' src={OKRIcon} />
                            <h3 style={{ marginLeft: '5%' }} >O.K.R</h3>
                        </div>
                        <div className={`assets ${(CurrentPage === 'Reports') ? 'sidebarItemSelected' : ''}`} onClick={navClicked} id="Reports">
                        <img className='dashboardIcons' src={MessageIcon} />
                            <h3 style={{ marginLeft: '5%' }} >Reports</h3>
                        </div> */}
                        <div className={`assets ${(CurrentPage === 'logout') ? 'sidebarItemSelected' : ''}`} onClick={logout} id="logout">
                            <img className='dashboardIcons' src={LogoutIcon} />
                            <h3 style={{ marginLeft: '5%' }} >Logout</h3>
                        </div>
                    </div>
                </div>
                <div className='companyDashboardBody'>
                    <div className='companyDashboardTopNav'>
                        <div className='headerDivUserInfo'>
                            <div className='Shape_cf_Class'>
                            </div>
                            <div>
                                <h3>{User.FirstName} {User.LastName}</h3>
                                {/* <p>{User.Position || ''}</p> */}
                            </div>
                            <div >
                                <img style={{ marginLeft: '-11%' }} src={UserIcon} />
                            </div>
                            {/* <Card className='CompanyIconsLogoSettings'>
                                <img src={Settings} />
                            </Card>
                            <Card className='CompanyIconsLogoSettings'>
                                <img style={{ width: '85%' }} src={Notification} />
                            </Card> */}
                        </div>
                    </div>
                    {/* {(CurrentPage === 'Dashboard') ? <Dashboard /> : null} */}
                    {(CurrentPage === 'Reports') ? <Reports /> : null}
                    {(CurrentPage === 'Welcome') ? <Welcome currPage={currPage} /> : null}
                    {(CurrentPage === 'OKR') ? <OKR /> : null}
                    {(CurrentPage === 'AlkemiaBriefing') ? <AlkemiaBriefing /> : null}
                    {(CurrentPage === 'PreviousActuals') ? <PreviousActuals /> : null}
                    {/* {(CurrentPage === 'Task') ? <Task /> : null} */}
                    {(CurrentPage === 'Overview') ? <Overview /> : null}
                </div>
            </div>
        )
    }
})

export default CompanyDashboard;