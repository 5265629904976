import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineController, LineElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(ChartDataLabels);

const AlkemiaStackedGraph = ({ Aions = [], id }) => {
    const sortedLabels = Aions
        .map((item) => item.Label)
        .filter((value, index, self) => {
            return self.indexOf(value) === index;
        })
        .sort((a, b) => {
            const dateA = new Date(a);
            const dateB = new Date(b);
            return dateA - dateB;
        });

    const dataCOS = sortedLabels.map((label) => {
        const aions = Aions.find((item) => item.Label === label && item.Type === 'Actual');
        if (aions && aions.type === 'LineData') {
            return 0;
        }
        if (aions && aions.type === 'LineData') {
            return 0;
        }
        return aions && aions.Income ? Number(aions.Expenses.COS).toFixed(2) : 0;
    });

    const dataExpenses = sortedLabels.map((label) => {
        const aions = Aions.find((item) => item.Label === label && item.Type === 'Actual');
        if (aions && aions.type === 'LineData') {
            return 0;
        }
        return aions && aions.Income ? Number(aions.Expenses.Expenses).toFixed(2) : 0;
    });

    const dataCOSForecast = sortedLabels.map((label) => {
        const aions = Aions.find((item) => item.Label === label && item.Type === 'Forecast');
        if (aions && aions.type === 'LineData') {
            return 0;
        }
        return aions && aions.Income ? Number(aions.Expenses.COSForecast).toFixed(2) : 0;
    });

    const dataExpensesForecast = sortedLabels.map((label) => {
        const aions = Aions.find((item) => item.Label === label && item.Type === 'Forecast');
        if (aions && aions.type === 'LineData') {
            return 0;
        }
        return aions && aions.Income ? Number(aions.Expenses.ExpensesForecast).toFixed(2) : 0;
    });
    const forecastLabels = sortedLabels.filter((label) => {
        const hasCOSForecast = Aions.some((item) => item.Label === label && item.Type === 'Forecast' && item.Expenses.COSForecast !== undefined);
        const hasExpensesForecast = Aions.some((item) => item.Label === label && item.Type === 'Forecast' && item.Expenses.ExpensesForecast !== undefined);
        const noActualCOS = !Aions.some((item) => item.Label === label && item.Type === 'Actual' && item.Expenses.COS !== undefined);
        const noActualExpenses = !Aions.some((item) => item.Label === label && item.Type === 'Actual' && item.Expenses.Expenses !== undefined);
    
        return (hasCOSForecast || hasExpensesForecast) && noActualCOS && noActualExpenses;
    });
    

    const options = {
        responsive: true,
        width: 800,
        height: 300,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Total Expenses',
            },
            datalabels: {
                display: true,
                color: 'black',
                font: {
                    weight: 'bold',
                },
                formatter: (value) => value > 0 ? `R ${Number(value).toLocaleString()}` : '',
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            x2: {
                display: true,
                position: 'bottom',
                labels: sortedLabels.map((label) => forecastLabels.includes(label) ? 'Forecast' : 'Actual'),
                offset: true,
                grid: {
                    display: false,
                },
                ticks: {
                    display: true,
                },
            },
            y: {
                stacked: true,
            },
        },
    };
    const data = {
        labels: sortedLabels,
        datasets: [
            {
                label: 'COS',
                data: dataCOS,
                backgroundColor: '#2e5cb8',
                borderWidth: 1,
                stack: 'stack1',
                categoryPercentage: 1,
            },
            {
                label: 'Expenses',
                data: dataExpenses,
                backgroundColor: '#ffa500',
                borderWidth: 1,
                stack: 'stack1',
                categoryPercentage: 1,
            },
            {
                label: 'COS Forecast',
                data: dataCOSForecast,
                backgroundColor: '#adc2eb',
                borderWidth: 1,
                stack: 'stack2',
                categoryPercentage: 1,
            },
            {
                label: 'Expense Forecast',
                data: dataExpensesForecast,
                backgroundColor: '#ffe6b3',
                borderWidth: 1,
                stack: 'stack2',
                categoryPercentage: 1,
            },
        ],
    };

    return <Bar options={options} data={data} id={id} />;
};

export default AlkemiaStackedGraph;
