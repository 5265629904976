import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineController, LineElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineController, LineElement, ChartDataLabels);

const CompaniesIncomeExpense = ({ comp = [] }) => {
    let CompValues = comp.companyIncomeExpData;
    let defaults = comp.DefaultForecast;
    let forecast = comp.ForecastData;
    const ForecastLabels = forecast.map(item => item.Label).sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA - dateB;
    });

    const labels = CompValues.map(item => item.Label).sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA - dateB;
    });

    // Filter out labels that are already present in the labels array
    const filteredForecastLabels = ForecastLabels.filter(label => !labels.includes(label));

    const actualExpensesData = CompValues.map(item => item.OperatingExpenses);
    const actualIncomeData = CompValues.map(item => item.TotalSales);

    const IncomeForecast = forecast.map(item => item.TotalSales);
    const ExpenseForecast = forecast.map(item => item.OperatingExpenses);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: `${comp.CompanyName} Income and Expenses`,
            },
            datalabels: {
                display: 'auto',
                color: 'black',
                font: {
                    weight: 'bold',
                },
                formatter: (value) => value > 0 ? `R ${Number(value).toLocaleString()}` : '',
                anchor: 'end',
                align: 'end',
                offset: -3,
                clamp: true,
                rotation: 0,
            },
        },
        scales: {
            x2: {
                display: false,
                position: 'bottom',
                labels: ['Actuals', 'Forecast'],
                offset: true,
                grid: {
                    display: false,
                },
                ticks: {
                    display: true,
                },
            },
        },
    };

    const data = {
        labels: [...labels, ...filteredForecastLabels],
        datasets: [
            {
                label: 'Actual Expenses',
                data: actualExpensesData,
                backgroundColor: '#DC143C',
                categoryPercentage: 1,
            },
            {
                label: 'Actual Income',
                data: actualIncomeData,
                backgroundColor: '#00BFFF',
                categoryPercentage: 1,
            },
            {
                label: 'Expenses Forecast',
                data: ExpenseForecast,
                backgroundColor: '#DC143C',
                borderColor: '#DC143C',
                borderWidth: 1,
                borderDash: [10, 10],
                tension: 0.1,
                type: 'line',
            },
            {
                label: 'Income Forecast',
                data: IncomeForecast,
                backgroundColor: '#00BFFF',
                borderColor: '#00BFFF',
                borderWidth: 1,
                borderDash: [10, 10],
                tension: 0.1,
                type: 'line',
            },
        ],
    };

    return <Bar options={options} data={data} />;
};

export default CompaniesIncomeExpense;
