import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import './style.css'
import { useNavigate } from 'react-router-dom';
import { Input, View, Button, Card, DoughnutChart, } from '../../../components';
import totalsales from '../../../assets/images/totalsales.png';
import Config from '../../../assets/images/configs.png';
import AddCompany from '../../../assets/images/add.png';
import { FaUpload, FaDownload, FaAngleRight, FaPlus, FaTrash } from 'react-icons/fa';
import CloseCard from '../../../assets/images/closeCard.png';
var mobile = require('is-mobile');

const Companies = observer(() => {
    const store = useStore();
    const [, updateState] = useState();
    const [TotalCompanies, setTotalCompanies] = useState(0);
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [CompanyList, setCompaniesList] = useState([]);
    const [Config, setConfig] = useState(false);
    const [Metrics, setMetrics] = useState([]);
    const [Name, setName] = useState("");
    const [SheetID, setSheetID] = useState('');
    const [InitialDate, setDate] = useState(new Date());
    const [metricValues, setMetricValues] = useState({});
    const [CustomMetricsName, setCustomMetricsName] = useState('');
    const [Company, setCompany] = useState({});
    const [AddNewCompany, setAddNewCompany] = useState(false);
    const [Target, setTarget] = useState({
        TotalSales: 0,
        NumberOfMeetings: 0,
        NewCustomers: 0,
        AverageRevenuePerCustomer: 0,
        OperatingExpenses: 0,
        TotalClients: 0,
        CostOfSales: 0,
        RecurringRevenue: 0,
        Date: new Date()
    });
    useEffect(() => {
        loadCompanies()
    }, []);
    function loadCompanies() {
        store.setLoading(true);
        store.getCompanies().then((ret) => {
            if (typeof ret === "string" || !ret) {
                setCompaniesList({});
                store.setLoading(false);
                return;
            }
            if (ret.length === 0) {
                setCompaniesList([]);
                store.setLoading(false);
                return;
            }
            setCompaniesList(ret);
            store.setLoading(false);
        });
        forceUpdate()
    }
    function TargetChanged(e) {
        let tar = Target;

        if (e.currentTarget.id === "targetTotalClients") {
            tar.TotalClients = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "targetCostOfsales") {
            tar.CostOfSales = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "targetTotalSales") {
            tar.TotalSales = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "targetNumberOfMeetings") {
            tar.NumberOfMeetings = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "targetNewCustomer") {
            tar.NewCustomers = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "targetAverageRevenuePerCustomer") {
            tar.AverageRevenuePerCustomer = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "targetOperatingExpenses") {
            tar.OperatingExpenses = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === "targetMonthlyRevenue") {
            tar.RecurringRevenue = Number(Number(e.target.value));
        }
        setTarget(tar);
        forceUpdate();
    }
    function nameChanged(e) {
        setName(e.target.value);
    }
    function sheetIdChange(e) {
        setSheetID(e.target.value);
    }
    function initialDate(e) {
        if (e.currentTarget.id === "inDate") {
            setDate(e.target.value);
        }
    }
    function metricSwitchToggled(metric) {
        let tmpMetrics = [...Metrics];
        if (tmpMetrics.includes(metric)) {
            let ind = tmpMetrics.indexOf(metric);
            tmpMetrics.splice(ind, 1);
        } else {
            tmpMetrics.push(metric);
        }

        setMetrics(tmpMetrics);
    }
    function addCustomMetric() {
        let tmpMetrics = Metrics;
        tmpMetrics.push(CustomMetricsName);
        setMetrics(tmpMetrics);
        setCustomMetricsName('');
    }
    function deleteCustomMetric(e) {
        let tmpMetrics = Metrics;
        let ind = tmpMetrics.indexOf(e.currentTarget.id);
        tmpMetrics.splice(ind, 1);
        setMetrics(tmpMetrics);
        forceUpdate();
    }
    function customNameChange(e) {
        setCustomMetricsName(e.target.value);
    }
    function AddNewCompanyClicked() {
        setAddNewCompany(true);
    }
    function configClicked(id) {
        let companyId = id;
        setConfig(true)
        store.setLoading(true);
        store.getCompanyByID(companyId).then((ret) => {
            if (typeof ret === 'string' || !ret) {
                setCompany({})
                setMetrics([]);
                store.setLoading(false);
                return;
            }
            setCompany(ret);
            let metricValues = {};

            let metricList = ret.Config.MetricList || [];
            metricList.forEach((metricName) => {
                if (ret.Config[metricName] && Array.isArray(ret.Config[metricName])) {
                    metricValues[metricName] = [...ret.Config[metricName]];
                } else {
                    metricValues[metricName] = [];
                }
            });
            setMetricValues(metricValues);
            if (ret.Config && ret.Config.MetricList) {
                setMetrics(ret.Config.MetricList);
            } else {
                setMetrics([]);
            }
            store.setLoading(false);
        });
        store.setLoading(false);
        forceUpdate();
    }
    function saveMetricValue(metric) {
        let inputValue = metricValues[`input_${metric}`]?.trim();
        if (inputValue) {
            setMetricValues({
                ...metricValues,
                [metric]: [...(metricValues[metric] || []), inputValue],
                [`input_${metric}`]: '',
            });
        }
    }
    function closeConfigurations() {
        setConfig(false);
        setCustomMetricsName('');
        setMetricValues({});
    }
    function closeAddCompanyClicked() {
        setAddNewCompany(false)
    }
    function addMetrics() {
        let id = Company._id;
        let config = {
            MetricList: Metrics,
            ...Metrics.reduce((acc, metric) => {
                acc[metric] = [];
                return acc;
            }, {}),
        };
        for (const metric in metricValues) {
            if (metricValues.hasOwnProperty(metric) && !metric.startsWith('input_')) {
                config[metric] = metricValues[metric];
            }
        }
        let data = {
            Config: config,
        };
        store.setLoading(true);
        store.updateCompanyById(id, data).then((ret) => {
            if (ret) {
                window.alert('Config added successfully');
                setConfig(false);
                store.setLoading(false);
            }
        })
            .catch((error) => {
                window.alert('Error updating company');
                console.error('Error updating company:', error);
                store.setLoading(false);
            });
    }
    const defaultMetrics = [
        'TotalSales',
        'NumberOfMeetings',
        'CostOfSales',
        'NewCustomers',
        'AverageRevenuePerCustomer',
        'OperatingExpenses',
        'RecurringRevenue',
        'TotalClients',
    ];
    function removeMetricValue(metric, valueToDelete) {
        const updatedValues = metricValues[metric].filter((value) => value !== valueToDelete);
        setMetricValues({
            ...metricValues,
            [metric]: updatedValues,
        });
    }
    function createCompanyClicked() {
        if (Name === "") {
            window.alert("Please enter company name!");
            return;
        }
        if (initialDate === "") {
            window.alert("Please input initial Date.!");
            return;
        }

        let newTarget = {
            TotalSales: (Target.TotalSales),
            NumberOfMeetings: (Target.NumberOfMeetings),
            CostOfSales: (Target.CostOfSales),
            NewCustomers: (Target.NewCustomers),
            AverageRevenuePerCustomer: (Target.AverageRevenuePerCustomer),
            OperatingExpenses: (Target.OperatingExpenses),
            RecurringRevenue: (Target.RecurringRevenue),
            TotalClients: (Target.TotalClients),
            Date: new Date()
        };
        let data = {
            Name: Name,
            Target: newTarget,
            Date: InitialDate,
            Config: Metrics,
            SheetID: SheetID,
        };
        store.setLoading(true);
        store.createCompanies(data).then((ret) => {
            if (ret === true) {
                window.alert("Company created successfully");
                loadCompanies();
                setTarget({
                    TotalSales: 0,
                    NumberOfMeetings: 0,
                    NewCustomers: 0,
                    AverageRevenuePerCustomer: 0,
                    OperatingExpenses: 0,
                    CostOfSales: 0,
                    RecurringRevenue: 0,
                    TotalClients: 0

                });
                setAddNewCompany(false);
                setName("");
            } else {
                window.alert("Unable to create company");
                store.setLoading(false);
            }
        });
    }
    if (mobile()) {
        return (
            <div>

            </div>
        );

    } else {
        return (
            <div className='container' style={{ width: '100%', height: '88%', overflow: 'scroll' }} >
                <card className='allCompaniesCard'>
                    <h1>Companies</h1>
                    <div className='myCards'>
                        <Card className='tasksCardsCompleted'>
                            <div>
                                <img className='inProgressImg' src={totalsales} />
                                <p>Total Companies</p>
                            </div>
                            <card className='cardCard'></card>
                            <h1>{CompanyList.length || 0}</h1>
                        </Card>
                        <Card className='companiesAddActualsCard' style={{ cursor: 'pointer' }} onClick={AddNewCompanyClicked}>
                            <div>
                                <img className='inProgressImg' src={AddCompany} />
                                <p>Add Company</p>
                            </div>
                        </Card>
                    </div>
                </card>
                <div className='bottomMappedCompanies'>
                    {CompanyList.map((company, index) => (
                        <card key={company} title={company} className='companyCardsWithConfig'>
                            <div style={{ width: '90%' }}>
                                <p style={{ marginLeft: '2%' }}>{company.Name}</p>
                            </div>
                            <card className='companyConfigButtons' onClick={() => configClicked(company._id)}>Config</card>
                        </card>
                    ))}
                </div>
                {AddNewCompany ? (
                    <Card className='addForecast'>
                        <div className='AddDataCardHeaders'>
                            <h1>Add Company</h1>
                            <img className='closeAddActualsCard' onClick={closeAddCompanyClicked} src={CloseCard} />
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <p style={{ width: '40%' }}>Name</p><p>:</p>
                                <Input className='addNewCompanyInputs' type="text"
                                    value={Name}
                                    onChange={nameChanged} />
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <p style={{ width: '40%' }}>Initial Date</p><p>:</p>
                                <Input className='addNewCompanyInputs' type="date"
                                    value={InitialDate}
                                    onChange={initialDate}
                                    id="inDate" />
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <p style={{ width: '40%' }}>Total Sales</p><p>:</p>
                                <Input className='addNewCompanyInputs'
                                    value={
                                        String(Target.TotalSales)
                                    }
                                    id="targetTotalSales"
                                    onChange={TargetChanged} />
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <p style={{ width: '40%' }}>Number of Meetings</p><p>:</p>
                                <Input className='addNewCompanyInputs' type="number"
                                    value={
                                        String(Target.NumberOfMeetings)
                                    }
                                    id="targetNumberOfMeetings"
                                    onChange={TargetChanged} />
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <p style={{ width: '40%' }}>Average Revenue Per Customer</p><p>:</p>
                                <Input className='addNewCompanyInputs' type="number"
                                    value={
                                        String(Target.AverageRevenuePerCustomer)
                                    }
                                    id="targetAverageRevenuePerCustomer"
                                    onChange={TargetChanged} />
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <p style={{ width: '40%' }}>Operating Expenses</p><p>:</p>
                                <Input className='addNewCompanyInputs' type="number"
                                    value={
                                        String(Target.OperatingExpenses)
                                    }
                                    id="targetOperatingExpenses"
                                    onChange={TargetChanged} />
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <p style={{ width: '40%' }}>Monthly Recurring Revenue</p><p>:</p>
                                <Input className='addNewCompanyInputs' type="number"
                                    value={
                                        String(Target.RecurringRevenue)
                                    }
                                    id="targetMonthlyRevenue"
                                    onChange={TargetChanged} />
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <p style={{ width: '40%' }}>Total Clients</p><p>:</p>
                                <Input className='addNewCompanyInputs' type="number"
                                    value={
                                        String(Target.TotalClients)
                                    }
                                    id="targetTotalClients"
                                    onChange={TargetChanged} />
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <p style={{ width: '40%' }}>Cost of Sales</p><p>:</p>
                                <Input className='addNewCompanyInputs' type="number"
                                    value={
                                        Target.CostOfSales.toString()
                                    }
                                    id="targetCostOfsales"
                                    onChange={TargetChanged} />
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <p style={{ width: '40%' }}>New Customers</p><p>:</p>
                                <Input className='addNewCompanyInputs' type="number"
                                    value={
                                        String(Target.NewCustomers)
                                    }
                                    id="targetNewCustomer"
                                    onChange={TargetChanged} />
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <p style={{ width: '40%' }}>Google Sheet ID</p><p>:</p>
                                <Input className='addNewCompanyInputs' type="text"
                                    value={SheetID}
                                    onChange={sheetIdChange} />
                            </div>
                        </div>
                        <Button className='addNewUserButton' onClick={createCompanyClicked}>Create Company</Button>
                    </Card>
                ) : null}
                {Config ? (
                    <Card className='companiesConfigurationTab'>
                        <div style={{ width: '100%', height: '10%', display: 'flex', flexDirection: 'column' }}>
                            <h1>Configuration</h1>
                            <img className='closeConfigIcon' onClick={closeConfigurations} src={CloseCard} />
                        </div>
                        <div style={{ width: '100%', height: '75%', display: 'flex', flexDirection: 'column', overflowY: 'scroll' }}>
                            {defaultMetrics.map((metric, index) => {
                                if (
                                    metric === 'TotalSales' ||
                                    metric === 'NumberOfMeetings' ||
                                    metric === 'CostOfSales' ||
                                    metric === 'NewCustomers' ||
                                    metric === 'AverageRevenuePerCustomer' ||
                                    metric === 'OperatingExpenses' ||
                                    metric === 'RecurringRevenue' ||
                                    metric === 'TotalClients'
                                ) {
                                    return (
                                        <div key={index} style={{ width: '100%' }}>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                <div style={{ width: '90%' }}>
                                                    <h3>{metric}</h3>
                                                </div>
                                                <div className="addMetricsIcon">
                                                    <div className="switch-container">
                                                        <div
                                                            className={`switch ${Metrics.includes(metric) ? 'on' : 'off'}`}
                                                            onClick={() => metricSwitchToggled(metric)}
                                                        >
                                                            <div
                                                                className={`toggle ${Metrics.includes(metric) ? 'on' : 'off'}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr style={{ width: '100%' }} />
                                            <div>
                                                {metricValues[metric] &&
                                                    metricValues[metric].map((value, index) => (
                                                        <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                            <p style={{ marginRight: '5px', width: '40%' }}>{value}</p>
                                                            <button className="removeMetricValueButton" onClick={() => removeMetricValue(metric, value)}>Remove</button>
                                                        </div>
                                                    ))}
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                <input className='addCategoryInputs' placeholder={`Enter ${metric} Categories`} value={metricValues[`input_${metric}`] || ''} onChange={(e) => setMetricValues({ ...metricValues, [`input_${metric}`]: e.target.value })} />

                                                <button className="addCategoryButtons" onClick={() => saveMetricValue(metric)}>Add Category</button>
                                            </div>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                            <div>
                                {Metrics.map((customMetric, index) => {
                                    if (!defaultMetrics.includes(customMetric)) {
                                        return (
                                            <div key={index}>
                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                    <h3 style={{ width: '90%' }}>{customMetric}</h3>
                                                    <button className="removeCustomMetricsArr" onClick={() => deleteCustomMetric(customMetric)}>Remove {customMetric} Metric</button>
                                                </div>
                                                <hr style={{ width: '100%' }} />
                                                <div>
                                                    {metricValues[customMetric] &&
                                                        metricValues[customMetric].map((value, index) => (
                                                            <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                <p style={{ marginRight: '5px', width: '40%' }}>{value}</p>
                                                                <button className="removeMetricValueButton" onClick={() => removeMetricValue(customMetric, value)}>Remove</button>
                                                            </div>
                                                        ))}
                                                </div>
                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                    <input className='addCategoryInputs' placeholder={`Enter ${customMetric} Categories`} value={metricValues[`input_${customMetric}`] || ''} onChange={(e) => setMetricValues({ ...metricValues, [`input_${customMetric}`]: e.target.value })} />
                                                    <button className="addCategoryButtons" onClick={() => saveMetricValue(customMetric)}>Add Category</button>
                                                </div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
                        <div style={{ width: '100%', height: '5%', display: 'flex', flexDirection: 'row' }}>
                            <input className='addCategoryInputs' placeholder="Custom Metrics" onChange={customNameChange} value={CustomMetricsName} />
                            <button className="addCategoryButtons" onClick={addCustomMetric}>Add Custom Metric</button>
                        </div>
                        <div style={{ width: '100%', height: '5%', display: 'flex', flexDirection: 'column', marginTop: '2%' }}>
                            <button className="addCategoryButtons" onClick={addMetrics}>Save Configurations</button>
                        </div>
                    </Card>
                ) : null}
            </div>
        )
    }
})

export default Companies;