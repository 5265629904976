import React from 'react';
import { Bar } from 'react-chartjs-2';

const AEXTransactionsAmount = ({ Aex = [] , id}) => {
    const obj = Aex.map(item => ({
        Label: item.Label,
        Amount: item.Amount,
    }));
    obj.sort((a, b) => new Date(a.Label) - new Date(b.Label));

    const labels = obj.map(item => item.Label);
    const data = obj.map(item => Number(item.Amount.toFixed(2)));
    const options = {
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'AEX Monthly Transactions Amount',
            },
            datalabels: {
                display: true,
                color: 'black',
                font: {
                    weight: 'bold',
                },
                formatter: (value) => value > 0 ? `R ${Number(value).toLocaleString()}` : '',
            },
        },
        scales: {
            x: {
                display: true,
                position: 'bottom',
                labels,
            },
        },
        responsive: true,
    };

    const chartData = {
        labels,
        datasets: [
            {
                label: 'AEX Monthly Transactions Amount',
                data,
                backgroundColor: 'rgba(75,192,192,0.6)',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
                categoryPercentage: 1,
            },
        ],
    };
    return <Bar options={options} data={chartData} id={id}/>;
};

export default AEXTransactionsAmount;