import React, { useEffect, useState, updateState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { useStore } from "../../../stores/Store";
import CloseArchive from "../../../assets/images/CloseTask.svg";
import "./style.scss";
import { Card, Textbox } from "../../../components";
import { FaIntercom } from "react-icons/fa";

var mobile = require("is-mobile");

const Clients = () => {
  //#region useStates

  const store = useStore();
  const navigate = useNavigate();
  const [, updateState] = useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [Companies, setCompanies] = useState([]);
  const [Company, setCompany] = useState({});
  const [AddUsers,  setAddUser] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    Password: "",
    Permissions: [],
    Role: [],
    Blocked: false,
    Company: "",
  });
  const [credits, setCredits] = useState({
    Department: {
      Sales: 0,
      Design: 0,
      Dev: 0,
    },
    Month: new Date().getMonth(),
    Year: new Date().getFullYear(),
  });
  const [AddCompany, setAddCompany] = useState({
    Name: "",
    Company: "",
    SheetID: "",
    Target: [],
    Actual: [],
    Date: new Date(),
  });
  const Months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // const [creditsUpdate,setCreditsUpdate] =useState({});

  const [AddNewCompany, setAddNewCompany] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [ViewCompany, setViewCompany] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [updateCredits, setUpdateCredits] = useState({
    Department: {
      Sales: 0,
      Design: 0,
      Dev: 0,
    },
    Month: new Date().getMonth(),
    Year: new Date().getFullYear(),
  });

  //#endregion UseState

  //#region useEffect
  useEffect(() => {
    getCompanies();
  }, []);

  ////#endregion useEffect

  //#region functions
  const getCompanies = async () => {
    store.setLoading(true);
    let comps = await store.getCompanies();
    if (comps === "error" || typeof comps === "string" || !comps) {
      setCompanies([]);
      store.setLoading(false);
    }
    setCompanies(comps);
    forceUpdate();
    store.setLoading(false);
  };

  const openCard = () => {
    setOpen(true);
    forceUpdate();
  };

  const closeCard = () => {
    setOpen(false);

    setCredits({
      Sales: 0,
      Dev: 0,
      Design: 0,
    });

    setAddCompany({
      Company: "",
      SheetID: "",
    });

    setAddUser({
      FirstName: "",
      LastName: "",
      Email: "",
      Password: "",
    });

    forceUpdate();
  };

  const handleDevChangeCredits = (e) => {
    let tempCreditsUpdate = { ...updateCredits };
    tempCreditsUpdate.Department.Dev = Number(e.target.value);
    setUpdateCredits(tempCreditsUpdate);
    forceUpdate();
  };

  const handleDesignChangeCredits = (e) => {
    let tempCreditsUpdate = { ...updateCredits };
    tempCreditsUpdate.Department.Design = Number(e.target.value);
    setUpdateCredits(tempCreditsUpdate);
    forceUpdate();
  };

  const formattedDate = `${new Date().getFullYear()}-${String(
    new Date().getMonth() + 1
  ).padStart(2, "0")}`;

  const handleChangeDate = (e) => {
    let credcopy = { ...updateCredits };
    let [year, month] = e.target.value.split("-");

    year = parseInt(year);
    month = parseInt(month);

    credcopy.Month = month;
    credcopy.Year = year;
    setUpdateCredits(credcopy);
  };

  const handleDevChange = (e) => {
    let tempCredits = credits;
    tempCredits.Dev = Number(e.target.value);
    setCredits(tempCredits);
    forceUpdate();
  };

  const handleSalesChange = (e) => {
    let tempCredits = credits;
    tempCredits.Sales = Number(e.target.value);
    setCredits(tempCredits);
    forceUpdate();
  };

  const handleDesignChange = (e) => {
    let tempCredits = credits;
    tempCredits.Design = Number(e.target.value);
    setCredits(tempCredits);
    forceUpdate();
  };

  const handleBtnCredits = () => {
    let creditedDetail = { ...updateCredits };

    setUpdateCredits(creditedDetail);
    forceUpdate();
  };

  const handleChangeInput = (e) => {
    let user = { ...AddUsers };
    let company = { ...AddCompany };
    let id = e.target.id;
    let value = e.target.value;


    if (id === "spread") {
      company.SheetID = value;
    }
    if (id === "company") {
      company.Company = value;
    }

    if (id === "FirstName") {
      user.FirstName = value;
    }

    if (id === "LastName") {
      user.LastName = value;
    }

    if (id === "Email") {
      user.Email = value;
    }

    if (id === "Password") {
      user.Password = value;
    }

    setAddUser(user);
    setAddCompany(company);
    forceUpdate();
  };

  const createCompanies = async () => {
    try {
      const userData = {
        FirstName: AddUsers,
        LastName: AddUsers,
        Email: AddUsers,
        Password: AddUsers,
        Company: AddUsers,
      };

      const companyData = {
        Name: AddCompany,
        SheetID: AddCompany,
        Target: AddCompany,
        Actual: AddCompany,
        Date: AddCompany,
        Credits: [credits],
      };

      console.log(companyData)
      console.log('\n\n\n',userData)
      let newCompany = await store.createCompanies(companyData);
      let newUser = await store.createUsers(userData);
      console.log(newUser);

      if (!newCompany || !newUser) {
        console.error("There was an error creating the company");
        return;
      }

      // Append the new company to the existing list
      setCompanies([...Companies, newCompany]);
      console.log(newCompany);
      store.setLoading(false);
    } catch (error) {
      console.error("Error creating company:", error.message);
    }
    forceUpdate();
  };

  const updateCompaniesById = async () => {
    try {
      store.setLoading(true);

      let updatedData = selectedCompany;
      updatedData.Credits.push(updateCredits);
      console.log("here");
      let updatedCompDetails = await store.updateCompanyById(
        updatedData._id,
        updatedData
      );

      if (!updatedCompDetails || "") {
        console.log(updatedCompDetails, "The error was updated ");
      }
      closePopCard();
      getCompanies();
    } catch (error) {
      console.log(error, "It was the was error captured during update");
    }
  };

  const handlePopCard = (e) => {
    let index = Number(e.currentTarget.id);
    setSelectedCompany(Companies[index]);
    forceUpdate();
  };

  const closePopCard = () => {
    setSelectedCompany(null);
    forceUpdate();
  };
  ////#endregion functions
  
  //Hlonis Functions
  const handleAddNewComp = () => {
    setAddNewCompany(true);
    forceUpdate();
  };

  const handleViewComp = (index) => {
    setCompany(Companies[index]);
    setViewCompany(true);
    forceUpdate();
  };

  const closeViewComp = () => {
    setViewCompany(false);
    setCompany({});
    setAddNewCompany(false);
    forceUpdate();
  };

  if (mobile()) {
    return <div className="mobile"></div>;
  } else {
    return (
      <div className="displayCards">
        <div className="CompanyNamesHeading">
          <h2 className="TextCompanyNames">Company Names </h2>
          <button className="btnNew" onClick={openCard}>
            New
          </button>
        </div>

        {isOpen ? (
          <Card className="BtnCardNew">
            {/* TODO:
                         (1)add onchange to textboxes to trigger onchange function
                  */}
            <div className="NavbarBtncard">
              {" "}
              <Textbox
                id="company"
                placeholder="Company"
                className="TextBoxNavBar"
                value={AddCompany.Company}
                onChange={handleChangeInput}
                type="text"
                required
              ></Textbox>
              <Textbox
                id="spread"
                placeholder="Spread Sheet Id"
                className="TextBoxNavBar"
                value={AddCompany.SheetID}
                onChange={handleChangeInput}
                type="text"
                required
              ></Textbox>
            </div>
            <button className="closeBtn" onClick={closeCard}>
              X{" "}
            </button>

            <div className="ContentBtnCard">
              <div className="leftCardAdd">
                <h2 className="TextHeading">NEW USER</h2>

                <Textbox
                  id="FirstName"
                  placeholder="First Name"
                  className="TextBoxName"
                  onChange={handleChangeInput}
                  value={AddUsers.FirstName}
                  type="text"
                  required
                ></Textbox>

                <Textbox
                  id="LastName"
                  placeholder="Last Name"
                  className="TextBoxName"
                  onChange={handleChangeInput}
                  value={AddUsers.LastName}
                  type="text"
                  required
                ></Textbox>

                <Textbox
                  id="Email"
                  placeholder="Email"
                  className="TextBoxName"
                  onChange={handleChangeInput}
                  value={AddUsers.Email}
                  type="email"
                  required
                ></Textbox>

                <Textbox
                  id="Password"
                  placeholder="Password"
                  className="TextBoxName"
                  onChange={handleChangeInput}
                  value={AddUsers.Password}
                  type="Password"
                  required
                ></Textbox>
              </div>

              <div className="RightContent">
                <div className="creditsTextBox">
                  <Textbox
                    className="rightTextBox"
                    onChange={handleSalesChange}
                    value={String(credits.Sales)}
                    placeholder="Sales Credits"
                    type="number"
                  ></Textbox>
                  <Textbox
                    className="rightTextBox"
                    onChange={handleDevChange}
                    value={String(credits.Dev)}
                    placeholder="Dev Credits"
                    type="number"
                  ></Textbox>
                  <Textbox
                    className="rightTextBox"
                    onChange={handleDesignChange}
                    value={String(credits.Design)}
                    placeholder="Design Credits"
                    type="number"
                  ></Textbox>
                </div>
                <button
                  className="btnSubmit"
                  type="submit"
                  onClick={createCompanies}
                >
                  Submit
                </button>
              </div>
            </div>
          </Card>
        ) : null}

        {/* {isOpen && (
            
          )} */}

        <div className="Clients">
          {/* <h2>{AddUsers.index}</h2> */}

          <div>
            {Companies.map((company, index) => (
              <div key={index}>
                <Card
                  className="CardName"
                  key={index}
                  id={index}
                  onClick={handlePopCard}
                >
                  <h3 className="textCard">{company.Name}</h3>
                </Card>
              </div>
            ))}
          </div>
        </div>
        {selectedCompany !== null ? (
          <Card className="CardPop">
            <button className="btnCloseCard" onClick={closePopCard}>
              X
            </button>
            <div className="headerCard">
              <Textbox
                className="textBoxer"
                placeholder="Company name"
                value={selectedCompany.Name || ""}
              ></Textbox>
              <Textbox
                className="textBoxer"
                placeholder="Spreed Sheet Id"
                value={selectedCompany.SheetID || ""}
              ></Textbox>
            </div>
            <div className="contentCard">
              <div className="leftCard">
                



                {selectedCompany.Credits.map((item, index) => {
                  let monthArr = [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ];
                  return (
                    <Card className="creditsMonthCard" key={index}>
                      {console.log(item)}
                      <h3 className="textCard">
                        {monthArr[item.Month - 1]}-
                        {item.Year}{" "}
                      </h3>
                      

                      <div className="creditsRadiusBox">
                      <div className="creditsSection">
                        <p>Design</p>
                        <div className="credsNum"><h3>{item.Department !==undefined ? item.Department.Design || item.Department.Design:0 }</h3></div>
                      </div>
                      <div className="creditsSectionTwo">
                        <p>Dev</p>
                        <div className="credsNum"><h3>{item.Department !==undefined ? item.Department.Dev || item.Department.Dev:0 }</h3></div>

                     </div>
                      </div>
                    </Card>
                  );
                })}

              </div>

              <div className="rightCard">
                <input
                  id="DATE"
                  className="inputCredits"
                  type="month"
                  placeholder="Month/Year"
                  onChange={handleChangeDate}
                  value={updateCredits.formattedDate}
                ></input>
                <Textbox
                  className="TextCredits"
                  type="number"
                  placeholder="Dev"
                  onChange={handleDevChangeCredits}
                  value={String(updateCredits.Department.Dev)}
                ></Textbox>
                <Textbox
                  className="TextCredits"
                  type="number"
                  placeholder="Design"
                  onChange={handleDesignChangeCredits}
                  value={String(updateCredits.Department.Design)}
                ></Textbox>

                <button className="btnCredits" onClick={updateCompaniesById}>
                  Add Credits
                </button>
              </div>
            </div>
          </Card>
        ) : null}
      </div>
    );
  }
};

export default Clients;
