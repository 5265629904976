import React, { useState, useRef, useEffect } from 'react';
import './style.scss';

function ResizableTextarea({ className, id, placeholder, type, value, onKeyDown, onChange, style = {} }) {
    const [height, setHeight] = useState('35px');
    const [textValue, setTextValue] = useState(value || '');
    const textareaRef = useRef(null);
    useEffect(() => {
        adjustHeight();
    }, [textValue]);
    const handleChange = (event) => {
        setTextValue(event.target.value);
        if (onChange) {
            onChange(event);
        }
    };
    const adjustHeight = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            if (!textValue || textValue.trim() === '') {
                setHeight('33px');
                textarea.style.height = '33px';
            } else {
                textarea.style.height = 'auto';
                const newHeight = textarea.scrollHeight + "px";
                setHeight(newHeight);
                textarea.style.height = newHeight;
            }
        }
    };
    const customStyle = { ...style, height: height };
    return (
        <div className={`ResizableTextarea ${className}`} style={customStyle} id={id}>
            <p className='placeHolder'>{placeholder}</p>
            <textarea
                ref={textareaRef}
                type={type}
                id={id}
                value={textValue}
                onKeyDown={onKeyDown}
                onChange={handleChange}
                style={{ height: height }}
            />
        </div>
    );
}

export default ResizableTextarea;
