import React from 'react';
import './style.css';

function Card({children, className, id, onClick, style}){
    return (
        <div className={`card ${className}`} id={id} onClick={onClick} style={style}>
            {children}
        </div>
    )
}

export default Card