import React, { useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineController, LineElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(ChartDataLabels);

const CompaniesIncome = ({ comp = [] }) => {
    let cat = comp.companyIncomeData;
    let incomeData = comp.DefaultForecast;
    function getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    const ForecastLabels = cat.map((income) => income.Label).sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA - dateB;
    });
    const labels = cat.map((income) => income.Label).sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA - dateB;
    });
    const allCategories = cat.reduce((accumulator, income) => {
        const categoryList = income.Categories.map((category) => ({
            Category: category.Category,
            Amount: category.Amount,
        }));
        return accumulator.concat(categoryList);
    }, []);

    const uniqueCategories = [...new Set(allCategories.map((item) => item.Category))];


    const dataSets = uniqueCategories.map((category) => {
        const categoryData = {
            label: category,
            data: cat.map((income) => {
                const matchingCategory = income.Categories.find((c) => c.Category === category);
                return matchingCategory ? matchingCategory.Amount : 0;
            }),
            backgroundColor: getRandomColor(),
        };
        return categoryData;
    });

    const options = {
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: `${comp.CompanyName} Income`,
            },
            datalabels: {
                display: 'auto',
                color: 'black',
                font: {
                    weight: 'bold',
                },
                formatter: (value) => value > 0 ? `R ${Number(value).toLocaleString()}` : '',
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
        tooltips: {},
    };
    const chartData = {
        labels: [...labels, ...ForecastLabels],
        datasets: [
            ...dataSets,
        ],
    };

    return <Bar options={options} data={chartData} />;
};

export default CompaniesIncome;
