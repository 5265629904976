import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const InvesteeProfitLossChart = ({ width, height, data }) => {
    const ref = useRef();

    useEffect(() => {
        const svg = d3.select(ref.current);
        const margin = { top: 20, right: 20, bottom: 20, left: 20 };
        svg.selectAll('*').remove();
        svg.attr('width', width).attr('height', height);

        let minAmount = d3.min(data, d => d.Amount) * 1.1;
        let maxAmount = d3.max(data, d => d.Amount) * 1.1;
        let halfMin = minAmount / 2;
        let halfMax = maxAmount / 2;
        if (minAmount === 0) minAmount = -500;
        if (maxAmount === 0) maxAmount = 500;

        const domainValues = [minAmount, halfMin, 0, halfMax, maxAmount];
        const x = d3.scaleLinear()
            .domain(d3.extent(domainValues))
            .range([margin.left, width - margin.right]);

        const filteredData = data.filter(d => d.Amount !== 0);

        const xAxis = svg.append('g')
            .attr('transform', `translate(0,${height - margin.bottom})`)
            .call(d3.axisBottom(x).ticks(5).tickFormat(d3.format(".2s")).tickSize(0));

        xAxis.selectAll('line')
            .remove();

        xAxis.selectAll('text')
            .style('font-size', '18px')
            .style('fill', '#213038')
            .style('font-weight', '300');

        // Draw y-axis lines for each x-axis domain
        xAxis.selectAll('g.tick')
            .append('line')
            .attr('x1', 0)
            .attr('y1', -height + margin.bottom)
            .attr('x2', 0)
            .attr('y2', 0)
            .style('stroke', '#E5E5E5')
            .style('stroke-width', 1);

        // Make the line at x-axis value 0 bold and black
        xAxis.selectAll('g.tick line')
            .filter((d, i) => d === 0)
            .style('stroke', 'black')
            .style('stroke-width', 2);

        const tooltip = d3.select('body').append('div')
            .style('position', 'absolute')
            .style('background', '#ffffff')
            .style('box-shadow', '0 0 10px rgba(0, 0, 0, 1)')
            .style('padding', '5px')
            .style('display', 'none')
            .style('pointer-events', 'none')
            .style('color', '#213038')
            .style('font-weight', '300')
            .style('font-size', '15px')
            .style('text-align', 'center')
            .style('align-items', 'center')
            .style('border-radius', '85px')
            .style('min-width', '100px')
            .style('height', '65px')
            .style('z-index', '5')
            .style('flex-direction', 'column');

        const showTooltip = (event, value, obj, element) => {
            tooltip
                .style('left', (event.pageX + 10) + 'px')
                .style('top', (event.pageY + 10) + 'px')
                .style('display', 'flex')
                .html(`
       <p style="line-height: 0px; font-size: 20px;">${obj.Company}</p>
        <p style="line-height: 0px; font-size: 20px; font-weight: 700; margin: 6px;">R ${value.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
      `);
        };

        const hideTooltip = () => {
            tooltip.style('display', 'none');
            d3.selectAll('.bar-actual, .bar-forecast, .bar-deficit, .bar-difference').style('opacity', 1);
        };
        
        const y = d3
            .scaleBand()
            .domain(data.map(d => d.Company))
            .range([margin.top, height - margin.bottom])
            .padding(0.1);

        svg.selectAll('.bar')
            .data(filteredData)
            .enter()
            .append('path')
            .attr('class', 'bar')
            .attr('fill', d => (d.Amount < 0 ? '#213038' : '#D5922D'))
            .attr('d', d => {
                const barHeight = 15;
                const yStart = y(d.Company) + (y.bandwidth() - barHeight) / 2 + 4 - 3;
                let xStart;
                if (d.Amount < 0) {
                    xStart = x(d.Amount) - 2;
                } else {
                    xStart = x(0) + 2;
                }
                const barWidth = Math.abs(x(d.Amount) - x(0));
                return roundedRect(xStart, yStart, barWidth, barHeight, 5, d.Amount >= 0);
            })
            .on('mouseover', function (event, d) { showTooltip(event, d.Amount, d, this); })
            .on('mouseout', hideTooltip)
            .transition()
            .duration(1000)
            .attrTween('d', function (d) {
                const barHeight = 15;
                const yStart = y(d.Company) + (y.bandwidth() - barHeight) / 2 + 4 - 3;
                const xEnd = d.Amount < 0 ? x(d.Amount) : x(d.Amount);
                let xStart;
                if (d.Amount < 0) {
                    xStart = x(0) - 1;
                } else {
                    xStart = x(0) + 1.5;
                }
                const interpolate = d3.interpolateNumber(xStart, xEnd);
                return function (t) {
                    const xCurrent = interpolate(t);
                    const barWidth = Math.abs(xCurrent - x(0));
                    let xCurrentStart;
                    if (d.Amount < 0) {
                        xCurrentStart = xCurrent - 1;
                    } else {
                        xCurrentStart = x(0) + 1;
                    }
                    return roundedRect(xCurrentStart, yStart, barWidth, barHeight, 5, d.Amount >= 0);
                };
            });

        svg.selectAll('.bar-label')
            .data(data)
            .enter()
            .append('text')
            .attr('class', 'bar-label')
            .attr('x', d => (d.Amount < 0 ? x(0) + 5 : x(0) - 5))
            .attr('y', d => y(d.Company) + y.bandwidth() / 2 + 6)
            .attr('text-anchor', d => (d.Amount < 0 ? 'start' : 'end'))
            .text(d => d.Company)
            .style('font-size', '18px')
            .style('font-weight', '300')
            .attr('fill', '#213038');

        svg.attr('style', 'border: none');
        svg.selectAll('g').selectAll('path').attr('stroke', 'none');
    }, [width, height, data]);

    const roundedRect = (x, y, width, height, radius, roundRight) => {
        if (roundRight) {
            return `M${x},${y}
                  h${width - radius}
                  a${radius},${radius} 0 0 1 ${radius},${radius}
                  v${height - 2 * radius}
                  a${radius},${radius} 0 0 1 -${radius},${radius}
                  h-${width - radius}
                  z`;
        } else {
            return `M${x + radius},${y}
                  h${width - radius}
                  v${height}
                  h-${width - radius}
                  a${radius},${radius} 0 0 1 -${radius},-${radius}
                  v-${height - 2 * radius}
                  a${radius},${radius} 0 0 1 ${radius},-${radius}
                  z`;
        }
    };
    return <svg ref={ref}></svg>;
};

export default InvesteeProfitLossChart;
