import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from 'react';
import { useStore } from '../../stores/Store';
import { Card, Input, Button } from '../../components';
import './style.css';

var mobile = require('is-mobile');

const ChangePassword = observer(() => {
    const store = useStore();
    const [OldPassword, setOldPassword] = useState('');
    const [NewPassword, setNewPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

    function oldPasswordChanged(e){
        setOldPassword(e.currentTarget.value);
    }
    function confirmPasswordChanged(e){
        setConfirmPassword(e.currentTarget.value);
    }
    function newPasswordChanged(e){
        setNewPassword(e.currentTarget.value);
    }
    const validatePassword = (pwd) => {
        if (!passwordRegex.test(pwd)) {
            return false
        } else {
            return true
        }
    }
    function clearData(){
        setConfirmPassword('');
        setNewPassword('');
        setOldPassword('');
    }
    
    function handleConfirmPasswordChange(){
        let email = store.user.Email
        let data = {
            OldPassword : OldPassword,
            NewPassword : NewPassword,
            ConfirmPassword  : ConfirmPassword,
        }

        if (OldPassword === ''){
            window.alert('Please enter your Old Password');
            return
        }
        if (NewPassword === ''){
            window.alert('Please enter your New Password');
            return
        }
        if (!validatePassword(NewPassword) || NewPassword === '') {
            window.alert(' New Password must contain at least 8 characters, including uppercase, lowercase, number, and special character.');
            return
        }
        if (ConfirmPassword === '' || ConfirmPassword !== NewPassword) {
            window.alert('Passwords do not match!');
            return
        };

        store.setLoading(true);
        store.patchUsersPassword(email, data).then((ret) => {
            if (ret === true || ret !== {}){
                window.alert('Passwords Updated Successfully');
                clearData();
            } else {
                window.alert('Could`nt Update Password!');
            }
            store.setLoading(false);
        })
    }

    if (mobile()) {
        return (
            <div>
                <Card className='changePasswordCardMobile'>
                    <h1>Change Password</h1>
                    <Input type='password' placeholder="Old Password" value={OldPassword} onChange={oldPasswordChanged}/>
                    <Input type='password' placeholder="New Password" value={NewPassword} onChange={newPasswordChanged}/>
                    <Input type='password' placeholder="Confirm New Password" value={ConfirmPassword} onChange={confirmPasswordChanged}/>
                    <Button onClick={handleConfirmPasswordChange}>Confirm</Button>
                </Card>
            </div>
        );
    } else {
        return (
            <div>
                <Card className='changePasswordCard'>
                    <h1>Change Password</h1>
                    <Input type="password" placeholder="Old Password" value={OldPassword} onChange={oldPasswordChanged}/>
                    <Input type="password" placeholder="New Password" value={NewPassword} onChange={newPasswordChanged}/>
                    <Input type="password" placeholder="Confirm New Password" value={ConfirmPassword} onChange={confirmPasswordChanged}/>
                    <Button onClick={handleConfirmPasswordChange}>Confirm</Button>
                </Card>
            </div>
        )
    }
});

export default ChangePassword;