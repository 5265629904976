import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './style.scss';
import { Textbox, Card } from '../../components';
import DashLog from '../../assets/images/DashLog.svg';
import { useStore } from '../../stores/Store';
import Login from "../../pages/login";

const Signup = () => {
    const store = useStore();
    const navigate = useNavigate();
    const [Params, setParams] = useState(null);
    const [error, setError] = useState(false);
    const [ConfirmaPass, setConfirmPass] = useState('');
    const [isMatch, setMatch] = useState(null);
    const [UserPassword, setPassword] = useState('');
    const [isPasswordSet, setIsPasswordSet] = useState(null);
    useEffect(() => {
        console.log('Runnging')
        const fetchData = async () => {
            store.setLoading(true);
            try {
                const res = await store.extractParams();
                if (res === 'error') {
                    setError(true);
                } else {

                    setParams(res);
                }
            } catch (error) {
                setError(true);
            }
            store.setLoading(false);
        };
        fetchData();
    }, [store]);

    const getPassword = (e) => {
        let value = e.target.value;
        let id = e.target.id;
        let passwordCopy = UserPassword;
        let password = ConfirmaPass;
        let match = isMatch;

        if (id === 'p1') {
            match = value === ConfirmaPass;
            passwordCopy = value;
        } else {
            match = value === passwordCopy;
            password = value;
        };
        setMatch(match);
        setPassword(passwordCopy)
        setConfirmPass(password);
    };

    const handleSubmit = async () => {
        if (!isMatch) { return };
        store.setLoading(true);
        const isValid = await store.isPasswordValid(UserPassword);
        if (isValid) {
            let dataObj = {
                Email: Params.Email,
                Password: UserPassword
            }
            await store.newUserPassword(dataObj, Params.Token).then((ret) => {
                if (ret === 'error') { return } else { setIsPasswordSet(true) };
            });
        };
        store.setLoading(false);
    };

    if (error) {
        return (
            <div className="pagenotactive">
                <h1>{`ERROR : 404`}<br />{`Page not found.!`}</h1>
            </div>
        );
    };
    return (
        <div className="mainLogin">
            <section id="topLeft"></section>
            <section id="topRight"></section>
            <section id="bottomRight"></section>
            <section id="bottomLeft"></section>
            <img src={DashLog} alt="dashPic" className="DashImage" />
            {isPasswordSet ===null ||isPasswordSet === false ?
                <Card className='LoginCredentialsCard'>
                    <p className="welcometxt">Welcome to the <br />Aions dashboard </p>
                    <Textbox id='p1' onChange={getPassword} style={{ borderColor: isMatch === null || ConfirmaPass === '' ? '' : isMatch ? 'lightgreen' : 'red' }} placeholder='Password' />
                    <Textbox id='p2' onChange={getPassword} style={{ borderColor: isMatch === null || ConfirmaPass === '' ? '' : isMatch ? 'lightgreen' : 'red' }} placeholder='Confirm Password' />
                    <span className="span" onClick={handleSubmit}>Create Password</span>
                </Card>
                :
                <Login email ={`${Params.Email}`} password={`${UserPassword}` } />
            }
        </div>
    );
};

export default Signup;
