import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { useNavigate } from 'react-router-dom';
import dashLogo from '../../../assets/images/dashLogo.png';
import Todo from '../../../assets/images/todo.png';
import InProgress from '../../../assets/images/In_Progress@2x.png';
import Completed from '../../../assets/images/checked.png';
import Plus from '../../../assets/images/plus.png';
import CloseCard from '../../../assets/images/closeCard.png';
import { Input, View, Button, Card, DoughnutChart, } from '../../../components';
import { PolarArea } from 'react-chartjs-2';
import { FaHome, FaRocketchat, FaEnvelopeOpenText, FaPaperPlane, FaRegCommentDots, FaRegFileAlt } from "react-icons/fa";
var mobile = require('is-mobile');

const Dashboard = observer(() => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Jobs, setJobs] = useState([]);
    const [CompaniesList, setCompaniesList] = useState([]);
    const [Company, setCompany] = useState({});
    const [Actuals, setActual] = useState({});
    const [Targets, setTargets] = useState({});
    const [CurrentPage, setCurrentPage] = useState('Dashboard');
    const [MappedActuals, setMappedActuals] = useState([

    ])
    const [Period, setPeriod] = useState("Weekly");
    const [ShowData, setShowData] = useState(false);
    const [ShowPreviousJobs, setShowPreviousJobs] = useState(true);
    const [SelectedJob, setSelectedJob] = useState({});
    const [ViewDetailsMobile, setViewDetailsMobile] = useState(false);
    const [ViewCommentsMobile, setViewCommentsMobile] = useState(true);
    const [Task, setTask] = useState(false);
    const [MappedCredits, setMappedCredits] = useState({
        "Departments": {
            "Sales": 0,
            "Dev": 0,
            "Design": 0
        }
    });
    const [CompanyCredits, setCompanyCredits] = useState([]);
    const [CreditsColor, setCreditsColor] = useState('')
    const [Comment, setComment] = useState('');
    const [Comments, setComments] = useState([])
    const [CommentObj, setCommentObj] = useState({
        Comment: '',
        Date: new Date(),
        Email: ''
    })
    const [ActDueDate, setActDueDate] = useState(new Date().toDateString());
    const [CompletedJobs, setCompletedJobs] = useState([]);
    const [InProgressJobs, setInProgressJobs] = useState([]);
    const [ToDoJobs, setToDoJobs] = useState([]);
    const [JobRequest, setJobRequest] = useState({
        ActualDueDate: null,
        Title: "",
        Description: "",
        FeedBack: '',
        Department: "",
        DesignSpec: {
            designElements: "",
            collateral: "",
            listDeliverables: "",
            DocLink: ''
        },
        DevSpec: {
            Platform: "",
            Type: "",
            Outcomes: "",
            Figma_Available: ""
        },
        ReqDate: new Date(),
        DueDate: new Date()
    });
    useEffect(() => {
        loadCompanies();
    }, []);
    function loadCompanies() {
        store.setLoading(true);
        store.getCompaniesByPerms(store.user.Email).then((ret) => {
            if (typeof ret === "string" || !ret) {
                setCompaniesList({});
                store.setLoading(false);
                return;
            }
            if (ret.length === 0) {
                setCompaniesList({});
                store.setLoading(false);
                return;
            }
            let comp = ret.filter(item => item.Name === store.user.Company);
            if (comp.length === 0) {
                setCompaniesList({});
                store.setLoading(false);
                return;
            }
            if (comp[0].Credits && comp[0].Credits.length > 0) {
                setCompanyCredits(comp[0].Credits[0].Departments);
            }
            setCompany(comp);
            setActual(comp[0].Actual[comp[0].Actual.length - 1]);
            getJobs(comp[0]);
            setTargets(comp[0].Target[comp[0].Target.length - 1]);
            forceUpdate();
            let currDate = new Date();
            let currMonthStartDate = new Date(currDate.getFullYear(), currDate.getMonth(), 1);
            let currMonthEndDate = new Date(currDate.getFullYear(), currDate.getMonth() + 1, 0);
            const filteredActuals = comp[0].Actual.filter((actual) => {
                let actualDate = new Date(actual.Date);
                return actualDate >= currMonthStartDate && actualDate <= currMonthEndDate;
            });
            const filteredTargets = comp[0].Target.filter((target) => {
                let targetDate = new Date(target.Date)
                return targetDate >= currMonthStartDate && targetDate <= currMonthEndDate;
            })
            let totalSalesTarget = 0;
            let totalSalesActuals = 0;
            let operatingExpensesTarget = 0;
            let operatingExpensesActuals = 0;
            let recurringRevenueTarget = 0;
            let recurringRevenueActuals = 0;
            let averageRevenuePerCustomerTarget = 0;
            let averageRevenuePerCustomerActuals = 0;

            filteredActuals.forEach((actual) => {
                totalSalesActuals += (actual.TotalSales || 0);
                operatingExpensesActuals += (actual.OperatingExpenses || 0);
                recurringRevenueActuals += (actual.RecurringRevenue || 0);
                averageRevenuePerCustomerActuals += (actual.AverageRevenuePerCustomer || 0);
            })
            if (filteredTargets.length >= 1) {
                filteredTargets.sort((a, b) => new Date(b.Date) - new Date(a.Date));
                const latestTarget = filteredTargets[0];

                totalSalesTarget = (latestTarget.TotalSales || 0);
                operatingExpensesTarget = (latestTarget.OperatingExpenses || 0);
                recurringRevenueTarget = (latestTarget.RecurringRevenue || 0);
                averageRevenuePerCustomerTarget = (latestTarget.AverageRevenuePerCustomer || 0);
            }
            setMappedActuals([
                { target: totalSalesTarget, actual: totalSalesActuals, color: 'rgba(227,54,126,1)', id: 'Total Sales' },
                { target: operatingExpensesTarget, actual: operatingExpensesActuals, color: 'rgba(146,140,195,1)', id: 'Operating Expenses' },
                { target: recurringRevenueTarget, actual: recurringRevenueActuals, color: 'rgba(49,192,209,1)', id: 'Recurring Revenue' },
                { target: averageRevenuePerCustomerTarget, actual: averageRevenuePerCustomerActuals, color: 'rgba(255, 223, 0, 1)', id: 'Average Revenue' }
            ]);
            store.setLoading(false);
        });
        forceUpdate()
    }
    function addPercentange(data, usedCredsObj) {
        let currentDate = new Date();
        let currentMonth = currentDate.getMonth();
        let currMonthData = data.find(entry => entry.Month === currentMonth);
        if (currMonthData) {
            currMonthData.Departments.Sales = ((currMonthData.Departments.Sales || 0) - usedCredsObj.Sales);
            currMonthData.Departments.Dev = ((currMonthData.Departments.Dev || 0) - usedCredsObj.Dev);
            currMonthData.Departments.Design = ((currMonthData.Departments.Design || 0) - usedCredsObj.Design);
        } else {
            currMonthData = {
                Departments: {
                    Sales: 0 - usedCredsObj.Sales,
                    Dev: 0 - usedCredsObj.Dev,
                    Design: 0 - usedCredsObj.Design,
                }
            }
        }
        setMappedCredits(currMonthData);
    }
    function closeAddTaskData() {
        setTask(false);
    }
    function jobRequestChange(e) {
        let job = JobRequest;
        job.ActualDueDate = null;
        job.FeedBack = "";
        if (e.currentTarget.id === "title") {
            job.Title = e.target.value;
        }
        if (e.currentTarget.id === "Description") {
            job.Description = e.target.value;
        }
        if (e.currentTarget.id === "Department") {
            job.Department = e.target.value;
        }
        if (e.currentTarget.id === "designElements") {
            job.DesignSpec.designElements = e.target.value;
        }
        if (e.currentTarget.id === "Collaterals") {
            job.DesignSpec.collaterals = e.target.value;
        }
        if (e.currentTarget.id === "category") {
            JobRequest.DesignSpec.category = e.currentTarget.value;
        }
        if (e.currentTarget.id === "listDeliverables") {
            job.DesignSpec.listDeliverables = e.target.value;
        }
        if (e.currentTarget.id === "docLinks") {
            job.DesignSpec.DocLink = e.target.value;
        }
        if (e.currentTarget.id === "dueDate") {
            job.DueDate = e.target.value;
        }
        if (e.currentTarget.id === "Platform") {
            job.DevSpec.Platform = e.target.value;
        }
        if (e.currentTarget.id === "Platform") {
            job.DesignSpec.Platform = e.target.value;
        }
        if (e.currentTarget.id === "Type") {
            job.DevSpec.Type = e.target.value;
        }
        if (e.currentTarget.id === "Outcomes") {
            job.DevSpec.Outcomes = e.target.value;
        }
        if (e.currentTarget.id === "Figma_Available") {
            job.DevSpec.Figma_Available = e.target.value;
        }
        if (e.currentTarget.id === "Size") {
            job.DesignSpec.Size = e.target.value;
        }
        if (e.currentTarget.id === "FullDescription") {
            job.DesignSpec.FullDescription = e.target.value;
        }
        if (e.currentTarget.id === "CopyIfRequired") {
            job.DesignSpec.CopyIfRequired = e.target.value;
        }
        if (e.currentTarget.id === "Attachments") {
            job.DesignSpec.Attachments = e.target.value;
        }
        setJobRequest(job);
        forceUpdate();
    }
    function RequestJobClicked() {
        let job = JobRequest;

        if (job.Title === "") {
            window.alert("Please enter Job Title!");
            return;
        }
        if (job.Department === "") {
            window.alert("Please input target department!");
            return;
        }
        if (job.Description === "") {
            window.alert("Please input some description about the job!");
            return;
        }

        const selectedDepartmentCredits = CompanyCredits && CompanyCredits[job.Department];
        if (selectedDepartmentCredits === undefined) {
            window.alert(`Credits not available for ${job.Department}`);
        } else if (selectedDepartmentCredits !== null && selectedDepartmentCredits <= 0) {
            window.alert(`Credits for ${job.Department} exhausted. Extra credits used for job requests will be deducted from next month's refill. Contact admin for more info.`);
        }
        const mappedCreditValue = MappedCredits.Departments[job.Department] || 0;
        store.setLoading(true);
        let senderEmail = store.user.Email;
        let data = {
            Sender: senderEmail,
            Title: job.Title,
            Department: job.Department,
            Description: job.Description,
            Client: store.user.Company,
            DueDate: new Date(job.DueDate),
            ReqDate: job.ReqDate,
            ActualDueDate: job.ActualDueDate,
            Status: "requested",
            Credits: mappedCreditValue,
            DesignSpec: {
                designElements: job.DesignSpec?.designElements || "",
                collateral: job.DesignSpec?.collaterals || '',
                category: job.DesignSpec?.category || '',
                listDeliverables: job.DesignSpec?.listDeliverables || "",
                Doclink: job.DesignSpec?.DocLink || "",
                Sizes: job.DesignSpec?.Size || "",
                Attachments: job.DesignSpec?.Attachments || "",
                CopyIfRequired: job.DesignSpec?.CopyIfRequired || "",
                FullDescription: job.DesignSpec?.FullDescription || "",
                Platform: job.DesignSpec?.Platform || "",
            },
            DevSpec: {
                Figma_Available: job.DevSpec?.Figma_Available || "",
                Outcomes: job.DevSpec?.Outcomes || "",
                Platform: job.DevSpec?.Platform || "",
                Type: job.DevSpec?.Type || ""
            },
        };
        store.createJob(data).then((ret) => {
            if (ret === true) {
                window.alert("Job request created successfully");
                setJobRequest({
                    Title: "",
                    Description: "",
                    Department: "",
                    FeedBack: '',
                    DesignSpec: {
                        designElements: "",
                        collateral: "",
                        category: "",
                        listDeliverables: "",
                        DocLink: "",
                    },
                    DevSpec: {
                        Figma_Available: "",
                        Outcomes: "",
                        Platform: "",
                        Type: ""
                    },
                    ActualDueDate: null,
                    ReqDate: new Date(),
                    DueDate: new Date()
                });
                getJobs();
                forceUpdate();
            } else {
                window.alert("Unable to Request job");
                forceUpdate();
            }
            store.setLoading(false);
            forceUpdate();
        });
        forceUpdate();
    }
    function getJobs(company) {
        store.setLoading(true);
        store.getJobByEmail(store.user.Company).then((ret) => {
            if (typeof ret === "string" || !ret) {
                setJobs({});
                store.setLoading(false);
                return;
            }
            const completedJobs = [];
            const inProgressJobs = [];
            const toDoJobs = [];

            ret.forEach(job => {
                if (job.Status === "Completed") {
                    completedJobs.push(job);
                } else if (job.Status === "inProgress") {
                    inProgressJobs.push(job);
                } else if (job.Status === "requested") {
                    toDoJobs.push(job);
                }
            });

            setCompletedJobs(completedJobs);
            setInProgressJobs(inProgressJobs);
            setToDoJobs(toDoJobs);

            forceUpdate();
            getCredits(company, ret);
            store.setLoading(false);
        });
    }
    function addTaskClicked() {
        setTask(true);
    }
    function getCredits(comp, jobArr) {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
        const jobsThisMonth = [];
        jobArr.forEach((item) => {
            const itemDate = new Date(item.ReqDate);
            const itemMonth = itemDate.getMonth();
            const itemYear = itemDate.getFullYear();

            if (itemMonth === currentMonth && itemYear === currentYear) {
                jobsThisMonth.push(item);
            }
        });

        let DepartmentSring = ''
        let usedCredsObj = {
            Sales: 0,
            Dev: 0,
            Design: 0
        }
        for (let i = 0; i < jobsThisMonth.length; i++) {
            let jb = jobsThisMonth[i];
            let usedCredits = 0;
            let estimatedHrs = jb.EstimatedHours || 0;
            let actualHrs = jb.ActualHours || 0;
            DepartmentSring = jb.Department;
            if (actualHrs === 0) {
                usedCredits = estimatedHrs;
            } else {
                usedCredits = actualHrs;
            }
            usedCredsObj[DepartmentSring] = usedCredsObj[DepartmentSring] + usedCredits;
        }
        let creditsThisMonthArr = []
        let allCreditsArr = comp.Credits || [];
        for (let i = 0; i < allCreditsArr.length; i++) {
            if (allCreditsArr[i].Month === currentMonth && allCreditsArr[i].Year === currentYear) {
                creditsThisMonthArr.push(allCreditsArr[i])
            }
        }

        let data = comp.Credits;
        addPercentange(data, usedCredsObj);
        forceUpdate();
    }
    if (mobile()) {
        return (
            <div>

            </div>
        );

    } else {
        return (
            <div style={{ width: '100%', height: '88%', overflow: 'scroll' }}>
                <Card className='companyDashboardTasks'>
                    <div className='tasks'>
                        <p style={{ color: 'white' }}><b>Tasks</b></p>
                    </div>
                    <div className='myCards'>
                        <Card className='tasksCardsCompleted'>
                            <div>
                                <img className='inProgressImg' src={Completed} />
                                <p>Completed</p>
                            </div>
                            <card className='cardCard'></card>
                            <h1 style={{ marginLeft: '15%' }}>{CompletedJobs.length}</h1>
                        </Card>
                        <Card className='tasksCardsTodo'>
                            <div>
                                <img className='todopngstyle' src={Todo} />
                                <p>To Do</p>
                            </div>
                            <card className='cardCard'></card>
                            <h1 style={{ marginLeft: '15%' }}>{ToDoJobs.length}</h1>
                        </Card>
                        <Card className='tasksCardsInProgress'>
                            <div>
                                <img className='inProgressImg' src={InProgress} />
                                <p>In Progress</p>
                            </div>
                            <card className='cardCard'></card>
                            <h1 style={{ marginLeft: '15%' }}>{InProgressJobs.length}</h1>
                        </Card>
                        <Card className='tasksCardsNew' style={{ width: '20%' }} onClick={addTaskClicked}>
                            <div>
                                <img className='todopngstyle' src={Plus} />
                                <p>New</p>
                            </div>
                        </Card>
                    </div>
                </Card>
                <div className='bottomContentDiv'>
                    <Card className='financeOverView'>
                        <h2 className='cardName'>Finance Overview</h2>
                        <div style={{ display: "flex" }}>
                            <svg style={{ marginLeft: "-10%" }} height="600" width="400">
                                {MappedActuals.map((data, index) => {
                                    const percentage = (data.actual / (data.target / 4)) * 100;
                                    const radius = 160 - index * 40;
                                    const circumference = 2 * Math.PI * radius;
                                    const strokeDasharray = percentage >= 100 ? 'none' : circumference;
                                    const strokeDashoffset = percentage >= 100 ? 0 : circumference - (percentage / 100) * circumference;
                                    const startAngle = -90;
                                    const endAngle = startAngle + (360 * (percentage / 100));

                                    return (
                                        <circle
                                            key={index}
                                            cx="200"
                                            cy="300"
                                            r={radius}
                                            stroke={data.color}
                                            strokeWidth="5"
                                            fill="none"
                                            strokeDasharray={strokeDasharray}
                                            strokeDashoffset={strokeDashoffset}
                                            transform={`rotate(${startAngle} 200 300)`}
                                        />
                                    );
                                })}
                            </svg>
                            <div className="summary">
                                {MappedActuals.map((data, index) => (
                                    <div key={index} className="summary-item">
                                        <div className="color-dot" style={{ backgroundColor: data.color }}></div>
                                        <div style={{ height: "50%" }} className="summary-info">
                                            <p style={{ display: 'inline-block', marginRight: '10px', color: 'white' }}>{data.id}</p>
                                            <h2 style={{ marginBottom: '5px', color: 'white' }}>R{data.actual || 0}</h2>
                                        </div>
                                    </div>
                                ))}
                                <Card className='moreInfoCard'>More Info</Card>
                            </div>
                        </div>
                    </Card>
                    <Card className='monthlyCreditsView'>
                        <h2 className='cardName'>Monthly Credits</h2>
                        <DoughnutChart Credits={MappedCredits} />
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div className="summary-item">
                                <div className="color-dot" style={{ backgroundColor: 'rgba(227,54,126,1)' }}></div>
                                <div style={{ height: "50%" }} className="summary-info">
                                    <p style={{ display: 'inline-block', marginRight: '10px', color: 'white' }}>Dev Credits</p>
                                    <h2 style={{ marginBottom: '5px', color: 'white' }}>{MappedCredits.Departments.Dev || 0}</h2>
                                </div>
                            </div>
                            <div className="summary-item">
                                <div className="color-dot" style={{ backgroundColor: 'rgba(49,192,209,1)' }}></div>
                                <div style={{ height: "50%" }} className="summary-info">
                                    <p style={{ display: 'inline-block', marginRight: '10px', color: 'white' }}>Sales Credits</p>
                                    <h2 style={{ marginBottom: '5px', color: 'white' }}> {MappedCredits.Departments.Sales || 0}</h2>
                                </div>
                            </div>
                            <div className="summary-item">
                                <div className="color-dot" style={{ backgroundColor: 'rgba(146,140,195,1)' }}></div>
                                <div style={{ height: "50%" }} className="summary-info">
                                    <p style={{ display: 'inline-block', marginRight: '10px', color: 'white' }}>Design Credits</p>
                                    <h2 style={{ marginBottom: '5px', color: 'white' }}> {MappedCredits.Departments.Design || 0}</h2>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                {Task ? (
                    <Card className='requestNewTaskCardsForm'>
                        <div className='AddDataCardHeaders'>
                            <h1>New Task</h1>
                            <img className='closeAddActualsCard' onClick={closeAddTaskData} src={CloseCard} />
                        </div>
                        <div className='AddDataCardBody' style={{ marginTop: '5%' }}>
                            <Input className="newTaskName" placeholder='Task Name' value={JobRequest.Title} id="title" onChange={jobRequestChange} />
                            <div className='selectDepartmentDiv'>
                                <div className='selectDueDateDiv'>
                                    <select className="selectDepartmentdiv" style={{ marginBottom: "10px", marginTop: "10px", width: "80%", height: '40%' }} id="Department" value={JobRequest.Department} onChange={jobRequestChange}>
                                        <option>Select department</option>
                                        <option value="Design">Design</option>
                                        <option value="Dev">Dev</option>
                                        <option value="Sales">Sales</option>
                                    </select>
                                </div>
                                <div className='selectDueDateDiv'>
                                    Requested due date: <Input className="newTaskDueDate" type='date' value={JobRequest.DueDate} onChange={jobRequestChange} id="dueDate" />
                                </div>
                            </div>
                            <hr style={{ marginTop: '-5%' }} />

                            <div className='moreCollateralInfo'>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                    {JobRequest.Department === "Design" ? (
                                        <>
                                            <select className="dropdownSelect" style={{ marginBottom: "10px", marginTop: "10px", width: "80%" }} id="category" value={JobRequest.DesignSpec.category} onChange={jobRequestChange}>
                                                <option>Category</option>
                                                <option value="StillGraphics">Still Graphics</option>
                                                <option value="WebDesign">Web Design</option>
                                                <option value="Video">Video</option>
                                                <option value="Animation">Animation</option>
                                                <option value="Photo">Photo</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </>
                                    ) : null}
                                    {JobRequest.DesignSpec.category === "StillGraphics" ? (
                                        <>
                                            <select className="dropdownSelect" value={JobRequest.DesignSpec.collaterals} id="Collaterals" onChange={jobRequestChange} style={{ marginBottom: "10px", marginTop: "10px", width: "80%" }}>
                                                <option>Collateral required</option>
                                                <option value='SocialMedia'>Social Media</option>
                                                <option value='Flyer'>Flyer</option>
                                                <option value='Brochure'>Brochure</option>
                                                <option value='Poster'>Poster</option>
                                                <option value='Presentation'>Presentation</option>
                                                <option value='Other'>Other</option>
                                            </select>
                                        </>
                                    ) : null}
                                    {JobRequest.DesignSpec.category === "WebDesign" ? (
                                        <>
                                            <select className="dropdownSelect" value={JobRequest.DesignSpec.collaterals} id="Collaterals" onChange={jobRequestChange} style={{ marginBottom: "10px", marginTop: "10px", width: "80%" }}>
                                                <option>Collateral required</option>
                                                <option value='NewWebsiteDesign'>New website design</option>
                                                <option value='NewAppDesign'>New app design</option>
                                                <option value='WebUpdates'>Web updates</option>
                                                <option value='AppUpdates'>App updates</option>
                                            </select>
                                        </>
                                    ) : null}
                                    {JobRequest.DesignSpec.category === "Video" ? (
                                        <>
                                            <select className="dropdownSelect" value={JobRequest.DesignSpec.collaterals} id="Collaterals" onChange={jobRequestChange} style={{ marginBottom: "10px", marginTop: "10px", width: "80%" }}>
                                                <option>Collateral required</option>
                                                <option value='InterView'>Interview</option>
                                                <option value='ProductVideoShoot'>Product video shoot</option>
                                                <option value='Podcast'>Podcast</option>
                                                <option value='Advert'>Advert</option>
                                                <option value='CorporateVideo'>Corporate/Explainer video</option>
                                            </select>
                                        </>
                                    ) : null}
                                    {JobRequest.DesignSpec.category === "Animation" ? (
                                        <>
                                            <select className="dropdownSelect" value={JobRequest.DesignSpec.collaterals} id="Collaterals" onChange={jobRequestChange} style={{ marginBottom: "10px", marginTop: "10px", width: "80%" }}>
                                                <option>Collateral required</option>
                                                <option value='InfoGraphics'>Info Graphics</option>
                                                <option value='CharacterAnimation'>Character animation</option>
                                            </select>
                                        </>
                                    ) : null}
                                    {JobRequest.DesignSpec.category === "Photo" ? (
                                        <>
                                            <select className="dropdownSelect" value={JobRequest.DesignSpec.collaterals} id="Collaterals" onChange={jobRequestChange} style={{ marginBottom: "10px", marginTop: "10px", width: "80%" }}>
                                                <option>Collateral required</option>
                                                <option value='Product'>Product</option>
                                                <option value='Lifestyle'>Lifestyle</option>
                                                <option value='Corporate'>Corporate</option>
                                            </select>
                                        </>
                                    ) : null}
                                </div>
                                {JobRequest.Department === "Design" ? (
                                    <select className="newTaskName" style={{ marginBottom: "10px", marginTop: "10px", width: "80%" }} id="Platform" value={JobRequest.DesignSpec.Platform} onChange={jobRequestChange}>
                                        <option>Select platform for use</option>
                                        <option value="SocialMediaPost">SocialMediaPost</option>
                                        <option value="SocialMediaStory">SocialMediaStory</option>
                                        <option value="Website">Website</option>
                                        <option value="DigitalPDF">DigitalPDF</option>
                                        <option value="PrintPDF">PrintPDF</option>
                                        <option value="Youtube">Youtube</option>
                                        <option value="GoogleCampaigns">GoogleCampaigns</option>
                                        <option value="LiveEvents">LiveEvents</option>
                                        <option value="Other">Other</option>
                                    </select>
                                ) : null}

                            </div>
                            {JobRequest.Department === "Design" ? (
                                <div className='TaskInformationCard'>
                                    <p>Size/Dimensions</p>
                                    <Input value={JobRequest.DesignSpec.Size} id='Size' onChange={jobRequestChange} placeholder='example: A4 full-color double sided flyer, website banner' />
                                    <p>Descriptions</p>
                                    <Input value={JobRequest.Description} id='Description' onChange={jobRequestChange} placeholder='Enter description' />
                                    <p>Full description of elements required</p>
                                    <Input value={JobRequest.DesignSpec.FullDescription} id='FullDescription' onChange={jobRequestChange} placeholder='Full description' />
                                    <p>Copy if required</p>
                                    <Input value={JobRequest.DesignSpec.CopyIfRequired} id='CopyIfRequired' onChange={jobRequestChange} placeholder='Link to copy' />
                                    <p>Attachments</p>
                                    <Input value={JobRequest.DesignSpec.Attachments} id='Attachments' onChange={jobRequestChange} placeholder='Google drive link' />
                                </div>
                            ) : null}

                            {JobRequest.Department === "Dev" ? (
                                <>
                                    <p>Descriptions</p>
                                    <Input value={JobRequest.Description} id='Description' onChange={jobRequestChange} placeholder='Enter description' />
                                    <select style={{ marginBottom: "10px", marginTop: "10px", width: "80%" }} value={JobRequest.DevSpec.Platform} id="Platform" onChange={jobRequestChange} className="dropdownSelect">
                                        <option value="Select platform">Select platform</option>
                                        <option value="App">App</option>
                                        <option value="Website">Website</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    <select style={{ marginBottom: "10px", marginTop: "10px", width: "80%" }} value={JobRequest.DevSpec.Type} id="Type" onChange={jobRequestChange} className="dropdownSelect">
                                        <option value="Select Type">Select Type</option>
                                        <option value="Bug fixes">Bug fixes</option>
                                        <option value="feature requests">feature requests</option>
                                        <option value="support meetings">support meetings</option>
                                        <option value="Other">Other</option>
                                    </select>

                                    <textarea style={{ resize: "none", borderRadius: "15px", width: "80%", height: "80px", marginBottom: "5px", marginTop: "5px" }} id="Outcomes" className="textAreaDiv" onChange={jobRequestChange} value={JobRequest.DevSpec.Outcomes} placeholder="What should this project achieve?" />
                                    <select style={{ marginBottom: "10px", marginTop: "10px", width: "80%" }} value={JobRequest.DevSpec.Figma_Available} id="Figma_Available" onChange={jobRequestChange} className="dropdownSelect">
                                        <option value="Select ">Figma available?</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </>
                            ) : null}
                        </div>
                        <div className='sendTaskRequestDiv'>
                            <Button onClick={RequestJobClicked}>Send</Button>
                        </div>
                    </Card>
                ) : null}
            </div>
        )
    }
})

export default Dashboard;