import React from 'react';
import './style.scss';

function Teaxtarea({ className, id, placeholder, type, value, onKeyDown, onChange, style = {} }) {
    const customStyle = style
    return (
        <div className={`teaxtArea ${className}`} style={customStyle} type={type} id={id} value={value} onKeyDown={onKeyDown} onChange={onChange}>
            <p className='placeHolder'>{placeholder}</p>
            <textarea type={type} id={id} value={value} onKeyDown={onKeyDown} onChange={onChange}/>
        </div>
    )
}

export default Teaxtarea
