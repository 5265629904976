import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import './style.css'
import { useNavigate } from 'react-router-dom';
import { Input, View, Button, Card, DoughnutChart, SalesFunnelTable, AionsESDFunnel, AEXFunnel, AionsESDFunnelBar } from '../../../components';
var mobile = require('is-mobile');

const OKRSessions = observer(() => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
    }, []);

    if (mobile()) {
        return (
            <div>

            </div>
        );

    } else {
        return (
            <div className='container' style={{ width: '100%', height: '88%', overflow: 'scroll', backgroundColor: 'white' }} >
            </div>
        )
    }
})

export default OKRSessions;