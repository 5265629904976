import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import EditCourse from '../EditCourse';
import { useNavigate } from 'react-router-dom';
import { FaShareSquare } from 'react-icons/fa'
import { Card, Input, View, Button, MobileTabBarItem, MobileTabBar } from '../../../components';
import isMobile from 'is-mobile';


const AllCourses = observer(({ CurrentPage, navClicked, selectCourseId }) => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [CourseDataFromBase, setCourseDataFromBase] = useState([]);
    const [Courses, setCourses] = useState([]);
    const [CourseId, setCourseId] = useState('');

    useEffect(() => {
        getAllCourses();
    }, []);

    function getAllCourses() {
        store.setLoading(true);
        store.getCourse().then((ret) => {
            setCourses(ret);
            store.setLoading(false);
        });
        forceUpdate();
    }

    function handleClickCreate() {
        navClicked('CreateCourse');
    };

    function handleClickEdit(courseId) {
        navClicked('EditCourse', courseId);
    }

    function handleClickDetails(courseId) {
        navClicked('CourseDetails', courseId);
    };

    if (isMobile()) {
        return (
            <div>
                <Button className="newCourseButtonMobile" id={CurrentPage} onClick={handleClickCreate}>New Course</Button>
                <Card className='descriptionCard'>
                    <p className='descriptionCardLbalel'>Course Name</p>
                    <p className='descriptionCardLbalel'>Completed</p>
                    <p className='descriptionCardLbalel'>Outstanding</p>
                </Card>
                <Card className='newCourseMapMainCard'>
                    {Courses && Courses.length > 0 ? (
                        Courses.map((course, index) => (
                            <Card className='newCourseMapInnerCard' key={course._id}>
                                <div className='descriptionCardLbalel'>
                                    <p>{course.Name}</p>
                                </div>
                                <div className='descriptionCardLbalel'>
                                    <div>{course.CompletedBy.length}</div>
                                </div>
                                <div className='descriptionCardLbalel'>
                                    <div>{course.RequiredBy.length}</div>
                                </div>
                                <div className='courseEditButtonDiv'>
                                    <Button className='courseEditButton' value={CurrentPage} onClick={() => handleClickEdit(course._id)}>Edit</Button>
                                    <FaShareSquare className='newPage' value={CurrentPage} onClick={() => handleClickDetails(course._id)} />
                                </div>
                            </Card>
                        ))
                    ) : (
                        <Card><h1>No available courses!</h1></Card>
                    )}
                </Card>
            </div>
        )
    } else {
        return (
            <div>
                <Button className="newCourseButton" id={CurrentPage} onClick={handleClickCreate}>New Course</Button>
                <Card className='descriptionCard'>
                    <p className='descriptionCardLbalel'>Course Name</p>
                    <p className='descriptionCardLbalel'>Completed</p>
                    <p className='descriptionCardLbalel'>Outstanding</p>
                </Card>
                <Card className='newCourseMapMainCard'>
                    {Courses && Courses.length > 0 ? (
                        Courses.map((course, index) => (
                            <Card className='newCourseMapInnerCard' key={course._id}>
                                <div className='descriptionCardLbalel'>
                                    <p>{course.Name}</p>
                                </div>
                                <div className='descriptionCardLbalel'>
                                    <div>{course.CompletedBy.length}</div>
                                </div>
                                <div className='descriptionCardLbalel'>
                                    <div>{course.RequiredBy.length}</div>
                                </div>
                                <div className='courseEditButtonDiv'>
                                    <Button className='courseEditButton' value={CurrentPage} onClick={() => handleClickEdit(course._id)}>Edit</Button>
                                    <FaShareSquare className='newPage' value={CurrentPage} onClick={() => handleClickDetails(course._id)} />
                                </div>
                            </Card>
                        ))
                    ) : (
                        <Card><h1>No available courses!</h1></Card>
                    )}
                </Card>
            </div>
        );
    }
})

export default AllCourses;