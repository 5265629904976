import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { useNavigate } from 'react-router-dom';
import { Card, Input, Button } from '../../../../components';
import { FaUpload, FaDownload, FaAngleRight, FaPlus, FaTrash } from 'react-icons/fa';
import isMobile from 'is-mobile';

const Config = observer(({ SelectedCompanyId }) => {
  const store = useStore();
  const [, updateState] = useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const navigate = useNavigate();
  const [Companies, setCompanies] = useState([]);
  const [Metrics, setMetrics] = useState([]);
  const [CustomMetricsName, setCustomMetricsName] = useState('');
  const [metricValues, setMetricValues] = useState({});

  useEffect(() => {
    selectedCompany();
  }, []);

  function selectedCompany() {
    store.setLoading(true);
    store.getCompanyByID(SelectedCompanyId).then((ret) => {
      if (typeof ret === 'string' || !ret) {
        setCompanies([]);
        setMetrics([]);
        store.setLoading(false);
        return;
      }
      setCompanies(ret);
      if (ret.Config && ret.Config.MetricList) {
        setMetrics(ret.Config.MetricList);
      } else {
        setMetrics([]);
      }
      store.setLoading(false);
    });
    forceUpdate();
  }

  // Default Metrics
  function metricSwitchToggled(e) {
    let tmpMetrics = Metrics;
    if (tmpMetrics.includes(e.currentTarget.id)) {
      let ind = tmpMetrics.indexOf(e.currentTarget.id);
      tmpMetrics.splice(ind, 1);
    } else {
      tmpMetrics.push(e.currentTarget.id);
    }
    setMetrics(tmpMetrics);
    forceUpdate();
  }
  const defaultMetrics = [
    'TotalSales',
    'NumberOfMeetings',
    'CostOfSales',
    'NewCustomers',
    'AverageRevenuePerCustomer',
    'OperatingExpenses',
    'RecurringRevenue',
    'TotalClients',
  ];
  // Custom Metrics
  function addCustomMetric() {
    let tmpMetrics = Metrics;
    tmpMetrics.push(CustomMetricsName);
    setMetrics(tmpMetrics);
    setCustomMetricsName('');
  }
  function deleteCustomMetric(e) {
    let tmpMetrics = Metrics;
    let ind = tmpMetrics.indexOf(e.currentTarget.id);
    tmpMetrics.splice(ind, 1);
    setMetrics(tmpMetrics);
    forceUpdate();
  }
  function customNameChange(e) {
    setCustomMetricsName(e.target.value);
  }

  function addMetrics() {
    let id = Companies._id;
    let config = {
      MetricList: Metrics,
      ...Metrics.reduce((acc, metric) => {
        acc[metric] = [];
        return acc;
      }, {}),
    };

    for (const metric in metricValues) {
      if (metricValues.hasOwnProperty(metric) && !metric.startsWith('input_')) {
        config[metric] = metricValues[metric];
      }
    }

    let data = {
      Config: config,
    };
    store.setLoading(true);
    store
      .updateCompanyById(id, data)
      .then((ret) => {
        if (ret) {
          window.alert('Config added successfully');
          store.setLoading(false);
        }
      })
      .catch((error) => {
        window.alert('Error updating company');
        console.error('Error updating company:', error);
        store.setLoading(false);
      });
  }
  function saveMetricValue(metric) {
    const inputValue = metricValues[`input_${metric}`]?.trim();
    if (inputValue) {
      setMetricValues({
        ...metricValues,
        [metric]: [...(metricValues[metric] || []), inputValue],
      });
    }
  }
  return (
    <div>
      <card className="customMetricsSettings">
        <div className="addCard">
          <Input
            className="customMetricsCard"
            placeholder="Custom Metrics"
            onChange={customNameChange}
            value={CustomMetricsName}
          />
        </div>
        <div className="addCustomConfigurations">
          <button className="addCategoryButtons" onClick={addCustomMetric}>
            Add
          </button>
        </div>
      </card>
      <div id="defaultMetrics">
        {defaultMetrics.map((metric, index) => {
          if (
            metric === 'TotalSales' ||
            metric === 'NumberOfMeetings' ||
            metric === 'CostOfSales' ||
            metric === 'NewCustomers' ||
            metric === 'AverageRevenuePerCustomer' ||
            metric === 'OperatingExpenses' ||
            metric === 'RecurringRevenue' ||
            metric === 'TotalClients'
          ) {
            return (
              <div key={index} style={{ width: '100%' }}>
                <card id={`${metric}Card`} className="customMetricsSettings">
                  <div className="metricsDiv" id={metric}>
                    {metric}
                  </div>
                  <div className="addMetricsIcon">
                    <div className="switch-container" id={metric}>
                      <div
                        className={`switch ${Metrics.includes(metric) ? 'on' : 'off'
                          }`}
                        id={metric}
                        onClick={metricSwitchToggled}
                      >
                        <div
                          className={`toggle ${Metrics.includes(metric) ? 'on' : 'off'
                            }`}
                          id={metric}
                        />
                      </div>
                    </div>
                  </div>
                </card>
                <div style={{ width: '40%', display: 'flex', flexDirection: 'row' }}>
                  {metricValues[metric] &&
                    metricValues[metric].map((value, index) => (
                      <div key={index}>{value}</div>
                    ))}
                  <Input
                    placeholder={`Enter ${metric}`}
                    value={metricValues[`input_${metric}`] || ''}
                    onChange={(e) => {
                      setMetricValues({
                        ...metricValues,
                        [`input_${metric}`]: e.target.value,
                      });
                    }}
                  />
                  <button className="addCategoryButtons" onClick={() => saveMetricValue(metric)}>Save</button>
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
      {Metrics.map((metric, index) => {
        if (
          metric === 'TotalSales' ||
          metric === 'NumberOfMeetings' ||
          metric === 'CostOfSales' ||
          metric === 'NewCustomers' ||
          metric === 'AverageRevenuePerCustomer' ||
          metric === 'OperatingExpenses' ||
          metric === 'RecurringRevenue' ||
          metric === 'TotalClients'
        ) {
          return null;
        }
        return (
          <div key={index}>
            <card className="customMetricsSettings">
              <p className="metricsDiv">{metric}</p>
              <div className="addMetricsIconDelete" id={metric}>
                <FaTrash id={metric} onClick={deleteCustomMetric} />
              </div>
            </card>
            <div style={{ width: '40%', display: 'flex', flexDirection: 'row' }}>
              {metricValues[metric] &&
                metricValues[metric].map((value, index) => (
                  <div key={index}>{value}</div>
                ))}
              <Input
                placeholder={`Enter ${metric}`}
                value={metricValues[`input_${metric}`] || ''}
                onChange={(e) => {
                  setMetricValues({
                    ...metricValues,
                    [`input_${metric}`]: e.target.value,
                  });
                }}
              />
              <button className="addCategoryButtons" onClick={() => saveMetricValue(metric)}>Save</button>
            </div>
          </div>
        );
      })}
      <card className="customConfigSettings">
        <button className="addCategoryButtons" onClick={addMetrics}>Save</button>
      </card>
    </div>
  );
});

export default Config;
