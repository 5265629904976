import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import CloseCard from '../../../assets/images/closeCard.png';
import { useNavigate } from 'react-router-dom';
import dashLogo from '../../../assets/images/dashLogo.png';
import Todo from '../../../assets/images/todo.png';
import UpdateActualsIcon from '../../../assets/images/system-update.png';
import ChartIcon from '../../../assets/images/chart.png';
import Plus from '../../../assets/images/plus.png';
import './style.css';
import { Input, View, Button, Card, DoughnutChart } from '../../../components';
import { PolarArea } from 'react-chartjs-2';
import { FaHome, FaRocketchat, FaEnvelopeOpenText, FaPaperPlane, FaRegCommentDots, FaRegFileAlt } from 'react-icons/fa';
var mobile = require('is-mobile');

const PreviousActuals = observer(() => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [PrevActuals, setPreviousActuals] = useState([]);
    const [SelectedActual, setSelectedActual] = useState({});
    const [SelectedActualIndex, setSelectedActualIndex] = useState(-1);
    const [Company, setCompany] = useState({});
    const [inputValues, setInputValues] = useState({});
    const [UpdateActuals, setUpdateActuals] = useState(false);
    const [MetricList, setMetricList] = useState([]);

    useEffect(() => {
        loadCompanies();
    }, []);

    function formatDate(dateString) {
        const options = { day: "numeric", month: "short", year: "numeric" };
        const dateObj = new Date(dateString);
        return dateObj.toLocaleDateString("en-US", options);
    }
    function loadCompanies() {
        store.setLoading(true);
        store.getCompaniesByPerms(store.user.Email).then((ret) => {
            if (typeof ret === 'string' || !ret) {
                setCompany({});
                store.setLoading(false);
                return;
            }
            if (ret.length === 0) {
                setCompany({});
                store.setLoading(false);
                return;
            }
            let comp = ret.filter((item) => item.Name === store.user.Company);
            if (comp.length === 0) {
                setCompany({});
                store.setLoading(false);
                return;
            }
            setCompany(comp[0]);
            setMetricList(comp[0].Config.MetricList);
            setPreviousActuals(comp[0].Actual);
            forceUpdate();
            store.setLoading(false);
        });
        forceUpdate();
    }
    function updateActualsClicked() {
        let name = Company.Name
        let actualToUpdate = PrevActuals;
        actualToUpdate[SelectedActualIndex] = SelectedActual;
        let data = {
            Actual: actualToUpdate
        }
        store.setLoading(true);
        store.updateCompaniesByName(data, name).then((retObj) => {
            if (retObj) {
                window.alert("Company updated Successfully..!");
                store.setLoading(false);
            } else {
                window.alert("Company updated Unsuccessfully..!");
                store.setLoading(false);
            }
        });
    }
    function handleInputChange(e) {
        const { name, value, id } = e.target;
        let tempSelectedActual = SelectedActual;
        tempSelectedActual.ActualsDetails[id][name].Amount = value;
        let metricName = id.replace('Details', '')
        let total = 0
        for (let i = 0; i < tempSelectedActual.ActualsDetails[id].length; i++) {
            total += Number(tempSelectedActual.ActualsDetails[id][i].Amount)
        }
        Number(tempSelectedActual[metricName] = total);
        setSelectedActual(tempSelectedActual)
        forceUpdate()
    }
    function removeActualAtIndex(indexToRemove) {
        const updatedPrevActuals = [...PrevActuals];
        updatedPrevActuals.splice(indexToRemove, 1);
        setPreviousActuals(updatedPrevActuals);
        let name = Company.Name
        let data = {
            Actual: updatedPrevActuals
        }
        store.setLoading(true)
        store.updateCompaniesByName(data, name).then((retObj) => {
            if (retObj) {
                window.alert("Company updated Successfully..!");
                setUpdateActuals(false);
                store.setLoading(false);
            } else {
                window.alert("Company updated Unsuccessfully..!");
                setUpdateActuals(false);
                store.setLoading(false);
            }
        });
        forceUpdate();
    }
    function closeUpdateActuals() {
        setUpdateActuals(false);
    }
    const categoryTotals = {};
    function handleUpdateClick(index) {
        setUpdateActuals(true);
        setSelectedActualIndex(index);
        setSelectedActual(PrevActuals[index]);
    }

    if (mobile()) {
        return <div></div>;
    } else {
        return (
            <div className="container" style={{ width: '100%', height: '88%', overflow: 'scroll' }}>
                <card className="allCompaniesCard">
                    <h1>Previous Actuals</h1>
                    <div className="myCards">
                        <Card className="tasksCardsCompleted">
                            <div>
                                <img className="inProgressImg" src={ChartIcon} />
                                <p>Total Actuals</p>
                            </div>
                            <card className="cardCard"></card>
                            <h1>{PrevActuals.length || 0}</h1>
                        </Card>
                    </div>
                </card>
                <div className="bottomMappedCompanies">
                    {PrevActuals.map((actuals, index) => (
                        <card key={actuals} title={actuals} className="usersCardWithUpdateButton">
                            <div style={{ width: '90%' }}>
                                <p style={{ marginLeft: '2%' }}>{formatDate(actuals.Date)}</p>
                            </div>
                            <card className="companyConfigButtons" onClick={() => handleUpdateClick(index)}>
                                <img src={UpdateActualsIcon} />
                                <p style={{ marginLeft: '10%' }}>Update</p>
                            </card>
                        </card>
                    ))}
                </div>
                {UpdateActuals ? (
                    <Card className='addForecast'>
                        <div className='AddDataCardHeaders'>
                            <h1>Update Actuals</h1>
                            <img className='closeAddActualsCard' onClick={closeUpdateActuals} src={CloseCard} />
                        </div>
                        <div className="DateDiv">
                            <p className="pElement" style={{ width: '80%' }}>Actuals Date :</p>
                            <Input className='InputDateElement' placeholder={formatDate(SelectedActual.Date)} id="Date" />
                        </div>
                        {SelectedActual && SelectedActual.ActualsDetails ? (
                            <div className='updateActualsDiv'>
                                {Object.keys(SelectedActual.ActualsDetails).map((categoryKey) => {
                                    return (
                                        <div key={categoryKey}>
                                            <h3>{categoryKey.replace("Details", "")}</h3>
                                            {Array.isArray(SelectedActual.ActualsDetails[categoryKey]) ? (
                                                SelectedActual.ActualsDetails[categoryKey].map((item, index) => {
                                                    if (Array.isArray(SelectedActual.ActualsDetails[categoryKey])) {
                                                        const total = SelectedActual.ActualsDetails[categoryKey].reduce((acc, item) => {
                                                            return acc + item.Amount;
                                                        }, 0);

                                                        categoryTotals[categoryKey.replace("Details", "")] = total;
                                                    }
                                                    return (
                                                        <div key={index} style={{ display: 'flex', flexDirection: 'row', marginLeft: '5%', width: '100%' }}>
                                                            <p style={{ width: '60%' }}><b>{item.Category}</b> :</p>
                                                            <input
                                                                className='updateActualsInputs'
                                                                type="number"
                                                                name={`${index}`}
                                                                id={categoryKey}
                                                                value={item.Amount}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    )
                                                })
                                            ) : (
                                                <p>No data available for this category.</p>
                                            )}
                                            <hr />
                                        </div>
                                    )
                                })}
                            </div>
                        ) : null}

                        <div style={{ width: '100%', alignContent: 'center', height: '10%' }}>
                            <Button className='addNewUserButton' onClick={updateActualsClicked}>Update Actuals</Button>
                            <Button className='removePrevActuals' onClick={removeActualAtIndex}>Remove Actuals</Button>
                        </div>
                    </Card>
                ) : null}
            </div>
        );
    }
});

export default PreviousActuals;
