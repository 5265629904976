import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import "chartjs-plugin-datalabels";

function DoughnutChart({ Credits }) {
    const departments = Credits.Departments;
    const labels = Object.keys(departments);
    const dataValues = Object.values(departments);
    const totalCredits = dataValues.reduce((acc, value) => acc + value, 0);

    const [hoveredIndex, setHoveredIndex] = useState(null);

    const data = {
        labels: labels,
        datasets: [
            {
                label: "Credits",
                data: dataValues,
                backgroundColor: [
                    "rgba(49,192,209,1)",
                    "rgba(227,54,126,1)",
                    "rgba(146,140,195,1)",
                ],
                hoverBackgroundColor: [
                    "rgba(49,192,209,0.8)",
                    "rgba(227,54,126,0.8)",
                    "rgba(146,140,195,0.8)",
                ],
                hoverOffset: 4,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                labels: {
                    color: "white",
                },
            },
            datalabels: {
                color: "white",
                display: true,
                anchor: 'end',
            },
        },
    };

    return (
        <div>
            <Doughnut
                data={data}
                options={options}
                height={200}
            />
        </div>
    );
}

export default DoughnutChart;
