import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { useNavigate } from 'react-router-dom';
import {
    Input,
    Button,
} from '../../../../components';
import isMobile from 'is-mobile';

const AddTargets = observer(({ SelectedCompanyId }) => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const navigate = useNavigate();
    const [Company, setCompany] = useState([]);
    const [CompanyMetrics, setCompanyMetrics] = useState([]);
    const [NewTarget, setNewTarget] = useState({});
    useEffect(() => {
        selectedCompany();
    }, []);

    function selectedCompany() {
        let newTar = {};
        store.setLoading(true);
        store.getCompanyByID(SelectedCompanyId).then((ret) => {
            if (typeof ret === "string" || !ret) {
                setCompany([]);
                store.setLoading(false);
                return;
            }
            let tmpMetrics = ret.Config.MetricList || [];
            setCompanyMetrics(ret.Config.MetricList || [])
            setCompany(ret);
            for (let i = 0; i < tmpMetrics.length;i++){
                newTar[tmpMetrics[i]]=0
            }
            newTar.Date = new Date()
            setNewTarget(newTar);
            store.setLoading(false);
        });
    }
    function AddNewTargetsClicked() {
        let id = Company._id;
        let tar = Company.Target;
        tar.push(NewTarget)
        let data = {
            Target: tar,
        };
        store.setLoading(true);
        store.updateCompanyById(id, data).then((ret) => {
            if (ret) {
                window.alert("Targets added successfully");
                handleClear();
                store.setLoading(false);
            }
        })
            .catch((error) => {
                window.alert("What are you doing")
                console.error("Error updating company:", error);
                store.setLoading(false);
            });
            
    }
    function handleClear() {
       
        forceUpdate();
    }
    function getNewTargets(e){
        let newTar = NewTarget;
        let currentMatric =  Object.keys(newTar).filter(item => item === e.currentTarget.id)
        if(currentMatric){
           newTar[currentMatric]=Number(e.target.value)
        }
        if (e.currentTarget.id === 'Date'){
            newTar.Date = e.target.value
        }
        setNewTarget(newTar);
        forceUpdate();
    }

    if (isMobile()) {
        return (
            <div className='updateTargetsPage'>
                <h1 className="targetHeader">New Targets</h1>
                <div className="inputDiv">
                    <p className="targetName">Date:</p>
                    <Input type="date" id='Date' value={NewTarget.Date} onChange={getNewTargets} />
                </div>
                {CompanyMetrics.map((metrics, index) => (
                    <div className="inputDiv" key={index}>
                        <p className="targetName">{metrics}</p>
                        <Input type="number" id={metrics} onChange={getNewTargets} value={Object.values(NewTarget)[index].toString()} />
                    </div>
                ))}
                <div className="elementStylingForButtons">
                    <Button className="buttonForCompanyPages" onClick={handleClear}>Cancel</Button>
                    <Button className="buttonForCompanyPages" onClick={AddNewTargetsClicked}>Submit</Button>
                </div>
            </div>
        )
    } else {
        return (
            <div className='updateTargetsPage'>
                <h1 className="targetHeader">New Targets</h1>
                <div className="inputDiv">
                    <p className="targetName">Date:</p>
                    <Input type="date" id='Date' value={NewTarget.Date} onChange={getNewTargets} />
                </div>
                {CompanyMetrics.map((metrics, index) => (
                    <div className="inputDiv" key={index}>
                        <p className="targetName">{metrics}</p>
                        <Input type="number" id={metrics} onChange={getNewTargets} value={Object.values(NewTarget)[index].toString()} />
                    </div>
                ))}
                <div className="elementStylingForButtons">
                    <Button className="buttonForCompanyPages" onClick={handleClear}>Cancel</Button>
                    <Button className="buttonForCompanyPages" onClick={AddNewTargetsClicked}>Submit</Button>
                </div>
            </div>
        )
    }
})

export default AddTargets;