import { observer } from "mobx-react-lite";
import { useStore } from "../../../stores/Store";
import { Card, Input, View, Button, LoadingBarGauge, JobRequestPDF } from "../../../components";
import "./style.css";
import React, { useEffect, useState } from "react";
import { Colors } from "chart.js";
import { FaTimes } from 'react-icons/fa';
import decline from "../../../assets/images/decline.png"
import accept from "../../../assets/images/accept.png"
import submit from "../../../assets/images/submit.png"
import close from "../../../assets/images/close.svg"
import { act } from "react-dom/test-utils";
const { PDFDocument, StandardFonts } = require("pdf-lib");
var mobile = require("is-mobile");

const Service = observer(() => {

    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [DesignTabs, setDesignTabs] = useState('requested');
    const [Jobs, setJobs] = useState([]);
    const [ShowData, setShowData] = useState(false);
    const [SelectedJob, setSelectedJob] = useState({});
    const [SelectedJobInfo, setSelectedJobInfo] = useState('Details');
    const [Decision, setDecision] = useState('');
    const [DeclinedRes, setDeclinedRes] = useState('');
    const [RejectedJobID, setRejectedJobID] = useState('');
    const [ActualDate, setActualDate] = useState('');
    const [JobClicked, setJobClicked] = useState(false);
    const [DevSpecs, setDevSpecs] = useState({});
    const [Comment, setComment] = useState('');
    const [Comments, setComments] = useState([]);
    const [Companies, setCompanies] = useState([])
    const [JobDetails, setJobDetails] = useState(false);
    const [CreditsArray, setCreditsArray] = useState([]);
    const [JsxCredit, setJsxCredit] = useState(0)
    const [RemovedCrds, setRemovedCrds] = useState(0);
    const [CommentObj, setCommentObj] = useState({
        Comment: '',
        Date: new Date(),
        Email: ''
    })
    useEffect(() => {
        getJobs();
        getMappedCredits();
    }, []);

    function getJobs() {
        store.setLoading(true);
        store.getJob().then((ret) => {
            if (typeof ret === "string" || !ret) {
                setJobs([]);
                store.setLoading(false);
                return;
            }
            let designJobs = ret.filter((a) => a.Department === store.user.Role);
            setJobs(designJobs);
            forceUpdate();
            store.setLoading(false);


            store.getCompanies().then((companies) => {
                if (typeof companies === "string" || !companies) {
                    setCompanies([]);
                    store.setLoading(false);
                    return;
                }

                const currentMonth = new Date().getMonth();
                const currentYear = new Date().getFullYear();
                const creditArray = [];
                const usedCredsObj = {
                    Sales: 0,
                    Dev: 0,
                    Design: 0
                };

                for (let i = 0; i < designJobs.length; i++) {
                    let jb = designJobs[i];
                    let usedCredits = 0;
                    let estimatedHrs = jb.EstimatedHours || 0;
                    let actualHrs = jb.ActualHours || 0;
                    let departmentString = jb.Department;
                    if (actualHrs === 0) {
                        usedCredits = estimatedHrs;
                    } else {
                        usedCredits = actualHrs;
                    }
                    usedCredsObj[departmentString] = usedCredsObj[departmentString] + usedCredits;
                }

                for (let i = 0; i < companies.length; i++) {
                    let company = companies[i];
                    let companyCredits = [...(company.Credits || [])];
                    let currMonthData = companyCredits.find(entry => entry.Month === currentMonth);

                    if (currMonthData) {
                        currMonthData.Departments.Sales = ((currMonthData.Departments.Sales || 0) - usedCredsObj.Sales);
                        currMonthData.Departments.Dev = ((currMonthData.Departments.Dev || 0) - usedCredsObj.Dev);
                        currMonthData.Departments.Design = ((currMonthData.Departments.Design || 0) - usedCredsObj.Design);
                    } else {
                        currMonthData = {
                            Month: currentMonth,
                            Year: currentYear,
                            Departments: {
                                Sales: 0 - usedCredsObj.Sales,
                                Dev: 0 - usedCredsObj.Dev,
                                Design: 0 - usedCredsObj.Design,
                            }
                        };
                        companyCredits.push(currMonthData);
                    }

                    creditArray.push({
                        CompanyName: company.Name,
                        RemainingCredits: currMonthData.Departments
                    });

                }
                setCreditsArray(creditArray);
                store.setLoading(false);
            });
        });
    }
    function getMappedCredits() {
    }
    function pageSelected(e) {
        setDesignTabs(e.target.id);

    }
    function formatDate(dateString) {
        const options = { day: "numeric", month: "short", year: "numeric" };
        const dateObj = new Date(dateString);
        return dateObj.toLocaleDateString("en-US", options);
    }
    function showDataButton(e) {
        let removedCred = RemovedCrds;
        setSelectedJob(Jobs[Number(e.currentTarget.id)]);
        removedCred = (Jobs[Number(e.currentTarget.id)].Credits - Jobs[Number(e.currentTarget.id)].ActualHours)
        setRemovedCrds(removedCred)

        var tmpComments = [];
        let key = Object.keys(Jobs[Number(e.currentTarget.id)]).filter(item => item === 'Comment');
        if (key !== '') {
            tmpComments = Jobs[Number(e.currentTarget.id)].Comment || [];
        }
        setComments(tmpComments);
        setShowData(true);

    }
    function closeJobDetail() {
        setSelectedJob({});
        setShowData(false);
        forceUpdate();
    }
    function selectedJobInfoSelected(e) {
        setSelectedJobInfo(e.currentTarget.id);
    }
    function selectedJobChanged(e) {
        let job = SelectedJob;
        if (e.currentTarget.id === 'EstimatedHours' || e.currentTarget.id === 'ActualHours') {
            job[e.currentTarget.id] = Number(e.currentTarget.value);
        } else {
            job[e.currentTarget.id] = e.currentTarget.value;
        }
        setSelectedJob(job);
        forceUpdate();
    }
    function getActDate(e) {
        let actDate = ActualDate;

        if (e.currentTarget.id = 'actualDate') {
            actDate = e.target.value
        }
        setActualDate(actDate);
        forceUpdate();
    }
    function updateJob() {
        if (ActualDate === '') {
            window.alert('Please assign the actual due date for the request.!')
            return
        }
        SelectedJob.ActualDueDate = ActualDate
        store.setLoading(true);
        store.updateJobById(SelectedJob).then((ret) => {
            if (ret === 'error') {
                window.alert('There was an error updating this job.');
                store.setLoading(false);
            } else {
                window.alert('Job updated succesfully.');
                setSelectedJob({});
                setActualDate(new Date())
                setShowData(false);
                store.setLoading(false)
            }
        })
        forceUpdate();
    }
    function acceptJob(e) {
        let decision = Decision;
        let index = 0
        for (let i = 0; i < Jobs.length; i++) {
            if (e.currentTarget.id === Jobs[i]._id) {
                Jobs[i].Status = 'Accepted'
                Jobs[i].FeedBack = 'We are working on it.'
                Jobs[i].DepartmentComment = Comment
                index = i
            }
            decision = 'Accepted'
        }
        store.setLoading(true)
        store.updateJobById(Jobs[index]).then((ret) => {
            if (ret === 'error') {
                window.alert('There was an error updating this job.');
                store.setLoading(false);
            } else {
                window.alert('Job accepted succesfully.');
                setSelectedJob({});
                setShowData(false);
                forceUpdate();
                store.setLoading(false)
            }
        })
        setDecision(decision)
        setJobClicked(false)
        forceUpdate();
    }
    function declineJob(e) {
        let decision = 'Declined';
        let index = 0;
        let rejectedJob = e.currentTarget.id;
        for (let i = 0; i < Jobs.length; i++) {
            let jobId = Jobs[i]._id;
            if (e.currentTarget.id === jobId) {
                Jobs[i].Status = 'Declined peacefully.';
                index = i;
            }
            decision = 'Declined';
        }
        store.setLoading(true);
        store.updateJobById(Jobs[index]).then((ret) => {
            if (ret === 'error') {
                window.alert('There was an error updating this job.');
            } else {
                setSelectedJob({});
                setActualDate(new Date());
                setShowData(false);
            }
            store.setLoading(false);
        }).catch((error) => {
            console.error('Error updating job:', error);
            window.alert('There was an error updating this job.');
            store.setLoading(false);
        });

        setRejectedJobID(rejectedJob);
        setDecision(decision);
        forceUpdate();
    }
    function getResponse(e) {
        let response = DeclinedRes;
        if (e.currentTarget.id === 'jobDeclined') {
            response = String(e.target.value);
        };
        setDeclinedRes(response);
        forceUpdate();
    }
    function submitDecision() {
        let index = 0
        for (let i = 0; i < Jobs.length; i++) {
            if (RejectedJobID === Jobs[i]._id) {
                Jobs[i].FeedBack = DeclinedRes
                index = i
            }
        }

        store.setLoading(true)
        store.updateJobById(Jobs[index]).then((ret) => {
            if (ret === 'error') {
                window.alert('There was an error updating this job.');
                store.setLoading(false);
            } else {
                window.alert('Job declined peacefully..!');
                setSelectedJob({});
                setDeclinedRes('');
                setDecision('')
                setShowData(false);
                forceUpdate();
                store.setLoading(false)
            }
        })

        setJobClicked(false);
    }
    function viewJobDetails(e) {
        setSelectedJob(Jobs[Number(e.currentTarget.id)]);
        setDevSpecs(Jobs[Number(e.currentTarget.id)])
        setJobClicked(true);
    }
    function closeJobDetails() {
        setJobClicked(false);
    }
    function commentChange(e) {
        let commentObj = CommentObj
        commentObj.Comment = e.currentTarget.value
        commentObj.Email = store.user.Email
        setCommentObj(commentObj)
        forceUpdate()
    }
    function sendComment() {
        if (CommentObj.Comment === "") {
            window.alert("Please enter a comment")
        } else {
            let id = SelectedJob._id
            let commentArr = Comments
            commentArr.push(CommentObj)
            let data = {
                Comment: commentArr
            }
            store.setLoading(true);
            store.UpdateJobById(id, data).then((ret) => {
                window.alert("Comment sent.")
                store.setLoading(false);
                setCommentObj({
                    Comment: '',
                    Date: new Date(),
                    Email: ''
                })
            })
        }
    }
    if (mobile()) {
        return (
            <div >
                <div className="designHomePageMobile">
                    <Card id='requested' className={`${(DesignTabs === 'requested') ? 'tabCardsSelected' : 'tabCards'}`} onClick={pageSelected} >Requested</Card>
                    <Card id='inProgress' className={`${(DesignTabs === 'inProgress') ? 'tabCardsSelected' : 'tabCards'}`} onClick={pageSelected} >In Progress</Card>
                    <Card id='completed' className={`${(DesignTabs === 'completed') ? 'tabCardsSelected' : 'tabCards'}`} onClick={pageSelected} >Completed</Card>
                </div>

                {(DesignTabs === 'requested') ?
                    <div className='jobCardsMobile'>
                        <Card className="designHomePageCardsMobile">
                            <h1>Requested Jobs</h1>
                            {Jobs.map((job, index) => {
                                let matchingCredits = CreditsArray.find(credit => credit.CompanyName === job.Client);
                                let department = job.Department;
                                let mappedValue = matchingCredits && matchingCredits.RemainingCredits[department] ? matchingCredits.RemainingCredits[department] : 0;
                                if (job.Status === 'requested') {
                                    return (
                                        <div key={index} className="existingjobDiv receivedJobMobile">
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                                <p style={{ margin: '0px', marginBottom: '5px', fontWeight: 'bold' }}>{job.Title}</p>
                                                <p style={{ margin: '0px', marginTop: '5px', fontWeight: 'lighter' }}>{job.Client}</p>
                                                <p style={{ margin: '0px', marginTop: '5px', fontWeight: 'lighter', marginLeft: "63%", marginTop: "-7%", marginBottom: "2%" }}>Credits: {mappedValue || 0}</p>
                                            </div>
                                            <Button onClick={viewJobDetails} id={index} style={{ width: '30%' }}>View Job Details</Button>
                                        </div>
                                    );
                                }
                            })}
                        </Card>
                        <Card className="designHomePageCardsMobile">
                            <h1>Accepted Jobs</h1>
                            {Jobs.map((job, index) => {
                                let matchingCredits = CreditsArray.find(credit => credit.CompanyName === job.Client);
                                let department = job.Department;
                                let mappedValue = matchingCredits && matchingCredits.RemainingCredits[department] ? matchingCredits.RemainingCredits[department] : 0;
                                if (job.Status === 'Accepted') {
                                    return (
                                        <div key={index} className="existingjobDiv receivedJobMobile">
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                                <p style={{ margin: '0px', marginBottom: '5px', fontWeight: 'bold' }}>{job.Title}</p>
                                                <p style={{ margin: '0px', marginTop: '5px', fontWeight: 'lighter' }}>{job.Client}</p>
                                                <p style={{ margin: '0px', marginTop: '5px', fontWeight: 'lighter', marginLeft: "63%", marginTop: "-7%", marginBottom: "2%" }}>Credits: {mappedValue || 0}</p>
                                            </div>
                                            <Button onClick={showDataButton} id={index} style={{ width: '30%' }}>Update</Button>
                                        </div>
                                    )
                                }
                            })}
                        </Card>
                        <Card className="designHomePageCardsMobile">
                            <h1>Declined Jobs</h1>
                            {Jobs.map((job, index) => {
                                let matchingCredits = CreditsArray.find(credit => credit.CompanyName === job.Client);
                                let department = job.Department;
                                let mappedValue = matchingCredits && matchingCredits.RemainingCredits[department] ? matchingCredits.RemainingCredits[department] : 0;
                                if (job.Status === 'Declined peacefully.') {
                                    return (
                                        <div key={index} className="existingjobDiv receivedJobMobile">
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                                <p style={{ margin: '0px', marginBottom: '5px', fontWeight: 'bold' }}>{job.Title}</p>
                                                <p style={{ margin: '0px', marginTop: '5px', fontWeight: 'lighter' }}>{job.Client}</p>
                                                <p style={{ margin: '0px', marginTop: '5px', fontWeight: 'lighter', marginLeft: "63%", marginTop: "-7%", marginBottom: "2%" }}>Credits: {mappedValue || 0}</p>
                                            </div>
                                            <Button onClick={viewJobDetails} id={index} style={{ width: '40%' }}>View Job Details</Button>
                                        </div>
                                    );
                                }
                            })}
                        </Card>
                    </div> : null}

                {(DesignTabs === 'inProgress') ?
                    <>
                        <Card className="designHomePageCardsMobile">
                            <h1>In Progress Jobs</h1>
                            {Jobs.map((job, index) => {
                                let matchingCredits = CreditsArray.find(credit => credit.CompanyName === job.Client);
                                let department = job.Department;
                                let mappedValue = matchingCredits && matchingCredits.RemainingCredits[department] ? matchingCredits.RemainingCredits[department] : 0;
                                if (job.Status !== 'inProgress') {
                                    return
                                }
                                return (
                                    <div key={index} className="existingjobDiv receivedJobMobile">
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                            <p style={{ margin: '0px', marginBottom: '5px', fontWeight: 'bold' }}>{job.Title}</p>
                                            <p style={{ margin: '0px', marginTop: '5px', fontWeight: 'lighter' }}>{job.Client}</p>
                                            <p style={{ margin: '0px', marginTop: '5px', fontWeight: 'lighter', marginLeft: "63%", marginTop: "-7%", marginBottom: "2%" }}>Credits: {mappedValue || 0}</p>
                                        </div>

                                        <Button onClick={showDataButton} id={index} style={{ width: '20%' }}>Update</Button>
                                    </div>
                                );
                            })}
                        </Card>
                    </> : null}

                {(DesignTabs === 'completed') ?
                    <>
                        <Card className="designHomePageCardsMobile">
                            <h1>Completed Jobs</h1>
                            {Jobs.map((job, index) => {
                                let matchingCredits = CreditsArray.find(credit => credit.CompanyName === job.Client);
                                let department = job.Department;
                                let mappedValue = matchingCredits && matchingCredits.RemainingCredits[department] ? matchingCredits.RemainingCredits[department] : 0;
                                if (job.Status !== 'complete') {
                                    return
                                }
                                return (
                                    <div key={index} className="existingjobDiv receivedJobMobile">
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                            <p style={{ margin: '0px', marginBottom: '5px', fontWeight: 'bold' }}>{job.Title}</p>
                                            <p style={{ margin: '0px', marginTop: '5px', fontWeight: 'lighter' }}>{job.Client}</p>
                                            <p style={{ margin: '0px', marginTop: '5px', fontWeight: 'lighter', marginLeft: "63%", marginTop: "-7%", marginBottom: "2%" }}>Credits: {mappedValue || 0}</p>
                                        </div>
                                        <Button onClick={showDataButton} id={index} style={{ width: '20%' }}>View</Button>
                                    </div>
                                );
                            })}
                        </Card>
                    </>
                    : null}
                {JobClicked ? (
                    <Card className="viewJobDetailsMobile">
                        <h3>{SelectedJob.Title} Details</h3>
                        <img src={close} onClick={closeJobDetails} className="closeJobDetails" />
                        {SelectedJob.Department === "Dev" ? (
                            <div className="Details">
                                <div className="jobPreview">
                                    <div style={{ width: "40%", marginLeft:"1%" }} className="mappingJobDetails">
                                        <p>Client</p>
                                        <p>Description</p>
                                        <p>Figma</p>
                                        <p>Type</p>
                                        <p>Platform</p>
                                        <p>Request Date</p>
                                        <p>Requested DueDate</p>
                                        <p>Remaining Credits</p>
                                    </div>
                                    <div className="mappingJobDetails">
                                        <p>: {SelectedJob.Client}</p>
                                        <p>: {SelectedJob.Description}</p>
                                        {SelectedJob.DevSpec && (
                                            <>
                                                <p>: {SelectedJob.DevSpec.Figma_Available}</p>
                                                <p>: {SelectedJob.DevSpec.Type}</p>
                                                <p>: {SelectedJob.DevSpec.Platform}</p>
                                            </>
                                        )}
                                        <p>: {formatDate(SelectedJob.ReqDate)}</p>
                                        <p>: {formatDate(SelectedJob.DueDate)}</p>
                                        {CreditsArray.map((credit, index) => {
                                            let matchingCredits = CreditsArray.find(credit => credit.CompanyName === SelectedJob.Client);
                                            let department = SelectedJob.Department;
                                            let mappedValue = matchingCredits && matchingCredits.RemainingCredits[department] ? matchingCredits.RemainingCredits[department] : 0;

                                            return (
                                                <p key={index}>: {mappedValue}</p>
                                            );
                                        })}
                                    </div>
                                </div>
                                {SelectedJob.Status !== "Declined peacefully." && (
                                    <>
                                        <Button style={{ margin: "2%", width: "45%" }} id={SelectedJob._id} onClick={acceptJob}>Accept</Button>
                                        <Button style={{ margin: "2%", width: "45%" }} id={SelectedJob._id} onClick={declineJob}>Decline</Button>
                                    </>
                                )}
                                {(Decision === 'Declined') ? <>
                                    <Card className="rejectionCard">
                                        <p>Please provide reason/s for declining the request.</p>
                                        <Input placeholder='Job declined because..!' id='jobDeclined' onChange={getResponse} value={String(DeclinedRes)} />
                                        <img src={submit} onClick={submitDecision} className="submitIcon" />
                                    </Card>
                                </> : null}
                            </div>

                        ) :
                            <div className="Details">
                                <div className="jobPreview">
                                    <div style={{ width: "30%" }} className="mappingJobDetails">
                                        <p>Client</p>
                                        <p>Description</p>
                                        <p>Design Elements</p>
                                        <p>Collateral</p>
                                        <p>List Deliverables</p>
                                        <p>Request Date</p>
                                        <p>Requested DueDate</p>
                                    </div>
                                    <div className="mappingJobDetails">
                                        <p>: {SelectedJob.Client}</p>
                                        <p>: {SelectedJob.Description}</p>
                                        {SelectedJob.DesignSpec && (
                                            <>
                                                <p>: {SelectedJob.DesignSpec.designElements}</p>
                                                <p>: {SelectedJob.DesignSpec.collateral}</p>
                                                <p>: {SelectedJob.DesignSpec.listDeliverables}</p>
                                            </>
                                        )}
                                        <p>: {(new Date(SelectedJob.ReqDate)).toDateString()}</p>
                                        <p>: {(new Date(SelectedJob.DueDate)).toDateString()}</p>
                                    </div>
                                </div>
                                {SelectedJob.Status !== "Declined peacefully." && (
                                    <>
                                        <Button style={{ margin: "2%", width: "45%" }} id={SelectedJob._id} onClick={acceptJob}>Accept</Button>
                                        <Button style={{ margin: "2%", width: "45%" }} id={SelectedJob._id} onClick={declineJob}>Decline</Button>
                                    </>
                                )}
                                {(Decision === 'Declined') ? <>
                                    <Card className="rejectionCard">
                                        <p>Please provide reason/s for declining the request.</p>
                                        <Input placeholder='Job declined because..!' id='jobDeclined' onChange={getResponse} value={String(DeclinedRes)} />
                                        <img src={submit} onClick={submitDecision} className="submitIcon" />
                                    </Card>
                                </> : null}
                            </div>
                        }
                    </Card>
                ) : null}
                {(!ShowData) ? null :
                    <Card style={{top: "43px"}} className='jobInfoCard'>
                        <FaTimes className="closeJobInfoCard" onClick={closeJobDetail} />
                        <div className='jobInfoTitle'>
                            <p style={{ fontSize: '20px' }}><b>{SelectedJob.Title}</b></p> 
                            <p style={{ textTransform: 'capitalize' }}>{SelectedJob.Status}</p>
                        </div>
                        <div className='jobInfoCardMenuMobile'>
                            <Card id='Details' className={`${(SelectedJobInfo === 'Details') ? 'tabCardsSelected' : 'tabCards'}`} onClick={selectedJobInfoSelected} style={{ marginLeft: '5px', marginRight: '5px', color: 'black' }}>Details</Card>
                            <Card id="Comments" className={`${(SelectedJobInfo === 'Comments') ? 'tabCardsSelected' : 'tabCards'}`} onClick={selectedJobInfoSelected} style={{ marginLeft: '5px', marginRight: '5px', color: 'black' }}>Comments</Card>
                            <Card id='Updates' className={`${(SelectedJobInfo === 'Updates') ? 'tabCardsSelected' : 'tabCards'}`} onClick={selectedJobInfoSelected} style={{ marginLeft: '5px', marginRight: '5px', color: 'black' }}>Updates</Card>
                            {/* <Card id='DownloadPDF' className={`${(SelectedJobInfo === 'DownloadPDF') ? 'tabCardsSelected' : 'tabCards'}`} onClick={selectedJobInfoSelected} style={{ marginLeft: '5px', marginRight: '5px', color: 'black' }}>View PDF</Card> */}
                        </div>
                        {(SelectedJobInfo === 'DownloadPDF' && SelectedJob) ?
                            <div className='jobInfoCardContent'>
                                <JobRequestPDF {...SelectedJob} />
                            </div>
                            : null}

                        {(SelectedJobInfo === 'Details') ?
                            <div className="moreInfo">
                                <div className='jobInfoCardContent'>
                                    <p><b>Description   :</b></p>
                                    <p>{SelectedJob.Description}</p>
                                    <p><b>Requested date    :</b></p>
                                    <p>{(new Date(SelectedJob.ReqDate)).toDateString()}</p>
                                    <p><b>Requested due date    :</b></p>
                                    <p>{(new Date(SelectedJob.DueDate)).toDateString()}</p>
                                    <p><b>Estimated hours   :</b></p>
                                    <p>{SelectedJob.EstimatedHours}</p>
                                    <p><b>Actual hours  :</b></p>
                                    <p>{SelectedJob.ActualHours}</p>
                                    <p><b>Actual due date   :</b></p>
                                    <p>{(new Date(SelectedJob.ActualDueDate)).toDateString()}</p>
                                    <p><b>Remaining Credits :</b></p>
                                    {CreditsArray.map((credit, index) => {
                                        let matchingCredits = CreditsArray.find(credit => credit.CompanyName === SelectedJob.Client);
                                        let department = SelectedJob.Department;
                                        let mappedValue = matchingCredits && matchingCredits.RemainingCredits[department] ? matchingCredits.RemainingCredits[department] : 0;

                                        return (
                                            <p key={index}> {mappedValue}</p>
                                        );
                                    })}
                                </div>

                            </div> : null}

                        {(SelectedJobInfo === "Comments") ?
                            <div className="departmentCommentsMobile">
                                <h1>Comments</h1>
                                <div className="CommentCardsMobile">
                                    {!Comments && Comments.length === 0 ? (
                                        <div className="CommentCard">
                                            <h3>No comments available</h3>
                                        </div>
                                    ) : (
                                        Comments.map((comment, index) => (
                                            <div className="CommentCard" key={index}>
                                                <h3>{comment.Comment}</h3>
                                                <p>Email: {comment.Email}</p>
                                            </div>
                                        ))
                                    )}
                                </div>
                                <div className="CommentsComps">
                                    <Input value={CommentObj.Comment} id='Comment' onChange={commentChange} style={{ resize: "none", borderRadius: "15px", width: "100%", }} placeholder="Comment" />
                                    <Button onClick={sendComment} >Send</Button>
                                </div>
                            </div>
                            : null}
                        {(SelectedJobInfo === 'Updates') ?
                            <div className='jobInfoCardContent'>
                                <p><b>Actual due date:</b></p>
                                <Input value={(ActualDate)} type='date' onChange={getActDate} id='actualDate' />
                                <p><b>Status:</b></p>
                                <select value={SelectedJob.Status} onChange={selectedJobChanged} id='Status'>
                                    <option value='requested'>Requested</option>
                                    <option value='inProgress'>In progress</option>
                                    <option value='OnHold'>On Hold</option>
                                    <option value='complete'>Complete</option>
                                </select>
                                <p><b>Estimated hours:</b></p>
                                <Input type='number' value={String(SelectedJob.EstimatedHours || 0)} onChange={selectedJobChanged} id='EstimatedHours' />
                                <p><b>Actual hours:</b></p>
                                <Input type='number' value={String(SelectedJob.ActualHours || 0)} onChange={selectedJobChanged} id='ActualHours' />
                                <Button onClick={updateJob}>Update</Button>
                            </div>
                            : null}
                    </Card>
                }
            </div>
        )
    } else {
        return (
            <div>
                <div className="designHomePage">
                    <Card id='requested' className={`${(DesignTabs === 'requested') ? 'tabCardsSelected' : 'tabCards'}`} onClick={pageSelected} style={{ marginLeft: '5px', marginRight: '5px' }}>Requested</Card>
                    <Card id='inProgress' className={`${(DesignTabs === 'inProgress') ? 'tabCardsSelected' : 'tabCards'}`} onClick={pageSelected} style={{ marginLeft: '5px', marginRight: '5px' }}>In Progress</Card>
                    <Card id='completed' className={`${(DesignTabs === 'completed') ? 'tabCardsSelected' : 'tabCards'}`} onClick={pageSelected} style={{ marginLeft: '5px', marginRight: '5px' }}>Completed</Card>
                </div>

                {(DesignTabs === 'requested') ?
                    <div className='jobCards'>
                        <Card className="designHomePageCards">
                            <h1>Requested Jobs</h1>
                            {Jobs.map((job, index) => {
                                let matchingCredits = CreditsArray.find(credit => credit.CompanyName === job.Client);
                                let department = job.Department;
                                let mappedValue = matchingCredits && matchingCredits.RemainingCredits[department] ? matchingCredits.RemainingCredits[department] : 0;
                                if (job.Status === 'requested') {
                                    return (
                                        <div key={index} className="existingjobDiv receivedJob">
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                                <p style={{ margin: '0px', marginBottom: '5px', fontWeight: 'bold' }}>{job.Title}</p>
                                                <p style={{ margin: '0px', marginTop: '5px', fontWeight: 'lighter' }}>{job.Client}</p>
                                                <p style={{ margin: '0px', marginTop: '5px', fontWeight: 'lighter', marginLeft: "63%", marginTop: "-7%", marginBottom: "2%" }}>Credits: {mappedValue || 0}</p>
                                            </div>
                                            <Button onClick={viewJobDetails} id={index} style={{ width: '30%' }}>View Job Details</Button>
                                        </div>
                                    );
                                }
                            })}
                        </Card>
                        <Card className="designHomePageCards">
                            <h1>Accepted Jobs</h1>
                            {Jobs.map((job, index) => {
                                let matchingCredits = CreditsArray.find(credit => credit.CompanyName === job.Client);
                                let department = job.Department;
                                let mappedValue = matchingCredits && matchingCredits.RemainingCredits[department] ? matchingCredits.RemainingCredits[department] : 0;
                                if (job.Status === 'Accepted') {
                                    return (
                                        <div key={index} className="existingjobDiv receivedJob">
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                                <p style={{ margin: '0px', marginBottom: '5px', fontWeight: 'bold' }}>{job.Title}</p>
                                                <p style={{ margin: '0px', marginTop: '5px', fontWeight: 'lighter' }}>{job.Client}</p>
                                                <p style={{ margin: '0px', marginTop: '5px', fontWeight: 'lighter', marginLeft: "63%", marginTop: "-7%", marginBottom: "2%" }}>Credits: {mappedValue || 0}</p>
                                            </div>
                                            <Button onClick={showDataButton} id={index} style={{ width: '30%' }}>Update</Button>
                                        </div>
                                    )
                                }
                            })}
                        </Card>
                        <Card className="designHomePageCards">
                            <h1>Declined Jobs</h1>
                            {Jobs.map((job, index) => {
                                let matchingCredits = CreditsArray.find(credit => credit.CompanyName === job.Client);
                                let department = job.Department;
                                let mappedValue = matchingCredits && matchingCredits.RemainingCredits[department] ? matchingCredits.RemainingCredits[department] : 0;
                                if (job.Status === 'Declined peacefully.') {
                                    return (
                                        <div key={index} className="existingjobDiv receivedJob">
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                                <p style={{ margin: '0px', marginBottom: '5px', fontWeight: 'bold' }}>{job.Title}</p>
                                                <p style={{ margin: '0px', marginTop: '5px', fontWeight: 'lighter' }}>{job.Client}</p>
                                                <p style={{ margin: '0px', marginTop: '5px', fontWeight: 'lighter', marginLeft: "63%", marginTop: "-7%", marginBottom: "2%" }}>Credits: {mappedValue || 0}</p>
                                            </div>
                                            <Button onClick={viewJobDetails} id={index} style={{ width: '30%' }}>View Job Details</Button>
                                        </div>
                                    );
                                }
                            })}
                        </Card>
                    </div> : null}

                {(DesignTabs === 'inProgress') ?
                    <>
                        <Card className="designHomePageCards">
                            <h1>In Progress Jobs</h1>
                            {Jobs.map((job, index) => {
                                let matchingCredits = CreditsArray.find(credit => credit.CompanyName === job.Client);
                                let department = job.Department;
                                let mappedValue = matchingCredits && matchingCredits.RemainingCredits[department] ? matchingCredits.RemainingCredits[department] : 0;
                                if (job.Status !== 'inProgress') {
                                    return
                                }
                                return (
                                    <div key={index} className="existingjobDiv receivedJob">
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                            <p style={{ margin: '0px', marginBottom: '5px', fontWeight: 'bold' }}>{job.Title}</p>
                                            <p style={{ margin: '0px', marginTop: '5px', fontWeight: 'lighter' }}>{job.Client}</p>
                                            <p style={{ margin: '0px', marginTop: '5px', fontWeight: 'lighter', marginLeft: "63%", marginTop: "-7%", marginBottom: "2%" }}>Credits: {mappedValue || 0}</p>
                                        </div>

                                        <Button onClick={showDataButton} id={index} style={{ width: '20%' }}>Update</Button>
                                    </div>
                                );
                            })}
                        </Card>
                    </> : null}

                {(DesignTabs === 'completed') ?
                    <>
                        <Card className="designHomePageCards">
                            <h1>Completed Jobs</h1>
                            {Jobs.map((job, index) => {
                                let matchingCredits = CreditsArray.find(credit => credit.CompanyName === job.Client);
                                let department = job.Department;
                                let mappedValue = matchingCredits && matchingCredits.RemainingCredits[department] ? matchingCredits.RemainingCredits[department] : 0;
                                if (job.Status !== 'complete') {
                                    return
                                }
                                return (
                                    <div key={index} className="existingjobDiv receivedJob">
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                            <p style={{ margin: '0px', marginBottom: '5px', fontWeight: 'bold' }}>{job.Title}</p>
                                            <p style={{ margin: '0px', marginTop: '5px', fontWeight: 'lighter' }}>{job.Client}</p>
                                            <p style={{ margin: '0px', marginTop: '5px', fontWeight: 'lighter', marginLeft: "63%", marginTop: "-7%", marginBottom: "2%" }}>Credits: {mappedValue || 0}</p>
                                        </div>
                                        <Button onClick={showDataButton} id={index} style={{ width: '20%' }}>View</Button>
                                    </div>
                                );
                            })}
                        </Card>
                    </>
                    : null}
                {JobClicked ? (
                    <Card className="viewJobDetails">
                        <h3>{SelectedJob.Title} Details</h3>
                        <img src={close} onClick={closeJobDetails} className="closeJobDetails" />
                        {SelectedJob.Department === "Dev" ? (
                            <div className="Details">
                                <div className="jobPreview">
                                    <div style={{ width: "30%" }} className="mappingJobDetails">
                                        <p>Client</p>
                                        <p>Description</p>
                                        <p>Figma</p>
                                        <p>Type</p>
                                        <p>Platform</p>
                                        <p>Request Date</p>
                                        <p>Requested DueDate</p>
                                        <p>Remaining Credits</p>
                                    </div>
                                    <div className="mappingJobDetails">
                                        <p>: {SelectedJob.Client}</p>
                                        <p>: {SelectedJob.Description}</p>
                                        {SelectedJob.DevSpec && (
                                            <>
                                                <p>: {SelectedJob.DevSpec.Figma_Available}</p>
                                                <p>: {SelectedJob.DevSpec.Type}</p>
                                                <p>: {SelectedJob.DevSpec.Platform}</p>
                                            </>
                                        )}
                                        <p>: {(new Date(SelectedJob.ReqDate)).toDateString()}</p>
                                        <p>: {(new Date(SelectedJob.DueDate)).toDateString()}</p>
                                        {CreditsArray.map((credit, index) => {
                                            let matchingCredits = CreditsArray.find(credit => credit.CompanyName === SelectedJob.Client);
                                            let department = SelectedJob.Department;
                                            let mappedValue = matchingCredits && matchingCredits.RemainingCredits[department] ? matchingCredits.RemainingCredits[department] : 0;

                                            return (
                                                <p key={index}>: {mappedValue}</p>
                                            );
                                        })}
                                    </div>
                                </div>
                                {SelectedJob.Status !== "Declined peacefully." && (
                                    <>
                                        <Button style={{ margin: "2%", width: "45%" }} id={SelectedJob._id} onClick={acceptJob}>Accept</Button>
                                        <Button style={{ margin: "2%", width: "45%" }} id={SelectedJob._id} onClick={declineJob}>Decline</Button>
                                    </>
                                )}
                                {(Decision === 'Declined') ? <>
                                    <Card className="rejectionCard">
                                        <p>Please provide reason/s for declining the request.</p>
                                        <Input placeholder='Job declined because..!' id='jobDeclined' onChange={getResponse} value={String(DeclinedRes)} />
                                        <img src={submit} onClick={submitDecision} className="submitIcon" />
                                    </Card>
                                </> : null}
                            </div>

                        ) :
                            <div className="Details">
                                <div className="jobPreview">
                                    <div style={{ width: "30%" }} className="mappingJobDetails">
                                        <p>Client</p>
                                        <p>Description</p>
                                        <p>Design Elements</p>
                                        <p>Collateral</p>
                                        <p>List Deliverables</p>
                                        <p>Request Date</p>
                                        <p>Requested DueDate</p>
                                    </div>
                                    <div className="mappingJobDetails">
                                        <p>: {SelectedJob.Client}</p>
                                        <p>: {SelectedJob.Description}</p>
                                        {SelectedJob.DesignSpec && (
                                            <>
                                                <p>: {SelectedJob.DesignSpec.designElements}</p>
                                                <p>: {SelectedJob.DesignSpec.collateral}</p>
                                                <p>: {SelectedJob.DesignSpec.listDeliverables}</p>
                                            </>
                                        )}
                                        <p>: {(new Date(SelectedJob.ReqDate)).toDateString()}</p>
                                        <p>: {(new Date(SelectedJob.DueDate)).toDateString()}</p>
                                    </div>
                                </div>
                                {SelectedJob.Status !== "Declined peacefully." && (
                                    <>
                                        <Button style={{ margin: "2%", width: "45%" }} id={SelectedJob._id} onClick={acceptJob}>Accept</Button>
                                        <Button style={{ margin: "2%", width: "45%" }} id={SelectedJob._id} onClick={declineJob}>Decline</Button>
                                    </>
                                )}
                                {(Decision === 'Declined') ? <>
                                    <Card className="rejectionCard">
                                        <p>Please provide reason/s for declining the request.</p>
                                        <Input placeholder='Job declined because..!' id='jobDeclined' onChange={getResponse} value={String(DeclinedRes)} />
                                        <img src={submit} onClick={submitDecision} className="submitIcon" />
                                    </Card>
                                </> : null}
                            </div>
                        }
                    </Card>
                ) : null}
                {(!ShowData) ? null :
                    <Card className='jobInfoCard'>
                        <FaTimes className="closeJobInfoCard" onClick={closeJobDetail} />
                        <div className='jobInfoTitle'>
                            <p style={{ fontSize: '20px' }}><b>{SelectedJob.Title}</b></p>
                            <p style={{ textTransform: 'capitalize' }}>{SelectedJob.Status}</p>
                        </div>
                        <div className='jobInfoCardMenu'>
                            <Card id='Details' className={`${(SelectedJobInfo === 'Details') ? 'tabCardsSelected' : 'tabCards'}`} onClick={selectedJobInfoSelected} style={{ marginLeft: '5px', marginRight: '5px', color: 'black' }}>Details</Card>
                            <Card id='Updates' className={`${(SelectedJobInfo === 'Updates') ? 'tabCardsSelected' : 'tabCards'}`} onClick={selectedJobInfoSelected} style={{ marginLeft: '5px', marginRight: '5px', color: 'black' }}>Updates</Card>
                            <Card id='DownloadPDF' className={`${(SelectedJobInfo === 'DownloadPDF') ? 'tabCardsSelected' : 'tabCards'}`} onClick={selectedJobInfoSelected} style={{ marginLeft: '5px', marginRight: '5px', color: 'black' }}>View PDF</Card>
                        </div>
                        {(SelectedJobInfo === 'DownloadPDF' && SelectedJob) ?
                            <div className='jobInfoCardContent'>
                                <JobRequestPDF {...SelectedJob} />
                            </div>
                            : null}

                        {(SelectedJobInfo === 'Details') ?
                            <div className="moreInfo">
                                <div className='jobInfoCardContent'>
                                    <p><b>Description   :</b></p>
                                    <p>{SelectedJob.Description}</p>
                                    <p><b>Requested date    :</b></p>
                                    <p>{(new Date(SelectedJob.ReqDate)).toDateString()}</p>
                                    <p><b>Requested due date    :</b></p>
                                    <p>{(new Date(SelectedJob.DueDate)).toDateString()}</p>
                                    <p><b>Estimated hours   :</b></p>
                                    <p>{SelectedJob.EstimatedHours}</p>
                                    <p><b>Actual hours  :</b></p>
                                    <p>{SelectedJob.ActualHours}</p>
                                    <p><b>Actual due date   :</b></p>
                                    <p>{(new Date(SelectedJob.ActualDueDate)).toDateString()}</p>
                                    <p><b>Remaining Credits  :</b></p>
                                    {CreditsArray.map((credit, index) => {
                                        let matchingCredits = CreditsArray.find(credit => credit.CompanyName === SelectedJob.Client);
                                        let department = SelectedJob.Department;
                                        let mappedValue = matchingCredits && matchingCredits.RemainingCredits[department] ? matchingCredits.RemainingCredits[department] : 0;

                                        return (
                                            <p key={index}>{mappedValue}</p>
                                        );
                                    })}
                                </div>
                                <div className="DepartmentsComments">
                                    <h1>Comments</h1>
                                    <div className="CommentCards">
                                        {!Comments && Comments.length === 0 ? (
                                            <div className="CommentCard">
                                                <h3>No comments available</h3>
                                            </div>
                                        ) : (
                                            Comments.map((comment, index) => (
                                                <div className="CommentCard" key={index}>
                                                    <h3>{comment.Comment}</h3>
                                                    <p>Email: {comment.Email}</p>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                    <div className="CommentsComps">
                                        <Input value={CommentObj.Comment} id='Comment' onChange={commentChange} style={{ resize: "none", borderRadius: "15px", width: "100%", }} placeholder="Comment" />
                                        <Button onClick={sendComment} >Send</Button>
                                    </div>
                                </div>
                            </div> : null}

                        {(SelectedJobInfo === 'Updates') ?
                            <div className='jobInfoCardContent'>
                                <p><b>Actual due date:</b></p>
                                <Input value={(ActualDate)} type='date' onChange={getActDate} id='actualDate' />
                                <p><b>Status:</b></p>
                                <select value={SelectedJob.Status} onChange={selectedJobChanged} id='Status'>
                                    <option value='requested'>Requested</option>
                                    <option value='OnHold'>On Hold</option>
                                    <option value='inProgress'>In progress</option>
                                    <option value='complete'>Complete</option>
                                </select>
                                <p><b>Estimated hours:</b></p>
                                <Input type='number' value={String(SelectedJob.EstimatedHours || 0)} onChange={selectedJobChanged} id='EstimatedHours' />
                                <p><b>Actual hours:</b></p>
                                <Input type='number' value={String(SelectedJob.ActualHours || 0)} onChange={selectedJobChanged} id='ActualHours' />
                                <Button onClick={updateJob}>Update</Button>
                            </div>
                            : null}
                    </Card>
                }
            </div>
        )
    }

})

export default Service;