import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { useNavigate } from 'react-router-dom';
import dashLogo from '../../../assets/images/dashLogo.png';
import Todo from '../../../assets/images/todo.png';
import InProgress from '../../../assets/images/In_Progress@2x.png';
import Completed from '../../../assets/images/checked.png';
import Plus from '../../../assets/images/plus.png';
import { Input, View, Button, Card, DoughnutChart, } from '../../../components';
import { PolarArea } from 'react-chartjs-2';
import { FaHome, FaRocketchat, FaEnvelopeOpenText, FaPaperPlane, FaRegCommentDots, FaRegFileAlt } from "react-icons/fa";
var mobile = require('is-mobile');

const OKR = observer(() => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Jobs, setJobs] = useState([]);

    if (mobile()) {
        return (
            <div>

            </div>
        );

    } else {
        return (
            <div sstyle={{ width: '100%', height: '88%', overflow:'scroll'}}>
               
            </div>
        )
    }
})

export default OKR;