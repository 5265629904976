import { observer } from "mobx-react-lite";
import { useStore } from "../../../stores/Store";
import { Card, PieChart } from "../../../components";
import "./style.css";
import React, { useEffect, useState } from "react";
var mobile = require("is-mobile");

const Admin = observer(() => {

    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [CompaniesList, setCompaniesList] = useState([]);
    const [AdminOverview, setAdminOverview] = useState([]);
    const [Actuals, setActual] = useState([]);
    const [ClickedCardIndex, setClickedCardIndex] = useState(-1);
    useEffect(() => {
        loadCompanies();
    }, []);

    function loadCompanies() {
        store.setLoading(true);
        store.getCompaniesByPerms(store.user.Email).then((ret) => {
            if (typeof ret === "string" || !ret) {
                setCompaniesList({});
                store.setLoading(false);
                return;
            }
            setCompaniesList(ret);
            setActual(ret[0].Actual[ret[0].Actual.length - 1]);
            forceUpdate();
            if (store.user.Role === "Admin") {
                getCompanyOverview(ret);
            } else {
                store.setLoading(false);
            }
        });
    }
    function getCompanyOverview(companies) {
        store.calculateAdminCompanyOverview(companies).then((ret) => {
            setAdminOverview(ret);
            forceUpdate();
            store.setLoading(false);
        })
    }
    function handleCardClick(e) {
        setClickedCardIndex(Number(e.currentTarget.id));
    }

    if (mobile()) {
        return (
            <div className="adminMobilePage" style={{ width:"100%" ,height: '50%' }}>
            {/* <PieChart adminOverview={AdminOverview}/> */}
            {AdminOverview.map((company, index) => {
                return (
                    <Card onClick={handleCardClick} className={`${(company.colour === 'red') ? 'homeCardsCompanyRedMobile' : (company.colour === 'orange') ? 'homeCardsCompanyOrangeMobile' : (company.colour === 'green') ? 'homeCardsCompanyGreenMobile': (company.colour === '') ? 'homeCardsCompanyRedMobile' : null}`} key={index} id={index}>
                       {(company.colour === '')?<p>{company.Name} Has No Available Data</p>:<p>{company.Name}</p>}
                        
                        {(ClickedCardIndex === index) ?
                            <>
                                {(company.totalSalesDifference < 0) ? <p>{`Total sales are down by R${(company.totalSalesDifference * -1).toFixed(2)}`}</p> : null}
                            </>
                            : null}
                    </Card>
                );
            })}
            </div>
        )
    } else {
        return (
            <div>
                <div className='gaugeSection'>
                    {/* <PieChart adminOverview={AdminOverview}/> */}
                </div>
                {AdminOverview.map((company, index) => {
                    return (
                        <Card onClick={handleCardClick} className={`${(company.colour === 'red') ? 'homeCardsCompanyRed' : (company.colour === 'orange') ? 'homeCardsCompanyOrange' : (company.colour === 'green') ? 'homeCardsCompanyGreen' : (company.colour === '') ? 'homeCardsCompanyRed': null}`} key={index} id={index}>
                            {(company.colour === '')?<p>{company.Name} Has No Available Data</p>:<p>{company.Name}</p>}
                            {(ClickedCardIndex === index) ?
                                <>
                                    {(company.totalSalesDifference < 0) ? <p>{`Total sales are down by R${(company.totalSalesDifference * -1).toFixed(2)}`}</p> : null}
                                    {(company.totalRevenueDifference < 0) ? <p>{`Total revenue is down by R${(company.totalRevenueDifference * -1).toFixed(2)}`}</p> : null}
                                </>
                                : null}
                        </Card>
                    );
                })}
            </div>
        )
    }

})

export default Admin;