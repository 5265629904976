import "./style.css";
import React from 'react';
import { pdfjs } from 'react-pdf';
import { saveAs } from 'file-saver';
import {Card,Button} from "../../components";
import {
  PDFViewer,
  Document,
  Page,
  pdf,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
var mobile = require("is-mobile");
Font.register({
  family: "Roboto",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

// #region styles
const BORDER_COLOR = "#bfbfbf";
const BORDER_STYLE = "solid";
const COL1_WIDTH = 40;
const COLN_WIDTH = 100 / 7;
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Roboto",
  },
  subtitle: {
    fontSize: 14,
    margin: 12,
    fontFamily: "Roboto",
  },
  text: {
    marginTop: 33,
    marginLeft: 15,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Roboto",
    marginVertical: 16.2,
  },

  tableCol1: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 1,
    borderBottomWidth: 0,
    marginBottom: 0.5,
    height: 46,
  },
  description: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 0,
    display: "flex",
    borderLeftWidth: 0,
    borderTopWidth: 0.5,
    borderBottomWidth: 0,
    marginBottom: 1,
    height: 46,
  },
  tableCol: {
    width: 40 + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 0,
    borderLeftWidth: 3,
    borderTopWidth: 1,
    marginLeft: 208,
    width: 312,
    marginTop: -47.5,
  },

  table: {
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 3,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    marginTop: 6,
  },
});
const stylesMobile = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 40,
    paddingHorizontal: 40,
    width: "90%",
    height: "90%",
    margin: "auto",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Roboto",
    marginBottom: 20,
  },
  subtitle: {
    fontSize: 9,
    margin: 8,
    fontFamily: "Roboto",
    width: "30%", // Adjust the width to fit the subtitles in desktop view
    textAlign: "right", // Align the subtitles to the right
  },
  text: {
    marginTop: 20,
    marginLeft: 10,
    fontSize: 10,
    textAlign: "justify",
    fontFamily: "Roboto",
    marginBottom: 10,
  },
  tableCol1: {
    width: "40%", // Increase the width for desktop view
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 2,
    display: "flex",
//     marginLeft: "30%", // Add left margin to align the content to the right
//     borderLeftWidth: 0,
    borderTopWidth: 1,
    borderBottomWidth: 1.5,
    marginBottom: -1,
        marginBottom: -1,
    height:45,
   
  },
  description: {
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 0,
    display: "flex",
    borderLeftWidth: 0,
    borderTopWidth: 0.5,
    borderBottomWidth: 0,
    marginBottom: 20,
    marginLeft: "30%",
    height: 30,
 
  },
  
  tableCol: {
    width: "60%", // Decrease the width for desktop view
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    height:50,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    marginLeft: "40%",
    marginTop: -50,
     marginTop: -48.5,
  },
  
  
  table: {
    display: "table",
    width: "75%",
    height: "95%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 2,
    borderRightWidth: 2,
    borderBottomWidth: 2,
    borderTopWidth: 5,
    marginTop: 0,
    marginLeft: 64,

  },
});
// #endregion

export default function JobRequestPDF(jobData) {
  let data = jobData;
  let reqDate = new Date(jobData.ReqDate).toLocaleDateString();
  let dueDate = new Date(jobData.DueDate).toLocaleDateString();

  if (mobile()){
    return (
      <></>
     
    );
  }else{
    return (
    
      <PDFViewer
        style={{
          width: "99%",
          height:  "98%",
        }}
      >
        <Document title={data.Title + "_" + data.Client + "_" + data.Department}>
          <Page style={styles.body}>
            <Text style={styles.title}>{data.Title}</Text>
            <View style={styles.table}>
              <View style={styles.tableCol1}>
                <Text style={styles.subtitle}>Client</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.text}>{data.Client}</Text>
              </View>
              <View style={styles.tableCol1}>
                <Text style={styles.subtitle}>Department</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.text}>{data.Department}</Text>
              </View>
              <View style={styles.tableCol1}>
                <Text style={styles.subtitle}>Request date</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.text}>{reqDate}</Text>
              </View>
              <View style={styles.tableCol1}>
                <Text style={styles.subtitle}>Due date</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.text}>{dueDate}</Text>
              </View>
              <View style={styles.tableCol1}>
                <Text style={styles.subtitle}>Status</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.text}>{data.Status}</Text>
              </View>
              <View style={styles.description}>
                <Text style={styles.subtitle}>Description</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.text}>{data.Description}</Text>
              </View>
  
              {jobData.Department === "Design" ? (
                <>
                  <View style={styles.description}>
                    <Text style={styles.subtitle}>Design elements</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.text}>
                      {data.DesignSpec.designElements}
                    </Text>
                  </View>
                  <View style={styles.description}>
                    <Text style={styles.subtitle}>Collateral</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.text}>{data.DesignSpec.collateral}</Text>
                  </View>
  
                  <View style={styles.description}>
                    <Text style={styles.subtitle}>List deliverables</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.text}>
                      {data.DesignSpec.listDeliverables}
                    </Text>
                  </View>
                </>
              ) : null}
              {jobData.Department === "Dev" ? (
                <>
                  <View style={styles.description}>
                    <Text style={styles.subtitle}>Platform</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.text}>{data.DevSpec.Platform}</Text>
                  </View>
  
                  <View style={styles.description}>
                    <Text style={styles.subtitle}>Type</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.text}>{data.DevSpec.Type}</Text>
                  </View>
  
                  <View style={styles.description}>
                    <Text style={styles.subtitle}>Outcome/s</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.text}>{data.DevSpec.Outcomes}</Text>
                  </View>
  
                  <View style={styles.description}>
                    <Text style={styles.subtitle}>Figma available?</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.text}>{data.DevSpec.Figma_Available}</Text>
                  </View>
                </>
              ) : null}
            </View>
          </Page>
        </Document>
      </PDFViewer>
    );
  }
 
}
