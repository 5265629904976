import React from 'react';
import './style.css';
function Slider({ aions = [], forecast = [] }) {
    // #region Dates

    let actualArray = [];
    let opexSet = new Set();
    let alkemiaSet = new Set();
    let forecastArray = [];
    for (let m = 0; m < 6; m++) {
        let date = new Date();
        let month = date.getMonth();
        let year = date.getFullYear();
        month = month - m;
        if (month < 0) {
            month = month + 12;
            year = year - 1;
        }
        date.setMonth(month);
        date.setFullYear(year);
        let startDate = new Date(date);
        startDate.setDate(1);
        let endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() + 1);
        endDate.setDate(0);
        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);
        // #endregion
        let currentData = aions.filter((a) => (new Date(a.Date)) >= startDate && (new Date(a.Date)) <= endDate);
        let currentforecast = forecast.filter((a) => (new Date(a.Date)) >= startDate && (new Date(a.Date)) <= endDate);
        let finalData = {
            Income: {
                Total: 0,
                AEX: 0,
                Interest: 0,
                Alkemia: 0,
                AlkemiaDetail: []
            },
            COS: {
                Total: 0,
                Alkemia: 0,
                AEX: 0
            },
            Telkom: {
                Drawdown: 0
            },
            OPEX: {
                Total: 0,
                AEX: 0,
                AEXDetail: [],
                Alkemia: 0,
                AlkemiaDetail: [],
                Aions: 0,
                AionsDetail: []
            },
            Date: `${startDate.getMonth() + 1}/${startDate.getFullYear()}`
        }
        let finalTarget = {
            Income: {
                Total: 0,
                AEX: 0,
                Interest: 0,
                Alkemia: 0,
            },
            COS: {
                Total: 0,
                Alkemia: 0,
                AEX: 0
            },
            Telkom: {
                Drawdown: 0
            },
            OPEX: {
                Total: 0,
                AEX: 0,
                Alkemia: 0,
                Aions: 0
            }
        }

        for (let i = 0; i < currentData.length; i++) {
            let cData = currentData[i];
            //Income
            let cIncome = cData.Income
            let tempTotal = 0;
            tempTotal += cIncome.AEX;
            finalData.Income.AEX += cIncome.AEX;
            tempTotal += cIncome.Interest;
            finalData.Income.Interest += cIncome.Interest;
            for (let j = 0; j < cIncome.Alkemia.length; j++) {
                let cAlkemiaIncome = cIncome.Alkemia[j];
                alkemiaSet.add(cAlkemiaIncome.Client);
                tempTotal += cAlkemiaIncome.Amount;
                finalData.Income.Alkemia += cAlkemiaIncome.Amount
                let alkIndex = finalData.Income.AlkemiaDetail.findIndex(obj => obj.Client === cAlkemiaIncome.Client);
                if (alkIndex !== -1) {
                    finalData.Income.AlkemiaDetail[alkIndex].Amount += cAlkemiaIncome.Amount
                } else {
                    finalData.Income.AlkemiaDetail.push(cAlkemiaIncome);
                }
            }
            finalData.Income.Total += tempTotal
            //COS
            let cCOS = cData.COS;
            finalData.COS.Total += cCOS.AEX;
            finalData.COS.AEX += cCOS.AEX;
            finalData.COS.Total += cCOS.Alkemia;
            finalData.COS.Alkemia += cCOS.Alkemia;
            //Telkom
            finalData.Telkom.Drawdown += cData.Telkom.Amount
            //OPEX
            let cOPEX = cData.OPEX
            let tOpexTotal = 0;
            for (let k = 0; k < cOPEX.Aions.length; k++) {
                let tOpex = cOPEX.Aions[k];
                opexSet.add(tOpex.Category);
                tOpexTotal += tOpex.Amount;
                let opexIndex = finalData.OPEX.AionsDetail.findIndex(obj => obj.Category === tOpex.Category);
                if (opexIndex !== -1) {
                    finalData.OPEX.AionsDetail[opexIndex].Amount += tOpex.Amount
                } else {
                    finalData.OPEX.AionsDetail.push(tOpex)
                }
            }
            finalData.OPEX.Total += tOpexTotal;
            finalData.OPEX.Aions += tOpexTotal;
        }
        actualArray.unshift(finalData);
        for (let j = 0; j < currentforecast.length; j++) {
            let cforecast = currentforecast[j];
            // Income
            let cIncome = cforecast.Income;
            let tmpIncFor = 0;
            finalTarget.Income.AEX += cIncome.AEX;
            tmpIncFor += cIncome.AEX;
            finalTarget.Income.Alkemia += cIncome.Alkemia;
            tmpIncFor += cIncome.Alkemia;
            finalTarget.Income.Interest += cIncome.Interest;
            tmpIncFor += cIncome.Interest;
            finalTarget.Income.Total += tmpIncFor;
            // COS
            let cCos = cforecast.COS;
            let tmpCOSFor = 0;
            finalTarget.COS.AEX += cCos.AEX;
            tmpCOSFor += cCos.AEX;
            finalTarget.COS.Alkemia += cCos.Alkemia;
            tmpCOSFor += cCos.Alkemia;
            finalTarget.COS.Total += tmpCOSFor;
            // Telkom
            finalTarget.Telkom.Drawdown += cforecast.Telkom.Amount;
            // OPEX
            finalTarget.OPEX.Total += cforecast.OPEX.Aions;
            finalTarget.OPEX.Aions += cforecast.OPEX.Aions;
        }
        forecastArray.unshift(finalTarget);
    }
    let Target = forecastArray[forecastArray.length - 1];
    let Actual = actualArray[actualArray.length - 1];
    let Income = Number(((Actual.Income.Total) / (Target.Income.Total) * 100) || 0);
    let COS = Number(((Actual.COS.Total) / (Target.COS.Total) * 100) || 0);
    let OPEX = Number(((Actual.OPEX.Total) / (Target.OPEX.Total) * 100) || 0);
    let Telkom = Number(((Actual.Telkom.Drawdown) / (Target.Telkom.Drawdown) * 100) || 0);
    return (
        <div style={{ width: '70%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <card style={{ width: '100%', marginBottom: '1%' }} className='totalSalesSliderCard'>
                    <div style={{ width: '100%', marginBottom: '1%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><p style={{ width: '60%' }}>Income</p><b>{Math.min(Income, 100) || 0}%</b></div>
                    <input className='sliderStyle' type='range' min={0} max={100} value={Income} />
                </card>
                <card style={{ width: '100%', marginBottom: '1%' }} className='opexSliderCard'>
                    <div style={{ width: '100%', marginBottom: '1%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><p style={{ width: '60%' }}>OPEX</p><b>{Math.min(OPEX, 100) || 0}%</b></div>
                    <input className='sliderStyle' type='range' min={0} max={100} value={OPEX} />
                </card>
            </div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <card style={{ width: '100%' }} className='COSSliderCard'>
                    <div style={{ width: '100%', marginBottom: '1%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><p style={{ width: '60%' }}>COS</p><b>{Math.min(COS, 100) || 0}%</b></div>
                    <input className='sliderStyle' type='range' min={0} max={100} value={COS} />
                </card>
                <card style={{ width: '100%' }} className='telkomSliderCard'>
                    <div style={{ width: '100%', marginBottom: '1%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><p style={{ width: '60%' }}>Telkom</p><b>{Math.min(Telkom, 100) || 0}%</b></div>
                    <input className='sliderStyle' type='range' min={0} max={100} value={Telkom} />
                </card>
            </div>
        </div>
    )
}

export default Slider;