import React, { useState } from 'react';
import './style.scss';

function CustomSelect({ children, className, id, placeholder, type, value, onKeyDown, onChange, style = {}, onMouseEnter, onMouseLeave, department }) {
    const customStyle = style;
    const [Active, setActive] = useState(false)
    function setActiveTrue() {
        setActive(true);
    }
    function setActiveFalse() {
        setActive(false);
    }
    const modifiedChildren = React.Children.toArray(children).map((child, index) => {
        const marginTop = index === 0 ? '25px' : '5px';
        return React.cloneElement(child, { style: { ...child.props.style, marginTop } });
    });
    return (
        <div className={`customSelect ${className} ${(Active) ? 'customSelectActive' : ''}`} style={customStyle} type={type} id={id} value={value} onKeyDown={onKeyDown} onChange={onChange} onMouseEnter={setActiveTrue} onMouseLeave={setActiveFalse}>
            <p className='placeHolder'>{placeholder}</p>
            {(value === '') ? <p className='DepartmentLabel'>Select</p> : <p className='DepartmentLabel'>{value}</p>}

            <div className='customSelectOptions' style={customStyle} type={type} id={id} value={value} onKeyDown={onKeyDown} onChange={onChange}>
                {(Active) ?
                    <>{modifiedChildren}</>
                    : null}
            </div>

        </div>
    );
}

export default CustomSelect;
