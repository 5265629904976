import React, { useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import './style.css';
import { useNavigate } from 'react-router-dom';
import CloseCard from '../../../assets/images/closeCard.png';
import UploadIcon from '../../../assets/images/upload.png';
import { Input, View, Button, Card, DoughnutChart, } from '../../../components';
var mobile = require('is-mobile');

const ClientsInfo = observer(({ ClientID, back }) => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const fileInputRef = useRef(null);
    const [SelectedClient, setSelectedClient] = useState({});
    const [AddSlA, setAddSLA] = useState(false);
    const [AddQuote, setAddQuote] = useState(false);
    const [SLA, setSLA] = useState({
        Name: '',
        StartDate: new Date(),
        EndDate: new Date(),
        Signed: false,
        DocumentName: ''
    });
    const [Quote, setQuote] = useState({
        Name: '',
        Comments: '',
        ExpiryDate: new Date(),
        Status: ''
    });

    useEffect(() => {
        getClient();
    }, []);

    function getClient() {
        let id = ClientID;
        store.setLoading(true);
        store.getClientByID(id).then((client) => {
            if (client) {
                setSelectedClient(client);
                store.setLoading(false);
            } else {
                window.alert('Could not find client');
                store.setLoading(false);
            }
        })
    }
    function clientInfoChange(e) {
        let client = SelectedClient;

        if (e.currentTarget.id === 'ClientName') {
            client.ClientName = e.target.value;
        }
        if (e.currentTarget.id === 'InvoiceName') {
            client.InvoiceName = e.target.value;
        }
        if (e.currentTarget.id === 'ContactName') {
            client.ContactName = e.target.value;
        }
        if (e.currentTarget.id === 'ContactEmail') {
            client.ContactEmail = e.target.value;
        }
        if (e.currentTarget.id === 'ContactNumber') {
            client.ContactNumber = e.target.value;
        }
        if (e.currentTarget.id === 'VATNo') {
            client.VATNo = e.target.value;
        }
        if (e.currentTarget.id === 'Address1') {
            client.Address1 = e.target.value;
        }
        if (e.currentTarget.id === 'Address2') {
            client.Address2 = e.target.value;
        }
        if (e.currentTarget.id === 'City') {
            client.City = e.target.value;
        }
        if (e.currentTarget.id === 'PostalCode') {
            client.PostalCode = e.target.value;
        }
        setSelectedClient(client);
        forceUpdate();
    }
    function updateClientDetails() {
        let data = SelectedClient;
        let id = data._id;

        store.setLoading(true);
        store.updateClientById(id, data).then((ret) => {
            if (ret) {
                window.alert('Client updated successfully.');
                store.setLoading(false);
            } else {
                window.alert('Client could not be updated');
                store.setLoading(false);
            }
        });
        forceUpdate();
    }
    function cancelDetailChange() {

    }
    function addNewQuoteClicked() {
        setAddQuote(true);
    }
    function addNewSLA() {
        setAddSLA(true)
    }
    function backClicked() {
        back('Alkemia')
    }
    function QuotesChanged(e) {
        let quote = Quote;
        if (e.currentTarget.id === 'QuoteName') {
            quote.Name = e.target.value;
        }
        if (e.currentTarget.id === 'QuoteExpiryDate') {
            quote.ExpiryDate = e.target.value;
        }
        setQuote(quote);
        forceUpdate();
    }
    function SlaChanged(e) {
        let sla = SLA;

        if (e.currentTarget.id === 'SLAName') {
            sla.Name = e.target.value;
        }
        if (e.currentTarget.id === 'SLAStartDate') {
            sla.StartDate = e.target.value;
        }
        if (e.currentTarget.id === 'SLAEndDate') {
            sla.EndDate = e.target.value;
        }
        setSLA(sla);
        forceUpdate();
    }
    function closeAddUser() {
        setAddSLA(false);
        setAddQuote(false);
    }
    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
    };
    function createSLAClicked() {
        let id = SelectedClient._id
        let client = SelectedClient;
        if (SLA.Name === '') {
            window.alert('SLA name is required.');
            return
        } else {
            client.SLAs.push(SLA);
        }
        forceUpdate();
        store.setLoading(true);
        store.updateClientById(id, client).then((ret) => {
            if (ret) {
                setSLA({
                    Name: '',
                    StartDate: new Date(),
                    EndDate: new Date(),
                    Signed: false,
                    DocumentName: ''
                });
                setAddSLA(false);
                setAddQuote(false);
                store.setLoading(false);
            } else {
                window.alert('Error adding new Sla');
                store.setLoading(false);
            }
        })
    }
    function createQuoteClicked() {
        let id = SelectedClient._id
        let client = SelectedClient;
        if (Quote.Name === '') {
            window.alert('Quotes name is required.');
            return
        } else {
            client.Quotes.push(Quote);
        }
        forceUpdate();
        store.setLoading(true);
        store.updateClientById(id, client).then((ret) => {
            if (ret) {
                setQuote({
                    Name: '',
                    Comments: '',
                    ExpiryDate: new Date(),
                    Status: ''
                });
                setAddSLA(false);
                setAddQuote(false);
                store.setLoading(false);
            } else {
                window.alert('Error adding new quotes');
                store.setLoading(false);
            }
        })
    }
    if (mobile()) {
        return (
            <div>

            </div>
        );

    } else {
        return (
            <div className='container' style={{ width: '100%', height: '95%', marginTop: '7%' }} >
                <div className='ClientToDiv'>
                    <Button className='backButton' onClick={backClicked}><img />Back</Button>
                    <Button className='addNewQuoteSla' style={{ marginLeft: '40%' }} onClick={addNewQuoteClicked}>New Quote</Button>
                    <Button className='addNewQuoteSla' style={{ marginLeft: '2%' }} onClick={addNewSLA}>New SLA</Button>
                    <h2 style={{ marginTop: '5%', marginLeft: '2%' }}>{SelectedClient.ClientName}</h2>
                </div>
                <div style={{ width: '100%', marginLeft: '10%', overflow: 'scroll', height: '94%' }}>
                    <h3>Client Details</h3>
                    <Input className='clientInfoInputs' value={SelectedClient.ClientName} id='ClientName' onChange={clientInfoChange} placeholder='Client Name' />
                    <Input className='clientInfoInputs' value={SelectedClient.InvoiceName} id='InvoiceName' onChange={clientInfoChange} placeholder='Invoice Name' />
                    <Input className='clientInfoInputs' value={SelectedClient.ContactName} id='ContactName' onChange={clientInfoChange} placeholder='Contact Name' />
                    <Input className='clientInfoInputs' value={SelectedClient.ContactEmail} id='ContactEmail' onChange={clientInfoChange} placeholder='Contact Email' />
                    <Input className='clientInfoInputs' value={SelectedClient.ContactNumber} id='ContactNumber' onChange={clientInfoChange} placeholder='Contact Number' />
                    <Input className='clientInfoInputs' value={SelectedClient.VATNo} id='VATNo' onChange={clientInfoChange} placeholder='VAT No' />
                    <Input className='clientInfoInputs' value={SelectedClient.Address1} id='Address1' onChange={clientInfoChange} placeholder='Address 1' />
                    <Input className='clientInfoInputs' value={SelectedClient.Address2} id='Address2' onChange={clientInfoChange} placeholder='Address 2' />
                    <div style={{ width: '80%', display: 'flex', flexDirection: 'row' }}>
                        <Input style={{ width: '70%', borderRadius: '10px', height: '40px', border: '1px solid #404952', marginbottom: '15px', margintop: '15px' }} value={SelectedClient.City} id='City' onChange={clientInfoChange} placeholder='City' />
                        <Input style={{ width: '30%', borderRadius: '10px', height: '40px', border: '1px solid #404952', marginbottom: '15px', margintop: '15px', marginLeft: '1%' }} value={SelectedClient.PostalCode} id='PostalCode' onChange={clientInfoChange} placeholder='Postal Code' />
                    </div>
                    <div style={{ width: '80%', display: 'flex', flexDirection: 'row' }}>
                        <button style={{ width: '30%', borderRadius: '10px', height: '40px', marginbottom: '15px', margintop: '15px', marginLeft: '40%', marginbottom: '5%', backgroundColor: 'rgb(227, 54, 126', border: 'none', color: 'white' }} onClick={backClicked}>Cancel</button>
                        <button style={{ width: '30%', borderRadius: '10px', height: '40px', marginbottom: '15px', margintop: '15px', marginLeft: '1%', marginbottom: '5%', backgroundColor: 'rgba(143,202,114,1)', border: 'none', color: 'white' }} onClick={updateClientDetails}>Update</button>
                    </div>
                </div>
                {AddSlA ? (
                    <Card className='addForecast'>
                        <img className='closeAddActualsCard' onClick={closeAddUser} src={CloseCard} />
                        <h3>New SLA</h3>
                        <Input className='newQuoteInputs' placeholder='SLA Name' onChange={SlaChanged} id='SLAName' />
                        <div style={{ display: 'flex', flexDirection: 'row', width: '90%' }}>
                            <h3 style={{ width: '15%' }}>Start Date:</h3><Input className='NewQuoteDateInputs' placeholder='SLA Start Date' type='date' onChange={SlaChanged} id='SLAStartDate' /><h3 style={{ marginLeft: '2%', width: '15%' }}>End Date:</h3><Input className='NewQuoteDateInputs' placeholder='SLA End Date' type='date' onChange={SlaChanged} id='SLAEndDate' />
                        </div>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        <Button className='newQuoteInputs' style={{ backgroundColor: 'rgb(143, 202, 114)' }} placeholder='Upload' onClick={handleButtonClick}>Upload <img src={UploadIcon} /></Button>
                        <Button className='createQuoteBtn' onClick={createSLAClicked}>Create</Button>
                    </Card>
                ) : AddQuote ? <Card className='addForecast'>
                    <img className='closeAddActualsCard' onClick={closeAddUser} src={CloseCard} />
                    <h3>New Quote</h3>
                    <Input className='newQuoteInputs' placeholder='Quote Name' onChange={QuotesChanged} id='QuoteName' />
                    <div style={{ display: 'flex', flexDirection: 'row', width: '90%' }}>
                        <h3 style={{ width: '18%' }}>Expiry Date :</h3>
                        <Input className='newQuoteInputs' style={{ width: '82%', marginLeft: '2%' }} placeholder='Quote Expiry Date' type='date' onChange={QuotesChanged} id='QuoteExpiryDate' />
                    </div>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    <Button
                        className='newQuoteInputs'
                        style={{ backgroundColor: 'rgb(143, 202, 114)' }}
                        placeholder='Upload'
                        onClick={handleButtonClick}
                    >
                        Upload <img src={UploadIcon} alt="Upload Icon" />
                    </Button>
                    <Button className='createQuoteBtn' onClick={createQuoteClicked}>Create</Button>
                </Card> : null}
            </div>
        )
    }
})

export default ClientsInfo;