import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/Store";
import { Card, Input, View, Button, LoadingBarGauge } from "../../components";
import "./style.css";
import React, { useEffect, useState } from "react";
import { jobs } from "../../services/requests";
import { Colors } from "chart.js";
import { FaExclamation, FaMinus, FaCheck } from 'react-icons/fa';
import Admin from "./Admin";
import Company from "./Company";
import Dev from "./Dev";
import Service from "./Service";
var mobile = require("is-mobile");



const HomePage = observer(() => {

  const store = useStore();
  const [, updateState] = useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
  }, []);
  
  // #endregion
  if (!mobile()) {
    return (
      <div style={{ overflow: 'scroll', height: '100%'}}>
        {store.user.Role === "Admin" ? <Admin/> : null}
        {store.user.Role === "Company" ? <Company/> : null}
        {store.user.Role === "Design" ? <Service/> : null}
        {store.user.Role === "Dev" ? <Service/> : null}
      </div>
    );

  } else {
    return (
      <div style={{ overflow: 'scroll', height: '100%'}}>
        {store.user.Role === "Admin" ? <Admin/> : null}
        {store.user.Role === "Company" ? <Company/> : null}
        {store.user.Role === "Design" ? <Service/> : null}
        {store.user.Role === "Dev" ? <Service/> : null}
      </div >
    );
  }
});
export default HomePage;
