import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { ProfitLossChart, RunwayChart, ActRunwayChart, InvesteeProfitLossChart } from '../../../components';

const Reports = observer(({ }) => {
    const store = useStore();
    const navigate = useNavigate();
    const actProffLossRef = useRef();
    const actRunwayref = useRef();
    const investeeProfLossRef = useRef();
    const investeeRunwayRef = useRef();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [ActProfitLoss, setActProfitLoss] = useState({ width: 0, height: 0 });
    const [ActRunway, setActRunway] = useState({ width: 0, height: 0 });
    const [InvesteeProfitLoss, setInvesteeProfitLoss] = useState({ width: 0, height: 0 });
    const [InvesteeRunway, setInvesteeRunway] = useState({ width: 0, height: 0 });
    const [ActProfitLossData, setActProfitLossData] = useState([]);
    const [ActRunwayData, setActRunwayData] = useState([]);
    const [InvesteeProfitLossData, setInvesteeProfitLossData] = useState([]);
    const [CompPercentage, setCompPercentage] = useState(0);
    const [ActPercentage, setActPercentage] = useState(0);
    const [InvesteeRunwayData, setInvesteeRunwayData] = useState([]);
    useEffect(() => {
        getCompData()
        const updateDimensions = () => {
            if (actProffLossRef.current) {
                setActProfitLoss({
                    width: actProffLossRef.current.offsetWidth,
                    height: actProffLossRef.current.offsetHeight,
                });
            }
            if (actRunwayref.current) {
                setActRunway({
                    width: actRunwayref.current.offsetWidth,
                    height: actRunwayref.current.offsetHeight,
                });
            }
            if (investeeProfLossRef.current) {
                setInvesteeProfitLoss({
                    width: investeeProfLossRef.current.offsetWidth,
                    height: investeeProfLossRef.current.offsetHeight,
                });
            }
            if (investeeRunwayRef.current) {
                setInvesteeRunway({
                    width: investeeRunwayRef.current.offsetWidth,
                    height: investeeRunwayRef.current.offsetHeight,
                });
            }
        };
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    const getCompData = async () => {
        store.setLoading(true);
        let aions = await store.getAions();
        let comps = await store.getCompanies();
        if (comps === "error" || typeof comps === "string" || !comps || comps === false) {
            window.alert('No companies found')
            store.setLoading(false);
            return;
        }
        if (aions === 'error' || !aions) {
            window.alert('No data found')
            store.setLoading(false);
            return;
        }
        let actuals = [];
        let actRunway = []
        let investees = [];
        for (let p = 0; p < comps.length; p++) {
            let date = new Date();
            let currMonth = date.getMonth() - 1;
            let currYear = date.getFullYear();

            let company = comps[p];
            let acts = company.Values;
            let currComp = {
                Name: company.Name,
                Income: 0,
                Expenses: 0,
                Cashflow: 0,
                Burnrate: 0
            }
            let runwayObj = {
                Company: company.Name,
                Months: 0
            }
            let compActs = []
            if (!acts) {
                console.log('Acts not available')
            } else {
                for (let a = 0; a < acts.length; a++) {
                    let currAct = acts[a];
                    if (currAct.Type === 'Actual') {
                        compActs.push(currAct);
                        // let totalIncome = Object.values(currAct.Income).reduce((acc, arr) => {
                        //     return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        // }, 0);
                        // let totalExpenses = Object.values(currAct.Expenses).reduce((acc, arr) => {
                        //     return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        // }, 0);
                        // let totalBurnrate = Object.values(currAct.Burnrate).reduce((acc, arr) => {
                        //     return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        // }, 0);
                        // let totalCashflow = Object.values(currAct.Cashflow).reduce((acc, arr) => {
                        //     return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        // }, 0);

                        let totalIncome = 0;
                        if (currAct.Income) {
                            totalIncome = Object.values(currAct.Income).reduce((acc, arr) => {
                                return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                            }, 0);
                        };
                        let totalExpenses = 0;
                        if (currAct.Expenses) {
                            totalExpenses = Object.values(currAct.Expenses).reduce((acc, arr) => {
                                return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                            }, 0);
                        };
                        let totalCashflow = 0;
                        if (currAct.Cashflow) {
                            totalCashflow = Object.values(currAct.Cashflow).reduce((acc, arr) => {
                                return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                            }, 0);
                        };
                        let totalBurnrate = 0;
                        if (currAct.Burnrate) {
                            totalBurnrate = Object.values(currAct.Burnrate).reduce((acc, arr) => {
                                return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                            }, 0);
                        };
                        currComp.Income += totalIncome;
                        currComp.Expenses += totalExpenses;
                        currComp.Cashflow += totalCashflow;
                        currComp.Burnrate += totalBurnrate;
                    }
                }
            }
            const currentDate = new Date();
            let currMonthObj = compActs.find(item => {
                let objDate = new Date(item.Date);
                let objMonth = objDate.getMonth();
                let objYear = objDate.getFullYear();
                return objMonth === currentDate.getMonth() - 1 && objYear === currentDate.getFullYear();
            });
            if (currMonthObj) {
                // let totalBurnrate = Object.values(currMonthObj.Burnrate).reduce((acc, arr) => {
                //     return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                // }, 0);
                // let totalCashflow = Object.values(currMonthObj.Cashflow).reduce((acc, arr) => {
                //     return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                // }, 0);
                let totalCashflow = 0;
                if (currMonthObj.Burnrate) {
                    totalCashflow = Object.values(currMonthObj.Burnrate).reduce((acc, arr) => {
                        return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                    }, 0);
                };
                let totalBurnrate = 0;
                if (currMonthObj.Burnrate) {
                    totalBurnrate = Object.values(currMonthObj.Burnrate).reduce((acc, arr) => {
                        return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                    }, 0);
                };
                let runway = calculateRunway(totalCashflow, totalBurnrate);
                if (runway > 0) {
                    runwayObj.Months = Number(runway)
                } else {
                    runwayObj.Months = 0
                }
            }
            actRunway.push(runwayObj);
            let obj = {
                Company: currComp.Name,
                Amount: currComp.Income - currComp.Expenses
            }
            investees.push(obj);
        }
        let profitComps = 0;
        investees.forEach((item) => {
            if (item.Amount > 0) {
                profitComps++;
            }
        });
        let total = (profitComps / investees.length) * 100;
        setInvesteeRunwayData(actRunway);
        setCompPercentage(total.toFixed(2));
        setInvesteeProfitLossData(investees)
        for (let i = 0; i < aions.length; i++) {
            if (aions[i].Type === 'Actual') {
                actuals.push(aions[i]);
            }
        }
        let runwayData = [
            { Company: 'Akemia', Months: 0 },
            { Company: 'Aions Ventures', Months: 0 },
            { Company: 'Aions Exchange', Months: 0 },
            { Company: 'Aions Brodcast Charity', Months: 0 },
        ]
        setActRunwayData(runwayData);
        let actIncome = 0;
        let actExpense = 0;
        let actCashflow = 0;
        let actBurnrate = 0;
        for (let b = 0; b < actuals.length; b++) {
            let act = actuals[b];
            // let totalIncome = Object.values(act.Income).reduce((acc, arr) => {
            //     return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
            // }, 0);
            // let totalExpenses = Object.values(act.Expenses).reduce((acc, arr) => {
            //     return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
            // }, 0);

            let totalIncome = 0;
            if (act.Income) {
                totalIncome = Object.values(act.Income).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalExpenses = 0;
            if (act.Expenses) {
                totalExpenses = Object.values(act.Expenses).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalBurnrate = 0;
            let totalCashflow = 0;
            actIncome += totalIncome;
            actExpense += totalExpenses;
            actCashflow += totalCashflow;
            actBurnrate += totalBurnrate;
        }
        let actData = [
            { Company: 'Alkemia', Amount: actIncome - actExpense },
            { Company: 'Aions Ventures', Amount: 0 },
            { Company: 'Aions Exchange', Amount: 0 },
            { Company: 'Aions Brodcast Charity', Amount: 0 }
        ]
        let actProf = 0;
        actData.forEach((item) => {
            if (item.Amount > 0) {
                actProf++;
            }
        });
        let acttotal = (actProf / actData.length) * 100;
        setActPercentage(acttotal.toFixed(2));
        setActProfitLossData(actData)
        forceUpdate();
        store.setLoading(false);
    }
    const calculateRunway = (cashflow, burnrate) => {
        const runway = cashflow / burnrate;
        return runway.toFixed(1);
    };
    return (
        <div className='overView-display'>
            <div className='profit-loss-ActChart'>
                <div className='title-legend-container'>
                    <div className='chartLegends-section'>
                        <div className='profit-divisions-section'>
                            <div className='profit-legend-constainer' />
                            <p>Profit</p>
                        </div>
                        <div className='loss-Divisions-section'>
                            <div className='loss-legend-constainer' />
                            <p>Loss</p>
                        </div>
                    </div>
                    <div className='chartTitle-section'>
                        <p>Profit/Loss</p>
                    </div>
                    <div className='chartPercentage-section'>
                        <div className='percent-value'>
                            <p>{ActPercentage}%</p>
                        </div>
                    </div>
                </div>
                <div className='profitloss-chart-container' ref={actProffLossRef}>
                    <ProfitLossChart width={ActProfitLoss.width} height={ActProfitLoss.height} data={ActProfitLossData} />
                </div>
            </div>
            <div className='runway-ActChart'>
                <div className='chart-title-Section'>
                    <p>Runway</p>
                </div>
                <div className='runway-chart-container'>
                    <ActRunwayChart width={ActProfitLoss.width} height={ActProfitLoss.height} data={ActRunwayData} />
                </div>
            </div>
            <div className='profit-loss-InvesteeChart'>
                <div className='title-legend-container'>
                    <div className='chartLegends-section'>
                        <div className='profit-divisions-section'>
                            <div className='profit-legend-constainer' />
                            <p>Profit</p>
                        </div>
                        <div className='loss-Divisions-section'>
                            <div className='loss-legend-constainer' />
                            <p>Loss</p>
                        </div>
                    </div>
                    <div className='chartTitle-section'>
                        <p>Profit/Loss</p>
                    </div>
                    <div className='chartPercentage-section'>
                        <div className='percent-value'>
                            <p>{CompPercentage}%</p>
                        </div>
                    </div>
                </div>
                <div className='profitloss-chart-container' ref={investeeProfLossRef}>
                    <InvesteeProfitLossChart width={InvesteeProfitLoss.width} height={InvesteeProfitLoss.height} data={InvesteeProfitLossData} />
                </div>
            </div>
            <div className='runway-InvesteeChart'>
                <div className='chart-title-Section'>
                    <p>Investee Runway</p>
                </div>
                <div className='runway-chart-container'>
                    <RunwayChart width={InvesteeProfitLoss.width} height={InvesteeProfitLoss.height} data={InvesteeRunwayData} />
                </div>
            </div>
        </div>
    );
});

export default Reports;