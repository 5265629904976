import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import './style.css';
import { useNavigate } from 'react-router-dom';
import NewClientIcon from '../../../assets/images/add-group.png';
import OpenClient from '../../../assets/images/right-arrow.png';
import BackIcon from '../../../assets/images/left.png';
import CloseCard from '../../../assets/images/closeCard.png';
import { Input, View, Button, Card, DoughnutChart, } from '../../../components';
var mobile = require('is-mobile');

const Alkemia = observer(({ selectedC }) => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [AllClients, setAllClients] = useState([]);
    const [SelectedClient, setSelectedClient] = useState({});
    const [PageSelected, setPageSelected] = useState('Initial');
    const [SelectedSubTab, setSelectedSubTab] = useState('Info')
    const [AddClient, setAddClient] = useState(false);
    const [Client, setClient] = useState({})
    const [SearchQuery, setSearchQuery] = useState('');
    const [NewClient, setNewClient] = useState({
        ClientName: '',
        InvoiceName: '',
        ContactName: '',
        ContactEmail: '',
        ContactNumber: 0,
        VATNo: 0,
        Address1: '',
        Address2: '',
        City: '',
        PostalCode: 0,
    });

    useEffect(() => {
        getAllClients();
    }, []);
    function getAllClients() {
        store.setLoading(true);
        store.getClients().then((ret) => {
            if (typeof ret === "string" || !ret) {
                setAllClients([]);
                store.setLoading(false);
                return;
            }
            if (ret.length === 0) {
                setAllClients([]);
                store.setLoading(false);
                return;
            }
            if (ret) {
                setAllClients(ret);
                store.setLoading(false);
            }
        });
    }
    function addNewClient() {
        setAddClient(true);
    }
    function clientDetailsChange(e) {
        let client = NewClient;
        if (e.currentTarget.id === 'ClientName') {
            client.ClientName = e.target.value
        }
        if (e.currentTarget.id === 'InvoiceName') {
            client.InvoiceName = e.target.value
        }
        if (e.currentTarget.id === 'ContactName') {
            client.ContactName = e.target.value
        }
        if (e.currentTarget.id === 'ContactEmail') {
            client.ContactEmail = e.target.value
        }
        if (e.currentTarget.id === 'ContactNumber') {
            client.ContactNumber = e.target.value
        }
        if (e.currentTarget.id === 'VATNo') {
            client.VATNo = e.target.value
        }
        if (e.currentTarget.id === 'Address1') {
            client.Address1 = e.target.value
        }
        if (e.currentTarget.id === 'Address2') {
            client.Address2 = e.target.value
        }
        if (e.currentTarget.id === 'City') {
            client.City = e.target.value
        }
        if (e.currentTarget.id === 'PostalCode') {
            client.PostalCode = e.target.value
        }
        setNewClient(client);
        forceUpdate();
    }
    function createClientClicked() {
        store.setLoading(true);
        let data = NewClient
        if (data.ClientName === '') {
            window.alert('Client name is required');
            return
        }
        if (data.InvoiceName === '') {
            window.alert('Invoice name is required');
            return
        }
        // if(data.ContactName === ''){
        //     window.alert('Contact name is required');
        //     return
        // }
        if (data.ContactEmail === '') {
            window.alert('Contact email is required');
            return
        }
        // if(data.ContactNumber === ''){
        //     window.alert('Contact number is required');
        //     return
        // }
        // if(data.VATNo === ''){
        //     window.alert('VAT number is required');
        //     return
        // }
        // if(data.Address1 === ''){
        //     window.alert('Address1 is required');
        //     return
        // }
        // if(data.Address2 === ''){
        //     window.alert('Address2 is required');
        //     return
        // }
        // if(data.City === ''){
        //     window.alert('City is required');
        //     return
        // }
        // if(data.PostalCode === ''){
        //     window.alert('Postal Code is required');
        //     return
        // }
        store.createClient(data).then((ret) => {
            if (ret) {
                store.setLoading(false);
                setAddClient(false);
                setNewClient({
                    ClientName: '',
                    InvoiceName: '',
                    ContactName: '',
                    ContactEmail: '',
                    ContactNumber: 0,
                    VATNo: 0,
                    Address1: '',
                    Address2: '',
                    City: '',
                    PostalCode: 0,
                });
                getAllClients();
            } else {
                window.alert('Error adding a new client');
                store.setLoading(false);
            }
        })
    }
    function handleSearch(e) {
        setAllClients([]);
        let searchQuery = e.target.value;
        store.getClients().then((ret) => {
            if (ret) {
                let filteredClients = ret.filter((item) =>
                    item.ClientName.toLowerCase().includes(searchQuery.toLowerCase())
                );
                setAllClients(filteredClients);
            } else {
                return;
            }
        });
    };


    function viewClient(index) {
        store.setLoading(true);
        let clients = AllClients;
        let selectedClient = clients[index];
        let Client = {
            ID: selectedClient._id,
            Page: 'Client',
            ClientPage: 'ClientsInfo'
        }
        selectedC(Client);
        setSelectedClient(clients[index]);
        if (clients[index]) {
            store.setLoading(false);
        } else {
            window.alert('Could not find client, please try again.');
        }
    };
    function closeClientsDetails() {
        setPageSelected('Initial');
        setSelectedClient({});
        store.setLoading(true);
        setAllClients([]);
        store.getClients().then((ret) => {
            if (typeof ret === "string" || !ret) {
                setAllClients([]);
                store.setLoading(false);
                return;
            }
            if (ret.length === 0) {
                setAllClients([]);
                store.setLoading(false);
                return;
            }
            if (ret) {
                setAllClients(ret);
                store.setLoading(false);

            }
        });
    }

    function addNewQuote() {
        setSelectedSubTab('NewQuote');
    }
    function addNewSLA() {
        setSelectedSubTab('NewSLA')
    }
    function closeAddClient() {
        setAddClient(false);
        setNewClient({
            ClientName: '',
            InvoiceName: '',
            ContactName: '',
            ContactEmail: '',
            ContactNumber: 0,
            VATNo: 0,
            Address1: '',
            Address2: '',
            City: '',
            PostalCode: 0,
        });
    }
    if (mobile()) {
        return (
            <div>

            </div>
        );

    } else {
        return (
            <div className='container' style={{ width: '100%', height: '95%' }} >
                <card className='clientsCards'>
                    <div className='addNewClient'>
                        <Card className='searchClient'>
                            <div style={{ width: '90%' }}>
                                <Input placeholder='Search' style={{ width: '75%', borderRadius: '10px' }} onChange={handleSearch} />
                            </div>
                            <div>
                                <p><b>Client </b>: {AllClients.length || 0}</p>
                            </div>
                        </Card>
                        <Card className='AddNewClientBtn' onClick={addNewClient}>
                            <img src={NewClientIcon} />
                            <h3 style={{ marginLeft: '5%' }}>New Client</h3>
                        </Card>
                    </div>
                    <h1 style={{ marginLeft: '2%' }}>All Clients</h1>
                    <div className='allClientsList' >
                        {AllClients.map((item, index) => {
                            return (
                                <Card className='clientsMappedCards' style={{cursor: 'pointer'}} key={index} onClick={() => viewClient(index)}>
                                    <div style={{ width: '97%' }}>
                                        <p>{item.ClientName}</p>
                                    </div>
                                    <img src={OpenClient} onClick={() => viewClient(index)} />
                                </Card>
                            )
                        })}
                    </div>
                </card>
                {AddClient ? (
                    <Card className='addClientCard'>
                        <img className='closeAddActualsCard' onClick={closeAddClient} src={CloseCard} />
                        <h2>New Client</h2>
                        <Input className='clientInfoInputs' onChange={clientDetailsChange} id='ClientName' placeholder='Client Name' />
                        <Input className='clientInfoInputs' onChange={clientDetailsChange} id='InvoiceName' placeholder='Invoice Name' />
                        <Input className='clientInfoInputs' onChange={clientDetailsChange} id='ContactName' placeholder='Contact Name' />
                        <Input className='clientInfoInputs' onChange={clientDetailsChange} id='ContactEmail' placeholder='Contact Email' />
                        <Input className='clientInfoInputs' onChange={clientDetailsChange} id='ContactNumber' placeholder='Contact Number' />
                        <Input className='clientInfoInputs' onChange={clientDetailsChange} id='VATNo' placeholder='VAT No' />
                        <Input className='clientInfoInputs' onChange={clientDetailsChange} id='Address1' placeholder='Address 1' />
                        <Input className='clientInfoInputs' onChange={clientDetailsChange} id='Address2' placeholder='Address 2' />
                        <div style={{ width: '80%', display: 'flex', flexDirection: 'row' }}>
                            <Input style={{ width: '65%', borderRadius: '10px', height: '40px', border: '1px solid #404952', marginbottom: '15px', margintop: '15px' }} onChange={clientDetailsChange} id='City' placeholder='City' />
                            <Input style={{ width: '35%', borderRadius: '10px', height: '40px', border: '1px solid #404952', marginbottom: '15px', margintop: '15px' }} onChange={clientDetailsChange} id='PostalCode' placeholder='Postal Code' />
                        </div>
                        <Button className='createUserbtn' onClick={createClientClicked}>Create Client</Button>
                    </Card>
                ) : null}
            </div>
        )
    }
})

export default Alkemia;