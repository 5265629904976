import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineController, LineElement } from 'chart.js';
import 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineController, LineElement);

const ReportsBarGraph = ({ Aions = [], forecast = [], id }) => {
    const sortedLabels = [...new Set([...Aions, ...forecast].map((item) => item.Label))]
        .sort((a, b) => {
            const dateA = new Date(a);
            const dateB = new Date(b);
            return dateA - dateB;
        });

    const incomeData = sortedLabels.map((label) => {
        const aions = Aions.find((item) => item.Label === label && item.Type === 'Actual');
        return aions ? Number(aions.IncomeAndExpense.Income).toFixed(2) : 0;
    });

    const expenseData = sortedLabels.map((label) => {
        const aions = Aions.find((item) => item.Label === label && item.Type === 'Actual');
        return aions ? Number(aions.IncomeAndExpense.Expense).toFixed(2) : 0;
    });

    const incomeForecastData = sortedLabels.map((label) => {
        const forecastItem = forecast.find((item) => item.Label === label && item.Type === 'Forecast');
        return forecastItem ? Number(forecastItem.IncomeAndExpense.IncomeForecast).toFixed(2) || 0 : 0;
    });

    const expenseForecastData = sortedLabels.map((label) => {
        const forecastItem = forecast.find((item) => item.Label === label && item.Type === 'Forecast');
        return forecastItem ? Number(forecastItem.IncomeAndExpense.ExpenseForecast).toFixed(2) || 0 : 0;
    });
    const uniqueForecastLabels = [...new Set([...incomeForecastData, ...expenseForecastData].map((value, index) => incomeForecastData[index] > 0 || expenseForecastData[index] > 0 ? sortedLabels[index] : null))].filter(Boolean);
    const options = {
        responsive: true,
        width: 800,
        height: 300,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Total Income and Expenses',
            },
            datalabels: {
                display: 'auto',
                color: 'black',
                font: {
                    weight: 'bold',
                },
                formatter: (value) => value > 0 ? `R ${Number(value).toLocaleString()}` : '',
                
            },
        },
        scales: {
            x: {
                display: true,
                position: 'bottom',
                labels: sortedLabels,
                // offset: false
            },
            x2: {
                display: true,
                position: 'bottom',
                labels: sortedLabels.map(label => 
                    uniqueForecastLabels.includes(label) && 
                    !incomeData[sortedLabels.indexOf(label)] && 
                    !expenseData[sortedLabels.indexOf(label)] ? 'Forecast' : 'Actual',
                ),
                offset: true,
                grid: {
                    display: false,
                },
                ticks: {
                    display: true,
                },
            },
        },
    };
    const lineDatasetOptions = {
        datalabels: {
            align: 'top', 
        },
    };
    const data = {
        labels: sortedLabels,
        datasets: [
            {
                label: 'Income Forecast',
                data: incomeForecastData,
                backgroundColor: '#004493',
                borderColor: '#4285F4',
                borderWidth: 1,
                borderDash: [10, 10],
                tension: 0.1,
                type: 'line',
                ...lineDatasetOptions,
            },
            {
                label: 'Expense Forecast',
                data: expenseForecastData,
                backgroundColor: '#E4729F',
                borderColor: '#DB4437',
                borderWidth: 1,
                borderDash: [10, 10],
                tension: 0.1,
                type: 'line',
                ...lineDatasetOptions,
            },
            {
                label: 'Actual Income',
                data: incomeData,
                backgroundColor: '#004493',
                borderWidth: 1,
                categoryPercentage: 1,
            },
            {
                label: 'Actual Expenses',
                data: expenseData,
                backgroundColor: '#E4729F',
                borderWidth: 1,
                categoryPercentage: 1,
            },
            
        ],
    };

    return <Bar options={options} data={data} id={id} />;
};

export default ReportsBarGraph;
