import React, { useEffect, useState, updateState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { HiMagnifyingGlass } from "react-icons/hi2";
import './style.scss';
import { useNavigate } from 'react-router-dom';
import CloseCard from '../../../assets/images/CloseTask.svg';
import ViewDetails from '../../../assets/images/view.svg';
import CloseArchive from '../../../assets/images/closeArchive.svg';
import Filter from '../../../assets/images/Filter.svg';
import FilterDown from '../../../assets/images/FilterDown.svg';
import AddIcon from '../../../assets/images/addMeeting.svg';
import SocialMedia from '../../../assets/images/SocialMedia.svg';
import Brochure from '../../../assets/images/Brochure.svg';
import Flyer from '../../../assets/images/Flyer.svg';
import Presentation from '../../../assets/images/Presentation.svg';
import Poster from '../../../assets/images/Poster.svg';
import { Card, Textbox, CustomSelect, UnArchiveHover, ResizableTextarea } from '../../../components';
import { TRUE } from 'sass';
var mobile = require('is-mobile');

const AlkemiaBriefing = observer(() => {
    const store = useStore();
    const navigate = useNavigate();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [ViewCompanyTasks, setViewCompanyTasks] = useState(false);
    const [Company, setCompany] = useState({});
    const [Companies, setCompanies] = useState([]);
    const [Tasks, setTasks] = useState([]);
    const [SelectedTask, setSelectedTask] = useState({});
    const [SelectedIndex, setSelectedIndex] = useState(null);
    const [ViewTask, setViewTask] = useState(false);
    const [ViewArchived, setViewArchived] = useState(false);
    const [IsHovered, setIsHovered] = useState(false);
    const [FilterOn, setFilterOn] = useState(false);
    const [FilterComps, setFilterComps] = useState('A-Z');
    const [TitleFilter, setTitleFilter] = useState('A-Z');
    const [DepartmentFilter, setDepartmentFilter] = useState('A-Z');
    const [CompanyTasks, setCompanyTasks] = useState([]);
    const [TotCreds, setTotCreds] = useState(0);
    const [Sortkey, setSorKey] = useState({
        Title: true,
        Department: false
    });
    const [FilterStatus, setFilterStatus] = useState([]);
    const [MonthlyCredits, setMonthlyCredits] = useState(false);
    const [IsOpened, setIsOpened] = useState('No');
    const [TaskCreds, setTaskCreds] = useState([]);
    const [AddNewTask, setAddNewTask] = useState(false);
    const [SelectedComp, setSelectedComp] = useState({});
    const [TaskInfo, setTaskInfo] = useState({
        ActualDueDate: null,
        Title: "",
        Description: "",
        FeedBack: '',
        Department: "",
        Archived: false,
        Status: 'requested',
        Client: SelectedComp.Name,
        ClientEmail: store.user.Email,
        DesignSpec: {
            designElements: "",
            collaterals: "",
            category: "",
            listDeliverables: "",
            DocLink: '',
            Platform: '',
            Sizes: '',
            CopyIfRequired: '',
            Attachments: ''
        },
        ClientName: '',
        DevSpec: {
            Platform: "",
            Type: "",
            Outcomes: "",
            Figma_Available: ""
        },
        ReqDate: new Date(),
        DueDate: ''
    });
    const [Background, setBackground] = useState('');
    const Categories = [
        {
            Category: 'Website',
            subCategory: 'Bug Fixes',
            Description: 'There is an issue in the current platform that needs to be resolved',
            Image: null,
            Department: 'Dev'
        },
        {
            Category: 'Website',
            subCategory: 'Feature Request',
            Description: 'An additional feature needs to be added onto an existing platform',
            Image: null,
            Department: 'Dev'
        },
        {
            Category: 'Website',
            subCategory: 'Support Meeting',
            Description: 'A user has been in contact regarding an issue or question that requires a developer to provide an answer for or a solution to',
            Image: null,
            Department: 'Dev'
        },
        {
            Category: 'App',
            subCategory: 'Bug Fixes',
            Description: 'There is an issue in the current platform that needs to be resolved',
            Image: null,
            Department: 'Dev'
        },
        {
            Category: 'App',
            subCategory: 'Feature Request',
            Description: 'An additional feature needs to be added onto an existing platform',
            Image: null,
            Department: 'Dev'
        },
        {
            Category: 'App',
            subCategory: 'Support Meeting',
            Description: 'A user has been in contact regarding an issue or question that requires a developer to provide an answer for or a solution to',
            Image: null,
            Department: 'Dev'
        },
        {
            Category: 'StillGraphics',
            subCategory: 'Social Media',
            Description: 'Tailored still images/designs for specific platforms to enhance brand engagement. Possible platforms include Linkedin, Instagram, Facebook.',
            Image: SocialMedia,
            Department: 'Design'
        },
        {
            Category: 'StillGraphics',
            subCategory: 'Flyer',
            Description: 'A promotional document designed for quick distribution. There is no standard sizing for a flyer. Please provide specific size requirements from the printers you will be using to print.',
            Image: Flyer,
            Department: 'Design'
        },
        {
            Category: 'StillGraphics',
            subCategory: 'Brochure',
            Description: 'A multi-page informational document often used for marketing and advertising, providing a concise overview of a company, product, or service. Generally printers will require brochures to be in page variables of 4 = 4,8,12,16, 20,etc. Pages may also need to be provided indivually and not in spreads. Please clarify with the printers you will be using regarding their printing guidlines for brochures.',
            Image: Brochure,
            Department: 'Design'
        },
        {
            Category: 'StillGraphics',
            subCategory: 'Poster',
            Description: 'A poster is a visually impactful, single-sheet promotional material designed for public display. It conveys concise information using eye-catching graphics and text to attract attention and convey a message.',
            Image: Poster,
            Department: 'Design'
        },
        {
            Category: 'StillGraphics',
            subCategory: 'Presentation/Document',
            Description: 'A presentation is a visual communication tool involving spoken content accompanied by slides or visual aids. It serves to convey information, ideas, or messages in a structured and engaging format. Questions to Consider: does this need to be printed or is it digital? Does it need to be editable i.e in Microsoft Powerpoint, or can it be a PDF? A document is a written or electronic piece of information, providing details, instructions, or data on a particular subject. It serves as a record or reference for communication and documentation purposes. Questions to Consider: does this need to be printed or is it digital? Does it need to be editable i.e in Microsoft Word, or can it be a PDF?',
            Image: Presentation,
            Department: 'Design'
        },
        {
            Category: 'WebDesign',
            subCategory: 'New website design',
            Description: 'An online platform accessible through web browsers, comprising web pages with multimedia content and information. It serves various purposes, including information dissemination, communication, and e-commerce.',
            Image: null,
            Department: 'Design'
        },
        {
            Category: 'WebDesign',
            subCategory: 'New App design',
            Description: 'Short for application, an app is a software program designed for mobile devices or computers to perform specific tasks, provide services, or offer entertainment. It typically offers a user-friendly interface for easy interaction.',
            Image: null,
            Department: 'Design'
        },
        {
            Category: 'WebDesign',
            subCategory: 'Web page updates',
            Description: 'Updates involve refreshing the visual elements of a website, including layout, color schemes, and imagery.',
            Image: null,
            Department: 'Design'
        },
        {
            Category: 'WebDesign',
            subCategory: 'App updates',
            Description: 'Updates involve refreshing the visual elements of a website, including layout, color schemes, and imagery.',
            Image: null,
            Department: 'Design'
        },
        {
            Category: 'Video/Animation',
            subCategory: 'Animation - infographics',
            Description: 'Generally focused around text and numbers, animated infographics use dynamic visuals and motion graphics to convey complex information in a visually engaging and easily digestible format. They combine animation and data visualization to enhance audience understanding and retention.',
            Image: null,
            Department: 'Design'
        },
        {
            Category: 'Video/Animation',
            subCategory: 'Animation - characters',
            Description: `Animation characters and designs refer to visually crafted elements in animated content, encompassing characters' appearances, movements, and overall aesthetics. They play a crucial role in conveying the storyline, enhancing engagement, and creating a distinctive visual identity for the animation.`,
            Image: null,
            Department: 'Design'
        },
        {
            Category: 'Video/Animation',
            subCategory: 'Filming - Podcast',
            Description: 'A podcast/podcast is a digital audio or video program available for streaming or download, typically in a series format. It covers a wide range of topics, featuring discussions, interviews, or storytelling, and is accessible on various platforms for audience consumption.',
            Image: null,
            Department: 'Design'
        },
        {
            Category: 'Video/Animation',
            subCategory: 'Filming - Content',
            Description: 'A visual presentation captured on video, covering various topics such as educational content, entertainment, or promotional material. It leverages visual elements to engage and communicate messages effectively.',
            Image: null,
            Department: 'Design'
        },
        {
            Category: 'Video/Animation',
            subCategory: 'Filming - interview',
            Description: 'Serves as a visual tool for sharing insights, expertise, or corporate messages, often enhancing communication and engagement.',
            Image: null,
            Department: 'Design'
        },
        {
            Category: 'Video/Animation',
            subCategory: 'Filming - Product video',
            Description: 'A product video is a short visual presentation showcasing the features, benefits, and usage of a product. It aims to engage and inform potential customers quickly, often using a combination of visuals, demonstrations, and compelling narration.',
            Image: null,
            Department: 'Design'
        },
        {
            Category: 'Written',
            subCategory: 'Newsletter/Emailer',
            Description: 'A concise means of communication with a targeted audience through email. Please indicate whether you already have a mailer platform, eg, mailchimp, etc.',
            Image: null,
            Department: 'Design'
        },
        {
            Category: 'Written',
            subCategory: 'Blog/ Article',
            Description: 'A dynamic online digital journal on various topics. These can be posted onto social media - linekdin, or websites to healp with SEO.',
            Image: null,
            Department: 'Design'
        },
        {
            Category: 'Photo',
            subCategory: 'Product',
            Description: 'Visual representations of a product, showcasing its features, design, and details through high-quality images',
            Image: null,
            Department: 'Design'
        },
        {
            Category: 'Photo',
            subCategory: 'Lifestyle',
            Description: 'Lifestyle photos are candid or staged images that capture the essence of everyday life, often featuring people engaging in various activities.',
            Image: null,
            Department: 'Design'
        },
        {
            Category: 'Photo',
            subCategory: 'Corporate',
            Description: 'Photos are professional images capturing the personnel within a company. The main form of corporate photos are employee headshots.',
            Image: null,
            Department: 'Design'
        },
        {
            Category: 'Photo',
            subCategory: 'Event',
            Description: 'Event photos capture moments and activities during an event, providing a visual record of the occasion. These images showcase attendees, speakers, and key happenings, serving as both documentation and promotional material for the event.',
            Image: null,
            Department: 'Design'
        }
    ];
    useEffect(() => {
        getCompanies()
    }, []);
    const getCompanies = async () => {
        store.setLoading(true);
        let comps = await store.getCompanies();
        let tasks = await store.getJob();
        if (typeof comps === "string" || !comps) {
            setCompanies([]);
            store.setLoading(false);
            return;
        }
        if (typeof tasks === "string" || !tasks) {
            setTasks([]);
            store.setLoading(false);
            return;
        }
        setTasks(tasks);
        for (let i = 0; i < comps.length; i++) {
            let comp = comps[i];
            let compTasks = tasks.filter(task => task.Client === comp.Name);
            let date = new Date();
            let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            let month = monthNames[date.getMonth()];
            let year = date.getFullYear();
            let formattedDate = month + year;
            const designTasks = compTasks.filter((item) => {
                return item.Department === 'Design' && item.Status && !item.Status.startsWith('Declined');
            });
            let devTasks = compTasks.filter((item) => {
                return item.Department === 'Dev' && item.Status && !item.Status.startsWith('Declined');
            });
            const salesTasks = compTasks.filter((item) => {
                return item.Department === 'Sales' && item.Status && !item.Status.startsWith('Declined');
            });

            let totDevHrs = 0;
            let totDesignHrs = 0;
            let totSalesHrs = 0;
            devTasks.forEach((item) => {
                let est = item.EstimatedHours;
                let act = item.ActualHours;
                item.TotalCredits = 0;
                let credsUsed;
                let reqDate = new Date(item.ReqDate);
                if (Object.keys(item).includes('MonthlyCredits')) {
                    let creds = item.MonthlyCredits;
                    if (creds.hasOwnProperty(formattedDate)) {
                        totDevHrs += creds[formattedDate];
                        credsUsed += creds[formattedDate];
                    }
                } else if (reqDate.getMonth() === date.getMonth() && reqDate.getFullYear() === date.getFullYear()) {
                    if (act === undefined || act === null || act === 0 || !act) {
                        if (est === undefined || est === null || est === 0 || !est) {
                            totDevHrs += 0;
                            credsUsed += 0;
                        } else {
                            totDevHrs += est;
                            credsUsed += est
                        }
                    } else {
                        totDevHrs += act;
                        credsUsed += act;
                    }
                }
                item.TotalCredits = credsUsed
            });
            designTasks.forEach((item) => {
                let est = item.EstimatedHours;
                let act = item.ActualHours;
                item.TotalCredits = 0;
                let credsUsed;
                let reqDate = new Date(item.ReqDate);
                if (Object.keys(item).includes('MonthlyCredits')) {
                    let creds = item.MonthlyCredits;
                    if (creds.hasOwnProperty(formattedDate)) {
                        totDesignHrs += creds[formattedDate];
                        credsUsed += creds[formattedDate];
                    }
                } else if (reqDate.getMonth() === date.getMonth() && reqDate.getFullYear() === date.getFullYear()) {
                    if (act === undefined || act === null || act === 0 || !act) {
                        if (est === undefined || est === null || est === 0 || !est) {
                            totDesignHrs += 0;
                            credsUsed += 0;
                        } else {
                            totDesignHrs += est;
                            credsUsed += est
                        }
                    } else {
                        totDesignHrs += act;
                        credsUsed += act;
                    }
                }
                item.TotalCredits = credsUsed
            });
            salesTasks.forEach((item) => {
                let est = item.EstimatedHours;
                let act = item.ActualHours;
                item.TotalCredits = 0;
                let credsUsed;
                let reqDate = new Date(item.ReqDate);
                if (Object.keys(item).includes('MonthlyCredits')) {
                    let creds = item.MonthlyCredits;
                    if (creds.hasOwnProperty(formattedDate)) {
                        totSalesHrs += creds[formattedDate];
                        credsUsed += creds[formattedDate];
                    }
                } else if (reqDate.getMonth() === date.getMonth() && reqDate.getFullYear() === date.getFullYear()) {
                    if (act === undefined || act === null || act === 0 || !act) {
                        if (est === undefined || est === null || est === 0 || !est) {
                            totSalesHrs += 0;
                            credsUsed += 0;
                        } else {
                            totSalesHrs += est;
                            credsUsed += est
                        }
                    } else {
                        totSalesHrs += act;
                        credsUsed += act;
                    }
                }
                item.TotalCredits = credsUsed
            });

            let currentMonth = date.getMonth();
            let currentYear = date.getFullYear();
            let creds = comp.Credits;
            let currentMonthCredits = creds.find((credit) => credit.Month === currentMonth && credit.Year === currentYear);
            const remaningCreds = {
                Sales: currentMonthCredits && currentMonthCredits.Departments && currentMonthCredits.Departments.Sales - totSalesHrs || 0,
                Dev: currentMonthCredits && currentMonthCredits.Departments && currentMonthCredits.Departments.Dev - totDevHrs || 0,
                Design: currentMonthCredits && currentMonthCredits.Departments && currentMonthCredits.Departments.Design - totDesignHrs || 0
            }
            comp.RemainingCredits = remaningCreds;
            const tasksToSet = compTasks.filter(task => !task.Archived);
            comp.Tasks = tasksToSet;
        }
        setCompanies(comps.sort((a, b) => (a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0)));
        store.setLoading(false);
    };
    const viewComp = async (index) => {
        setViewCompanyTasks(true);
        setCompany(Companies[index]);
        setIsOpened('Yes')
        setCompanyTasks(Companies[index].Tasks);
        setSelectedIndex(index);
    };
    const closeViewComp = async (index) => {
        setViewCompanyTasks(false);
        setCompany(Companies[index]);
        setIsOpened('No')
        setCompanyTasks(Companies[index].Tasks);
        setSelectedIndex(index);
        forceUpdate();
    };
    const viewTaskClicked = async (index) => {
        setViewTask(true);
        setViewCompanyTasks(false);
        setSelectedTask(Company.Tasks[index]);
        if (Company.Tasks[index].hasOwnProperty('MonthlyCredits')) {
            let data = Company.Tasks[index].MonthlyCredits
            const total = Object.values(data).reduce((acc, value) => acc + value, 0);
            setTotCreds(total);

            const formattedData = Object.entries(data).map(([month, value]) => ({
                Name: month,
                Value: value
            }));
            setTaskCreds(formattedData);
        } else {
            setTotCreds(0)
        }
        forceUpdate();
    };
    const closeTaskClicked = async () => {
        setViewTask(false);
        setSelectedTask({});
        forceUpdate();
    };
    const viewArchivedClicked = async () => {
        setViewArchived(true);
        setViewTask(false);
    };
    function closeArchive() {
        setViewArchived(false);
        setViewCompanyTasks(false);
        setSelectedIndex(null);
        getCompanies();
        forceUpdate();
    };
    const archiveTask = async () => {
        let confirmed = window.confirm('Are you sure you want to archive this task?');
        if (!confirmed) {
            return
        }
        let task = SelectedTask;
        store.setLoading(true);
        task.Archived = true;
        let archive = await store.updateJobById(task);
        if (archive === 'error') {
            store.setLoading(false);
        }
        setViewTask(false);
        store.setLoading(false);
        forceUpdate();
    };
    const handleHoverStart = () => {
        setIsHovered(true);
        forceUpdate();
    };
    const handleHoverEnd = () => {
        setIsHovered(false);
        forceUpdate();
    };
    const handleUnacrchiveTask = async (index) => {
        let confirmed = window.confirm('Are you sure you want to unarchive this task?');
        if (!confirmed) {
            return
        }
        let task = Tasks[index];
        store.setLoading(true);
        task.Archived = false;
        let archive = await store.updateTaskById(task);
        if (archive === 'error') {
            store.setLoading(false);
        }
        setViewTask(false);
        store.setLoading(false);
        forceUpdate();
    }
    const handleFilterComps = () => {
        let comps = Companies;
        if (FilterComps === 'Z-A') {
            setFilterComps('A-Z');
            comps = comps.sort((a, b) => (a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0));
        } else {
            setFilterComps('Z-A');
            comps = comps.sort((a, b) => (a.Name > b.Name ? -1 : a.Name < b.Name ? 1 : 0));
        }
        setCompanies(comps);
        forceUpdate();
    };
    const handleTitleFilterComps = (e) => {
        let comp = Company
        let compTasks = comp.Tasks;
        let activeSortKey = Sortkey;
        compTasks.forEach((item) => {
            if (item.Archived === true) {
                return
            }
        })
        let id = e.target.id;
        if (id === 'titleSort') {
            activeSortKey.Title = true;
            activeSortKey.Department = false;
            if (TitleFilter === 'Z-A') {
                setTitleFilter('A-Z');
                compTasks.sort((a, b) => a.Title.localeCompare(b.Title));
            } else {
                setTitleFilter('Z-A');
                compTasks.sort((a, b) => b.Title.localeCompare(a.Title));
            };
        };
        if (id === 'departmentSort') {
            activeSortKey.Title = false;
            activeSortKey.Department = true;
            if (DepartmentFilter === 'Z-A') {
                setDepartmentFilter('A-Z');
                compTasks.sort((a, b) => a.Department.localeCompare(b.Department));
            } else {
                setDepartmentFilter('Z-A');
                compTasks.sort((a, b) => b.Department.localeCompare(a.Department));
            };
        };
        setSorKey(activeSortKey);
        comp.Tasks = compTasks;
        setCompany(comp);
        forceUpdate();
    };
    const handleFilterOn = () => {
        setFilterOn(true);
        forceUpdate();
    };
    const handleFilterOff = () => {
        setFilterOn(false);
        forceUpdate();
    };
    const addStatus = (e) => {
        const selectedId = e.target.id;
        if (selectedId === 'Complete') {
            if (FilterStatus.includes('Complete')) {
                setFilterStatus(prevStatus => prevStatus.filter(id => id !== 'Complete' && id !== 'Completed'));
            } else {
                setFilterStatus(prevStatus => [...prevStatus, 'Complete', 'Completed']);
            }
        } else {
            if (!FilterStatus.includes(selectedId)) {
                setFilterStatus(prevStatus => [...prevStatus, selectedId]);
            } else {
                setFilterStatus(prevStatus => prevStatus.filter(id => id !== selectedId));
            }
        }
        forceUpdate();
    }
    const clearFilterArr = () => {
        setFilterStatus([]);
        forceUpdate();
    }
    const openCredits = () => {
        setMonthlyCredits(true);
        forceUpdate();
    }
    const closeCredits = () => {
        setMonthlyCredits(false);
        forceUpdate();
    }
    const addNewTask = () => {
        setAddNewTask(true);
        forceUpdate();
    }
    const closeAddNewTask = () => {
        setAddNewTask(false);
        forceUpdate();
    }
    const changeCompClicked = (e) => {
        let comp = Companies.filter(comp => comp.Name === e.currentTarget.id);
        setSelectedComp(comp[0]);
        forceUpdate();
    }
    function collateralChanged(e, subCategory) {
        let task = TaskInfo;
        setBackground(subCategory);
        if (task.DesignSpec.Platform !== e.currentTarget.id) {
            task.DesignSpec.category = e.currentTarget.id;
            task.DesignSpec.collaterals = subCategory;
        }
    };
    function devCollateralChanged(e, subCategory) {
        let task = TaskInfo;
        setBackground(subCategory);
        if (task.DevSpec.category !== e.currentTarget.id) {
            task.DevSpec.collaterals = e.currentTarget.id;
            task.DevSpec.Type = subCategory
        }
    };
    const submitTask = async () => {
        let task = TaskInfo;
        if (task.Department === 'Dev') {
            if (task.Description === '') {
                window.alert('Please fill in all required fields for the Development task.');
                return
            }
            if (task.DevSpec.Platform === '') {
                window.alert('Please fill in all required fields for the Development task.');
                return
            }
            if (task.DevSpec.Type === '') {
                window.alert('Please fill in all required fields for the Development task.');
                return
            }
            if (task.DevSpec.Outcomes === '') {
                window.alert('Please fill in all required fields for the Development task.');
                return
            }
        } else if (task.Department === 'Design') {
            if (task.Description === '') {
                window.alert('Please fill in all required fields for the Design task.');
                return
            }
            if (task.DesignSpec.category === '') {
                window.alert('Please fill in all required fields for the Design task.');
                return
            }
            if (task.DesignSpec.collaterals === '') {
                window.alert('Please fill in all required fields for the Design task.');
                return
            }
            if (task.DesignSpec.Platform === '') {
                window.alert('Please fill in all required fields for the Design task.');
                return
            }
            if (task.DesignSpec.CopyIfRequired === '') {
                window.alert('Please fill in all required fields for the Design task.');
                return
            }
        }
        task.Status = 'requested';
        task.ClientName = store.user.FirstName;
        task.Client = SelectedComp.Name;
        task.ClientEmail = store.user.Email;
        store.setLoading(true);
        let response = await store.createJob(task);
        if (response === true) {
            window.alert('Task has been submitted.');
            store.setLoading(false);
            setAddNewTask(false);
        } else {
            window.alert('Please try again later.');
            store.setLoading(false);
        }
        setSelectedComp({});
        setTaskInfo({
            ActualDueDate: null,
            Title: "",
            Description: "",
            FeedBack: '',
            Department: "",
            Archived: false,
            Status: 'requested',
            Client: SelectedComp.Name,
            ClientEmail: store.user.Email,
            DesignSpec: {
                designElements: "",
                collaterals: "",
                category: "",
                listDeliverables: "",
                DocLink: '',
                Platform: '',
                Sizes: '',
                CopyIfRequired: '',
                Attachments: ''
            },
            ClientName: '',
            DevSpec: {
                Platform: "",
                Type: "",
                Outcomes: "",
                Figma_Available: ""
            },
            ReqDate: new Date(),
            DueDate: ''
        });
        getCompanies()
        forceUpdate();
    }
    function devPlatformChanged(e) {
        let task = TaskInfo
        if (e.currentTarget.id === 'Other') {
            task.DevSpec.Platform = e.currentTarget.id;
            task.DevSpec.Type = 'Other';
        } else {
            task.DevSpec.Platform = e.currentTarget.id;
            task.DevSpec.Type = '';
        }
        setBackground('');
        setTaskInfo(task);
        forceUpdate();
    };
    function jobRequestChange(e) {
        let job = TaskInfo;
        job.ActualDueDate = null;
        job.FeedBack = "";
        if (e.currentTarget.id === 'taskName') {
            job.Title = e.target.value
        }
        if (e.currentTarget.id === "dueDate") {
            job.DueDate = e.target.value;
        } else {
            job[e.currentTarget.id] = e.target.value;
        }
        if (e.currentTarget.id === "designElements") {
            job.DesignSpec.designElements = e.target.value;
        }
        if (e.currentTarget.id === "Collaterals") {
            job.DesignSpec.collaterals = e.target.value;
        }
        if (e.currentTarget.id === "category") {
            job.DesignSpec.category = e.target.value;
        }
        if (e.currentTarget.id === "listDeliverables") {
            job.DesignSpec.listDeliverables = e.target.value;
        }
        if (e.currentTarget.id === "docLinks") {
            job.DesignSpec.DocLink = e.target.value;
        }
        if (e.currentTarget.id === "Size") {
            job.DesignSpec.Sizes = e.target.value;
        }
        if (e.currentTarget.id === "CopyIfRequired") {
            job.DesignSpec.CopyIfRequired = e.target.value;
        }
        if (e.currentTarget.id === "Attachments") {
            job.DesignSpec.Attachments = e.target.value;
        }
        if (e.currentTarget.id === "Platform") {
            job.DesignSpec.Platform = e.target.value;
        }
        if (e.currentTarget.id === "Platform") {
            job.DevSpec.Platform = e.target.value;
        }
        if (e.currentTarget.id === "Type") {
            job.DevSpec.Type = e.target.value;
        }
        if (e.currentTarget.id === "Outcomes") {
            job.DevSpec.Outcomes = e.target.value;
        }
        if (e.currentTarget.id === "Figma_Available") {
            job.DevSpec.Figma_Available = e.target.value;
        }
        if (e.currentTarget.id === "Figma_Available" || e.currentTarget.id === "Figma_NotAvailable") {
            job.DevSpec.Figma_Available = e.target.value;
        }
        if (e.currentTarget.id === "dueDate") {
            job.DueDate = e.target.value;
        }
        setTaskInfo(job);
        forceUpdate();
    };
    function devFigmaChanged(e) {
        let task = TaskInfo
        task.DevSpec.Figma_Available = e.currentTarget.id;
        setTaskInfo(task);
        forceUpdate();
    };
    function designCategoryChanged(e) {
        let task = TaskInfo
        setBackground('');
        task.DesignSpec.category = e.currentTarget.id;
        task.DesignSpec.collaterals = '';
        setTaskInfo(task);
        forceUpdate();
    };
    function designPlatformChanged(e) {
        let task = TaskInfo
        task.DesignSpec.Platform = e.currentTarget.id;
        setTaskInfo(task);
        forceUpdate();
    };
    const departmentChange = (e) => {
        let task = TaskInfo
        task.Department = e.currentTarget.id;
        setTaskInfo(task);
        forceUpdate();
    }
    const handleTitleVChange = (e) => {
        let task = TaskInfo;
        task.Title = e.target.value;
    };
    const acceptCredsClicked = () => {
        store.setLoading(true);
        let task = SelectedTask;
        task.Status = 'inProgress';
        store.setLoading(true);
        let updateTask = store.clientAcceptCreds(task);
        if (updateTask === 'error') {
            window.alert('There was an error updating this job.');
            store.setLoading(false);
        } else {
            setSelectedTask({});
            window.alert(`Credits Accepted successfully.`);
            store.setLoading(false);
        }
        getCompanies();
        setAddNewTask(false);
        forceUpdate();
    }
    const rejectCredsClicked = () => {
        let task = SelectedTask;
        task.Status = 'Declined peacefully.';
        store.setLoading(true);
        let updateTask = store.clientDeclineCreds(task);
        if (updateTask === 'error') {
            window.alert('There was an error updating this job.');
            setViewTask(false);
            store.setLoading(false);
        } else {
            setSelectedTask({});
            window.alert(`Estimated Credits rejected succefully.`);
            setViewTask(false);
            store.setLoading(false);
        }
        getCompanies();
        setAddNewTask(false);
        forceUpdate();
    }
    if (mobile()) {
        return (
            <div>
            </div>
        );
    } else {
        return (
            <div className='handlerMainPage'>
                {(ViewArchived) ?
                    <div className="archivedTasksContainer">
                        <div className="archiveHeader">
                            <img src={CloseArchive} alt='' style={{ cursor: 'pointer' }} onClick={closeArchive} />
                            <p className='companyNameArchs'>{Company.Name} Archives</p>
                        </div>
                        <div className="archiveTerms">
                            <p className='titleValue'>Title</p>
                            <p className='departmentValue'>Department</p>
                            <p className='deliveryDateValue'>Delivery Date</p>
                        </div>
                        <div className="archivedTasks">
                            {Tasks.map((task, index) => {
                                if (task.Client !== Company.Name || !task.Archived) {
                                    return null;
                                }
                                let formattedDate;
                                if (task.ActualDueDate) {
                                    formattedDate = new Date(task.ActualDueDate).toISOString().split('T')[0];
                                }

                                return (
                                    <Card className='archievedTasks'>
                                        <b className='taskTitleValue'>{task.Title.length > 20 ? task.Title.substring(0, 20) + '...' : task.Title}</b>
                                        <p className='taskDepartmentValue'>{task.Department}</p>
                                        <b className='taskDateValue'>{formattedDate || 'No Date!'}</b>
                                        <div className='unArchiveSection'>
                                            <UnArchiveHover onMouseEnter={handleHoverStart} onMouseLeave={handleHoverEnd} Active={IsHovered} onClick={() => handleUnacrchiveTask(index)} />
                                        </div>
                                    </Card>
                                );
                            })}
                        </div>
                    </div>
                    :
                    <>
                        <h2 style={{ fontSize: '25px', fontWeight: 700, marginLeft: '1%', display: 'flex', color: '#213038', cursor: 'pointer', width: '20%' }} onClick={handleFilterComps}>
                            {(FilterComps === '') ? null : (
                                <img src={FilterComps === 'A-Z' ? FilterDown : Filter} style={{ marginRight: '0.5%' }} />
                            )}
                            Company Name</h2>
                        {store.user.Role === 'Admin' ?
                            (
                                <div className='admin-add-task' onClick={addNewTask}>
                                    Add Task
                                </div>
                            ) : null}
                        <div>

                        </div>
                        {Companies.map((item, index) => {
                            return (
                                <div className='availableCompanies' key={index}>
                                    <div className='companyCredsDetails'>
                                        <p>{item.Name}</p>
                                        <div className='compAvailableCreds'>
                                            <div className='credsDIv'>
                                                <p>Dev</p>
                                                <div className='availableCredsValue'>{item.RemainingCredits.Dev}</div>
                                            </div>
                                            <div className='credsDIv'>
                                                <p>Design</p>
                                                <div className='availableCredsValue'>{item.RemainingCredits.Design}</div>
                                            </div>
                                            <img src={ViewDetails} key={index} style={{ cursor: 'pointer' }} onClick={IsOpened === 'No' ? () => viewComp(index) : () => closeViewComp(index)} />
                                        </div>
                                    </div>
                                    {(ViewCompanyTasks && SelectedIndex === index) ?
                                        <div className='compTaskDetails' style={{ zIndex: '5%' }}>
                                            <div className='titlesSection'>
                                                <div className='taskTitle'>
                                                    <p id='titleSort' onClick={handleTitleFilterComps}>
                                                        {Sortkey.Title ? <img id='titleSort' src={TitleFilter === 'A-Z' ? FilterDown : Filter} style={{ marginRight: '0.5%' }} onClick={handleTitleFilterComps} /> : null}
                                                        Title
                                                    </p>
                                                </div>
                                                <div className='taskDepartment'>
                                                    <p id='departmentSort' onClick={handleTitleFilterComps}>
                                                        {Sortkey.Department ? <img id='departmentSort' src={DepartmentFilter === 'A-Z' ? FilterDown : Filter} style={{ marginRight: '0.5%' }} onClick={handleTitleFilterComps} /> : null}
                                                        Department
                                                    </p>
                                                </div>
                                                <div className='taskDeliveryDate'>
                                                    <div>
                                                        <p className='label'>Delivery Date</p>
                                                    </div>
                                                </div>
                                                <div className='taskStatus' onMouseEnter={handleFilterOn} onMouseLeave={handleFilterOff}><p>Status</p><HiMagnifyingGlass />
                                                    {(FilterOn) ?
                                                        <div className='filterContent'>
                                                            <div className='filterPointer'></div>
                                                            <div className='filterContainer'>
                                                                <option className='filterOptions' id='requested' onClick={addStatus} style={{ backgroundColor: FilterStatus.includes('requested') ? '#EFB86F' : '' }}>Requested</option>
                                                                <option className='filterOptions' id='pending' onClick={addStatus} style={{ backgroundColor: FilterStatus.includes('pending') ? '#EFB86F' : '' }}>Pending</option>
                                                                <option className='filterOptions' id='inProgress' onClick={addStatus} style={{ backgroundColor: FilterStatus.includes('inProgress') ? '#EFB86F' : '' }}>In Progress</option>
                                                                <option className='filterOptions' id='Complete' onClick={addStatus} style={{ backgroundColor: FilterStatus.includes('Complete') ? '#EFB86F' : '' }}>Completed</option>
                                                                <option className='filterOptions' id='Declined peacefully.' onClick={addStatus} style={{ backgroundColor: FilterStatus.includes('Declined peacefully.') ? '#EFB86F' : '' }}>Declined</option>
                                                                <p className='clearFilter' onClick={clearFilterArr}>Clear Filters</p>
                                                            </div>
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className='availableCompTasksSection'>
                                                {CompanyTasks.length === 0 && (
                                                    <card className='compTaskCard' key={-1}>
                                                        <div className='Title'><p>No Tasks available for this company</p></div>
                                                    </card>
                                                )}
                                                {CompanyTasks.map((task, index) => {
                                                    if (FilterStatus.length !== 0 && !FilterStatus.includes(task.Status)) {
                                                        return
                                                    }
                                                    let statusLabel;
                                                    if (task.Status === 'requested') {
                                                        statusLabel = 'Requested';
                                                    } else if (task.Status === 'inProgress') {
                                                        statusLabel = 'In Progress';
                                                    } else if (task.Status === 'Complete' || task.Status === 'Completed') {
                                                        statusLabel = 'Completed';
                                                    } else if (task.Status === 'pending') {
                                                        statusLabel = 'Pending';
                                                    } else if (task.Status === 'Declined peacefully.') {
                                                        statusLabel = 'Declined';
                                                    } else if (task.Status === 'Abandon') {
                                                        statusLabel = 'Abandoned';
                                                    } else if (task.Status === 'Abandoned') {
                                                        statusLabel = 'Abandoned';
                                                    }
                                                    let formattedDate = '';
                                                    if (task.ActualDueDate) {
                                                        formattedDate = new Date(task.ActualDueDate).toISOString().split('T')[0];
                                                    }
                                                    return (
                                                        <div className='compTaskCard' key={index} id={index} onClick={() => viewTaskClicked(index)}>
                                                            <p className='Title'>{task.Title.length > 17 ? task.Title.substring(0, 17) + '...' : task.Title}</p>
                                                            <p className='Department'>{task.Department}</p>
                                                            <p className='DeliveryDate'>{formattedDate || 'No Date!'}</p>
                                                            <div className='Status'>
                                                                <p>{statusLabel}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                <button className='viewArchivesBtn' onClick={viewArchivedClicked}><b className='btnText'>View Archive</b></button>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                            )
                        })}
                    </>}
                {(ViewTask) ?
                    <div className='ViewSelectedCard'>
                        <img className='closeTask' src={CloseCard} alt='' onClick={closeTaskClicked} />
                        <div className='selectedTaskCompName'>
                            <p>{Company.Name}</p>
                            <div className='selectedCompCreds'>
                                <p style={{ marginLeft: '3%' }}>Remaining {SelectedTask.Department} Credits</p>
                                <div className='selectedCredsValue'>{Company.RemainingCredits[SelectedTask.Department]}</div>
                            </div>
                        </div>
                        <div className='selectedTaskDetails'>
                            <div className='taskNameDetails'>
                                <Textbox className='takAvailDetails' placeholder='Task Name' value={SelectedTask.Title} readOnly></Textbox>
                                <Textbox className='takAvailDetails' placeholder='Date Created' value={new Date(SelectedTask.createdAt).toLocaleDateString('en-US')} readOnly></Textbox>
                                <Textbox className='takAvailDetails' placeholder='Requested By' value={SelectedTask.ClientEmail} readOnly></Textbox>
                            </div>
                            <div className='dateDetails'>
                                <Textbox className='platformDetails' placeholder='Requested Date' value={new Date(SelectedTask.DueDate).toLocaleDateString('en-US')} readOnly></Textbox>
                                <Textbox className='platformDetails' placeholder='Category' value={SelectedTask.Department === 'Dev' ? SelectedTask.DevSpec.Type : SelectedTask.Department === 'Design' ? SelectedTask.DesignSpec.category : ''} readOnly></Textbox>
                                <Textbox className='platformDetails' placeholder='Platform' value={SelectedTask.Department === 'Dev' ? SelectedTask.DevSpec.Platform : SelectedTask.Department === 'Design' ? SelectedTask.DesignSpec.Platform : ''} readOnly />
                            </div>
                        </div>
                        <hr className='hr' />
                        <div className='selectedTaskDescription'>
                            <div className='selectedTaskDescriptionSection'>
                                <p className='titleLabel'>Description</p>
                                <p className='titlleValue'>{SelectedTask.Description}</p>
                                {(SelectedTask.Department === 'Design') ?
                                    <>
                                        <p className='titleLabel'>Collateral</p>
                                        <p className='titlleValue'>{SelectedTask.DesignSpec.Collateral}</p>
                                        <p className='titleLabel'>Size/Dimension</p>
                                        <p className='titlleValue'>{SelectedTask.DesignSpec.Sizes}</p>
                                        <p className='titleLabel'>Text/Copy</p>
                                        <p className='titlleValue'>{SelectedTask.DesignSpec.CopyIfRequired}</p>
                                        <p className='titleLabel'>Attachments</p>
                                        <p className='titlleValue'>{SelectedTask.DesignSpec.Attachments}</p>
                                    </> : (SelectedTask.Department === 'Dev' ?
                                        <>
                                            <p className='titleLabel'>What should this project achieve?</p>
                                            <p className='titlleValue'>{SelectedTask.DevSpec.Outcomes}</p>
                                            <p className='titleLabel'>Is there a design Available?</p>
                                            <p className='titlleValue'>{SelectedTask.DevSpec.Figma_Available}</p>
                                        </>
                                        : null)}
                            </div>
                            <div className='selectedTAskActionsSection'>
                                <div className='creditsAvailableSecti'>
                                    <div className='creditsBreakDown'>
                                        <p style={{ marginLeft: '3%' }}>Credits</p>
                                        <div className='monthlyCredsSection' onMouseEnter={openCredits} onMouseLeave={closeCredits}>
                                            <p>{TotCreds}</p>
                                            {MonthlyCredits && (
                                                <>
                                                    <div className='monthlyCredsPointer'></div>
                                                    <div className='monthlyCredtsContainer'>
                                                        <div className='monthlyCredsHeader'>
                                                            <p>Month</p>
                                                            <p>Credits</p>
                                                        </div>
                                                        <hr className='hr' style={{ marginTop: '0%', marginBottom: '0%', width: '95%' }}></hr>
                                                        <div className='allocateCredsSection'>
                                                            {SelectedTask.MonthlyCredits && Object.keys(SelectedTask.MonthlyCredits).length > 0 ? (
                                                                Object.entries(SelectedTask.MonthlyCredits)
                                                                    .sort((a, b) => {
                                                                        const monthA = new Date(Date.parse(a[0] + ' 1, 2020')).getMonth();
                                                                        const yearA = parseInt(a[0].match(/\d+/)[0]);
                                                                        const monthB = new Date(Date.parse(b[0] + ' 1, 2020')).getMonth();
                                                                        const yearB = parseInt(b[0].match(/\d+/)[0]);
                                                                        if (yearA !== yearB) {
                                                                            return yearA - yearB;
                                                                        } else {
                                                                            return monthA - monthB;
                                                                        }
                                                                    })
                                                                    .map(([date, credits]) => (
                                                                        <div key={date} className='availableMonthlyCreds'>
                                                                            <p>{new Date(date).toLocaleString('en-US', { month: 'long' })}</p>
                                                                            <p>{credits}</p>
                                                                        </div>
                                                                    ))
                                                            ) : (
                                                                <p>No monthly credits available.</p>
                                                            )}
                                                        </div>
                                                        <hr className='hr' style={{ marginTop: '0%', marginBottom: '0%', width: '95%' }}></hr>
                                                        <div className='totalSection'>
                                                            <p>Total</p>
                                                            <p>{TotCreds}</p>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className='deliveryDateSect'>
                                        <p style={{ marginLeft: '3%' }}>Delivery Date</p>
                                        <div className='dateValue'>{new Date(SelectedTask.ActualDueDate).toLocaleDateString('en-US')}</div>
                                    </div>
                                    <div className='statusSect'>
                                        <p style={{ marginLeft: '3%' }}>Status</p>
                                        <div className='statusValue'>
                                            {
                                                SelectedTask.Status === 'requested' ? 'Requested' :
                                                    SelectedTask.Status === 'inProgress' ? 'In Progress' :
                                                        SelectedTask.Status === 'Complete' || SelectedTask.Status === 'Completed' ? 'Completed' :
                                                            SelectedTask.Status === 'pending' ? 'Pending' :
                                                                SelectedTask.Status === 'Declined peacefully.' ? 'Declined' :
                                                                    SelectedTask.Status
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='adminAcceptRejectSect'>
                                    {(store.user.Role === 'Admin' && SelectedTask.Status === 'pending') ?
                                        <>
                                            <button className='acceptCredsbtn' onClick={acceptCredsClicked}>Accept</button>
                                            <button className='rejectCredsbtn' onClick={rejectCredsClicked}>Reject</button>
                                        </>
                                        : null}
                                </div>
                                <div className='archiveBtnSect'>
                                    {(SelectedTask.Status === 'Complete' || SelectedTask.Status === 'Completed') ?
                                        <button className='archBtn' onClick={archiveTask}><p className='btnValue'>Archive</p></button>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}
                {(AddNewTask) ?
                    <div className='admin-newTask'>
                        <div className='company-credits-section'>
                            <CustomSelect value={SelectedComp.Name || 'Select a company'} placeholder='Company*'>
                                {Companies.map((comp, index) => {
                                    return (
                                        <div key={index} id={comp.Name} onClick={changeCompClicked} className={`${(SelectedComp.Name === comp.Name) ? 'selected-comp-active' : 'select-Comp-section'}`}>
                                            <p>{comp.Name}</p>
                                        </div>
                                    )
                                })}
                            </CustomSelect>
                            {Object.keys(SelectedComp).length > 0 ? (
                                <div className='selected-comp-Creds'>
                                    <p>Remaining Credits</p>
                                    <div className='avail-Cred-container'>
                                        <p>Dev</p>
                                        <div className='dev-Rem-creds'>{SelectedComp.RemainingCredits.Dev || 0}</div>
                                        <p>Design</p>
                                        <div className='design-rem-creds'>{SelectedComp.RemainingCredits.Design || 0}</div>
                                    </div>
                                </div>
                            ) : null}
                            <img className='admin-closeTask' style={{ cursor: 'pointer' }} src={CloseCard} alt='' onClick={closeAddNewTask} />
                        </div>
                        <div className='task-name-date-section'>
                            <Textbox style={{ width: '50%' }} placeholder='Task Name*' id='taskName' value={TaskInfo.Title} onChange={jobRequestChange}></Textbox>
                            <CustomSelect style={{ marginTop: 'unset', marginLeft: '0%' }} placeholder='Deparment*' value={TaskInfo.Department}>
                                <div className={`${(TaskInfo.Department === 'Dev') ? 'selected-comp-active' : 'select-Comp-section'}`} id='Dev' onClick={departmentChange}><p>Dev</p></div>
                                <div className={`${(TaskInfo.Department === 'Design') ? 'selected-comp-active' : 'select-Comp-section'}`} id='Design' onClick={departmentChange}><p>Design</p></div>
                            </CustomSelect>
                            <Textbox style={{ width: '25%', marginRight: 'unset', justifyContent: 'center' }} type='date' placeholder='Requested Due Date*' id="dueDate" onChange={jobRequestChange}></Textbox>
                        </div>
                        <hr className='hr' />
                        <div className='department-metrics'>
                            <div className='department-collaterals'>
                                {(TaskInfo.Department === 'Dev') ?
                                    <>
                                        <div className='taskCategorySection'>
                                            <CustomSelect className='plartformSelects' placeholder='Platform *' onMouseEnter={handleHoverStart} onMouseLeave={handleHoverEnd} Active={true} id="Platform" value={TaskInfo.DevSpec.Platform} onChange={jobRequestChange}>
                                                <div onClick={devPlatformChanged} id="App" className={`${(TaskInfo.DevSpec.Platform === 'App') ? 'selectActive' : 'customOptions'}`}>App</div>
                                                <div onClick={devPlatformChanged} id="Website" className={`${(TaskInfo.DevSpec.Platform === 'Website') ? 'selectActive' : 'customOptions'}`}>Website</div>
                                                <div onClick={devPlatformChanged} id="Other" className={`${(TaskInfo.DevSpec.Platform === 'Other') ? 'selectActive' : 'customOptions'}`}>Other</div>
                                            </CustomSelect>
                                            <CustomSelect className='plartformSelects' placeholder='Design Available?*' id='Figma_Available' onChange={jobRequestChange} value={TaskInfo.DevSpec.Figma_Available}>
                                                <div id='Yes' onClick={devFigmaChanged} className={`${(TaskInfo.DevSpec.Figma_Available === 'Yes') ? 'selectActive' : 'customOptions'}`}>Yes</div>
                                                <div id='No' onClick={devFigmaChanged} className={`${(TaskInfo.DevSpec.Figma_Available === 'No') ? 'selectActive' : 'customOptions'}`}>No</div>
                                            </CustomSelect>
                                        </div>
                                        <div className='taskDescSection'>
                                            <ResizableTextarea className='descriptionTextB' placeholder='Description' id='Description' onChange={jobRequestChange} />
                                            <ResizableTextarea className='projectObjectiveText' placeholder='What should this project achieve?' id="Outcomes" onChange={jobRequestChange} />
                                            {(TaskInfo.Title !== '' && TaskInfo.ActualDueDate !== '' && TaskInfo.Description !== '' && TaskInfo.DevSpec.Platform !== '' && TaskInfo.DevSpec.Type !== '' && TaskInfo.DevSpec.Figma_Available !== '' && TaskInfo.DevSpec.CopyIfRequired !== '' && TaskInfo.DevSpec.Outcomes !== '') ? (
                                                <button className='requirementsMetBtn' onClick={submitTask}><b>Submit</b></button>
                                            ) : (
                                                <button className='submitTask'><b>Submit</b></button>
                                            )}
                                            <p style={{ fontSize: '20px', color: '#213038', fontWeight: '300', margin: 'unset' }}>Fields marked with * are required.</p>
                                        </div>
                                    </> : (TaskInfo.Department === 'Design') ?
                                        <>
                                            <div className='taskCategorySection'>
                                                <CustomSelect className='plartformSelects' placeholder='Category *' id='category' onChange={jobRequestChange} value={TaskInfo.DesignSpec.category}>
                                                    <div onClick={designCategoryChanged} className={`${(TaskInfo.DesignSpec.category === 'StillGraphics') ? 'selectActive' : 'customOptions'}`} id="StillGraphics">Still Graphics</div>
                                                    <div onClick={designCategoryChanged} className={`${(TaskInfo.DesignSpec.category === 'WebDesign') ? 'selectActive' : 'customOptions'}`} id="WebDesign">Web Design</div>
                                                    <div onClick={designCategoryChanged} className={`${(TaskInfo.DesignSpec.category === 'Video/Animation') ? 'selectActive' : 'customOptions'}`} id="Video/Animation">Video/ Animation</div>
                                                    <div onClick={designCategoryChanged} className={`${(TaskInfo.DesignSpec.category === 'Photo') ? 'selectActive' : 'customOptions'}`} id="Photo">Photo</div>
                                                    <div onClick={designCategoryChanged} className={`${(TaskInfo.DesignSpec.category === 'Written') ? 'selectActive' : 'customOptions'}`} id="Written">Written</div>
                                                </CustomSelect>
                                                <CustomSelect className='plartformSelects' placeholder='Platform *' id='Platform' onChange={jobRequestChange} value={TaskInfo.DesignSpec.Platform}>
                                                    <div onClick={designPlatformChanged} className={`${(TaskInfo.DesignSpec.Platform === 'SocialMediaPost') ? 'selectActive' : 'customOptions'}`} id="SocialMediaPost">Social Media Post</div>
                                                    <div onClick={designPlatformChanged} className={`${(TaskInfo.DesignSpec.Platform === 'SocialMediaStory') ? 'selectActive' : 'customOptions'}`} id="SocialMediaStory">Social Media Story</div>
                                                    <div onClick={designPlatformChanged} className={`${(TaskInfo.DesignSpec.Platform === 'Website') ? 'selectActive' : 'customOptions'}`} id="Website">Website</div>
                                                    <div onClick={designPlatformChanged} className={`${(TaskInfo.DesignSpec.Platform === 'DigitalPDF') ? 'selectActive' : 'customOptions'}`} id="DigitalPDF">Digital PDF</div>
                                                    <div onClick={designPlatformChanged} className={`${(TaskInfo.DesignSpec.Platform === 'PrintPDF') ? 'selectActive' : 'customOptions'}`} id="PrintPDF">Print PDF</div>
                                                    <div onClick={designPlatformChanged} className={`${(TaskInfo.DesignSpec.Platform === 'Youtube') ? 'selectActive' : 'customOptions'}`} id="Youtube">Youtube</div>
                                                    <div onClick={designPlatformChanged} className={`${(TaskInfo.DesignSpec.Platform === 'GoogleCampaigns') ? 'selectActive' : 'customOptions'}`} id="GoogleCampaigns">Google Campaigns</div>
                                                    <div onClick={designPlatformChanged} className={`${(TaskInfo.DesignSpec.Platform === 'LiveEvents') ? 'selectActive' : 'customOptions'}`} id="LiveEvents">LiveEvents</div>
                                                    <div onClick={designPlatformChanged} className={`${(TaskInfo.DesignSpec.Platform === 'Other') ? 'selectActive' : 'customOptions'}`} id="Other">Other</div>
                                                </CustomSelect>
                                            </div>
                                            <div className='taskDescSection'>
                                                <ResizableTextarea className='descriptionTextB' placeholder='Description' id='Description' onChange={jobRequestChange} />
                                                <ResizableTextarea className='descriptionTextB' placeholder='Size/ Dimensions' id='Size' onChange={jobRequestChange} />
                                                <ResizableTextarea className='projectObjectiveText' style={{ marginTop: 'unset' }} placeholder='Text/ Copy' id='CopyIfRequired' onChange={jobRequestChange} />
                                                <ResizableTextarea className='descriptionTextB' placeholder='Attach drive link' id='Attachments' onChange={jobRequestChange} />
                                                {(TaskInfo.Title !== '' && TaskInfo.ActualDueDate !== '' && TaskInfo.DesignSpec.category !== '' && TaskInfo.DesignSpec.collaterals !== '' && TaskInfo.DesignSpec.Platform !== '') ? (
                                                    <button className='requirementsMetBtn' onClick={submitTask}><b>Submit</b></button>
                                                ) : (
                                                    <button className='submitTask'><b>Submit</b></button>
                                                )}
                                                <p style={{ fontSize: '20px', color: '#213038', fontWeight: '300', margin: 'unset' }}>Fields marked with * are required.</p>
                                            </div>
                                        </>
                                        : null}
                            </div>
                            <div className='collateral-details'>
                                {(TaskInfo.Department !== '') ?
                                    <>
                                        <div className='categoryHeader'>
                                            <p>Select a {TaskInfo.Department} Category Detail</p>
                                        </div>
                                        <div className='collateralDet'>
                                            {Categories.map((item, index) => {
                                                if (item.Department !== TaskInfo.Department) {
                                                    return
                                                }
                                                if (item.Department === 'Design') {
                                                    if (TaskInfo.DesignSpec.category !== '' && item.Category !== TaskInfo.DesignSpec.category) {
                                                        return
                                                    } else {
                                                        return (
                                                            <Card key={index} className='colateralCards' id={item.Category.replace(/\s+/g, '')} onClick={(e) => collateralChanged(e, item.subCategory)} style={{ backgroundColor: item.subCategory === Background ? '#EFB86F' : '#EAEAEA', cursor: 'pointer' }} >
                                                                <h3 className='header'>{item.subCategory}</h3>
                                                                <img src={item.Image} alt='' />
                                                                <p>{item.Description}</p>
                                                            </Card>
                                                        )
                                                    }
                                                } else if (item.Department === 'Dev') {
                                                    if (TaskInfo.DevSpec.Platform !== '' && item.Category !== TaskInfo.DevSpec.Platform) {
                                                        return
                                                    } else {
                                                        return (
                                                            <Card key={index} className='colateralCards' id={item.Category.replace(/\s+/g, '')} onClick={(e) => devCollateralChanged(e, item.subCategory)} style={{ backgroundColor: item.subCategory === Background ? '#EFB86F' : '#EAEAEA' }}>
                                                                <h3 className='header'>{item.subCategory}</h3>
                                                                <img src={item.Image} alt='' />
                                                                <p>{item.Description}</p>
                                                            </Card>
                                                        )
                                                    }
                                                }

                                            })}
                                        </div>
                                    </>
                                    : null}
                            </div>
                        </div>

                    </div> : null}
            </div>
        );
    }
});

export default AlkemiaBriefing;
