import React from "react";
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineController, LineElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineController, LineElement, ChartDataLabels);

const OpexPie = ({ Aions = [], id }) => {
    const generateRandomColor = () => {
        const randomColor = () => Math.floor(Math.random() * 256);
        return `rgba(${randomColor()}, ${randomColor()}, ${randomColor()}, 0.7)`;
    };
    const aionsData = Aions;
    let currMonthData = aionsData.CurrentMonth || [];
    let PrevMonthData = aionsData.PreviousMonth || [];
    let currOpexData = [];
    let prevOpexData = [];
    let tempCurrMonthData = [];
    let tmpPrevIncomeData = [];

    currMonthData.forEach((item) => {
        let Opex = item.Opex.Aions || [];
        tempCurrMonthData.push(...Opex);
    });
    let CurrLabel = currMonthData[0]?.Label || '';

    PrevMonthData.forEach((item) => {
        let Opex = item.Opex.Aions || [];

        tmpPrevIncomeData.push(...Opex);
    });
    let PrevLabel = PrevMonthData[0]?.Label || '';

    const categoryMap = new Map();
    for (let i = 0; i < tempCurrMonthData.length; i++) {
        const currentItem = tempCurrMonthData[i];
        const existingItem = categoryMap.get(currentItem.Category);

        if (existingItem) {
            existingItem.Amount += parseFloat(currentItem.Amount);
        } else {
            categoryMap.set(currentItem.Category, { ...currentItem, Amount: parseFloat(currentItem.Amount) });
        }
    }
    currOpexData.push(...categoryMap.values());
    const prevOpexMap = new Map();
    for (let i = 0; i < tmpPrevIncomeData.length; i++) {
        const currentItem = tmpPrevIncomeData[i];
        const existingItem = prevOpexMap.get(currentItem.Category);

        if (existingItem) {
            existingItem.Amount += parseFloat(currentItem.Amount);
        } else {
            prevOpexMap.set(currentItem.Category, { ...currentItem, Amount: parseFloat(currentItem.Amount) });
        }
    }
    prevOpexData.push(...prevOpexMap.values());

    const getRandomColors = (length) => Array.from({ length }, () => generateRandomColor());
    const data = {
        labels: currOpexData.map(item => `${item.Category}: ${item.Amount}`),
        datasets: [
            {
                data: currOpexData.map(item => item.Amount),
                backgroundColor: getRandomColors(currOpexData.length),
            },
        ],
    };
    const filteredPrevIncomeData = prevOpexData.filter(item => item.Amount !== 0);
    const charData = {
        labels: filteredPrevIncomeData.map(item => `${item.Category}: R ${item.Amount}`),
        datasets: [
            {
                data: filteredPrevIncomeData.map(item => item.Amount),
                backgroundColor: getRandomColors(filteredPrevIncomeData.length)
            },

        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
                position: 'bottom',
            },
            title: {
                display: true,
                text: `${CurrLabel} Expenses`,
                font: {
                    weight: 'bold',
                    size: 20,
                },
            },
            datalabels: {
                display: 'auto',
                color: 'black',
                font: {
                    weight: 'bold',
                },
                formatter: (value) => `R ${Number(value).toLocaleString()}`
            },
        },
    };
    const PrevOptions = {
        plugins: {
            legend: {
                position: 'right',
            },
            title: {
                display: true,
                text: `${PrevLabel} Expenses`,
                font: {
                    weight: 'bold',
                    size: 25,
                },
            },
            datalabels: {
                display: 'auto',
                color: 'black',
                font: {
                    weight: 'bold',
                },
                formatter: (value) => value > 0 ? `R ${Number(value).toLocaleString()}` : '',
            },
        },
    };
    return (
        <div >
            {/* <Pie data={charData} options={PrevOptions} />
            <Pie data={data} options={options} id={id} /> */}
            <Pie style={{ width: "800px", height: "800px" }} data={charData} options={PrevOptions} id={id} />
        </div>

    );
};

export default OpexPie;
