import React, { useEffect, useState, useRef } from "react";
import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer";
import AionsIcon from '../../assets/images/Aions.png';
import AionsSlide1 from './AionsSlide1.png'
import AionsSlide3 from './AionsSlide3.png'
import d3ToPng from 'd3-svg-to-png';
const styles = StyleSheet.create({
    body: {
        color: '#000000'
    },
    title: {
        fontSize: 25,
        paddingTop: 10,
        paddingBottom: 10
    },
    subTitle: {
        fontSize: 20,
        paddingTop: 10,
        paddingBottom: 10
    },
    paragraph: {
        fontSize: 16,
        paddingTop: 10,
        paddingBottom: 10
    },
});

const ReportExport = ({ }) => {
    const [chartUrl, setChartUrl] = useState(null);
    useEffect(() => {
        exportToPng();
    }, [chartUrl]);

    const exportToPng = () => {
        const chartSvg = document.getElementById('AIONSIncomeChart');
        if (chartSvg) {
            d3ToPng(chartSvg, "chart.png", {
                scale: 3,
                format: 'webp',
                quality: 0.01,
                download: false,
                ignore: '.ignored',
                background: 'white'
            }).then(imageUrl => {
                setChartUrl(imageUrl);
            }).catch(error => {
                console.error('Error converting SVG to PNG:', error);
            });
        }
    };
    return (
        <Document>
            <Page style={styles.body} orientation="landscape">
                <View>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', padding: '35px', zIndex: '2' }}>
                        <Image src={AionsIcon} style={{ width: '50%' }} />
                        <Text style={styles.title}>Sales Report</Text>
                        <Text style={styles.subTitle}>{`Start Date -  End Date`}</Text>
                    </div>
                    <Image src={AionsSlide1} style={{ position: 'absolute', width: '100%', height: '100%', zIndex: '1' }} />
                </View>
            </Page>
            <Page style={styles.body} orientation="landscape">
                <View>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', height: '100%', padding: '35px', zIndex: '2' }}>
                        <Text style={styles.title}>Aions Income and Expenses</Text>
                        {chartUrl && <Image src={chartUrl} alt="Chart" />}
                        <Text style={styles.paragraph}>No Comment</Text>
                    </div>
                    <Image src={AionsSlide3} style={{ position: 'absolute', width: '100%', height: '100%', zIndex: '1' }} />
                </View>
            </Page>
        </Document>
    );
};

export default ReportExport;
