import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from "../../../stores/Store";
import { Navigate, useNavigate } from 'react-router-dom';
import { FaShareSquare } from 'react-icons/fa'
import { Card, Input, View, Button, MobileTabBarItem, MobileTabBar } from '../../../components';
import isMobile from 'is-mobile';


const CompCourse = observer(() => {
    //#region UseSates
    const store = useStore();
    const navigate = useNavigate();
    const [, updateState] = useState();
    const [Courses, setCourses] = useState([]);
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [selectedCourseId, setSelectedCourseId] = useState('');
    const [Course, setCourse] = useState({})

    useEffect(() => {
        getCourseId();
        getAllCourses();
        forceUpdate();
    }, []);

    function getAllCourses() {
        store.setLoading(true);
        store.getCourse().then((ret) => {
            setCourses(ret);
            store.setLoading(false);
        });
        forceUpdate();
    }
    function viewCourses(courseId) {
        let id = courseId
        navigate(`/viewCourse/${id}`);
    }

    function getCourseId() {
        let id = selectedCourseId
        store.setLoading(true);
        store.getCoursesByID(id).then((ret) => {
            setCourse(ret);

            store.setLoading(false);
        });
        forceUpdate();
    }



    if (isMobile()) {
        return (
            <div>
                <div>
                    <h1>Courses</h1>
                    <Card className='newCourseMapMainCard'>
                        {(Courses) ? <>
                            {Courses.map((course, index) => {
                                return (
                                    <Card className='newCourseMapInnerCard' key={course._id}>
                                        <div className='descriptionCardLbalel'><p>{course.Name}</p></div>
                                        <Button className="ViewCourse" onClick={() => viewCourses(course._id)}>View</Button>
                                    </Card>
                                )
                            })}
                        </> : <>
                            <Card><h1>NO AVAILABLE DATA ...!</h1></Card>
                        </>}
                    </Card>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <div>
                    <h1>Courses</h1>
                    <Card className='newCourseMapMainCard'>
                        {(Courses) ? <>
                            {Courses.map((course, index) => {
                                return (
                                    <Card className='newCourseMapInnerCard' key={course._id}>
                                        <div className='descriptionCardLbalel'><p>{course.Name}</p></div>
                                        <Button className="ViewCourse" onClick={() => viewCourses(course._id)}>View</Button>
                                    </Card>
                                )
                            })}
                        </> : <>
                            <Card><h1>NO AVAILABLE DATA ...!</h1></Card>
                        </>}
                    </Card>
                </div>
            </div>
        )
    }
})

export default CompCourse;