import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { useNavigate } from 'react-router-dom';
import './style.css';
import dashLogo from '../../assets/images/dashLogo.png';
import Todo from '../../assets/images/todo.png';
import InProgress from '../../assets/images/In_Progress@2x.png';
import Completed from '../../assets/images/checked.png';
import Plus from '../../assets/images/plus.png';
import Logo from '../../assets/images/levelLogo.png';
import BackIcon from '../../assets/images/left.png';
import ReportsLogo from '../../assets/images/document.png';
import LogoutIcon from '../../assets/images/power.png';
import Dashboard from './Dashboard/Dashboard';
import UserIcon from '../../assets/images/user.png'
import Users from './Users/Users';
import AionsGlobe from '../../assets/images/Aionsglobe.png';
import Globe from '../../assets/images/globe 32x32px.png';
import General from './General/General';
import Aions from './Aions/Aions';
import UsersIcons from '../../assets/images/group.png';
import Company from './Company/Company';
import Reports from './Reports/Reports';
import ClientsIcon from '../../assets/images/collaboration.png'
import HomeIcon from '../../assets/images/icon.png';
import CompaniesIcon from '../../assets/images/employees.png';
import ClientInfoIcon from '../../assets/images/businessman.png';
import QuotesIcon from '../../assets/images/estimate.png';
import SLAIcon from '../../assets/images/sla.png';
import OKRSessions from './OKRSessions/OKRSessions';
import Companies from './Companies/Companies';
import Alkemia from './Alkemia/Alkemia';
import Clients from './Clients/Clients';
import ClientsInfo from './Clients/ClientInfo';
import Quotes from './Clients/Quotes';
import SLAs from './Clients/SLAs';
import { Input, View, Button, Card, DoughnutChart } from '../../components';
var mobile = require('is-mobile');

const DepartmentDashboard = observer(() => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [CurrentPage, setCurrentPage] = useState('Dashboard');
    const [Page, setPage] = useState('');
    const [User, setUser] = useState({});
    const [CompaniesList, setCompaniesList] = useState([]);
    const [RemovedCrds, setRemovedCrds] = useState(0);
    const [SelectedCompanyId, setSelectedCompanyId] = useState('');
    const [ClientID, setClientID] = useState('');
    const [SelectedClient, setSelectedClient] = useState({});
    const [SelectedCompany, setSelectedCompany] = useState({});
    const [AionsId, setAionsId] = useState('')
    useEffect(() => {
        loadCompanies();

    }, []);
    function loadCompanies() {
        store.setLoading(true);
        store.getUserByEmail(store.user.Email).then((ret) => {
            if (typeof ret === "string" || !ret) {
                setUser({});
                store.setLoading(false);
                return;
            }
            setUser(ret);
            store.setLoading();
        })
        store.getCompanies().then((ret) => {
            if (typeof ret === "string" || !ret) {
                setCompaniesList({});
                store.setLoading(false);
                return;
            }
            const aionsCompany = ret.find((company) => company.Name.includes("Aions"));
            if (aionsCompany) {
                setAionsId(aionsCompany._id);
            } else {
                setAionsId(null);
            }
            const filteredCompanies = ret.filter((company) => !company.Name.includes("Aions"));
            setCompaniesList(filteredCompanies)
            forceUpdate();
            store.setLoading(false);
        });
    }


    function companySelected(e) {
        let id = ''
        if (e.currentTarget.id === '') {
            return
        }
        else {
            id = e.currentTarget.id
        }
        setSelectedCompany(CompaniesList[Number(id)]);
        setSelectedCompanyId(CompaniesList[Number(id)]._id)
        setCurrentPage('Company');
        forceUpdate()
    }
    function navClicked(e) {
        setCurrentPage(e.currentTarget.id);
    }
    function clientNavClicked(e) {
        setPage(e.currentTarget.id)
    }
    function logout() {
        store.logout()
    }
    function getClient(data) {
        let id = data.ID;
        let currPage = data.Page;
        setPage(data.ClientPage)
        setCurrentPage(currPage)
        setClientID(id);
        store.setLoading(true);
        store.getClientByID(id).then((ret) => {
            if (ret) {
                setSelectedClient(ret);
                store.setLoading(false);
            } else {
                window.alert('Error fetching client.');
                store.setLoading(false);
            }
        })
    }
    function backClicked(data) {
        setCurrentPage(data);
        setPage('');
        forceUpdate();
    }
    if (mobile()) {
        return (
            <div>

            </div>
        );

    } else {
        return (
            <div style={{ display: 'flex' }} className='dashBoardBackGround'>
                <div className='companyDashboardSideNav'>
                    <img className='' src={dashLogo} />
                    <div className='sideNavItems'>
                        {CurrentPage === 'Client' || Page === 'Quotes' || Page === 'SLAs' || Page === 'ClientsInfo' ? (
                            <>
                                <div className={`assets ${(Page === 'ClientsInfo') ? 'sidebarItemSelected' : ''}`} onClick={clientNavClicked} id="ClientsInfo">
                                    <img className='dashboardIcons' src={ClientInfoIcon} />
                                    <h3 style={{ marginLeft: '5%', fontFamily: 'Roboto' }} >Client Info</h3>
                                </div>
                                <div className={`assets ${(Page === 'Quotes') ? 'sidebarItemSelected' : ''}`} onClick={clientNavClicked} id="Quotes">
                                    <img className='dashboardIcons' src={QuotesIcon} />
                                    <h3 style={{ marginLeft: '5%', fontFamily: 'Roboto' }} >Quotes </h3>
                                </div>
                                <div className={`assets ${(Page === 'SLAs') ? 'sidebarItemSelected' : ''}`} onClick={clientNavClicked} id="SLAs">
                                    <img className='dashboardIcons' src={SLAIcon} />
                                    <h3 style={{ marginLeft: '5%', fontFamily: 'Roboto' }} >SLAs</h3>
                                </div>
                                <div className={`assets ${(CurrentPage === 'Logout') ? 'sidebarItemSelected' : ''}`} onClick={logout} id="Logout">
                                    <img className='dashboardIcons' src={LogoutIcon} />
                                    <h3 style={{ marginLeft: '5%', fontFamily: 'Roboto' }} >Logout</h3>
                                </div>
                            </>
                        ) : <>
                            <div className={`assets ${(CurrentPage === 'Dashboard') ? 'sidebarItemSelected' : ''}`} id='Dashboard' onClick={navClicked}>
                                <img className='dashboardIcons' src={HomeIcon} />
                                <h3 style={{ marginLeft: '5%', fontFamily: 'Roboto' }} >Dashboard</h3>
                            </div>
                            <div className={`assets ${(CurrentPage === 'Users') ? 'sidebarItemSelected' : ''}`} id="Users" onClick={navClicked} >
                                <img className='dashboardIcons' src={UsersIcons} />
                                <h3 style={{ marginLeft: '5%', fontFamily: 'Roboto' }}  >Users</h3>
                            </div>
                            <div className={`assets ${(CurrentPage === 'Companies') ? 'sidebarItemSelected' : ''}`} id="Companies" onClick={navClicked} >
                                <img className='dashboardIcons' src={CompaniesIcon} />
                                <h3 style={{ marginLeft: '5%', fontFamily: 'Roboto' }}  >Companies</h3>
                            </div>
                            {/* <div className={`assets ${(CurrentPage === 'OKRSessions') ? 'sidebarItemSelected' : ''}`} id="OKRSessions" onClick={navClicked} >
                                <img className='dashboardIcons' src={Globe} />
                                <h3 style={{ marginLeft: '5%', fontFamily: 'Roboto' }}  >O.K.R Sessions</h3>
                            </div> */}
                            {/* <div className={`assets ${(CurrentPage === 'General') ? 'sidebarItemSelected' : ''}`} onClick={navClicked} id="General">
                            <img className='dashboardIcons' src={GeneralIcon} />
                            <h3 style={{ marginLeft: '5%', fontFamily: 'Roboto' }} >General</h3>
                        </div> */}
                            <div className={`assets ${(CurrentPage === 'Aions') ? 'sidebarItemSelected' : ''}`} onClick={navClicked} id="Aions">
                                <img className='dashboardIcons' src={Globe} />
                                <h3 style={{ marginLeft: '5%', fontFamily: 'Roboto' }} >Aions</h3>
                            </div>
                            <div className={`assets ${(CurrentPage === 'Reports') ? 'sidebarItemSelected' : ''}`} id="Reports" onClick={navClicked} >
                                <img className='dashboardIcons' src={ReportsLogo} />
                                <h3 style={{ marginLeft: '5%', fontFamily: 'Roboto' }}  >Reports</h3>
                            </div>
                            <div className={`assets ${(CurrentPage === 'Alkemia') ? 'sidebarItemSelected' : ''}`} id="Alkemia" onClick={navClicked} >
                                <img className='dashboardIcons' src={ClientsIcon} />
                                <h3 style={{ marginLeft: '5%', fontFamily: 'Roboto' }}  >Alkemia Clients</h3>
                            </div>
                            <div className={`assets ${(CurrentPage === 'Logout') ? 'sidebarItemSelected' : ''}`} onClick={logout} id="Logout">
                                <img className='dashboardIcons' src={LogoutIcon} />
                                <h3 style={{ marginLeft: '5%', fontFamily: 'Roboto' }} >Logout</h3>
                            </div>
                        </>}
                    </div>
                </div>
                <div className='companyDashboardBody' style={{ height: `${window.innerHeight}px` }}>
                    <div className='companyDashboardTopNav' style={{ height: '10%' }}>
                        {CurrentPage === 'Client' || Page === 'Quotes' || Page === 'SLAs' || Page === 'ClientsInfo' ? (
                            null
                        ) : <div className='headerDivUserInfo' style={{ justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '25%' }}>
                                <div className='Shape_cf_Class'></div>
                                <div>
                                    <img style={{ marginLeft: '22%' }} src={UserIcon} />
                                </div>
                                <div style={{ height: '10%' }}>
                                    <h3>{User.FirstName} {User.LastName}</h3>
                                </div>
                            </div>
                            {/* <Card className='CompanyIconsLogoSettings'>
                            <img src={Settings} />
                        </Card>
                        <Card className='CompanyIconsLogoSettings'>
                            <img style={{ width: '85%' }} src={Notification} />
                        </Card> */}
                            <div style={{ display: 'flex', flexDirection: 'row', width: '80%' }}>
                                {CompaniesList.map((company, index) => {
                                    if (
                                        (store.user.Role === 'Admin' || store.user.Company !== '')
                                    ) {
                                        return (
                                            <div
                                                key={index}
                                                id={index}
                                                onClick={companySelected}
                                                className={`clickedCompanyName ${SelectedCompany.Name &&
                                                    SelectedCompany.Name === company.Name
                                                    ? 'setThePElementToBold'
                                                    : ''
                                                    }`}
                                            >
                                                <p style={{ marginLeft: '5%', fontWeight: SelectedCompany.Name === company.Name ? 'bold' : 'normal' }}>
                                                    {company.Name}
                                                </p>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </div>}
                    </div>
                    <div style={{ width: '100%', height: '90%' }}>
                        {(CurrentPage === 'Dashboard') ? <Dashboard /> : null}
                        {(CurrentPage === 'Users') ? <Users /> : null}
                        {(CurrentPage === 'General') ? <General /> : null}
                        {(CurrentPage === 'Companies') ? <Companies /> : null}
                        {(CurrentPage === 'Reports') ? <Reports /> : null}
                        {(Page === 'SLAs') ? <SLAs ClientID={ClientID} back={backClicked} /> : null}
                        {(Page === 'Quotes') ? <Quotes ClientID={ClientID} back={backClicked} /> : null}
                        {(Page === 'ClientsInfo') ? <ClientsInfo ClientID={ClientID} back={backClicked} /> : null}
                        {(CurrentPage === 'Clients') ? <Clients /> : null}
                        {(CurrentPage === 'Alkemia') ? <Alkemia selectedC={getClient} /> : null}
                        {(CurrentPage === 'OKRSessions') ? <OKRSessions /> : null}
                        {(CurrentPage === 'Aions') ? <Aions company={AionsId} /> : null}
                        {(CurrentPage === 'Company') ? <Company company={SelectedCompanyId} /> : null}
                    </div>
                </div>
            </div>
        )
    }
})

export default DepartmentDashboard;