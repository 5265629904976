import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { useNavigate } from 'react-router-dom';
import {
    Card,
    Input,
    Button,
} from '../../../../components';
import { FaUpload, FaDownload, FaAngleRight, FaPlus, FaDatabase } from 'react-icons/fa';
import isMobile from 'is-mobile';

const UpdateTargets = observer(({ SelectedCompanyId }) => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const navigate = useNavigate();
    const [Company, setCompany] = useState([]);
    const [CompanyMetrics, setCompanyMetrics] = useState([]);
    const [Target, setTarget] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [TargetsData, setTargetsData] = useState([]);
    useEffect(() => {
        selectedCompany();
    }, []);
    function selectedCompany() {
        let tmpTar = TargetsData;
        let dataToMap = []
        let matrics = []
        store.setLoading(true);
        store.getCompanyByID(SelectedCompanyId).then((ret) => {
            if (typeof ret === "string" || !ret) {
                setCompany([]);
                store.setLoading(false);
                return;
            } else {
                if (ret.Target.length > 0) {
                    let date = new Date(ret.Target[0].Date).toDateString()
                    setSelectedDate(date)
                    for (let j = 0; j < Object.keys(ret.Target[0]).length; j++) {
                        if (Object.keys(ret.Target[0])[j]) {
                            dataToMap.push(Object.values(ret.Target[0])[j])
                            if (Object.keys(ret.Target[0])[j] !== 'Date') {
                                matrics.push(Object.keys(ret.Target[0])[j])
                            }
                        }
                    }
                    tmpTar = dataToMap;
                }

                setTargetsData(tmpTar)
                setCompanyMetrics(matrics)
                setCompany(ret);
                store.setLoading(false);
            }
        });
    }
    function updateTargets() {
        let id = Company._id;
        let data = {
            Target: Target
        };
        store.setLoading(true);
        store.updateCompanyById(id, data).then((ret) => {
            if (ret) {
                window.alert("Targets added successfully");
                setTarget([]);
                store.setLoading(false);
            }
        })
            .catch((error) => {
                console.error("Error updating company:", error);
                store.setLoading(false);
            });
    }
    function handleClear() {
        setTarget([]);
        forceUpdate();
    }
    function getNewTargets(e) {
        let tar = Company.Target;
        let targetArr = [];
        let dataToMap = [];
        let updateMatric = '';

        for (let k = 0; k < tar.length; k++) {
            targetArr = tar.filter(item => new Date(item.Date).toDateString() === selectedDate);
            if (new Date(tar[k].Date).toDateString() === selectedDate) {
                for (let i = 0; i < CompanyMetrics.length; i++) {
                    let filteredMatric = CompanyMetrics.filter(item => item === e.currentTarget.id);
                    updateMatric = filteredMatric[0].toString();
                }
                if (updateMatric === e.currentTarget.id) {
                    targetArr[0][updateMatric] = Number(e.target.value);
                }
                for (let i = 0; i < tar.length; i++) {
                    if (new Date(tar[i].Date).toDateString() === selectedDate) {
                        tar[i] = targetArr[i];
                    }
                };
                for (let i = 0; i < Company.Config.length; i++) {
                    for (let j = 0; j < Object.keys(Company.Target[k]).length; j++) {
                        if (Company.Config[i] === Object.keys(Company.Target[k])[j]) {
                            dataToMap.push(Number(Object.values(Company.Target[k])[j]))
                        }
                    }
                }
            }
        }
        setTargetsData(dataToMap || [])
        setTarget(tar);
        forceUpdate();
    }
    if (isMobile()) {
        return (
            <div className='updateTargetsPage'>
                <h1 className="targetHeader">Update Targets</h1>
                {(Company.Target) ? <div>
                    <div className='selectUpdateDateDiv'>
                        <p><b>Select Date : </b></p>
                        <select id='selectDate' className='selectElemen' value={selectedDate} >
                            {Company.Target.map((tar, index) => {

                                if (Company.Target) {
                                    return (
                                        <option key={index} value={tar.Date}>
                                            {
                                                new Date(tar.Date).toDateString()
                                            }
                                        </option>
                                    )
                                }
                            })
                            }
                        </select>
                    </div>
                </div> :
                    <div>
                    </div>}
                {(CompanyMetrics) ? <div>
                    {(CompanyMetrics).map((matric, index) => {
                        if (matric) {
                            return (
                                <div className="inputDiv">
                                    <p className="targetName">{matric}</p>
                                    <Input type="number" id={matric} onChange={getNewTargets} value={TargetsData[CompanyMetrics.indexOf(matric)].toString()} />

                                </div>
                            )
                        } else {
                            return (
                                <Card>No data available..!</Card>
                            )
                        }
                    })}
                </div> :
                    <div>

                    </div>}


                <div className="elementStylingForButtons">
                    <Button className="buttonForCompanyPages" onClick={handleClear}>Cancel</Button>
                    <Button className="buttonForCompanyPages" onClick={updateTargets}>Submit</Button>
                </div>
            </div>
        )
    } else {
        return (
            <div className='updateTargetsPage'>
                <h1 className="targetHeader">Update Targets</h1>

                {(Company.Target) ? <div>
                    <div className='selectUpdateDateDiv'>
                        <p><b>Select Date : </b></p>
                        <select id='selectDate' className='selectElemen' value={selectedDate} >
                            {Company.Target.map((tar, index) => {

                                if (Company.Target) {
                                    return (
                                        <option key={index} value={tar.Date}>
                                            {
                                                new Date(tar.Date).toDateString()
                                            }
                                        </option>
                                    )
                                }
                            })
                            }
                        </select>
                    </div>
                </div> :
                    <div>
                        {/* <Card>Invalid Date formats..!</Card> */}
                    </div>}
                {(CompanyMetrics) ? <div>
                    {(CompanyMetrics).map((matric, index) => {
                        if (matric) {
                            return (
                                <div className="inputDiv">
                                    <p className="targetName">{matric}</p>
                                    <Input type="number" id={matric} onChange={getNewTargets} value={TargetsData[CompanyMetrics.indexOf(matric)].toString()} />

                                </div>
                            )
                        } else {
                            return (
                                <Card>No data available..!</Card>
                            )
                        }
                    })}
                </div> :
                    <div>

                    </div>}


                <div className="elementStylingForButtons">
                    <Button className="buttonForCompanyPages" onClick={handleClear}>Cancel</Button>
                    <Button className="buttonForCompanyPages" onClick={updateTargets}>Submit</Button>
                </div>
            </div>
        )
    }
})

export default UpdateTargets;