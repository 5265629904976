import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import './style.css';
import Plus from '../../../assets/images/plus.png';
import totalexpenses from '../../../assets/images/totalexpenses.png';
import CloseCard from '../../../assets/images/closeCard.png';
import totalsales from '../../../assets/images/totalsales.png';
import SmileIcon from '../../../assets/images/smile.png';
import AngryIcon from '../../../assets/images/angry.png';
import UnHappyIcon from '../../../assets/images/unhappy.png';
import HappyIcon from '../../../assets/images/happy.png';
import NeutralIcon from '../../../assets/images/neutral-face.png';
import ConfigIcon from '../../../assets/images/configs.png';
import { useNavigate } from 'react-router-dom';
import { Input, Button, Card, AEXGraph, AionsPL, AionsGraph, AlkemiaGraph, Slider } from '../../../components';
import { FaLessThanEqual } from 'react-icons/fa';
var mobile = require('is-mobile');

const Aions = observer(({ company }) => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Aions, setAions] = useState([]);
    const [ActualsDate, setActualsDate] = useState([]);
    const [Forecast, setForecast] = useState('');
    const [AddActuals, setAddActuals] = useState(false);
    const [AddForecast, setAddForecast] = useState(false);
    const [UpdateForecast, setUpdateForecast] = useState(false);
    const [UpdateActuals, setUpdateActuals] = useState(false);
    const [AllActuals, setAllActuals] = useState([]);
    const [AllTargets, setAllTargets] = useState([]);
    const [Client, setClient] = useState('');
    const [UpdateClient, setUpdateClient] = useState('');
    const [UpdateClientsAmount, setUpdateClientsAmount] = useState(0);
    const [UpdateCategory, setUpdateCategory] = useState('');
    const [UpdateCategoryAmount, setUpdateCategoryAmount] = useState(0);
    const [ForecastDate, setForecastDate] = useState(new Date());
    const [AionsType, setAionsType] = useState('Income');
    const [AEXType, setAEXType] = useState('Income');
    const [AlkemiaType, setAlkemiaType] = useState('Income');
    const [ClientsAmount, setClientsAmount] = useState(0);
    const [ViewForecast, setViewForecast] = useState(false);
    const [ViewActuals, setViewActuals] = useState(false);
    const [SelectedActualIndex, setSelectedActualIndex] = useState(-1);
    const [SelectedForecastIndex, setSelectedForecastIndex] = useState(-1);
    const [SelectedActual, setSelectedActual] = useState({});
    const [data, setData] = useState([]);
    const [SelectedForecast, setSelectedForecast] = useState({});
    const [Arr, setArr] = useState([]);
    const [AionsArr, setAionsArr] = useState([]);
    const [OPEXAionsOBJ, setOPEXAionsOBJ] = useState({
        Category: '',
        Amount: 0,
    });
    const [AionsForecastData, setAionsForecastData] = useState({
        Type: 'Forecast',
        Income: {
            AEX: 0,
            Interest: 0,
            Alkemia: 0,
            CashBalance: 0,
        },
        COS: {
            AEX: 0,
            Alkemia: 0
        },
        OPEX: {
            AEX: 0,
            Alkemia: 0,
            Aions: 0
        },
        Telkom: {
            Amount: 0,
        },
        Date: new Date(),
    });
    const [AionsData, setAionsData] = useState({
        Type: 'Actuals',
        Income: {
            AEX: 0,
            Interest: 0,
            Alkemia: [],
            CashBalance: 0,
        },
        COS: {
            AEX: 0,
            Alkemia: 0
        },
        OPEX: {
            AEX: [],
            Alkemia: [],
            Aions: AionsArr
        },
        Telkom: {
            Amount: 0,
        },
        Date: new Date(),
    });
    useEffect(() => {
        getData();
    }, []);
    function getData() {
        store.setLoading(true);
        store.getAions().then((data) => {
            if (data) {
                let actualsValues = data.filter((a) => a.Type === 'Actual' || a.Type === 'Actuals');
                let forecastValues = data.filter((a) => a.Type === 'Forecast');
                setAllActuals(actualsValues);
                setAllTargets(forecastValues);
                setAions(data);
                store.setLoading(false);
            }
            if (!data) {
                setAions({});
                store.setLoading(false);
            }
        });
        forceUpdate();
    };
    function handlePushToArr() {
        let aions = AionsData;
        let newOBJ = {
            Client: Client,
            Amount: Number(ClientsAmount)
        }
        aions.Income.Alkemia.push(newOBJ);
        setAionsData(aions);
        setClient('');
        setClientsAmount(0);
        forceUpdate();
    };
    function pushUpdatedOBJ() {
        let actualToUpdate = SelectedActual;
        let newOBJ = {
            Client: UpdateClient,
            Amount: Number(UpdateClientsAmount)
        }
        actualToUpdate.Income.Alkemia.push(newOBJ);
        setSelectedActual(actualToUpdate);
        setUpdateClient('');
        setUpdateClientsAmount(0);
        forceUpdate();
    };
    function pushUpdatedCategoriesOBJ() {
        let actualToUpdate = SelectedActual;
        let newOBJ = {
            Category: UpdateCategory,
            Amount: Number(UpdateCategoryAmount)
        }
        actualToUpdate.OPEX.Aions.push(newOBJ);
        setSelectedActual(actualToUpdate);
        setUpdateCategory('');
        setUpdateCategoryAmount(0);
        forceUpdate();
    };
    function categoryChanged(e) {
        setUpdateCategory(e.target.value);
        forceUpdate();
    }
    function categoryAmountChanged(e) {
        setUpdateCategoryAmount(e.target.value);
        forceUpdate();
    }
    function deleteClient(e) {
        let index = Number(e.currentTarget.id);
        let tempAions = AionsData;
        tempAions.Income.Alkemia.splice(index, 1);
        setAionsData(tempAions);
        forceUpdate();
    }
    function deleteAionsOPEX(e) {
        let data = SelectedActual;
        let index = Number(e.currentTarget.id);
        data.OPEX.Aions.splice(index, 1);
        setSelectedActual(data);
        forceUpdate();
    }
    function deleteAlkemiaIncome(e) {
        let data = SelectedActual;
        let index = Number(e.currentTarget.id);
        data.Income.Alkemia.splice(index, 1);
        setSelectedActual(data);
        forceUpdate();
    }
    function handlePushAionsToArr() {
        let aions = AionsArr;
        aions.push(OPEXAionsOBJ);
        setAionsArr(aions);
        setOPEXAionsOBJ({
            Category: '',
            Amount: 0,
        });
        forceUpdate();
    };
    function deleteCategory(e) {
        let index = Number(e.currentTarget.id);
        let aions = AionsArr;
        aions.splice(index, 1);
        setAionsArr(aions);
        forceUpdate();
    }
    function closeAddActualData() {
        handleClear();
        setAddActuals(false);
        forceUpdate();
    }
    function handleActualChange(e) {
        let actuals = AionsData;
        if (e.currentTarget.id === 'ActualsDate') {
            actuals.Date = e.target.value;
        }
        if (e.currentTarget.id === 'AEX') {
            actuals.Income.AEX = Number(e.target.value);
        }
        if (e.currentTarget.id === 'CashBalance') {
            actuals.Income.CashBalance = Number(e.target.value);
        }
        if (e.currentTarget.id === 'Interest') {
            actuals.Income.Interest = Number(e.target.value);
        }
        if (e.currentTarget.id === 'COSAex') {
            actuals.COS.AEX = Number(e.target.value);
        }
        if (e.currentTarget.id === 'COSAlkemia') {
            actuals.COS.Alkemia = Number(e.target.value);
        }
        if (e.currentTarget.id === 'TelkomAmount') {
            actuals.Telkom.Amount = Number(e.target.value);
        }
        setAionsData(actuals);
        forceUpdate();
    }
    function getForecastDate(e) {
        setForecastDate(e.target.value);
        forceUpdate();
    }
    function handleForecastValueChange(e) {
        let forecast = AionsForecastData;
        if (e.currentTarget.id === 'ForecastDate') {
            forecast = e.target.value;
        }
        if (e.currentTarget.id === 'AEXForecast') {
            forecast.Income.AEX = Number(e.target.value);
        }
        if (e.currentTarget.id === 'CashBalance') {
            forecast.Income.CashBalance = Number(e.target.value);
        }
        if (e.currentTarget.id === 'Interest') {
            forecast.Income.Interest = Number(e.target.value);
        }
        if (e.currentTarget.id === 'COSAex') {
            forecast.COS.AEX = Number(e.target.value);
        }
        if (e.currentTarget.id === 'AlkemiaForecast') {
            forecast.Income.Alkemia = Number(e.target.value);
        }
        if (e.currentTarget.id === 'COSAlkemia') {
            forecast.COS.Alkemia = Number(Number(e.target.value));
        }
        if (e.currentTarget.id === 'OpexAions') {
            forecast.OPEX.Aions = Number(e.target.value);
        }
        if (e.currentTarget.id === 'OpexAlkemia') {
            forecast.OPEX.Alkemia = Number(e.target.value);
        }
        if (e.currentTarget.id === 'OpexAEX') {
            forecast.OPEX.AEX = Number(e.target.value);
        }
        if (e.currentTarget.id === 'Telkom') {
            forecast.Telkom.Amount = Number(e.target.value);
        }
        setAionsForecastData(forecast);
        forceUpdate();
    }
    function handleInputChange(e) {
        let values = OPEXAionsOBJ
        if (e.target.id === 'Category') {
            values.Category = e.target.value;
        }
        if (e.target.id === 'Amount') {
            values.Amount = Number(e.target.value);
        }
        setOPEXAionsOBJ(values)
        forceUpdate();
    }
    function closeAddForecastData() {
        setViewForecast(false);
        setViewActuals(false);
        handleClear();
        setAddForecast(false);
        forceUpdate();
    }
    function forecastUpdate(e) {
        let forecastToUpdate = SelectedForecast;
        if (e.currentTarget.id === 'UpdateIncomeAEX') {
            forecastToUpdate.Income.AEX = Number(e.target.value);
        }
        if (e.currentTarget.id === 'UpdateIncomeInterest') {
            forecastToUpdate.Income.Interest = Number(e.target.value);
        }
        if (e.currentTarget.id === 'UpdateCashBalance') {
            forecastToUpdate.Income.CashBalance = Number(e.target.value) ?? 0;
        }
        if (e.currentTarget.id === 'UpdateIncomeAlkemia') {
            forecastToUpdate.Income.Alkemia = Number(e.target.value);
        }
        if (e.currentTarget.id === 'UpdateCOSAex') {
            forecastToUpdate.COS.AEX = Number(e.target.value);
        }
        if (e.currentTarget.id === 'UpdateCOSAlkemia') {
            forecastToUpdate.COS.Alkemia = Number(e.target.value);
        }
        if (e.currentTarget.id === 'UpdateOpexAex') {
            forecastToUpdate.OPEX.AEX = Number(e.target.value);
        }
        if (e.currentTarget.id === 'UpdateOPEXAlkemia') {
            forecastToUpdate.OPEX.Alkemia = Number(e.target.value);
        }
        if (e.currentTarget.id === 'UpdateTelkomAmount') {
            forecastToUpdate.Telkom.Amount = Number(e.target.value);
        }
        if (e.currentTarget.id === 'UpdateOPEXAions') {
            forecastToUpdate.OPEX.Aions = Number(e.target.value);
        }
        forceUpdate();
    }
    function actualsUpdate(e) {
        let actualsToUpdate = SelectedActual;
        if (e.currentTarget.id === 'UpdateIncomeAex') {
            actualsToUpdate.Income.AEX = Number(e.target.value);
        }
        if (e.currentTarget.id === 'UpdateCashBalance') {
            actualsToUpdate.Income.CashBalance = Number(e.target.value);
        }
        if (e.currentTarget.id === 'UpdateIncomeInterest') {
            actualsToUpdate.Income.Interest = Number(e.target.value);
        }
        if (e.currentTarget.id === 'UpdateCOSAex') {
            actualsToUpdate.COS.AEX = Number(e.target.value);
        }
        if (e.currentTarget.id === 'UpdateCOSAlkemia') {
            actualsToUpdate.COS.Alkemia = Number(e.target.value);
        }
        if (e.currentTarget.id === 'UpdateTelkomAmount') {
            actualsToUpdate.Telkom.Amount = Number(e.target.value);
        }
        forceUpdate();
    }
    function handleSubmitData() {
        let data = AionsData;
        if (data.Date === '') {
            window.alert('Please enter a date.');
        } else {
            const date = new Date(data.Date);
            if (date.getDay() !== 1) {
                window.alert('Date should be a Monday.');
                return;
            }
        }
        store.setLoading(true);
        store.createAions(data).then((ret) => {
            if (ret) {
                window.alert("Actuals added successfully.");
                handleClear();
                store.setLoading();
            }
        })
    }
    function handleForecastData() {
        let data = AionsForecastData;
        data.Date = ForecastDate;
        store.setLoading(true);
        store.createAions(data).then((ret) => {
            if (ret) {
                window.alert("Actuals added successfully.");
                handleClear();
                store.setLoading();
            }
        })
    }
    function handleClear() {
        setAionsArr([]);
        setArr([]);
        setAddActuals(false)
        setAddForecast(false)
        setAionsData({
            Type: 'Actuals',
            Income: {
                AEX: 0,
                Interest: 0,
                Alkemia: [],
            },
            COS: {
                AEX: 0,
                Alkemia: 0
            },
            OPEX: {
                AEX: [],
                Alkemia: [],
                Aions: AionsArr
            },
            Telkom: {
                Amount: 0,
            },
            Date: new Date(),
        });
        setAionsForecastData({
            Type: 'Forecast',
            Income: {
                AEX: 0,
                Interest: 0,
                Alkemia: 0,
            },
            COS: {
                AEX: 0,
                Alkemia: 0
            },
            OPEX: {
                AEX: 0,
                Alkemia: 0,
                Aions: 0
            },
            Telkom: {
                Amount: 0,
            },
            Date: new Date(),
        })
        forceUpdate();
    };
    function UpdateForecastClicked() {
        setUpdateForecast(true);
    }
    function UpdateActualsClicked() {
        setUpdateActuals(true);
    }
    function closeUpdateForecast() {
        setUpdateForecast(false);
    }
    function closeUpdateActuals() {
        setUpdateActuals(false);
    }
    function handleUpdateClick(index) {
        setSelectedForecastIndex(index);
        setSelectedForecast(AllTargets[index]);
        forceUpdate();
        setViewForecast(true);
        setUpdateForecast(false);
    }
    function handleUpdateActualsClick(index) {
        setSelectedActualIndex(index);
        setSelectedActual(AllActuals[index]);
        forceUpdate();
        setViewActuals(true);
        setUpdateActuals(false);
    }
    function amountChanged(e) {
        setClientsAmount(e.target.value);
    }
    function UpdateAmountChanged(e) {
        setUpdateClientsAmount(e.target.value);
    }
    function UpdateClientChanged(e) {
        setUpdateClient(e.target.value)
    }
    function clientChanged(e) {
        setClient(e.target.value)
    }
    function formatDate(inputDate) {
        if (!inputDate) {
            return null;
        }
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(inputDate).toLocaleDateString(undefined, options);
        return formattedDate;
    }
    function handleAionsType(e) {
        setAionsType(e.currentTarget.id);
        forceUpdate();
    }
    function handleAEXType(e) {
        setAEXType(e.currentTarget.id);
        forceUpdate();
    }
    function handleAlkemiaType(e) {
        setAlkemiaType(e.currentTarget.id);
        forceUpdate();
    }
    function updateActualsClicked() {
        let id = SelectedActual._id;
        let data = SelectedActual;
        store.setLoading(true);
        store.updateAionsByID(id, data).then((retObj) => {
            if (retObj) {
                window.alert("Actuals updated Successfully.");
                setViewActuals(false);
                getData();
                store.setLoading(false);
            } else {
                window.alert("Actuals could not be updated.");
                setViewActuals(false);
                store.setLoading();
            }
        });
    }
    function updateForecastClicked(e) {
        let id = SelectedForecast._id;
        let data = SelectedForecast;
        delete data['Date'];
        store.setLoading(true);
        store.updateAionsByID(id, data).then((retObj) => {
            if (retObj) {
                window.alert("Actuals updated Successfully.");
                setViewForecast(false);
                getData();
                store.setLoading(false);
            } else {
                window.alert("Actuals could not be updated.");
                setViewForecast(false);
                store.setLoading();
            }
        });
        forceUpdate();
    }
    function removeForecast() {
        let id = SelectedForecast._id
        store.setLoading(true);
        store.removeAions(id).then((retObj) => {
            if (retObj) {
                setViewForecast(false);
                window.alert('Forecast Removed!');
                getData();
                store.setLoading(false);
            } else {
                window.alert('Forecast could not be Removed!');
                store.setLoading(false);
            }
        });
        forceUpdate();
    }
    function getSheetData() {
        store.setLoading(true);
        let Actuals = [];
        store.getSheetData().then((data) => {
            if (data === 'Success') {
                window.alert('Data has been updated');
                store.setLoading(false);
            } else {
                window.alert('Error syncing data, please check your google sheet and try again.');
                store.setLoading(false);
            }
        });
    }
    function getXeroData() {
        store.setLoading(true);
        store.getXero().then((ret) => {
            if (ret) {
                window.open(ret);
                store.setLoading(false);
            } else {
                window.alert('Error loading Xero data.')
                store.setLoading(false);
            }
        })
    }
    function removeActuals() {
        let id = SelectedActual._id
        store.setLoading(true);
        store.removeAions(id).then((retObj) => {
            if (!retObj) {
                window.alert('Actual Removed!');
                getData();
                setViewActuals(false);
                store.setLoading(false);
            } else {
                store.setLoading(false);
            }
        });
        forceUpdate();
    }
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    function convertDateFormat(inputDate) {
        const monthAbbreviation = inputDate.split('-')[0];
        const monthMap = {
            Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5, Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11
        };
        const month = monthMap[monthAbbreviation];
        const day = parseInt(inputDate.split('-')[1]);

        const lastTwoDigitsOfYear = parseInt(inputDate.split('-')[1]) + 2000;

        const currentDate = new Date(lastTwoDigitsOfYear, month, day);
        currentDate.setUTCHours(0, 0, 0, 0);

        const formattedDate = currentDate.toISOString();

        return formattedDate;
    }
    if (mobile()) {
        return (
            <div>

            </div>
        );
    } else {
        return (
            <div className='' style={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
                <Card className='companyDashboardTasks'>
                    <div className='myCards'>
                        <Slider aions={AllActuals} forecast={AllTargets} />
                        {/* <Card className='addAionsForeCast' style={{ cursor: 'pointer' }} onClick={addForecastClicked}>
                            <div >
                                <img className='todopngstyle' src={Plus} />
                                <p>Forecast</p>
                            </div>
                        </Card>
                        <Card className='addNewActualsCard' style={{ cursor: 'pointer' }} onClick={addActualsClicked}>
                            <div >
                                <img className='todopngstyle' src={Plus} />
                                <p>Actuals</p>
                            </div>
                        </Card> */}
                        <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                            <Card className='updateForecast' style={{ cursor: 'pointer' }} onClick={UpdateForecastClicked}>
                                <p>Update Forecast</p>
                            </Card>
                            <Card className='updateActualsCard' style={{ cursor: 'pointer' }} onClick={UpdateActualsClicked}>
                                <p>Update Actuals</p>
                            </Card>
                        </div>
                        <Button onClick={getSheetData} className='syncAionsData'>Sync Actual Data</Button>
                        <Button onClick={getXeroData} style={{ marginLeft: '2%' }} className='syncAionsData'>Sync Xero Data</Button>
                    </div>
                </Card>
                <Card className='companyDashboardTasks' style={{ marginTop: '1%' }}>
                    <AionsPL aions={AllActuals} />
                </Card>
                <Card className='companyDashboardTasks' style={{ marginTop: '1%' }}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ width: '100%', height: '20%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <p style={{ fontWeight: 'bold', fontSize: '25px' }}>Aions</p>
                            <div style={{ width: '60%', height: '20%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                <Card className='incomeCard' id='Income' onClick={handleAionsType}>Income</Card>
                                <Card className='OPEXCard' id='Opex' onClick={handleAionsType}>OPEX</Card>
                                <Card className='COSCard' id='COS' onClick={handleAionsType}>COS</Card>
                                <Card className='TelkomCard' id='Telkom' onClick={handleAionsType}>Telkom</Card>
                            </div>
                        </div>
                        <AionsGraph aions={AllActuals} forecast={AllTargets} type={AionsType} />
                    </div>
                </Card>
                <Card className='companyDashboardTasks' style={{ marginTop: '1%' }}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ width: '100%', height: '20%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <p style={{ fontWeight: 'bold', fontSize: '25px' }}>AEX</p>
                            <div style={{ width: '60%', height: '20%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                <Card className='incomeCard' id='Income' onClick={handleAEXType}>Income</Card>
                                <Card className='COSCard' id='COS' onClick={handleAEXType}>COS</Card>
                            </div>
                        </div>
                        <AEXGraph aions={AllActuals} forecast={AllTargets} type={AEXType} />
                    </div>
                </Card>
                <Card className='companyDashboardTasks' style={{ marginTop: '1%' }}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ width: '100%', height: '20%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <p style={{ fontWeight: 'bold', fontSize: '25px' }}>Alkemia</p>
                            <div style={{ width: '60%', height: '20%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                <Card className='incomeCard' id='Income' onClick={handleAlkemiaType}>Income</Card>
                                <Card className='COSCard' id='COS' onClick={handleAlkemiaType}>COS</Card>
                            </div>
                        </div>
                        <AlkemiaGraph aions={AllActuals} forecast={AllTargets} type={AlkemiaType} />
                    </div>
                </Card>
                {AddActuals ? (
                    <Card className='addAionsActuals'>
                        <div className='AddDataCardHeaders'>
                            <h1>Aions Actual Values</h1>
                            <img className='closeAddActualsCard' onClick={closeAddActualData} src={CloseCard} />
                        </div>
                        <div className="DateDiv">
                            <p className="pElement" style={{ width: '80%' }}>Select Date</p>
                            <Input className='InputDateElement' type="date" id="ActualsDate" value={AionsData.Date} onChange={handleActualChange} />
                        </div>
                        <div className='AddDataCardBody'>
                            <div>
                                <h2>Income :</h2>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>AEX :</b></p>
                                    <Input className='aionsDataInputs' type='number' id='AEX' value={String(AionsData.Income.AEX)} onChange={handleActualChange} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>Cash Balance :</b></p>
                                    <Input className='aionsDataInputs' type='number' id='CashBalance' value={String(AionsData.Income.CashBalance)} onChange={handleActualChange} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>Interest :</b></p>
                                    <Input className='aionsDataInputs' type='number' id='Interest' value={String(AionsData.Income.Interest)} onChange={handleActualChange} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <h3 style={{ marginLeft: '3%' }}>Alkemia :</h3>
                                    {AionsData.Income.Alkemia.map((items, index) => {
                                        return (
                                            <div style={{ marginLeft: '5%', display: 'flex' }}>
                                                <p style={{ width: '35%' }}>{items.Client}</p>
                                                <p style={{ width: '35%' }}><b>R{items.Amount}</b></p>
                                                <Button className='btnCategory' id={index} style={{ width: '30%', backgroundColor: '#e3367e' }} onClick={deleteClient}>Delete</Button>
                                            </div>
                                        )
                                    })}
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ width: '50%', display: 'flex', flexDirection: 'row', marginLeft: '5%' }}>
                                            <p><b>Client : </b></p><Input className='btnCategory' id='Client' value={Client} onChange={clientChanged} />
                                        </div>
                                        <div style={{ width: '50%', display: 'flex', flexDirection: 'row' }}>
                                            <p><b>Amount : </b></p><Input className='btnCategory' id='ClientAmount' value={String(ClientsAmount)} onChange={amountChanged} />
                                        </div>
                                        <Button className='btnCategory' onClick={handlePushToArr}>Add Client</Button>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <h2>Cost of sales :</h2>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>AEX :</b></p>
                                    <Input className='aionsDataInputs' type='number' id='COSAex' value={String(AionsData.COS.AEX)} onChange={handleActualChange} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>Alkemia :</b></p>
                                    <Input className='aionsDataInputs' id='COSAlkemia' value={String(AionsData.COS.Alkemia)} onChange={handleActualChange} />
                                </div>
                            </div>
                            <hr />
                            <div>
                                <h2>Operating Expenses :</h2>
                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '7%' }}>
                                    <h3>Aions :</h3>
                                    {AionsArr.map((items, index) => {
                                        return (
                                            <div style={{ marginLeft: '5%', display: 'flex' }}><p style={{ width: '40%' }}>{items.Category}</p> <p><b>R{items.Amount}</b></p><Button className='btnCategory' id={index} style={{ width: '30%', backgroundColor: '#e3367e' }} onClick={deleteCategory}>Delete</Button></div>
                                        )
                                    })}
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ width: '50%', display: 'flex', flexDirection: 'row', marginLeft: '5%' }}>
                                            <p><b>Category : </b></p><input className='btnCategory' id='Category' value={OPEXAionsOBJ.Category} onChange={handleInputChange} />
                                        </div>
                                        <div style={{ width: '50%', display: 'flex', flexDirection: 'row' }}>
                                            <p><b>Amount : </b></p><input className='btnCategory' id='Amount' value={String(OPEXAionsOBJ.Amount)} onChange={handleInputChange} />
                                        </div>
                                        <Button className='btnCategory' onClick={handlePushAionsToArr}>Add Category</Button>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <h2>Telkom :</h2>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>Amount :</b></p>
                                    <Input className='aionsDataInputs' type='number' id='TelkomAmount' value={String(AionsData.Telkom.Amount)} onChange={handleActualChange} />
                                </div>
                            </div>
                        </div>
                        <hr style={{ width: '100%' }} />
                        <Button className='AddDataButton' onClick={handleSubmitData}>Add Actuals</Button>
                    </Card>
                ) : null}
                {AddForecast ? (
                    <Card className='addAionsActuals'>
                        <div className='AddDataCardHeaders'>
                            <h1>Aions Forecast Values</h1>
                            <img className='closeAddActualsCard' onClick={closeAddForecastData} src={CloseCard} />
                        </div>
                        <div className="DateDiv">
                            <p className="pElement" style={{ width: '80%' }}>Select Date</p>
                            <Input className='InputDateElement' type="date" id='ForecastDate' value={ForecastDate} onChange={getForecastDate} />
                        </div>
                        <div className='AddDataCardBody'>
                            <div>
                                <h2>Income :</h2>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>AEX :</b></p>
                                    <Input className='aionsDataInputs' type='number' id='AEXForecast' value={String(AionsForecastData.Income.AEX)} onChange={handleForecastValueChange} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>Cash Balance :</b></p>
                                    <Input className='aionsDataInputs' type='number' id='CashBalance' value={String(AionsForecastData.Income.CashBalance)} onChange={handleForecastValueChange} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>Interest :</b></p>
                                    <Input className='aionsDataInputs' type='number' id='Interest' value={String(AionsForecastData.Income.Interest)} onChange={handleForecastValueChange} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>Alkemia :</b></p>
                                    <Input className='aionsDataInputs' type='number' id='AlkemiaForecast' value={String(AionsForecastData.Income.Alkemia)} onChange={handleForecastValueChange} />
                                </div>
                            </div>
                            <hr />
                            <div>
                                <h2>Cost of sales :</h2>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>AEX :</b></p>
                                    <Input className='aionsDataInputs' type='number' id='COSAex' value={String(AionsForecastData.COS.AEX)} onChange={handleForecastValueChange} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>Alkemia :</b></p>
                                    <Input className='aionsDataInputs' id='COSAlkemia' value={String(AionsForecastData.COS.Alkemia)} onChange={handleForecastValueChange} />
                                </div>
                            </div>
                            <hr />
                            <div>
                                <h2>Operating Expenses :</h2>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>AEX :</b></p>
                                    <Input className='aionsDataInputs' id='OpexAEX' value={String(AionsForecastData.OPEX.AEX)} onChange={handleForecastValueChange} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>Alkemia :</b></p>
                                    <Input className='aionsDataInputs' id='OpexAlkemia' value={String(AionsForecastData.OPEX.Alkemia)} onChange={handleForecastValueChange} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>Aions :</b></p>
                                    <Input className='aionsDataInputs' id='OpexAions' value={String(AionsForecastData.OPEX.Aions)} onChange={handleForecastValueChange} />
                                </div>
                            </div>
                            <hr />
                            <div>
                                <h2>Telkom :</h2>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>Amount :</b></p>
                                    <Input className='aionsDataInputs' type='number' id='Telkom' value={String(AionsForecastData.Telkom.Amount)} onChange={handleForecastValueChange} />
                                </div>
                            </div>
                        </div>
                        <hr style={{ width: '100%' }} />
                        <Button className='AddDataButton' onClick={handleForecastData}>Add Forecast</Button>
                    </Card>
                ) : null}
                {UpdateActuals ? (
                    <Card className='addAionsActuals'>
                        <div style={{ width: '100%', height: "20%" }}>
                            <h1>Update Actuals Values</h1>
                            <img className='closeAddActualsCard' onClick={closeUpdateActuals} src={CloseCard} />
                        </div>
                        <div style={{ width: '100%', height: '80%' }}>
                            {AllActuals.map((item, index) => {
                                if (item.Type === 'Actuals') {
                                    return (
                                        <card key={item} title={item} className='itemCard'>
                                            <div style={{ width: '90%' }}>
                                                <p style={{ marginLeft: '2%' }}>{formatDate(item.Date)}</p>
                                            </div>
                                            <card className='updateForecastbtn' onClick={() => handleUpdateActualsClick(index)}>Update</card>
                                        </card>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </div>

                    </Card>
                ) : null}
                {UpdateForecast ? (
                    <Card className='addAionsActuals'>
                        <div style={{ width: '100%', height: "20%" }}>
                            <h1>Update Forecast Values</h1>
                            <img className='closeAddActualsCard' onClick={closeUpdateForecast} src={CloseCard} />
                        </div>
                        <div style={{ width: '100%', height: '80%' }}>
                            {AllTargets.map((item, index) => {
                                if (item.Type === 'Forecast') {
                                    return (
                                        <card key={item} title={item} className='itemCard'>
                                            <div style={{ width: '90%' }}>
                                                <p style={{ marginLeft: '2%' }}>{formatDate(item.Date)}</p>
                                            </div>
                                            <card className='updateForecastbtn' id={index} onClick={() => handleUpdateClick(index)}>Update</card>
                                        </card>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </div>
                    </Card>
                ) : null}
                {ViewActuals ? (
                    <Card className='addAionsActuals'>
                        <div className='AddDataCardHeaders'>
                            <h1>Update Actual Values</h1>
                            <img className='closeAddActualsCard' onClick={closeAddForecastData} src={CloseCard} />
                        </div>
                        <div className="DateDiv">
                            <p className="pElement" style={{ width: '80%' }}>Date</p>
                            <Input className='InputDateElement' value={formatDate(SelectedActual.Date)} />
                        </div>
                        <div className='AddDataCardBody'>
                            <div>
                                <h2>Income :</h2>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <p style={{ width: '40%' }}><b>AEX :</b></p>
                                    <Input className='aionsDataInputs' id='UpdateIncomeAex' value={String(SelectedActual.Income.AEX)} onChange={actualsUpdate} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>Interest :</b></p>
                                    <Input className='aionsDataInputs' type='number' id='UpdateIncomeInterest' value={String(SelectedActual.Income.Interest)} onChange={actualsUpdate} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <p style={{ width: '40%' }}><b>CashBalance :</b></p>
                                    <Input className='aionsDataInputs' id='UpdateCashBalance' value={String(SelectedActual.Income.CashBalance || 0)} onChange={actualsUpdate} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <h3 style={{ marginLeft: '3%' }}>Alkemia :</h3>
                                    {SelectedActual.Income.Alkemia.map((items, index) => {
                                        return (
                                            <div style={{ marginLeft: '5%', display: 'flex' }}>
                                                <p style={{ width: '35%' }}>{items.Client}</p>
                                                <p style={{ width: '35%' }}><b>R{items.Amount}</b></p>
                                                <Button className='btnCategory' id={index} style={{ width: '30%', backgroundColor: '#e3367e' }} onClick={deleteAlkemiaIncome}>Delete</Button>
                                            </div>)
                                    })}
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ width: '50%', display: 'flex', flexDirection: 'row', marginLeft: '5%' }}>
                                            <p><b>Client : </b></p><Input className='btnCategory' value={UpdateClient} onChange={UpdateClientChanged} />
                                        </div>
                                        <div style={{ width: '50%', display: 'flex', flexDirection: 'row' }}>
                                            <p><b>Amount : </b></p><Input className='btnCategory' value={String(UpdateClientsAmount)} onChange={UpdateAmountChanged} />
                                        </div>
                                        <Button className='btnCategory' onClick={pushUpdatedOBJ}>Add Client</Button>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <h2>Cost of sales :</h2>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>AEX :</b></p>
                                    <Input className='aionsDataInputs' type='number' id='UpdateCOSAex' value={String(SelectedActual.COS.AEX)} onChange={actualsUpdate} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>Alkemia :</b></p>
                                    <Input className='aionsDataInputs' id='UpdateCOSAlkemia' value={String(SelectedActual.COS.Alkemia)} onChange={actualsUpdate} />
                                </div>
                            </div>
                            <hr />
                            <div>
                                <h2>Operating Expenses :</h2>
                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '7%' }}>
                                    <h3>Aions :</h3>
                                    {SelectedActual.OPEX.Aions.map((items, index) => {
                                        return (
                                            <div style={{ marginLeft: '5%', display: 'flex' }}>
                                                <p style={{ width: '35%' }}>{items.Category}</p>
                                                <p style={{ width: '35%' }}><b>R{items.Amount}</b></p>
                                                <Button className='btnCategory' id={index} style={{ width: '30%', backgroundColor: '#e3367e' }} onClick={deleteAionsOPEX}>Delete</Button>
                                            </div>
                                        )
                                    })}
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ width: '50%', display: 'flex', flexDirection: 'row', marginLeft: '5%' }}>
                                            <p><b>Category : </b></p><input className='btnCategory' value={UpdateCategory} onChange={categoryChanged} />
                                        </div>
                                        <div style={{ width: '50%', display: 'flex', flexDirection: 'row' }}>
                                            <p><b>Amount : </b></p><input className='btnCategory' value={String(UpdateCategoryAmount)} onChange={categoryAmountChanged} />
                                        </div>
                                        <Button className='btnCategory' onClick={pushUpdatedCategoriesOBJ}>Add Category</Button>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <h2>Telkom :</h2>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>Amount :</b></p>
                                    <Input className='aionsDataInputs' type='number' id='UpdateTelkomAmount' value={String(SelectedActual.Telkom.Amount)} onChange={actualsUpdate} />
                                </div>
                            </div>
                        </div>
                        <hr style={{ width: '100%' }} />
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <Button className='AddDataButton' onClick={updateActualsClicked}>Update Actuals</Button>
                            <Button className='removeForeOrActualButton' onClick={removeActuals}>Remove Actuals</Button>
                        </div>
                    </Card>
                ) : null}
                {ViewForecast ? (
                    <Card className='addAionsActuals'>
                        <div className='AddDataCardHeaders'>
                            <h1>Aions Forecast Values</h1>
                            <img className='closeAddActualsCard' onClick={closeAddForecastData} src={CloseCard} />
                        </div>
                        <div className="DateDiv">
                            <p className="pElement" style={{ width: '80%' }}>Date</p>
                            <Input className='InputDateElement' value={formatDate(SelectedForecast.Date)} />
                        </div>
                        <div className='AddDataCardBody'>
                            <div>
                                <h2>Income :</h2>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>AEX :</b></p>
                                    <Input className='aionsDataInputs' type='number' id='UpdateIncomeAEX' onChange={forecastUpdate} value={String(SelectedForecast.Income.AEX)} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>Interest :</b></p>
                                    <Input className='aionsDataInputs' type='number' id='UpdateIncomeInterest' onChange={forecastUpdate} value={String(SelectedForecast.Income.Interest)} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <p style={{ width: '40%' }}><b>CashBalance :</b></p>
                                    <Input className='aionsDataInputs' id='UpdateCashBalance' value={String(SelectedForecast.Income.CashBalance || 0)} onChange={forecastUpdate} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>Alkemia :</b></p>
                                    <Input className='aionsDataInputs' type='number' id='UpdateIncomeAlkemia' onChange={forecastUpdate} value={String(SelectedForecast.Income.Alkemia) || 0} />
                                </div>
                            </div>
                            <hr />
                            <div>
                                <h2>Cost of sales :</h2>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>AEX :</b></p>
                                    <Input className='aionsDataInputs' type='number' id='UpdateCOSAex' onChange={forecastUpdate} value={String(SelectedForecast.COS.AEX)} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>Alkemia :</b></p>
                                    <Input className='aionsDataInputs' id='UpdateCOSAlkemia' onChange={forecastUpdate} value={String(SelectedForecast.COS.Alkemia)} />
                                </div>
                            </div>
                            <hr />
                            <div>
                                <h2>Operating Expenses :</h2>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>AEX :</b></p>
                                    <Input className='aionsDataInputs' id='UpdateOpexAex' onChange={forecastUpdate} value={String(SelectedForecast.OPEX.AEX)} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>Alkemia :</b></p>
                                    <Input className='aionsDataInputs' id='UpdateOPEXAlkemia' onChange={forecastUpdate} value={String(SelectedForecast.OPEX.Alkemia)} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>Aions :</b></p>
                                    <Input className='aionsDataInputs' id='UpdateOPEXAions' onChange={forecastUpdate} value={String(SelectedForecast.OPEX.Aions)} />
                                </div>
                            </div>
                            <hr />
                            <div>
                                <h2>Telkom :</h2>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '7%' }}>
                                    <p style={{ width: '40%' }}><b>Amount :</b></p>
                                    <Input className='aionsDataInputs' type='number' id='UpdateTelkomAmount' onChange={forecastUpdate} value={String(SelectedForecast.Telkom.Amount)} />
                                </div>
                            </div>
                        </div>
                        <hr style={{ width: '100%' }} />
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <Button className='AddDataButton' onClick={updateForecastClicked}>Update Forecast</Button>
                            <Button className='removeForeOrActualButton' onClick={removeForecast}>Remove Forecast</Button>
                        </div>
                    </Card>
                ) : null}
            </div >
        );
    }
});

export default Aions;
