import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import './style.css';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Input,
} from '../../components';
import { FaUpload, FaDownload } from 'react-icons/fa';
import isMobile from 'is-mobile';
import { AES, enc } from 'crypto-js';
const pako = require('pako');

function Doc() { // #region UseStates
  const store = useStore();
  const [, updateState] = useState();
  const [CurrentPage, setCurrentPage] = useState(true);
  const [CompanyTab, setCompanyTab] = useState(false);
  const [FinanceTab, setFinanceTab] = useState(false);
  const [HrTab, setHrTab] = useState(false);
  const [CompanyDocs, setCompanyDocs] = useState([]);
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [DocDetails, setDocDetails] = useState({
    Name: '',
    Type: 'Select',
    File: ''
    });
  // #endregion

  // #region Functions
  useEffect(() => {
    loadCompanies();
  }, []);
  function loadCompanies() {
    store.setLoading(true);
    store.getCompaniesByPerms(store.user.Email).then((ret) => {
      if (ret) {
        getDocFromBase(ret);
      }
    })
    store.setLoading(false);
  }
  function getDocFromBase(ret) {
    let compDocs = CompanyDocs;
    for (let i = 0; i < ret.length; i++) {
      if (store.user.Company === ret[i].Name) {
        compDocs = ret[i].Document;
        continue;
      }
    }
    setCompanyDocs(compDocs);
    forceUpdate();
  }
  function uploadDocuments() {
    let docArray = [];
    if(DocDetails.File === {} ||DocDetails.File === ''){
      window.alert('Please upload file');
      return
    }
    if (DocDetails.Name === ''){
      window.alert('Please input document name ');
      return
    }
    if (DocDetails.Type === 'Select') {
      window.alert('Please select a documnent type/category');
      return
    } else {
      docArray.push(DocDetails)
      let data = {
        Document: docArray
      }
      store.setLoading(true);
      store.uploadDocs(data, store.user.Company).then((ret) => {
        if (ret) {
          window.alert('File uploaded Successfully..!')
          getDocFromBase(ret);
          store.setLoading(false);
        }else{
          window.alert('File uploaded Unsuccessfully..!')
          store.setLoading(false);
        }
      })
      handleClear()
    }
  }
  function downloadDocument(e) {
    try {
      const base64String = CompanyDocs[e.currentTarget.id].File;
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
  
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const link = document.createElement('a');
  
      link.href = URL.createObjectURL(blob);
      link.download = CompanyDocs[e.currentTarget.id].Name + '.pdf';
      link.click();
      window.alert('File successfully downloaded.!')
    } catch (error) {
      window.alert('File unsuccessfully downloaded.!')
      console.error('An error occurred while downloading the document:', error);
    }
  }
  function getDocDetailsFromUser(e) {
    let docDetails = DocDetails;
    if (e.currentTarget.id === "docFile") {
      convertToBase64(e, function (file) {
        docDetails.File = file
      });

    }
    if (e.currentTarget.id === 'docName') {
      docDetails.Name = String(e.target.value);
    }
    if (e.currentTarget.id === 'selcetDocType') {
      docDetails.Type = String(e.target.value);
    }
    setDocDetails(docDetails)
    forceUpdate();
  }
  function convertToBase64(e, callback) {
    const file = e.target.files[0];
    if (!file) {
      alert("Please select a PDF file.");
      return;
    }
  
    const reader = new FileReader();
    reader.onload = function (event) {
      const base64String = event.target.result.split(",")[1];
      callback(base64String);
    };
    reader.readAsDataURL(file);
  }
  function handleClear() {
    setDocDetails({
      Name: '',
      Type: 'Select',
      File: {}
    })
  }
  function navigateToCompany() {
    setCompanyTab(true);
    setFinanceTab(false);
    setHrTab(false);
    setCurrentPage(false);

    forceUpdate();
  }
  function navigateToFinance() {
    setCompanyTab(false);
    setFinanceTab(true);
    setHrTab(false);
    setCurrentPage(false);

    forceUpdate();
  }
  function navigateToHr() {
    setCompanyTab(false);
    setFinanceTab(false);
    setHrTab(true);
    setCurrentPage(false);

    forceUpdate();
  }
  function navigateToDefaultPage(e) {
    setCompanyTab(false);
    setFinanceTab(false);
    setHrTab(false);
    setCurrentPage(true);

    forceUpdate();
  }
  //#endregion
  return (
    <div className='documetantionDiv'>
      <div className='headinDiv'>
        <h3 className='heading'>Documents</h3>
      </div>
      <div className='tabDiv'>
        <div value={CurrentPage} className='doctypeDiv' onClick={navigateToDefaultPage} >ALL</div>
        <div value={CompanyTab} className='doctypeDiv' onClick={navigateToCompany} >Company</div>
        <div value={FinanceTab} className='doctypeDiv' onClick={navigateToFinance} >Finance</div>
        <div value={HrTab} className='doctypeDiv' onClick={navigateToHr} >HR</div>
      </div>

      {CurrentPage ? (
        <div>
          <Card className='descriptionCard'>
            <div className='DocNameIputDiv'>
              <Input className='DocNameIput' type='text' id='docName' placeholder='Document Name' onChange={getDocDetailsFromUser} value={DocDetails.Name} />
              <select className='selcetDocType' id='selcetDocType' onChange={getDocDetailsFromUser} value={DocDetails.Type}>
                <option value='Select'>Type/Category</option>
                <option value='Company'>Company</option>
                <option value='Finance'>Finance</option>
                <option value='HR'>HR</option>
              </select>
              <div className ='uploadDocFile'>
                <Input type="file" id="docFile" className="inputFile" accept=".pdf" onChange={getDocDetailsFromUser} />
              </div>
             
            </div>
            <div className='uploadDocIconDiv'>
              <FaUpload id='upload' className='uploadDocIcon' onClick={uploadDocuments} />
            </div>
          </Card>
          {(CompanyDocs === []) ? <Card className='erroCard'>No Available Data.!</Card> :
            <div className='mapCardDocDiv'>
              {CompanyDocs.map((doc,index) => {
                if (!doc) {
                  return (
                    <Card className='erroCard'>When you upload a Document, will appear here.!</Card>
                  )
                } else {
                  return (
                    <Card className='mapDocCard'>
                      <div className='mappedDocName'>{doc.Name}</div>
                      <div className='mappedDocType'>{doc.Type}</div>
                      <div className='downloadIconDiv'>
                        {/* <Base64Downloader base64={Base64} downloadName="pdfDownload" onClick={downloadDocument}>download </Base64Downloader> */}
                        <FaDownload id={index} className='downloadIcon' onClick={downloadDocument} />
                      </div>
                    </Card>
                  )
                }
              })}
            </div>}
        </div>
      ) : null}

      {CompanyTab ? (
        <div>
          {(CompanyDocs === []) ? <Card className='erroCard'>No Available Data.!</Card> :
            <div className='mapCardDocDiv'>
              {CompanyDocs.map((doc) => {
                if (doc.Type === 'Company') { // Filter documents by type 'Hr'
                  return (
                    <Card className='mapDocCard'>
                      <div className='mappedDocName'>{doc.Name}</div>
                      <div className='mappedDocType'>{doc.Type}</div>
                      <div className='downloadIconDiv'>
                        <FaDownload id='download' className='downloadIcon' onClick={downloadDocument} />
                      </div>
                    </Card>
                  );
                } else {
                  return null; 
                }
              })}
            </div>}
        </div>
      ) : null}
      
      {FinanceTab ? (
        <div>
          {(CompanyDocs === []) ? <Card className='erroCard'>No Available Data.!</Card> :
            <div className='mapCardDocDiv'>
              {CompanyDocs.map((doc) => {
                if (doc.Type === 'Finance') { // Filter documents by type 'Hr'
                  return (
                    <Card className='mapDocCard'>
                      <div className='mappedDocName'>{doc.Name}</div>
                      <div className='mappedDocType'>{doc.Type}</div>
                      <div className='downloadIconDiv'>
                        <FaDownload id='download' className='downloadIcon' onClick={downloadDocument} />
                      </div>
                    </Card>
                  );
                } else {
                  return null; 
                }
              })}
            </div>}
        </div>
      ) : null}

      {HrTab ? (
        <div>
          {(CompanyDocs === []) ? <Card className='erroCard'>No Available Data.!</Card> :
            <div className='mapCardDocDiv'>
              {CompanyDocs.map((doc) => {
                if (doc.Type === 'HR') { 
                  return (
                    <Card className='mapDocCard'>
                      <div className='mappedDocName'>{doc.Name}</div>
                      <div className='mappedDocType'>{doc.Type}</div>
                      <div className='downloadIconDiv'>
                        <FaDownload id='download' className='downloadIcon' onClick={downloadDocument} />
                      </div>
                    </Card>
                  );
                } else {
                  return null; 
                }
              })}
            </div>}
        </div>
      ) : null}
    </div>
  );
}

export default Doc;