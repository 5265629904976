import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { Input, View, Button, Card, DoughnutChart, } from '../../../components';
var mobile = require('is-mobile');

const Clients = observer(() => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    if (mobile()) {
        return (
            <div>

            </div>
        );

    } else {
        return (
            <div className='container' style={{ width: '100%', height: '95%' }} >
                <h1>Clients</h1>
            </div>
        )
    }
})

export default Clients;