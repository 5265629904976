import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

function PieChartFinance(passedPieData) {
  let finalLabels = [];
  const letters = '0123456789ABCDEF';
  function generateColors(arrayLength) {
    const colors = [];

    for (let i = 0; i < arrayLength; i++) {
      const color = getRandomColor();
      colors.push(color);
    }
    return colors;
  }

  function getRandomColor() {
    let color = '#';

    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  if (passedPieData.pieData.length>0 ){
    for (let i = 0; i < passedPieData.pieLabel.length; i++) {
      finalLabels.push(passedPieData.pieLabel[i] + ": R" + passedPieData.pieData[i].toString()|| 0);
    }
  }else{
    finalLabels.push('no data');
  }



  const opEpxArr = passedPieData.pieLabel;
  const colorsArray = generateColors(opEpxArr.length);
  const data = {
    labels: finalLabels,
    color:'white',
    datasets: [
      {
        data: passedPieData.pieData,
        backgroundColor: colorsArray,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
      title: {
        display: true,
        text: passedPieData.type,
        color: 'Black',
      },
    },
  };

  return (
    <div>
      <Pie data={data} options={options} />
    </div>
  );
}

export default PieChartFinance;