import React, { useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import './style.css';
import { useNavigate } from 'react-router-dom';
import CloseCard from '../../../assets/images/closeCard.png';
import OpenClient from '../../../assets/images/right-arrow.png';
import UploadIcon from '../../../assets/images/upload.png';
import { Input, View, Button, Card, DoughnutChart, } from '../../../components';
var mobile = require('is-mobile');

const SLAs = observer(({ ClientID, back }) => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [SelectedClient, setSelectedClient] = useState({});
    const fileInputRef = useRef(null);
    const [SLAs, setSLAs] = useState([]);
    const [SelectedSLA, setSelectedSLA] = useState({});
    const [UpdateSlA, setUpdateSLA] = useState(false);
    const [UpdateQuote, setUpdateQuote] = useState(false);
    const [AddSlA, setAddSLA] = useState(false);
    const [AddQuote, setAddQuote] = useState(false);
    const [SelectedPdf, setSelectedPdf] = useState({});
    const [SLA, setSLA] = useState({
        Name: '',
        StartDate: new Date(),
        EndDate: new Date(),
        Signed: false,
        DocumentName: ''
    });
    const [Quote, setQuote] = useState({
        Name: '',
        Comments: '',
        ExpiryDate: new Date(),
        Status: ''
    });

    useEffect(() => {
        getClient();
    }, []);
    function getClient() {
        let id = ClientID;
        store.setLoading(true);
        store.getClientByID(id).then((client) => {
            if (client) {
                setSelectedClient(client);
                setSLAs(client.SLAs);
                store.setLoading(false);
            } else {
                window.alert('Could not find client');
                store.setLoading(false);
            }
        })
    }
    function addNewQuoteClicked() {
        setAddQuote(true);
    }
    function addNewSLA() {
        setAddSLA(true)
    }
    function backClicked() {
        back('Alkemia')
    }
    function closeAddUser() {
        setAddSLA(false);
        setAddQuote(false);
        setUpdateQuote(false);
        setUpdateSLA(false);
    }
    function slaClicked(index) {
        let sla = SLAs[index];
        setSelectedSLA(sla);
        setUpdateSLA(true);
    }
    function QuotesChanged(e) {
        let quote = Quote;
        if (e.currentTarget.id === 'QuoteName') {
            quote.Name = e.target.value;
        }
        if (e.currentTarget.id === 'QuoteExpiryDate') {
            quote.ExpiryDate = e.target.value;
        }
        setQuote(quote);
        forceUpdate();
    }
    function SlaChanged(e) {
        let sla = SLA;

        if (e.currentTarget.id === 'SLAName') {
            sla.Name = e.target.value;
        }
        if (e.currentTarget.id === 'SLAStartDate') {
            sla.StartDate = e.target.value;
        }
        if (e.currentTarget.id === 'SLAEndDate') {
            sla.EndDate = e.target.value;
        }
        setSLA(sla);
        forceUpdate();
    }
    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setSelectedPdf(selectedFile);
    };
    function createSLAClicked() {
        let id = SelectedClient._id
        let client = SelectedClient;
        if (SLA.Name === '') {
            window.alert('SLA name is required.');
            return
        } else {
            client.SLAs.push(SLA);
        }
        forceUpdate();
        store.setLoading(true);
        store.updateClientById(id, client).then((ret) => {
            if (ret) {
                setSLA({
                    Name: '',
                    StartDate: new Date(),
                    EndDate: new Date(),
                    Signed: false,
                    DocumentName: ''
                });
                setAddSLA(false);
                setAddQuote(false);
                store.setLoading(false);
            } else {
                window.alert('Error adding new Sla');
                store.setLoading(false);
            }
        })
    }
    function createQuoteClicked() {
        let id = SelectedClient._id
        let client = SelectedClient;
        if (Quote.Name === '') {
            window.alert('Quotes name is required.');
            return
        } else {
            client.Quotes.push(Quote);
        }
        forceUpdate();
        store.setLoading(true);
        store.updateClientById(id, client).then((ret) => {
            if (ret) {
                setQuote({
                    Name: '',
                    Comments: '',
                    ExpiryDate: new Date(),
                    Status: ''
                });
                setAddSLA(false);
                setAddQuote(false);
                store.setLoading(false);
            } else {
                window.alert('Error adding new quotes');
                store.setLoading(false);
            }
        })
    }
    if (mobile()) {
        return (
            <div>

            </div>
        );
    } else {
        return (
            <div className='container' style={{ width: '100%', height: '95%', marginTop: '7%' }} >
                <div className='ClientToDiv'>
                    <Button className='backButton' onClick={backClicked}><img />Back</Button>
                    <Button className='addNewQuoteSla' style={{ marginLeft: '40%' }} onClick={addNewQuoteClicked}>New Quote</Button>
                    <Button className='addNewQuoteSla' style={{ marginLeft: '2%' }} onClick={addNewSLA}>New SLA</Button>
                    <h2 style={{ marginTop: '5%', marginLeft: '2%' }}>{SelectedClient.ClientName}</h2>
                </div>
                <div style={{ width: '100%', marginLeft: '2%', overflow: 'scroll', height: '94%' }}>
                    {SLAs.map((item, index) => {
                        return (
                            <Card className='quotesCards' onClick={() => slaClicked(index)}>
                                <h3 style={{ width: '90%' }}>{item.Name}</h3><p style={{ width: '5%' }}>Status {item.Status || ''}</p><img src={OpenClient} />
                            </Card>
                        )
                    })}
                </div>
                {AddSlA ? (
                    <Card className='addForecast'>
                        <img className='closeAddActualsCard' onClick={closeAddUser} src={CloseCard} />
                        <h3>New SLA</h3>
                        <Input className='newQuoteInputs' placeholder='SLA Name' onChange={SlaChanged} id='SLAName' />
                        <div style={{ display: 'flex', flexDirection: 'row', width: '90%' }}>
                            <h3 style={{ width: '15%' }}>Start Date:</h3><Input className='NewQuoteDateInputs' placeholder='SLA Start Date' type='date' onChange={SlaChanged} id='SLAStartDate' /><h3 style={{ marginLeft: '2%', width: '15%' }}>End Date:</h3><Input className='NewQuoteDateInputs' placeholder='SLA End Date' type='date' onChange={SlaChanged} id='SLAEndDate' />
                        </div>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        <Button className='newQuoteInputs' style={{ backgroundColor: 'rgb(143, 202, 114)' }} placeholder='Upload' onClick={handleButtonClick}>Upload <img src={UploadIcon} /></Button>
                        <Button className='createQuoteBtn' onClick={createSLAClicked}>Create</Button>
                    </Card>
                ) : AddQuote ? <Card className='addForecast'>
                    <img className='closeAddActualsCard' onClick={closeAddUser} src={CloseCard} />
                    <h3>New Quote</h3>
                    <Input className='newQuoteInputs' placeholder='Quote Name' onChange={QuotesChanged} id='QuoteName' />
                    <div style={{ display: 'flex', flexDirection: 'row', width: '90%' }}>
                        <h3 style={{ width: '18%' }}>Expiry Date :</h3>
                        <Input className='newQuoteInputs' style={{ width: '82%', marginLeft: '2%' }} placeholder='Quote Expiry Date' type='date' onChange={QuotesChanged} id='QuoteExpiryDate' />
                    </div>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    <Button
                        className='newQuoteInputs'
                        style={{ backgroundColor: 'rgb(143, 202, 114)' }}
                        placeholder='Upload'
                        onClick={handleButtonClick}
                    >
                        Upload <img src={UploadIcon} alt="Upload Icon" />
                    </Button>
                    <Button className='createQuoteBtn' onClick={createQuoteClicked}>Create</Button>
                </Card> : null}
                {UpdateQuote ? (
                    <Card className='updateQuotesAndSlaCard'>
                        <img className='closeViewQuoteCard' onClick={closeAddUser} src={CloseCard} />
                        {/* <h3>{SelectedQuote.Name}</h3>
                        <p>Expiry Date: <b>{SelectedQuote.ExpiryDate || ''}</b></p>
                        <p>Expiry Date: <b>{SelectedQuote.Status || 'No Status'}</b></p> */}
                        {SelectedPdf && SelectedPdf.type && (
                            <embed src={URL.createObjectURL(SelectedPdf)} type="application/pdf" width="100%" height="700px" />
                        )}
                        <Button className='createQuoteBtn'>View Quote</Button>
                    </Card>
                ) : UpdateSlA ? <Card className='updateQuotesAndSlaCard'>
                    <img className='closeViewQuoteCard' onClick={closeAddUser} src={CloseCard} />
                    <h3>{SelectedSLA.Name}</h3>
                    <p>Start Date :<b>{SelectedSLA.StartDate}</b></p>
                    <p>End Date :<b>{SelectedSLA.EndDate}</b></p>
                    {SelectedPdf && SelectedPdf.type && (
                        <embed src={URL.createObjectURL(SelectedPdf)} type="application/pdf" width="100%" height="700px" />
                    )}
                    <Button className='createQuoteBtn'>Download SLA</Button>
                </Card> : null}

            </div>
        )
    }
})

export default SLAs;