import React from 'react';
import './style.css';
import * as FontAwesome from 'react-icons/fa';
const Icon = props => {
    const { iconName, size, color } = props;
    const icon = React.createElement(FontAwesome[iconName]);
    return <div style={{ fontSize: size, color: color }}>{icon}</div>;
  };

function MobileTabBarItem({children, className, id, onClick, numItems=1, icon, description, isActive, style={}}){
    let customStyle = style;
    customStyle.maxWidth = `${100/numItems}%`;
    return (
        <div className={`mobileTabBarItem ${(isActive ? 'tabBarItemActive' : '')} ${className}`} id={id} onClick={onClick} style={customStyle}>
            <Icon iconName={icon} size={25}/>
            <p className='tabBarDesc'>{description}</p>
        </div>
    )
}

export default MobileTabBarItem