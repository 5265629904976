import React from "react";
import { useState } from "react";
import "./style.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  registerables
} from 'chart.js';
import { Line } from "react-chartjs-2";
ChartJS.register(...registerables);

function Graph({ actual = [], target = [], type, period = "weekly", offSet=0 }) {
  // #region declaration
  let labels = [];
  let caption = "";
  let currency = "";
  let DisplayLabels = [];
  let pointColor = [];
  let finalAct = [];
  let prevFinalAct = [];
  let finalTar = [];
  const [SelectedData, setSelectedData] = useState(-1);
  // #endregion

  //#region Yaerly data
  const currentYear = (new Date().getFullYear()+offSet) ;
  const monthlyTargets = [];
  const actualsSum = [];
  const prevActualsSum = [];
  if (period === "monthly") {
    caption = "Yearly Graph";
    actual.sort((a, b) => new Date(a.Date) - new Date(b.Date));
    for (let month = 0; month < 12; month++) {
      const targetMonth = target.find((target) => new Date(target.Date).getMonth() === month && new Date(target.Date).getFullYear() === currentYear);
      const actualsMonth = actual.filter((actual) => new Date(actual.Date).getMonth() === month && new Date(actual.Date).getFullYear() === currentYear);
      const prevActualsMonth = actual.filter((actual) => new Date(actual.Date).getMonth() === month && new Date(actual.Date).getFullYear() === currentYear-1);

      const label = `${month + 1}/${currentYear}`;
    
      labels.push(label);
  
      if (targetMonth) {
        monthlyTargets.push(targetMonth[type]);
      } else if (monthlyTargets.length > 0) {
        monthlyTargets.push(monthlyTargets[monthlyTargets.length - 1]);
      } else {
        monthlyTargets.push(0);
      }
  
      const actualsSumMonth = actualsMonth.reduce((sum, actual) => sum + (actual[type] || 0), 0);
      actualsSum.push(actualsSumMonth);

      const prevActualsSumMonth = prevActualsMonth.reduce((sum, actual) => sum + (actual[type] || 0), 0);
      prevActualsSum.push(prevActualsSumMonth);
    }
    DisplayLabels = labels;
    finalTar = monthlyTargets
    finalAct = actualsSum;
    prevFinalAct = prevActualsSum

  }
  //#endregion

  // #region Monthly data
  if (period === "weekly") {
    caption = "Monthly Graph";
    actual.sort((a, b) => new Date(a.Date) - new Date(b.Date));

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth()+offSet;
    const weeklyTargets = [];
    const actualsByWeek = [];
    const prevActualsByWeek = [];

    const getStartOfWeek = (year, month, week) => {
      const firstDayOfMonth = new Date(year, month, 1);
      const dayOffset = firstDayOfMonth.getDay() === 0 ? 7 : firstDayOfMonth.getDay();
      const startDate = new Date(year, month, 1 + (week - 1) * 7 - (dayOffset - 1));
      return startDate;
    };
  
    const getWeeksInMonth = (year, month) => {
      const firstDayOfMonth = new Date(year, month, 1);
      const lastDayOfMonth = new Date(year, month + 1, 0);
      const numDays = lastDayOfMonth.getDate();
      const numWeeks = Math.ceil((numDays + firstDayOfMonth.getDay()) / 7);
      return numWeeks;
    };
  
    const numWeeks = getWeeksInMonth(currentYear, currentMonth);
  
    for (let week = 1; week <= numWeeks; week++) {
      const startDate = getStartOfWeek(currentYear, currentMonth, week);
      const endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000);
      const label = `${startDate.getMonth()+1}/${startDate.getDate()}/${currentYear}`;
      labels.push(label);
  
      const targetMonth = target.find((targe) => new Date(targe.Date).getMonth() === currentMonth);
      if (targetMonth) {
        const weeklyTarget = targetMonth[type] / numWeeks;
        weeklyTargets.push(weeklyTarget);
      } else if (weeklyTargets.length > 0) {
        weeklyTargets.push(weeklyTargets[weeklyTargets.length - 1]);
      } else {
        weeklyTargets.push(0);
      }
  
      const actualsWeek = actual.filter(
        (actu) =>
          new Date(actu.Date) >= startDate &&
          new Date(actu.Date) <= endDate &&
          new Date(actu.Date).getMonth() === currentMonth
      );
      const actualsSumWeek = actualsWeek.reduce((sum, actual) => sum + (actual[type] || 0), 0);
      actualsByWeek.push(actualsSumWeek);
      // prev
      const prevStartDate = getStartOfWeek(currentYear, currentMonth, week);
      const prevEndDate = new Date(prevStartDate.getTime() + 6 * 24 * 60 * 60 * 1000);
      const prevActualsWeek = actual.filter(
        (actu) =>
          new Date(actu.Date) >= prevStartDate &&
          new Date(actu.Date) <= prevEndDate &&
          new Date(actu.Date).getMonth() === currentMonth - 1
      );
      const prevActualsSumWeek = prevActualsWeek.reduce((sum, actual) => sum + (actual[type] || 0), 0);
      prevActualsByWeek.push(prevActualsSumWeek);
    }

    DisplayLabels = labels;
    finalTar = weeklyTargets;
    finalAct = actualsByWeek;
    prevFinalAct = prevActualsByWeek;
  }
  // #endregion
  // #region format Colours
  for (let i = 0; i < finalAct.length; i++) {
    if (type !== "OperatingExpenses") {
      if (finalAct[i] >= finalTar[i]) {
        pointColor.push("rgb(0, 255, 0)");
      } else if (finalAct[i] < finalTar[i]) {
        pointColor.push("rgb(255, 0, 0)");
      }
    } else {
      if (finalAct[i] > finalTar[i]) {
        pointColor.push("rgb(255, 0, 0)"); 
      } else if (finalAct[i] <= finalTar[i]) {
        pointColor.push("rgb(0, 255, 0)"); 
      }
    }//
  }
  // #endregion
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Actual',
        data: finalAct,
        fill: false,
        borderColor: 'rgba(143,202,114,1)',
        tension: 0.1,
        pointRadius: 6,
        color: "white",
      },
      {
        label: 'Target',
        data: finalTar,
        fill: false,
        borderColor: 'rgb(255, 0, 166)',
        borderDash: [10, 10], 
        tension: 0.1,
        backgroundColor: "black",
        pointRadius: 3,
        color: "white",
      },
      {
        label: 'Previous',
        data: prevFinalAct,
        fill: false,
        borderColor: 'rgb(49, 192, 209)',
        borderDash: [10, 10], 
        tension: 0.1,
        backgroundColor: "black",
        pointRadius: 3,
        color: "white",
      },
    ],
  };
  const options = {
    scales: {
      x: {
        ticks: {
          color: "white",
        },
        grid: {
          color: "transparent",
        },
      },
      y: {
        border: {dash: [10, 10]}, 
        ticks: {
          color: "white",
        },
        grid: {
          color: "grey",
          
        },
      },
    },
    backgroundColor: "white",
    responsive: true,
    onClick: (e, element) => {
      if (element.length > 0) {
        setSelectedData(element[0]?.index);
      }
    },
    plugins: {
      legend: {
        position: "top",
      },
      fill: true,
      title: {
        display: true,
        text: type,
        color: "lightgrey",
      },
      datalabels: {
        display: false,
    },
    },
  };

  return (
    // <div style={{ width: "1260px", height: "540px", marginLeft: '5%'}}>
    //   <Line style={{ width: "1260px", height: "540px", marginLeft: '2%'}} data={data} options={options} />
    // </div>
    <div >
      <Line  data={data} options={options} />
    </div>
  );
}
export default Graph;