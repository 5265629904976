import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from "../../../stores/Store";
import { createRoutesFromElements, useNavigate } from 'react-router-dom';
import { FaShareSquare } from 'react-icons/fa'
import { Card, Input, View, Button, MobileTabBarItem, MobileTabBar } from '../../../components';
var isMobile = require('is-mobile');


const CreateCourse = observer(({ CurrentPage, navClicked }) => {
    //#region UseSates
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Courses, setCourses] = useState([]);
    const [CourseName, setCourseName] = useState('');
    const [Required, setRequired] = useState(false);
    const [Quiz, setQuiz] = useState([]);
    const [QuizPage, setQuizPage] = useState(false)
    const [selectedOption, setSelectedOption] = useState("");
    const [Questions, setQuestions] = useState({
        Question: '',
        OptionA: '',
        OptionB: '',
        OptionC: '',
        OptionD: '',
    })
    const [CourseContent, setCourseContent] = useState({});
    const [Users, setUsers] = useState({})
    const [Qs, setQs] = useState(false)
    const [Content, setContent] = useState({
        Type: '',
        Content: ''
    });

    //#endregion

    //#regionfunctions
    useEffect(() => {
        getAllCourses();
        getAllUsers();
    }, []);

    function clearData() {
        setCourseName('');
        setContent({
            Type: '',
            Content: '',
        });
        setRequired(false);

        forceUpdate();
    };
    function getAllUsers() {
        store.setLoading(true);
        store.getUsers().then((ret) => {
            const emails = ret.map((user) => user.Email);
            setUsers(emails);
            store.setLoading(false);
        });
    };
    function courseNameChanged(e) {
        setCourseName(e.target.value);
    };
    function getAllCourses() {
        store.setLoading(true);
        store.getCourse().then((ret) => {
            setCourses(ret);
            store.setLoading(false);
        });
        forceUpdate();
    };
    function handleTypeChange(e) {
        const newType = e.target.value;
        setContent(prevContent => ({
            ...prevContent,
            Type: newType
        }));

        if (newType === 'Quiz') {
            setQuizPage(true);
            setQs(true);
        } else {
            setQuizPage(false);
            setQs(false);
        }
    };
    function handleCheckboxChange(checked) {
        setRequired(checked);
    };
    function handleChange(event) {
        const checked = event.target.checked;
        handleCheckboxChange(checked);
    };
    function handleContentChange(e) {
        const newContent = e.target.value;
        setContent(prevContent => ({
            ...prevContent,
            Content: newContent
        }));
    };
    function createCourseClicked() {
        if (CourseName === '') {
            window.alert('Please enter your Course Name');
            return
        };
        if (Content.Type === '') {
            window.alert('Please select your Content Type');
            return
        };
        if (Content.UserContentType === '') {
            window.alert('Please enter your User Content Type');
            return
        };

        let data = {
            Name: CourseName,
            Content: {
                Type: Content.Type,
                Content: Content.Content
            },
            Required: Required,
            Quiz: Quiz,
            RequiredBy: Users
        };
        store.setLoading(true);
        store.createCourse(data).then((ret) => {
            if (ret) {
                window.alert('Course created successfully');
                store.setLoading(false);
                clearData();
            }
        });
    };
    function submitQuiz() {
        ;
        if (!Questions.Question.trim()) {
            window.alert('Please enter a question');
            return;
        }
        if (!selectedOption) {
            window.alert('Please select the correct answer');
            return;
        }
        const newQuestion = {
            Question: Questions.Question,
            OptionA: Questions.OptionA,
            OptionB: Questions.OptionB,
            OptionC: Questions.OptionC,
            OptionD: Questions.OptionD,
            CorrectAnswer: Questions[selectedOption],
        };



        setQuiz((prevQuiz) => [...prevQuiz, newQuestion]);
        setQuestions({
            Question: '',
            OptionA: '',
            OptionB: '',
            OptionC: '',
            OptionD: '',
        });
        setSelectedOption("");
    };
    function quizValues(e) {
        let ques = Questions

        if (e.currentTarget.id === 'Question') {
            ques.Question = e.target.value;
        }
        if (e.currentTarget.id === 'OptionA') {
            ques.OptionA = e.target.value;
        }
        if (e.currentTarget.id === 'OptionB') {
            ques.OptionB = e.target.value;
        }
        if (e.currentTarget.id === 'OptionC') {
            ques.OptionC = e.target.value;
        }
        if (e.currentTarget.id === 'OptionD') {
            ques.OptionD = e.target.value;
        }
        setQuestions(ques);
        forceUpdate();
    };
    function handleCancel() {
        navClicked('AllCourses')
    };
    function handleSelectChange(e) {
        setSelectedOption(e.target.value);
    };
    if (!isMobile()) {
        return (
            <div>
                <div className='CourseDetails'>
                    <Input className='courseInputName' onChange={courseNameChanged} value={CourseName} placeholder='Course Name' />
                    <Button className='newCourseCancelBtn' id={CurrentPage} onClick={handleCancel}>Cancel</Button>
                    <div className='requiredField'>
                        <p>Required</p><input type='checkbox' id='required' onChange={handleChange} checked={Required} />
                    </div>
                    <select className='courseInputSelect' placeholder='Type' id='type' onChange={handleTypeChange} value={String(Content.Type)}>
                        <option>select</option>
                        <option value='Paragraph'>Paragraph</option>
                        <option value='Video'>Video</option>
                        <option value='Quiz'>Quiz</option>
                    </select>
                    <textarea className='courseInputSelectContent' placeholder='Content' id='content' onChange={handleContentChange} value={(Content.Content).toString()} />
                    <Button className='newCourseBtnAdd' onClick={createCourseClicked}>Add Course</Button>
                </div>
                <div className='Quiz'>
                    <div className='createQuizContentDiv'>
                        {QuizPage ? (
                            <div>
                                <Card className='addQuizContent'>
                                    <h3>Create Quiz</h3>
                                    <Input className="quizInputs" id='Question' value={Questions.Question} onChange={quizValues} className="quizInputs" placeholder="Questions" />
                                    <Input className="quizInputs" id='OptionA' value={Questions.OptionA} onChange={quizValues} className="quizInputs" placeholder='Option A' />
                                    <Input className="quizInputs" id='OptionB' value={Questions.OptionB} onChange={quizValues} className="quizInputs" placeholder='Option B' />
                                    <Input className="quizInputs" id='OptionC' value={Questions.OptionC} onChange={quizValues} className="quizInputs" placeholder='Option C' />
                                    <Input className="quizInputs" id='OptionD' value={Questions.OptionD} onChange={quizValues} className="quizInputs" placeholder='Option D' />
                                    <select className="creditSelect" value={selectedOption} onChange={handleSelectChange}>
                                        <option>Select correct answer</option>
                                        <option value='OptionA'>Option A</option>
                                        <option value='OptionB'>Option B</option>
                                        <option value='OptionC'>Option C</option>
                                        <option value='OptionD'>Option D</option>
                                    </select>
                                    <Button onClick={submitQuiz}>Add Question</Button>
                                </Card>
                            </div>
                        ) : null}
                    </div>
                    <div className='createQuizContentDiv'>
                        {Qs ? (
                            <Card className='addQuizContent'>
                                {Quiz.map((question, index) => (
                                    <div>
                                        <b>Question {index + 1}</b>
                                        <p>{question.Question}</p>
                                        <input type="radio" name={`option_${index}`} value="A" />
                                        {question.OptionA}
                                        <input type="radio" name={`option_${index}`} value="B" />
                                        {question.OptionB}
                                        <input type="radio" name={`option_${index}`} value="C" />
                                        {question.OptionC}
                                        <input type="radio" name={`option_${index}`} value="D" />
                                        {question.OptionD}
                                    </div>
                                ))}
                            </Card>
                        ) : null}

                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div style={{ overflow: "scroll", height: "120%" }}>
                <div className='CourseDetails'>
                    <Input className='courseInputName' onChange={courseNameChanged} value={CourseName} placeholder='Course Name' />
                    <Button className='newCourseCancelBtn' id={CurrentPage} onClick={handleCancel}>Cancel</Button>
                    <div className='requiredField'>
                        <p>Required</p><input type='checkbox' id='required' onChange={handleChange} checked={Required} />
                    </div>
                    <select className='courseInputSelect' placeholder='Type' id='type' onChange={handleTypeChange} value={String(Content.Type)}>
                        <option>select</option>
                        <option value='Paragraph'>Paragraph</option>
                        <option value='Video'>Video</option>
                        <option value='Quiz'>Quiz</option>
                    </select>
                    <textarea className='courseInputSelectContent' placeholder='Content' id='content' onChange={handleContentChange} value={(Content.Content).toString()} />
                    <Button className='newCourseBtnAdd' onClick={createCourseClicked}>Add Course</Button>
                </div>
                <div className='QuizMobile'>
                    {QuizPage ? (
                        <div>
                            <Card className='addQuizContentMobile'>
                                <h3>Create Quiz</h3>
                                <Input className="quizInputs" id='Question' value={Questions.Question} onChange={quizValues} placeholder="Questions" />
                                <Input className="quizInputs" id='OptionA' value={Questions.OptionA} onChange={quizValues} placeholder='Option A' />
                                <Input className="quizInputs" id='OptionB' value={Questions.OptionB} onChange={quizValues} placeholder='Option B' />
                                <Input className="quizInputs" id='OptionC' value={Questions.OptionC} onChange={quizValues} placeholder='Option C' />
                                <Input className="quizInputs" id='OptionD' value={Questions.OptionD} onChange={quizValues} placeholder='Option D' />
                                <select className="creditSelect" value={selectedOption} onChange={handleSelectChange}>
                                    <option>Select correct answer</option>
                                    <option value='OptionA'>Option A</option>
                                    <option value='OptionB'>Option B</option>
                                    <option value='OptionC'>Option C</option>
                                    <option value='OptionD'>Option D</option>
                                </select>
                                <Button onClick={submitQuiz}>Add Question</Button>
                            </Card>
                        </div>
                    ) : null}
                    {Qs ? (
                        <Card className='addQuizContentMobile'>
                            {Quiz.map((question, index) => (
                                <div>
                                    <b>Question {index + 1}</b>
                                    <p>{question.Question}</p>
                                    <input type="radio" name={`option_${index}`} value="A" />
                                    {question.OptionA}
                                    <input type="radio" name={`option_${index}`} value="B" />
                                    {question.OptionB}
                                    <input type="radio" name={`option_${index}`} value="C" />
                                    {question.OptionC}
                                    <input type="radio" name={`option_${index}`} value="D" />
                                    {question.OptionD}
                                </div>
                            ))}
                        </Card>
                    ) : null}

                </div>
            </div>
        )
    }
})

export default CreateCourse;