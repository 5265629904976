import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import './style.css'
import { useNavigate } from 'react-router-dom';
import { Input, View, Button, Card, DoughnutChart, } from '../../../components';
import RequestedIcon from '../../../assets/images/departmentInProgress.png';
import InProgressIcon from '../../../assets/images/In_Progress@2x.png';
import OnHoldIcon from '../../../assets/images/onHold.png';
import DeclinedIcon from '../../../assets/images/declinedTask.png';
import ArchivedLogo from '../../../assets/images/folder.png';
import CompletedIcon from '../../../assets/images/checked.png'
import AionsCoin from '../../../assets/images/AionsCoin.png';
import CloseCard from '../../../assets/images/closeCard.png';
var mobile = require('is-mobile');

const Task = observer(() => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Jobs, setJobs] = useState([]);
    const [Companies, setCompanies] = useState([]);
    const [CreditsArray, setCreditsArray] = useState([]);
    const [RequestedJobs, setRequestedJobs] = useState([]);
    const [OnHoldJobs, setOnHoldJobs] = useState([]);
    const [DeclinedJobs, setDeclinedJobs] = useState([]);
    const [InProgressJobs, setInProgressJobs] = useState([]);
    const [CompletedJobs, setCompletedJobs] = useState([]);
    const [ArchivedJobs, setArchivedJobs] = useState(false);
    const [ArchivedTasks, setArchivedTasks] = useState([]);
    const [SelectedJob, setSelectedJob] = useState({});
    const [ShowData, setShowData] = useState(false);
    const [JobClicked, setJobClicked] = useState(false);
    const [ActualDate, setActualDate] = useState('');
    const [RejectedJobID, setRejectedJobID] = useState('');
    const [Decision, setDecision] = useState('');
    const [CommentsArray, setCommentsArray] = useState([]);
    const [ShowRequestedJobs, setShowRequestedJobs] = useState(false);
    const [Comments, setComments] = useState([]);
    const [CommentObj, setCommentObj] = useState({
        Comment: '',
        Date: new Date(),
        Email: ''
    })
    useEffect(() => {
        getJobs();
    }, []);
    function getJobs() {
        store.setLoading(true);
        store.getJob().then((ret) => {
            if (typeof ret === "string" || !ret) {
                setJobs([]);
                store.setLoading(false);
                return;
            }
            let allJobs = ret;
            let designJobs = allJobs.filter((a) => a.Department === store.user.Role);
            setJobs(designJobs);
            let inProgress = [];
            let Declined = [];
            let Completed = [];
            let Requested = [];
            let Archived = [];

            designJobs.forEach((item) => {
                if (item.Status === 'Declined peacefully.') {
                    Declined.push(item);
                } else if (item.Status === 'Completed') {
                    Completed.push(item);
                } else if (item.Status === 'inProgress') {
                    inProgress.push(item);
                } else if (item.Status === 'requested') {
                    Requested.push(item);
                } else if (item.Status === 'Completed') {
                    if (item.Archived === true) {
                        Archived.push(item);
                    }
                }
            });
            setDeclinedJobs(Declined);
            setCompletedJobs(Completed);
            setInProgressJobs(inProgress);
            setRequestedJobs(Requested);
            setArchivedTasks(Archived);
            forceUpdate();
            store.setLoading(false);
            store.getCompanies().then((data) => {
                let compsCreds = [];
                for (let i = 0; i < data.length; i++) {
                    let company = data[i];
                    let date = new Date();
                    let currentMonth = date.getMonth();
                    let currentYear = date.getFullYear();
                    let creds = company.Credits;
                    let currentMonthCredits = creds.find((credit) => credit.Month === currentMonth && credit.Year === currentYear);

                    let designTask = designJobs.filter((item) =>
                        item.Department === 'Design' &&
                        new Date(item.ReqDate).getMonth() === date.getMonth() &&
                        item.Client === company.Name
                    );
                    let devTasks = designJobs.filter((item) =>
                        item.Department === 'Dev' &&
                        new Date(item.ReqDate).getMonth() === date.getMonth() &&
                        item.Client === company.Name
                    );
                    let salesTasks = designJobs.filter((item) =>
                        item.Department === 'Sales' &&
                        new Date(item.ReqDate).getMonth() === date.getMonth() &&
                        item.Client === company.Name
                    );
                    let totDevHrs = devTasks.reduce((total, task) => total + (task.ActualHours || 0), 0);
                    let totDesignHrs = designTask.reduce((total, task) => total + (task.ActualHours || 0), 0);
                    let totSalesHrs = salesTasks.reduce((total, task) => total + (task.ActualHours || 0), 0);
                    let Credits = {
                        Sales: currentMonthCredits && currentMonthCredits.Departments && currentMonthCredits.Departments.Sales - totSalesHrs || 0,
                        Dev: currentMonthCredits && currentMonthCredits.Departments && currentMonthCredits.Departments.Dev - totDevHrs || 0,
                        Design: currentMonthCredits && currentMonthCredits.Departments && currentMonthCredits.Departments.Design - totDesignHrs || 0,
                    }
                    let tmpObj = {
                        CompanyName: company.Name,
                        RemainingCredits: Credits
                    }
                    compsCreds.push(tmpObj)
                }
                const currentMonth = new Date().getMonth();
                const currentYear = new Date().getFullYear();
                const creditArray = [];
                const usedCredsObj = {
                    Sales: 0,
                    Dev: 0,
                    Design: 0,
                    Name: ''
                };
                const clientsData = {};
                for (let i = 0; i < designJobs.length; i++) {
                    let jb = designJobs[i];
                    let usedCredits = 0;
                    let estimatedHrs = jb.EstimatedHours || 0;
                    let actualHrs = jb.ActualHours || 0;
                    let departmentString = jb.Department;
                    let client = jb.Client || '';

                    if (!clientsData[client]) {
                        clientsData[client] = { ...usedCredsObj, Name: client };
                    }

                    if (actualHrs === 0) {
                        usedCredits = estimatedHrs;
                    } else {
                        usedCredits = actualHrs;
                    }

                    clientsData[client][departmentString] += usedCredits;
                }
                const allClientsData = Object.values(clientsData);
                for (let i = 0; i < data.length; i++) {
                    let company = data[i];
                    let companyName = company.Name;
                    let companyCredits = [...(company.Credits || [])];
                    let currMonthData = companyCredits.find(entry => entry.Month === currentMonth);


                    if (!currMonthData) {
                        currMonthData = {
                            Month: currentMonth,
                            Year: currentYear,
                            Departments: {
                                Sales: 0,
                                Dev: 0,
                                Design: 0,
                            }
                        };
                        companyCredits.push(currMonthData);
                    }
                    let matchingClientData = allClientsData.find(clientData => clientData.Name === companyName);

                    if (matchingClientData) {
                        currMonthData.Departments.Sales -= (matchingClientData.Sales || 0);
                        currMonthData.Departments.Dev -= (matchingClientData.Dev || 0);
                        currMonthData.Departments.Design -= (matchingClientData.Design || 0);

                        creditArray.push({
                            CompanyName: companyName,
                            RemainingCredits: currMonthData.Departments
                        });
                    }
                }
                for (let k = 0; k < designJobs.length; k++) {
                    let numCreditObj = compsCreds.filter(c => c.CompanyName === designJobs[k].Client);
                    let totalRemCredits = numCreditObj[0].RemainingCredits[designJobs[k].Department];
                    designJobs[k].RemCreds = totalRemCredits;
                }
                setJobs(designJobs);
                setCreditsArray(compsCreds);
                store.setLoading(false);
            });
        });
    }
    function viewJobDetails(e) {
        setSelectedJob(Jobs[Number(e.currentTarget.id)]);
        var tmpComments = [];
        let key = Object.keys(Jobs[Number(e.currentTarget.id)]).filter(item => item === 'Comment')
        if (key !== '') {
            tmpComments = Jobs[Number(e.currentTarget.id)].Comment || [];
        }
        setCommentsArray(tmpComments);
        setShowData(true);
    }
    function unArchiveTasks(e) {
        let task = Jobs[Number(e.currentTarget.id)]
        store.setLoading(true);
        task.Archived = false;
        store.updateJobById(task).then((ret) => {
            if (ret === 'error') {
                forceUpdate();
                store.setLoading(false);
            } else {
                setArchivedJobs(false);
                setSelectedJob({});
                setShowData(false);
                setActualDate(new Date());
                forceUpdate();
                store.setLoading(false);
            }
        });
    }
    function closeAddTaskData() {
        setShowData(false);
    }
    function formatDate(dateString) {
        const options = { day: "numeric", month: "short", year: "numeric" };
        const dateObj = new Date(dateString);
        return dateObj.toLocaleDateString("en-US", options);
    }
    function acceptJob(e) {
        let decision = Decision;
        let index = 0;
        for (let i = 0; i < Jobs.length; i++) {
            if (e.currentTarget.id === Jobs[i]._id) {
                Jobs[i].Status = 'pending'
                Jobs[i].FeedBack = 'We are working on it.'
                Jobs[i].DepartmentComment = Comment;
                Jobs[i].ClientEmail = Jobs[i].ClientEmail;
                index = i
            }
            decision = 'Accepted'
        }
        let task = Jobs[index];
        // if (task.EstimatedHours === null || task.EstimatedHours === undefined || task.EstimatedHours === '' || task.EstimatedHours === 0){
        //     window.alert('Please assign estimated hours for this task.');
        //     return
        // }
        // if (task.ActualDueDate === null || task.ActualDueDate === undefined || task.ActualDueDate === ''){
        //     window.alert('Please assign estimated due date for this task.');
        //     return
        // }
        // if (Jobs[index].EstimatedHours === '' || Jobs[index].EstimatedHours === null || Jobs[index].EstimatedHours === undefined) {
        //     window.alert('Please assign Estimated Hours!');
        //     return
        // }

        store.setLoading(true)
        store.updateJobById(Jobs[index]).then((ret) => {
            if (ret === 'error') {
                window.alert('There was an error updating this job.');
                store.setLoading(false);
            } else {
                setSelectedJob({});
                setShowData(false);
                forceUpdate();
                store.setLoading(false)
            }
        })
        setDecision(decision)
        setJobClicked(false)
        forceUpdate();
    }
    function declineJob(e) {
        let decision = 'Declined';
        let index = 0;
        let rejectedJob = e.currentTarget.id;
        for (let i = 0; i < Jobs.length; i++) {
            let jobId = Jobs[i]._id;
            if (e.currentTarget.id === jobId) {
                Jobs[i].Status = 'Declined peacefully.';
                index = i;
            }
            decision = 'Declined';
        }
        store.setLoading(true);
        store.updateJobById(Jobs[index]).then((ret) => {
            if (ret === 'error') {
                window.alert('There was an error updating this job.');
            } else {
                setSelectedJob({});
                setActualDate(new Date());
                setShowData(false);
            }
            store.setLoading(false);
        }).catch((error) => {
            console.error('Error updating job:', error);
            window.alert('There was an error updating this job.');
            store.setLoading(false);
        });

        setRejectedJobID(rejectedJob);
        setDecision(decision);
        forceUpdate();
    }
    function commentChange(e) {
        let commentObj = CommentObj
        commentObj.Comment = e.currentTarget.value
        commentObj.Email = store.user.Email
        setCommentObj(commentObj)
        forceUpdate()
    }
    function selectedJobChanged(e) {
        let job = SelectedJob;
        if (e.currentTarget.id === 'EstimatedHours') {
            job.EstimatedHours = Number(e.target.value);
        }
        if (e.currentTarget.id === 'ActualHours') {
            job.ActualHours = Number(e.target.value);
        }
        if (e.currentTarget.id === 'Status') {
            job.Status = e.target.value;
        }
        if (e.currentTarget.id === 'actualDate') {
            job.ActualDueDate = e.target.value;
        }
        setSelectedJob(job);

        forceUpdate();
    }
    function updateJob() {
        if (SelectedJob.ActualDueDate === '') {
            window.alert('Please assign the actual due date for the request.');
            return;
        }

        store.setLoading(true);
        store.updateJobById(SelectedJob).then((ret) => {
            if (ret === 'error') {
                window.alert('There was an error updating this job.');
                forceUpdate();
                store.setLoading(false);
            } else {
                window.alert('Job updated successfully.');
                setSelectedJob({});
                setShowData(false);
                setActualDate(new Date());

                forceUpdate();
                store.setLoading(false);
            }
        });
    }
    function getActDate(e) {
        let actDate = ActualDate;

        if (e.currentTarget.id = 'actualDate') {
            actDate = e.target.value
        }
        setActualDate(actDate);
        forceUpdate();
    }
    function sendComment() {
        if (CommentObj.Comment === "") {
            window.alert("Please enter a comment")
        } else {
            let id = SelectedJob._id
            let commentArr = Comments
            commentArr.push(CommentObj)
            let data = {
                Comment: commentArr
            }
            store.setLoading(true);
            store.UpdateJobById(id, data).then((ret) => {
                window.alert("Comment sent.")
                store.setLoading(false);
                setCommentObj({
                    Comment: '',
                    Date: new Date(),
                    Email: ''
                })
            })
        }
    }
    function archivedTasks() {
        setArchivedJobs(true);
    }
    function closeArchivedTasks() {
        setSelectedJob({});
        setArchivedJobs(false);
        forceUpdate();
    }
    function archiveTask() {
        let confirmed = window.confirm('Are you sure you want to archive this task?');
        if (!confirmed){
            return
        }
        let task = SelectedJob;
        store.setLoading(true);
        task.Archived = true;
        store.updateJobById(task).then((ret) => {
            if (ret === 'error') {
                forceUpdate();
                store.setLoading(false);
            } else {
                setSelectedJob({});
                setShowData(false);
                setActualDate(new Date());
                forceUpdate();
                store.setLoading(false);
            }
        });
    };
    if (mobile()) {
        return (
            <div>

            </div>
        );

    } else {
        return (
            <div className='container' style={{ width: '100%', height: '88%', overflow: 'scroll' }} >
                <Card className='companyDashboardTasks'>
                    <div className='tasks'>
                        <p style={{ color: 'white' }}><b>Tasks</b></p>
                    </div>
                    <div className='myCards'>
                        <Card className='tasksCardsCompleted'>
                            <div>
                                <img className='inProgressImg' src={RequestedIcon} />
                                <p>Requested</p>
                            </div>
                            <card className='cardCard'></card>
                            <h1 style={{ marginLeft: '15%' }}>{RequestedJobs.length || 0}</h1>
                        </Card>
                        <Card className='tasksCardsInProgress'>
                            <div>
                                <img className='inProgressImg' src={InProgressIcon} />
                                <p>In Progress</p>
                            </div>
                            <card className='cardCard'></card>
                            <h1 style={{ marginLeft: '15%' }}>{InProgressJobs.length || 0}</h1>
                        </Card>
                        <Card className='tasksCardsTodo'>
                            <div>
                                <img className='todopngstyle' src={CompletedIcon} />
                                <p>Completed</p>
                            </div>
                            <card className='cardCard'></card>
                            <h1 style={{ marginLeft: '15%' }}>{CompletedJobs.length || 0}</h1>
                        </Card>
                        <Card className='tasksCardsNew' style={{ width: '20%' }}>
                            <div>
                                <img className='todopngstyle' src={DeclinedIcon} />
                                <p>Declined</p>
                            </div>
                            <card className='cardCard'></card>
                            <h1 style={{ marginLeft: '15%' }}>{DeclinedJobs.length || 0}</h1>
                        </Card>
                    </div>
                </Card>
                <div className='taskBottomContentDiv'>
                    <Card className='pendingTaskCard'>
                        <div className='newTaskHeader'>
                            <h2>Requested Jobs</h2>
                        </div>
                        <div className='newTaskBody'>
                            {Jobs.map((job, index) => {
                                if (job.Status === 'requested') {
                                    return (
                                        <Card key={index} className='mappedTasks' onClick={viewJobDetails} id={index}>
                                            <card className='ColorCard'>
                                                <p>       </p>
                                            </card>
                                            <h3>{job.Client}</h3>
                                            <div className='CreditsInfoDiv'>
                                                <p style={{ width: '70%', overflow: 'scroll' }}>{job.Title}</p> <img className='coins' src={AionsCoin} />
                                                <p style={{ marginTop: '-1%' }}>x{job.ActualHours || job.EstimatedHrs || 0}</p>
                                            </div>
                                        </Card>
                                    );
                                }
                            })}
                        </div>
                    </Card>
                    <Card className='pendingTaskCard'>
                        <div className='newTaskHeader'>
                            <h2>Pending</h2>
                        </div>
                        <div className='newTaskBody'>
                            {Jobs.map((job, index) => {
                                if (job.Status === 'pending' || job.Status === 'Accepted') {
                                    return (
                                        <Card key={index} className='mappedTasks' onClick={viewJobDetails} id={index}>
                                            <card className='ColorCard'>
                                                <p>       </p>
                                            </card>
                                            <h3>{job.Client}</h3>
                                            <div className='CreditsInfoDiv'>
                                                <p style={{ width: '70%', overflow: 'scroll' }}>{job.Title}</p> <img className='coins' src={AionsCoin} />
                                                <p style={{ marginTop: '-1%' }}>x{job.ActualHours || job.EstimatedHrs || 0}</p>
                                            </div>
                                        </Card>
                                    );
                                }
                            })}
                        </div>
                    </Card>
                    <Card className='pendingTaskCard'>
                        <div className='newTaskHeader'>
                            <h2>In Progress</h2>
                        </div>
                        <div className='newTaskBody'>
                            {Jobs.map((job, index) => {
                                if (job.Status === 'inProgress' || job.Status === 'Accepted') {
                                    return (
                                        <Card key={index} className='mappedTasks' onClick={viewJobDetails} id={index}>
                                            <card className='ColorCard'>
                                                <p>       </p>
                                            </card>
                                            <h3>{job.Client}</h3>
                                            <div className='CreditsInfoDiv'>
                                                <p style={{ width: '70%', overflow: 'scroll' }}>{job.Title}</p> <img className='coins' src={AionsCoin} />
                                                <p style={{ marginTop: '-1%' }}>x{job.ActualHours || job.EstimatedHrs || 0}</p>
                                            </div>
                                        </Card>
                                    );
                                }
                            })}
                        </div>
                    </Card>
                    <Card className='pendingTaskCard'>
                        <div className='newTaskHeader'>
                            <h2 style={{ width: '80%' }}>Completed</h2>
                            <div style={{ width: '20%' }}>
                                <img src={ArchivedLogo} style={{ cursor: 'pointer' }} onClick={archivedTasks} />
                            </div>
                        </div>
                        <div className='newTaskBody'>
                            {Jobs.map((job, index) => {
                                if ((job.Status === 'Completed' || job.Status === 'complete') && job.Archived !== true) {
                                    return (
                                        <Card key={index} className='mappedTasks' onClick={viewJobDetails} id={index}>
                                            <card className='ColorCard'>
                                                <p>       </p>
                                            </card>
                                            <h3>{job.Client}</h3>
                                            <div className='CreditsInfoDiv'>
                                                <p style={{ width: '70%', overflow: 'scroll' }}>{job.Title}</p> <img className='coins' src={AionsCoin} />
                                                <p style={{ marginTop: '-1%' }}>x{job.ActualHours || job.EstimatedHrs || 0}</p>
                                            </div>
                                        </Card>
                                    );
                                }
                            })}
                        </div>
                    </Card>
                    <Card className='pendingTaskCard'>
                        <div className='newTaskHeader'>
                            <h2>Declined</h2>
                        </div>
                        <div className='newTaskBody'>
                            {Jobs.map((job, index) => {
                                if (job.Status === 'Declined peacefully.') {
                                    return (
                                        <Card key={index} className='mappedTasks' onClick={viewJobDetails} id={index}>
                                            <card className='ColorCard'></card>
                                            <h3>{job.Client}</h3>
                                            <div className='CreditsInfoDiv'>
                                                <p style={{ width: '70%', overflow: 'scroll' }}>{job.Title}</p> <img className='coins' src={AionsCoin} />
                                                <p style={{ marginTop: '-1%' }}>x{job.ActualHours || job.EstimatedHrs || 0}</p>
                                            </div>
                                        </Card>
                                    );
                                }
                            })}
                        </div>
                    </Card>
                </div>
                {ArchivedJobs ? (
                    <Card style={{ display: 'flex', flexDirection: 'column' }} className='archivedTasksCard'>
                        <div style={{ width: '100%', height: '10%' }}>
                            <h2>Archived Tasks</h2>
                            <img className='closeAddActualsCard' onClick={closeArchivedTasks} src={CloseCard} />
                        </div>

                        <div style={{ width: '100%', height: '80%', overflow: 'scroll' }}>
                            {Jobs.map((job, index) => {
                                if (job.Status === 'Completed' && job.Archived === true) {
                                    return (
                                        <card key={index} className="ArchivedTaskCards">
                                            <div style={{ width: '90%' }}>
                                                <p style={{ marginLeft: '2%' }}><b>{job.Client}</b>, {job.Title}</p>
                                            </div>
                                            <card className="unArchivebtn">
                                                <p style={{ marginLeft: '10%' }} onClick={unArchiveTasks} id={index}>Unarchive</p>
                                            </card>
                                        </card>
                                    );
                                }
                            })}
                        </div>
                    </Card>
                ) : null}
                {ShowData ? (
                    <>
                        {SelectedJob.Status === 'requested' ? (
                            <>
                                {SelectedJob.Department === 'Design' ? (
                                    <Card className='viewTaskInfoCard'>
                                        <div className='AddDataCardHeaders'>
                                            <h1>{SelectedJob.Client}</h1>
                                            <img className='closeViewTaskCard' onClick={closeAddTaskData} src={CloseCard} />
                                        </div>
                                        <div className='AddDataCardBody' style={{ marginTop: '2%', overflowY: 'scroll' }}>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }} >
                                                <p style={{ width: '100%' }}>Task created on {formatDate(SelectedJob.createdAt)}</p>
                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                    <p>Title: </p>
                                                    <Card className="displayDepartmentCard" style={{ marginLeft: '4%' }}>
                                                        <p>{SelectedJob.Title}</p>
                                                    </Card>
                                                </div>
                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                    <p>Credits Remaining : </p>
                                                    <Card className="displayDepartmentCard" style={{ marginLeft: '4%' }}>
                                                        <p>{SelectedJob.RemCreds || 0}</p>
                                                    </Card>
                                                </div>
                                            </div>
                                            <div style={{ width: '100%', height: '10%', display: 'flex', flexDirection: 'row' }}>
                                                <div style={{ width: '50%', display: 'flex' }}>
                                                    <p>Department</p>
                                                    <Card className="displayDepartmentCard" style={{ marginLeft: '4%' }}>
                                                        <p>{SelectedJob.Department}</p>
                                                    </Card>
                                                </div>
                                                <div style={{ width: '50%', display: 'flex', }}>
                                                    <p>Requested due date</p>
                                                    <Card className="displayDepartmentCard" style={{ marginLeft: '4%' }}>
                                                        <p>{formatDate(SelectedJob.DueDate)}</p>
                                                    </Card>
                                                </div>
                                            </div>
                                            <hr style={{ width: '100%' }} />
                                            <div style={{ width: '100%', height: '10%', display: 'flex', flexDirection: 'row' }}>
                                                <div style={{ width: '50%' }}>
                                                    <p>Collateral required</p>
                                                    <Card className="collateralCard">
                                                        <p>{SelectedJob.DesignSpec.collateral || ''}</p>
                                                    </Card>
                                                </div>
                                                <div style={{ width: '50%' }}>
                                                    <p>PlatForm</p>
                                                    <Card className="collateralCard">
                                                        <p>{SelectedJob.DesignSpec.Platform}</p>
                                                    </Card>
                                                </div>
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: '3%' }}>
                                                <p>Description</p>
                                                <card className="descCards">
                                                    <p>{SelectedJob.Description}</p>
                                                </card>
                                                <p>Full description of elements required</p>
                                                <card className="descCards">
                                                    <p>{SelectedJob.DesignSpec.FullDescription}</p>
                                                </card>
                                                <p>Size/Dimensions</p>
                                                <card className="descCards">
                                                    <p>{SelectedJob.DesignSpec.Sizes}</p>
                                                </card>
                                                <p>Attachments Link</p>
                                                <card className="descCards">
                                                    <p>
                                                        <a href={SelectedJob.DesignSpec.Attachments} target="_blank" rel="noopener noreferrer">
                                                            {SelectedJob.DesignSpec.Attachments}
                                                        </a>
                                                    </p>
                                                </card>
                                                <p>Copy if required</p>
                                                <card className="descCards">
                                                    <p>{SelectedJob.DesignSpec.CopyIfRequired}</p>
                                                </card>
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: '3%' }}>
                                                <h1>Job {SelectedJob.Status}</h1>
                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                    <div style={{ width: '33%', display: 'flex', flexDirection: 'column' }}>
                                                        <p>Actual Due date</p>
                                                        <input className='actualAndEstimatedCredits' value={(SelectedJob.ActualDueDate)} type='date' onChange={selectedJobChanged} id='actualDate' />
                                                    </div>
                                                    <div style={{ width: '33%', display: 'flex', flexDirection: 'column' }}>
                                                        <p>Estimated credits</p>
                                                        <div style={{ display: 'flex' }}>
                                                            <input className='actualAndEstimatedCredits' value={String(SelectedJob.EstimatedHours)} onChange={selectedJobChanged} id='EstimatedHours' type='number'></input>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                        <div className='sendTaskRequestDiv'>
                                            <Button className='rejectTaskButton' id={SelectedJob._id} onClick={declineJob}>Reject</Button>
                                            <Button className='rejectTaskButtonT' id={SelectedJob._id} onClick={acceptJob}>Accept</Button>
                                        </div>
                                    </Card>
                                ) : SelectedJob.Department === 'Dev' ? (
                                    <Card className='viewTaskInfoCard'>
                                        <img className='closeViewTaskCard' onClick={closeAddTaskData} src={CloseCard} />
                                        <div className='AddDataCardHeaders'>
                                            <h1>{SelectedJob.Client}</h1>
                                            <img className='closeViewTaskCard' onClick={closeAddTaskData} src={CloseCard} />
                                        </div>
                                        <div className='AddDataCardBody' style={{ marginTop: '2%', overflowY: 'scroll' }}>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }} >
                                                <p style={{ width: '100%' }}>Task created on {formatDate(SelectedJob.createdAt)}</p>
                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                    <p>Title: </p>
                                                    <Card className="displayDepartmentCard" style={{ marginLeft: '4%' }}>
                                                        <p>{SelectedJob.Title}</p>
                                                    </Card>
                                                </div>
                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                    <p>Credits Remaining : </p>
                                                    <Card className="displayDepartmentCard" style={{ marginLeft: '4%' }}>
                                                        <p>{SelectedJob.RemCreds || 0}</p>
                                                    </Card>
                                                </div>
                                            </div>
                                            <div style={{ width: '100%', height: '10%', display: 'flex', flexDirection: 'row' }}>
                                                <div style={{ width: '50%', display: 'flex' }}>
                                                    <p>Department</p>
                                                    <Card className="displayDepartmentCard" style={{ marginLeft: '4%' }}>
                                                        <p>{SelectedJob.Department}</p>
                                                    </Card>
                                                </div>
                                                <div style={{ width: '50%', display: 'flex', }}>
                                                    <p>Requested due date</p>
                                                    <Card className="displayDepartmentCard" style={{ marginLeft: '4%' }}>
                                                        <p>{formatDate(SelectedJob.DueDate)}</p>
                                                    </Card>
                                                </div>
                                            </div>
                                            <hr style={{ width: '100%' }} />
                                            <div style={{ width: '100%', height: '10%', display: 'flex', flexDirection: 'row' }}>
                                                <div style={{ width: '50%' }}>
                                                    <p>Collateral required</p>
                                                    <Card className="collateralCard">
                                                        <p>{SelectedJob.DevSpec.Figma_Available || ''}</p>
                                                    </Card>
                                                </div>
                                                <div style={{ width: '50%' }}>
                                                    <p>PlatForm</p>
                                                    <Card className="collateralCard">
                                                        <p>{SelectedJob.DevSpec.Platform}</p>
                                                    </Card>
                                                </div>
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: '3%' }}>
                                                <p>Outcome</p>
                                                <card className="descCards">
                                                    <p>{SelectedJob.DevSpec.Outcomes}</p>
                                                </card>
                                                <p>Description</p>
                                                <card className="descCards">
                                                    <p>{SelectedJob.Description}</p>
                                                </card>
                                                <p>Type</p>
                                                <card className="descCards">
                                                    <p>{SelectedJob.DevSpec.Type}</p>
                                                </card>
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: '3%' }}>
                                                <h1>Job {SelectedJob.Status}</h1>
                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                    {/* <div style={{ width: '33%', display: 'flex', flexDirection: 'column' }}>
                                                        <p>Update status</p>
                                                        <select value={SelectedJob.Status} onChange={selectedJobChanged} id='Status' className='updateStatusSelect'>
                                                            <option>Select status</option>
                                                            <option value="inProgress">In Progress</option>
                                                            <option value='Completed'>Completed</option>
                                                        </select>
                                                    </div> */}
                                                    <div style={{ width: '33%', display: 'flex', flexDirection: 'column' }}>
                                                        <p>Estimated credits</p>
                                                        <div style={{ display: 'flex' }}>
                                                            <input className='actualAndEstimatedCredits' value={String(SelectedJob.EstimatedHours || 0)} onChange={selectedJobChanged} id='EstimatedHours' type='number'></input>
                                                            <p>X</p>
                                                            <img className='creditsCoin' src={AionsCoin} />
                                                        </div>
                                                    </div>
                                                    {/* <div style={{ width: '33%', display: 'flex', flexDirection: 'column' }}>
                                                        <p>Actual credits</p>
                                                        <div style={{ display: 'flex' }}>
                                                            <input className='actualAndEstimatedCredits' value={String(SelectedJob.ActualHours || 0)} onChange={selectedJobChanged} id='ActualHours' type='number'></input>
                                                            <p>X</p>
                                                            <img className='creditsCoin' src={AionsCoin} />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <p>Actual Due date</p>
                                                <Input className='dateInputCard' value={(SelectedJob.ActualDueDate)} type='date' onChange={selectedJobChanged} id='actualDate' />
                                            </div>
                                        </div>
                                        <div className='sendTaskRequestDiv'>
                                            <Button className='rejectTaskButton' id={SelectedJob._id} onClick={declineJob}>Reject</Button>
                                            <Button className='rejectTaskButtonT' id={SelectedJob._id} onClick={acceptJob}>Accept</Button>
                                        </div>
                                    </Card>
                                ) : null}
                            </>
                        ) : (
                            <>
                                {SelectedJob.Department === 'Design' ? (
                                    <Card className='viewTaskInfoCard'>
                                        <div className='AddDataCardHeaders'>
                                            <h1>{SelectedJob.Client}</h1>
                                            <img className='closeViewTaskCard' onClick={closeAddTaskData} src={CloseCard} />
                                        </div>
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                            <h3>{SelectedJob.Title}</h3>
                                        </div>
                                        <div className='AddDataCardBody' style={{ marginTop: '2%', overflowY: 'scroll' }}>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }} >
                                                <p style={{ width: '100%' }}>Task created on {formatDate(SelectedJob.createdAt)}</p>

                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                    <p>Credits Remaining : </p>
                                                    <Card className="displayDepartmentCard" style={{ marginLeft: '4%' }}>
                                                        <p>{SelectedJob.RemCreds || 0}</p>
                                                    </Card>
                                                </div>
                                            </div>
                                            <div style={{ width: '100%', height: '10%', display: 'flex', flexDirection: 'row' }}>
                                                <div style={{ width: '50%', display: 'flex' }}>
                                                    <p>Department</p>
                                                    <Card className="displayDepartmentCard" style={{ marginLeft: '4%' }}>
                                                        <p>{SelectedJob.Department}</p>
                                                    </Card>
                                                </div>
                                                <div style={{ width: '50%', display: 'flex', }}>
                                                    <p>Requested due date</p>
                                                    <Card className="displayDepartmentCard" style={{ marginLeft: '4%' }}>
                                                        <p>{formatDate(SelectedJob.DueDate)}</p>
                                                    </Card>
                                                </div>
                                            </div>
                                            <hr style={{ width: '100%' }} />
                                            <div style={{ width: '100%', height: '10%', display: 'flex', flexDirection: 'row' }}>
                                                <div style={{ width: '50%' }}>
                                                    <p>Collateral required</p>
                                                    <Card className="collateralCard">
                                                        <p>{SelectedJob.DesignSpec.collateral || ''}</p>
                                                    </Card>
                                                </div>
                                                <div style={{ width: '50%' }}>
                                                    <p>PlatForm</p>
                                                    <Card className="collateralCard">
                                                        <p>{SelectedJob.DesignSpec.Platform}</p>
                                                    </Card>
                                                </div>
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: '3%' }}>
                                                <p>Description</p>
                                                <card className="descCards">
                                                    <p>{SelectedJob.Description}</p>
                                                </card>
                                                <p>Full description of elements required</p>
                                                <card className="descCards">
                                                    <p>{SelectedJob.DesignSpec.FullDescription}</p>
                                                </card>
                                                <p>Size/Dimensions</p>
                                                <card className="descCards">
                                                    <p>{SelectedJob.DesignSpec.Sizes}</p>
                                                </card>
                                                <p>Attachments Link</p>
                                                <card className="descCards">
                                                    <p>
                                                        <a href={SelectedJob.DesignSpec.Attachments} target="_blank" rel="noopener noreferrer">
                                                            {SelectedJob.DesignSpec.Attachments}
                                                        </a>
                                                    </p>
                                                </card>
                                                <p>Copy if required</p>
                                                <card className="descCards">
                                                    <p>{SelectedJob.DesignSpec.CopyIfRequired}</p>
                                                </card>
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: '3%' }}>
                                                <h1>Job {SelectedJob.Status}</h1>
                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                    <div style={{ width: '33%', display: 'flex', flexDirection: 'column' }}>
                                                        <p>Update status</p>
                                                        <select value={SelectedJob.Status} onChange={selectedJobChanged} id='Status' className='updateStatusSelect'>
                                                            <option>Select status</option>
                                                            <option value="inProgress">In Progress</option>
                                                            <option value='Completed'>Completed</option>
                                                        </select>
                                                    </div>
                                                    <div style={{ width: '33%', display: 'flex', flexDirection: 'column' }}>
                                                        <p>Estimated credits</p>
                                                        <div style={{ display: 'flex' }}>
                                                            <input className='actualAndEstimatedCredits' value={String(SelectedJob.EstimatedHours || 0)} onChange={selectedJobChanged} id='EstimatedHours' type='number'></input>
                                                            <p>X</p>
                                                            <img className='creditsCoin' src={AionsCoin} />
                                                        </div>
                                                    </div>
                                                    <div style={{ width: '33%', display: 'flex', flexDirection: 'column' }}>
                                                        <p>Actual credits</p>
                                                        <div style={{ display: 'flex' }}>
                                                            <input className='actualAndEstimatedCredits' value={String(SelectedJob.ActualHours || 0)} onChange={selectedJobChanged} id='ActualHours' type='number'></input>
                                                            <p>X</p>
                                                            <img className='creditsCoin' src={AionsCoin} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <p>Actual Due date</p>
                                                <Input className='dateInputCard' value={(SelectedJob.ActualDueDate)} type='date' onChange={selectedJobChanged} id='actualDate' />
                                                <p>Comments</p>
                                                <div style={{ color: "black" }} className="CommentCards">
                                                    {!CommentsArray || CommentsArray.length === 0 ? (
                                                        <div className="CommentCard">
                                                            <h3>No comments available</h3>
                                                        </div>
                                                    ) : (
                                                        CommentsArray.map((comment, index) => (
                                                            <div className="CommentCard" key={index}>
                                                                <h3>{comment.Comment}</h3>
                                                                <p>Email: {comment.Email}</p>
                                                            </div>
                                                        ))
                                                    )}
                                                </div>
                                                <div className="CommentsComps">
                                                    <Input value={CommentObj.Comment} id="Comment" onChange={commentChange} style={{ resize: "none", borderRadius: "15px", width: "100%" }} placeholder="Comment" />
                                                    <Button className='rejectTaskButtonT' onClick={sendComment}>Send comment</Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                            {SelectedJob.Status === 'Completed' && (
                                                <Button className='archiveButton' onClick={archiveTask}>Archive</Button>
                                            )}
                                            {SelectedJob.Status !== 'Declined peacefully.' && (
                                                <Button className='rejectTaskButtonT' onClick={updateJob}>Update</Button>
                                            )}
                                        </div>
                                    </Card>
                                ) : SelectedJob.Department === 'Dev' ? (
                                    <Card className='viewTaskInfoCard'>
                                        <div className='AddDataCardHeaders'>
                                            <h1>{SelectedJob.Client}</h1>
                                            <img className='closeViewTaskCard' onClick={closeAddTaskData} src={CloseCard} />
                                        </div>
                                        <div className='AddDataCardBody' style={{ marginTop: '2%', overflowY: 'scroll' }}>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }} >
                                                <p style={{ width: '100%' }}>Task created on {formatDate(SelectedJob.createdAt)}</p>
                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                    <p>Title: </p>
                                                    <Card className="displayDepartmentCard" style={{ marginLeft: '4%' }}>
                                                        <p>{SelectedJob.Title}</p>
                                                    </Card>
                                                </div>
                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                    <p>Credits Remaining : </p>
                                                    <Card className="displayDepartmentCard" style={{ marginLeft: '4%' }}>
                                                        <p>{SelectedJob.RemCreds || 0}</p>
                                                    </Card>
                                                </div>
                                            </div>
                                            <div style={{ width: '100%', height: '10%', display: 'flex', flexDirection: 'row' }}>
                                                <div style={{ width: '50%', display: 'flex' }}>
                                                    <p>Department</p>
                                                    <Card className="displayDepartmentCard" style={{ marginLeft: '4%' }}>
                                                        <p>{SelectedJob.Department}</p>
                                                    </Card>
                                                </div>
                                                <div style={{ width: '50%', display: 'flex', }}>
                                                    <p>Requested due date</p>
                                                    <Card className="displayDepartmentCard" style={{ marginLeft: '4%' }}>
                                                        <p>{formatDate(SelectedJob.DueDate)}</p>
                                                    </Card>
                                                </div>
                                            </div>
                                            <hr style={{ width: '100%' }} />
                                            <div style={{ width: '100%', height: '10%', display: 'flex', flexDirection: 'row' }}>
                                                <div style={{ width: '50%' }}>
                                                    <p>Figma Available</p>
                                                    <Card className="collateralCard">
                                                        <p>{SelectedJob.DevSpec.Figma_Available || ''}</p>
                                                    </Card>
                                                </div>
                                                <div style={{ width: '50%' }}>
                                                    <p>PlatForm</p>
                                                    <Card className="collateralCard">
                                                        <p>{SelectedJob.DevSpec.Platform}</p>
                                                    </Card>
                                                </div>
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: '3%' }}>
                                                <p>Outcomes</p>
                                                <card className="descCards">
                                                    <p>{SelectedJob.DevSpec.Outcomes}</p>
                                                </card>
                                                <p>Description</p>
                                                <card className="descCards">
                                                    <p>{SelectedJob.Description}</p>
                                                </card>
                                                <p>Type</p>
                                                <card className="descCards">
                                                    <p>{SelectedJob.DevSpec.Type}</p>
                                                </card>
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: '3%' }}>
                                                <h1>Job {SelectedJob.Status}</h1>
                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                    <div style={{ width: '33%', display: 'flex', flexDirection: 'column' }}>
                                                        <p>Update status</p>
                                                        <select value={SelectedJob.Status} onChange={selectedJobChanged} id='Status' className='updateStatusSelect'>
                                                            <option>Select status</option>
                                                            <option value="inProgress">In Progress</option>
                                                            <option value='Completed'>Completed</option>
                                                        </select>
                                                    </div>
                                                    <div style={{ width: '33%', display: 'flex', flexDirection: 'column' }}>
                                                        <p>Estimated credits</p>
                                                        <div style={{ display: 'flex' }}>
                                                            <input className='actualAndEstimatedCredits' value={String(SelectedJob.EstimatedHours || 0)} onChange={selectedJobChanged} id='EstimatedHours' type='number'></input>
                                                            <p>X</p>
                                                            <img className='creditsCoin' src={AionsCoin} />
                                                        </div>
                                                    </div>

                                                    <div style={{ width: '33%', display: 'flex', flexDirection: 'column' }}>
                                                        <p>Actual credits</p>
                                                        <div style={{ display: 'flex' }}>
                                                            <input className='actualAndEstimatedCredits' value={String(SelectedJob.ActualHours || 0)} onChange={selectedJobChanged} id='ActualHours' type='number'></input>
                                                            <p>X</p>
                                                            <img className='creditsCoin' src={AionsCoin} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <p>Actual Due date</p>
                                                <Input className='dateInputCard' value={(SelectedJob.ActualDueDate)} type='date' onChange={selectedJobChanged} id='actualDate' />
                                                <p>Comments</p>
                                                <div style={{ color: "black" }} className="CommentCards">
                                                    {!CommentsArray || CommentsArray.length === 0 ? (
                                                        <div className="CommentCard">
                                                            <h3>No comments available</h3>
                                                        </div>
                                                    ) : (
                                                        CommentsArray.map((comment, index) => (
                                                            <div className="CommentCard" key={index}>
                                                                <h3>{comment.Comment}</h3>
                                                                <p>Email: {comment.Email}</p>
                                                            </div>
                                                        ))
                                                    )}
                                                </div>
                                                <div className="CommentsComps">
                                                    <Input value={CommentObj.Comment} id="Comment" onChange={commentChange} style={{ resize: "none", borderRadius: "15px", width: "100%" }} placeholder="Comment" />
                                                    <Button className='rejectTaskButtonT' onClick={sendComment}>Send comment</Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                            {SelectedJob.Status === 'Completed' && (
                                                <Button className='archiveButton' onClick={archiveTask}>Archive</Button>
                                            )}
                                            {SelectedJob.Status !== 'Declined peacefully.' && (
                                                <Button className='rejectTaskButtonT' onClick={updateJob}>Update</Button>
                                            )}
                                        </div>
                                    </Card>
                                ) : null}
                            </>
                        )}
                    </>
                ) : null}
            </div>
        )
    }
})
export default Task;