import React, { useState, useEffect } from 'react';
import './style.scss';
import { useStore } from '../../../stores/Store';
import { Textbox, CustomSelect } from '../../../components';
import { observer } from 'mobx-react';

const Users_ =observer(()=>{
    const store = useStore();
    const [Users, setUsers] = useState([]);
    const [EditIndex, setEditIndex] = useState(null);
    const [Roles,] = useState(['Admin', 'Company', 'Design', 'Dev', 'Handler']);
    const [GeneralArr, setGeneralArr] = useState([]);
    const [SelectedUser, setSelectedUser] = useState({
        FirstName: '',
        LastName: '',
        Company: '',
        Email: '',
        Role: '',
        Blocked: 'false',
        Permissions: {
            General: [],
            HandlerCompanies: [],
        }
    });
    const [CreateNewUser, setCreateNewUser] = useState(false);
    const [Companies, setCompanies] = useState([]);
    const [SelectedCompanies, setSelectedCompanies] = useState([]);

    useEffect(() => {
        refresh();
    }, []);

    const handleEditUser = (index) => {
        let selectedUser = Users[index];
        setEditIndex(index);

        if (!Object.keys(selectedUser).includes('Permissions')) {
            selectedUser['Permissions'] = { Genenral: [], HandlerCompanies: [] }
        };
        if (!Object.keys(selectedUser.Permissions).includes('General')) {
            selectedUser.Permissions['General'] = [];
        };
        if (!Object.keys(selectedUser.Permissions).includes('HandlerCompanies')) {
            selectedUser.Permissions['HandlerCompanies'] = [];
        };


        setGeneralArr(selectedUser.Permissions.General);
        setSelectedCompanies(selectedUser.Permissions.HandlerCompanies);
        setSelectedUser(selectedUser);
    };

    const refresh = async () => {
        await store.getUsers().then((ret) => { ret.length > 0 && setUsers(ret) });
        await store.getCompanies().then(ret => ret.length > 0 && setCompanies(ret))
    };

    const handleClose = () => {
        refresh();
        setCreateNewUser(false);
        setEditIndex(null);
        setGeneralArr([]);
        setSelectedCompanies([]);
        setSelectedUser({
            FirstName: '',
            LastName: '',
            Company: '',
            Email: '',
            Blocked: 'false',
            Role: '',
            Permissions: {
                General: [],
                HandlerCompanies: [],
            }
        })
    };

    const handleNewClick = () => {
        setCreateNewUser(true);
    };

    const handleSelectedCompanies = (id, value) => {
        let selectedComps = [...SelectedCompanies];

        if (selectedComps.includes(value)) {
            selectedComps = selectedComps.filter(item => item !== value);
        } else {
            selectedComps.push(value);
        };
        setSelectedCompanies(selectedComps);
    };

    const handleGeneralRoles = (id, value) => {
        let genRoles = [...GeneralArr];

        let processedGenRoles = genRolesFunc(value, genRoles);
        if (!processedGenRoles.includes('Handler')) {
            setSelectedCompanies([]);
        };
    };

    const genRolesFunc = (value, genRoles) => {
        if (genRoles.includes(value)) {
            genRoles = genRoles.filter(item => item !== value);
        } else {
            genRoles.push(value);
        };
        setGeneralArr(genRoles);
        return genRoles
    };

    const hanadleUserData = (id, value) => {
        let userCopy = { ...SelectedUser };
        let genRoles = [...GeneralArr];

        if (id === 'Role' || id === 'Company') {
            userCopy[id] = value;

            if (id === 'Role') {
                if (EditIndex === null) {
                    genRoles = [];
                };
                genRolesFunc(value, genRoles);
                if (value !== 'Handler') {
                    setSelectedCompanies([]);
                };
                userCopy.Company = '';
            };
        };
        setSelectedUser(userCopy);
    };

    const getUserPrimeData = (e) => {
        let usercopy = { ...SelectedUser };
        usercopy[e.target.id] = e.target.value;
        setSelectedUser(usercopy);
    };

    const handleSubmit =async () => {
        let usercopy = { ...SelectedUser };

        usercopy.Permissions.General = GeneralArr;
        usercopy.Permissions.HandlerCompanies = SelectedCompanies;
        //   usercopy['Password'] = 'Test@1234';

        await store.setLoading(true);
        if (EditIndex !== null) {
            await store.patchUsers(usercopy.Email, usercopy).then((ret) => {
                if (Object.keys(ret).length > 0) {
                    handleClose();
                } else {
                    return
                }
            })
        } else {
            await store.createUsers(usercopy).then((ret) => {
                if (Object.keys(ret).length > 0) {
                    handleClose();
                } else {
                    return
                }
            })
        };
        setTimeout(async() => {
            await store.setLoading(false);
        }, 2000)
    };

    return (
        <div className='userContainer'>
            <div className='userbody'>
                {EditIndex === null && CreateNewUser === false ?
                    <div className='usertop'>
                        <p>Users {Users.length}</p>
                        <span style={{  background: CreateNewUser || EditIndex !== null ? 'transparent' : '' }} onClick={handleNewClick}>{CreateNewUser || EditIndex !== null ? '' : 'New user'}</span>
                    </div> :
                    null
                }
                {EditIndex === null && CreateNewUser === false ?
                    Users.map((user, index) => (

                        <div className='mappeduser' key={index}>
                            <p>{`${user.FirstName} ${user.LastName}`}</p>
                            <p style={{ fontWeight: '300' }}>{user.Email}</p>
                            <span onClick={() => handleEditUser(index)}>{user.Role}</span>
                        </div>
                    ))
                    :
                    <div className='new_update_usercard'>
                        <div className='primaryInfo'>
                            <Textbox id='FirstName' onChange={getUserPrimeData} value={SelectedUser.FirstName} placeholder='FirstName' />
                            <Textbox id='LastName' onChange={getUserPrimeData} value={SelectedUser.LastName} placeholder='LastName' />
                            <Textbox id='Email' onChange={getUserPrimeData} value={SelectedUser.Email} placeholder='Email' />
                            <p className='close' onClick={handleClose}>X</p>
                        </div>

                        <hr />

                        <div className='userbody_sub'>
                            <div className='body_left'>
                                {['Role', 'General'].map((key) => (
                                    <CustomSelect key={key} placeholder={key} id={key} value={SelectedUser.Role}>
                                        {Roles.map((role, index) => {
                                            if (key === 'General') {
                                                const isActive = GeneralArr.includes(role) || SelectedUser.Role === role;
                                                if (SelectedUser.Role !== '') {
                                                    return (
                                                        <span key={role} onClick={() => handleGeneralRoles(index, role)} style={{ background: isActive ? '#d4912d' : '' }}>
                                                            {role}
                                                        </span>
                                                    );
                                                }
                                            } else {
                                                let isActive = false;
                                                if (SelectedUser.Role === role) {
                                                    isActive = true;
                                                };
                                                return (
                                                    <span key={role} onClick={(id, value) => hanadleUserData(id = 'Role', value = role)} style={{ background: isActive ? '#d4912d' : '' }}>
                                                        {role}
                                                    </span>
                                                );
                                            }
                                            return null;
                                        })}
                                    </CustomSelect>
                                ))}
                                <div className='thebutton' onClick={handleSubmit}>{CreateNewUser ? 'Create User' : 'Update User'}</div>
                            </div>

                            <div className='body_right'>
                                <h3>{SelectedUser.Role === 'Handler' || GeneralArr.includes('Handler') ? 'Companies' : 'Company'}</h3>
                                {
                                    SelectedUser.Role !== '' ? <div className='maparea'>
                                        {Companies.map((company, index) => {
                                            //   console.log(GeneralArr)
                                            return (
                                                <p onClick={(id, value) => {
                                                    if (SelectedUser.Role === 'Handler' || GeneralArr.includes('Handler')) {
                                                        handleSelectedCompanies(id = index, value = company.Name);
                                                    } else {
                                                        hanadleUserData(id = 'Company', value = company.Name);
                                                    }
                                                }} id={index} style={{ background: SelectedCompanies.includes(company.Name) || SelectedUser.Company === company.Name ? '#d4912d' : '' }}>{company.Name}</p>
                                            )
                                        })}
                                    </div>
                                        :
                                        <h2>Please select role first.!</h2>
                                }

                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
});

export default Users_;
