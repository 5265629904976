import React, {useState} from 'react';
import './style.scss';
import unArchiveIcon from '../../assets/images/UnArchiveIcon.svg';
import { Card } from '../../components';
function UnArchiveHover({ className, id, placeholder, type, value, onKeyDown, onChange, style = {}, inputStyle = {}, onMouseEnter, onMouseLeave, onClick}) {
    const customStyle = style;
    const [Active, setActive] = useState(false);
    function setActiveTrue() {
        setActive(true);
    }
    function setActiveFalse() {
        setActive(false);
    }
    return (
        <div className={`UnArchiveHover ${className}`} style={customStyle} type={type} id={id} value={value} onKeyDown={onKeyDown} onChange={onChange} onMouseEnter={setActiveTrue} onMouseLeave={setActiveFalse} onKeyDown={onKeyDown} >
            <img src={unArchiveIcon} />
            {(Active) ?
                <>
                    <div class="triangle" />
                    <div className='unArchiveTask'>
                        <Card className='unarchivecontainer'><p className='unArchiveValue' onClick={onClick}>Unarchive</p></Card>
                    </div>
                </>
                : null}
        </div>
    )
}

export default UnArchiveHover
