import React from 'react';
import { Bar } from 'react-chartjs-2';

const AEXAgeGroups = ({ Arr = [], id}) => {
    let Age = [];
    let cat1824 = 0;
    let cat2534 = 0;
    let cat3544 = 0;
    let cat4554 = 0;
    let cat5564 = 0;
    let cat65 = 0;

    Arr.forEach((item) => {
        let age = item.Age;
        if (age >= 18 && age <= 24) {
            cat1824 += 1;
        } else if (age >= 25 && age <= 34) {
            cat2534 += 1;
        } else if (age >= 35 && age <= 44) {
            cat3544 += 1;
        } else if (age >= 45 && age <= 54) {
            cat4554 += 1;
        } else if (age >= 55 && age <= 64) {
            cat5564 += 1;
        } else if (age >= 65) {
            cat65 += 1;
        }
    });

    const options = {
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Age Groups',
            },
            datalabels: {
                display: true,
                color: 'black',
                font: {
                    weight: 'bold',
                    size: 15,
                },
                formatter: (value) => value > 0 ? `${Number(value).toLocaleString()}` : '',
            },
        },
        scales: {
            x: {
                display: true,
                position: 'bottom',
            },
        },
        responsive: true,
    };

    const chartData = {
        labels: ['18 - 24', '25 - 34', '35 - 44', '45 - 54', '55 - 64', '65 +'],
        datasets: [
            {
                label: 'Age Groups',
                data: [cat1824, cat2534, cat3544, cat4554, cat5564, cat65],
                backgroundColor: 'rgb(227, 54, 126)',
                borderColor: 'rgb(227, 54, 126)',
                borderWidth: 1,
                categoryPercentage: 1,
            },
        ],
    };
    return <Bar options={options} data={chartData} id={id}/>;
};

export default AEXAgeGroups;
