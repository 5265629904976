import { observer } from "mobx-react-lite";
import { useStore } from "../../../stores/Store";
import { Card, Input, Button } from "../../../components";
import "./style.css";
import React, { useEffect, useState } from "react";
import { FaChevronRight } from "react-icons/fa";
var mobile = require("is-mobile");
const Company = observer(() => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Jobs, setJobs] = useState([]);
    const [CompaniesList, setCompaniesList] = useState([]);
    const [Company, setCompany] = useState({});
    const [Actuals, setActual] = useState([]);
    const [MappedActuals, setMappedActuals] = useState()
    const [Period, setPeriod] = useState("Weekly");
    const [ShowData, setShowData] = useState(false);
    const [ShowPreviousJobs, setShowPreviousJobs] = useState(true);
    const [SelectedJob, setSelectedJob] = useState({});
    const [ViewDetailsMobile, setViewDetailsMobile] = useState(false);
    const [ViewCommentsMobile, setViewCommentsMobile] = useState(true);
    const [MappedCredits, setMappedCredits] = useState({
        "Departments": {
            "Sales": 0,
            "Dev": 0,
            "Design": 0
        }
    });
    const [CompanyCredits, setCompanyCredits] = useState([])
    const [CreditsColor, setCreditsColor] = useState('')
    const [Comment, setComment] = useState('');
    const [Comments, setComments] = useState([])
    const [CommentObj, setCommentObj] = useState({
        Comment: '',
        Date: new Date(),
        Email: ''
    })
    const [ActDueDate, setActDueDate] = useState(new Date().toDateString())
    const [JobRequest, setJobRequest] = useState({
        ActualDueDate: null,
        Title: "",
        Description: "",
        FeedBack: '',
        Department: "",
        DesignSpec: {
            designElements: "",
            collateral: "",
            listDeliverables: "",
            DocLink: ''
        },
        DevSpec: {
            Platform: "",
            Type: "",
            Outcomes: "",
            Figma_Available: ""
        },
        ReqDate: new Date(),
        DueDate: new Date()
    });
    useEffect(() => {
        loadCompanies();
    }, []);
    function loadCompanies() {
        store.setLoading(true);
        store.getCompaniesByPerms(store.user.Email).then((ret) => {
            if (typeof ret === "string" || !ret) {
                setCompaniesList({});
                store.setLoading(false);
                return;
            }
            if (ret.length === 0) {
                setCompaniesList({});
                store.setLoading(false);
                return;
            }
            let comp = ret.filter(item => item.Name === store.user.Company);
            if (comp.length === 0) {
                setCompaniesList({});
                store.setLoading(false);
                return;
            }
            if (comp[0].Credits && comp[0].Credits.length > 0) {
                setCompanyCredits(comp[0].Credits[0].Departments);
            }
            setCompany(comp);
            setActual(comp[0].Actual[comp[0].Actual.length - 1]);

            getJobs(comp[0]);
            store.setLoading(false);
        });
        forceUpdate();
    }
    function addPercentange(data, usedCredsObj) {
        let currentDate = new Date();
        let currentMonth = currentDate.getMonth();
        let currMonthData = data.find(entry => entry.Month === currentMonth);
        if (currMonthData) {
            currMonthData.Departments.Sales = ((currMonthData.Departments.Sales || 0) - usedCredsObj.Sales);
            currMonthData.Departments.Dev = ((currMonthData.Departments.Dev || 0) - usedCredsObj.Dev);
            currMonthData.Departments.Design = ((currMonthData.Departments.Design || 0) - usedCredsObj.Design);

        } else {
            currMonthData = {
                Departments: {
                    Sales: 0 - usedCredsObj.Sales,
                    Dev: 0 - usedCredsObj.Dev,
                    Design: 0 - usedCredsObj.Design,
                }
            }
        }
        setMappedCredits(currMonthData);

    }
    function getJobs(company) {
        store.setLoading(true);
        store.getJobByEmail(store.user.Company).then((ret) => {
            if (typeof ret === "string" || !ret) {
                setJobs({});
                store.setLoading(false);
                return;
            }
            setJobs(ret);
            forceUpdate();
            getCredits(company, ret);
            store.setLoading(false);
        });
    }
    function viewCommentsClicked() {
        setViewCommentsMobile(true);
        setViewDetailsMobile(false);
    }
    function viewDetailsClicked() {
        setViewCommentsMobile(false);
        setViewDetailsMobile(true);
    }
    function getCredits(comp, jobArr) {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
        const jobsThisMonth = [];
        jobArr.forEach((item) => {
            const itemDate = new Date(item.ReqDate);
            const itemMonth = itemDate.getMonth();
            const itemYear = itemDate.getFullYear();

            if (itemMonth === currentMonth && itemYear === currentYear) {
                jobsThisMonth.push(item);
            }
        });

        let DepartmentSring = ''
        let usedCredsObj = {
            Sales: 0,
            Dev: 0,
            Design: 0
        }
        for (let i = 0; i < jobsThisMonth.length; i++) {
            let jb = jobsThisMonth[i];
            let usedCredits = 0;
            let estimatedHrs = jb.EstimatedHours || 0;
            let actualHrs = jb.ActualHours || 0;
            DepartmentSring = jb.Department;
            if (actualHrs === 0) {
                usedCredits = estimatedHrs;
            } else {
                usedCredits = actualHrs;
            }
            usedCredsObj[DepartmentSring] = usedCredsObj[DepartmentSring] + usedCredits;
        }
        let creditsThisMonthArr = []
        let allCreditsArr = comp.Credits || [];
        for (let i = 0; i < allCreditsArr.length; i++) {
            if (allCreditsArr[i].Month === currentMonth && allCreditsArr[i].Year === currentYear) {
                creditsThisMonthArr.push(allCreditsArr[i])
            }
        }

        let data = comp.Credits;
        addPercentange(data, usedCredsObj);
        forceUpdate();
    }
    function showDataButton(e) {
        setShowData(true);
        setSelectedJob(Jobs[Number(e.currentTarget.id)]);
        var tmpComments = [];
        let key = Object.keys(Jobs[Number(e.currentTarget.id)]).filter(item => item === 'Comment')
        if (key !== '') {
            tmpComments = Jobs[Number(e.currentTarget.id)].Comment || [];
        }
        setComments(tmpComments);
        forceUpdate();
    }
    function jobRequestChange(e) {
        let job = JobRequest;
        job.ActualDueDate = null;
        job.FeedBack = "";
        if (e.currentTarget.id === "title") {
            job.Title = e.target.value;
        }
        if (e.currentTarget.id === "description") {
            job.Description = e.target.value;
        }
        if (e.currentTarget.id === "department") {
            job.Department = e.target.value;
        }
        if (e.currentTarget.id === "designElements") {
            job.DesignSpec.designElements = e.target.value;
        }
        if (e.currentTarget.id === "collateral") {
            job.DesignSpec.collateral = e.target.value;
        }
        if (e.currentTarget.id === "listDeliverables") {
            job.DesignSpec.listDeliverables = e.target.value;
        }
        if (e.currentTarget.id === "docLinks") {
            job.DesignSpec.DocLink = e.target.value;
        }
        if (e.currentTarget.id === "dueDate") {
            job.DueDate = e.target.value;
        }
        if (e.currentTarget.id === "Platform") {
            job.DevSpec.Platform = e.target.value;
        }
        if (e.currentTarget.id === "Type") {
            job.DevSpec.Type = e.target.value;
        }
        if (e.currentTarget.id === "Outcomes") {
            job.DevSpec.Outcomes = e.target.value;
        }
        if (e.currentTarget.id === "Figma_Available") {
            job.DevSpec.Figma_Available = e.target.value;
        }
        setJobRequest(job);
        forceUpdate();
    }
    function RequestJobClicked() {
        let job = JobRequest;

        if (job.Title === "") {
            window.alert("Please enter Job Title!");
            return;
        }
        if (job.Department === "") {
            window.alert("Please input target department!");
            return;
        }
        if (job.Description === "") {
            window.alert("Please input some description about the job!");
            return;
        }

        const selectedDepartmentCredits = CompanyCredits && CompanyCredits[job.Department];
        if (selectedDepartmentCredits === undefined) {
            window.alert(`Credits not available for ${job.Department}`);
        } else if (selectedDepartmentCredits !== null && selectedDepartmentCredits <= 0) {
            window.alert(`Credits for ${job.Department} exhausted. Extra credits used for job requests will be deducted from next month's refill. Contact admin for more info.`);
        }

        const mappedCreditValue = MappedCredits.Departments[job.Department] || 0;

        store.setLoading(true);
        let senderEmail = store.user.Email;
        let data = {
            Sender: senderEmail,
            Title: job.Title,
            Department: job.Department,
            Description: job.Description,
            Client: store.user.Company,
            DueDate: new Date(job.DueDate),
            ReqDate: job.ReqDate,
            ActualDueDate: job.ActualDueDate,
            Status: "requested",
            Credits: mappedCreditValue,
            DesignSpec: {
                designElements: job.DesignSpec?.designElements || "",
                collateral: job.DesignSpec?.collateral || "",
                listDeliverables: job.DesignSpec?.listDeliverables || "",
                Doclink: job.DesignSpec?.DocLink || ""
            },
            DevSpec: {
                Figma_Available: job.DevSpec?.Figma_Available || "",
                Outcomes: job.DevSpec?.Outcomes || "",
                Platform: job.DevSpec?.Platform || "",
                Type: job.DevSpec?.Type || ""
            },
        };

        store.createJob(data).then((ret) => {
            if (ret === true) {
                window.alert("Job request created successfully");
                setJobRequest({
                    Title: "",
                    Description: "",
                    Department: "",
                    FeedBack: '',
                    DesignSpec: {
                        designElements: "",
                        collateral: "",
                        listDeliverables: "",
                        DocLink: ""
                    },
                    DevSpec: {
                        Figma_Available: "",
                        Outcomes: "",
                        Platform: "",
                        Type: ""
                    },
                    ActualDueDate: null,
                    ReqDate: new Date(),
                    DueDate: new Date()
                });
                getJobs();
            } else {
                window.alert("Unable to Request job");
            }
            store.setLoading(false);
        });
    }
    function closeGraph() {
        setShowData(false);
    }
    function togglePreviousJobs() {
        setShowPreviousJobs(!ShowPreviousJobs);
    }
    function sendComment() {
        let job = JobRequest;
        if (CommentObj.Comment === "") {
            window.alert("Please enter a comment");
        } else {
            const mappedCreditValue = MappedCredits.Departments[job.Department];
            const id = SelectedJob._id;
            const commentArr = [...Comments, CommentObj];
            const data = {
                Comment: commentArr,
                Credits: mappedCreditValue
            };
            store.setLoading(true);
            store.UpdateJobById(id, data)
                .then((ret) => {
                    if (ret === "error") {
                        window.alert("Error updating comments. Please try again.");
                    } else {
                        window.alert("Comment sent.");
                        setComments(commentArr);
                        setCommentObj({
                            Comment: "",
                            Date: new Date(),
                            Email: ""
                        });
                    }
                    store.setLoading(false);
                })
                .catch((error) => {
                    console.error("Error updating job:", error);
                    window.alert("Error updating comments. Please try again.");
                    store.setLoading(false);
                });
        }
    }
    function commentChange(e) {

        let commentObj = CommentObj
        commentObj.Comment = e.currentTarget.value
        commentObj.Email = store.user.Email
        setCommentObj(commentObj)
        forceUpdate()
    }
    if (mobile()) {
        return (
            <div style={{ height: "120%", width: "100%", overflowY: "scroll" }}>
                <p className="companyName">
                    <b>{store.user.Company}</b>
                </p>
                <div className="compDataDiv">
                    <div className="compDataInnerDiv">
                        {
                            (Actuals) ? <>
                                <Card className="figureCards companyFigureCards"
                                    style={
                                        {
                                            width: "50%",
                                            height: "4vw",
                                            marginTop: "2vw"
                                        }
                                    }>
                                    <p style={
                                        {
                                            position: "absolute",
                                            top: "1vw",
                                            fontSize: "10px",
                                            fontStyle: "italic"
                                        }
                                    }>
                                        Total Sales
                                    </p>
                                    <p style={
                                        {
                                            position: "absolute",
                                            top: "2vw",
                                            fontSize: "20px"
                                        }
                                    }>
                                        <b>R</b>
                                        {
                                            Actuals.TotalSales
                                        } </p>
                                </Card>
                                <Card className="figureCards companyFigureCards"
                                    style={
                                        {
                                            width: "50%",
                                            height: "4vw",
                                            marginTop: "2vw"
                                        }
                                    }>
                                    <p style={
                                        {
                                            position: "absolute",
                                            top: "1vw",
                                            fontSize: "10px",
                                            fontStyle: "italic"
                                        }
                                    }>
                                        Operating Expenses
                                    </p>
                                    <p style={
                                        {
                                            position: "absolute",
                                            top: "2vw",
                                            fontSize: "20px"
                                        }
                                    }>
                                        <b>R</b>
                                        {
                                            Actuals.OperatingExpenses
                                        } </p>
                                </Card>
                            </> : <>
                                <Card className="companyFigureCardsNoData">

                                    <p style={
                                        {
                                            position: "absolute",
                                            top: "2%",
                                            fontSize: "20px"
                                        }
                                    }>
                                        <b>No Available Data For {
                                            store.user.Company
                                        }</b>
                                    </p>

                                </Card>
                            </>
                        }
                    </div>
                    <Card style={{ width: "45%" }} className="creditsCard">
                        <b> Monthly Credits </b>
                        <p className="creditsP"><b>Sales</b> : {MappedCredits.Departments.Sales}</p>
                        <p className="creditsP"><b>Dev</b> : {MappedCredits.Departments.Dev}</p>
                        <p className="creditsP"><b>Design</b> : {MappedCredits.Departments.Design}</p>
                    </Card>
                </div>
                <div className='jobCardsMobile'>
                    <Card className="designHomePageCardsMobile" style={{ marginRight: "2%", marginBottom: "1%" }}>
                        <h1>Request Job</h1>
                        <Input placeholder="Title" value={JobRequest.Title} onChange={jobRequestChange} id="title" type="text" />
                        <textarea style={{ resize: "none", borderRadius: "15px", width: "80%", height: "80px", padding: "1%" }} value={JobRequest.Description}
                            onChange={jobRequestChange} id="description" placeholder="Description" />
                        <p style={{ marginBottom: "0px" }}>Due Date:</p>
                        <Input value={JobRequest.DueDate} id="dueDate" onChange={jobRequestChange} type="date" />
                        <p style={{ marginBottom: "0px" }}>Type:</p>
                        <select style={{ marginBottom: "10px", marginTop: "10px", width: "80%" }} value={JobRequest.Department} id="department" onChange={jobRequestChange} className="dropdownSelect">
                            <option value="Department">Department</option>
                            <option value="Sales">Sales</option>
                            <option value="Design">Design</option>
                            <option value="Dev">Dev</option>
                            <option value="Mentor">Mentor</option>
                        </select>

                        {
                            JobRequest.Department === "Design" ? (
                                <>
                                    <textarea style={{ resize: "none", borderRadius: "15px", width: "80%", height: "80px", marginBottom: "5px", marginTop: "5px", padding: "1%" }}
                                        id="listDeliverables" onChange={jobRequestChange} className="textAreaDiv" value={JobRequest.DesignSpec.listDeliverables}
                                        placeholder="List Deliverables/ Outputs:" />
                                    <textarea style={{ resize: "none", borderRadius: "15px", width: "80%", height: "80px", marginBottom: "5px", marginTop: "5px", padding: "1%" }}
                                        id="collateral" className="textAreaDiv" onChange={jobRequestChange} value={JobRequest.DesignSpec.collateral}
                                        placeholder="What will this collateral be used for?" />
                                    <textarea style={{ resize: "none", borderRadius: "15px", width: "80%", height: "80px", marginBottom: "5px", marginTop: "5px", padding: "1%" }}
                                        id="designElements" className="textAreaDiv" onChange={jobRequestChange}
                                        value={JobRequest.DesignSpec.designElements}
                                        placeholder="What design elements should be incorporated to ensure brand consistency?" />
                                    <textarea style={{ resize: "none", borderRadius: "15px", width: "80%", height: "80px", marginBottom: "5px", marginTop: "5px", padding: "1%" }}
                                        id="docLinks" onChange={jobRequestChange} className="textAreaDiv" value={JobRequest.DesignSpec.DocLink}
                                        placeholder="Document link/ Drive link " />
                                </>
                            ) : null
                        }

                        {
                            JobRequest.Department === "Dev" ? (
                                <>
                                    <select style={{ marginBottom: "10px", marginTop: "10px", width: "80%" }} value={JobRequest.DevSpec.Platform} id="Platform" onChange={jobRequestChange} className="dropdownSelect">
                                        <option value="Select platform">Select platform</option>
                                        <option value="App">App</option>
                                        <option value="Website">Website</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    <select style={{ marginBottom: "10px", marginTop: "10px", width: "80%" }} value={JobRequest.DevSpec.Type} id="Type" onChange={jobRequestChange} className="dropdownSelect">
                                        <option value="Select Type">Select Type</option>
                                        <option value="Bug fixes">Bug fixes</option>
                                        <option value="feature requests">feature requests</option>
                                        <option value="support meetings">support meetings</option>
                                        <option value="Other">Other</option>
                                    </select>

                                    <textarea style={{ resize: "none", borderRadius: "15px", width: "80%", height: "80px", marginBottom: "5px", marginTop: "5px" }} id="Outcomes" className="textAreaDiv" onChange={jobRequestChange} value={JobRequest.DevSpec.Outcomes} placeholder="What should this project achieve?" />
                                    <select style={{ marginBottom: "10px", marginTop: "10px", width: "80%" }} value={JobRequest.DevSpec.Figma_Available} id="Figma_Available" onChange={jobRequestChange} className="dropdownSelect">
                                        <option value="Select ">Figma available?</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </>
                            ) : null
                        }
                        <Button style={{ justifyContent: "center" }} onClick={RequestJobClicked}>Request</Button>
                    </Card>
                    <Card className="designHomePageCardsMobile" >
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <h1 style={{ marginLeft: "10vw" }}>Previous Jobs</h1>
                            <FaChevronRight className={`showSectionChevron ${ShowPreviousJobs ? "showSectionChevronShow" : ""}`} onClick={togglePreviousJobs} />
                        </div>
                        {
                            !ShowPreviousJobs ? null : (
                                <>
                                    {Jobs.map((job, index) => {
                                        return (
                                            <div key={index} className="existingjobDiv">
                                                <div>
                                                    <p style={{ marginTop: "0px", marginBottom: "5px", fontWeight: "bold" }}>{job.Title}
                                                    </p>
                                                    <p style={{ marginTop: "2px", marginBottom: "0px" }}>{job.Status}
                                                    </p>
                                                </div>

                                                <Button onClick={showDataButton} id={index}>View</Button>
                                            </div>
                                        );
                                    })
                                    }
                                </>
                            )
                        } </Card>
                </div>
                {
                    ShowData ? (
                        <Card className="viewCompanyJobMobile">
                            <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
                                <Button onClick={viewDetailsClicked}>Job Details</Button>
                                <Button onClick={viewCommentsClicked}>Job Comments</Button>
                            </div>
                            {ViewDetailsMobile ? (
                                <div style={{ height: "100%", width: "100%" }}>
                                    <p style={{ position: "absolute", top: "0px", right: "10px", cursor: "pointer" }} id="none" onClick={closeGraph}>&#10005;</p>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                        <p style={{ fontSize: "25px", fontWeight: "bold" }}>{SelectedJob.Title}</p>
                                    </div>
                                    <p><b>Status                :   </b>{SelectedJob.Status} </p>
                                    <p><b>Date of request       :   </b>{new Date(SelectedJob.ReqDate).toDateString()} </p>
                                    <p><b>Requested due date    :   </b>{new Date(SelectedJob.DueDate).toDateString()} </p>
                                    <p><b>Actual due date       :   </b>{SelectedJob.ActualDueDate}</p>
                                    <p><b>Estimated hours       :   </b>{SelectedJob.EstimatedHours}</p>
                                    <p><b>Actual hours          :   </b>{SelectedJob.ActualHours}</p>
                                    <p><b>Description           :   </b>{SelectedJob.Description}</p>
                                </div>
                            ) : null}
                            {ViewCommentsMobile ? (
                                <div style={{ height: "100%", width: "100%" }}>
                                    <p style={{ position: "absolute", top: "0px", right: "10px", cursor: "pointer" }} id="none" onClick={closeGraph}>&#10005;</p>
                                    <h1>Comments</h1>
                                    <div style={{ color: "black" }} className="CommentCardsMobile">
                                        {!Comments || Comments.length === 0 ? (
                                            <div className="CommentCard">
                                                <h3>No comments available</h3>
                                            </div>
                                        ) : (
                                            Comments.map((comment, index) => (
                                                <div className="CommentCard" key={index}>
                                                    <h3>{comment.Comment}</h3>
                                                    <p>Email: {comment.Email}</p>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                    <div className="CommentsCompsMobile">
                                        <Input value={CommentObj.Comment} id="Comment" onChange={commentChange} style={{ resize: "none", borderRadius: "15px", width: "100%" }} placeholder="Comment" />
                                        <Button onClick={sendComment}>Send</Button>
                                    </div>
                                    <div className="DetailsTabs">

                                    </div>
                                </div>
                            ) : null}
                        </Card>
                    ) : null
                } </div>
        );
    } else {
        return (
            <div style={{ height: "120%", width: "100%" }}>
                <p className="companyName">
                    <b>{store.user.Company}</b>
                </p>
                <div className="compDataDiv">
                    <div className="compDataInnerDiv">
                        {
                            (Actuals) ? <>
                                <Card className="figureCards companyFigureCards"
                                    style={
                                        {
                                            width: "50%",
                                            height: "4vw",
                                            marginTop: "2vw"
                                        }
                                    }>
                                    <p style={
                                        {
                                            position: "absolute",
                                            top: "1vw",
                                            fontSize: "10px",
                                            fontStyle: "italic"
                                        }
                                    }>
                                        Total Sales
                                    </p>
                                    <p style={
                                        {
                                            position: "absolute",
                                            top: "2vw",
                                            fontSize: "20px"
                                        }
                                    }>
                                        <b>R</b>
                                        {
                                            Actuals.TotalSales
                                        } </p>
                                </Card>
                                <Card className="figureCards companyFigureCards"
                                    style={
                                        {
                                            width: "50%",
                                            height: "4vw",
                                            marginTop: "2vw"
                                        }
                                    }>
                                    <p style={
                                        {
                                            position: "absolute",
                                            top: "1vw",
                                            fontSize: "10px",
                                            fontStyle: "italic"
                                        }
                                    }>
                                        Operating Expenses
                                    </p>
                                    <p style={
                                        {
                                            position: "absolute",
                                            top: "2vw",
                                            fontSize: "20px"
                                        }
                                    }>
                                        <b>R</b>
                                        {
                                            Actuals.OperatingExpenses
                                        } </p>
                                </Card>
                                <Card className="figureCards companyFigureCards"
                                    style={
                                        {
                                            width: "50%",
                                            height: "4vw",
                                            marginTop: "2vw"
                                        }
                                    }>
                                    <p style={
                                        {
                                            position: "absolute",
                                            top: "1vw",
                                            fontSize: "10px",
                                            fontStyle: "italic"
                                        }
                                    }>
                                        Recurring Revenue
                                    </p>
                                    <p style={
                                        {
                                            position: "absolute",
                                            top: "2vw",
                                            fontSize: "20px"
                                        }
                                    }>
                                        <b>R</b>
                                        {
                                            Actuals.RecurringRevenue
                                        } </p>
                                </Card>
                                <Card className="figureCards companyFigureCards"
                                    style={
                                        {
                                            width: "50%",
                                            height: "4vw",
                                            marginTop: "2vw"
                                        }
                                    }>
                                    <p style={
                                        {
                                            position: "absolute",
                                            top: "1vw",
                                            fontSize: "10px",
                                            fontStyle: "italic"
                                        }
                                    }>
                                        Average Revenue
                                    </p>
                                    <p style={
                                        {
                                            position: "absolute",
                                            top: "2vw",
                                            fontSize: "20px"
                                        }
                                    }>
                                        <b>R</b>
                                        {
                                            Actuals.AverageRevenuePerCustomer
                                        } </p>
                                </Card>
                            </> : <>
                                <Card className="companyFigureCardsNoData">

                                    <p style={
                                        {
                                            position: "absolute",
                                            top: "2%",
                                            fontSize: "20px"
                                        }
                                    }>
                                        <b>No Available Data For {
                                            store.user.Company
                                        }</b>
                                    </p>

                                </Card>
                            </>
                        }
                    </div>
                    <Card className="creditsCard">
                        <b> Monthly Credits </b>
                        <p className="creditsP"><b>Sales</b> : {MappedCredits.Departments.Sales}</p>
                        <p className="creditsP"><b>Dev</b> : {MappedCredits.Departments.Dev}</p>
                        <p className="creditsP"><b>Design</b> : {MappedCredits.Departments.Design}</p>
                    </Card>
                </div>
                <div className='jobCards'>
                    <Card className="homeCardRequestedJob" style={{ marginRight: "2%" }}>
                        <h1>Request Job</h1>
                        <Input placeholder="Title" value={JobRequest.Title} onChange={jobRequestChange} id="title" type="text" />
                        <textarea style={{ resize: "none", borderRadius: "15px", width: "80%", height: "80px", padding: "1%" }} value={JobRequest.Description}
                            onChange={jobRequestChange} id="description" placeholder="Description" />
                        <p style={{ marginBottom: "0px" }}>Due Date:</p>
                        <Input value={JobRequest.DueDate} id="dueDate" onChange={jobRequestChange} type="date" />
                        <p style={{ marginBottom: "0px" }}>Type:</p>
                        <select style={{ marginBottom: "10px", marginTop: "10px", width: "80%" }} value={JobRequest.Department} id="department" onChange={jobRequestChange} className="dropdownSelect">
                            <option value="Department">Department</option>
                            <option value="Sales">Sales</option>
                            <option value="Design">Design</option>
                            <option value="Dev">Dev</option>
                            <option value="Mentor">Mentor</option>
                        </select>

                        {
                            JobRequest.Department === "Design" ? (
                                <>
                                    <textarea style={{ resize: "none", borderRadius: "15px", width: "80%", height: "80px", marginBottom: "5px", marginTop: "5px", padding: "1%" }}
                                        id="listDeliverables" onChange={jobRequestChange} className="textAreaDiv" value={JobRequest.DesignSpec.listDeliverables}
                                        placeholder="List Deliverables/ Outputs:" />
                                    <textarea style={{ resize: "none", borderRadius: "15px", width: "80%", height: "80px", marginBottom: "5px", marginTop: "5px", padding: "1%" }}
                                        id="collateral" className="textAreaDiv" onChange={jobRequestChange} value={JobRequest.DesignSpec.collateral}
                                        placeholder="What will this collateral be used for?" />
                                    <textarea style={{ resize: "none", borderRadius: "15px", width: "80%", height: "80px", marginBottom: "5px", marginTop: "5px", padding: "1%" }}
                                        id="designElements" className="textAreaDiv" onChange={jobRequestChange}
                                        value={JobRequest.DesignSpec.designElements}
                                        placeholder="What design elements should be incorporated to ensure brand consistency?" />
                                    <textarea style={{ resize: "none", borderRadius: "15px", width: "80%", height: "80px", marginBottom: "5px", marginTop: "5px", padding: "1%" }}
                                        id="docLinks" onChange={jobRequestChange} className="textAreaDiv" value={JobRequest.DesignSpec.DocLink}
                                        placeholder="Document link/ Drive link " />
                                </>
                            ) : null
                        }

                        {
                            JobRequest.Department === "Dev" ? (
                                <>
                                    <select style={{ marginBottom: "10px", marginTop: "10px", width: "80%" }} value={JobRequest.DevSpec.Platform} id="Platform" onChange={jobRequestChange} className="dropdownSelect">
                                        <option value="Select platform">Select platform</option>
                                        <option value="App">App</option>
                                        <option value="Website">Website</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    <select style={{ marginBottom: "10px", marginTop: "10px", width: "80%" }} value={JobRequest.DevSpec.Type} id="Type" onChange={jobRequestChange} className="dropdownSelect">
                                        <option value="Select Type">Select Type</option>
                                        <option value="Bug fixes">Bug fixes</option>
                                        <option value="feature requests">feature requests</option>
                                        <option value="support meetings">support meetings</option>
                                        <option value="Other">Other</option>
                                    </select>

                                    <textarea style={{ resize: "none", borderRadius: "15px", width: "80%", height: "80px", marginBottom: "5px", marginTop: "5px" }} id="Outcomes" className="textAreaDiv" onChange={jobRequestChange} value={JobRequest.DevSpec.Outcomes} placeholder="What should this project achieve?" />
                                    <select style={{ marginBottom: "10px", marginTop: "10px", width: "80%" }} value={JobRequest.DevSpec.Figma_Available} id="Figma_Available" onChange={jobRequestChange} className="dropdownSelect">
                                        <option value="Select ">Figma available?</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </>
                            ) : null
                        }
                        <Button style={{ justifyContent: "center" }} onClick={RequestJobClicked}>Request</Button>
                    </Card>
                    <Card className="homeCardsPrevJobs">
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <h1 style={{ marginLeft: "10vw" }}>Previous Jobs</h1>
                            <FaChevronRight className={`showSectionChevron ${ShowPreviousJobs ? "showSectionChevronShow" : ""}`} onClick={togglePreviousJobs} />
                        </div>
                        {
                            !ShowPreviousJobs ? null : (
                                <>
                                    {Jobs.map((job, index) => {
                                        return (
                                            <div key={index} className="existingjobDiv">
                                                <div>
                                                    <p style={{ marginTop: "0px", marginBottom: "5px", fontWeight: "bold" }}>{job.Title}
                                                    </p>
                                                    <p style={{ marginTop: "2px", marginBottom: "0px" }}>{job.Status}
                                                    </p>
                                                </div>

                                                <Button onClick={showDataButton} id={index}>View</Button>
                                            </div>
                                        );
                                    })
                                    }
                                </>
                            )
                        } </Card>
                </div>
                {
                    ShowData ? (
                        <Card className="viewCompanyJob">
                            <div className="JobAtIndexDetails">
                                <p style={{ position: "absolute", top: "0px", right: "10px", cursor: "pointer" }} id="none" onClick={closeGraph}>&#10005;</p>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                    <p style={{ fontSize: "25px", fontWeight: "bold" }}>{SelectedJob.Title}</p>
                                </div>
                                <p><b>Status                :   </b>{SelectedJob.Status} </p>
                                <p><b>Date of request       :   </b>{new Date(SelectedJob.ReqDate).toDateString()} </p>
                                <p><b>Requested due date    :   </b>{new Date(SelectedJob.DueDate).toDateString()} </p>
                                <p><b>Actual due date       :   </b>{SelectedJob.ActualDueDate}</p>
                                <p><b>Estimated hours       :   </b>{SelectedJob.EstimatedHours}</p>
                                <p><b>Actual hours          :   </b>{SelectedJob.ActualHours}</p>
                                <p><b>Description           :   </b>{SelectedJob.Description}</p>
                            </div>
                            <div className="DepartmentsComments">
                                <h1>Comments</h1>
                                <div style={{ color: "black" }} className="CommentCards">
                                    {!Comments || Comments.length === 0 ? (
                                        <div className="CommentCard">
                                            <h3>No comments available</h3>
                                        </div>
                                    ) : (
                                        Comments.map((comment, index) => (
                                            <div className="CommentCard" key={index}>
                                                <h3>{comment.Comment}</h3>
                                                <p>Email: {comment.Email}</p>
                                            </div>
                                        ))
                                    )}
                                </div>
                                <div className="CommentsComps">
                                    <Input value={CommentObj.Comment} id="Comment" onChange={commentChange} style={{ resize: "none", borderRadius: "15px", width: "100%" }} placeholder="Comment" />
                                    <Button onClick={sendComment}>Send</Button>
                                </div>
                            </div>
                        </Card>
                    ) : null} 
                    </div>
        );

    }
})
export default Company;