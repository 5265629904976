import React from 'react';
import './style.css';

function MobileTabBar({children, className, id, style={}}){
    let customStyle = style;
    return (
        <div className={`mobileTabBar ${className}`} id={`mobileTabBar ${id}`} style={customStyle}>
            {children}
        </div>
    )
}

export default MobileTabBar