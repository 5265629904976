import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import CloseCard from '../../../assets/images/CloseTask.svg';
import Archive from '../../../assets/images/ArchiveIcon.svg';
import CloseArchive from '../../../assets/images/closeArchive.svg';
import unArchiveIcon from '../../../assets/images/UnArchiveIcon.svg';
import Filter from '../../../assets/images/Filter.svg';
import FilterDown from '../../../assets/images/FilterDown.svg';
import AddIcon from '../../../assets/images/addMeeting.svg'
import { Input, DepartmentReport, Calender } from '../../../components';

var mobile = require('is-mobile');
const Report = observer(() => {
    const store = useStore();
    const navigate = useNavigate();
    const [, updateState] = useState();
    const graphSectionRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [IsOverview, setIsOverview] = useState(false);
    const [IsCompanyOpen, setIscompanyOpen] = useState(false);
    const [IsCalenderOpen, setIsCalenderOpen] = useState(false);
    const [Companies, setCompanies] = useState([]);
    const [Task, setTask] = useState([]);
    const [Dates, setDates] = useState({
        StartDate: new Date(),
        EndDate: new Date()
    });
    const [CompIndex, setCompIndex] = useState(null);

    useEffect(() => {
        getCompTasks();
        if (IsOverview) {
            const updateDimensions = () => {
                if (graphSectionRef.current) {
                    setDimensions({
                        width: graphSectionRef.current.offsetWidth,
                        height: graphSectionRef.current.offsetHeight,
                    });
                }
            };
            updateDimensions();
        }
    }, [IsOverview]);
    const getCompTasks = async () => {
        store.setLoading(true);
        let tasks = await store.getJob();
        if (typeof tasks === "string" || !tasks) {
            store.setLoading(false);
            return;
        }
        let comps = await store.getCompanies();
        if (typeof comps === "string" || !comps) {
            store.setLoading(false);
            return;
        }
        for (let i = 0; i < comps.length; i++) {
            let comp = comps[i];
            comp.DevTask = [];
            comp.DesignTask = [];
            for (let t = 0; t < tasks.length; t++) {
                let task = tasks[t];
                if (task.Client === comp.Name) {
                    if (task.Department === 'Dev') {
                        comp.DevTask.push(task);
                    } else if (task.Department === 'Design') {
                        comp.DesignTask.push(task);
                    }
                }
            }
        }
        taskCalculation(comps);
        setCompanies(comps);
        store.setLoading(false);
        forceUpdate();
    };
    const taskCalculation = (data) => {
        let comps = data;

    }
    const viewOverViewClicked = () => {
        setIsOverview(true);

        console.log('viewOverViewClicked', IsOverview, dimensions)
        forceUpdate();
    }
    const closeOverViewClicked = () => {
        setIsOverview(false);
        console.log('closeOverViewClicked', IsOverview, dimensions)
        forceUpdate();
    }
    const viewCompClicked = (index) => {
        setCompIndex(index);
        setIscompanyOpen(true);
        forceUpdate();
    }
    const closeCompClicked = () => {
        setIscompanyOpen(false);
        forceUpdate();
    }
    const openCalendar = () => {
        setIsCalenderOpen(true);
        forceUpdate();
    };
    const closeCalender = () => {
        setIsCalenderOpen(false);
        forceUpdate();
    };
    const setStartDate = (date) => {
        setDates(prevDates => ({
            ...prevDates,
            StartDate: new Date(date)
        }));
    };
    const setEndDate = (e) => {
        let dates = Dates;
        dates.EndDate = e.target.value;
        setDates(dates);
        forceUpdate();
    };
    if (mobile()) {
        return (
            <div>
            </div>
        );
    } else {
        return (
            <div className='department-report-section'>
                <div className='top-sectionDepartment-report'>
                    <div className='department-report-title'>
                        <p>Company Name</p>
                    </div>
                    <div className='department-report-filter' onMouseEnter={openCalendar} onMouseLeave={closeCalender}>
                        <div className='filter-from-date'>
                            <p className='filter-placeHolder'>Placeholder</p>
                            <p style={{ marginBottom: '15px' }}>12/02/2022</p>
                        </div>
                        <div className='filter-from-date'>
                            <p className='filter-placeHolder'>Placeholder</p>
                            <p style={{ marginBottom: '15px' }}>12/02/2022</p>
                        </div>
                        {(IsCalenderOpen) ?
                            <div className='view-calendar'>
                                <Calender />
                                <Calender />
                            </div> : null}
                    </div>
                </div>
                <div className='taskComp-overview-screen'>
                    <div className='companiesOverview' style={{ height: IsOverview ? '70%' : '10%' }}>
                        <div className='taskOverview-chart-legend' style={{ height: IsOverview ? '14%' : '99%', fontSize: '20px' }}>
                            <div className='chart-legends-section'>
                                <div className='dev-legend-section'>
                                    <div /><p>Dev</p>
                                </div>
                                <div className='design-legend-section'>
                                    <div /><p>Design</p>
                                </div>
                            </div>
                            <div className='overview-tab'>
                                <img style={{ marginRight: '15px' }} src={IsOverview ? Filter : FilterDown} onClick={IsOverview ? closeOverViewClicked : viewOverViewClicked} /><p onClick={IsOverview ? closeOverViewClicked : viewOverViewClicked}>Overview</p>
                            </div>
                            <div className='total-tasks-section'>
                                <div className='dev-totals-section'>
                                    <p>78</p>
                                </div>
                                <div className='design-totals-section'>
                                    <p>78</p>
                                </div>
                            </div>
                        </div>
                        {(IsOverview) ?
                            <div className='taskOverview-chart-section' ref={graphSectionRef}>
                                <DepartmentReport />
                            </div> : null}
                    </div>
                    {Companies.map((item, index) => {
                        return (
                            <div className='companiesOverview' style={{ height: IsCompanyOpen && index === CompIndex ? '50%' : '10%' }}>
                                <div className='taskOverview-chart-legend' style={{ height: IsCompanyOpen && index === CompIndex ? '20%' : '99%', fontSize: '20px' }}>
                                    <div className='comp-name-filter'>
                                        <img style={{ marginRight: '15px' }} src={IsCompanyOpen && index === CompIndex ? Filter : FilterDown} onClick={IsCompanyOpen ? () => closeCompClicked(index) : () => viewCompClicked(index)} />
                                        <p onClick={IsCompanyOpen ? () => closeCompClicked(index) : () => viewCompClicked(index)}>{item.Name}</p>
                                    </div>
                                    <div className='assigned-creds-section'>
                                        <p>Assigned Credits</p>
                                        <div className='departments-remaing-section'>
                                            <div className='assigned-Dev-creds-conatainer'>
                                                <p>56</p>
                                            </div>
                                            <div className='assigned-Design-creds-container'>
                                                <p>66</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {(IsCompanyOpen && index === CompIndex) ?
                                    <div className='taskOverview-chart-section'>
                                        <div className='credits-tasks-section'>
                                            <div className='category-section'>
                                                <div className='categories-containers' style={{ fontWeight: '700' }}>
                                                    <p>Total Used Credits</p>
                                                    <div><p>55</p></div>
                                                </div>
                                                <div className='categories-containers' style={{ fontWeight: '700' }}>
                                                    <p>Total Tasks</p>
                                                    <div><p>55</p></div>
                                                </div>
                                            </div>
                                            <div className='category-section'>
                                                <div className='categories-containers'>
                                                    <p>Total Design Credits</p>
                                                    <div><p>55</p></div>
                                                </div>
                                                <div className='categories-containers'>
                                                    <p>Total Design Tasks</p>
                                                    <div><p>55</p></div>
                                                </div>
                                            </div>
                                            <div className='category-section'>
                                                <div className='categories-containers'>
                                                    <p>Total Dev Credits</p>
                                                    <div><p>55</p></div>
                                                </div>
                                                <div className='categories-containers'>
                                                    <p>Total Dev Tasks</p>
                                                    <div><p>55</p></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='remainingCategory-section'>
                                            <div className='remaingCreds-container'>
                                                <p>Remaining Credits</p>
                                                <div><p>55</p></div>
                                            </div>
                                        </div>
                                    </div> : null}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    };
});
export default Report;
