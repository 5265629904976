import React, { useState, useEffect } from 'react';
import './style.scss';

const shortMonths = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

const fullMonths = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

const Calendar = ({ onClick }) => {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    useEffect(() => {
    }, []);

    const nextYear = () => setCurrentYear(currentYear + 1);
    const prevYear = () => setCurrentYear(currentYear - 1);

    const handleMonthClick = (monthIndex) => {
        const selectedDate = `${fullMonths[monthIndex]} ${currentYear}`;
        onClick(selectedDate);
    };

    return (
        <div className="calendar-container">
            <div className="calendar-header">
                <button onClick={prevYear}>&lt;</button>
                <span>{currentYear}</span>
                <button onClick={nextYear}>&gt;</button>
            </div>
            <div className="calendar-body" style={{ width: '210px' }}>
                {shortMonths.map((month, index) => (
                    <div
                        key={index}
                        className="calendar-month"
                        onClick={() => handleMonthClick(index)}
                    >
                        {month}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Calendar;
